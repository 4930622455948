/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import CurrencyInput from 'react-currency-input';
import Validator from 'validator';
import { Formatter } from '../../utils';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(2),
  },
}));


function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

function NumberFormatCustom(props) {
  const { inputRef, onChange, onBlur, ...other } = props;

  return(
    <CurrencyInput

      {...other}
      required
      // className="form-control"
      name="valor"
      thousandSeparator='.'
      decimalSeparator=','
      onBlur={e => {
        if(typeof props.onBlur === 'function'){
          onBlur(e);
        }
      }}
      onChangeEvent={e => {
        onChange(e);

        // if(typeof props.onBlur === 'function'){
        //   onBlur(e);
        // }
        // console.log(other);
      }}
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function FormattedInputs( props ) {
  const classes = useStyles();
  const [data, setData] = React.useState(props.value);
  const [error, setError] = React.useState(false);

  const handleChange = name => event => {
    setData(event.target.value);
    setError(false);
  };

  const handleValidation = ( event ) => {
    if(props.required){
      if(Validator.isEmpty(event.target.value)){
        setError('O campo não pode ser vazio.');
        return false;
      }
      
      if(Formatter.Valor(event.target.value) < props.min){
        setError('O valor não pode ser menor que '+Formatter.Real(props.min));
        return false;
      }
    }

  }



  useEffect(() => {
    if(props.onError === true){
      var event = {
        target: {
          value: data
        }
      }

      handleValidation( event );
    }
  }, [props.onError]);

  useEffect(() => {
    setData(props.value);
  }, [props.value])


  const {onError, ...rest} = props;
  return (
    <React.Fragment>
      <TextField
        {...rest}
        className={classes.formControl}
        // label="react-number-format"
        value={data}
        onChange={handleChange('numberformat')}
        id="formatted-numberformat-input"
        style={{width: props.width || '90%',marginLeft:0}}
        InputProps={{
          inputComponent: NumberFormatCustom,
          startAdornment: <InputAdornment position="start">R$ </InputAdornment>
        }}
      />
      <div style={{color:'red'}}>
        {error}
      </div>

    </React.Fragment>
  );
}
