import React, { useState, useEffect, useMemo } from 'react';
import {
	Grid,
	Paper,
	Container,
	makeStyles,
	Button,
	Typography,
	Checkbox,
	FormControlLabel,
	TextField,
} from '@material-ui/core';
import ZTitle from '../system/ZTitle';
import ZText from '../system/ZText';
import ZSelect from '../system/ZSelect';
import ZSwitch from '../system/ZSwitch';
import ZSaveButton from '../system/ZSaveButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ValidatorForm, Isset, Mask } from '../../utils';
import { useSelector, useDispatch } from 'react-redux';
import { addMessage } from '../../store/alert';
import config from 'react-global-configuration';
import axios from 'axios';
import clsx from 'clsx';
import ZDatePicker from '../system/ZDatePicker';
import { format } from 'date-fns/esm';
import { useHistory, useRouteMatch } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
	formaPagamento: {
		padding: 20,
		fontSize: 16,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginBottom: 32,
		fontWeight: 700,
		color: '#333',
		width: 263,
		border: `solid 1px rgb(234, 234, 234)`,
		borderRadius: 8,
		cursor: 'pointer',
		'&.selected': {
			border: `solid 1px ${theme.palette.primary.main}`,
			color: theme.palette.primary.main,
		},
		'&:hover': {
			border: `1px solid ${theme.palette.primary.main}`,
			color: theme.palette.primary.main,
		},
		'&.disabled': {
			color: '#777',
			border: `solid 1px rgb(234, 234, 234)`,
		},
		[theme.breakpoints.down('sm')]: {
			padding: 10,
			marginBottom: 20,
			width: '100%',
		},
	},
	backButton: {
		color: theme.palette.primary.main,
		border: `solid 1px ${theme.palette.primary.main}`,
		width: 171,
		height: 44,
		marginRight: 10,
		'&:hover': {
			color: '#FFF',
			backgroundColor: theme.palette.primary.main,
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	nextButton: {
		width: 171,
		height: 44,
		backgroundColor: theme.palette.primary.main,
		color: '#FFF',
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	stepActions: {
		marginTop: 56,
		textAlign: 'center',
	},
	span: {
		color: '#777',
		lineHeight: 1.5,
	},
}));

const options = [
	{ id: 'daily', name: 'Diaria' },
	{ id: 'weekly', name: 'Semanal' },
	{ id: 'monthly', name: 'Mensal' },
	{ id: 'annually', name: 'Anual' },
];

const days = [...Array(30).keys()].map(i => ({ day: String(i + 1) }));

export default () => {
	const classes = useStyles();

	// const { history, match } = useReactRouter();
	const history = useHistory();
	const match = useRouteMatch();

	const dispatch = useDispatch();
	const validateFields = {
		name: { required: true },
		description: { required: true },
		amount: { required: true, min: 5 },
		setup_amount: { required: true },
		frequency: { required: true },
		payment_methods: { required: true },
		interval: { required: true },
	};

	const [loading, setLoading] = useState(false);
	const [onError, setOnError] = useState(false);
	const [activeStep, setActiveStep] = useState(0);
	const [checked, setChecked] = useState(false);
	const [isDueDaysChecked, setIsDueDaysChecked] = useState(false);
	const [defaultDueDays, setDefaultDueDays] = useState([]);

	const [data, setData] = useState({
		name: '',
		description: '',
		email: '',
		setup_amount: 0.0,
		amount: 0.0,
		grace_period: 0,
		tolerance_period: 0,
		frequency: 'monthly',
		zoop_plano_id: null,
		interval: 1,
		logo: false,
		currency: 'BRL',
		payment_methods: '',
		plan_expiration_date: new Date(),
		has_expiration: false,
		expire_subscriptions: false,
		subscription_duration: '',
		due_days: [],
		splits: [
			{
				cpfcnpj: '',
				estabelecimentoId: null,
				nome: '',
				email: '',
				tipoSplit: 2, // 1 - Valor, 2 - Percentual
				valor: '',
				chargeProcessingFee: false,
			},
		],
	});

	const token = useSelector(store => store.auth.token);

	useEffect(() => {
		if (match.params.planoId) {
			axios
				.get(config.get('apiUrl') + 'planos/' + match.params.planoId, {
					headers: { Authorization: `Bearer ${token}` },
					params: {
						isEdicao: true,
					},
				})
				.then(res => {
					if (res.data.success) {
						const due_days = res.data.plano.due_days
							? res.data.plano.due_days.map(days => ({ day: days }))
							: [];
						const hasDueDays = Boolean(due_days.length > 0);

						if (hasDueDays) {
							setIsDueDaysChecked(true);
						}

						setDefaultDueDays(due_days);

						setData({
							name: res.data.plano.name,
							description: res.data.plano.description,
							email: res.data.plano.email,
							setup_amount: res.data.plano.setup_amount,
							amount: res.data.plano.amount,
							grace_period: res.data.plano.grace_period,
							tolerance_period: res.data.plano.tolerance_period,
							frequency: res.data.plano.frequency,
							zoop_plano_id: res.data.plano.zoop_plano_id,
							interval: res.data.plano.interval,
							logo: !!res.data.plano.logo,
							currency: res.data.plano.currency,
							payment_methods: res.data.plano.method,
							plan_expiration_date: res.data.plano.plan_expiration_date,
							has_expiration: res.data.plano.plan_expiration_date
								? res.data.plano.plan_expiration_date
								: false,
							expire_subscriptions: res.data.plano.expire_subscriptions,
							subscription_duration: res.data.plano.subscription_duration,
							due_days,
							splits: Isset(() => res.data.plano.split.splits_estabelecimentos)
								? res.data.plano.split.splits_estabelecimentos
								: [],
						});
					}
				});
		}
	}, [match.params.planoId, token]);

	const frequencyOptions = useMemo(() => {
		const result = options.filter(frequency => {
			if (data.payment_methods === 'boleto') {
				return frequency.id !== 'daily';
			}

			return frequency;
		});

		return result;
	}, [data.payment_methods]);

	const handleSubmitForm = async () => {
		setLoading(true);

		try {
			ValidatorForm(validateFields, data);
		} catch (erro) {
			dispatch(addMessage({ type: 'error', message: 'Existem campos inválidos no formulário' }));
			setLoading(false);
			setOnError(true);
			return false;
		}

		if (data.due_days.length > 0) {
			if (typeof data.due_days[0] === 'object') {
				const due_days = data.due_days.map(({ day }) => Number(day));
				data.due_days = due_days;
			}
		}

		let res = '';

		if (!isDueDaysChecked) {
			data.due_days = [];
		}

		if (match.params.planoId) {
			res = await axios.put(config.get('apiUrl') + 'planos/' + match.params.planoId, data, {
				headers: { Authorization: `Bearer ${token}` },
			});
		} else {
			res = await axios.post(config.get('apiUrl') + 'planos', data, {
				headers: { Authorization: `Bearer ${token}` },
			});
		}
		setLoading(false);

		if (res.data.error) {
			dispatch(addMessage({ type: 'error', message: 'Ocorreu um erro ao salvar o novo plano' }));
		}

		if (Isset(() => res.data.success) && res.data.success === true) {
			dispatch(addMessage({ type: 'success', message: 'Operação realizada com sucesso' }));
			history.push('/planos');
		}
	};

	const handleChange = name => event => {
		const { value } = event.target;

		setData({
			...data,
			[name]: value,
		});
	};

	const handleCartao = () => {
		if (!match.params.planoId) {
			setData(data => ({ ...data, payment_methods: 'credito' }));
		}
	};

	const handleBoleto = () => {
		if (!match.params.planoId) {
			setData(data => ({ ...data, payment_methods: 'boleto' }));
		}
	};

	const handlePix = () => {
		if (!match.params.planoId) {
			setData(data => ({ ...data, payment_methods: 'pix' }));
		}
	};

	const handleDueDays = (_, data) => {
		const due_days = data.map(({ day }) => Number(day));

		setData(data => ({ ...data, due_days }));
		setDefaultDueDays(data);
	};

	const isDueDayActiveStep = useMemo(() => {
		return Boolean(activeStep === 4 || activeStep === 6);
	}, [activeStep]);

	const hideDueDays = useMemo(() => {
		return isDueDayActiveStep && data.frequency !== 'monthly';
	}, [data.frequency, isDueDayActiveStep]);

	// const handleNext = () =>
	// 	setActiveStep(activeStep => {
	// 		const isCreditSteper = Boolean(isDueDayActiveStep && data.payment_methods === 'credito');

	// 		if ((isCreditSteper || hideDueDays) && activeStep === 4) {
	// 			return activeStep + 2;
	// 		}

	// 		return activeStep + 1;
	// 	});

	const handleNext = async () => {
		try {
			let quantidadeSteps = 1;
			const isCreditSteper = Boolean(isDueDayActiveStep && data.payment_methods === 'credito');

			switch (activeStep) {
				case 1:
					if (data.nome === '' || data.description === '' || data.amount / 100 < 5) {
						ValidatorForm(validateFields.name, validateFields.description, validateFields.amount, data);
						dispatch(
							addMessage({
								type: 'error',
								message: 'Por favor preencha todos os campos corretamente para continuar ',
							})
						);
						return false;
					}
					break;
				case 3:
					if (data.grace_period < 0) {
						dispatch(addMessage({ type: 'error', message: 'O período nao pode ser menor que  0' }));
						return false;
					}
					break;
				case 4:
					if (data.interval >= 1) {
						if (isCreditSteper || hideDueDays) {
							quantidadeSteps = 2;
						}
					} else {
						dispatch(addMessage({ type: 'error', message: 'O intervalo deve ser maior que 0' }));
						return false;
					}
					break;
				default:
					break;
			}
			setActiveStep(prevStep => prevStep + quantidadeSteps);
		} catch (error) {
			setOnError(true);
			dispatch(addMessage({ type: 'error', message: error.message }));
			setLoading(false);
		}
	};

	const handleBack = () =>
		setActiveStep(activeStep => {
			const isCreditSteper = Boolean(isDueDayActiveStep && data.payment_methods === 'credito');

			if ((isCreditSteper || hideDueDays) && activeStep === 6) {
				return activeStep - 2;
			}

			return activeStep - 1;
		});

	const formattedDueDays = useMemo(() => {
		return defaultDueDays.map(({ day }) => day).toString();
	}, [defaultDueDays]);

	const handleFrequency = useMemo(() => {
		const result = {
			frequency: {
				daily: 'diária',
				weekly: 'semanal',
				monthly: 'mensal',
				annually: 'anual',
			},
			interval: {
				daily: 'dias',
				weekly: 'semanas',
				monthly: 'meses',
				annually: 'anos',
			},
		};

		return {
			frequency: result.frequency[data.frequency],
			interval: result.interval[data.frequency],
		};
	}, [data.frequency]);

	return (
		<Container maxWidth="md">
			<Grid container>
				<ZTitle title={match.params.planoId ? 'Editar Plano' : 'Adicionar Plano'} />
				<Grid item xs={12}>
					<Paper style={{ padding: 32 }}>
						{activeStep === 0 && (
							<>
								<ZTitle title={'Forma de Pagamento'} variant={'h5'} marginTop={8} marginBottom={32} />

								<Grid container justifyContent="center" spacing={3}>
									<Grid item xs={12} sm={4} md={4} lg={4}>
										<div
											className={clsx(classes.formaPagamento, {
												selected: data.payment_methods === 'boleto',
												disabled:
													match.params.planoId &&
													['credit', 'credito'].includes(data.payment_methods),
											})}
											onClick={handleBoleto}
										>
											Boleto Bancário
										</div>
									</Grid>

									<Grid item xs={12} sm={4} md={4} lg={4}>
										<div
											className={clsx(classes.formaPagamento, {
												selected: data.payment_methods === 'pix',
												disabled: match.params.planoId && data.payment_methods !== 'pix',
											})}
											onClick={handlePix}
										>
											Pix
										</div>
									</Grid>
									<Grid item xs={12} sm={4} md={4} lg={4}>
										<div
											className={clsx(classes.formaPagamento, {
												selected: data.payment_methods === 'credito',
												disabled: match.params.planoId && data.payment_methods !== 'credito',
											})}
											onClick={handleCartao}
										>
											Cartão de Crédito
										</div>
									</Grid>
								</Grid>
							</>
						)}

						{activeStep === 1 && (
							<>
								<ZTitle title={'Informações do Plano'} variant={'h5'} marginTop={8} marginBottom={32} />
								<Grid container spacing={4}>
									<Grid item xs={12} md={12} sm={12}>
										<Grid container spacing={3}>
											<Grid item xs={12} sm={6} md={6}>
												<ZText
													label="Nome"
													onError={onError}
													required={true}
													value={data.name}
													onBlur={handleChange('name')}
													maxLength={32}
												/>
											</Grid>
											<Grid item xs={12} sm={6} md={6}>
												<ZText
													fullWidth
													label="Valor"
													margin="normal"
													realMask
													onError={onError}
													min={validateFields.amount.min}
													placeholder="Valor do plano"
													value={data.amount / 100}
													onBlur={e => {
														setData({ ...data, amount: e.target.value * 100 });
													}}
												/>
											</Grid>
											<Grid item xs={12} md={12}>
												<ZText
													label="Descrição"
													multiline
													rows="3"
													onError={onError}
													required={true}
													value={data.description}
													onBlur={handleChange('description')}
												/>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12} md={8} lg={8}>
										<Grid container spacing={5}>
											{/* <Grid item xs={12} sm={6} md={4}>
										<ZText
											label="Período de tolerância"
											placeholder="Período em dias"
											onError={onError}
											required={true}
											onlyNumber
											value={data.tolerance_period}
											onBlur={handleChange('tolerance_period')}
										/>
									</Grid> */}
										</Grid>
									</Grid>
								</Grid>
							</>
						)}

						{activeStep === 2 && (
							<>
								<ZTitle
									title={'E-mail para Notificações'}
									variant={'h5'}
									marginTop={8}
									marginBottom={32}
								/>
								<Grid container spacing={4}>
									<Grid item xs={12} md={12} sm={12}>
										<Grid container spacing={3}>
											<Grid item xs={12} sm={6} md={6}>
												<ZText
													label="E-mail"
													onError={onError}
													value={data.email}
													onBlur={handleChange('email')}
												/>
												<span className={classes.span}>
													Será enviado e-mail de notificação com as ações realizadas para este
													plano
												</span>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</>
						)}

						{activeStep === 3 && (
							<>
								<ZTitle title={'Setup'} variant={'h5'} marginTop={8} marginBottom={32} />

								<Grid container spacing={4}>
									<Grid item container xs={12} sm={12} md={12}>
										<Grid container spacing={3}>
											<Grid item xs={12} sm={6} md={6}>
												<ZText
													fullWidth
													label="Setup Valor"
													margin="normal"
													realMask
													placeholder="Valor do setup"
													value={data.setup_amount / 100}
													onBlur={e =>
														setData({ ...data, setup_amount: e.target.value * 100 })
													}
												/>

												<span className={classes.span}>
													Setup Valor é uma taxa cobrada no ato de adesão
												</span>
											</Grid>
											<Grid item xs={12} sm={6} md={6}>
												<ZText
													label="Período gratuito em dias"
													placeholder="Período em dias"
													onError={onError}
													required={true}
													value={data.grace_period}
													onlyNumber
													onBlur={handleChange('grace_period')}
												/>

												<span className={classes.span}>
													Quantidade de dias até realizar a primeira cobrança dessa assinatura
												</span>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</>
						)}

						{activeStep === 4 && (
							<>
								<ZTitle
									title={'Periodo de Recorrência'}
									variant={'h5'}
									marginTop={8}
									marginBottom={32}
								/>

								<Grid container spacing={4}>
									<Grid item container xs={12} sm={12} md={12}>
										<Grid container spacing={3}>
											<Grid item xs={12} sm={6} md={6}>
												<ZSelect
													options={frequencyOptions}
													label="Frequência"
													onError={onError}
													width="100%"
													value={data.frequency}
													onBlur={handleChange('frequency')}
												/>

												<span className={classes.span}>
													A frequência é o periodo da cobrança
												</span>
											</Grid>
											<Grid item xs={12} sm={6} md={6}>
												<ZText
													label="Intervalo"
													onError={onError}
													required={true}
													value={data.interval}
													onlyNumber
													onBlur={handleChange('interval')}
												/>

												<span className={classes.span}>
													O intervalo de {handleFrequency.interval} até a próxima recorrência
												</span>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</>
						)}

						{activeStep === 5 && (
							<>
								<ZTitle
									title={'Melhor dia Para Pagamento'}
									variant={'h5'}
									marginTop={8}
									marginBottom={32}
								/>
								<Grid container xs sm md alignItems="center">
									<Grid item xs={12} sm={6} md={6}>
										<FormControlLabel
											value="end"
											control={
												<Checkbox
													checked={isDueDaysChecked}
													onChange={({ target }) => setIsDueDaysChecked(target.checked)}
													color="primary"
												/>
											}
											label="Definir o melhor dia para o pagamento"
											labelPlacement="end"
										/>
									</Grid>
									<Grid item xs={12} sm={6} md={6}>
										<Autocomplete
											multiple
											id="tags-outlined"
											disabled={!isDueDaysChecked}
											options={days}
											onChange={handleDueDays}
											getOptionLabel={option => option.day}
											getOptionSelected={(option, defaultDueDays) =>
												option.day === defaultDueDays.day
											}
											filterSelectedOptions
											defaultValue={defaultDueDays}
											disableCloseOnSelect
											renderInput={params => (
												<TextField {...params} variant="standard" label="Dias de Vencimento" />
											)}
										/>
										<span className={classes.span}>
											Selecione os melhores dias para o vencimento do {data.payment_methods}.
										</span>
									</Grid>
								</Grid>
							</>
						)}

						{activeStep === 6 && (
							<>
								<ZTitle
									title={'Validade da Assinatura'}
									variant={'h5'}
									marginTop={8}
									marginBottom={32}
								/>
								<Grid container xs sm md alignItems="center">
									<Grid item xs={12} sm={6} md={6}>
										<FormControlLabel
											value="end"
											control={
												<Checkbox
													checked={checked}
													onChange={({ target }) => setChecked(target.checked)}
													color="primary"
												/>
											}
											label="Definir um periodo de expiração"
											labelPlacement="end"
										/>
									</Grid>
									<Grid item xs={12} sm={6} md={6}>
										<ZText
											label="Duração da assinatura"
											placeholder="Período em número de meses"
											disabled={!checked}
											onError={onError}
											onlyNumber
											value={data.subscription_duration}
											onBlur={handleChange('subscription_duration')}
										/>
										<span className={classes.span}>
											Quantidade de meses que a assinatura ficará ativa após à adesão.
										</span>
									</Grid>
								</Grid>
							</>
						)}

						{activeStep === 7 && (
							<>
								<ZTitle title={'Parâmetros'} variant={'h5'} marginTop={8} marginBottom={32} />

								<Grid item xs={12} sm={12} md={12} style={{ marginTop: 16 }}>
									<ZSwitch
										onChange={() => setData({ ...data, logo: !data.logo })}
										label="Exibir logo do estabelecimento na tela de adesão?"
										checked={data.logo}
									/>
								</Grid>
								<Grid item xs={12} sm={12} md={12} style={{ marginTop: 16 }}>
									<Grid container>
										<Grid item sm={6}>
											<ZSwitch
												onChange={() => {
													if (!match.params.planoId) {
														setData({ ...data, has_expiration: !data.has_expiration });
													}
												}}
												label="Data de expiração no plano?"
												disabled={match.params.planoId ? true : false}
												checked={data.has_expiration}
											/>
										</Grid>
									</Grid>
								</Grid>

								{data.has_expiration && (
									<>
										<Grid container style={{ marginTop: 16 }}>
											<Grid item sm={6} md={1}></Grid>
											<Grid item sm={6} md={6}>
												<ZDatePicker
													textField
													label="Data de expiração do Plano"
													// erro={errors.dataVencimento}
													date={data.plan_expiration_date}
													minDate={new Date()}
													disabled={match.params.planoId ? true : false}
													// onChange={handleChange('')}
													onChange={date => setData({ ...data, plan_expiration_date: date })}
												/>
											</Grid>
										</Grid>

										<Grid item style={{ marginTop: 16 }}>
											<ZSwitch
												onChange={() => {
													if (!match.params.planoId) {
														setData({
															...data,
															expire_subscriptions: !data.expire_subscriptions,
														});
													}
												}}
												label="Expirar as assinaturas?"
												disabled={match.params.planoId ? true : false}
												checked={data.expire_subscriptions}
											/>
										</Grid>
									</>
								)}
							</>
						)}

						{activeStep === 8 && (
							// 	<>
							// 		{/* <ZTitle title={'Split'} variant={'h5'} marginTop={8} marginBottom={32} /> */}
							// 		<Grid container spacing={2}>
							// 			<ConfigurarSplit
							// 				data={data}
							// 				setData={setData}
							// 				estabelecimentoId={'estabelecimentoId'}
							// 				errors={'errors'}
							// 				buttons={false}
							// 				setErrors={() => true}
							// 				changeStep={() => true}
							// 				setActiveStep={setActiveStep}
							// 			/>
							// 		</Grid>
							// 	</>
							// )}

							// {activeStep === 9 && (
							<>
								<ZTitle title={'Resumo'} variant={'h5'} marginTop={8} marginBottom={32} />

								<Grid container spacing={2}>
									<Grid item md={6} xs={12} className={classes.labelResumo}>
										<Typography variant="body1" component="h5" gutterBottom>
											Nome <br /> <span className={classes.span}>{data.name}</span>
										</Typography>
									</Grid>
									<Grid item md={6} xs={12} className={classes.labelResumo}>
										<Typography variant="body1" component="h5" gutterBottom>
											Descrição <br /> <span className={classes.span}>{data.description}</span>
										</Typography>
									</Grid>
									<Grid item md={6} xs={12} className={classes.labelResumo}>
										<Typography variant="body1" component="h5" gutterBottom>
											E-mail <br /> <span className={classes.span}>{data.email}</span>
										</Typography>
									</Grid>
									<Grid item md={6} xs={12} className={classes.labelResumo}>
										<Typography variant="body1" component="h5" gutterBottom>
											Valor <br />{' '}
											<span className={classes.span}>{Mask.Real(data.amount / 100)}</span>
										</Typography>
									</Grid>
									<Grid item md={6} xs={12} className={classes.labelResumo}>
										<Typography variant="body1" component="h5" gutterBottom>
											Setup Valor <br />{' '}
											<span className={classes.span}>{Mask.Real(data.setup_amount / 100)}</span>
										</Typography>
									</Grid>
									<Grid item md={6} xs={12} className={classes.labelResumo}>
										<Typography variant="body1" component="h5" gutterBottom>
											Frequência <br />{' '}
											<span style={{ textTransform: 'capitalize' }} className={classes.span}>
												{handleFrequency.frequency}
											</span>
										</Typography>
									</Grid>
									<Grid item md={6} xs={12} className={classes.labelResumo}>
										<Typography variant="body1" component="h5" gutterBottom>
											Intervalo <br /> <span className={classes.span}>{data.interval}</span>
										</Typography>
									</Grid>
									<Grid item md={6} xs={12} className={classes.labelResumo}>
										<Typography variant="body1" component="h5" gutterBottom>
											Duração da assinatura <br />{' '}
											<span className={classes.span}>
												{data.subscription_duration
													? `${data.subscription_duration} meses`
													: 'N/A'}
											</span>
										</Typography>
									</Grid>
									<Grid item md={6} xs={12} className={classes.labelResumo}>
										<Typography variant="body1" component="h5" gutterBottom>
											Periodo gratuito em dias <br />{' '}
											<span className={classes.span}>
												{data.grace_period
													? `${data.grace_period} dia${data.grace_period > 1 ? 's' : ''}`
													: 'N/A'}
											</span>
										</Typography>
									</Grid>
									{data.has_expiration && (
										<>
											<Grid item md={6} xs={12} className={classes.labelResumo}>
												<Typography variant="body1" component="h5" gutterBottom>
													Data de Expiração do Plano <br />{' '}
													<span className={classes.span}>
														{data.plan_expiration_date
															? format(data.plan_expiration_date, 'DD/MM/YYYY')
															: 'N/A'}
													</span>
												</Typography>
											</Grid>
											<Grid item md={6} xs={12} className={classes.labelResumo}>
												<Typography variant="body1" component="h5" gutterBottom>
													Exibir Assinatura? <br />{' '}
													<span className={classes.span}>
														{data.expire_subscriptions ? 'Sim' : 'Não'}
													</span>
												</Typography>
											</Grid>
										</>
									)}
									<Grid item md={6} xs={12} className={classes.labelResumo}>
										<Typography variant="body1" component="h5" gutterBottom>
											Exibir logo do estabelecimento? <br />{' '}
											<span className={classes.span}>{data.logo ? 'Sim' : 'Não'}</span>
										</Typography>
									</Grid>
									{/* {data.due_days.length > 0 && ( */}
									{isDueDaysChecked && data.due_days.length > 0 && (
										<Grid item md={6} xs={12} className={classes.labelResumo}>
											<Typography variant="body1" component="h5" gutterBottom>
												Dias para o vencimento <br />{' '}
												<span className={classes.span}>{formattedDueDays}</span>
											</Typography>
										</Grid>
									)}
								</Grid>
							</>
						)}

						<Grid
							container
							justifyContent="flex-end"
							spacing={2}
							direction="row"
							className={classes.stepActions}
						>
							{activeStep !== 0 && (
								<Grid item xs={12} sm={12} md={6} lg={3}>
									<Button className={classes.backButton} fullWidth onClick={handleBack}>
										Voltar
									</Button>
								</Grid>
							)}

							{activeStep === 8 ? (
								<Grid item xs={12} sm={12} md={6} lg={3}>
									<ZSaveButton
										onClick={() => handleSubmitForm()}
										color={'primary'}
										align="right"
										label={'Salvar'}
										required={true}
										loading={loading}
									/>
								</Grid>
							) : (
								<Grid item xs={12} sm={12} md={6} lg={3}>
									<Button
										className={classes.nextButton}
										fullWidth
										onClick={handleNext}
										style={{ color: '#fff' }}
									>
										Continuar
									</Button>
								</Grid>
							)}
						</Grid>
					</Paper>
				</Grid>
			</Grid>
		</Container>
	);
};
