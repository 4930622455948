import React, { useCallback, useEffect, useState } from 'react';
import ZLoader from '../system/ZLoader';

import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';
import config from 'react-global-configuration';
import { useSelector } from 'react-redux';

import { Container, Grid } from '@material-ui/core';
import PrintCarneParcela from './PrintCarneParcela';

const useStyle = makeStyles(theme => ({
	container: {
		width: '100%',
		minHeight: '50vh',
		display: 'block',

		'& .container__icon': {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			marginTop: '64px',

			position: 'relative',
			backgroundColor: '#FFF',
			borderRadius: '50%',
			width: '100px',
			height: '100px',
		},

		'& .container__icon-check': {
			position: 'absolute',
			top: '66px',
			left: '79px',
		},

		'& .paper': {
			borderRadius: 5,
			padding: 32,
			position: 'absolute',
			// top: '55%',

			'& .paper__content-title': {
				color: '#424242',
				fontSize: '18px',
				fontWeight: 'bold',
				margin: 0,
			},

			'& .paper__content-text': {
				color: '#757575',
				fontSize: '14px',
				lineHeight: '24px',
				margin: 0,
			},
		},
	},
	breakPage: {
		pageBreakAfter: 'always',
		visibility: 'hidden',
	},
	empty: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: 18,
		fontWeight: 700,
		height: 100,
	},
}));

const BreakPage = props => {
	const classes = useStyle();
	return <div className={classes.breakPage} />;
};

export default props => {
	const { carneId } = props;
	const token = useSelector(store => store.auth.token);
	const classes = useStyle();

	const [loading, setLoading] = useState(true);
	const [carne, setCarne] = useState(null);

	const getCarne = useCallback(async () => {
		setLoading(true);
		try {
			const res = await axios.get(`${config.get('apiUrl')}carnes/${carneId}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			if (res.data.success) {
				setCarne(res.data.carne);
			}
		} catch (error) {
			console.log(error.message);
		}
		setLoading(false);
	}, [carneId, token]);

	useEffect(() => {
		getCarne();
	}, [getCarne]);


	if (loading) {
		return <ZLoader height={400} message="Carregando Carnê" />;
	}

	return (
		<Container maxWidth="md">
			<Grid container className={classes.container}>
				{!carne ? (
					<div className={classes.empty}>Carnê não encontrado.</div>
				) : (
					<>
						{carne.carnes_parcelas.map((carneParcela, index) => (
							<>
								<PrintCarneParcela
									titulo={carne.titulo}
									descricao={carne.descricao}
									logo={carne.logo}
									totalParcelas={carne.parcelas}
									estabelecimento={carne.estabelecimento}
									cliente={carne.cliente}
									carneParcela={carneParcela}
									descontos={carneParcela.pedido.descontos}
									mora={carne.mora}
									multa={carne.multa}
								/>
								{(index + 1) % 3 === 0 && <BreakPage />}
							</>
						))}
					</>
				)}
			</Grid>
		</Container>
	);
};
