/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import axios from 'axios';
import config from 'react-global-configuration';
import { useSelector, useDispatch } from 'react-redux';
import { addMessage } from '../../store/alert';
import { Dialog, DialogTitle, DialogActions, Button, DialogContentText } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	text: {
		margin: '20px',
		color: 'black',
	},
	fullWidth: {
		width: '100%',
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightRegular,
	},
	btn: {
		marginTop: 20,
	},
	buttonProgress: {
		color: 'blue',
	},
}));

export default props => {
	const { posId } = props;
	const classes = useStyles();
	const dispatch = useDispatch();
	const token = useSelector(store => store.auth.token);
	const [loader, setLoader] = useState(false);

	const DeletePos = async () => {
		setLoader(true)
		const res = await axios.delete(`${config.get('apiUrl')}pos/${posId}`, {
			headers: { Authorization: `Bearer ${token}` },
		});
		if (res.data.success) {
			props.onClose();
			dispatch(addMessage({ type: 'success', message: 'Operação realizada com sucesso' }));
			setLoader(false)
		} else {
			dispatch(addMessage({ type: 'error', message: res.data.error }));
			setLoader(false)
		}
		setLoader(false)
	};

	return (
		<Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
			<DialogTitle>Excluir Pos</DialogTitle>
			<DialogContentText>
				<div className={classes.text}>
					Essa ação não desloga a POS no gateway de pagamento, para deslogar lá é preciso desvincular a POS na
					própria POS
				</div>
			</DialogContentText>
			<DialogActions>
				<Button className={classes.btn} onClick={props.onClose}>
					Cancelar
				</Button>
				<Button className={classes.btn} variant="contained" color="primary" onClick={loader ? () => {} : DeletePos}>
					{loader ? (
						<CircularProgress size={24} className={classes.buttonProgress} />
					): 'Excluir'}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
