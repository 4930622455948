import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import { useSelector } from 'react-redux';
// import Toolbar from '@material-ui/core/Toolbar';

// import Typography from '@material-ui/core/Typography';

import IconButton from '@material-ui/core/IconButton';
// import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import Header from './Header';
import Menu from './Menu';
import Routes from './Routes';
import Saldo from './Saldo';
// import moment from 'moment';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogTitle from '@material-ui/core/DialogTitle';
// import { setOnePayComunicado } from '../../store/system';
import { isMobile } from 'react-device-detect';
//import TermoOnePay from '../Termos/TermoOnePay';
import Termos from '../Termos/TermosCondicoes';
import axios from 'axios';
import config from 'react-global-configuration';
import { Isset } from '../../utils';
import ZSplashScreen from '../system/ZSplashScreen';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
	},
	appBar: {
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: drawerWidth,
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	hide: {
		display: 'none',
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
	},
	drawerHeader: {
		display: 'flex',
		alignItems: 'center',
		padding: '0 8px',
		...theme.mixins.toolbar,
		justifyContent: 'flex-end',
	},
	content: {
		width: '100%',
		flexGrow: 1,
		padding: 15,
		paddingTop: 54,
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		marginLeft: -drawerWidth,
		'& .rdrStaticRangeSelected': {
			color: `${theme.palette.primary.main} !important`,
		},
		'& .rdrDayStartPreview': {
			color: `${theme.palette.primary.main} !important`,
		},
		'& .rdrDayEndPreview': {
			color: `${theme.palette.primary.main} !important`,
		},
		'& .rdrDayInPreview': {
			color: `${theme.palette.primary.main} !important`,
		},
		'& .rdrStartEdge': {
			color: `${theme.palette.primary.main} !important`,
		},
		'& .rdrEndEdge': {
			color: `${theme.palette.primary.main} !important`,
		},
		'& .rdrInRange': {
			color: `${theme.palette.primary.main} !important`,
		},
		'& .rdrDayToday .rdrDayNumber span:after': {
			backgroundColor: `${theme.palette.primary.main} !important`,
		},
	},
	contentShift: {
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		position: 'fixed',
		width: '100%',
		transform: `translateX(${drawerWidth}px)`,
		marginLeft: 0,
	},
	contentTop: {
		paddingTop: 90,
	},
	contentBackdrop: {
		position: 'fixed',
		zIndex: 1200,
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		visibility: 'hidden',
		transition: theme.transitions.create('left', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		backgroundColor: 'rgba(0,0,0,0.3)',
		'&.open': {
			visibility: 'visible',
			left: drawerWidth,
		},
	},
	dialogOnePay: {
		'& .MuiDialog-paper': {
			fontSize: 14,
			background: '#0746b1',
			color: 'white',
			height: '100%',
			width: 680,
			textAlign: 'center',
		},
		'& p': {
			margin: '4px 0px',
		},
	},
	dialogOK: {
		fontSize: 16,
		color: 'white',
	},
}));

const useStylesCssBaseline = makeStyles(theme => ({
	'@global': {
		'*::-webkit-scrollbar': {
			width: 6,
			cursor: 'pointer',
		},
		'*::-webkit-scrollbar-track': {
			'-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
			borderRadius: 10,
		},
		'*::-webkit-scrollbar-thumb': {
			backgroundColor: 'rgba(0,0,0,.3)',
			outline: '1px solid slategrey',
			borderRadius: 10,
			'&:hover': {
				backgroundColor: 'rgba(0,0,0,.5)',
			},
		},
	},
}));

// const OnePayMessage = props => {
// 	return (
// 		<>
// 			{/* <DialogTitle id="alert-dialog-title">{'Caros parceiros'}</DialogTitle> */}
// 			<DialogContent>
// 				<div>
// 					<p style={{ textAlign: 'center' }}>
// 						<img
// 							style={{ width: '100%' }}
// 							alt="Comunicado"
// 							src="https://z-systems-bucket.s3-sa-east-1.amazonaws.com/files/system_config/others/comunicadoOnePay.jpeg"
// 						/>
// 					</p>
// 					{/* <p>Nós voltamos!  </p> */}

// 					{/* <p>É com imenso prazer que comunicamos a retomada das antecipações em 1 dia útil.</p> */}

// 					<p>
// 						A partir de hoje, quinta feira (28/05) todas as vendas feitas em débito e crédito serão
// 						liquidadas no próximo dia útil.
// 					</p>

// 					<p>As vendas anteriores a este período serão liquidadas até no máximo segunda-feira (01/06).</p>

// 					<p>Agradecemos a compreensão e fidelidade de todos vocês.</p>

// 					<p>Cordialmente,</p>

// 					<p>
// 						<strong>
// 							Jonny Morais
// 							<br />
// 							CEO
// 						</strong>
// 					</p>
// 				</div>
// 			</DialogContent>
// 		</>
// 	);
// };

// const ClearPayMessage = props => {
// 	return (
// 		<>
// 			<DialogTitle id="alert-dialog-title">{'SUSPENSÃO TEMPORÁRIA DE ANTECIPAÇÕES'}</DialogTitle>
// 			<DialogContent>
// 				<div>
// 					<p>Caro Parceiro,</p>

// 					<p>
// 						Desde o início da crise provocada pela Covid-19, a CLEAR PAY vem fazendo todo o possível para
// 						ser um porto seguro para seus funcionários, parceiros e clientes. Nesse período, uma das nossas
// 						fornecedoras mais importantes alterou radicalmente, e sem consulta prévia, suas práticas
// 						comerciais. Há alguns dias, a adquirente REDE interrompeu a antecipação de recebíveis para a
// 						CLEAR PAY - entre centenas de outras instituições - , afetando direta e unilateralmente nossa
// 						operação.
// 					</p>

// 					<p>
// 						Até o momento, mantivemos as antecipações para 100% dos nossos clientes, enquanto trabalhamos
// 						para que a REDE reveja sua posição. Mas, à medida que o impasse se estende, nos vemos obrigados
// 						a reconfigurar nossos serviços.
// 					</p>

// 					<p>
// 						Portanto, por motivos totalmente alheios à nossa vontade, a CLEAR PAY interromperá
// 						temporariamente a liquidação antecipada dos planos antecipados para pagamentos capturados com
// 						"cartão presente", a partir do dia 1° de Maio.
// 					</p>

// 					<p>
// 						A interrupção se aplica somente a pagamentos do tipo crédito à vista e parcelado capturados com
// 						"cartão presente", ou seja, capturadas em POS, mPOS ou outro terminal físico de captura, e não
// 						afetará as transações do tipo débito nem as transações online. Durante a interrupção, os
// 						pagamentos de crédito à vista e parcelado serão liquidados em D+30, em vez de D+1.
// 					</p>

// 					<p>
// 						Esta é uma situação imprevista, gerada por movimentação unilateral da adquirente REDE, que
// 						infelizmente não pode ser contornada de outra forma.{' '}
// 					</p>

// 					<p>
// 						Reforço o compromisso de manter a transparência e fazer tudo que estiver ao nosso alcance para
// 						garantir o mínimo impacto possível aos nossos clientes neste momento difícil.{' '}
// 					</p>

// 					<p>
// 						Estamos empenhados em buscar alternativas e esperamos comunicar, em breve, o restabelecimento de
// 						nossa oferta completa de serviços, assim que resolvermos o problema, executaremos uma rotina
// 						para liquidação no próximo dia útil do saldo em conta dos parceiros.
// 					</p>

// 					<p>
// 						Por exemplo: se conseguirmos uma solução para retomar as antecipações na próxima quinta-feira
// 						(07/05), uma transação (mesmo parcelada) realizada no sábado (02/05) será liquidada em D+5. A
// 						taxa aplicada a essa transação será proporcional à antecipação em D+5, considerando o plano que
// 						aquele EC está associado. Neste mesmo dia, iremos restaurar as configurações originais do prazo
// 						de liquidação nos planos antecipados.{' '}
// 					</p>

// 					<p>As equipes da CLEAR PAY estão à disposição para sanar as suas dúvidas. Atenciosamente,</p>

// 					<p>
// 						<strong>Equipe Clear pay</strong>
// 					</p>
// 				</div>
// 			</DialogContent>
// 		</>
// 	);
// };

export default function MainSystem() {
	const classes = useStyles();
	// const dispatch = useDispatch();
	const classesCssBaseline = useStylesCssBaseline();
	const theme = useTheme();
	const [open, setOpen] = useState(false);
	const usuario = useSelector(state => state.auth.usuario);
	const [termos, setTermos] = useState(false);

	// const comunicado = useSelector(state => state.system.onePayComunicado);
	// const showMessage = [4, 8].includes(usuario.marketplace.id);

	// const [termoOnePayShow, setTermoOnePayShow] = useState(
	// 	usuario.marketplace.id === 8 && !usuario.estabelecimento.termosCondicoesAceito
	// );
	React.useEffect(() => {
		const getTermos = async () => {
			var res = null;
			const parentId = Isset(() => usuario.estabelecimento.parent.id) ? usuario.estabelecimento.parent.id : null;
			if (!parentId) {
				res = await axios.get(
					`${config.get('apiUrl')}termosCondicoes/por_marketplace/${usuario.marketplace.id}`
				);
			} else {
				res = await axios.get(`${config.get('apiUrl')}termosCondicoes/por_estabelecimento/${parentId}`);
			}

			if (res.data.success && !usuario.estabelecimento.termosCondicoesAceito) {
				setTermos(res.data.termos.termos_condicoes);
			}
		};
		getTermos();
	}, [usuario.estabelecimento.parent, usuario.marketplace.id, usuario.estabelecimento.termosCondicoesAceito]);

	function handleDrawerOpen() {
		setOpen(true);
	}

	function handleDrawerClose() {
		setOpen(false);
	}

	// const handleCloseOnePayComunicado = () => {
	// 	dispatch(setOnePayComunicado(false));
	// };

	if (theme.palette.primary.main === '#036596') {
		return <ZSplashScreen />;
	}

	return (
		<div className={classes.root}>
			<CssBaseline classes={classesCssBaseline} />
			<AppBar
				position="fixed"
				className={clsx(classes.appBar, {
					[classes.appBarShift]: open,
				})}
			>
				<Header
					propsOnClick={handleDrawerOpen}
					propsClassName={clsx(classes.menuButton, open && classes.hide)}
				/>
				<Saldo />
			</AppBar>
			<Drawer
				className={classes.drawer}
				variant="persistent"
				anchor="left"
				open={open}
				classes={{
					paper: classes.drawerPaper,
				}}
			>
				<div className={classes.drawerHeader}>
					<IconButton onClick={handleDrawerClose}>
						{theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
					</IconButton>
				</div>

				<Menu
					closeMenu={() => {
						setOpen(false);
					}}
				/>
				{
					// <List>
					//            {['All mail', 'Trash', 'Spam'].map((text, index) => (
					//              <ListItem button key={text}>
					//                <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
					//                <ListItemText primary={text} />
					//              </ListItem>
					//            ))}
					//          </List>
				}
			</Drawer>
			<div onClick={handleDrawerClose} className={clsx({ [classes.contentBackdrop]: true, open: open })} />
			<main
				className={clsx(classes.content, {
					[classes.contentShift]: open,
					[classes.contentTop]: isMobile,
				})}
			>
				<div className={classes.drawerHeader} />
				<Routes />
			</main>

			{/* {usuario.marketplace.id === 8 && (
				<TermoOnePay open={termoOnePayShow} onAceitar={() => setTermoOnePayShow(false)} />
			)} */}

			{termos && <Termos termos={termos} open={true} onAceitar={() => setTermos(false)} />}

			{/* <Dialog
				fullWidth
				maxWidth="md"
				open={comunicado && showMessage}
				onClose={handleCloseOnePayComunicado}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				className={clsx({
					// [classes.dialogOnePay]: usuario.marketplace.id === 8,
				})}
			>
				{usuario.marketplace.id === 8 && <TermoOnePay />}
				<DialogActions>
					<Button
						className={classes.dialogOK}
						onClick={handleCloseOnePayComunicado}
						color="primary"
						// variant="contained"
						autoFocus
					>
						OK
					</Button>
				</DialogActions>
			</Dialog> */}
		</div>
	);
}
