import { Button, Grid, TextField, makeStyles } from '@material-ui/core';
import React from 'react';
import { useMask } from '../../../hooks/useMask';
import { addMessage } from '../../../store/alert';
import ZTitle from '../../system/ZTitle';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import config from 'react-global-configuration';

const useStyles = makeStyles(theme => ({
	paper: {
		marginBottom: theme.spacing(3),
		padding: theme.spacing(2),
		[theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
			marginBottom: theme.spacing(6),
			padding: theme.spacing(6),
		},
	},
	button: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(1),
		padding: '8px 16px',
	},
}));

export default props => {
	const classes = useStyles();
	const dispatch = useDispatch();
	// const message = useSelector(store => store.alert);

	const history = useHistory();
	const systemConfig = useSelector(store => store.system.config);
	const { estabelecimentoId } = systemConfig;
	const initialState = {
		documento: '',
	};
	const { state, handleMask } = useMask(initialState);

	const handleChange = e => {
		handleMask(e);

		dispatch(addMessage({ type: 'success', message: '' }));
	};

	const handleSubmit = e => {
		e.preventDefault();
		const documento = state.documento.replace(/\D/g, '');

		axios
			.get(`${config.get('apiUrl')}clientes/por_documento/${documento}/public/${estabelecimentoId}`)
			.then(response => response.data)
			.then(data => {
				if (!data.success) {
					dispatch(addMessage({ type: 'error', message: data.error }));
				} else {
					dispatch(addMessage({ type: 'success', message: '' }));
					history.push(`listagem-boletos/${documento}`);
					// window.location = `/listagem-boletos/${documento}`;
				}
			})
			.catch(err => {
				dispatch(addMessage({ type: 'error', message: 'Cliente não encontrado' }));

				console.error(err);
			});
	};
	return (
		<>
			<Grid item xs={12} md={6}>
				<ZTitle title={'CONSULTAR BOLETOS'} marginBottom={25} variant="h6" textAlign="center" color="#808080" />
				<Grid container justifyContent="center">
					<Grid item md={12} xs={12}>
						<form onSubmit={handleSubmit}>
							<TextField
								name="documento"
								label="CPF/CNPJ"
								fullWidth
								onChange={handleChange}
								value={state.documento}
							/>
						</form>
					</Grid>

					<Grid container justifyContent="flex-end">
						<Grid item xs={12} md={12}>
							<Button
								className={classes.button}
								type="submit"
								onClick={handleSubmit}
								variant="contained"
								color="primary"
								disableElevation
								fullWidth
							>
								Continuar
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};
