/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { addMessage } from '../../store/alert';
import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import config from 'react-global-configuration';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import TransferenciaAgendada from './TransferenciaAgendada';
import ZModalConfirm from '../system/ZModalConfirm';
const useStyles = makeStyles(theme => ({
	header: {
		fontWeight: 700,
		fontSize: 20,
		margin: 8,
	},
	subheader: {
		fontWeight: 700,
		fontSize: 17,
		margin: 4,
	},
	containerCard: {
		minHeight: 230,
	},
	fieldLabel: {
		marginBottom: 0,
		lineHeight: 1.66,
	},
	field: {
		padding: 5,
	},
	lineInfo: {
		marginBottom: 7,
	},
	label: {
		fontSize: 11,
		textTransform: 'uppercase',
		fontWeight: 700,
	},
	valor: {
		fontSize: 18,
		fontWeight: 700,
	},
	paper: {
		padding: 15,
	},
	tabPanel: {
		overflow: 'hidden',
	},
}));

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	};
}

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			<Box style={{ paddingTop: 10, paddingBottom: 10 }}>{children}</Box>
		</Typography>
	);
}

export default props => {
	// const { match } = useReactRouter();
	const token = useSelector(store => store.auth.token);
	const classes = useStyles();
	const dispatch = useDispatch();
	const [transferencias, setTransferencias] = useState(null);
	const [transferenciasBancarias, setTransferenciasBancarias] = useState(null);
	const [value, setValue] = React.useState(0);

	const [removerAgendamento, setRemoverAgendamento] = useState({
		agendamentoId: null,
		open: false,
		tipoId: null,
	});

	function handleChange(event, newValue) {
		setValue(newValue);
	}

	function handleChangeIndex(index) {
		setValue(index);
	}

	useEffect(() => {
		const getTransferencia = async () => {
			const res = await axios.get(`${config.get('apiUrl')}transferencias/agendadas/1`, {
				headers: { Authorization: `Bearer ${token}` },
			});

			if (res.data.success) {
				setTransferencias(res.data.data);
			}
		};

		getTransferencia();
	}, []);

	const getData = async value => {
		const res = await axios.get(`${config.get('apiUrl')}transferencias/agendadas/` + value, {
			headers: { Authorization: `Bearer ${token}` },
		});

		if (res.data.success && value === 1) {
			setTransferencias(res.data.data);
		}

		if (res.data.success && value === 2) {
			setTransferenciasBancarias(res.data.data);
		}
	};

	const handleRemoverAgendamento = async result => {
		if (result) {
			const res = await axios.delete(
				`${config.get('apiUrl')}transferencias/` + removerAgendamento.agendamentoId,
				{
					headers: { Authorization: `Bearer ${token}` },
				}
			);

			if (res.data.success) {
				dispatch(addMessage({ type: 'success', message: res.data.message }));
				getData(removerAgendamento.tipoId);

				setRemoverAgendamento({ ...removerAgendamento, open: false, agendamentoId: null, tipoId: null });
			} else {
				dispatch(addMessage({ type: 'error', message: res.data.message }));
			}
		} else {
			setRemoverAgendamento({ ...removerAgendamento, open: false, agendamentoId: null, tipoId: null });
		}
	};
	console.log('transferencias', transferencias);
	return (
		<div className={classes.transferencia}>
			<Grid
				container
				direction="row"
				justifyContent="flex-start"
				alignItems="flex-start"
				// style={{height:'calc(100vh - 200px)'}}
				spacing={3}
			>
				<Grid item xs={12}>
					<Typography variant="h5" gutterBottom>
						Transferências agendadas
					</Typography>
				</Grid>

				<Grid item xs={12}>
					<AppBar position="static" color="default">
						<Tabs
							value={value}
							onChange={handleChange}
							variant="fullWidth"
							aria-label="full width tabs example"
						>
							<Tab label="P2P" onClick={() => getData(1)} {...a11yProps(0)} />
							<Tab label="Bancária" onClick={() => getData(2)} {...a11yProps(1)} />
						</Tabs>
					</AppBar>
					<SwipeableViews axis={'x-reverse'} index={value} onChangeIndex={handleChangeIndex}>
						<TabPanel value={value} index={0} dir={'x-reverse'} className={classes.tabPanel}>
							{transferencias === null || !transferencias || transferencias.length < 1 ? (
								<Paper className={classes.paper}>
									<Grid item align="center" xs={12}>
										<h4> Não há transferências agendadas.</h4>
									</Grid>
								</Paper>
							) : (
								<Grid container spacing={3}>
									<>
										{transferencias.map((value, key) => {
											return (
												<TransferenciaAgendada
													handleRemover={value => {
														setRemoverAgendamento({
															agendamentoId: value.id,
															open: true,
															tipoId: value.tipoId,
														});
													}}
													value={value}
													key={key}
												/>
											);
										})}
									</>
								</Grid>
							)}
						</TabPanel>
						<TabPanel value={value} index={1} dir={'x-reverse'} className={classes.tabPanel}>
							{transferenciasBancarias === null ||
							!transferenciasBancarias ||
							transferenciasBancarias.length < 1 ? (
								<Grid item align="center" xs={12}>
									<Paper className={classes.paper}>
										<h4> Não há transferências agendadas.</h4>
									</Paper>
								</Grid>
							) : (
								<Grid container spacing={3}>
									{transferenciasBancarias.map((value, key) => {
										return (
											<TransferenciaAgendada
												handleRemover={value => {
													setRemoverAgendamento({
														agendamentoId: value.id,
														open: true,
														tipoId: value.tipoId,
													});
												}}
												value={value}
												key={key}
											/>
										);
									})}
								</Grid>
							)}
						</TabPanel>
					</SwipeableViews>
				</Grid>
			</Grid>
			<ZModalConfirm
				title={`Remover Cliente`}
				message={`Deseja remover esse agendamento?`}
				open={removerAgendamento.open}
				onClose={handleRemoverAgendamento}
			/>
		</div>
	);
};
