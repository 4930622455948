/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, memo } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import yellow from '@material-ui/core/colors/yellow';
import axios from 'axios';
import config from 'react-global-configuration';
import format from 'date-fns/format';
import pt from 'date-fns/locale/pt';
import { Formatter, Isset } from '../../../utils';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import { FaCheckCircle, FaTimesCircle, FaExclamationCircle, FaInfoCircle } from 'react-icons/fa';
import ZLoader from '../../system/ZLoader';
import { useHistory } from 'react-router-dom';

const getTransferenciaIcon = statusId => {
	switch (+statusId) {
		case 3:
			return <FaCheckCircle style={{ color: green[500] }} />;
		case 4:
			return <FaTimesCircle style={{ color: red[700] }} />;
		case 1:
		case 2:
		default:
			return <FaExclamationCircle style={{ color: yellow[700] }} />;
	}
};

const useStyles = makeStyles(theme => ({
	dashboard: {
		padding: 10,
	},
	box: {
		position: 'relative',
		padding: 10,
		height: '100%',
	},
	boxTitle: {
		fontWeight: 700,
		fontSize: 14,
		margin: 4,
	},
	boxFiltro: {
		position: 'absolute',
		top: 10,
		right: 5,
	},
	extrato: {
		height: 270,
	},
	extratoChart: {
		width: '100%',
		height: 160,
	},
	lancamentosChart: {
		width: '100%',
		height: 230,
	},
	extratoIO: {
		marginTop: 10,
		marginBottom: 20,
	},
	extratoFooter: {
		position: 'absolute',
		bottom: 4,
		right: 10,
		textAlign: 'right',
		cursor: 'pointer',
		'&:hover': {
			fontWeight: '700',
		},
	},
	progress: {
		height: 6,
		borderRadius: 6,
	},
	progressEntrada: {
		backgroundColor: `#ea606187`,
		'& .MuiLinearProgress-bar': {
			backgroundColor: `#ea6061FF`,
		},
	},
	progressSaida: {
		backgroundColor: `#00579787`,
		'& .MuiLinearProgress-bar': {
			backgroundColor: `#005797FF`,
		},
	},
	vendas: {
		minHeight: 435,
	},
	btnUltimasVendas: {
		[theme.breakpoints.down('md')]: {
			marginTop: 35,
		},
	},
	vendasChart: {
		width: '100%',
		height: 280,
		marginBottom: 10,
		[theme.breakpoints.up('md')]: {
			width: 300,
		},
	},
	webOnly: {
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'block',
		},
	},
	gridHeader: {
		fontWeight: 700,
		color: 'rgba(0,0,0,0.4)',
		height: 30,
	},
	ultimasVendas: {
		maxHeight: 300,
		overflowY: 'auto',
	},
	ultimasVendasFooter: {
		// position: 'absolute',
		bottom: 4,
		marginTop: 10,
		textAlign: 'right',
		cursor: 'pointer',
		'&:hover': {
			fontWeight: '700',
		},
	},
	ultimasTransferencias: {
		maxHeight: 210,
		overflowY: 'auto',
		marginBottom: 25,
	},
	ultimasTransferenciasFooter: {
		position: 'absolute',
		bottom: 4,
		right: 10,
		textAlign: 'right',
		cursor: 'pointer',
		'&:hover': {
			fontWeight: '700',
		},
	},
	tableDateRow: {
		fontWeight: 700,
		height: 30,
		display: 'flex',
		alignItems: 'center',
		borderRadius: 10,
		backgroundColor: `${theme.palette.primary.main}67`,
		paddingLeft: 20,
	},
	tableRow: {
		textAlign: 'center',
		fontWeight: 700,
		height: 30,
		display: 'flex',
		alignItems: 'center',
		borderRadius: 20,
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}2b`,
		},
	},
	tipoPagamentoIcon: {
		color: theme.palette.primary.main,
		fontSize: 20,
	},
	empty: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: 18,
		fontWeight: 700,
	},
	extratoEmpty: {
		height: 120,
	},
	vendasEmpty: {
		height: '100%',
	},
	lancamentosEmpty: {
		height: 230,
	},
	transferenciasEmpty: {
		height: '100%',
	},
	transferenciaDe: {
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
	transferenciaPara: {
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		textAlign: 'left',
		overflow: 'hidden',
	},
	hide: {
		display: 'none !important',
	},
}));

export default memo(props => {
	const history = useHistory();

	const classes = useStyles();
	const token = useSelector(store => store.auth.token);

	const [loading, setLoading] = useState(true);
	const [ultimasTransferencias, setUltimasTransferencias] = useState([]);

	useEffect(() => {
		const getUltimasTransferencias = async () => {
			setLoading(true);
			const transferencias = await axios.get(`${config.get('apiUrl')}dashboard/transferencias`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
				params: {
					limit: 20,
					filters: {
						estabelecimentoId: props.estabelecimentoId,
					},
				},
			});

			if (transferencias.data.success) {
				const result = [];
				let lastDate = null;
				transferencias.data.transferencias.forEach(v => {
					const date = format(v.created, 'YYYY-MM-DD');
					if (lastDate !== date) {
						lastDate = date;
						result.push({ type: 'date', date });
					}
					let para = '';
					if (Isset(() => v.ToEstabelecimento.nome_fantasia)) {
						para = v.ToEstabelecimento.nome_fantasia;
					} else if (Isset(() => v.conta_bancaria.nome_titular)) {
						para = v.conta_bancaria.nome_titular;
					}
					result.push({
						type: 'row',
						id: v.id,
						created: v.created,
						descricao: v.descricao,
						statusId: v.status_transferencia_id,
						valor: v.valor,
						de: v.FromEstabelecimento.nome_fantasia,
						status: v.status_transferencia.titulo,
						para,
					});
				});
				setUltimasTransferencias(result);
			}
			setLoading(false);
		};

		getUltimasTransferencias();
	}, [props.estabelecimentoId]);

	const viewTransferencia = transferenciaId => history.push(`/transferencias/detalhes/${transferenciaId}`);

	return (
		<Paper className={clsx(classes.box)}>
			<div className={classes.boxTitle}>Últimas Transferências</div>
			{loading ? (
				<ZLoader height={150} size={50} />
			) : (
				<>
					{ultimasTransferencias.length > 0 ? (
						<Grid container className={classes.ultimasTransferencias}>
							{ultimasTransferencias.map((transferencia, key) => (
								<React.Fragment key={key}>
									{transferencia.type === 'date' ? (
										<Grid container item className={classes.tableDateRow}>
											<Grid item xs>
												{format(transferencia.date, 'DD [de] MMMM [de] YYYY', {
													locale: pt,
												})}
											</Grid>
										</Grid>
									) : (
										<Grid
											container
											item
											className={classes.tableRow}
											onClick={() => viewTransferencia(transferencia.id)}
										>
											<Grid item xs={2}>
												{format(transferencia.created, 'HH:mm', { locale: pt })}
											</Grid>
											<Grid item xs={6} className={classes.transferenciaPara}>
												{transferencia.para}
											</Grid>
											<Grid item xs={3}>
												{Formatter.Real(transferencia.valor)}
											</Grid>
											<Grid item xs={1} style={{ display: 'flex' }}>
												<Tooltip
													title={transferencia.status}
													placement="bottom"
													style={{ marginRight: 4 }}
												>
													<div>{getTransferenciaIcon(transferencia.statusId)}</div>
												</Tooltip>
												{transferencia.descricao && (
													<Tooltip title={transferencia.descricao} placement="bottom">
														<div>
															<FaInfoCircle />
														</div>
													</Tooltip>
												)}
											</Grid>
										</Grid>
									)}
								</React.Fragment>
							))}
						</Grid>
					) : (
						<Grid
							container
							direction="row"
							justifyContent="center"
							alignItems="center"
							style={{ height: '300px' }}
						>
							<Grid item lg={6}>
								<h3>Nenhuma Transferência Encontrada.</h3>
							</Grid>
						</Grid>
					)}
				</>
			)}
			<div className={classes.ultimasTransferenciasFooter} onClick={() => history.push('/transferencias')}>
				<Button size="small">Ver todas as transferências</Button>
			</div>
		</Paper>
	);
});
