/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from 'react-global-configuration';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import ZLoader from '../system/ZLoader';
import Zoom from '@material-ui/core/Zoom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import { FaEdit, FaRegTrashAlt } from 'react-icons/fa';

import { Formatter } from '../../utils';
import { Tooltip } from '@material-ui/core';
import EditarPos from './EditarPos';
import ExcluirPos from './ExcluirPos';

const useStyles = makeStyles(theme => ({
	habilitarPos: {
		width: '40%',
		margin: 'auto',
		textAlign: 'center',
		marginTop: 10,
		marginBottom: 10,
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	btnHabilitarPos: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.getContrastText(theme.palette.primary.main),
		fontWeight: 700,
	},
	btnCancel: {
		fontWeight: 700,
		backgroundColor: '#e53935',
		color: 'white',
		fontSize: 11,
		height: 36,
		marginLeft: 10,
	},
	tableHeader: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.getContrastText(theme.palette.primary.main),
		fontWeight: 700,
	},
	pos: {
		width: '60%',
		margin: 'auto',
		textAlign: 'center',
		borderWidth: 1,
		borderColor: theme.palette.primary.main,
		borderStyle: 'solid',
		borderRadius: 5,
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	posRow: {
		borderTop: `solid 1px ${theme.palette.primary.main}`,
	},
	avisoPOS: {
		backgroundColor: '#fff9c4',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: 50,
		fontWeight: 'bold',
	},
	editBtn: {
		cursor: 'pointer',
		fontSize: 16,
	},
}));

export default props => {
	const classes = useStyles();

	const { estabelecimentoId } = props;

	const token = useSelector(store => store.auth.token);

	const [loading, setLoading] = useState(true);
	const [update, setUpdate] = useState(false);
	const [sending, setSending] = useState(false);
	const [showPos, setShowPos] = useState(false);
	const [pos, setPos] = useState([]);
	const [tokenPos, setTokenPos] = useState('');
	const [tokenPosError, setTokenPosError] = useState(false);

	const [showEditarPos, setShowEditarPos] = useState(false);
	const [showExcluirPos, setShowExcluirPos] = useState(false);
	const [posId, setPosId] = useState(null);

	useEffect(() => {
		if (update) {
			const getPos = async () => {
				let res = await axios.get(`${config.get('apiUrl')}estabelecimentos/${estabelecimentoId}/pos`, {
					headers: { Authorization: `Bearer ${token}` },
				});

				if (res.data.success) {
					setPos(res.data.pos);
				}

				setLoading(false);
			};

			getPos();
			setUpdate(false);
		}
	}, [update]);

	useEffect(() => {
		setUpdate(true);
	}, []);

	const habilitarPos = async () => {
		if (!tokenPos) {
			setTokenPosError(true);
			return;
		}

		setTokenPosError(false);

		setSending(true);

		let res = await axios.post(
			`${config.get('apiUrl')}estabelecimentos/${estabelecimentoId}/habilitar_pos/${tokenPos}`,
			{},
			{
				headers: { Authorization: `Bearer ${token}` },
			}
		);

		if (res.data.success) {
			setShowPos(false);
			setUpdate(true);
			setTokenPos('');
		} else {
			setTokenPosError(true);
		}

		setSending(false);
	};

	return (
		<Zoom in>
			<>
				<Grid className={classes.habilitarPos} justifyContent="center" container>
					{!showPos ? (
						<>
							<Grid xs item>
								<Button className={classes.btnHabilitarPos} onClick={() => setShowPos(true)}>
									Habilitar POS
								</Button>
							</Grid>
							<Grid xs={12} item style={{ marginTop: 10 }}>
								<Paper className={classes.avisoPOS}>
									Para finalizar a habilitação de uma POS, efetue uma venda na mesma.
								</Paper>
							</Grid>
						</>
					) : (
						<>
							<Grid xs item>
								<TextField
									error={tokenPosError}
									label={false}
									placeholder="Token"
									value={tokenPos}
									onChange={event => setTokenPos(event.target.value)}
								/>
							</Grid>
							<Grid xs item>
								<Button disabled={sending} className={classes.btnHabilitarPos} onClick={habilitarPos}>
									Salvar
								</Button>
								<Button className={classes.btnCancel} onClick={() => setShowPos(false)}>
									Cancelar
								</Button>
							</Grid>
						</>
					)}
				</Grid>

				{loading ? (
					<ZLoader height={200} size={50} />
				) : (
					<Grid className={classes.pos} container spacing={2}>
						<Grid className={classes.tableHeader} item container>
							<Grid xs={4} item>
								Identificação
							</Grid>
							<Grid xs={3} item>
								{/* Total de Vendas */}
							</Grid>
							<Grid xs={2} item>
								Serial
							</Grid>
							<Grid xs={2} item>
								Chip
							</Grid>
							<Grid xs={1} item></Grid>
						</Grid>
						{pos.map((p, i) => (
							<React.Fragment key={i}>
								{p.id !== null && (
									<Grid className={classes.posRow} item container>
										<Grid xs={4} item>
											{p.identification_number}
										</Grid>
										<Grid xs={3} item>
											{/* {Formatter.Real(p.valorTotalVendas)} */}
										</Grid>
										<Grid xs={2} item>
											{p.serial}
										</Grid>
										<Grid xs={2} item>
											{p.chip}
										</Grid>
										<Grid xs={1} item>
											<Tooltip title="Editar">
												<div>
													<FaEdit
														className={classes.editBtn}
														onClick={() => {
															setShowEditarPos(true);
															setPosId(p.id);
														}}
													/>
												</div>
											</Tooltip>
											<Tooltip title="Excluir">
												<div>
													<FaRegTrashAlt
														className={classes.editBtn}
														onClick={() => {
															setShowExcluirPos(true);
															setPosId(p.id);
														}}
													/>
												</div>
											</Tooltip>
										</Grid>
									</Grid>
								)}
							</React.Fragment>
						))}
					</Grid>
				)}
				{showEditarPos && (
					<EditarPos
						open={showEditarPos}
						onClose={() => {
							setUpdate(true);
							setShowEditarPos(false);
						}}
						posId={posId}
					/>
				)}
				{showExcluirPos && (
					<ExcluirPos
						open={showExcluirPos}
						onClose={() => {
							setShowExcluirPos(false);
						}}
						posId={posId}
					/>
				)}
			</>
		</Zoom>
	);
};
