/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
// import clsx from 'clsx';
import { useTheme } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
// import useReactRouter from 'use-react-router';
import { updateSaldo } from '../../store/system';
import { addMessage } from '../../store/alert';
import ZLoader from '../system/ZLoader';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
// import Switch from '@material-ui/core/Switch';
import { Mask, Isset, Formatter } from '../../utils';
import { Paper } from '@material-ui/core';
import axios from 'axios';
import config from 'react-global-configuration';
// import ZDatePicker from '../system/ZDatePicker';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { FilePond } from 'react-filepond';
// import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
// import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
// import FilePondPluginFilePoster from 'filepond-plugin-file-poster';

const useStyles = makeStyles(theme => ({
	field: {
		padding: 5,
		textAlign: 'center',
	},
	label: {
		fontSize: 11,
		textTransform: 'uppercase',
		fontWeight: 700,
	},
	content: {
		[theme.breakpoints.up('sm')]: {
			width: 400,
		},
	},
	fullWidth: {
		width: '100%',
	},
	saldoBox: {
		backgroundColor: '#616161',
		color: 'white',
		margin: -10,
		marginBottom: 10,
		padding: '5px 15px',
	},
	transferencia: {},
	formaTransferencia: {
		padding: 16,
		fontSize: 14,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontWeight: 700,
		color: theme.palette.primary.main,
		border: `solid 1px ${theme.palette.primary.main}`,
		borderRadius: 6,
		cursor: 'pointer',
		'&.selected': {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.getContrastText(theme.palette.primary.main),
		},
	},
	contaBancaria: {
		margin: 8,
		marginTop: 10,
		padding: 4,
		fontSize: 14,
		fontWeight: 700,
		color: theme.palette.primary.main,
		border: `solid 1px ${theme.palette.primary.main}`,
		borderRadius: 6,
		cursor: 'pointer',
		'&.selected': {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.getContrastText(theme.palette.primary.main),
		},
	},
	error: {
		fontSize: 13,
		textTransform: 'uppercase',
		fontWeight: 700,
		color: '#f44336',
	},
	errorLink: {
		color: '#f44310',
		cursor: 'pointer',
	},
}));

var minDate = new Date();
minDate.setDate(minDate.getDate() + 1);

const initialState = {
	valor: 0,
	codigoBarras: null,
	descricao: '',
	senha: '',
	arquivo: null,
};

export default props => {
	const classes = useStyles();
	const dispatch = useDispatch();
	// const { history } = useReactRouter();

	const token = useSelector(state => state.auth.token);
	const estabelecimento = useSelector(state => state.auth.estabelecimento);
	const estabelecimentoAdmin = useSelector(state => state.admin.estabelecimento);
	const [open, setOpen] = useState(props.open || false);
	const [sending, setSending] = useState(false);
	// const [contasBancarias, setContasBancarias] = useState([]);
	const [data, setData] = useState(initialState);
	const [arquivo, setArquivo] = useState({ arquivo: null });
	const [switcher] = useState(false);
	// const [loadingContaBancaria, setLoadingContaBancaria] = useState(false);

	const estabelecimentoId = Isset(() => estabelecimentoAdmin.id) ? estabelecimentoAdmin.id : estabelecimento.id;

	const [errors, setErrors] = useState({});
	const [saldo, setSaldo] = useState({
		atual: 0,
		futuro: 0,
	});
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

	const validate = () => {
		let e = {};

		if (!data.valor) {
			e.valor = true;
			dispatch(addMessage({ type: 'error', message: 'Campo valor do boleto obrigatório' }));
		} else if (!switcher && data.valor > saldo.atual) {
			e.valorMessage = 'Valor maior que o saldo atual.';
		}

		// if (data.formaTransferencia === 1) {
		// 	if (!data.estabelecimento.id) {
		// 		e.cpfcnpj = true;
		// 	}
		// } else if (data.formaTransferencia === 2) {
		// 	if (!data.contaBancariaId) {
		// 		e.contaBancaria = true;
		// 	}
		// }

		if (!arquivo.arquivo) {
			e.arquivo = true;
			e.arquivoMessage = 'Arquivo inválido';
			dispatch(addMessage({ type: 'error', message: 'Arquivo inválido' }));
		}

		if (!data.senha) {
			e.senha = true;
			dispatch(addMessage({ type: 'error', message: 'Campo senha obrigatório' }));
		}

		setErrors(e);

		return Object.keys(e).length > 0;
	};

	const handleClose = () => {
		// setOpen(false);
		if (props.onClose) {
			props.onClose();
		}
	};

	useEffect(() => {
		setOpen(props.open);
		const getSaldo = async () => {
			const res = await axios.get(`${config.get('apiUrl')}estabelecimentos/${estabelecimentoId}/saldo`, {
				headers: { Authorization: `Bearer ${token}` },
			});

			if (res.data.success) {
				setSaldo({
					...saldo,
					...res.data.saldo,
				});
			}
		};

		getSaldo();
	}, [props.open]);

	const handleSubmit = async () => {
		if (!validate()) {
			setSending(true);

			const formData = new FormData();
			const file = !Array.isArray(arquivo.arquivo) ? false : arquivo.arquivo;

			let dataServicoBancarioBoleto = data;
			// delete dataServicoBancarioBoleto.data.arquivo;
			// delete dataServicoBancarioBoleto.arquivo;

			for (let key in dataServicoBancarioBoleto) {
				if (typeof dataServicoBancarioBoleto[key] === 'object') {
					for (let key2 in dataServicoBancarioBoleto[key]) {
						if (typeof dataServicoBancarioBoleto[key][key2] === 'object') {
							for (let key3 in dataServicoBancarioBoleto[key][key2]) {
								formData.append(`${key}[${key2}][${key3}]`, dataServicoBancarioBoleto[key][key2][key3]);
							}
						} else {
							formData.append(`${key}[${key2}]`, dataServicoBancarioBoleto[key][key2]);
						}
					}
				} else {
					formData.append(key, dataServicoBancarioBoleto[key]);
				}
			}

			// formData.append('data[status]', status);
			// formData.append('data[message]', message);

			if (file) {
				file.forEach(a => {
					// if (!a.type.match('image.*')) {
					// 	return;
					// }
					formData.append('arquivo[]', a, a.name);
				});
			}

			const res = await axios.post(`${config.get('apiUrl')}servicos-bancarios/pagamentos-boletos`, formData, {
				headers: { Authorization: `Bearer ${token}` },
			});

			if (res.data.success) {
				dispatch(addMessage({ type: 'success', message: 'Operação realizada com sucesso!' }));
				dispatch(updateSaldo(true));
				if (props.onSuccess) {
					props.onSuccess();
					setData(initialState);
				}
				handleClose();
			} else {
				dispatch(addMessage({ type: 'error', message: res.data.message }));
				if (props.onError) {
					props.onError();
				}
			}

			setSending(false);
		}
	};

	useEffect(() => {
		const getSaldo = async () => {
			const res = await axios.get(`${config.get('apiUrl')}estabelecimentos/${estabelecimentoId}/saldo`, {
				headers: { Authorization: `Bearer ${token}` },
			});

			if (res.data.success) {
				setSaldo({
					...saldo,
					...res.data.saldo,
				});
			}
		};

		getSaldo();
	}, []);

	return (
		<div className={classes.transferencia}>
			<Dialog open={open} fullScreen={fullScreen} onClose={handleClose}>
				<DialogContent className={classes.content}>
					<Paper className={classes.saldoBox}>
						<div className={classes.saldoAtual}>Saldo Atual: {Formatter.Real(saldo.atual)} </div>
						{/* <div className={classes.saldoFuturo}>Saldo Futuro: {Formatter.Real(saldo.futuro)} </div> */}
					</Paper>

					<Grid container justifyContent="center" spacing={2} style={{ marginTop: 5 }}>
						<Grid item xs={12}>
							<TextField
								className={classes.fullWidth}
								error={errors.valor}
								label="Valor do boleto"
								value={Mask.Real(data.valor)}
								onChange={e => setData({ ...data, valor: Mask.OnlyNumber(e.target.value) / 100 })}
							/>
						</Grid>
						{errors.valorMessage && (
							<Grid item xs={12} style={{ textAlign: 'center' }}>
								<div className={classes.error}>{errors.valorMessage}</div>
							</Grid>
						)}

						<Grid item xs={12} align="center" style={{ marginTop: 20 }}>
							Dados do boleto
						</Grid>

						<Grid item xs={12} style={{ marginTop: 4 }}>
							<TextField
								className={classes.fullWidth}
								label="Código de barras"
								value={data.codigoBarras}
								onChange={e => setData({ ...data, codigoBarras: e.target.value })}
							/>
						</Grid>

						<Grid item xs={12} style={{ marginTop: 4 }}>
							<TextField
								className={classes.fullWidth}
								label="Descrição do pagamento"
								value={data.descricao}
								onChange={e => setData({ ...data, descricao: e.target.value })}
							/>
						</Grid>
						<Grid item xs={12} style={{ marginTop: 2 }}>
							<div className={classes.configHeader}>Arquivo/Boleto</div>
							<FilePond
								server={{
									process: null,
								}}
								allowMultiple
								labelIdle='Arraste e solte seus arquivos aqui ou <span class="filepond--label-action">Selecione</span>'
								files={arquivo.arquivo}
								onupdatefiles={fileItems => {
									setArquivo({
										// ...data,
										arquivo: fileItems.map(f => f.file),
									});
								}}
							/>
						</Grid>
						<Grid item xs={12} align="center" style={{ marginTop: 25 }}>
							Informe sua senha para concluir
						</Grid>
						<Grid item xs={12} style={{ marginTop: 0 }}>
							<TextField
								className={classes.fullWidth}
								error={errors.senha}
								type="password"
								label="Senha"
								inputProps={{
									autoComplete: 'new-password',
									form: {
										autoComplete: 'off',
									},
								}}
								value={data.senha}
								onChange={e => setData({ ...data, senha: e.target.value })}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					{sending ? (
						<ZLoader size={30} height={60} />
					) : (
						<React.Fragment>
							<Button onClick={handleClose} color="primary">
								Cancelar
							</Button>
							<Button onClick={handleSubmit} color="primary" autoFocus>
								Concluir
							</Button>
						</React.Fragment>
					)}
				</DialogActions>
			</Dialog>
		</div>
	);
};
