import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CurrencyInput from 'react-currency-input';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import { SelectEstabelecimento } from '../../Estabelecimentos/SelectEstabelecimento';
import CancelIcon from '@material-ui/icons/Cancel';
import Check from '@material-ui/icons/Check';
import { useEstabelecimento } from '../../../hooks/useEstabelecimento';
import ZCurrency from '../../system/ZCurrency';
import { Mask, Isset, Formatter } from '../../../utils';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import config from 'react-global-configuration';
import { useSelector, useDispatch } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import ZLoader from '../../system/ZLoader';

const useStyles = makeStyles(theme => ({
	titulo: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		fontWeight: 'bold',
		paddingTop: 20,
	},
	valor: {
		flexBasis: '50%',
	},
	lista: {
		display: 'flex',
		alignItems: 'center',
		paddingTop: 20,
	},
	input: {
		border: 'none',
		borderBottom: '1px solid #000',
		width: '100%',
	},
	inputValor: {
		border: 'none',
		borderBottom: '1px solid #000',
		width: '70%',
		display: 'flex',
		alignItems: 'center',
		textAlign: 'center',
		// paddingTop: 12,
	},
	barraVertical: {
		borderLeft: '1px solid #ccc', // Barra vertical
		height: '20px', // Altura da barra vertical
		margin: '0 5px', // Margem entre os campos
		backgroundColor: '#f0f0f0', // Cor de fundo cinza
	},
	addButton: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		paddingTop: 5,
	},
	deleteButton: {
		padding: 0,
		paddingTop: 5,
	},
	errorIcon: {
		fontSize: '20px', // Tamanho do ícone
		color: '#ff0000',
	},
	successIcon: {
		fontSize: '20px', // Tamanho do ícone
		color: '#10ff00',
	},
	error: {
		fontSize: 13,
		textTransform: 'uppercase',
		fontWeight: 700,
		color: '#f44336',
	},
	loaderMsg: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		paddingTop: 3,

		fontSize: '16px',
	},
	// descricao: {
	// 	width: '90%',
	// },
}));

export default function MultiplasTransferencias(props) {
	const {
		setValoresInseridos,
		setEstabelecimentosTransferencias,
		estabelecimentosTransferencias,
		error,
		switcherTodosEcs,
		finished,
		sending,
	} = props;
	const classes = useStyles();
	const token = useSelector(store => store.auth.token);
	// const axiosCancelSource = axios.CancelToken.source();
	const usuario = useSelector(store => store.auth.usuario);
	const [loading, setLoading] = useState(true);

	// const isSandBox = usuario.estabelecimento.marketplace.id === 3;
	const is7pay = usuario.estabelecimento.marketplace.id === 60;
	const addInput = ec => {
		const newId = estabelecimentosTransferencias.length + 1;
		setEstabelecimentosTransferencias([
			...estabelecimentosTransferencias,
			{ id: newId, estabelecimento: ec ? ec : null, value: null },
		]);
	};
	const removeInput = id => {
		const updatedInputs = estabelecimentosTransferencias.filter(input => input.id !== id);
		setEstabelecimentosTransferencias(updatedInputs);
	};
	const updateArrayItem = (index, newValue, field) => {
		const updatedArray = [...estabelecimentosTransferencias];
		updatedArray[index - 1] = {
			...estabelecimentosTransferencias[index - 1],
			[field]: newValue,
		};
		setEstabelecimentosTransferencias(updatedArray);
	};

	useEffect(() => {
		const getEcs = async () => {
			setLoading(true);
			let res = await axios.get(`${config.get('apiUrl')}estabelecimentos/filhos`, {
				headers: { Authorization: `Bearer ${token}` },
				params: {
					limit: 50,
				},
			});
			if (res.data.success && is7pay) {
				const estabelecimentos = res.data.estabelecimentos.map((ec, index) => {
					return {
						id: index + 1,
						estabelecimento: {
							id: ec.id,
							nomeFantasia: ec.nome_fantasia
								? ec.nome_fantasia
								: ec.estabelecimentos_documentos[0].documento,
							documento: ec.estabelecimentos_documentos[0].documento,
						},
					};
				});
				setEstabelecimentosTransferencias(estabelecimentos);
			}
			setLoading(false);
		};
		if (switcherTodosEcs) {
			console.log(switcherTodosEcs);
			getEcs();
		}
	}, [switcherTodosEcs]);

	useEffect(() => {
		const sum = estabelecimentosTransferencias.reduce((total, item) => {
			return total + (item.value || 0);
		}, 0);
		setValoresInseridos(sum);
	}, [estabelecimentosTransferencias, setValoresInseridos]);

	// if (!estabelecimentosTransferencias.length) {
	// 	addInput();
	// }
	if (loading) {
		return (
			<>
				<ZLoader height={200} />
				<p className={classes.loaderMsg}>Carregando estabelecimentos...</p>
			</>
		);
	}

	return (
		<>
			<div className={classes.titulo}>
				<div>
					<label>Estabelecimento</label>
				</div>
				<div className={classes.valor}>
					<label>Valor</label>
				</div>
			</div>
			{estabelecimentosTransferencias.length > 0 &&
				estabelecimentosTransferencias.map(input => {
					return (
						<div className={classes.lista} key={input.id}>
							{!is7pay && (
								<SelectEstabelecimento
									setEstabelecimento={estabelecimento => {
										updateArrayItem(input.id, estabelecimento, 'estabelecimento');
									}}
								/>
							)}
							{is7pay && (
								<TextField
									disabled={true}
									className={classes.inputValor}
									// label="Estabelecimento"
									value={input.estabelecimento ? input.estabelecimento.nomeFantasia : input.id}
									// onChange={e => setData({ ...data, descricao: e.target.value })}
								/>
							)}
							<div className={classes.barraVertical}></div>

							<ZCurrency
								className={classes.inputValor}
								value={estabelecimentosTransferencias.value}
								onBlur={e => updateArrayItem(input.id, Mask.OnlyNumber(e.target.value) / 100, 'value')}
							/>
							<div className={classes.deleteButton}>
								{finished.includes(input.id) ? (
									<Check className={classes.successIcon} />
								) : (
									<CancelIcon className={classes.errorIcon} />
								)}
							</div>
						</div>
					);
				})}
			{error.valorMessage && (
				<Grid item xs={12} style={{ textAlign: 'center' }}>
					<div className={classes.error}>{error.valorMessage}</div>
				</Grid>
			)}
			{!is7pay && (
				<div className={classes.addButton}>
					<IconButton color="primary" onClick={addInput}>
						<AddIcon />
					</IconButton>
				</div>
			)}
		</>
	);
}
