/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, TextField } from '@material-ui/core';
import axios from 'axios';
import config from 'react-global-configuration';
import { makeStyles } from '@material-ui/core/styles';
import { addMessage } from '../../store/alert';
import ZLoader from '../system/ZLoader';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
	fullWidth: {
		width: '100%',
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightRegular,
	},
	btn: {
		marginTop: 20,
	},
}));

export default props => {
	const { posId } = props;
	const classes = useStyles();
	const dispatch = useDispatch();
	const token = useSelector(store => store.auth.token);
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState({
		serial: '',
		chip: '',
		operadora: '',
		observacao: '',
	});
	const [observacoes, setObservacoes] = useState();

	useEffect(() => {
		const getPos = async () => {
			setLoading(true);
			const res = await axios.get(`${config.get('apiUrl')}pos/${posId}`, {
				headers: { Authorization: `Bearer ${token}` },
			});

			if (res.data.success) {
				setData({
					serial: res.data.pos.serial,
					chip: res.data.pos.chip,
					operadora: res.data.pos.operadora,
				});
				setObservacoes(res.data.pos.observacoes);
			}
			setLoading(false);
		};

		getPos();
	}, [posId]);

	const saveBtnClick = async () => {
		const res = await axios.put(`${config.get('apiUrl')}pos/${posId}`, data, {
			headers: { Authorization: `Bearer ${token}` },
		});

		if (res.data.success) {
			props.onClose();
			dispatch(addMessage({ type: 'success', message: 'Operação realizada com sucesso' }));
		} else {
			dispatch(addMessage({ type: 'error', message: res.data.message }));
		}
	};

	return (
		<Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
			<DialogTitle>Editar Pos</DialogTitle>
			<DialogContent>
				{loading ? (
					<ZLoader height={200} message="Carregando" />
				) : (
					<Grid container spacing={2} justifyContent="center">
						<Grid item xs={6}>
							<TextField
								className={classes.fullWidth}
								label="Número Serial"
								value={data.serial}
								onChange={e => setData({ ...data, serial: e.target.value })}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								className={classes.fullWidth}
								label="Número Chip"
								value={data.chip}
								onChange={e => setData({ ...data, chip: e.target.value })}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								className={classes.fullWidth}
								label="Operadora"
								value={data.operadora}
								onChange={e => setData({ ...data, operadora: e.target.value })}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								className={classes.fullWidth}
								label="Observação"
								onChange={e => setData({ ...data, observacao: e.target.value })}
							/>
						</Grid>
						<Grid item container md={12} xs={12}>
							<Accordion className={classes.fullWidth}>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls="panel1a-content"
									id="panel1a-header"
								>
									<Typography className={classes.heading}>Observações</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Grid item container md={12} xs={12}>
										{Array.isArray(observacoes) && observacoes.length > 0 ? (
											<>
												{observacoes.map(observacao => (
													<Grid item md={12} xs={12} style={{ marginTop: 10 }}>
														<Typography>{observacao}</Typography>
													</Grid>
												))}
											</>
										) : (
											<Typography>{data.observacoes}</Typography>
										)}
									</Grid>
								</AccordionDetails>
							</Accordion>
						</Grid>
					</Grid>
				)}
			</DialogContent>
			<DialogActions>
				<Button className={classes.btn} onClick={props.onClose}>
					Cancelar
				</Button>
				<Button className={classes.btn} variant="contained" color="primary" onClick={saveBtnClick}>
					Salvar
				</Button>
			</DialogActions>
		</Dialog>
	);
};
