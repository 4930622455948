/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Grid, Paper, Divider, Typography, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { Formatter } from '../../utils';
import { makeStyles } from '@material-ui/core/styles';

import moment from 'moment';
import {Mask} from '../../utils/';


const useStyles = makeStyles(theme => ({
    header: {
        fontWeight: 700,
        fontSize: 20,
        margin: 8,
    },
    subheader: {
        fontWeight: 700,
        fontSize: 17,
        margin: 4,
    },
    containerCard:{
        minHeight: 230
    },
    fieldLabel: {
        marginBottom:0,
        lineHeight: 1.66
    },
    field: {
        padding: 5,
    },
    lineInfo: {
        marginBottom: 7,
    },
    label: {
        fontSize: 11,
        textTransform: 'uppercase',
        fontWeight: 700,
    },
    valor: {
        fontSize: 18,
        fontWeight: 700,
    },
    paper: {
        padding: 15,
    },
}));

export default props => {
    const classes = useStyles();
    const { value } = props;
    return (
        <Grid item xs={12} sm={6} md={4} lg={3} >
            <Paper className={classes.paper}>
                <Grid container className={classes.containerCard}>
                    <Grid item xs={10}>
                        <Grid container>
                            <Grid item xs={6} >
                                Data:
                                <Typography variant="subtitle2" style={{fontSize:'15px'}} gutterBottom>
                                    <b>{moment(value.agendadoPara).format('DD/MM/YYYY')}</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={6} align={'right'}>
                                Valor:
                                <Typography variant="subtitle2" style={{fontSize:'15px'}} gutterBottom>
                                    <b>{Formatter.Real(value.valor)}</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style={{marginBottom:15,marginTop:10}}>
                                <Divider/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={2} align={'right'}>
                        <Grid style={{position:'relative'}}>
                            <IconButton aria-label="delete" style={{padding:0}} onClick={ () =>{ props.handleRemover(value);} }>
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                        </Grid>
                    </Grid>
                    { value.tipoId === 1 && 
                        <>
                            <Grid className={classes.lineInfo} item xs={12} >
                                <Typography variant="overline" className={classes.fieldLabel} display="block" gutterBottom> 
                                    Nome:
                                </Typography>
                                <b>{value.to.nome}</b>
                            </Grid>
                            <Grid className={classes.lineInfo} item xs={12} >
                                <Typography variant="overline" className={classes.fieldLabel} display="block" gutterBottom> 
                                    E-mail:
                                </Typography>
                                <b>{value.to.email}</b>
                            </Grid>
                            <Grid className={classes.lineInfo} item xs={12} >
                                <Typography variant="overline" className={classes.fieldLabel} display="block" gutterBottom> 
                                    CPF/CNPJ:
                                </Typography>
                                <b>{value.to.documento.length > 11 ? Mask.Cnpj(value.to.documento) : Mask.Cpf(value.to.documento)}</b>
                            </Grid>
                        </>
                    }

                    { value.tipoId === 2 && 
                        <>
                            <Grid className={classes.lineInfo} item xs={12} >
                                <Typography variant="overline" className={classes.fieldLabel} display="block" gutterBottom> 
                                    Banco:
                                </Typography>
                                <b>{value.contaBancaria.banco.nome}</b>
                            </Grid>
                            <Grid className={classes.lineInfo} item xs={12} >
                                <Typography variant="overline" className={classes.fieldLabel} display="block" gutterBottom> 
                                    Titular:
                                </Typography>
                                <b>{value.contaBancaria.nome_titular}</b>
                            </Grid>
                            <Grid className={classes.lineInfo} item xs={12} >
                                <Typography variant="overline" className={classes.fieldLabel} display="block" gutterBottom> 
                                    Tipo de conta:
                                </Typography>
                                <b>{value.contaBancaria.tipo_conta_bancaria.titulo}</b>
                            </Grid>
                            <Grid className={classes.lineInfo} item xs={6} >
                                <Typography variant="overline" className={classes.fieldLabel} display="block" gutterBottom> 
                                    Agencia:
                                </Typography>
                                <b>{value.contaBancaria.agencia}</b>
                            </Grid>
                            <Grid className={classes.lineInfo} item xs={6} >
                                <Typography variant="overline" className={classes.fieldLabel} display="block" gutterBottom> 
                                    Conta:
                                </Typography>
                                <b>{value.contaBancaria.conta}</b>
                            </Grid>
                        </>
                    }
                    
                    <Grid className={classes.lineInfo} item xs={12}>
                        <Typography variant="overline" className={classes.fieldLabel} display="block" gutterBottom> 
                            Descrição:
                        </Typography>
                        <div style={{height:'90px', overflow: 'auto'}}>
                            {value.descricao}
                        </div>
                    </Grid>
                    <Grid item align="center" xs={12}>
                        {/* <Button variant="outlined" size="small" color="default"> */}
                            <small style={{fontSize:10}}>Criado: {moment(value.created).format('DD/MM/YYYY')}</small>
                        {/* </Button> */}
                    </Grid>
                    <Divider/>
                </Grid>
            </Paper>
        </Grid>
    );
}