import React, { useState, useEffect, useMemo } from 'react';
import { Grid, Typography, makeStyles, Paper, Container, Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { FaCopy } from 'react-icons/fa';
import clsx from 'clsx';
import ZTitle from '../system/ZTitle';
import { Mask } from '../../utils';
import { format } from 'date-fns/esm';
import { useSelector } from 'react-redux';
import axios from 'axios';
import config from 'react-global-configuration';
import { useRouteMatch } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
	formaPagamento: {
		padding: 20,
		fontSize: 16,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginBottom: 32,
		fontWeight: 700,
		color: '#333',
		width: 263,
		border: `solid 1px rgb(234, 234, 234)`,
		borderRadius: 8,
		cursor: 'pointer',
		'&.selected': {
			border: `solid 1px ${theme.palette.primary.main}`,
			color: theme.palette.primary.main,
		},
		'&:hover': {
			border: `1px solid ${theme.palette.primary.main}`,
			color: theme.palette.primary.main,
		},
		'&.disabled': {
			color: '#777',
			border: `solid 1px rgb(234, 234, 234)`,
		},
		[theme.breakpoints.down('sm')]: {
			padding: 10,
			marginBottom: 20,
			width: '100%',
		},
	},
	backButton: {
		color: theme.palette.primary.main,
		border: `solid 1px ${theme.palette.primary.main}`,
		width: 171,
		height: 44,
		marginRight: 10,
		'&:hover': {
			color: '#FFF',
			backgroundColor: theme.palette.primary.main,
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	nextButton: {
		width: 171,
		height: 44,
		backgroundColor: theme.palette.primary.main,
		color: '#FFF',
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	stepActions: {
		marginTop: 56,
		textAlign: 'center',
	},
	span: {
		color: '#777',
		lineHeight: 1.5,
	},
}));

export default function PlanosDetalhe() {
	const classes = useStyles();
	const url = useSelector(store => store.system.config.hostname);
	// const { match } = useReactRouter();
	const match = useRouteMatch();

	const [defaultDueDays, setDefaultDueDays] = useState([]);
	const [data, setData] = useState({
		name: '',
		description: '',
		email: '',
		setup_amount: 0.0,
		amount: 0.0,
		grace_period: 0,
		tolerance_period: 0,
		frequency: 'monthly',
		zoop_plano_id: null,
		interval: 1,
		logo: false,
		currency: 'BRL',
		payment_methods: '',
		plan_expiration_date: '',
		has_expiration: false,
		expire_subscriptions: false,
		subscription_duration: '',
		due_days: [],
	});
	const token = useSelector(store => store.auth.token);

	useEffect(() => {
		if (match.params.planoId) {
			axios
				.get(config.get('apiUrl') + 'planos/' + match.params.planoId, {
					headers: { Authorization: `Bearer ${token}` },
				})
				.then(res => {
					if (res.data.success) {
						const due_days = res.data.plano.due_days
							? res.data.plano.due_days.map(days => ({ day: days }))
							: [];
						const hasDueDays = Boolean(due_days.length > 0);
						if (hasDueDays) {
							setDefaultDueDays(due_days);
						}

						setData({
							name: res.data.plano.name,
							description: res.data.plano.description,
							email: res.data.plano.email,
							setup_amount: res.data.plano.setup_amount,
							amount: res.data.plano.amount,
							grace_period: res.data.plano.grace_period,
							tolerance_period: res.data.plano.tolerance_period,
							frequency: res.data.plano.frequency,
							zoop_plano_id: res.data.plano.zoop_plano_id,
							interval: res.data.plano.interval,
							logo: !!res.data.plano.logo,
							currency: res.data.plano.currency,
							payment_methods: res.data.plano.method,
							plan_expiration_date: res.data.plano.plan_expiration_date,
							has_expiration: res.data.plano.plan_expiration_date
								? res.data.plano.plan_expiration_date
								: false,
							expire_subscriptions: res.data.plano.expire_subscriptions,
							subscription_duration: res.data.plano.subscription_duration,
							due_days,
						});
					}
				});
		}
	}, [match.params.planoId, token]);

	const formattedDueDays = useMemo(() => {
		return defaultDueDays.map(({ day }) => day).toString();
	}, [defaultDueDays]);

	const handleFrequency = useMemo(() => {
		const result = {
			frequency: {
				daily: 'diária',
				weekly: 'semanal',
				monthly: 'mensal',
				annually: 'anual',
			},
			interval: {
				daily: 'dias',
				weekly: 'semanas',
				monthly: 'meses',
				annually: 'anos',
			},
		};

		return {
			frequency: result.frequency[data.frequency],
			interval: result.interval[data.frequency],
		};
	}, [data.frequency]);

	return (
		<Container maxWidth="md">
			<Paper style={{ padding: 32 }}>
				<ZTitle title={'Detalhes do Plano'} variant={'h5'} marginTop={8} marginBottom={32} />

				<Grid container spacing={2}>
					<Grid item md={6} xs={12} className={classes.labelResumo}>
						<Typography variant="body1" component="h5" gutterBottom>
							Nome <br /> <span className={classes.span}>{data.name}</span>
						</Typography>
					</Grid>
					<Grid item md={6} xs={12} className={classes.labelResumo}>
						<Typography variant="body1" component="h5" gutterBottom>
							Descrição <br /> <span className={classes.span}>{data.description}</span>
						</Typography>
					</Grid>
					<Grid item md={6} xs={12} className={classes.labelResumo}>
						<Typography variant="body1" component="h5" gutterBottom>
							E-mail <br /> <span className={classes.span}>{data.email}</span>
						</Typography>
					</Grid>
					<Grid item md={6} xs={12} className={classes.labelResumo}>
						<Typography variant="body1" component="h5" gutterBottom>
							Valor <br /> <span className={classes.span}>{Mask.Real(data.amount / 100)}</span>
						</Typography>
					</Grid>
					<Grid item md={6} xs={12} className={classes.labelResumo}>
						<Typography variant="body1" component="h5" gutterBottom>
							Setup Valor <br />{' '}
							<span className={classes.span}>{Mask.Real(data.setup_amount / 100)}</span>
						</Typography>
					</Grid>
					<Grid item md={6} xs={12} className={classes.labelResumo}>
						<Typography variant="body1" component="h5" gutterBottom>
							Frequência <br />{' '}
							<span style={{ textTransform: 'capitalize' }} className={classes.span}>
								{handleFrequency.frequency}
							</span>
						</Typography>
					</Grid>
					<Grid item md={6} xs={12} className={classes.labelResumo}>
						<Typography variant="body1" component="h5" gutterBottom>
							Intervalo <br /> <span className={classes.span}>{data.interval}</span>
						</Typography>
					</Grid>
					<Grid item md={6} xs={12} className={classes.labelResumo}>
						<Typography variant="body1" component="h5" gutterBottom>
							Duração da assinatura <br />{' '}
							<span className={classes.span}>
								{data.subscription_duration ? `${data.subscription_duration} meses` : 'N/A'}
							</span>
						</Typography>
					</Grid>
					<Grid item md={6} xs={12} className={classes.labelResumo}>
						<Typography variant="body1" component="h5" gutterBottom>
							Periodo gratuito em dias <br />{' '}
							<span className={classes.span}>
								{data.grace_period
									? `${data.grace_period} dia${data.grace_period > 1 ? 's' : ''}`
									: 'N/A'}
							</span>
						</Typography>
					</Grid>
					{data.has_expiration && (
						<>
							<Grid item md={6} xs={12} className={classes.labelResumo}>
								<Typography variant="body1" component="h5" gutterBottom>
									Data de Expiração do Plano <br />{' '}
									<span className={classes.span}>
										{data.plan_expiration_date
											? format(data.plan_expiration_date, 'DD/MM/YYYY')
											: 'N/A'}
									</span>
								</Typography>
							</Grid>
							<Grid item md={6} xs={12} className={classes.labelResumo}>
								<Typography variant="body1" component="h5" gutterBottom>
									Exibir Assinatura? <br />{' '}
									<span className={classes.span}>{data.expire_subscriptions ? 'Sim' : 'Não'}</span>
								</Typography>
							</Grid>
						</>
					)}
					<Grid item md={6} xs={12} className={classes.labelResumo}>
						<Typography variant="body1" component="h5" gutterBottom>
							Exibir logo do estabelecimento? <br />{' '}
							<span className={classes.span}>{data.logo ? 'Sim' : 'Não'}</span>
						</Typography>
					</Grid>
					{data.due_days.length > 0 && (
						<Grid item md={6} xs={12} className={classes.labelResumo}>
							<Typography variant="body1" component="h5" gutterBottom>
								Dias para o vencimento <br /> <span className={classes.span}>{formattedDueDays}</span>
							</Typography>
						</Grid>
					)}
					<Grid item md={12} xs={12} className={classes.labelResumo}>
						<Typography variant="body1" component="h4" gutterBottom>
							Link <br />{' '}
							<span className={classes.span}>
								{`https://${url}${config.get('baseName')}/plano/${match.params.planoId}/assinar`}{' '}
							</span>
							<Tooltip title="Copiar">
								<Button
									className={clsx(classes.buttonCopy, classes.iconButton)}
									onClick={() => {
										const copyText = `https://${url}${config.get('baseName')}/plano/${match.params.planoId
											}/assinar`;
										if (navigator.clipboard) {
											navigator.clipboard.writeText(copyText).then(
												() => {
													console.log('copy success');
												},
												error => {
													console.log('Error: ' + error);
													document.execCommand('copy');
												}
											);
										} else {
											console.log(document.execCommand('copy'));
											document.execCommand('copy');
										}
									}}
								>
									<FaCopy className={classes.copy} />
								</Button>
							</Tooltip>
						</Typography>
					</Grid>
				</Grid>
				<Grid container item spacing={3} xs={12} md={6} lg={6} className={classes.divButton}>
					<Grid item xs={12} md={6} lg={6}>
						<Button
							className={classes.button}
							onClick={() => {
								const text = encodeURIComponent(
									`Detalhes do Plano - ${data.name
									}\n\n*${data.name.trim()}*\nhttps://${url}${config.get('baseName')}/plano/${match.params.planoId
									}/assinar`
								);
								window.open(`https://web.whatsapp.com/send?text=${text}`, '_blank');
							}}
							variant="contained"
							fullWidth
						>
							Enviar Via Whatsapp
						</Button>
					</Grid>
				</Grid>
			</Paper>
		</Container>
	);
}
