import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ZDatePicker from './ZDatePicker';

export default function ZModalDatePicker( props ) {
  const [open, setOpen] = React.useState(props.open || false);
  // const [value, setValue] = React.useState(props.value);

  function handleChange( event ){
    props.handleOnChange(event);
  }

  function handleClose() {
    props.handleClose(false);
    setOpen(false);
  }

  React.useEffect(()=>{
    setOpen(props.open);
    // setValue(props.value);
  }, [props]);

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {props.description}
          </DialogContentText>
          <ZDatePicker 
            modal 
            date={props.date}
            minDate={props.minDate}
            onChange={handleChange}
            />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={() => props.handleOnSave()} color="primary">
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}