import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core';
import { Isset } from '../../utils';

const useStyles = makeStyles({
	container: {
		marginTop: props => props.marginTop || 20,
		marginBottom: props => props.marginBottom || 32,
		marginLeft: props => props.marginLeft || 0,
		textAlign: props => props.textAlign,
		color: props => props.color,
	},
});

function ZTitle(props) {
	const classes = useStyles(props);

	var buttonAdd = false;
	var colTitle = 12;
	if (typeof props.handleOnClick == 'function') {
		buttonAdd = true;
		colTitle = 10;
	}

	let grupo = true;
	if (Isset(() => props.grupo)) {
		grupo = props.grupo;
	}

	return (
		<Grid container className={classes.container}>
			<Grid item xs={colTitle} lg={colTitle} md={colTitle} style={props.style || null}>
				<Typography variant={props.variant ? props.variant : 'h4'} component="h1" gutterBottom>
					{props.title}
				</Typography>
			</Grid>

			{buttonAdd && props.button === 'add' && grupo && (
				<Grid item xs={2} lg={2} md={2}>
					<Grid container justifyContent="flex-end">
						<Grid item>
							<Fab
								color="primary"
								onClick={() => {
									props.handleOnClick();
								}}
								size="medium"
								aria-label="Add"
							>
								<AddIcon />
							</Fab>
						</Grid>
					</Grid>
				</Grid>
			)}
		</Grid>
	);
}

export default ZTitle;
