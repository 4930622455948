/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Axios from 'axios';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ZLoader from '../system/ZLoader';
import ZFiltros from '../system/ZFiltros';

import { FaDownload } from 'react-icons/fa';
import ZEmpty from '../system/ZEmpty';
import clsx from 'clsx';
import { Formatter, Isset } from '../../utils/';
import { addMessage } from '../../store/alert';

import config from 'react-global-configuration';
import { makeStyles } from '@material-ui/core/styles';
import format from 'date-fns/format';
import { Button, Tooltip } from '@material-ui/core';
import LastExport from '../Export/LastExport';

const useStyles = makeStyles(theme => ({
	header: {
		fontWeight: 700,
		fontSize: 20,
		margin: 8,
		color: theme.palette.primary.main,
	},
	export: {
		position: 'absolute',
		right: 10,
		opacity: 0.5,
		cursor: 'pointer',
		color: theme.palette.primary.main,
		fontSize: 16,
		transition: theme.transitions.create(['all'], {
			duration: theme.transitions.duration.complex,
		}),
		'&:hover': {
			fontSize: 18,
			opacity: 1,
		},
	},
	exportDownload: {
		color: theme.palette.text.primary,
		fontSize: 16,
		marginLeft: 8,
	},
	iconButton: {
		minWidth: 0,
	},
	buttonExport: {
		color: theme.palette.text.primary,
		border: `solid 1px ${theme.palette.text.primary}`,
		'&:hover': {
			color: '#FFF',
			backgroundColor: theme.palette.primary.main,
			'& svg': {
				color: '#FFF',
			},
		},
		[theme.breakpoints.down('xs')]: {
			width: '90%',
			marginTop: 16,
		},
		'& svg': {
			margin: 0,
		},
	},
	tableHeader: {
		textAlign: 'center',
		fontWeight: 700,
		fontSize: 18,
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		borderRadius: 4,
		paddingLeft: 20,
		height: 40,
		display: 'flex',
		alignItems: 'center',
	},
	tableRow: {
		textAlign: 'center',
		fontWeight: 700,
		height: 40,
		display: 'flex',
		alignItems: 'center',
		borderRadius: 4,
		marginBottom: 2,
		backgroundColor: theme.palette.background.paper,
		border: `1px solid ${theme.palette.divider}`,
		borderTop: 'none',
		'&:hover': {
			backgroundColor: theme.palette.action.hover,
		},
	},
	vendas: {
		marginBottom: 10,
		backgroundColor: theme.palette.background.paper,
		padding: 10,
		borderRadius: 4,
	},
	emptyText: {
		color: theme.palette.text.primary,
	},
	filtersContainer: {
		borderRadius: 4,
		border: `1px solid ${theme.palette.divider}`,
		padding: '5px 10px',
		display: 'flex',
		alignItems: 'center',
		marginBottom: 10,
	},
}));

export default props => {
	const token = useSelector(store => store.auth.token);
	const classes = useStyles();

	const dispatch = useDispatch();
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [showLastExport, setShowLastExport] = useState(false);

	const queryFilter = {
		omni: data.omni,
		mes: format(new Date(), 'MM'),
		ano: format(new Date(), 'YYYY'),
	};

	const [filtros, setFiltros] = useState(queryFilter);
	const filtrosChange = data => {
		setFiltros(data);
	};

	useEffect(() => {
		const getMarkup = async () => {
			setLoading(true);
			const res = await Axios.get(`${config.get('apiUrl')}vendas/markupPorEstabelecimento`, {
				params: {
					filtros,
				},
				headers: { Authorization: `Bearer ${token}` },
			});

			if (res.data.success) {
				setData(res.data.result);
			}
			setLoading(false);
		};

		getMarkup();
	}, [filtros]);

	const exportXlsClick = async () => {
		// if (totalRows > 20000) {
		setShowLastExport(true);
		// } else {
		// 	await exportXls();
		// }
	};
	const exportXls = async getLastExports => {
		try {
			await Axios.get(`${config.get('apiUrl')}export/relatorios/markupPorEstabelecimento`, {
				headers: { Authorization: `Bearer ${token}` },
				responseType: 'blob',
				params: {
					filtros,
				},
			});
			getLastExports();

			dispatch(addMessage({ type: 'warning', message: 'Sua nova exportação está sendo processada.' }));

			// const { 'content-disposition': contentDisposition, 'content-type': contentType } = result.headers;
			// let filename = 'markupPorEstabelecimento.xlsx';
			// if (contentDisposition.indexOf('filename="') !== -1) {
			// 	filename = contentDisposition.slice(
			// 		contentDisposition.indexOf('filename="') + 10,
			// 		contentDisposition.length - 1
			// 	);
			// }

			// FileDownload(result.data, filename, contentType);
		} catch (error) {
			console.error('Error exporting data:', error);
			dispatch(addMessage({ type: 'error', message: 'Erro ao exportar os dados.' }));
		}
	};

	const newExport = async getLastExports => {
		await exportXls(getLastExports);
	};

	return (
		<Grid className={classes.vendas}>
			<div className={classes.header}>Markup Por Estabelecimento</div>

			<Paper
				style={{
					position: 'relative',
					marginBottom: 10,
					borderRadius: 4,
					padding: '5px 10px',
					display: 'flex',
					alignItems: 'center',
				}}
			>
				<ZFiltros
					onChange={filtrosChange}
					input={[
						{
							key: 'omni',
							defaultValue: filtros.omni,
							placeholder: 'Estabelecimento',
							modal: false,
							md: 2,
						},
					]}
					mes={{
						defaultValue: filtros.mes,
					}}
					ano={{
						defaultValue: filtros.ano,
					}}
				/>
				<Tooltip title="Exportar">
					<Button className={clsx(classes.buttonExport, classes.iconButton)} onClick={exportXlsClick}>
						<FaDownload className={classes.exportDownload} />
					</Button>
				</Tooltip>
			</Paper>

			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper style={{ padding: 0 }}>
						{loading ? (
							<ZLoader height={200} />
						) : (
							<>
								{data.length > 0 ? (
									<Grid container className={classes.vendas}>
										<Grid container item className={classes.tableHeader}>
											<Grid item xs={4}>
												Estabelecimento
											</Grid>
											<Grid item xs={4}>
												Quantidade de Vendas
											</Grid>
											<Grid item xs={4}>
												Markup
											</Grid>
										</Grid>
										{data.map((estabelecimento, key) => (
											<React.Fragment key={key}>
												<Grid container item className={clsx(classes.tableRow)}>
													<Grid item xs={4}>
														{estabelecimento.nomeFantasia}
													</Grid>
													<Grid item xs={4}>
														{estabelecimento.quantidadeVendas}
													</Grid>
													<Grid item xs={4}>
														{Formatter.Real(Isset(() => estabelecimento.markup) ?
															+estabelecimento.markup + +estabelecimento.planoTaxa :
															+estabelecimento.planoTaxa
														)}
													</Grid>
												</Grid>
											</React.Fragment>
										))}
									</Grid>
								) : (
									<ZEmpty
										height={200}
										text="Nenhuma Venda Encontrada."
										className={classes.emptyText}
									/>
								)}
							</>
						)}
						{showLastExport && (
							<LastExport
								show={showLastExport}
								onClose={() => setShowLastExport(false)}
								url={'/export/relatorios/markupPorEstabelecimento'}
								newExport={newExport}
								loadingProp={loading}
							/>
						)}
					</Paper>
				</Grid>
			</Grid>
		</Grid>
	);
};
