/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useEffect } from 'react';
import { Grid, Typography, Container, Paper, Button, SvgIcon, CircularProgress } from '@material-ui/core';
import ZCard from '../system/ZCard';


import ZAlert from '../system/ZAlert';
import ZLoader from '../system/ZLoader';
import { Mask, ValidatorForm, Isset } from '../../utils';
import { useSelector, useDispatch } from 'react-redux';
import { addMessage } from '../../store/alert';
import { makeStyles } from '@material-ui/core/styles';
import { FiShoppingBag, FiAlertTriangle } from 'react-icons/fi';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import config from 'react-global-configuration';
import axios from 'axios';

import moment from 'moment';
import { FaBarcode, FaRegCreditCard } from 'react-icons/fa';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
	paper: {
		borderRadius: 5,
		padding: 32,
		// marginTop: 25,
		marginBottom: 16,
	},
	containerPaper: {
		margin: '32px auto',
		[theme.breakpoints.down('xs')]: {
			margin: '16px 0',
		},
	},
	error: {
		backgroundColor: '#c53030',
	},
	warning: {
		backgroundColor: '#ef6c00',
	},
	success: {
		backgroundColor: '#50c878',
	},
	container: {
		width: '100%',
		minHeight: '50vh',
		display: 'flex',

		'& .container__icon': {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			marginTop: '64px',

			position: 'relative',
			backgroundColor: '#FFF',
			borderRadius: '50%',
			width: '100px',
			height: '100px',
		},

		'& .container__icon-check': {
			position: 'absolute',
			top: '66px',
			left: '79px',
		},

		'& .paper': {
			borderRadius: 5,
			padding: 32,
			position: 'absolute',
			// top: '55%',

			'& .paper__content-title': {
				color: '#424242',
				fontSize: '18px',
				fontWeight: 'bold',
				margin: 0,
			},

			'& .paper__content-text': {
				color: '#757575',
				fontSize: '14px',
				lineHeight: '24px',
				margin: 0,
			},
		},
	},
	button: {
		backgroundColor: ({ colorButton }) => colorButton,
		color: '#fff',
		padding: '12px 8px',
		'&:hover': {
			backgroundColor: ({ colorButton }) => colorButton,
		},
	},
	backButton: {
		backgroundColor: 'transparent',
		color: '#777',
		border: ({ colorButton }) => `1px solid ${colorButton}`,
		padding: '12px 8px',
		'&:hover': {
			color: '#fff',
			backgroundColor: ({ colorButton }) => colorButton,
		},
	},
	title: {
		[theme.breakpoints.down('xs')]: {
			textAlign: 'center',
		},
	},
	label: {
		[theme.breakpoints.down('xs')]: {
			marginTop: 32,
		},
	},
	description: {
		[theme.breakpoints.down('xs')]: {
			textAlign: 'center',
		},
	},
	hideMobile: {
		[theme.breakpoints.down('xs')]: {
			display: 'none',
		},
	},
	displayMobile: {
		[theme.breakpoints.down('xs')]: {
			flexDirection: 'column-reverse',
		},
	},
	hideLogo: {
		// [theme.breakpoints.down('xs')]: {
		// 	display: 'none',
		// },
	},
	errorText: {
		color: '#ff0033',
		fontSize: 14,
	},
	valorOption: {
		display: 'flex',
		height: 85,
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: '#ccc',
		color: '#333',
		borderRadius: 10,
		boxShadow: '4px 4px 4px #999',
		fontWeight: 'bold',
		fontSize: 18,
		cursor: 'pointer',
	},
	valorSelected: {
		backgroundColor: '#6969f3',
		color: 'white',
	},
}));
// eslint-disable-next-line
const getBandeiraId = bandeira => {
	switch (`${bandeira}`.toLowerCase()) {
		case 'visa':
			return 'Visa';
		case 'mastercard':
			return 'MasterCard';
		case 'dinersclub':
			return 'Diners Club';
		case 'amex':
			return 'American Express';
		case 'aura':
			return 'Aura';
		case 'jcb':
			return 'JCB';
		case 'hipercard':
			return 'Hipercard';
		case 'discover':
		case 'elo':
			return 'Elo';
		default:
			return 'others';
	}
};

export default props => {
	const dispatch = useDispatch();
	const {
		estabelecimentoConfig: { primary_color },
	} = useSelector(store => store.system.config);

	const classes = useStyles({ colorButton: primary_color || '#3f51b5' });
	const validateFields = {
		cliente: {
			nome: {
				required: true,
			},
			email: {
				required: true,
				email: true,
			},
			celular: {
				required: true,
			},
			cpf: {
				required: true,
			},
			dataNascimento: {
				required: true,
			},
		},
		endereco: {
			logradouro: {
				required: true,
			},
			numero: {
				required: true,
			},
			cep: {
				required: true,
			},
			cidade: {
				required: true,
			},
			estado: {
				required: true,
			},
		},
		cartao: {
			titular: {
				required: true,
			},
			validade: {
				required: true,
			},
			numero: {
				required: true,
			},
			codigoSeguranca: {
				required: true,
			},
		},
	};

	const message = useSelector(state => state.alert);
	const [text, setText] = useState('Carregando...');
	const [pedidoId, setPedidoId] = useState('');
	const [loading, setLoading] = useState(false);
	const [loadingData, setLoadingData] = useState(false);

	// const ip = useClientIp();
	const ip = '';
	// const [activeStep, setActiveStep] = useState(0);
	const [onError, setOnError] = useState(false);
	const [pagamentoAprovado, setPagamentoAprovado] = useState(false);
	// const [dataPagamento, setDataPagamento] = useState({"planoId":"4","cliente":{"nome":"Teste2","email":"dafdsf@hotmail.com","dataNascimento":"1991-12-26","cpf":"19860489009","telefone":"1421421241","celular":"14241241241"},"endereco":{"logradouro":"Rua Leoneta","numero":"4","cep":"03380235","cidade":"São Paulo","estado":"SP","complemento":""},"cartao":{"titular":"Dayglor Campos","validade":"02/07","numero":"5234211181847112","codigoSeguranca":"069"}});
	const [dataPagamento, setDataPagamento] = useState({
		token: props.token,
		tipoPagamentoId: 3,
		parcelas: 1,
		valor: 10,
		valorSelected: 1,
		juros: 0,
		repassarTaxaCliente: false,
		tipoEstabelecimentoId: 1,
		cliente: {
			nome: 'EVANDRO LUIS BARBOSA',
			email: 'evandrolbarbosa@gmail.com',
			dataNascimento: '2021-07-12',
			cpf: '08078824882',
			cnpj: '',
			telefone: '',
			celular: '17997927091',
		},
		endereco: {
			logradouro: 'AVENIDA 9 DE JULHO',
			numero: '2090',
			cep: '15200000',
			cidade: 'José Bonifácio',
			estado: 'SP',
			complemento: '',
		},
		cartao: {
			titular: '',
			validade: '',
			numero: '',
			codigoSeguranca: '',
		},
		valorParcela: 10,
		valorTotal: 10,
	});
	// const [dataPagamento, setDataPagamento] = useState({"planoId":"3","cliente":{"nome":"f dsa fdsaf","email":"fdafdsa@hotmail.com","dataNascimento":"1991-12-26","cpf":"74575174050","telefone":"1124141412","celular":"41241241212"},"endereco":{"logradouro":"Rua Leoneta","numero":"421","cep":"03380235","cidade":"São Paulo","estado":"SP","complemento":""},"cartao":{"titular":"Titular Cartão","validade":"12/23","numero":"5346955803456653","codigoSeguranca":"124"}});

	const [data, setData] = useState({
		name: '',
		description: '',
		setup_amount: 0.0,
		amount: 0.0,
		grace_period: '',
		tolerance_period: '',
		frequency: '',
		zoop_plano_id: null,
		interval: 1,
		juros: 0,
		tipo_pagamento_id: 3,
	});

	const [parcelasOptions, setParcelasOptions] = useState([]);
	const [taxas, setTaxas] = useState(null);
	// eslint-disable-next-line
	const [jurosBandeira, setJurosBandeira] = useState({});
	const [statusPedido, setStatusPedido] = useState(null);
	// const [errorMessage, setErrorMessage] = useState('');

	const status = {
		success: [1, 2].includes(statusPedido),
		warning: [7, 11].includes(statusPedido),
		error: [3, 4, 5, 12].includes(statusPedido),
	};

	const token = useSelector(store => store.auth.token);
	// const estabelecimento = useSelector(store => store.auth.estabelecimento);

	// const handleNext = () => {
	// 	dispatch(addMessage({ type: 'error', message: '' }));
	// 	setActiveStep(prevActiveStep => prevActiveStep + 1);
	// };

	// const handleBack = () => {
	// 	setActiveStep(prevActiveStep => prevActiveStep - 1);
	// };

	const getParcelas = (juros = {}) => {
		const parcelas = [];

		if (data.tipo_pagamento_id === 1 && Isset(() => taxas.taxaBoleto)) {
			const jurosBoleto = taxas.taxaBoleto;
			juros = { 1: jurosBoleto };
		}

		if (+data.amount > 10) {
			for (var i = 1; i <= data.parcelamento_ate; i++) {
				let valor = +data.amount;
				// eslint-disable-next-line no-loop-func
				if (Isset(() => juros[i]) && data.repassarTaxaCliente) {
					valor = +((+data.amount * 100) / (100 - +juros[i]));
				}
				let value = Mask.Real(valor / i);
				let amount = valor / i;
				if (data.juros > 0 && i > 1) {
					if (i >= data.juros_a_partir) {
						let amountJuros = +((valor / 100) * (i * data.juros)).toFixed(2);
						amount = valor + amountJuros;
						value = Mask.Real(+(amount / i).toFixed(2));
					}
				}

				let textParcela = i > 1 ? ` vezes ` : ' vez';
				textParcela += ` de ${value}`;

				if (data.juros && i !== 1 && i >= data.juros_a_partir) {
					textParcela += ` (Juros ${data.juros}% a.m)`;
				} else if (data.repassarTaxaCliente) {
					textParcela += ``;
				} else {
					textParcela += ` (Sem juros)`;
				}

				parcelas.push({ id: i, name: i + textParcela, value: amount });
			}
		} else {
			parcelas.push({ id: 1, name: '1 vez' });
		}

		setParcelasOptions(parcelas);
	};

	// const getValorParcela = parcela => {
	// 	let valor = +data.amount;

	// 	let juros = jurosBandeira;

	// 	if (data.tipo_pagamento_id === 1 && Isset(() => taxas.taxaBoleto)) {
	// 		const jurosBoleto = taxas.taxaBoleto;
	// 		juros = { 1: jurosBoleto };
	// 	}

	// 	// eslint-disable-next-line no-loop-func
	// 	if (Isset(() => juros[parcela])) {
	// 		valor = +((+data.amount * 100) / (100 - +juros[parcela]));
	// 	}

	// 	const valorParcela = valor / parcela;

	// 	return { amount: valor, valorParcela };
	// };

	// const handleDocument = e => {
	// 	const value = e.target.value.replace(/[^\d]+/g, '');
	// 	const cpfCnpj = value.length <= 11 ? ValidarCpf(value) : ValidarCnpj(value);
	// 	const errorMessage = value.length <= 11 ? 'CPF Inválido' : 'CNPJ Inválido';

	// 	if (!cpfCnpj && value) {
	// 		setErrorMessage(errorMessage);
	// 	} else {
	// 		setErrorMessage('');
	// 	}
	// };

	useEffect(() => {
		const getData = async () => {
			setLoadingData(true);
			// if( match.params.planoId ){
			let pedidoToken = props.token;
			if (!pedidoToken && props.pedidoId) {
				const pagarBoletoComCartao = await axios.get(
					`${config.get('apiUrl')}pre-venda/pagar-boleto-com-cartao/${props.pedidoId}`,
					{
						// headers: { Authorization: `Bearer ${token}` },
					}
				);
				pedidoToken = pagarBoletoComCartao.data.lp.token;
			}

			if (pedidoToken) {
				const res = await axios.get(`${config.get('apiUrl')}pre_venda/${pedidoToken}/token`, {
					// headers: { Authorization: `Bearer ${token}` },
				});

				if (res.data.success) {
					setData({
						titulo: res.data.preVenda.titulo,
						descricao: res.data.preVenda.descricao,
						amount: res.data.preVenda.amount / 100,
						valor: res.data.preVenda.amount / 100,
						data_expiracao: res.data.preVenda.data_expiracao,
						parcelamento_ate: res.data.preVenda.parcelamento_ate,
						logo: !!res.data.preVenda.logo,
						logoUrl: Isset(() => res.data.preVenda.estabelecimento.arquivo.url)
							? res.data.preVenda.estabelecimento.arquivo.url
							: null,
						nome_fantasia: res.data.preVenda.estabelecimento.nome_fantasia,
						razao_social: res.data.preVenda.estabelecimento.razao_social,
						identificacao_fatura: res.data.preVenda.estabelecimento.identificacao_fatura,
						estabelecimentoId: res.data.preVenda.estabelecimento.id,
						juros: res.data.preVenda.juros,
						juros_a_partir: res.data.preVenda.juros_a_partir,
						tipo_pagamento_id: res.data.preVenda.tipo_pagamento_id,
						repassarTaxaCliente: res.data.preVenda.repassar_taxa_cliente,
					});

					setDataPagamento({
						...dataPagamento,
						token: pedidoToken,
						// valor: res.data.preVenda.amount / 100,
						tipoPagamentoId: res.data.preVenda.tipo_pagamento_id,
						// valorParcela: res.data.preVenda.amount / 100,
						// valorTotal: res.data.preVenda.amount / 100,
					});

					const taxas = await axios.get(
						`${config.get('apiUrl')}planos_taxas/porEstabelecimento/${res.data.preVenda.estabelecimento.id
						}/taxaTotal`,
						{
							params: { tipoVendaId: 2 },
							headers: { Authorization: `Bearer ${token}` },
						}
					);

					if (taxas.data.success) {
						setTaxas(taxas.data);
					}
				} else {
					setText(res.data.message);
				}
			}
		};

		getData();
		// }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.token]);

	useEffect(() => {
		getParcelas();
		setLoadingData(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data.amount, taxas]);

	useEffect(() => {
		installmentOnChange({ target: { value: dataPagamento.parcelas } });
	}, [parcelasOptions]);

	const handleSubmitForm = async () => {
		dispatch(addMessage({ type: 'error', message: '' }));
		setLoading(true);

		try {
			if (dataPagamento.tipoEstabelecimentoId === 2) {
				delete validateFields.cliente.cpf;
				// delete validateFields.cliente.dataNascimento;
				validateFields.cliente.cnpj = {
					required: true,
				};
			} else {
				delete validateFields.cliente.cnpj;
				validateFields.cliente.cpf = {
					required: true,
				};
				// validateFields.cliente.dataNascimento = {
				// 	required: true,
				// };
			}

			if (data.tipo_pagamento_id === 1) {
				delete validateFields.cartao;
			}

			ValidatorForm(validateFields, dataPagamento);
		} catch (erro) {
			// setActiveStep(0);
			dispatch(addMessage({ type: 'error', message: 'Existem campos inválidos no formulário' }));
			setLoading(false);
			setOnError(true);
			return false;
		}

		let res = '';
		var dadosPagamento = dataPagamento;
		if (dataPagamento.tipoEstabelecimentoId === 2) {
			delete dadosPagamento.cliente.cpf;
			delete dadosPagamento.cliente.dataNascimento;
		} else {
			delete dadosPagamento.cliente.cnpj;
		}

		dadosPagamento.estabelecimento_id = data.estabelecimentoId;
		dadosPagamento.ip = ip;

		// console.log(dadosPagamento);
		// console.log(data);
		// if (dadosPagamento.valorTotal > data.amount) {
		// 	dadosPagamento.valor = +dadosPagamento.valorTotal.toFixed(2);
		// }
		dadosPagamento.valor = +dadosPagamento.valorTotal.toFixed(2);
		dadosPagamento.ip = ip;
		// dadosPagamento.sendMail = false;
		// dadosPagamento.email = false;

		res = await axios.post(config.get('apiUrl') + 'vendas/token', dadosPagamento, {
			// headers: { Authorization: `Bearer ${token}` },
		});

		if (res.data.error) {
			dispatch(addMessage({ type: 'error', message: res.data.error.message }));
			setLoading(false);
		}

		if (res.data.success && res.data.pedido) {
			const { status_pedido_id } = res.data.pedido;

			setStatusPedido(status_pedido_id);
		}

		if (res.data.errors) {
			dispatch(addMessage({ type: 'error', message: res.data.errors.join(' - ') }));
			setLoading(false);
		}

		if (Isset(() => res.data.success) && res.data.success === true) {
			dispatch(addMessage({ type: 'success', message: 'Operação realizada com sucesso' }));
			setPagamentoAprovado(true);
			setPedidoId('Operação: ' + res.data.pedido.id);
			setData({ ...data });
		} else {
			dispatch(addMessage({ type: 'error', message: res.data.message }));
		}
		setLoading(false);
	};

	// const getEndereco = async value => {
	// 	if (value.length === 8) {
	// 		var res = await axios.get(config.get('apiUrl') + 'buscar_cep/' + value);
	// 		if (res.data.success === false || res.data.endereco.erro) {
	// 			setDataPagamento({
	// 				...dataPagamento,
	// 				endereco: {
	// 					...dataPagamento.endereco,
	// 					cep: value,
	// 					logradouro: '',
	// 					cidade: '',
	// 					estado: '',
	// 					complemento: '',
	// 				},
	// 			});
	// 			return false;
	// 		}

	// 		setDataPagamento({
	// 			...dataPagamento,
	// 			endereco: {
	// 				...dataPagamento.endereco,
	// 				cep: Mask.OnlyNumber(res.data.endereco.cep),
	// 				logradouro: res.data.endereco.logradouro,
	// 				cidade: res.data.endereco.localidade,
	// 				estado: res.data.endereco.uf,
	// 				complemento: res.data.endereco.complemento,
	// 			},
	// 		});
	// 	}
	// };

	const maskCreditCard = useMemo(() => {
		const regex = /\b(?:\d{4}[ -]?){3}(?=\d{4}\b)/gm;
		const subst = `**** **** **** `;

		return dataPagamento.cartao.numero.replace(regex, subst);
	}, [dataPagamento.cartao.numero]);

	const onCardChange = (type, isValid) => {
		const bandeira = getBandeiraId(type.issuer);
		if (Isset(() => taxas.taxaTotal[bandeira].valores.credit)) {
			const taxasParcelas = taxas.taxaTotal[bandeira].valores.credit.valores;
			setJurosBandeira(taxasParcelas);
			getParcelas(taxasParcelas);
		} else {
			setJurosBandeira({});
			getParcelas();
		}
	};

	const onSelectValor = valorSelected => {
		let valor = 10;
		switch (valorSelected) {
			case 1:
				valor = 10;
				break;
			case 2:
				valor = 30;
				break;
			case 3:
				valor = 50;
				break;
			default:
				valor = 10;
		}
		setDataPagamento({
			...dataPagamento,
			valorSelected,
			valor,
			valorParcela: valor,
			valorTotal: valor,
		});
	};

	const installmentOnChange = e => {
		// let valorParcela = data.amount;
		// let amount = data.amount;
		// if (e.target.value > 1 && (data.juros_a_partir <= e.target.value || !data.juros_a_partir) && data.juros) {
		// 	let amountJuros = +((data.amount / 100) * data.juros).toFixed(2);
		// 	amountJuros = amountJuros * e.target.value;
		// 	amount = data.amount + amountJuros;
		// 	valorParcela = +(amount / e.target.value).toFixed(2);
		// } else if (e.target.value > 1) {
		// 	valorParcela = +(amount / e.target.value).toFixed(2);
		// }
		// if (data.repassarTaxaCliente) {
		// 	const valorParcelaAux = getValorParcela(e.target.value);
		// 	valorParcela = valorParcelaAux.valorParcela;
		// 	amount = valorParcelaAux.amount;
		// }
		// setDataPagamento({
		// 	...dataPagamento,
		// 	parcelas: e.target.value,
		// 	tipoPagamentoId: data.tipo_pagamento_id,
		// 	valorParcela,
		// 	valorTotal: amount,
		// });
	};

	if (loadingData) {
		return <ZLoader message="Carregando" />;
	}

	if (text && text !== 'Carrregando...' && !data.titulo) {
		return text;
	}

	if (pagamentoAprovado) {
		return (
			<>
				{pagamentoAprovado ? (
					<Grid
						container
						className={clsx(classes.container, {
							[classes.warning]: status.warning,
							[classes.error]: status.error,
							[classes.success]: status.success,
						})}
					>
						<Grid container item justifyContent="center">
							<div className="container__icon">
								<div>
									{(() => {
										if (status.success) {
											return <FiShoppingBag color="#059142" size="50px" strokeWidth="1px" />;
										}

										if (status.warning) {
											return <FiAlertTriangle color="#FFC107" size="50px" strokeWidth="1px" />;
										}

										if (status.error) {
											return <FiAlertTriangle color="#ff0000" size="50px" strokeWidth="1px" />;
										}
									})()}
								</div>

								{status.success && (
									<div className="container__icon-check">
										<SvgIcon
											component={CheckCircleIcon}
											fontSize="large"
											style={{ color: '#043927' }}
										/>
									</div>
								)}
							</div>
							<Grid container item justifyContent="center">
								<section>
									<p
										style={{
											color: '#fff',
											fontSize: '32px',
											margin: 0,
											padding: 10,
											textAlign: 'center',
										}}
									>
										{(() => {
											if (status.success) {
												if (data.tipo_pagamento_id === 1)
													return 'Pronto, seu boleto foi gerado';

												return 'Pronto, seu pagamento foi aprovado!';
											}

											if (status.error) {
												return 'Pagamento recusado! Entre em contato com o estabelecimento';
											}

											if (status.warning) {
												return 'Pagamento em análise, em breve enviaremos uma notificação por e-mail';
											}
										})()}
									</p>
									<p
										style={{
											color: '#fff',
											fontSize: '18px',
											textAlign: 'center',
											marginBottom: '64px',
											marginTop: 8,
										}}
									>
										{pedidoId}
									</p>
								</section>
							</Grid>
						</Grid>
						{status.success && (
							<Grid container justifyContent="center" alignItems="center" style={{ marginTop: 64 }}>
								<Paper className="paper">
									<Grid className="paper__container-content" item container direction="row" xs={12}>
										<Grid item sm={1} xs={12} style={{ marginRight: 16 }}>
											{data.tipo_pagamento_id === 1 ? (
												<FaBarcode size="32px" />
											) : (
												<FaRegCreditCard size="32px" />
											)}
										</Grid>

										<Grid item sm={10}>
											<p className="paper__content-title">
												{data.tipo_pagamento_id === 1 ? 'Você vai pagar' : 'Você pagou'}{' '}
												{Mask.Real(dataPagamento.valorTotal)}{' '}
											</p>
											{data.tipo_pagamento_id !== 1 && (
												<p className="paper__content-text">{maskCreditCard}</p>
											)}
											<p className="paper__content-text">
												{data.tipo_pagamento_id === 1
													? 'O boleto foi enviado para o seu e-mail, faça o pagamento o quanto antes pois a compensação pode demorar até 3 dias úteis'
													: `Na fatura do seu cartão você verá o pagamento em nome de ${data.nome_fantasia.toUpperCase()}`}
											</p>
										</Grid>
									</Grid>
								</Paper>
							</Grid>
						)}
					</Grid>
				) : (
					<ZLoader />
				)}
			</>
		);
	}

	if (data.titulo) {
		return (
			<div style={{ background: 'rgb(230 231 236)', minHeight: '100vh' }}>
				<Container maxWidth="md">
					<Grid container className={classes.displayMobile}>
						{message.message && (
							<ZAlert type={message.type} message={message.message} time={moment().unix()} />
						)}

						<Grid item xs={12} md={6} className={classes.containerPaper}>
							<Grid item xs={12}>
								<Paper className={classes.paper}>
									<Grid item xs={12}>
										<Grid container spacing={2}>
											<Grid item xs={12}>
												<Grid container spacing={2}>
													<>
														<Grid container item spacing={2} style={{ marginBottom: 20 }}>
															<Grid item xs={4}>
																<div
																	className={clsx(classes.valorOption, {
																		[classes.valorSelected]:
																			dataPagamento.valorSelected === 1,
																	})}
																	onClick={() => onSelectValor(1)}
																>
																	R$ 10,00
																</div>
															</Grid>
															<Grid item xs={4}>
																<div
																	className={clsx(classes.valorOption, {
																		[classes.valorSelected]:
																			dataPagamento.valorSelected === 2,
																	})}
																	onClick={() => onSelectValor(2)}
																>
																	R$ 30,00
																</div>
															</Grid>
															<Grid item xs={4}>
																<div
																	className={clsx(classes.valorOption, {
																		[classes.valorSelected]:
																			dataPagamento.valorSelected === 3,
																	})}
																	onClick={() => onSelectValor(3)}
																>
																	R$ 50,00
																</div>
															</Grid>
														</Grid>

														{data.tipo_pagamento_id === 3 && (
															<Grid item xs={12}>
																<ZCard
																	number={dataPagamento.cartao.numero}
																	name={dataPagamento.cartao.titular}
																	expiry={dataPagamento.cartao.validade}
																	cvc={dataPagamento.cartao.codigoSeguranca}
																	numberOnBlur={e => {
																		setDataPagamento({
																			...dataPagamento,
																			cartao: {
																				...dataPagamento.cartao,
																				numero: Mask.OnlyNumber(e.target.value),
																			},
																		});
																	}}
																	nameOnBlur={e =>
																		setDataPagamento({
																			...dataPagamento,
																			cartao: {
																				...dataPagamento.cartao,
																				titular: e.target.value,
																			},
																		})
																	}
																	expiryOnBlur={e =>
																		setDataPagamento({
																			...dataPagamento,
																			cartao: {
																				...dataPagamento.cartao,
																				validade: e.target.value,
																			},
																		})
																	}
																	cvcOnBlur={e =>
																		setDataPagamento({
																			...dataPagamento,
																			cartao: {
																				...dataPagamento.cartao,
																				codigoSeguranca: e.target.value,
																			},
																		})
																	}
																	onError={onError}
																	maxInstallment={parcelasOptions}
																	installment={dataPagamento.parcelas}
																	installmentOnChange={installmentOnChange}
																	enableInstallmentOnCardNumber
																	cardGrid={{
																		xs: 12,
																		sm: 12,
																		md: 12,
																		lg: 12,
																	}}
																	containerInputsGrid={{
																		xs: 12,
																		sm: 12,
																		md: 12,
																		lg: 12,
																		marginTop: 30,
																	}}
																	onChange={onCardChange}
																/>
															</Grid>
														)}

														{data.tipo_pagamento_id === 1 && (
															<Grid item xs={12}>
																<Typography variant="h6" gutterBottom>
																	Boleto Bancário
																</Typography>
																<Typography variant="body2" gutterBottom>
																	O boleto será enviado para o seu e-mail logo após
																	concluir o processo. <br />
																	Clique em avançar para continuar
																</Typography>
															</Grid>
														)}
													</>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Paper>
								<Grid item xs={12}>
									<Grid spacing={2} container direction="row" justifyContent="flex-end">
										<Grid item xs={12} md={6}>
											{loading ? (
												<Button
													// onClick={() => handleSubmitForm()}
													className={classes.button}
													fullWidth
												>
													<CircularProgress size={24} style={{ color: '#fff' }} />
												</Button>
											) : (
												<Button
													onClick={() => handleSubmitForm()}
													className={classes.button}
													fullWidth
												>
													Concluir
												</Button>
											)}
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} md={4} className={classes.containerPaper}>
							<Paper className={classes.paper}>
								<Grid container spacing={9} direction="row" justifyContent="center" alignItems="center">
									{data.logo && data.logoUrl && (
										<Grid item xs={12} md={12} align="center" className={classes.hideLogo}>
											<img
												src={data.logoUrl}
												alt={data.nome_fantasia}
												title={data.nome_fantasia}
												style={{ maxWidth: '100%', maxHeight: '100px' }}
											/>
										</Grid>
									)}
									<Grid item xs={12} md={12}>
										<Typography className={classes.title} variant="h5" gutterBottom>
											{data.titulo}
										</Typography>
										<Typography className={classes.description} variant="subtitle1" gutterBottom>
											{data.descricao}
										</Typography>
										{/* <Typography className={classes.description} variant="subtitle1" gutterBottom>
											<p>
												Preço: <b>{Mask.Real(dataPagamento.valorTotal)}</b>
											</p>
										</Typography>
										<Typography variant="body2" gutterBottom style={{ marginBottom: 16 }}>
											{data.identificacao_fatura ? data.identificacao_fatura : data.nome_fantasia}
										</Typography> */}
									</Grid>
								</Grid>
							</Paper>
						</Grid>
					</Grid>
				</Container>
			</div>
		);
	}
};
