/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
	header: {
		fontWeight: 700,
		fontSize: 20,
		margin: 8,
	},
	relatorios: {},
	relatorioBtn: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		padding: 20,
		borderRadius: 10,
		border: 'solid 1px #abc',
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.getContrastText(theme.palette.primary.main),
		opacity: 0.8,
		cursor: 'pointer',
		height: 100,
		transition: theme.transitions.create(['opacity'], { duration: theme.transitions.duration.complex }),
		'&:hover': {
			opacity: 1,
		},
	},
	relatorioBtnText: {
		fontWeight: 700,
		fontSize: 20,
		fontVariant: 'small-caps',
		textAlign: 'center',
	},
}));

export default props => {
	const classes = useStyles();

	const history = useHistory();

	const usuario = useSelector(store => store.auth.usuario);
	const isAdmin = usuario.grupo_id === 1;
	const isRepresentante = usuario.grupo_id === 5;
	const isEC = [2, 3].includes(usuario.grupo_id);

	return (
		<div className={classes.relatorios}>
			<div className={classes.header}>Relatórios</div>

			<Grid container spacing={2} justifyContent="center">
				{!isEC && usuario.grupo_id !== 12 && usuario.grupo_id !== 11 && usuario.grupo_id !== 15 && (
					<Grid item md={3} sm={6} xs={12}>
						<div
							className={classes.relatorioBtn}
							onClick={() => history.push('/relatorios/vendasPorEstabelecimento')}
						>
							<div className={classes.relatorioBtnText}>Vendas por Estabelecimento</div>
						</div>
					</Grid>
				)}

				{((usuario.marketplace.id !== 129 &&
					usuario.grupo_id !== 11 &&
					usuario.grupo_id !== 12 &&
					usuario.grupo_id !== 13 &&
					usuario.grupo_id !== 14) ||
					(isAdmin && usuario.marketplace.id === 129)) && (
					<Grid item md={3} sm={6} xs={12}>
						<div className={classes.relatorioBtn} onClick={() => history.push('/relatorios/vendasPorPOS')}>
							<div className={classes.relatorioBtnText}>Vendas por POS</div>
						</div>
					</Grid>
				)}
				{isAdmin && (
					<Grid item md={3} sm={6} xs={12}>
						<div
							className={classes.relatorioBtn}
							onClick={() => history.push('/relatorios/markupPorEstabelecimento')}
						>
							<div className={classes.relatorioBtnText}>MarkUp Por Estabelecimento</div>
						</div>
					</Grid>
				)}
				{(isAdmin || isRepresentante) && (
					<Grid item md={3} sm={6} xs={12}>
						<div className={classes.relatorioBtn} onClick={() => history.push('/relatorios/performance')}>
							<div className={classes.relatorioBtnText}>Performance Por Estabelecimento</div>
						</div>
					</Grid>
				)}
				<Grid item md={3} sm={6} xs={12}>
					<div className={classes.relatorioBtn} onClick={() => history.push('/relatorios/vendasPorDia')}>
						<div className={classes.relatorioBtnText}>Vendas por Dia</div>
					</div>
				</Grid>
				{isAdmin && (
					<Grid item md={3} sm={6} xs={12}>
						<div
							className={classes.relatorioBtn}
							onClick={() => history.push('/relatorios/chargeback-por-estabelecimento')}
						>
							<div className={classes.relatorioBtnText}>Chargeback Por Estabelecimento</div>
						</div>
					</Grid>
				)}
				{isAdmin && (
					<Grid item md={3} sm={6} xs={12}>
						<div
							className={classes.relatorioBtn}
							onClick={() => history.push('/relatorios/splits-por-estabelecimento')}
						>
							<div className={classes.relatorioBtnText}>Splits Por Estabelecimento</div>
						</div>
					</Grid>
				)}
				{isAdmin && (
					<Grid item md={3} sm={6} xs={12}>
						<div
							className={classes.relatorioBtn}
							onClick={() => history.push('/relatorios/saldo-por-estabelecimento')}
						>
							<div className={classes.relatorioBtnText}>Saldo Por Estabelecimento</div>
						</div>
					</Grid>
				)}
			</Grid>
		</div>
	);
};

// eslint-disable-next-line no-lone-blocks
{
	/* <ResponsiveBar
		data={melhoresECs}
		keys={['valorTotalVendas']}
		indexBy="nomeFantasia"
		margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
		padding={0.3}
		colors={colors}
		colorBy="index" ssa
		tooltipFormat={value => Formatter.Real(value)}
		labelFormat={value => Formatter.Real(value)}
		axisTop={null}
		axisRight={null}
		axisBottom={{
				tickSize: 5,
				tickPadding: 5,
				tickRotation: -8,
		}}
		axisLeft={{
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 0,
				legend: 'Valor Total de Vendas',
				// legendPosition: 'middle',
				legendOffset: -40
		}}
		animate={true}
		motionStiffness={90}
		motionDamping={15}
/> */
}
