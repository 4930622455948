/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import StringMask from 'string-mask';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { updateSaldo } from '../../store/system';
import { addMessage } from '../../store/alert';
import ZLoader from '../system/ZLoader';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import { Mask, Isset, Formatter } from '../../utils';
import { Paper } from '@material-ui/core';
import axios from 'axios';
import config from 'react-global-configuration';
import ZDatePicker from '../system/ZDatePicker';
import { useHistory } from 'react-router-dom';
import MultiplasTransferencias from './components/MultiplasTransferencias';
import { isWhite } from '../../utils';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import debounce from 'lodash.debounce';

const useStyles = makeStyles(theme => ({
	field: {
		padding: 5,
		textAlign: 'center',
	},
	label: {
		fontSize: 11,
		textTransform: 'uppercase',
		fontWeight: 700,
	},
	fullWidth: {
		width: '100%',
	},
	saldoBox: {
		backgroundColor: '#616161',
		color: 'white',
		margin: -10,
		marginBottom: 10,
		padding: '5px 15px',
	},
	saldoFuturo: { color: '#FFF0A0' },
	transferencia: {},
	content: {
		width: 400,
	},
	formaTransferencia: {
		padding: 16,
		fontSize: 14,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontWeight: 700,
		color: isWhite(theme.palette.primary.main) ? '#999' : theme.palette.primary.main,
		backgroundColor: isWhite(theme.palette.primary.main)
			? '#ccc'
			: theme.palette.primary.main
			? '#F1F1F1'
			: theme.palette.getContrastText(theme.palette.primary.main),
		borderColor: isWhite(theme.palette.primary.main) ? '#999' : theme.palette.primary.main,
		border: '1px solid',
		borderRadius: 6,
		cursor: 'pointer',
		'&.selected': {
			backgroundColor: isWhite(theme.palette.primary.main) ? '#999' : theme.palette.primary.main,
			color: isWhite(theme.palette.primary.main)
				? '#fff'
				: theme.palette.getContrastText(theme.palette.primary.main),
		},
	},
	contaBancaria: {
		margin: 8,
		marginTop: 10,
		padding: 4,
		fontSize: 14,
		fontWeight: 700,
		color: isWhite(theme.palette.primary.main) ? '#333' : theme.palette.primary.main,
		borderColor: isWhite(theme.palette.primary.main) ? '#999' : theme.palette.primary.main,
		border: '1px solid',
		cursor: 'pointer',
		'&.selected': {
			backgroundColor: isWhite(theme.palette.primary.main) ? '#999' : theme.palette.primary.main,
			color: isWhite(theme.palette.primary.main)
				? '#fff'
				: theme.palette.getContrastText(theme.palette.primary.main),
		},
	},
	error: {
		fontSize: 13,
		textTransform: 'uppercase',
		fontWeight: 700,
		color: '#f44336',
	},
	errorLink: {
		color: '#f44310',
		cursor: 'pointer',
	},
	opcoesTransferir: {
		backgroundColor: '#efefef',
		margin: '16px',
		cursor: 'pointer',
	},
	enviarCancelar: {
		color: isWhite(theme.palette.primary.main) ? '#000' : theme.palette.primary.main,
	},
}));

var minDate = new Date();
minDate.setDate(minDate.getDate() + 1);

export default props => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const history = useHistory();
	const usuario = useSelector(store => store.auth.usuario);
	const is7pay = usuario.estabelecimento.marketplace.id === 60;
	const token = useSelector(state => state.auth.token);
	const estabelecimento = useSelector(state => state.auth.estabelecimento);
	const isElo7 = estabelecimento.marketplace.id === 129;
	const estabelecimentoAdmin = useSelector(state => state.admin.estabelecimento);
	const [open, setOpen] = useState(props.open || false);
	const [sending, setSending] = useState(false);

	const [loading, setLoading] = useState(false);
	const [loadingAllResquest, setLoadingAllRequest] = useState(false);
	const [contasBancarias, setContasBancarias] = useState([]);

	const [switcher, setSwitcher] = useState(false);
	const [switcherMultiplas, setSwitcherMultiplas] = useState(false);
	const [switcherTodosEcs, setSwitcherTodosEcs] = useState(false);
	const [loadingContaBancaria, setLoadingContaBancaria] = useState(false);

	const estabelecimentoId = Isset(() => estabelecimentoAdmin.id) ? estabelecimentoAdmin.id : estabelecimento.id;
	const [finished, setFinished] = useState([]);
	const [errors, setErrors] = useState({});
	const [isMultiplas, setIsMultiplas] = useState({});
	const [estabelecimentosTransferencias, setEstabelecimentosTransferencias] = useState([]);
	const [saldo, setSaldo] = useState({
		atual: 0,
		futuro: 0,
	});
	const initialState = {
		cpfcnpj: '',
		formaTransferencia: isElo7 ? 2 : 1,
		valor: 0,
		estabelecimento: {
			id: null,
			logo: null,
			nome: '',
			email: '',
			documento: '',
		},
		contaBancariaId: null,
		descricao: '',
		senha: '',
		agendadoPara: null,
	};
	const [data, setData] = useState(initialState);
	const maskCpf = v => {
		const mask = new StringMask('000.000.000-00');
		return mask.apply(v);
	};
	const [localCpfCnpj, setLocalCpfCnpj] = useState(data.cpfcnpj);
	const validate = data => {
		let e = {};

		if (!data.valor) {
			e.valor = true;
		} else if (!switcher && data.valor > saldo.atual) {
			e.valorMessage = 'Valor maior que o saldo atual.';
		}

		if (data.tipoTransferencia === 1) {
			if (!data.toEstabelecimentoId) {
				e.cpfcnpj = true;
			}
		} else if (data.tipoTransferencia === 2) {
			if (!data.contaBancariaId) {
				e.contaBancaria = true;
			}
		}

		if (!data.senha) {
			e.senha = true;
		}

		setErrors(e);

		return e;
	};
	const handleClose = () => {
		setOpen(false);

		if (props.onClose) {
			props.onClose();
		}
	};
	const handleCpfCnpjChange = debounce(value => {
		if (value.length === 11) {
			setData({ ...data, cpfcnpj: maskCpf(value) });
		} else if (value.length === 17) {
			setData({ ...data, cpfcnpj: Mask.Cnpj(value) });
		} else {
			setData({ ...data, cpfcnpj: value });
		}
	}, 300);
	const onChangeCpfCnpj = e => {
		const value = e.target.value.replace(/\D/g, ''); // Remover caracteres não numéricos
		setLocalCpfCnpj(value); // Atualizar estado local para UX responsiva
		handleCpfCnpjChange(value); // Função com debounce
	};

	const handleSubmit = async () => {
		let dataTransferencia = estabelecimentosTransferencias;
		let success = true;
		if (!switcherMultiplas) {
			dataTransferencia = [
				{
					tipoTransferencia: data.formaTransferencia,
					value: data.valor,
					fromEstabelecimentoId: estabelecimentoId,
					toEstabelecimentoId: data.estabelecimento.id,
					contaBancariaId: data.contaBancariaId,
					senha: data.senha,
					descricao: data.descricao,
					agendadoPara: data.agendadoPara,
					estabelecimento: {
						nomeFantasia: data.estabelecimento.nome,
						id: data.estabelecimento.id,
					},
				},
			];
		}

		let error = [];
		setLoadingAllRequest(true);
		for await (const estabelecimento of dataTransferencia) {
			const postData = {
				tipoTransferencia: data.formaTransferencia,
				valor: estabelecimento.value,
				fromEstabelecimentoId: estabelecimentoId,
				toEstabelecimentoId: estabelecimento.estabelecimento.id,
				contaBancariaId: data.contaBancariaId,
				senha: data.senha,
				descricao: data.descricao,
				agendadoPara: data.agendadoPara,
			};

			if (postData.valor) {
				const validation = validate(postData);
				if (Object.keys(validation).length === 0) {
					setSending(true);
					const res = await axios
						.post(`${config.get('apiUrl')}transferencias`, postData, {
							headers: { Authorization: `Bearer ${token}` },
						})
						.then(res => {
							if (res.data.success) {
								dispatch(updateSaldo(true));
								setFinished(prevFinished => [...prevFinished, estabelecimento.id]);
								delete dataTransferencia[estabelecimento.id - 1];
								if (props.onSuccess) {
									props.onSuccess();
								}
							} else {
								error.push({
									message: res.data.message,
									ec: estabelecimento.estabelecimento.nomeFantasia,
								});
								if (!switcherMultiplas) {
									dispatch(
										addMessage({
											type: 'error',
											message: `${res.data.message}`,
										})
									);
								}
								if (props.onError) {
									props.onError();
								}
							}
							setSending(false);
						})
						.catch(err => {
							error.push({
								message: 'Too many requests',
								ec: estabelecimento.estabelecimento.nomeFantasia,
							});
							setSending(false);
						});
				} else {
					if (validation.senha) {
						error.push({
							message: 'Preencha o campo senha!',
						});
					}
					if (validation.contaBancaria) {
						error.push({
							message: 'Selecione uma conta bancaria!',
						});
					}
					if (validation.valorMessage) {
						error.push({
							message: validation.valorMessage,
						});
					}
					if (validation.cpfcnpj) {
						error.push({
							message: 'Preencha o campo CPF / CNPJ!',
						});
					}
				}
			} else {
				success = false;
				dispatch(addMessage({ type: 'error', message: 'Preencha o campo valor!' }));
			}
		}
		setLoadingAllRequest(false);
		if (error.length > 0) {
			let warning = [];
			for await (const err of error) {
				warning.push(err.ec);
			}

			if (error[0].message) {
				dispatch(
					addMessage({
						type: 'error',
						message: error[0].message,
					})
				);
			}

			if (switcherMultiplas && error[0].ec) {
				dispatch(
					addMessage({
						type: 'error',
						message: 'Erro ao transferir para o(s) estabelecimento(s) ' + warning.join(','),
					})
				);
			}
		} else {
			if (success) {
				dispatch(addMessage({ type: 'success', message: 'Operação realizada com sucesso!' }));
				handleClose();
			}
		}
	};

	useEffect(() => {
		const getSaldo = async () => {
			const res = await axios.get(`${config.get('apiUrl')}estabelecimentos/${estabelecimentoId}/saldo`, {
				headers: { Authorization: `Bearer ${token}` },
			});

			if (res.data.success) {
				setSaldo({
					...saldo,
					...res.data.saldo,
				});
			}
		};

		const getContasBancarias = async () => {
			setLoadingContaBancaria(true);
			const res = await axios.get(
				`${config.get('apiUrl')}estabelecimentos/${estabelecimentoId}/contas_bancarias`,
				{
					headers: { Authorization: `Bearer ${token}` },
				}
			);

			if (res.data.success) {
				setContasBancarias(res.data.contasBancarias);
			}
			setLoadingContaBancaria(false);
		};

		getSaldo();
		getContasBancarias();
	}, []);

	useEffect(() => {
		setData({
			...data,
			estabelecimento: {
				id: null,
				logo: null,
				nome: '',
				email: '',
				documento: '',
			},
		});
		if (props.documento) {
			setData({
				...data,
				cpfcnpj: props.documento,
			});
		}
		if (localCpfCnpj.length === 11 || localCpfCnpj.length === 14) {
			const getEstabelecimento = async () => {
				const res = await axios.get(`${config.get('apiUrl')}estabelecimentos/por_documento/${localCpfCnpj}`, {
					headers: { Authorization: `Bearer ${token}` },
				});

				if (res.data.success) {
					const est = res.data.estabelecimento;
					let arrayEcs = [];

					if (!est.length) {
						// arrayEcs.push({
						// 	id: est[i].id,
						// 	logo: Isset(() => est[i].arquivo.url) ? est[i].arquivo.url : null,
						// 	nome: est[i].nome_fantasia,
						// 	email: Isset(() => est[i].estabelecimentos_contatos[0].contato)
						// 		? est[i].estabelecimentos_contatos[0].contato
						// 		: '',
						// 	documento: Isset(() => est[i].estabelecimentos_documentos[0].documento)
						// 		? est[i].estabelecimentos_documentos[0].documento
						// 		: '',
						// });
						if (est.id === estabelecimentoId && !props.documento) {
							setErrors({
								...errors,
								estabelecimentoMessage: 'Você não pode transferir dinheiro para você mesmo.',
							});
							return;
						}
					} else {
						for (let i = 0; i < est.length; i++) {
							arrayEcs.push({
								id: est[i].id,
								logo: Isset(() => est[i].arquivo.url) ? est[i].arquivo.url : null,
								nome: est[i].nome_fantasia,
								email: Isset(() => est[i].estabelecimentos_contatos[0].contato)
									? est[i].estabelecimentos_contatos[0].contato
									: '',
								documento: Isset(() => est[i].estabelecimentos_documentos[0].documento)
									? est[i].estabelecimentos_documentos[0].documento
									: '',
							});
							// if (est[i].id === estabelecimentoId && !props.documento) {
							// 	setErrors({
							// 		...errors,
							// 		estabelecimentoMessage: 'Você não pode transferir dinheiro para você mesmo.',
							// 	});
							// 	return;
							// }
						}
					}

					if (arrayEcs.length > 1) {
						setData({
							...data,
							estabelecimento: arrayEcs,
						});
						setErrors({
							...errors,
							estabelecimentoMessage: null,
						});
					} else {
						setData({
							...data,
							estabelecimento: {
								id: est.id,
								logo: Isset(() => est.arquivo.url) ? est.arquivo.url : null,
								nome: est.nome_fantasia,
								email: Isset(() => est.estabelecimentos_contatos[0].contato)
									? est.estabelecimentos_contatos[0].contato
									: '',
								documento: Isset(() => est.estabelecimentos_documentos[0].documento)
									? est.estabelecimentos_documentos[0].documento
									: '',
							},
						});
						setErrors({
							...errors,
							estabelecimentoMessage: null,
						});
					}
				} else {
					setErrors({
						...errors,
						estabelecimentoMessage: 'Estabelecimento não encontrado.',
					});
				}
			};

			getEstabelecimento();
		}
	}, [localCpfCnpj]);
	const [valoresInseridos, setValoresInseridos] = useState(0);
	const [saldoRestante, setSaldoRestante] = useState(saldo.atual);
	useEffect(() => {
		setSaldoRestante(saldo.atual - valoresInseridos);
	}, [saldo.atual, valoresInseridos]);
	return (
		<div className={classes.transferencia}>
			<Dialog open={open} onClose={handleClose}>
				<DialogContent className={classes.content}>
					<Paper className={classes.saldoBox}>
						<div className={classes.saldoAtual}>Saldo Atual: {Formatter.Real(saldo.atual)} </div>
						<div className={classes.saldoFuturo}>Saldo ao finalizar: {Formatter.Real(saldoRestante)} </div>
					</Paper>

					<Grid item xs={12} align="start" style={{ marginTop: 10 }}>
						<FormControlLabel
							control={
								<Switch
									checked={switcher}
									onChange={() => {
										setSwitcher(!switcher);
										setData({ ...data, agendadoPara: switcher ? null : minDate });
									}}
									value="checkedB"
									color="primary"
								/>
							}
							label="Agendar transferência"
						/>
					</Grid>
					{is7pay && (
						<Grid item xs={12} align="start" style={{ marginTop: 3 }}>
							<FormControlLabel
								control={
									<Switch
										checked={switcherMultiplas}
										onChange={() => {
											setSwitcherMultiplas(!switcherMultiplas);
											// setIsMultiplas({
											// 	...isMultiplas,
											// 	setIsMultiplas: switcherMultiplas ? true : false,
											// });
										}}
										value="checkedB"
										color="primary"
									/>
								}
								label="Transferência multipla (P2P)"
							/>
						</Grid>
					)}
					{/* {switcherMultiplas && (
						<Grid item xs={12} align="start" style={{ marginTop: 3 }}>
							<FormControlLabel
								control={
									<Switch
										checked={switcherTodosEcs}
										onChange={() => {
											setSwitcherTodosEcs(!switcherTodosEcs);
											// setIsMultiplas({
											// 	...isMultiplas,
											// 	setIsMultiplas: switcherMultiplas ? true : false,
											// });
										}}
										value="checkedB"
										color="primary"
									/>
								}
								label="Listar todos"
							/>
						</Grid>
					)} */}
					{switcher && (
						<Grid item xs={12}>
							<ZDatePicker
								modal
								textField
								label="Agendar para:"
								date={data.agendadoPara}
								minDate={minDate}
								onChange={date => setData({ ...data, agendadoPara: date })}
							/>
						</Grid>
					)}
					{!switcherMultiplas && (
						<>
							<Grid container justifyContent="center" spacing={2} style={{ marginTop: 5 }}>
								<Grid item xs={12}>
									<TextField
										className={classes.fullWidth}
										error={errors.valor}
										label="Valor"
										value={Mask.Real(data.valor)}
										onChange={e =>
											setData({ ...data, valor: Mask.OnlyNumber(e.target.value) / 100 })
										}
									/>
								</Grid>
								{errors.valorMessage && (
									<Grid item xs={12} style={{ textAlign: 'center' }}>
										<div className={classes.error}>{errors.valorMessage}</div>
									</Grid>
								)}
							</Grid>

							<Grid container justifyContent="center" spacing={2}>
								<Grid item xs={6}>
									<div
										className={clsx(classes.formaTransferencia, {
											selected: data.formaTransferencia === 1,
										})}
										onClick={() => setData({ ...data, formaTransferencia: 1 })}
									>
										Conta Digital
									</div>
								</Grid>
								<Grid item xs={6}>
									<div
										className={clsx(classes.formaTransferencia, {
											selected: data.formaTransferencia === 2,
										})}
										onClick={() => setData({ ...data, formaTransferencia: 2 })}
									>
										Conta Bancária
									</div>
								</Grid>
							</Grid>

							{data.formaTransferencia === 1 && (
								<Grid container justifyContent="center" spacing={2}>
									<Grid item xs={12}>
										<TextField
											className={classes.fullWidth}
											error={errors.cpfcnpj}
											label="CPF / CNPJ"
											autoComplete="off"
											// value={
											// 	data.cpfcnpj.length === 11
											// 		? maskCpf(data.cpfcnpj)
											// 		: data.cpfcnpj.length === 17
											// 		? Mask.Cnpj(data.cpfcnpj)
											// 		: data.cpfcnpj
											// }
											// onChange={
											// 	!props.documento
											// 		? e => {
											// 				// console.log(data.cpfcnpj);
											// 				// console.log(
											// 				// 	e.target.value +
											// 				// 		' target.value' +
											// 				// 		`lenght ${e.target.value.length}`
											// 				// );J
											// 				// setData({ ...data, cpfcnpj: e.target.value });
											// 				const value =
											// 					e.target.value.length === 11
											// 						? maskCpf(e.target.value)
											// 						: e.target.value.length === 17
											// 						? Mask.Cnpj(e.target.value)
											// 						: e.target.value;
											// 				setData({ ...data, cpfcnpj: value });
											// 		  }
											// 		: () => {}
											// }
											value={
												localCpfCnpj.length === 11
													? maskCpf(localCpfCnpj)
													: localCpfCnpj.length === 14
													? Mask.Cnpj(localCpfCnpj)
													: localCpfCnpj
											}
											onChange={!props.documento ? onChangeCpfCnpj : () => {}}
										/>
									</Grid>

									{Isset(() => errors.estabelecimentoMessage) &&
										errors.estabelecimentoMessage !== null && (
											<Grid item xs={12} style={{ textAlign: 'center' }}>
												<div className={classes.error}>{errors.estabelecimentoMessage}</div>
											</Grid>
										)}

									{data.estabelecimento.id !== null && data.estabelecimento.length > 1 ? (
										data.estabelecimento.map(e => {
											return (
												<>
													<Grid
														Grid
														container
														className={classes.opcoesTransferir}
														justifyContent="center"
														spacing={2}
														onClick={() =>
															setData({
																...data,
																estabelecimento: {
																	id: e.id,
																	logo: Isset(() => e.logo) ? e.logo : null,
																	nome: e.nome,
																	email: Isset(() => e.email) ? e.email : '',
																	documento: Isset(() => e.documento)
																		? e.documento
																		: '',
																},
															})
														}
													>
														<Grid item xs={12}>
															{e.logo !== null && (
																<Grid item xs={12} style={{ textAlign: 'center' }}>
																	<img src={e.logo} alt="Logo" height={100} />
																</Grid>
															)}
															<Grid item xs={12}>
																<div className={classes.field}>
																	<div className={classes.label}>Transferir Para</div>
																	<div>{e.nome}</div>
																</div>
															</Grid>
															<Grid item xs={12}>
																<div className={classes.field}>
																	<div className={classes.label}>CPF / CNPJ</div>
																	<div>
																		{e.documento.length <= 11
																			? Mask.Cpf(e.documento)
																			: Mask.Cnpj(e.documento)}
																	</div>
																</div>
															</Grid>
															<Grid item xs={12}>
																<div className={classes.field}>
																	<div className={classes.label}>E-mail</div>
																	<div>{e.email}</div>
																</div>
															</Grid>
														</Grid>
													</Grid>
												</>
											);
										})
									) : (
										<>
											{data.estabelecimento.logo !== null && (
												<Grid
													item
													xs={12}
													style={{ textAlign: 'center', backgroundColor: '#efefef' }}
												>
													<img src={data.estabelecimento.logo} alt="Logo" height={100} />
												</Grid>
											)}
											<Grid item xs={12}>
												<div className={classes.field}>
													<div className={classes.label}>Transferindo Para</div>
													<div>{data.estabelecimento.nome}</div>
												</div>
											</Grid>
											<Grid item xs={6}>
												<div className={classes.field}>
													<div className={classes.label}>CPF / CNPJ</div>
													<div>
														{data.estabelecimento.documento.length <= 11
															? Mask.Cpf(data.estabelecimento.documento)
															: Mask.Cnpj(data.estabelecimento.documento)}
													</div>
												</div>
											</Grid>
											<Grid item xs={6}>
												<div className={classes.field}>
													<div className={classes.label}>E-mail</div>
													<div>{data.estabelecimento.email}</div>
												</div>
											</Grid>
										</>
									)}
								</Grid>
							)}

							{data.formaTransferencia === 2 && (
								<Grid container justifyContent="center" spacing={2}>
									{errors.contaBancaria && (
										<Grid item xs={12} style={{ textAlign: 'center' }}>
											<div className={classes.error}>Selecione uma Conta Bancária</div>
										</Grid>
									)}
									{loadingContaBancaria ? (
										<ZLoader height={100} message="Carregando Contas Bancárias" />
									) : (
										<>
											{contasBancarias.length === 0 ? (
												<Grid item xs={12} style={{ textAlign: 'center' }}>
													<div className={classes.error}>
														Nenhuma Conta Bancária Encontrada.
													</div>
													<div
														className={clsx(classes.error, classes.errorLink)}
														onClick={() =>
															history.push(
																'/estabelecimentos/configuracoes?t=contaBancaria'
															)
														}
													>
														Clique aqui para cadastrar uma.
													</div>
												</Grid>
											) : (
												<React.Fragment>
													{contasBancarias.map((c, i) => (
														<Paper
															key={i}
															className={clsx(classes.contaBancaria, {
																selected: data.contaBancariaId === c.id,
															})}
															onClick={() => setData({ ...data, contaBancariaId: c.id })}
														>
															<Grid container item xs={12}>
																<Grid item xs={6}>
																	<div className={classes.label}>{c.banco}</div>
																</Grid>
																<Grid item xs={6}>
																	<div
																		className={classes.label}
																		style={{ textAlign: 'right' }}
																	>
																		{c.tipoContaBancaria === 1
																			? 'Conta Corrente'
																			: 'Conta Poupança'}
																	</div>
																</Grid>
																<Grid item xs={12}>
																	<div className={classes.field}>
																		<div className={classes.label}>Titular</div>
																		<div>{c.nomeTitular}</div>
																	</div>
																</Grid>
																<Grid item xs={6}>
																	<div className={classes.field}>
																		<div className={classes.label}>Agência</div>
																		<div>{c.agencia}</div>
																	</div>
																</Grid>
																<Grid item xs={6}>
																	<div className={classes.field}>
																		<div className={classes.label}>Conta</div>
																		<div>{c.conta}</div>
																	</div>
																</Grid>
															</Grid>
														</Paper>
													))}
												</React.Fragment>
											)}
										</>
									)}
								</Grid>
							)}
						</>
					)}
					{switcherMultiplas && (
						<MultiplasTransferencias
							setValoresInseridos={setValoresInseridos}
							setEstabelecimentosTransferencias={setEstabelecimentosTransferencias}
							estabelecimentosTransferencias={estabelecimentosTransferencias}
							error={errors}
							switcherTodosEcs={true}
							finished={finished}
							loading={loadingAllResquest}
						/>
					)}
					<Grid item xs={12} style={{ marginTop: 4 }}>
						<TextField
							className={classes.fullWidth}
							label="Descrição"
							value={data.descricao}
							onChange={e => setData({ ...data, descricao: e.target.value })}
						/>
					</Grid>
					<Grid item xs={12} style={{ marginTop: 4 }}>
						<TextField
							className={classes.fullWidth}
							error={errors.senha}
							type="password"
							label="Senha"
							inputProps={{
								autoComplete: 'new-password',
								form: {
									autoComplete: 'off',
								},
							}}
							value={data.senha}
							onChange={e => setData({ ...data, senha: e.target.value })}
						/>
					</Grid>
				</DialogContent>
				<DialogActions>
					{sending ? (
						<ZLoader size={30} height={60} />
					) : (
						<React.Fragment>
							<Button onClick={handleClose} className={classes.enviarCancelar}>
								Cancelar
							</Button>
							<Button
								onClick={data.estabelecimento.length > 1 ? () => {} : handleSubmit}
								className={classes.enviarCancelar}
								autoFocus
								disabled={loadingAllResquest}
							>
								{loadingAllResquest ? <ZLoader size={20} height={30} /> : 'Enviar'}
							</Button>
						</React.Fragment>
					)}
				</DialogActions>
			</Dialog>
		</div>
	);
};
