/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { addMessage } from '../../store/alert';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import config from 'react-global-configuration';
import Check from '@material-ui/icons/Check';
import Grow from '@material-ui/core/Grow';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import ZText from '../system/ZText';
import queryString from 'query-string';

import { Isset, getGrupoPermission, validatePassword } from '../../utils';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
	configuracoes: {
		fontFamily: "'Exo 2', sans-serif",
		width: '100%',
		flexGrow: 1,
	},
	header: {
		fontWeight: 700,
		fontSize: 20,
		margin: 8,
	},
	tabPanels: {
		paddingTop: 10,
	},
	configBox: {
		position: 'relative',
		padding: 10,
		minHeight: 150,
	},
	configHeader: {
		fontWeight: 700,
		fontSize: 16,
		margin: 4,
	},
	configUpdateBtn: {
		textAlign: 'center',
		textTransform: 'uppercase',
		paddingBottom: 15,
		fontWeight: 700,
		fontSize: 12,
		cursor: 'pointer',
		color: theme.palette.primary.main,
	},
	colorPicker: {
		'& .MuiTextField-root': {
			width: '100% !important',
		},
	},
	icon: {
		color: theme.palette.primary.main,
	},
	updateCheck: {
		position: 'absolute',
		top: 10,
		right: 10,
	},
	passwordInstruction: {
		fontSize: 12,
	},
}));

const tabsPermissions = {
	conta: ['all'],
};

export default props => {
	const dispatch = useDispatch();
	const location = useLocation();

	const token = useSelector(state => state.auth.token);
	const usuario = useSelector(state => state.auth.usuario);

	const query = queryString.parse(location.search);

	const [permissions, setPermissions] = useState({});

	const [senha, setSenha] = useState({
		current: '',
		new: '',
		confirm: '',
		errors: false,
	});

	const [slugUpdated] = useState(null);
	const [tabSelected, setTabSelected] = useState(0);

	const classes = useStyles();

	useEffect(() => {
		if (Isset(() => query.t)) {
			switch (query.t) {
				case 'conta':
					setTabSelected(0);
					break;
				default:
			}
		} else {
			setTabSelected(0);
		}
	}, []);

	const handleAtualizarClick = async value => {
		if (senha.new === '' || senha.current === '' || senha.confirm === '') {
			setSenha({ ...senha, errors: ['Preencha todos os campos'] });
			return false;
		}

		const validacao = validatePassword(senha.new, senha.confirm);
		console.log(validacao);
		if (validacao.success) {
			const res = await axios.put(`${config.get('apiUrl')}usuarios/alterar_senha/`, senha, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			if (res.data.success) {
				dispatch(addMessage({ type: 'success', message: res.data.message }));
				setSenha({
					errors: false,
					current: '',
					new: '',
					confirm: '',
				});
			} else {
				dispatch(addMessage({ type: 'error', message: res.data.message }));
			}
		}
		setSenha({
			...senha,
			errors: validacao.errors,
		});
	};

	useEffect(() => {
		const usuarioPermission = getGrupoPermission(usuario.grupo_id);
		const auxPermissions = Object.keys(tabsPermissions).map(key => {
			const permission = tabsPermissions[key];
			return [key, permission.filter(value => usuarioPermission.includes(value)).length > 0];
		});

		const perm = {};
		auxPermissions.forEach(a => {
			perm[a[0]] = a[1];
		});

		if (!Isset(() => query.t)) {
			setTabSelected(Object.values(perm).findIndex(b => b));
		}
		setPermissions(perm);
	}, [usuario]);

	const handleChange = (e, value) => setTabSelected(value);

	return (
		<div className={classes.configuracoes}>
			<div className={classes.header}>Configurações - {usuario.nome}</div>

			<Tabs value={tabSelected} onChange={handleChange} centered>
				{permissions.conta && <Tab label="Conta" value={0} />}
			</Tabs>
			<div className={classes.tabPanels}>
				{permissions.conta && tabSelected === 0 && (
					<Grid container spacing={3}>
						<Grid item xs={12} sm={6} md={4} lg={3}>
							<Typography component="div" role="tabpanel">
								<Grid item xs={12}>
									<Paper>
										<div className={classes.configBox}>
											<div className={classes.updateCheck}>
												<Grow in={slugUpdated === 'primary_color'}>
													<Check className={classes.icon} />
												</Grow>
											</div>

											<div className={classes.configHeader}>Alterar senha</div>
											<p className={classes.passwordInstruction}>
												<small>
													A Senha deve conter mais de 8 caracteres, letras e números. Além de
													letras maiúsculas e caracteres especiais
												</small>
											</p>
											<Grid item xs={12}>
												<ZText
													label="Senha atual"
													onBlur={e => setSenha({ ...senha, current: e.target.value })}
													value={senha.current}
													type="password"
													id="current"
												/>
											</Grid>
											<Grid item xs={12}>
												<ZText
													label="Nova senha"
													onBlur={e => setSenha({ ...senha, new: e.target.value })}
													value={senha.new}
													type="password"
													id="new"
												/>
											</Grid>
											<Grid item xs={12}>
												<ZText
													label="Confirme a nova senha"
													onBlur={e => setSenha({ ...senha, confirm: e.target.value })}
													value={senha.confirm}
													type="password"
													id="confirm"
												/>
											</Grid>
											{senha.errors.length && (
												<Grid item align="center" xs={12}>
													<small style={{ color: 'red' }}> {senha.errors}</small>
												</Grid>
											)}
										</div>
										<div
											className={classes.configUpdateBtn}
											onClick={() => handleAtualizarClick(senha)}
										>
											Alterar
										</div>
									</Paper>
								</Grid>
							</Typography>
						</Grid>
					</Grid>
				)}
			</div>
		</div>
	);
};
