import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function ZModalWarning(props) {
  const [open, setOpen] = React.useState(props.open || false);

  function handleClose() {
    props.handleClose(false);
    setOpen(false);
  }

  React.useEffect(() => {
    setOpen(props.open);
  }, [props]);

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {props.description}
          </DialogContentText>
          {props.content}
        </DialogContent>
        <DialogActions>
          {props.button}
        </DialogActions>
      </Dialog>
    </div>
  );
}