import React, { useState, useEffect, useMemo, useCallback } from 'react';
// import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import ZTitle from '../system/ZTitle';
import ZLoader from '../system/ZLoader';
import ZPagination from '../system/ZPagination';
import ZModalConfirm from '../system/ZModalConfirm';
import { Mask, Isset } from '../../utils';
import axios from 'axios';
import config from 'react-global-configuration';
import { addMessage } from '../../store/alert';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { FaEye, FaDollarSign, FaWhatsapp, FaShare, FaTrashAlt } from 'react-icons/fa';
import { makeStyles } from '@material-ui/core/styles';
import ZText from '../system/ZText';
import { useHistory } from 'react-router-dom';
import ZFiltros from '../system/ZFiltros';
import FileDownload from 'js-file-download';
import clsx from 'clsx';
import { FaDownload } from 'react-icons/fa';

const useStyles = makeStyles(theme => ({
	menuIcon: {
		fontSize: 16,
		cursor: 'pointer',
		marginRight: 4,
	},
}));

export default props => {
	const history = useHistory();
	const dispatch = useDispatch();
	const token = useSelector(store => store.auth.token);
	const url = useSelector(store => store.system.config.hostname);
	const estabelecimentoAdmin = useSelector(store => store.admin.estabelecimento);
	const usuario = useSelector(store => store.auth.usuario);
	const { pathname } = history.location;
	const pathnameSlug = pathname.toLowerCase().replace(/[^a-z0-9]/gi, '');
	const filtersCache = useSelector(store => store.filters[pathnameSlug] || {});
	const classes = useStyles();
	const isNewLink = [1, 14, 17, 151].includes(usuario.estabelecimento.marketplace.id) ? true : false;
	const [loading, setLoading] = useState(true);
	const [preVendas, setPreVendas] = useState([]);

	// Paginação

	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(15);
	const [totalRows, setTotalRows] = useState(0);

	const [removePreVenda, setRemovePreVenda] = useState({});
	const [removeModalOpen, setRemoveModalOpen] = useState(false);
	const [sendEmailOpen, setSendEmailOpen] = useState(false);
	const [sendEmailData, setSendEmailData] = useState(null);
	const [email, setEmail] = useState('');
	const [filtros, setFiltros] = useState({
		id: '',
		titulo: '',
		valor: 0,
		...filtersCache,
	});

	const handleSendEmail = async result => {
		try {
			if (result && email) {
				const { preVenda, subject, text, titulo, descricao } = sendEmailData;

				if (!preVenda) {
					throw new Error('preVenda undefined');
				}

				const res = await axios.post(
					`${config.get('apiUrl')}pre-venda/send-mail/${preVenda.id}`,
					{
						subject,
						descricao,
						titulo,
						text,
						email,
					},
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				);
			}
		} catch (error) {
			console.log(error);
		}

		setSendEmailOpen(false);
		setSendEmailData(null);
	};

	const onChangePage = newPage => {
		setPage(newPage);
	};

	const onChangeRowsPerPage = rowsPerPage => {
		setRowsPerPage(rowsPerPage);
		setPage(1);
	};

	const getPreVendas = () => {
		setLoading(true);
		axios
			.get(`${config.get('apiUrl')}pre_venda`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
				params: {
					page,
					limit: rowsPerPage,
					id: filtros.omni ? +filtros.omni : '',
					titulo: filtros.omni2 ? filtros.omni2 : '',
					valor: filtros.valor ? parseInt(filtros.valor.replace(/[^\d]+/g, '')) / 100 : '',
					filters: {
						estabelecimentoId: Isset(() => estabelecimentoAdmin.id) ? estabelecimentoAdmin.id : null,
					},
				},
			})
			.then(res => res.data)
			.then(data => {
				setLoading(false);
				if (data.success) {
					setPreVendas(data.pre_vendas);
					setTotalRows(data.quantidade);
				}
			});
	};

	useEffect(() => {
		getPreVendas();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [estabelecimentoAdmin, filtros, page, rowsPerPage]);

	const handleRemovePreVenda = async result => {
		setRemoveModalOpen(false);

		const { id: preVendaId } = removePreVenda;

		if (result && preVendaId) {
			const res = await axios.delete(`${config.get('apiUrl')}pre_venda/${preVendaId}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			if (res.data.success) {
				dispatch(addMessage({ type: 'success', message: 'Link de Pagamento removido com sucesso' }));
				getPreVendas();
			}
		}

		setRemovePreVenda({});
	};

	const formaPagamento = useMemo(() => {
		return preVendas.map(preVenda => {
			switch (preVenda.tipo_pagamento_id) {
				case 1:
					return 'Boleto';
				case 2:
					return 'Débito';
				case 3:
					return 'Cartão de Crédito';
				case 4:
					return 'Comissão';
				case 5:
					return 'Voucher';
				case 6:
					return 'Pix';
				case 8:
					return isNewLink ? 'Todos' : 'Boleto ou Crédito';
				case 9:
					return 'NuPay';
				default:
					return 'Link de Pagamento';
			}
		});
	}, [preVendas]);

	const handleFiltrosChange = useCallback(data => {
		setFiltros(filtros => ({ ...filtros, ...data }));
	}, []);

	const exportXls = async () => {
		const params = {
			page,
			limit: rowsPerPage,
			id: filtros.omni ? +filtros.omni : '',
			titulo: filtros.omni2 ? filtros.omni2 : '',
			valor: filtros.valor ? parseInt(filtros.valor.replace(/[^\d]+/g, '')) / 100 : '',
			filters: {
				estabelecimentoId: Isset(() => estabelecimentoAdmin.id) ? estabelecimentoAdmin.id : null,
			},
		};

		const preVendas = await axios.get(`${config.get('apiUrl')}export/pre-vendas`, {
			headers: { Authorization: `Bearer ${token}` },
			responseType: 'blob',
			params,
		});

		if (preVendas.status === 200) {
			const { 'content-disposition': contentDisposition, 'content-type': contentType } = preVendas.headers;
			let filename = 'links-de-pagamento.xlsx';
			if (contentDisposition.indexOf('filename="') !== -1) {
				filename = contentDisposition.slice(
					contentDisposition.indexOf('filename="') + 10,
					contentDisposition.length - 1
				);
			}

			FileDownload(preVendas.data, filename, contentType);
		} else {
			dispatch(addMessage({ type: 'error', message: 'Erro ao exportar links de pagamentos.' }));
		}
	};

	return (
		<Grid container>
			<ZTitle title="Links de Pagamentos" button="add" handleOnClick={() => history.push(`/venda-digitada`)} />
			<Grid item xs={12}>
				<Paper
					style={{
						position: 'relative',
						marginBottom: 10,
						padding: '5px 10px',
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<ZFiltros
						onChange={handleFiltrosChange}
						input={[
							{
								key: 'omni',
								defaultValue: filtros.id,
								placeholder: 'ID',
								modal: false,
								md: 2,
							},
							{
								key: 'omni2',
								placeholder: 'Título',
								modal: false,
								defaultValue: filtros.titulo,
								md: 2,
							},
							{
								key: 'valor',
								placeholder: 'Valor',
								defaultValue: filtros.valor,
								mask: 'real',
								md: 2,
								modal: false,
							},
						]}
					/>
					<Tooltip title="Exportar">
						<Button className={clsx(classes.buttonExport, classes.iconButton)} onClick={exportXls}>
							<FaDownload className={classes.exportDownload} />
						</Button>
					</Tooltip>
				</Paper>
			</Grid>
			<Grid item xs={12}>
				{loading ? (
					<ZLoader height="200px" size={40} />
				) : (
					<Paper style={{ overflowX: 'auto', width: 'calc(100vw - 45px)' }}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>ID</TableCell>
									<TableCell>Titulo</TableCell>
									<TableCell align="right">Pedidos</TableCell>
									<TableCell align="right">Valor</TableCell>
									<TableCell align="right">Data expiração</TableCell>
									<TableCell align="right">Criado</TableCell>
									<TableCell align="right">Forma Pagamento</TableCell>
									<TableCell align="right"></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								<>
									{preVendas.map((preVenda, key) => (
										<TableRow hover key={key}>
											<TableCell component="th" scope="row">
												{preVenda.id}
											</TableCell>
											<TableCell component="th" scope="row">
												{preVenda.titulo}
											</TableCell>
											<TableCell align="right">{preVenda.pre_venda_pedidos.length}</TableCell>
											<TableCell align="right">{Mask.Real(preVenda.amount / 100)}</TableCell>
											<TableCell align="right">
												{moment(preVenda.data_expiracao).format('DD/MM/YYYY')}
											</TableCell>
											<TableCell align="right">
												{moment(preVenda.createdAt).format('DD/MM/YYYY')}
											</TableCell>
											<TableCell align="right">{formaPagamento[key]}</TableCell>
											<TableCell align="right">
												<Tooltip title="Visualizar">
													<span>
														<FaEye
															className={classes.menuIcon}
															onClick={() => {
																history.push('/venda-digitada/' + preVenda.id);
															}}
														/>
													</span>
												</Tooltip>
												{/* {!Isset(() => preVenda.pre_venda_pedidos[0].pedido_id) && ( */}
												{/* <> */}
												<Tooltip title="Tela de Pagamento">
													<span>
														<FaDollarSign
															className={classes.menuIcon}
															onClick={() => {
																if (isNewLink) {
																	window.open('/link?' + preVenda.token, '__blank');
																} else {
																	window.open('/lp/' + preVenda.token, '__blank');
																}
															}}
															// onClick={() => {
															// 	window.open(
															// 		'/venda/token/' + preVenda.token,
															// 		'__blank'
															// 	);
															// }}
														/>
													</span>
												</Tooltip>
												<Tooltip title="Compartilhar no Whatsapp">
													<span>
														<FaWhatsapp
															className={classes.menuIcon}
															onClick={() => {
																let text;
																if (isNewLink) {
																	text = encodeURIComponent(
																		`Link de Pagamento - ${
																			preVenda.estabelecimento.nome_fantasia
																		}\n\n*${preVenda.titulo.trim()}*\nhttps://${url}/link?${
																			preVenda.token
																		}`
																	);
																} else {
																	text = encodeURIComponent(
																		`Link de Pagamento - ${
																			preVenda.estabelecimento.nome_fantasia
																		}\n\n*${preVenda.titulo.trim()}*\nhttps://${url}${config.get(
																			'baseName'
																		)}/lp/${preVenda.token}`
																	);
																}
																window.open(
																	`https://web.whatsapp.com/send?text=${text}`,
																	'_blank'
																);
															}}
														/>
													</span>
												</Tooltip>
												<Tooltip title="Enviar por E-mail">
													<span>
														<FaShare
															className={classes.menuIcon}
															onClick={() => {
																const subject = `Link de Pagamento - ${preVenda.estabelecimento.nome_fantasia}`;
																let text;
																if (isNewLink) {
																	text = `https://${url}/link?${preVenda.token}`;
																} else {
																	text = `https://${url}${config.get(
																		'baseName'
																	)}/lp/${preVenda.token}`;
																}
																const titulo = preVenda.titulo.trim();
																const descricao = preVenda.descricao.trim();
																// const text = `\n\n*${preVenda.titulo.trim()}*\nhttps://${url}${config.get(
																// 	'baseName'
																// )}/lp/${preVenda.token}`;
																// const text = encodeURIComponent(
																// 	`\n\n*${preVenda.titulo.trim()}*\nhttps://${url}${config.get(
																// 		'baseName'
																// 	)}/venda/token/${preVenda.token}`
																// );
																setSendEmailData({
																	preVenda,
																	subject,
																	text,
																	titulo,
																	descricao,
																});
																setSendEmailOpen(true);
																// window.open(
																// 	`mailto:?subject=${subject}&body=${text}`,
																// 	'_blank'
																// );
															}}
														/>
													</span>
												</Tooltip>
												{/* </> */}
												{/* )} */}
												<Tooltip title="Remover Link de Pagamento">
													<span>
														<FaTrashAlt
															className={classes.menuIcon}
															onClick={() => {
																setRemovePreVenda(preVenda);
																setRemoveModalOpen(true);
															}}
														/>
													</span>
												</Tooltip>
												{/* {Isset(() => preVenda.pre_venda_pedidos[0].pedido_id) && (
													<Tooltip title="Visualizar Pedido">
														<span>
															<FaReceipt
																className={classes.menuIcon}
																onClick={() => {
																	history.push(
																		'/vendas/detalhes/' +
																			preVenda.pre_venda_pedidos[0].pedido_id
																	);
																}}
															/>
														</span>
													</Tooltip>
												)} */}
											</TableCell>
										</TableRow>
									))}
								</>
							</TableBody>
							<ZPagination
								count={totalRows}
								rowsPerPage={rowsPerPage}
								page={page - 1}
								onChangePage={onChangePage}
								onChangeRowsPerPage={onChangeRowsPerPage}
							/>
						</Table>
					</Paper>
				)}
			</Grid>
			<ZModalConfirm
				title={`Remover Link de Pagamento`}
				message={`Deseja remover esse Link de Pagamento (${removePreVenda.titulo || ''})?`}
				open={removeModalOpen}
				onClose={handleRemovePreVenda}
			/>
			<ZModalConfirm
				title={`Enviar por E-mail`}
				message={
					<div>
						<div>{Isset(() => sendEmailData.preVenda.titulo) ? sendEmailData.preVenda.titulo : ''}</div>
						<ZText
							type="email"
							label="E-mail"
							required={true}
							value={email}
							onBlur={e => setEmail(e.target.value)}
						/>
					</div>
				}
				open={sendEmailOpen}
				onClose={handleSendEmail}
			/>
		</Grid>
	);
};
