/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import { addMessage } from '../../store/alert';

import ZLoader from '../system/ZLoader';
import Taxas from './Taxas';

import axios from 'axios';
import config from 'react-global-configuration';

const useStyles = makeStyles(theme => ({
	header: {
		fontWeight: 700,
		fontSize: 20,
		margin: 8,
	},
	planoTaxaPaper: {
		padding: 10,
	},
	dialogPVs: {
		position: 'absolute',
		top: 70,
		left: 10,
		zIndex: 1101,
	},
	dialogPVsPaper: {
		width: 240,
		minHeight: 150,
		padding: 10,
		backgroundColor: '#f1f1f1',
	},
	dialogPV: {
		padding: 2,
		fontWeight: 'bold',
		'&:not(:last-child)': {
			borderBottom: 'solid 1px #abc',
		},
	},
	backdrop: {
		position: 'fixed',
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		backgroundColor: 'black',
		opacity: 0.5,
		zIndex: 1100,
	},
	planosVendasEmpty: {
		fontVariant: 'small-caps',
		textAlign: 'center',
		fontSize: 16,
		fontWeight: 'bold',
	},
}));

// const PvDialogItem = memo(props => {
// 	const { plano, checked, onChange } = props;
// 	const classes = useStyles();
// 	return (
// 		<Grid container className={classes.dialogPV} alignItems="center">
// 			<Grid item xs={10}>
// 				{plano.titulo}
// 			</Grid>
// 			<Grid item xs={2}>
// 				<Checkbox checked={checked} size="small" onChange={onChange} value={plano.id} color="primary" />
// 			</Grid>
// 		</Grid>
// 	);
// });

const PlanoVendaSelect = memo(props => {
	const { pvsSelected, marketplaceId } = props;

	const token = useSelector(store => store.auth.token);
	// const classes = useStyles();

	const [planosVendas, setPlanosVendas] = useState([]);
	// const [pvsDialog, setPVsDialog] = useState(false);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const getPlanosVendas = async () => {
			setLoading(true);
			const res = await axios.get(`${config.get('apiUrl')}planos_de_vendas/marketplace/${marketplaceId}`, {
				headers: { Authorization: `Bearer ${token}` },
			});

			if (res.data.success) {
				const pvs = res.data.planosVendas.map(pv => ({
					id: pv.id,
					titulo: pv.titulo,
					antecipado: pv.antecipado,
					checked: false,
				}));
				setPlanosVendas(pvs);
			}
			setLoading(false);
		};

		getPlanosVendas();
	}, []);

	// const removerPlanoVenda = pvId => {
	// 	const newPvs = pvsSelected.filter(a => a !== pvId);

	// 	setPvsSelected(newPvs);
	// };

	// const openPVs = () => {
	// 	setPVsDialog(!pvsDialog);
	// };

	// const selectPV = (checked, pvId) => {
	// 	let newPvs = pvsSelected;
	// 	if (checked) {
	// 		newPvs = [...pvsSelected, +pvId];
	// 		setPvsSelected(newPvs);
	// 	} else {
	// 		newPvs = pvsSelected.filter(a => a !== pvId);

	// 		setPvsSelected(newPvs);
	// 	}

	// 	if (onChange) {
	// 		const planos = planosVendas.filter(a => newPvs.includes(+a.id));
	// 		onChange(planos);
	// 	}
	// };

	if (loading) {
		return <ZLoader height={70} size={16} />;
	}

	return (
		<>
			{/* <div
				className={classes.backdrop}
				onClick={() => setPVsDialog(false)}
				style={{ display: pvsDialog ? 'block' : 'none' }}
			></div> */}
			<InputLabel>
				Planos de Vendas
				<div>
					{pvsSelected.length > 0 ? (
						<>
							{pvsSelected.map((pvId, i) => {
								const pv = planosVendas.find(a => a.id === pvId);
								return <Chip key={i} size="small" style={{ margin: 4 }} label={pv.titulo} />;
							})}
						</>
					) : (
						<Chip
							size="small"
							style={{ margin: 4, backgroundColor: '#f79e9e', fontWeight: 'bold' }}
							label="Nenhum Plano de Venda Selecionado"
						/>
					)}
				</div>
				{/* {planosVendas} */}
				{/* <Fab
					color="primary"
					onClick={openPVs}
					style={{
						marginLeft: 10,
						width: 30,
						height: 30,
						minHeight: 30,
						transform: [{ rotate: pvsDialog ? '90deg' : '0deg' }],
					}}
					aria-label="Add"
				>
					<AddIcon />
				</Fab> */}
			</InputLabel>
			{/* <div>
				{pvsSelected.length > 0 ? (
					<>
						{pvsSelected.map((pvId, i) => {
							const pv = planosVendas.find(a => a.id === pvId);
							return (
								<Chip
									key={i}
									size="small"
									style={{ margin: 4 }}
									onDelete={() => removerPlanoVenda(pv.id)}
									label={pv.titulo}
								/>
							);
						})}
					</>
				) : (
					<Chip
						size="small"
						style={{ margin: 4, backgroundColor: '#f79e9e', fontWeight: 'bold' }}
						label="Nenhum Plano de Venda Selecionado"
					/>
				)}
			</div> */}
			{/* <Collapse in={pvsDialog} className={classes.dialogPVs}>
				<Paper className={classes.dialogPVsPaper}>
					{planosVendas.length > 0 ? (
						<>
							{planosVendas.map(pv => (
								<PvDialogItem
									key={pv.id}
									plano={pv}
									checked={pvsSelected.find(a => a === pv.id) !== undefined}
									onChange={e => selectPV(e.target.checked, pv.id)}
								/>
							))}
						</>
					) : (
						<div className={classes.planosVendasEmpty}>Nenhum Plano de Venda Encontrado.</div>
					)}
				</Paper>
			</Collapse> */}
		</>
	);
});

export default props => {
	const { marketplaceId, planoTaxaId } = useParams();
	const classes = useStyles();
	const token = useSelector(store => store.auth.token);
	const dispatch = useDispatch();

	const [titulo, setTitulo] = useState('');
	const [pvsSelected, setPvsSelected] = useState([]);
	const [loading, setLoading] = useState(true);
	const [hasPlanoPro, setHasPlanoPro] = useState(false);

	useEffect(() => {
		const getPlanoTaxa = async () => {
			setLoading(true);
			const res = await axios.get(
				`${config.get('apiUrl')}planos_taxas/porMarketplace/${marketplaceId}/${planoTaxaId}`,
				{
					headers: { Authorization: `Bearer ${token}` },
				}
			);

			if (res.data.success) {
				const { plano } = res.data;
				setTitulo(plano.titulo);
				setPvsSelected(plano.planos_vendas.map(a => a.id));
				setHasPlanoPro(!!plano.planos_vendas.find(a => a.antecipado));
			}
			setLoading(false);
		};

		getPlanoTaxa();
	}, []);

	const btnSaveClick = async () => {
		if (!titulo || pvsSelected.length === 0) {
			dispatch(addMessage({ type: 'error', message: 'Existem campos inválidos no formulário.' }));
			return false;
		}

		const res = await axios.post(
			`${config.get('apiUrl')}planos_taxas/porMarketplace/${marketplaceId}/${planoTaxaId}`,
			{
				planosVendas: pvsSelected,
				titulo,
			},
			{
				headers: { Authorization: `Bearer ${token}` },
			}
		);

		if (res.data.success) {
			dispatch(addMessage({ type: 'success', message: 'Tabela de Markup salva com sucesso.' }));
		} else {
			dispatch(addMessage({ type: 'error', message: res.data.error }));
		}
	};

	const planosOnChange = newPvs => {
		setHasPlanoPro(!!newPvs.find(a => a.antecipado));
	};

	if (loading) {
		return <ZLoader height={200} />;
	}

	return (
		<div className={classes.planosTaxasAdd}>
			<div className={classes.header}>Editar Tabela de Markup</div>

			<Paper className={classes.planoTaxaPaper}>
				<Grid container spacing={2} justifyContent="center" alignItems="center">
					<Grid item xs={3}>
						<TextField
							label="Título"
							value={titulo}
							onChange={e => setTitulo(e.target.value)}
							style={{ width: '100%' }}
						/>
					</Grid>
					<Grid item xs={6} style={{ position: 'relative' }}>
						<PlanoVendaSelect
							pvsSelected={pvsSelected}
							setPvsSelected={setPvsSelected}
							marketplaceId={marketplaceId}
							onChange={planosOnChange}
						/>
					</Grid>
					<Grid item xs={3}>
						<Button variant="contained" color="primary" className={classes.btnAdd} onClick={btnSaveClick}>
							Salvar
						</Button>
					</Grid>
				</Grid>
			</Paper>

			<Taxas marketplaceId={marketplaceId} planoTaxaId={planoTaxaId} />

			<Taxas marketplaceId={marketplaceId} planoTaxaId={planoTaxaId} tipoVendaId={2} />
			{/* {hasPlanoPro && <Taxas marketplaceId={marketplaceId} planoTaxaId={planoTaxaId} tipoVendaId={2} />} */}
		</div>
	);
};
