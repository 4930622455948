/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { pt } from 'react-date-range/src/locale/index';
import format from 'date-fns/format';
import addDays from 'date-fns/addDays';
import addMonths from 'date-fns/addMonths';
import { Isset, Mask } from '../../utils';
import { withStyles } from '@material-ui/core/styles';

import { makeStyles } from '@material-ui/core/styles';

import { DateRange } from 'react-date-range';
import { FaTimes } from 'react-icons/fa';
import Paper from '@material-ui/core/Paper';
// import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import Container from '@material-ui/core/Container';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
// import ZText from './ZText';
import TextField from '@material-ui/core/TextField';
import ZDateRangePicker from './ZDateRangePicker';

import FiltroEstabelecimento from './ZFiltros/Estabelecimento';
import FiltroBandeira from './ZFiltros/Bandeira';
import FiltroFormaPagamento from './ZFiltros/FormaPagamento';
import FiltroStatusTransferencia from './ZFiltros/StatusTransferencia';
import FiltroTipoTransferencia from './ZFiltros/TipoTransferencia';
import FiltroStatusPreCadastro from './ZFiltros/StatusPreCadastro';
import FiltroSituacaoEstabelecimento from './ZFiltros/SituacaoEstabelecimento';
import FiltroMes from './ZFiltros/Mes';
import FiltroAno from './ZFiltros/Ano';
import Input from './ZFiltros/Input';
import SelectField from './ZFiltros/Select';
import PriceRange from './ZFiltros/PriceRange';

import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setFilters, clearFilter } from '../../store/filters';
import ZDatePicker from './ZDatePicker';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const styles = theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.getContrastText(theme.palette.primary.main) || theme.palette.grey[500],
	},
});

const useStyles = makeStyles(theme => ({
	filtros: {
		position: 'relative',
		display: 'inline-flex',
		color: theme.palette.getContrastText(theme.palette.primary.main),
	},
	filtrosDiv: {
		width: 'auto',
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
		color: theme.palette.getContrastText(theme.palette.primary.main),

		[theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
			width: 960,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	filtroText: {
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
		padding: '4px 4px',
		border: `solid 1px ${theme.palette.primary.main}`,
		borderRadius: 30,
		marginLeft: 10,
		fontWeight: 700,
		fontSize: 12,
		color: theme.palette.getContrastText(theme.palette.primary.main) || theme.palette.primary.main,
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.palette.getContrastText(theme.palette.primary.main),
	},
	button: {
		height: 37,
		color: theme.palette.getContrastText(theme.palette.primary.main) || '#FFF',
		backgroundColor: theme.palette.primary.main,

		border: `solid 1px ${theme.palette.primary.main}`,
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}`,
			color: theme.palette.getContrastText(theme.palette.primary.main) || '#FFF',
		},
	},
	filterButton: {
		height: 37,
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.getContrastText(theme.palette.primary.main) || '#FFF',

		'&:hover': {
			color: theme.palette.getContrastText(theme.palette.primary.main) || '#FFF',
			backgroundColor: `${theme.palette.primary.main}`,
		},
	},
	filterButtonWidth: {
		[theme.breakpoints.up('md')]: {
			width: '50%',
		},
	},
	paperModal: {
		backgroundColor: theme.palette.background.paper,
		color: theme.palette.getContrastText(theme.palette.primary.main),

		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
	},
	paperFiltro: {
		marginBottom: theme.spacing(3),
		padding: theme.spacing(2),
		[theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
			marginBottom: theme.spacing(6),
			padding: theme.spacing(3),
		},
	},
	filtroTextSelected: {
		backgroundColor: `${theme.palette.primary.main}`,
		color: `${theme.palette.getContrastText(theme.palette.primary.main)} !important`,
		'& .MuiSelect-select': {
			color: `${theme.palette.getContrastText(theme.palette.primary.main)} !important`,
		},
	},
	filtroTextSelectedMultiple: {
		backgroundColor: `${theme.palette.primary.main}`,
		color: `${theme.palette.getContrastText(theme.palette.primary.main)} !important`,
		'& .MuiSelect-select': {
			color: `${theme.palette.getContrastText(theme.palette.primary.main)} !important`,
		},
		padding: '2px 10px',
		minHeight: 29,
		maxWidth: 340,
	},
	filterSelected: {},
	filterBox: {
		position: 'absolute',
		borderRadius: 10,
		padding: 10,
		zIndex: 1201,
		top: '32%',
		left: '3%',
	},
	clearBtn: {
		marginLeft: 8,
	},
	clearFilter: {
		color: theme.palette.getContrastText(theme.palette.primary.main) || '#222222',
		textDecoration: 'underline',
		fontWeight: 'bold',
		'&:hover': {
			textDecoration: 'underline',
			background: 'none',
		},
	},
	backdrop: {
		position: 'fixed',
		zIndex: 1200,
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		backgroundColor: 'rgba(0,0,0,0.3)',
	},
	staticOptions: {
		textAlign: 'center',
	},
	staticOption: {
		textAlign: 'center',
		cursor: 'pointer',
	},
	optionSelected: {
		fontWeight: 700,
	},
	filtroSelect: {
		width: '80%',
		fontWeight: 700,
		fontSize: 12,
		'&:before': {
			borderBottom: 'none',
		},
		'& .MuiSelect-select': {
			padding: '2px 10px !important',
			marginRight: 10,
		},
	},
	dateRange: {
		borderRadius: 4,
		backgroundColor: theme.palette.common.white,
		border: '1px solid #ced4da',
		fontSize: 16,
		cursor: 'pointer',
		textAlign: 'center',
		padding: '6.65px 12px',
		[theme.breakpoints.up('md')]: {
			width: '100%',
		},
	},
	chips: {
		display: 'flex',
		flexWrap: 'nowrap',
	},
	chip: {
		// margin: 2,
		height: 20,
		maxWidth: 130,
		justifyContent: 'flex-start',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		'& .MuiChip-label': {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			display: 'inline-block',
		},
	},
	hasMore: {
		width: 44,
	},
	menuItem: {
		flexDirection: 'column',
	},
	omniField: {
		'& .MuiInput-input': {
			fontSize: 14,
			padding: 0,
		},
		'& .MuiInput-underline:before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
			bottom: -6,
			transform: 'scaleX(1)',
		},
		'& .MuiInput-underline:not(.Mui-focused):after': {
			borderBottom: 'solid 1px #abc',
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: 'none',
			bottom: -6,
		},
	},
	filtroOmniHasText: {
		backgroundColor: `${theme.palette.primary.main}`,
		color: `${theme.palette.getContrastText(theme.palette.primary.main)} !important`,
		'& .MuiInput-input': {
			color: `${theme.palette.getContrastText(theme.palette.primary.main)} !important`,
		},
	},
	ZDatePicker: {
		'& .z-date-picker': {
			border: '1px solid #ced4da',
			cursor: 'pointer',
			padding: '0px 12px',
			fontSize: 16,
			textAlign: 'center',
			borderRadius: 4,
			backgroundColor: '#fff',
			color: theme.palette.getContrastText(theme.palette.primary.main),
		},
		'& label + .MuiInput-formControl': {
			marginTop: 3,
		},
	},
	labelCheckbox: {
		fontSize: 16,
		fontWeight: 'bold',
	},
	gridCheckbox: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		marginTop: 40,
	},
}));

const selectedOptions = {
	today: 'Hoje',
	lastMonth: 'Último Mês',
	last7days: 'Últimos 7 Dias',
	next6months: 'Próximos 6 Meses',
	nextMonth: 'Próximo Mês',
};

const statusVendaData = [
	{ id: 1, titulo: 'Pendente' },
	{ id: 2, titulo: 'Aprovado' },
	{ id: 3, titulo: 'Falhado' },
	{ id: 4, titulo: 'Cancelado' },
	// { id: 5, titulo: 'Parcialmente Pago' },
	{ id: 6, titulo: 'Estornado' },
];

const statusServicoBancarioData = [
	{ id: 1, titulo: 'Aguardando' },
	{ id: 2, titulo: 'Aprovado' },
	{ id: 3, titulo: 'Reprovado' },
];

const DialogTitle = withStyles(styles)(props => {
	const { children, classes, onClose, ...other } = props;

	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography style={{ textAlign: 'center', fontWeight: 'bold' }} variant="h6">
				{children}
			</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles(theme => ({
	root: {
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(1),
	},
}))(MuiDialogActions);

export default props => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const { pathname } = history.location;
	const pathnameSlug = pathname.toLowerCase().replace(/[^a-z0-9]/gi, '');

	const [filtros, setFiltros] = useState({
		date1: null,
		date: {
			start: null,
			end: null,
		},
		estabelecimento: null,
		omniEstabelecimento: '',
		bandeira: null,
		formaPagamento: null,
		statusTransferencia: null,
		tipoTransferencia: null,
		statusPreCadastro: null,
		situacaoEstabelecimento: null,
		tipoCobranca: null,
		mes: null,
		ano: null,
	});

	const [date, setDate] = useState(Isset(() => props.date.defaultValue) ? props.date.defaultValue : new Date());

	// DatePicker
	const [openDate, setOpenDate] = useState(false);
	const [selectedDate, setSelectedDate] = useState(
		Isset(() => props.dateRange.default) ? props.dateRange.default : 'last7days'
	);
	const [startDate, setStartDate] = useState(
		Isset(() => props.dateRange.startDate) ? props.dateRange.startDate : new Date()
	);
	const [endDate, setEndDate] = useState(Isset(() => props.dateRange.endDate) ? props.dateRange.endDate : new Date());
	const [dataCreated, setDataCreated] = useState({
		start: Isset(() => props.created.start) ? props.created.start : new Date(),
		end: Isset(() => props.created.end) ? props.created.end : new Date(),
		label: Isset(() => props.created.label) ? props.created.label : 'Clique para selecionar a data',
	});

	const [dataProximaCobranca, setDataProximaCobranca] = useState({
		start: Isset(() => props.proximaCobranca.start) ? props.proximaCobranca.start : new Date(),
		end: Isset(() => props.proximaCobranca.end) ? props.proximaCobranca.end : new Date(),
		label: Isset(() => props.proximaCobranca.label) ? props.proximaCobranca.label : 'Clique para selecionar a data',
	});
	const [minDate] = useState(Isset(() => props.dateRange.minDate) ? props.dateRange.minDate : undefined);
	const [maxDate] = useState(Isset(() => props.dateRange.maxDate) ? props.dateRange.maxDate : undefined);
	const selectionRange = {
		startDate,
		endDate,
		key: 'selection',
	};

	// Tipo de Pagamento
	const [tipoPagamento, setTipoPagamento] = useState(
		Isset(() => props.tipoPagamento.default) ? props.tipoPagamento.default : ''
	);

	// Omni
	const [omni, setOmni] = useState(Isset(() => props.omni.default) ? props.omni.default : '');
	const [omniTypingTimeout, setOmniTypingTimeout] = useState(null);

	//EstabelecimentoText
	const [omniEstabelecimento, setOmniEstabelecimento] = useState(
		Isset(() => props.omniEstabelecimento.default) ? props.omniEstabelecimento.default : ''
	);

	//State que armazena todos as keys do filtro
	const [data, setData] = useState({
		date1: date,
		date: {
			start: startDate,
			end: endDate,
		},
		estabelecimento: null,
		omniEstabelecimento: '',
		bandeira: null,
		formaPagamento: null,
		statusTransferencia: null,
		tipoTransferencia: null,
		statusPreCadastro: null,
		situacaoEstabelecimento: null,
		tipoCobranca: null,
		mes: null,
		ano: null,
		transacoesSuspeitas: false,
	});

	// Omni 2
	const [omni2, setOmni2] = useState(Isset(() => props.omni2.default) ? props.omni2.default : '');
	// const [reference, setReference] = useState(Isset(() => props.reference.default) ? props.reference.default : '');
	const [omni2TypingTimeout, setOmni2TypingTimeout] = useState(null);

	// searchEstabelecimentoByCpfOrCNPJ
	const [searchEstabelecimentoByCpfOrCNPJ, setSearchEstabelecimentoByCpfOrCNPJ] = useState(
		Isset(() => props.searchEstabelecimentoByCpfOrCNPJ.value) ? props.searchEstabelecimentoByCpfOrCNPJ.value : ''
	);

	// nomeComprovante
	const [nomeComprovante, setNomeComprovante] = useState(
		Isset(() => props.nomeComprovante.value) ? props.nomeComprovante.value : ''
	);

	// Tipo de Transacao
	const [tipoTransacao, setTipoTransacao] = useState(
		Isset(() => props.tipoTransacao.default) ? props.tipoTransacao.default : ''
	);

	const [open, setOpen] = useState(false);

	// Tipo da Cobrança
	const [tipoCobranca, setTipoCobranca] = useState(
		Isset(() => props.tipoCobranca.defaultValue) ? props.tipoCobranca.defaultValue : ''
	);

	// Status Venda
	const [statusVenda, setStatusVenda] = useState(
		Isset(() => props.statusVenda.default) ? props.statusVenda.default : ''
	);

	// Status Venda
	const [servicosBancarios, setServicosBancarios] = useState(
		Isset(() => props.setServicosBancarios.default) ? props.setServicosBancarios.default : ''
	);

	useEffect(() => {
		// Seta filtros iniciais que estao separados em outro componente
		setFiltros({
			...filtros,
			date1: Isset(() => props.date.defaultValue) ? props.date.defaultValue : null,
			estabelecimento: Isset(() => props.estabelecimento.defaultValue)
				? props.estabelecimento.defaultValue
				: null,
			omniEstabelecimento: Isset(() => props.omniEstabelecimento.default)
				? props.omniEstabelecimento.default
				: '',
			bandeira: Isset(() => props.bandeira.defaultValue) ? props.bandeira.defaultValue : null,
			formaPagamento: Isset(() => props.formaPagamento.defaultValue) ? props.formaPagamento.defaultValue : null,
			statusTransferencia: Isset(() => props.statusTransferencia.defaultValue)
				? props.statusTransferencia.defaultValue
				: null,
			tipoTransferencia: Isset(() => props.tipoTransferencia.defaultValue)
				? props.tipoTransferencia.defaultValue
				: null,
			statusPreCadastro: Isset(() => props.statusPreCadastro.defaultValue)
				? props.statusPreCadastro.defaultValue
				: null,
			situacaoEstabelecimento: Isset(() => props.situacaoEstabelecimento.defaultValue)
				? props.situacaoEstabelecimento.defaultValue
				: null,
			mes: Isset(() => props.mes.defaultValue) ? props.mes.defaultValue : null,
			ano: Isset(() => props.ano.defaultValue) ? props.ano.defaultValue : null,
		});

		setData({
			...data,
			mes: Isset(() => props.mes.defaultValue) ? props.mes.defaultValue : null,
			ano: Isset(() => props.ano.defaultValue) ? props.ano.defaultValue : null,
		});
	}, []);

	const getFiltrosData = data => {
		const result = {};

		if (props.date) {
			result.date1 = filtros.date1;
		}

		if (props.dateRange) {
			result.date = {
				start: startDate,
				end: endDate,
			};
		}

		if (data.reference) {
			result.reference = data.reference;
		}

		if (props.tipoPagamento) {
			result.tipoPagamento = tipoPagamento;
		}
		if (props.mes) {
			result.mes = filtros.mes;
		}
		if (props.ano) {
			result.ano = filtros.ano;
		}
		if (props.estabelecimento) {
			result.estabelecimento = filtros.estabelecimento;
		}
		if (props.bandeira) {
			result.bandeira = filtros.bandeira;
		}

		if (props.statusServicoBancario) {
			result.statusServicoBancario = servicosBancarios;
		}
		if (props.formaPagamento) {
			result.formaPagamento = filtros.formaPagamento;
		}
		if (props.statusTransferencia) {
			result.statusTransferencia = filtros.statusTransferencia;
		}
		if (props.tipoTransferencia) {
			result.tipoTransferencia = filtros.tipoTransferencia;
		}
		if (props.tipoCobranca) {
			result.tipoCobranca = filtros.tipoCobranca;
		}
		if (props.statusPreCadastro) {
			result.statusPreCadastro = filtros.statusPreCadastro;
		}
		if (props.situacaoEstabelecimento) {
			result.situacaoEstabelecimento = filtros.situacaoEstabelecimento;
		}
		if (props.tipoTransacao) {
			result.tipoTransacao = tipoTransacao;
		}
		if (props.statusVenda) {
			result.statusVenda = statusVenda;
		}
		if (props.omni) {
			result.omni = omni;
		}
		if (props.omni2) {
			result.omni2 = omni2;
		}
		if (props.searchEstabelecimentoByCpfOrCNPJ) {
			result.searchEstabelecimentoByCpfOrCNPJ = searchEstabelecimentoByCpfOrCNPJ;
		}
		if (props.nomeComprovante) {
			result.nomeComprovante = nomeComprovante;
		}

		if (props.created) {
			result.created = {
				...dataCreated,
			};
		}

		const r = { ...data };

		dispatch(setFilters(pathnameSlug, r));

		return r;
	};

	const setFiltroData = (index, value) => {
		const newFiltro = { [index]: value };

		setData(data => ({ ...data, ...newFiltro }));

		// setFiltros({
		// 	...filtros,
		// 	...newFiltro,
		// });

		// if (props.onChange) {
		// 	props.onChange(getFiltrosData(filtros));
		// }
	};

	const handleCreatedSelected = ranges => {
		setDataCreated({
			...dataCreated,
			start: ranges.startDate,
			end: ranges.endDate,
		});

		setData(data => ({
			...data,
			created: {
				...dataCreated,
				start: ranges.startDate,
				end: ranges.endDate,
			},
		}));

		// if (props.onChange) {
		// 	props.onChange(
		// 		getFiltrosData({
		// 			created: {
		// 				...dataCreated,
		// 				start: ranges.startDate,
		// 				end: ranges.endDate,
		// 			},
		// 		})
		// 	);
		// }
	};

	const handleProximaCobranca = ranges => {
		setDataProximaCobranca({
			...dataProximaCobranca,
			start: ranges.startDate,
			end: ranges.endDate,
		});

		setData(data => ({
			...data,
			proximaCobranca: {
				...dataProximaCobranca,
				start: ranges.startDate,
				end: ranges.endDate,
			},
		}));

		// if (props.onChange) {
		// 	props.onChange(
		// 		getFiltrosData({
		// 			proximaCobranca: {
		// 				...dataProximaCobranca,
		// 				start: ranges.startDate,
		// 				end: ranges.endDate,
		// 			},
		// 		})
		// 	);
		// }
	};

	const handleDateChange = date => {
		setDate(date);
		setFiltroData('date1', date);
	};

	const handleDateSelect = ranges => {
		setStartDate(ranges.selection.startDate);
		setEndDate(ranges.selection.endDate);
		setSelectedDate('custom');

		// if (props.onChange) {
		//     props.onChange(getFiltrosData({
		//         date: {
		//             start: ranges.selection.startDate,
		//             end: ranges.selection.endDate,
		//         }
		//     }));
		// }
	};

	// const handleDateBackdropClick = () => {
	// 	setOpenDate(false);

	// 	setData(data => ({
	// 		...data,
	// 		date: {
	// 			start: startDate,
	// 			end: endDate,
	// 		},
	// 	}));

	// 	if (props.onChange) {
	// 		props.onChange(getFiltrosData());
	// 	}
	// };

	const handleDateBackdrop = () => {
		setOpenDate(false);
		setData(data => ({
			...data,
			date: {
				start: startDate,
				end: endDate,
			},
		}));
	};

	const handleClearFilterDate = e => {
		e.stopPropagation();
		const today = new Date();
		setSelectedDate('today');
		setStartDate(today);
		setEndDate(today);
		setData(data => ({
			...data,
			date: {
				start: today,
				end: today,
			},
		}));
	};

	// const handleDateClear = e => {
	// 	e.stopPropagation();
	// 	const today = new Date();
	// 	setSelectedDate('today');
	// 	setStartDate(today);
	// 	setEndDate(today);
	// 	setData(data => ({
	// 		...data,
	// 		date: {
	// 			start: today,
	// 			end: today,
	// 		},
	// 	}));

	// 	if (props.onChange) {
	// 		props.onChange(
	// 			getFiltrosData({
	// 				date: {
	// 					start: today,
	// 					end: today,
	// 				},
	// 			})
	// 		);
	// 	}
	// };

	const staticDateClick = option => {
		switch (option) {
			case 'today':
				setStartDate(new Date());
				setEndDate(new Date());
				break;
			case 'lastMonth':
				setStartDate(addMonths(new Date(), -1));
				setEndDate(new Date());
				break;
			case 'last7days':
				setStartDate(addDays(new Date(), -7));
				setEndDate(new Date());
				break;
			case 'next6months':
				setStartDate(new Date());
				setEndDate(addMonths(new Date(), 6));
				break;
			case 'nextMonth':
				setStartDate(new Date());
				setEndDate(addMonths(new Date(), 1));
				break;
			default:
		}
	};

	const handleChangeData = (key, value, keyPressed) => {
		const newData = {
			...data,
			[key]: value,
			date: {
				start: startDate,
				end: endDate,
			},
		};
		setData(newData);

		if (keyPressed) {
			if (props.onChange) {
				props.onChange(newData);

				getFiltrosData(newData);
			}
		}
	};

	const handleFilterChange = () => {
		if (props.onChange) {
			props.onChange(data);

			getFiltrosData(data);
		}
	};

	const handleClearAllFilters = e => {
		const initialState = {
			date1: new Date(),
			date: {
				start: new Date(),
				end: new Date(),
			},
			estabelecimento: null,
			omniEstabelecimento: '',
			bandeira: null,
			formaPagamento: null,
			statusTransferencia: null,
			tipoTransferencia: null,
			statusPreCadastro: null,
			situacaoEstabelecimento: null,
			tipoCobranca: null,
			mes: null,
			ano: null,
		};
		props.handleClearFilter();
		handleClearFilterDate(e);

		setData(initialState);
		dispatch(clearFilter(pathnameSlug, initialState));
	};

	useEffect(() => {
		const checkSelectedDate = () => {
			if (selectedDate === 'custom' && startDate && endDate) {
				const today = new Date();
				const possibilities = [
					{ start: today, end: new Date(), name: 'today' },
					{ start: addMonths(today, -1), end: today, name: 'lastMonth' },
					{ start: addDays(today, -7), end: today, name: 'last7days' },
					{ start: today, end: addMonths(today, 1), name: 'nextMonth' },
					{ start: today, end: addMonths(today, 6), name: 'next6months' },
				];
				const exists = possibilities.find(v => {
					return (
						format(v.start, 'YYYYMMDD') === format(startDate, 'YYYYMMDD') &&
						format(v.end, 'YYYYMMDD') === format(endDate, 'YYYYMMDD')
					);
				});
				if (exists) {
					setSelectedDate(exists.name);
				}
			}
		};

		checkSelectedDate();
	}, []);

	useEffect(() => {
		staticDateClick(selectedDate);
	}, [selectedDate]);

	useEffect(() => {
		setDataCreated({
			...props.created,
		});
	}, [props.created]);

	useEffect(() => {
		setDataProximaCobranca({
			...props.proximaCobranca,
		});
	}, [props.proximaCobranca]);

	const tipoPagamentoChange = e => {
		const value = e.target.value;
		setTipoPagamento(value);

		if (props.onChange) {
			props.onChange(
				getFiltrosData({
					tipoPagamento: value,
				})
			);
		}
	};

	const omniChange = e => {
		const value = e.target.value;
		setOmni(value);

		if (omniTypingTimeout) {
			clearTimeout(omniTypingTimeout);
		}

		const timeout = setTimeout(() => {
			if (props.onChange && !props.omni.onBlur) {
				props.onChange(
					getFiltrosData({
						omni: value,
					})
				);
			}
		}, 500);

		setOmniTypingTimeout(timeout);
	};

	const omniEstabelecimentoChange = e => {
		const value = e.target.value;
		setOmniEstabelecimento(value);

		// const timeout = setTimeout(() => {
		// }, 500);

		// setestabelecimentoTypingTimeout(timeout);
	};

	const omni2Change = e => {
		const value = e.target.value;
		setOmni2(value);

		if (omni2TypingTimeout) {
			clearTimeout(omni2TypingTimeout);
		}

		const timeout = setTimeout(() => {
			if (props.onChange && !props.omni2.onBlur) {
				props.onChange(
					getFiltrosData({
						omni2: value,
					})
				);
			}
		}, 500);

		setOmni2TypingTimeout(timeout);
	};

	const tipoPagamentoClear = e => {
		e.stopPropagation();
		setTipoPagamento('');
		if (props.onChange) {
			props.onChange(
				getFiltrosData({
					tipoPagamento: '',
				})
			);
		}
	};

	const handleOnChangeStatus = (id, e) => {
		if (props.onSelectChange) {
			props.onSelectChange(id, e);
		}
	};

	// const statusChange = e => {
	//     const value = e.target.value;

	//     if (props.onChange) {
	//         props.onChange(getFiltrosData({
	//             estabelecimento: value
	//         }));
	//     }
	// }

	const tipoTransacaoChange = e => {
		const value = e.target.value;
		setTipoTransacao(value);

		if (props.onChange) {
			props.onChange(
				getFiltrosData({
					tipoTransacao: value,
				})
			);
		}
	};

	const tipoTransacaoClear = e => {
		e.stopPropagation();
		setTipoTransacao('');
		if (props.onChange) {
			props.onChange(
				getFiltrosData({
					tipoTransacao: '',
				})
			);
		}
	};

	const tipoCobrancaChange = e => {
		const { value } = e.target;

		setTipoCobranca(value);

		if (props.onChange) {
			props.onChange(
				getFiltrosData({
					tipoCobranca: value,
				})
			);
		}
	};

	const tipoCobrancaClear = e => {
		e.stopPropagation();
		setTipoCobranca('');
		if (props.onChange) {
			props.onChange(
				getFiltrosData({
					tipoTransacao: '',
				})
			);
		}
	};

	const statusVendaChange = e => {
		const value = e.target.value;
		setStatusVenda(value);
	};

	const statusServicoBancarioChange = e => {
		const value = e.target.value;
		setServicosBancarios(value);
	};

	// const statusServicosBancariosClose = e => {
	// if (props.onChange) {
	// 	props.onChange(
	// 		getFiltrosData({
	// 			servicosBancarios,
	// 		})
	// 	);
	// }
	// };

	useEffect(() => {
		if (servicosBancarios && props.onChange) {
			props.onChange(
				getFiltrosData({
					servicosBancarios,
				})
			);
		}
	}, [servicosBancarios]);

	const statusVendaClose = e => {
		if (props.onChange) {
			props.onChange(
				getFiltrosData({
					statusVenda,
				})
			);
		}
	};

	const statusVendaClear = e => {
		const isMultiple = Isset(() => props.statusVenda.multiple) ? props.statusVenda.multiple : false;
		e.stopPropagation();
		const value = isMultiple ? [] : '';
		setStatusVenda(value);
		if (props.onChange) {
			props.onChange(
				getFiltrosData({
					statusVenda: value,
				})
			);
		}
	};

	const servicoBancarioClear = e => {
		// const isMultiple = Isset(() => props.servico.multiple) ? props.statusVenda.multiple : false;
		e.stopPropagation();
		const value = '';
		setServicosBancarios(value);
		if (props.onChange) {
			props.onChange(
				getFiltrosData({
					servicoBancario: value,
				})
			);
		}
	};

	const dataCreatedClear = e => {
		if (props.onChange) {
			props.onChange(
				getFiltrosData({
					...data,
					created: {
						...dataCreated,
						start: null,
						end: null,
					},
				})
			);
		}
	};

	const clearDataProximoCobranca = e => {
		if (props.onChange) {
			props.onChange(
				getFiltrosData({
					proximaCobranca: {
						...dataProximaCobranca,
						start: '',
						end: '',
					},
				})
			);
		}
	};

	const omniClear = e => {
		e.stopPropagation();
		setOmni('');
		if (props.onChange) {
			props.onChange(
				getFiltrosData({
					omni: '',
				})
			);
		}
	};

	const omniEstabelecimentoClear = e => {
		e.stopPropagation();
		setOmniEstabelecimento('');
		if (props.onChange) {
			props.onChange(
				getFiltrosData({
					omniEstabelecimento: '',
				})
			);
		}
	};

	const omni2Clear = e => {
		e.stopPropagation();
		setOmni2('');
		if (props.onChange) {
			props.onChange(
				getFiltrosData({
					omni2: '',
				})
			);
		}
	};

	return (
		<Grid container style={{ padding: '5px' }} spacing={2}>
			{props.input &&
				props.input
					.filter(value => !value.modal)
					.map((value, index) => (
						<Grid key={index} item xs={12} md={value.md ? value.md : 2}>
							<Input value={value} onChange={handleChangeData} />
						</Grid>
					))}

			{/* esse renderiza o filtro tipo transação */}
			{props.selectField &&
				props.selectField
					.filter(value => !value.modal)
					.map((value, index) => (
						<Grid key={index} item xs={12} md={value.md ? value.md : 2}>
							<SelectField value={value} title={value.title} index={index} onChange={handleChangeData} />
						</Grid>
					))}

			{props.selectMethodExtrato &&
				props.selectMethodExtrato
					.filter(value => !value.modal)
					.map((value, index) => (
						<Grid key={index} item xs={12} md={value.md ? value.md : 2}>
							<SelectField value={value} title={value.title} index={index} onChange={handleChangeData} />
						</Grid>
					))}

			{props.omni && (
				<div className={classes.omni}>
					<div className={clsx(classes.filtroText, { [classes.filtroOmniHasText]: omni })}>
						<TextField
							// id="standard-search"
							// label={props.omni.label || 'Busca'}
							placeholder={props.omni.placeholder || 'Digite um termo de busca'}
							type="text"
							className={classes.omniField}
							value={omni}
							onChange={omniChange}
							margin="normal"
							style={{ margin: 0, height: 21, padding: 0 }}
						/>
						{omni && <FaTimes className={classes.clearBtn} onClick={omniClear} />}
					</div>
				</div>
			)}

			{/* {props.dateRange && !props.modal && (
				<div className={classes.datePicker}>
					<div
						className={clsx({
							[classes.filtroText]: true,
							[classes.filtroTextSelected]: selectedDate !== null,
						})}
						onClick={() => setOpenDate(!openDate)}
					>
						{selectedDate !== null ? (
							<React.Fragment>
								<span className={classes.filterSelected}>
									{Isset(() => selectedOptions[selectedDate])
										? selectedOptions[selectedDate]
										: `${format(startDate, 'DD/MM/YYYY')} - ${format(endDate, 'DD/MM/YYYY')}`}
								</span>
								<FaTimes className={classes.clearBtn} onClick={handleDateClear} />
							</React.Fragment>
						) : (
							<span>Nenhuma Data Selecionada</span>
						)}
					</div>

					{openDate && <div className={classes.backdrop} onClick={handleDateBackdropClick}></div>}

					{openDate && (
						<Paper className={classes.filterBox}>
							<DateRange
								locale={pt}
								ranges={[selectionRange]}
								onChange={handleDateSelect}
								dateDisplayFormat={'DD/MM/YYYY'}
								minDate={minDate}
								maxDate={maxDate}
								showSelectionPreview={false}
								moveRangeOnFirstSelection={false}
							/>
							<Grid container className={classes.staticOptions}>
								{minDate === undefined && (
									<Grid xs item>
										<div
											onClick={() => setSelectedDate('lastMonth')}
											className={clsx({
												[classes.staticOption]: true,
												[classes.optionSelected]: selectedDate === 'lastMonth',
											})}
										>
											{selectedOptions['lastMonth']}
										</div>
										<div
											onClick={() => setSelectedDate('last7days')}
											className={clsx({
												[classes.staticOption]: true,
												[classes.optionSelected]: selectedDate === 'last7days',
											})}
										>
											{selectedOptions['last7days']}
										</div>
									</Grid>
								)}
								{maxDate === undefined && (
									<Grid xs item>
										<div
											onClick={() => setSelectedDate('next6months')}
											className={clsx({
												[classes.staticOption]: true,
												[classes.optionSelected]: selectedDate === 'next6months',
											})}
										>
											{selectedOptions['next6months']}
										</div>
										<div
											onClick={() => setSelectedDate('nextMonth')}
											className={clsx({
												[classes.staticOption]: true,
												[classes.optionSelected]: selectedDate === 'nextMonth',
											})}
										>
											{selectedOptions['nextMonth']}
										</div>
									</Grid>
								)}
							</Grid>
						</Paper>
					)}
				</div>
			)} */}

			{props.omniEstabelecimento && (
				<div className={classes.estabelecimento}>
					<div
						className={clsx(classes.filtroText, {
							[classes.filtroOmniHasText]: omniEstabelecimento,
						})}
					>
						<TextField
							// id="standard-search"
							// label={props.estabelecimento.label || 'Busca'}
							placeholder={props.omniEstabelecimento.placeholder || 'Nome Fantasia'}
							type="text"
							className={classes.omniField}
							value={omniEstabelecimento}
							onChange={omniEstabelecimentoChange}
							onBlur={e =>
								props.onChange(
									getFiltrosData({
										omniEstabelecimento: e.target.value,
									})
								)
							}
							margin="normal"
							style={{ margin: 0, height: 21, padding: 0 }}
						/>
						{omniEstabelecimento && (
							<FaTimes className={classes.clearBtn} onClick={omniEstabelecimentoClear} />
						)}
					</div>
				</div>
			)}

			{props.omni2 && (
				<div className={classes.omni}>
					<div className={clsx(classes.filtroText, { [classes.filtroOmniHasText]: omni2 })}>
						<TextField
							// id="standard-search"
							// label={props.omni2.label || 'Busca'}
							placeholder={props.omni2.placeholder || 'Digite um termo de busca'}
							type="text"
							className={classes.omniField}
							value={omni2}
							onChange={omni2Change}
							margin="normal"
							style={{ margin: 0, height: 21, padding: 0 }}
						/>
						{omni2 && <FaTimes className={classes.clearBtn} onClick={omni2Clear} />}
					</div>
				</div>
			)}

			{props.mes && <FiltroMes {...props.mes} onChange={value => setFiltroData('mes', value)} />}

			{props.ano && <FiltroAno {...props.ano} onChange={value => setFiltroData('ano', value)} />}

			{props.created && !props.created.modal && (
				<>
					<ZDateRangePicker
						onClose={handleCreatedSelected}
						startDate={dataCreated.start}
						endDate={dataCreated.end}
						label={dataCreated.label}
						clearDate={dataCreatedClear}
					/>
				</>
			)}

			{props.proximaCobranca && (
				<>
					<ZDateRangePicker
						onClose={handleProximaCobranca}
						startDate={dataProximaCobranca.start}
						endDate={dataProximaCobranca.end}
						label={dataProximaCobranca.label}
						clearDate={clearDataProximoCobranca}
					/>
				</>
			)}

			{props.tipoTransacao && (
				<div className={classes.tipoTransacao}>
					<div
						className={clsx({
							[classes.filtroText]: true,
							[classes.filtroTextSelected]: tipoTransacao,
						})}
					>
						<Select
							className={classes.filtroSelect}
							value={tipoTransacao}
							onChange={tipoTransacaoChange}
							displayEmpty
						>
							<MenuItem value="">
								<em>Tipo de Transação</em>
							</MenuItem>
							<MenuItem value="venda">Venda</MenuItem>
							<MenuItem value="split">Split</MenuItem>
							<MenuItem value="transferencia">Transferência</MenuItem>
						</Select>
						{tipoTransacao && <FaTimes className={classes.clearBtn} onClick={tipoTransacaoClear} />}
					</div>
				</div>
			)}

			{props.tipoCobranca && (
				<div className={classes.tipoCobranca}>
					<div
						className={clsx({
							[classes.filtroText]: true,
							[classes.filtroTextSelected]: tipoCobranca,
						})}
					>
						<Select
							className={classes.filtroSelect}
							value={tipoCobranca}
							onChange={tipoCobrancaChange}
							displayEmpty
						>
							<MenuItem disabled value={tipoCobranca}>
								<em>Tipo da Cobrança</em>
							</MenuItem>
							{props.tipoCobranca.tipo.map((tipoCobranca, i) => (
								<MenuItem key={i} value={tipoCobranca.id} className={classes.menuItem}>
									{tipoCobranca.name}
								</MenuItem>
							))}
						</Select>

						{tipoCobranca && <FaTimes className={classes.clearBtn} onClick={tipoCobrancaClear} />}
					</div>
				</div>
			)}

			{props.tipoPagamento && (
				<div className={classes.tipoPagamento}>
					<div
						className={clsx({
							[classes.filtroText]: true,
							[classes.filtroTextSelected]: tipoPagamento,
						})}
					>
						<Select
							multiple={true}
							className={classes.filtroSelect}
							value={tipoPagamento}
							onChange={tipoPagamentoChange}
							displayEmpty
						>
							<MenuItem value="">
								<em>Tipo de Pagamento</em>
							</MenuItem>
							<MenuItem value="credit_card">Cartão de Crédito</MenuItem>
							<MenuItem value="billet">Boleto Bancário</MenuItem>
						</Select>
						{tipoPagamento && <FaTimes className={classes.clearBtn} onClick={tipoPagamentoClear} />}
					</div>
				</div>
			)}

			{props.bandeira && (
				<FiltroBandeira {...props.bandeira} onChange={value => setFiltroData('bandeira', value)} />
			)}

			{props.formaPagamento && (
				<FiltroFormaPagamento
					{...props.formaPagamento}
					onChange={value => setFiltroData('formaPagamento', value)}
				/>
			)}

			{props.statusTransferencia && (
				<FiltroStatusTransferencia
					{...props.statusTransferencia}
					onChange={value => setFiltroData('statusTransferencia', value)}
				/>
			)}

			{props.tipoTransferencia && (
				<FiltroTipoTransferencia
					{...props.tipoTransferencia}
					onChange={value => setFiltroData('tipoTransferencia', value)}
				/>
			)}

			{props.statusPreCadastro && (
				<FiltroStatusPreCadastro
					{...props.statusPreCadastro}
					onChange={value => setFiltroData('statusPreCadastro', value)}
				/>
			)}

			{props.situacaoEstabelecimento && (
				<FiltroSituacaoEstabelecimento
					{...props.situacaoEstabelecimento}
					onChange={value => setFiltroData('situacaoEstabelecimento', value)}
				/>
			)}

			{props.statusVenda && (
				<div className={classes.statusVenda}>
					<div
						className={clsx({
							[classes.filtroText]: true,
							[classes.filtroTextSelectedMultiple]: Array.isArray(statusVenda)
								? statusVenda.length > 0
								: statusVenda,
						})}
					>
						<Select
							multiple={props.statusVenda.multiple || false}
							className={classes.filtroSelect}
							value={statusVenda}
							onChange={statusVendaChange}
							onClose={statusVendaClose}
							displayEmpty
							renderValue={selected => {
								if (selected.length === 0) {
									return <em>Status da Venda</em>;
								}

								let aux = statusVendaData.filter(a => selected.includes(a.id)).map(a => a.titulo);
								let hasMore = 0;
								let hasMoreText = '';
								if (aux.length > 3) {
									hasMore = aux.length - 3;
									hasMoreText = aux.slice(3).join(', ');
									aux = aux.slice(0, 3);
								}
								return (
									<div className={classes.chips}>
										{aux.map((value, i) => (
											<Chip key={`${value}${i}`} label={value} className={classes.chip} />
										))}
										{hasMore > 0 && (
											<Tooltip title={hasMoreText}>
												<Chip
													label={`+${hasMore}`}
													className={clsx(classes.chip, classes.hasMore)}
												/>
											</Tooltip>
										)}
									</div>
								);
							}}
						>
							<MenuItem disabled value="">
								<em>Status da Venda</em>
							</MenuItem>
							{statusVendaData.map((status, i) => (
								<MenuItem key={i} value={status.id} className={classes.menuItem}>
									<div className={classes.statusVendaTitulo}>{status.titulo}</div>
								</MenuItem>
							))}
						</Select>
						{statusVenda && <FaTimes className={classes.clearBtn} onClick={statusVendaClear} />}
					</div>
				</div>
			)}

			{props.statusServicoBancario && (
				<div className={classes.statusVenda}>
					<div
						className={clsx({
							[classes.filtroText]: true,
							[classes.filtroTextSelectedMultiple]: Array.isArray(servicosBancarios)
								? servicosBancarios.length > 0
								: servicosBancarios,
						})}
					>
						<Select
							multiple={false}
							className={classes.filtroSelect}
							value={servicosBancarios}
							onChange={statusServicoBancarioChange}
							// onClose={statusServicosBancariosClose}
							displayEmpty
							renderValue={selected => {
								if (selected.length === 0) {
									return <em>Status da Venda</em>;
								}

								let aux = statusServicoBancarioData.filter(a => selected === a.id);
								aux = aux[0].titulo;

								let hasMore = 0;
								let hasMoreText = '';

								return (
									<div className={classes.chips}>
										{/* {aux.map((value, i) => ( */}
										<Chip label={aux} className={classes.chip} />
										{/* ))} */}
										{hasMore > 0 && (
											<Tooltip title={hasMoreText}>
												<Chip
													label={`+${hasMore}`}
													className={clsx(classes.chip, classes.hasMore)}
												/>
											</Tooltip>
										)}
									</div>
								);
							}}
						>
							<MenuItem disabled value="">
								<em>Status</em>
							</MenuItem>
							{statusServicoBancarioData.map((status, i) => (
								<MenuItem key={i} value={status.id} className={classes.menuItem}>
									<div className={classes.statusVendaTitulo}>{status.titulo}</div>
								</MenuItem>
							))}
						</Select>
						{servicosBancarios && <FaTimes className={classes.clearBtn} onClick={servicoBancarioClear} />}
					</div>
				</div>
			)}

			{props.select && props.select.length && (
				<>
					{props.select.map((value, key) => {
						return (
							<div
								className={clsx({
									[classes.filtroText]: true,
								})}
								key={value.id}
							>
								<Select
									multiple={true}
									className={classes.filtroSelect}
									value={value.value}
									onChange={e => {
										handleOnChangeStatus(value.id, e);
									}}
									displayEmpty
									renderValue={selected => {
										if (selected.length === 0) {
											return <em>{value.label}</em>;
										}
										return (
											<div className={classes.chips}>
												{value.value.map((selectValue, i) => {
													var data = {};

													value.options.filter(item => {
														if (item.id === selectValue) {
															data = { id: item.id, name: item.name };
														}
														return true;
													});

													if (data.id) {
														return (
															<Chip
																key={`${data.id}`}
																label={data.name}
																className={classes.chip}
															/>
														);
													} else {
														return '';
													}
												})}
											</div>
										);
									}}
								>
									<MenuItem disabled value="">
										<em>{value.label}</em>
									</MenuItem>
									{value.options.map((value, i) => (
										<MenuItem key={i} value={value.id} className={classes.menuItem}>
											<div>{value.name}</div>
											{/* <div className={classes.estabelvalueimentoDocumento}>{value.documento.length <= 11 ? Formatter.Cpf(value.documento) : Formatter.Cnpj(value.documento)}</div> */}
										</MenuItem>
									))}
								</Select>
								{value.value.length > 0 && (
									<FaTimes
										className={classes.clearBtn}
										onClick={() => {
											if (props.onSelectChange) {
												props.onSelectChange(value.id, {
													target: { value: [] },
												});
											}
										}}
									/>
								)}
							</div>
						);
					})}
				</>
			)}

			{props.searchEstabelecimentoByCpfOrCNPJ && (
				<div className={classes.omni}>
					<div
						className={clsx(classes.filtroText, {
							[classes.filtroOmniHasText]: searchEstabelecimentoByCpfOrCNPJ,
						})}
					>
						<TextField
							// id="standard-search"
							// label={props.omni.label || 'Busca'}
							placeholder={props.searchEstabelecimentoByCpfOrCNPJ.placeholder || 'Pesquisa por CPF/CNPJ'}
							type="text"
							className={classes.omniField}
							value={
								searchEstabelecimentoByCpfOrCNPJ.length <= 11
									? Mask.Cpf(searchEstabelecimentoByCpfOrCNPJ)
									: Mask.Cnpj(searchEstabelecimentoByCpfOrCNPJ)
							}
							onChange={e => {
								const value = e.target.value;
								setSearchEstabelecimentoByCpfOrCNPJ(Mask.OnlyNumber(value));

								props.onChange(
									getFiltrosData({
										documento: Mask.OnlyNumber(value),
									})
								);
							}}
							margin="normal"
							style={{ margin: 0, height: 21, padding: 0 }}
						/>
						{searchEstabelecimentoByCpfOrCNPJ && (
							<FaTimes
								className={classes.clearBtn}
								onClick={() => {
									setSearchEstabelecimentoByCpfOrCNPJ('');
									props.onChange(
										getFiltrosData({
											documento: '',
										})
									);
								}}
							/>
						)}
					</div>
				</div>
			)}

			{props.date && (
				<Grid item xs={12} md={2} className={classes.ZDatePicker}>
					<ZDatePicker
						textField
						// label={props.date.placeholder || 'Data'}
						date={date}
						onChange={handleDateChange}
					/>
				</Grid>
			)}

			{props.dateRange && (
				<Grid item xs={12} md={3}>
					{props.dateRange && (
						<div className={classes.datePicker}>
							<div className={classes.dateRange} onClick={() => setOpenDate(!openDate)}>
								{selectedDate !== null ? (
									<React.Fragment>
										<span>
											{Isset(() => selectedOptions[selectedDate])
												? selectedOptions[selectedDate]
												: `${format(startDate, 'DD/MM/YYYY')} - ${format(
														endDate,
														'DD/MM/YYYY'
												  )}`}
										</span>
										<FaTimes className={classes.clearBtn} onClick={handleClearFilterDate} />
									</React.Fragment>
								) : (
									<span>Nenhuma Data Selecionada</span>
								)}
							</div>

							{openDate && <div className={classes.backdrop} onClick={handleDateBackdrop}></div>}

							{openDate && (
								<Paper className={classes.filterBox}>
									<DateRange
										locale={pt}
										ranges={[selectionRange]}
										onChange={handleDateSelect}
										dateDisplayFormat={'DD/MM/YYYY'}
										minDate={minDate}
										maxDate={maxDate}
										showSelectionPreview={false}
										moveRangeOnFirstSelection={false}
									/>
									<Grid container className={classes.staticOptions}>
										{minDate === undefined && (
											<Grid xs item>
												<div
													onClick={() => setSelectedDate('lastMonth')}
													className={clsx({
														[classes.staticOption]: true,
														[classes.optionSelected]: selectedDate === 'lastMonth',
													})}
												>
													{selectedOptions['lastMonth']}
												</div>
												<div
													onClick={() => setSelectedDate('last7days')}
													className={clsx({
														[classes.staticOption]: true,
														[classes.optionSelected]: selectedDate === 'last7days',
													})}
												>
													{selectedOptions['last7days']}
												</div>
											</Grid>
										)}
										{maxDate === undefined && (
											<Grid xs item>
												<div
													onClick={() => setSelectedDate('next6months')}
													className={clsx({
														[classes.staticOption]: true,
														[classes.optionSelected]: selectedDate === 'next6months',
													})}
												>
													{selectedOptions['next6months']}
												</div>
												<div
													onClick={() => setSelectedDate('nextMonth')}
													className={clsx({
														[classes.staticOption]: true,
														[classes.optionSelected]: selectedDate === 'nextMonth',
													})}
												>
													{selectedOptions['nextMonth']}
												</div>
											</Grid>
										)}
									</Grid>
								</Paper>
							)}
						</div>
					)}
				</Grid>
			)}

			{props.nomeComprovante && (
				<div className={classes.omni}>
					<div
						className={clsx(classes.filtroText, {
							[classes.filtroOmniHasText]: nomeComprovante,
						})}
					>
						<TextField
							// id="standard-search"
							// label={props.omni.label || 'Busca'}
							placeholder={props.nomeComprovante.placeholder || 'Nome do comprovante'}
							type="text"
							className={classes.omniField}
							value={nomeComprovante}
							onChange={e => {
								setNomeComprovante(e.target.value);
								props.onChange(
									getFiltrosData({
										nomeComprovante: e.target.value,
									})
								);
							}}
							margin="normal"
							style={{ margin: 0, height: 21, padding: 0 }}
						/>
						{nomeComprovante && (
							<FaTimes
								className={classes.clearBtn}
								onClick={() => {
									setNomeComprovante('');
									props.onChange(
										getFiltrosData({
											nomeComprovante: '',
										})
									);
								}}
							/>
						)}
					</div>
				</div>
			)}

			<div>
				<Dialog maxWidth={'md'} open={open} onClose={() => setOpen(false)}>
					<DialogTitle id="customized-dialog-title" onClose={() => setOpen(false)}>
						Mais Filtros
					</DialogTitle>
					<DialogContent dividers>
						<Container maxWidth={'sm'}>
							<Grid container spacing={2}>
								{props.priceRange &&
									props.priceRange.map((value, index) => (
										<Grid item key={index} xs={12} md={6}>
											<PriceRange value={value} onChange={handleChangeData} />
										</Grid>
									))}

								{props.input &&
									props.input
										.filter(value => value.modal)
										.map((value, index) => (
											<Grid item key={index} xs={12} md={value.md}>
												<Input value={value} onChange={handleChangeData} />
											</Grid>
										))}

								{props.created && props.created.modal && (
									<Grid item xs={12} md={6} style={{ marginTop: 40 }}>
										<ZDateRangePicker
											onClose={handleCreatedSelected}
											startDate={dataCreated.start}
											endDate={dataCreated.end}
											label={dataCreated.label}
											clearDate={dataCreatedClear}
											modal={props.created.modal}
										/>
									</Grid>
								)}

								{props.checkbox &&
									props.checkbox.map((value, index) => (
										<Grid item className={classes.gridCheckbox} xs={12} md={value.md}>
											<FormControlLabel
												control={
													<Switch
														checked={data.transacoesSuspeitas}
														onChange={e =>
															setData({
																...data,
																transacoesSuspeitas: e.target.checked,
															})
														}
														name="checkedB"
														color="primary"
													/>
												}
												label={value.placeholder}
											/>
										</Grid>
									))}

								{props.selectField &&
									props.selectField
										.filter(value => value.modal)
										.map((value, index) => (
											<Grid key={index} item xs={12} md={value.md}>
												<SelectField
													value={value}
													title={value.title}
													index={index}
													onChange={handleChangeData}
												/>
											</Grid>
										))}

								{props.estabelecimento && (
									<Grid item xs={12} md={12}>
										<FiltroEstabelecimento
											{...props.estabelecimento}
											onChange={value => setFiltroData('estabelecimento', value)}
										/>
									</Grid>
								)}
							</Grid>
						</Container>
					</DialogContent>
					<DialogActions>
						<Grid container>
							<Grid item xs={12} md={6}>
								<Button className={classes.clearFilter} autoFocus onClick={handleClearAllFilters}>
									Limpar Filtros
								</Button>
							</Grid>
							<Grid item xs={12} md={6} align="end">
								<Button
									className={clsx(classes.filterButton, classes.filterButtonWidth)}
									fullWidth
									autoFocus
									onClick={() => {
										handleFilterChange();

										setOpen(false);
									}}
								>
									Filtrar
								</Button>
							</Grid>
						</Grid>
					</DialogActions>
				</Dialog>
			</div>
			<Grid item xs={12} md={2}>
				<Button fullWidth className={classes.filterButton} onClick={handleFilterChange}>
					Filtrar
				</Button>
			</Grid>
			{props.modal && (
				<Grid item xs={12} md={2}>
					<Button fullWidth className={classes.button} onClick={() => setOpen(true)}>
						Mais Filtros
					</Button>
				</Grid>
			)}
		</Grid>
	);
};
