import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import { useSelector } from 'react-redux';
// import Fab from '@material-ui/core/Fab';
// import CheckIcon from '@material-ui/icons/Check';
// import SaveIcon from '@material-ui/icons/Save';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		alignItems: 'center',
	},
	wrapper: {
		// margin: theme.spacing(1),
		position: 'relative',
		width: '100%',
		// marginRight: 6,
	},
	button: {
		width: '100%',
		height: 44,
		//backgroundColor: theme.palette.primary.main,
		color: theme.palette.getContrastText(theme.palette.primary.main),
	},
	buttonEdmond: {
		width: '100%',
		height: 44,
		backgroundColor: '#bb48e0',
		color: '#FFF',
		'&:hover': {
			backgroundColor: '#ac54c0',
		},
	},
	buttonSuccess: {
		backgroundColor: green[500],
		'&:hover': {
			backgroundColor: green[700],
		},
	},
	fabProgress: {
		color: green[500],
		position: 'absolute',
		top: -6,
		left: -6,
		zIndex: 1,
	},
	buttonProgress: {
		color: green[500],
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
}));

export default function CircularIntegration(props) {
	const {
		estabelecimentoConfig: { primary_color },
		marketplaceConfig: { marketplace_color },
	} = useSelector(store => store.system.config);
	const theme = useTheme();
	const classes = useStyles({ primary_color, marketplace_color, theme });
	const [loading, setLoading] = React.useState(props.loading);

	// const [success, setSuccess] = React.useState(false);
	const success = false;
	// const timer = React.useRef();
	var buttonClassname = [];

	if (props.isEdmond) {
		buttonClassname = clsx({
			[classes.buttonSuccess]: success,
			[classes.buttonEdmond]: true,
		});
	} else {
		buttonClassname = clsx({
			[classes.buttonSuccess]: success,
			[classes.button]: true,
		});
	}

	// const buttonClassname = clsx({
	// 	[classes.buttonSuccess]: success,
	// 	[classes.button]: true,
	// });

	// React.useEffect(() => {
	//   return () => {
	//     clearTimeout(timer.current);
	//   };
	// }, []);

	React.useEffect(() => {
		setLoading(props.loading);
	}, [props.loading]);

	return (
		<div className={classes.root}>
			<div className={classes.wrapper}>
				<Button
					onClick={props.onClick}
					variant="contained"
					color={props.color}
					className={buttonClassname}
					disabled={loading}
					style={props.style ? props.style : undefined}
				>
					{props.label}
				</Button>
				{loading && <CircularProgress size={24} className={classes.buttonProgress} />}
			</div>
		</div>
	);
}
