import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Formatter } from '../../utils';
import Grid from '@material-ui/core/Grid';
import format from 'date-fns/format';
import pt from 'date-fns/locale/pt';

const useStyles = makeStyles(theme => {
	return {
		tableHeader: {
			textAlign: 'center',
			fontWeight: 700,
			fontSize: 16,
			margin: 0,
		},
		tableDateRow: {
			fontSize: 14,
			fontWeight: 700,
			padding: 10,
			display: 'flex',
			alignItems: 'center',
			borderRadius: 10,
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.getContrastText(theme.palette.primary.main),
			paddingLeft: 20,
			marginTop: 10,
			marginBottom: 10,
		},
		tableRow: {
			fontSize: 14,
			textAlign: 'center',
			fontWeight: 400,
			padding: 15,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-around',
			borderRadius: 10,
			borderColor: '#d9d7d7',
			border: '1px solid',
			cursor: 'pointer',
			'&:hover': {
				backgroundColor: `${theme.palette.primary.main}2b`,
			},
			marginBottom: 8,
			marginTop: 8,
			backgroundColor: '#fff',
		},
		negativo: {
			color: `#ff5440 !important`,
		},
		truncate: {
			textOverflow: 'ellipsis',
			overflow: 'hidden',
			whiteSpace: 'nowrap',
		},
		hideOnXs: {
			[theme.breakpoints.down('xs')]: {
				display: 'none',
			},
		},
		valorOnXs: {
			[theme.breakpoints.down('xs')]: {
				fontSize: 10,
			},
		},
	};
});

export default props => {
	const [lancamentos] = useState(props.lancamentos);
	const classes = useStyles();
	const usuario = useSelector(state => state.auth.usuario);
	const isForPay = usuario.marketplace.id === 2;

	return (
		<Grid container item className={classes.tableHeader}>
			{lancamentos.map((lancamento, key) => (
				<React.Fragment key={key}>
					{lancamento.typeRow === 'date' ? (
						<>
							<Grid container item className={classes.tableDateRow}>
								<Grid item xs={8} style={{ textAlign: 'left' }}>
									{format(lancamento.date, 'DD [de] MMMM [de] YYYY', {
										locale: pt,
									})}
								</Grid>
								<Grid item xs style={{ textAlign: 'right', paddingRight: 20 }}>
									{!isForPay && lancamento.saldoNovo && Formatter.Real(lancamento.saldoNovo.saldo)}
								</Grid>
							</Grid>
						</>
					) : (
						<Grid container item className={classes.tableRow}>
							<Grid item xs={2} sm={2} className={classes.truncate}>
								<p className={classes.tableHeader}>Tipo</p>
								{lancamento.type}
							</Grid>
							<Grid item xs={3} sm={3} className={classes.truncate}>
								<p className={classes.tableHeader}>Método</p>
								{lancamento.method}
							</Grid>
							<Grid
								item
								xs={2}
								sm={2}
								className={clsx(classes.valorOnXs, {
									[classes.negativo]: lancamento.grossValue < 0,
								})}
							>
								<p className={classes.tableHeader}>Líquido</p>
								{Formatter.Real(lancamento.amountToReceive)}
							</Grid>
						</Grid>
					)}
				</React.Fragment>
			))}
		</Grid>
	);
};
