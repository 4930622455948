import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState, useCallback } from 'react';
import ZLoader from '../system/ZLoader';
import format from 'date-fns/format';
import moment from 'moment';

import axios from 'axios';
import config from 'react-global-configuration';
import { useSelector, useDispatch } from 'react-redux';
import { addMessage } from '../../store/alert';
import { Mask } from '../../utils';
import { Button, Tooltip } from '@material-ui/core';
import {
	FaBandcamp,
	FaCheckCircle,
	FaExclamationCircle,
	FaMinusCircle,
	FaStopCircle,
	FaTimesCircle,
} from 'react-icons/fa';

import { MdAttachMoney } from 'react-icons/md';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import yellow from '@material-ui/core/colors/yellow';
import orange from '@material-ui/core/colors/orange';

import ZModalConfirm from '../system/ZModalConfirm';

const useStyle = makeStyles(theme => ({
	menuIcon: {
		fontSize: 18,
		cursor: 'pointer',
	},
	carne: {},
	paper: {
		padding: 10,
	},
	titulo: { fontSize: 20, fontWeight: 'bold', padding: 5, paddingLeft: 0 },
	descricao: {},
	fieldTitle: { fontSize: 16, fontWeight: 'bold' },
	fieldValue: { fontSize: 14 },
	tableHeader: { fontSize: 15, fontWeight: 'bold', textAlign: 'center' },
	tableRow: {
		margin: 2,
		textAlign: 'center',
		'&:hover': {
			background: '#ddd',
		},
	},
}));

const getStatusIcon = (statusId, dataVencimento) => {
	const newDate = new Date();
	const dataAtual = moment(newDate).format('YYYY-MM-DD');
	switch (+statusId) {
		case 2:
			return (
				<Tooltip title="Aprovada">
					<div>
						<FaCheckCircle style={{ color: green[500] }} />
					</div>
				</Tooltip>
			);
		case 3:
			return (
				<Tooltip title="Falhado">
					<div>
						<FaStopCircle style={{ color: red[400] }} />
					</div>
				</Tooltip>
			);
		case 4:
			return (
				<Tooltip title="Cancelada">
					<div>
						<FaTimesCircle style={{ color: red[700] }} />
					</div>
				</Tooltip>
			);
		case 6:
			return (
				<Tooltip title="Estornado">
					<div>
						<FaTimesCircle style={{ color: orange[700] }} />
					</div>
				</Tooltip>
			);
		case 11:
			return (
				<Tooltip title="Review Anti-Fraude">
					<div>
						<FaBandcamp style={{ color: orange[300] }} />
					</div>
				</Tooltip>
			);
		case 12:
			return (
				<Tooltip title="Recusado Anti-Fraude">
					<div>
						<FaMinusCircle style={{ color: red[400] }} />
					</div>
				</Tooltip>
			);
		case 15:
			return (
				<Tooltip title="Baixa Manual">
					<div>
						<FaCheckCircle style={{ color: green[500] }} />
					</div>
				</Tooltip>
			);
		case 1:
		default:
			if (dataVencimento < dataAtual) {
				return (
					<Tooltip title="Atrasado">
						<div>
							<FaStopCircle style={{ color: red[400] }} />
						</div>
					</Tooltip>
				);
			} else {
				return (
					<Tooltip title="Pendente">
						<div>
							<FaExclamationCircle style={{ color: yellow[700] }} />
						</div>
					</Tooltip>
				);
			}
	}
};

export default props => {
	const { id } = props.match.params;
	const token = useSelector(store => store.auth.token);
	const classes = useStyle();
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(true);
	const [carne, setCarne] = useState(null);
	const [baixaParcela, setBaixaParcela] = useState({
		parcelaId: null,
		open: false,
	});
	const [quitaCarne, setQuitaCarne] = useState({
		carneId: null,
		open: false,
	});

	const getCarne = useCallback(async () => {
		setLoading(true);
		const res = await axios.get(`${config.get('apiUrl')}carnes/${id}`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		if (res.data.success) {
			setCarne(res.data.carne);
		}
		setLoading(false);
	}, [id, setCarne, setLoading, token]);

	useEffect(() => {
		getCarne();
	}, [getCarne]);


	const imprimirCarne = async () => {
		console.log(id)
		window.open(`/app/carnes/${id}/imprimir`, '_blank');
	};

	const quitarCarne = async (result) => {
		const data = { carneId: quitaCarne.carneId };
		setQuitaCarne({ ...quitaCarne, open: false, carneId: null });

		if (result) {
			setLoading(true);
			await axios.post(`${config.get('apiUrl')}carnes/quitar`, data, {
				headers: { Authorization: `Bearer ${token}` },
			})
				.then(res => {
					if (res.data.success) {
						dispatch(addMessage({ type: 'success', message: 'Carnê quitado com sucesso!' }));
						getCarne();
					}
					setLoading(false);
				});
		}
	};

	const baixarParcela = async (result) => {
		const data = { parcelaId: baixaParcela.parcelaId };
		setBaixaParcela({ ...baixaParcela, open: false, parcelaId: null });

		if (result) {
			setLoading(true);
			await axios.post(`${config.get('apiUrl')}carnes/parcela/quitar`, data, {
				headers: { Authorization: `Bearer ${token}` },
			})
				.then(res => {
					if (res.data.success) {
						dispatch(addMessage({ type: 'success', message: 'Baixa de parcela realizada com sucesso!' }));
						getCarne();
					}
					setLoading(false);
				});
		}
	};

	if (loading) {
		return <ZLoader height={200} message="Carregando Carnê" />;
	}

	return (
		<Grid container spacing={2} className={classes.carne}>
			<Grid item xs={12}>
				<Paper className={classes.paper}>
					<Grid container>
						<Grid item xs={8}>
							<div className={classes.titulo}>{carne.titulo}</div>
							<div className={classes.descricao}>{carne.descricao}</div>
						</Grid>
						<Grid item xs={4} align="right">
							<Button variant="contained" color="primary" onClick={imprimirCarne} size="small">
								Imprimir Carnê
							</Button>
							{carne.status_carne_id !== 2 &&
								<>
									<br />
									<Button
										style={{ marginTop: 5 }}
										variant="contained"
										color="primary"
										onClick={() => {
											setQuitaCarne({
												...quitaCarne,
												carneId: id,
												open: true,
											});
										}}
										size="small"
									>
										Quitar Carnê
									</Button>
								</>
							}
						</Grid>
					</Grid>
					<Grid container spacing={2} style={{ marginTop: 10 }}>
						<Grid item xs={3}>
							<div className={classes.fieldTitle}>Estabelecimento</div>
							<div className={classes.fieldValue}>{carne.estabelecimento.nome_fantasia}</div>
							{carne.estabelecimento.razao_social && (
								<div className={classes.fieldValue}>
									<small>{carne.estabelecimento.razao_social}</small>
								</div>
							)}
						</Grid>
						<Grid item xs={3}>
							<div className={classes.fieldTitle}>Cliente</div>
							<div className={classes.fieldValue}>{carne.cliente.nome}</div>
						</Grid>
						<Grid item xs={2}>
							<div className={classes.fieldTitle}>Valor</div>
							<div className={classes.fieldValue}>{Mask.Real(carne.valor)}</div>
						</Grid>
						<Grid item xs={2}>
							<div className={classes.fieldTitle}>Parcelas</div>
							<div className={classes.fieldValue}>{carne.parcelas}</div>
						</Grid>
						<Grid item xs={2}>
							<div className={classes.fieldTitle}>Dia de Vencimento</div>
							<div className={classes.fieldValue}>{carne.dia_vencimento}</div>
						</Grid>
					</Grid>
				</Paper>
			</Grid>

			<Grid item xs={12}>
				<Paper className={classes.paper}>
					<div className={classes.titulo}>Parcelas</div>
					<Grid container>
						<Grid item xs={3} className={classes.tableHeader}>
							Parcela
						</Grid>
						<Grid item xs={3} className={classes.tableHeader}>
							Valor
						</Grid>
						<Grid item xs={3} className={classes.tableHeader}>
							Data de Vencimento
						</Grid>
						<Grid item xs={2} className={classes.tableHeader}>
							Status
						</Grid>
						<Grid item xs={1} className={classes.tableHeader}>
						</Grid>
					</Grid>
					{carne.carnes_parcelas.map(parcela => (
						<Grid container className={classes.tableRow}>
							<Grid item xs={3}>
								{parcela.parcela}
							</Grid>
							<Grid item xs={3}>
								{Mask.Real(parcela.valor)}
							</Grid>
							<Grid item xs={3}>
								{format(parcela.data_vencimento, 'DD/MM/YYYY')}
							</Grid>
							<Grid item xs={2}>
								{getStatusIcon(parcela.pedido.status_pedido_id, parcela.data_vencimento)}
							</Grid>
							<Grid item xs={1}>
								{parcela.pedido.status_pedido_id !== 2 && parcela.pedido.status_pedido_id !== 15 &&
									<Tooltip title="Quitar Parcela">
										<span>
											<MdAttachMoney
												className={classes.menuIcon}
												onClick={() => {
													setBaixaParcela({
														...baixaParcela,
														parcelaId: parcela.id,
														open: true,
													});
												}}
											/>
										</span>
									</Tooltip>
								}
							</Grid>
						</Grid>
					))}
				</Paper>
			</Grid>
			<ZModalConfirm
				title={`Baixa de Parcela`}
				message={`Deseja dar baixa nessa parcela? Essa ação não pode ser desfeita.`}
				open={baixaParcela.open}
				onClose={baixarParcela}
			/>
			<ZModalConfirm
				title={`Quitar Carnê`}
				message={`Deseja quitar este carnê? Essa ação não pode ser desfeita.`}
				open={quitaCarne.open}
				onClose={quitarCarne}
			/>
		</Grid>
	);
};
