import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import ZLoader from '../system/ZLoader';
import LayoutFileSelector from './LayoutConfiguracoes/LayoutFileSelector';
import LayoutColorSelector from './LayoutConfiguracoes/LayoutColorSelector';
import Check from '@material-ui/icons/Check';
import Grow from '@material-ui/core/Grow';

registerPlugin(FilePondPluginImagePreview);
registerPlugin(FilePondPluginFileValidateType);

const useStyles = makeStyles(theme => ({
	configuracoes: {
		fontFamily: "'Exo 2', sans-serif",
		width: '100%',
		flexGrow: 1,
	},
	header: {
		fontWeight: 700,
		fontSize: 20,
		margin: 8,
	},
	tabPanels: {
		paddingTop: 10,
	},
	configBox: {
		position: 'relative',
		padding: 10,
		minHeight: 150,
	},
	configHeader: {
		fontWeight: 700,
		fontSize: 16,
		margin: 4,
	},
	configUpdateBtn: {
		textAlign: 'center',
		textTransform: 'uppercase',
		paddingBottom: 15,
		fontWeight: 700,
		fontSize: 12,
		cursor: 'pointer',
		color: 'black',
	},
	colorPicker: {
		display: 'flex',
		justifyContent: 'center',
		'& .MuiTextField-root': {
			width: '100% !important',
		},
	},
	icon: {
		color: 'black',
	},
	updateCheck: {
		position: 'absolute',
		top: 10,
		right: 1,
	},
}));

const LayoutBox = props => {
	const { title, slug, logoData, setLogoData, estabelecimentoId, token } = props;
	const classes = useStyles();
	const [slugUpdated, setSlugUpdated] = useState(null);
	const [submit, setSubmit] = useState(false);

	return (
		<Paper style={{ minHeight: '350px', display: 'grid', alignContent: 'space-between' }}>
			<div className={classes.configBox}>
				<div className={classes.updateCheck}>
					<Grow in={slugUpdated === slug}>
						<Check className={classes.icon} />
					</Grow>
				</div>
				<div className={classes.configHeader}>{title}</div>
				<div>
					{slug === 'primary_color' ? (
						<div className={classes.colorPicker}>
							<LayoutColorSelector
								submit={submit}
								setSubmit={setSubmit}
								setSlugUpdated={setSlugUpdated}
								slugUpdated={slugUpdated}
								estabelecimentoId={estabelecimentoId}
								slug={slug}
								token={token}
							/>
						</div>
					) : (
						<LayoutFileSelector
							estabelecimentoId={estabelecimentoId}
							slug={slug}
							setSlugUpdated={setSlugUpdated}
							slugUpdated={slugUpdated}
							submit={submit}
							setSubmit={setSubmit}
							logoData={logoData}
							setLogoData={setLogoData}
							token={token}
						/>
					)}
				</div>
			</div>
			<div className={classes.configUpdateBtn} onClick={() => setSubmit(true)}>
				{submit ? <ZLoader height={15} /> : <>Atualizar</>}
			</div>
		</Paper>
	);
};
export default LayoutBox;
