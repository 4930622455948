import React, { useState } from 'react';
import { Grid, Typography, Button, makeStyles, InputAdornment } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Close';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { addMessage } from '../../store/alert';
import moment from 'moment';

import { addDays } from 'date-fns';
import ZText from '../system/ZText';
import ZDatePicker from '../system/ZDatePicker';
import { Mask } from '../../utils';

const useStyles = makeStyles(theme => ({
	fullWidth: {
		width: '100%',
	},
	stepActions: {
		marginTop: 60,
		textAlign: 'center',
	},
	backButton: {
		color: theme.palette.primary.main,
		border: `solid 1px ${theme.palette.primary.main}`,
		width: 171,
		height: 44,
		marginRight: 10,
		'&:hover': {
			color: '#FFF',
			backgroundColor: theme.palette.primary.main,
		},
		[theme.breakpoints.down('sm')]: {
			marginRight: 0,
			width: '100%',
		},
	},
	nextButton: {
		width: 171,
		height: 44,
		backgroundColor: theme.palette.primary.main,
		color: '#FFF',
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	stepTitle: {
		color: '#3E3B47',
		marginBottom: 48,
	},
	removeButton: {
		minHeight: 26,
		height: 26,
		width: 26,
		backgroundColor: '#e53935',
		color: 'white',
	},
	label: {
		fontSize: 11,
		textTransform: 'uppercase',
		fontWeight: 700,
	},
	error: {
		padding: 8,
		borderRadius: 5,
		backgroundColor: '#e53935',
		color: 'white',
		marginBottom: 10,
	},
	descontoValor: {
		'&.porcentagem input': {
			textAlign: 'right',
		},
	},
	limitDateError: {
		fontSize: 12,
		color: '#e53935',
	},
}));

export default ({ setData, data, carne, errors, setErrors, setActiveStep, split, canSplit }) => {
	const classes = useStyles();
	const [mode, setMode] = useState(data.descontos[0].mode === '' ? 'FIXED' : data.descontos[0].mode);
	const dispatch = useDispatch();
	const [disableNew, setDisableNew] = useState(false);
	const [nextStep, setNextStep] = useState(true);

	if (data.descontos[0].limitDate === '') {
		data.descontos[0].limitDate = new Date();
	}

	// eslint-disable-next-line no-unused-vars
	const handleNewDesconto = () => {
		const descontos = [...data.descontos];

		descontos.push({
			mode: '',
			value: 0,
			limitDate: new Date(),
		});

		if (descontos.length === 3) {
			setDisableNew(true);
		}

		setData({
			...data,
			descontos,
		});
	};

	const updateDesconto = async (index, name, value) => {
		let descontos = [...data.descontos];
		let desconto = descontos.find((_, i) => i === index);
		setNextStep(true);

		if (name === 'limitDate') {
			delete errors[`limitDate${index}`];
			setErrors(errors);
			desconto.limitDate = value;

			data.descontos.map((d, i) => {
				if (moment(d.limitDate).unix() === moment(desconto.limitDate).unix() && index !== i) {
					setNextStep(false);
					setErrors({
						...errors,
						[`limitDate${index}`]: 'Você não pode selecionar a mesma data.',
					});
					dispatch(addMessage({ type: 'error', message: 'Você não pode selecionar a mesma data.' }));
				}
				return true;
			});
		} else {
			delete errors[`value${index}`];
			setErrors(errors);

			if (desconto) {
				desconto.value = value;

				if (mode === 'PERCENTAGE') {
					if (desconto.value > 100) {
						desconto.value = 100;
					}
				}

				// const valueExists = data.descontos.map((d, i) => {
				// 	if (d.value === desconto.value && index !== i) {
				// 		setNextStep(false);
				// 		setErrors({
				// 			...errors,
				// 			[`value${index}`]: 'Valor ou porcentagem do desconto devem ser diferentes.',
				// 		});
				// 		dispatch(
				// 			addMessage({
				// 				type: 'error',
				// 				message: 'Valor ou porcentagem do desconto devem ser diferentes.',
				// 			})
				// 		);
				// 		return;
				// 	}
				// });
				if (data.formaPagamento === 4) {
					if (mode === 'FIXED' && value >= data.valorCarne / data.parcelasCarne) {
						setNextStep(false);
						setErrors({
							...errors,
							[`value${index}`]: 'Valor do desconto não pode ser maior ou igual ao valor da parcela.',
						});
						dispatch(
							addMessage({
								type: 'error',
								message: 'Valor do desconto não pode ser maior ou igual ao valor da parcela.',
							})
						);
						return;
					}
				} else {
					if (mode === 'FIXED' && value >= data.valorBoleto) {
						setErrors({
							...errors,
							[`value${index}`]: 'Valor do desconto não pode ser maior ou igual ao valor do boleto.',
						});
						dispatch(
							addMessage({
								type: 'error',
								message: 'Valor do desconto não pode ser maior ou igual ao valor do boleto.',
							})
						);
						return;
					}
				}
			}
		}

		desconto.mode = mode;
		descontos[index] = desconto;
		setData({ ...data, descontos });
	};

	const handleRemoveDesconto = position => {
		const descontos = [...data.descontos];
		descontos.splice(position, 1);

		setDisableNew(false);

		setData({
			...data,
			descontos,
		});
	};

	const handleChange = event => {
		setMode(event.target.value);

		let descontos = [...data.descontos];
		descontos.map(d => {
			d.mode = event.target.value;
			return true;
		});

		setData({ ...data, descontos });
	};

	const handleNext = () => {
		if (nextStep) {
			NextStep();
		} else {
			if (!errors) {
				dispatch(addMessage({ type: 'error', message: 'Existem campos inválidos no formulário!' }));
			}
			setActiveStep(3);
		}
	};

	const NextStep = () => {
		if (canSplit && split === 0) {
			setActiveStep(4);
		} else {
			setActiveStep(5);
		}
	};

	return (
		<>
			<Grid container alignItems="center">
				<Typography variant="h5" component="h6" className={classes.stepTitle}>
					Descontos
				</Typography>
				<Grid container xs={12} justifyContent="center">
					<Typography variant="body1" component="h6" className={classes.stepTitle}>
						Se você não deseja aplicar desconto nesse boleto, clique em continuar
					</Typography>
				</Grid>

				<Grid container item md={12}>
					<Grid container style={{ marginBotton: 10 }}>
						{errors.porcentagem && (
							<Grid item xs={12}>
								<div className={classes.error}>
									O total da porcentagem deve ser menor ou igual a 100%
								</div>
							</Grid>
						)}
						<Grid item xs={6}></Grid>
						<Grid container xs={12} alignItems="center">
							<Grid item xs={12} md={6}>
								<FormControl component="fieldset">
									<FormLabel style={{ fontSize: 12 }} component="legend">
										Tipo do Desconto
									</FormLabel>
									<RadioGroup row aria-label="mode" name="mode" value={mode} onChange={handleChange}>
										<FormControlLabel value="FIXED" control={<Radio />} label="Valor Fixo" />
										<FormControlLabel value="PERCENTAGE" control={<Radio />} label="Porcentagem" />
									</RadioGroup>
								</FormControl>
							</Grid>
							<Grid item xs={12} md={6}>
								<Button
									onClick={() => handleNewDesconto()}
									disabled={disableNew}
									variant="contained"
									size="small"
									color="primary"
									aria-label="add"
								>
									<AddIcon /> Desconto
								</Button>
							</Grid>
						</Grid>
					</Grid>
					<Grid container spacing={3}>
						{data.descontos.map((desconto, key) => {
							return (
								<>
									<Grid item xs={12} md={12} style={{ marginBottom: 10, marginTop: 15 }}>
										<Grid container spacing={3} alignItems="center">
											<Grid
												style={{
													display: 'flex',
													alignItems: 'center',
												}}
												item
												xs={12}
												md={3}
											>
												<ZText
													error={errors[`value${key}`]}
													endAdornment={
														mode === 'PERCENTAGE' ? (
															<InputAdornment position="end">%</InputAdornment>
														) : (
															false
														)
													}
													className={clsx(classes.fullWidth, classes.descontoValor)}
													label={mode === 'FIXED' ? 'Valor' : 'Porcentagem'}
													value={
														mode === 'FIXED'
															? Mask.Real(desconto.value)
															: Mask.Porcentagem(desconto.value)
													}
													onChange={(value, setValue) => {
														value = Mask.OnlyNumber(value) / 100;
														setValue(
															mode === 'FIXED'
																? Mask.Real(value)
																: value > 100
																	? Mask.Porcentagem(100)
																	: Mask.Porcentagem(value)
														);
													}}
													onBlur={e =>
														updateDesconto(
															key,
															'valor',
															Mask.OnlyNumber(e.target.value) / 100
														)
													}
													onFocus={e => {
														const length = e.target.value.length * 2;
														e.target.setSelectionRange(0, length);
													}}
												/>
											</Grid>
											<Grid
												style={{
													display: 'flex',
													alignItems: 'center',
													marginTop: 8,
												}}
												item
												xs={12}
												md={3}
											>
												<ZDatePicker
													error={errors[`limitDate${key}`]}
													textField
													label="Data limite"
													erro={errors.limitDate}
													date={desconto.limitDate}
													minDate={new Date()}
													maxDate={data.dataVencimento}
													onChange={date => {
														updateDesconto(key, 'limitDate', date);
													}}
												/>
											</Grid>
											<Grid
												className={classes.valorLiquido}
												style={{
													display: 'flex',
													alignItems: 'center',
												}}
												item
												xs={12}
												md={1}
											>
												{key > 0 && (
													<Grid item lg={1} sm={1} xs={1}>
														<Fab
															size="small"
															className={classes.removeButton}
															onClick={() => handleRemoveDesconto(key)}
														>
															<RemoveIcon size="small" style={{ fontSize: 15 }} />
														</Fab>
													</Grid>
												)}
											</Grid>
										</Grid>
									</Grid>
								</>
							);
						})}
					</Grid>
				</Grid>

				<Grid container justifyContent="flex-end" spacing={2} direction="row" className={classes.stepActions}>
					<Grid item xs={12} sm={12} md={6} lg={3}>
						<Button className={classes.backButton} fullWidth onClick={() => setActiveStep(2)}>
							Voltar
						</Button>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={3}>
						<Button
							className={classes.nextButton}
							fullWidth
							// onClick={() => setActiveStep(4)}
							onClick={() => {
								data.formaPagamento === 4 ? handleNext() : NextStep();
							}}
							style={{ color: '#fff' }}
						>
							Continuar
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};
