import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Container from '@material-ui/core/Container';

import axios from 'axios';
import config from 'react-global-configuration';
import moment from 'moment';
import { connect } from 'react-redux';
import { addMessage } from '../../store/alert';
import { bindActionCreators } from 'redux';
import Link from '@material-ui/core/Link';

import ZSaveButton from '../system/ZSaveButton';
import ZText from '../system/ZText';
import ZSelect from '../system/ZSelect';
import ZAlert from '../system/ZAlert';
import { Mask, ValidarCpf, ValidatorForm } from '../../utils/';

import Z4MoneyLogoWhite from '../../assets/images/z4money0white.png';

const validateFields = {
	tipoEstabelecimentoId: {
		required: true,
	},
	nome: {
		required: true,
	},
	email: {
		required: true,
	},
	celular: {
		required: true,
	},
	cpf: {
		required: true,
	},
	endereco: {
		logradouro: {
			required: true,
		},
		numero: {
			required: true,
		},
		cidade: {
			required: true,
		},
		estado: {
			required: true,
		},
		cep: {
			required: true,
		},
	},
};

const ValidateFieldsPessoaJuridica = {
	tipoEstabelecimentoId: {
		required: true,
	},
	razaoSocial: {
		required: true,
	},
	nomeFantasia: {
		required: true,
	},
	celular: {
		required: true,
	},
	email: {
		required: true,
	},
	cnpj: {
		required: true,
	},
	endereco: {
		logradouro: {
			required: true,
		},
		numero: {
			required: true,
		},
		cidade: {
			required: true,
		},
		estado: {
			required: true,
		},
		cep: {
			required: true,
		},
	},
};

function Cadastrar(props) {
	const [loading, setLoading] = useState(false);
	// const [errors, setErrors] = useState([]);
	const [onError, setOnError] = useState(false);
	const [concluido, setConcluido] = useState(false);

	const [data, setData] = useState({
		tipoEstabelecimentoId: 1,
		nome: '',
		email: '',
		telefone: '',
		celular: '',
		dataNascimento: '',
		dataNascimento2: '',
		cpf: '',
		endereco: {
			logradouro: '',
			numero: '',
			cidade: '',
			estado: '',
			cep: '',
			complemento: '',
		},
	});

	const handleSubmitForm = async () => {
		// setErrors([]);
		setLoading(true);
		try {
			if (+data.tipoEstabelecimentoId === 1) {
				ValidatorForm(validateFields, data);
			} else {
				ValidatorForm(ValidateFieldsPessoaJuridica, data);
			}
		} catch (erro) {
			props.addMessage({ type: 'error', message: 'Existem campos inválidos no formulário' });
			setLoading(false);
			setOnError(true);
			return false;
		}

		var res = await axios.post(`${config.get('apiUrl')}estabelecimentos/pre_cadastro`, data);

		setLoading(false);
		if (!res.data.success) {
			props.addMessage({ type: 'error', message: res.data.error });
			return false;
		}

		props.addMessage({ type: 'success', message: res.data.message });
		// props.history.push('/estabelecimentos');
		setConcluido(true);
	};

	const handleClearForm = () => {
		setData({
			tipoEstabelecimentoId: 1,
			nome: '',
			email: '',
			telefone: '',
			celular: '',
			dataNascimento: '',
			dataNascimento2: '',
			cpf: '',
			endereco: {
				logradouro: '',
				numero: '',
				cidade: '',
				estado: '',
				cep: '',
				complemento: '',
			},
		});

		setConcluido(false);
	};

	const handleTipoEstabelecimento = name => event => {
		if (+event.target.value === 1) {
			setData({
				tipoEstabelecimentoId: 1,
				nome: '',
				email: '',
				telefone: '',
				celular: '',
				dataNascimento: '',
				dataNascimento2: '',
				cpf: '',
				endereco: {
					logradouro: '',
					numero: '',
					cidade: '',
					estado: '',
					cep: '',
					complemento: '',
				},
			});
		} else {
			setData({
				tipoEstabelecimentoId: 2,
				razaoSocial: '',
				nomeFantasia: '',
				telefone: '',
				celular: '',
				email: '',
				cnpj: '',
				endereco: {
					logradouro: '',
					numero: '',
					cidade: '',
					estado: '',
					cep: '',
					complemento: '',
				},
			});
		}
	};

	const handleChange = name => event => {
		if (name === 'cpf') {
			if (!ValidarCpf(event.target.value)) {
				// setErrors(['CPF inválido']);
				// props.addMessage({type:'error', message: 'CPF inválido'})
				setData({
					...data,
					cpf: '',
				});
				return false;
			}
		}

		var value = event.target.value;
		let onlyNumber = ['celular', 'telefone', 'cnpj', 'cpf', 'celular'];

		if (onlyNumber.indexOf(name) !== -1) value = Mask.OnlyNumber(value);

		setData({
			...data,
			[name]: value,
		});
	};

	const handleDataNascimento = name => event => {
		if (name === 'dataNascimento2') {
			setData({
				...data,
				[name]: event.target.value,
				dataNascimento: moment(event.target.value, 'DD/MM/YYYY').format('YYYY-MM-DD'),
			});
		}
	};

	const handleChangeEndereco = name => async event => {
		var value = event.target.value;
		if (name === 'cep') {
			value = Mask.OnlyNumber(value);
		}

		if (name === 'cep' && event.target.value.length === 9) {
			var res = await axios.get(config.get('apiUrl') + 'buscar_cep/' + event.target.value);

			if (res.data.success === false || res.data.endereco.erro) {
				setData({
					...data,
					endereco: {
						...data.endereco,
						cep: value,
						logradouro: '',
						cidade: '',
						estado: '',
					},
				});
				// setErrors([ 'CEP não encontrado' ]);
				props.addMessage({ type: 'error', message: 'CEP não encontrado' });
				return false;
			}

			setData({
				...data,
				endereco: {
					...data.endereco,
					cep: value,
					logradouro: res.data.endereco.logradouro,
					cidade: res.data.endereco.localidade,
					estado: res.data.endereco.uf,
				},
			});
		} else {
			setData({
				...data,
				endereco: {
					...data.endereco,
					[name]: value,
				},
			});
		}
	};

	return (
		<div style={{ paddingTop: 40, paddingBottom: 40, background: '#001e55', minHeight: '100vh' }}>
			{props.message.message.length && (
				<ZAlert
					type={props.message.type}
					message={props.message.message}
					time={moment().unix()}
					vertical="bottom"
				/>
			)}

			<Container maxWidth="sm">
				<Grid container>
					<Grid item xs={12} style={{ textAlign: 'center' }}>
						<Link href={'https://www.z4money.com.br'} color="inherit">
							<img
								src={Z4MoneyLogoWhite}
								style={{ maxWidth: '70%', marginBottom: 70, marginTop: 10 }}
								alt="Logo Z 4 Money "
								title="Logo Z4 MONEY"
							/>
						</Link>
					</Grid>
					<Grid item xs={12}>
						<Paper style={{ padding: 15 }}>
							{!concluido && (
								<React.Fragment>
									<Grid item xs={12}>
										<Grid container>
											<Grid item xs={12} style={{ marginBottom: 10 }}>
												<Typography align="center" variant="h6" gutterBottom>
													Informações do estabelecimento
												</Typography>
												<Divider />
											</Grid>
											<Grid item xs={12} md={12} lg={12}>
												<ZSelect
													options={[
														{
															id: 1,
															name: 'Pessoa Física',
														},
														{
															id: 2,
															name: 'Pessoa Jurídica',
														},
													]}
													label="Tipo"
													width="100%"
													required={true}
													value={data.tipoEstabelecimentoId}
													onChange={handleTipoEstabelecimento('tipoEstabelecimentoId')}
												/>
											</Grid>
											{data.tipoEstabelecimentoId === 1 && (
												<React.Fragment>
													<Grid item sm={6} xs={12} md={6} lg={6}>
														<ZText
															label="Nome"
															onError={onError}
															required={true}
															value={data.nome}
															onBlur={handleChange('nome')}
														/>
													</Grid>
													<Grid item sm={6} xs={12} md={6} lg={6}>
														<ZText
															label="E-mail"
															onError={onError}
															required={true}
															type="email"
															email={true}
															value={data.email}
															onBlur={handleChange('email')}
														/>
													</Grid>
													<Grid item sm={6} xs={12} md={6} lg={6}>
														<ZText
															label="CPF"
															onError={onError}
															required={true}
															value={data.cpf}
															mask="999.999.999-99"
															onBlur={handleChange('cpf')}
														/>
													</Grid>
													<Grid item sm={6} xs={12} md={6} lg={6}>
														<ZText
															label="Telefone"
															value={data.telefone}
															onBlur={handleChange('telefone')}
															mask="(99) 9999 9999"
														/>
													</Grid>
													<Grid item sm={6} xs={12} md={6} lg={6}>
														<ZText
															label="Celular"
															onError={onError}
															required={true}
															value={data.celular}
															mask="(99) 9 9999-9999"
															onBlur={handleChange('celular')}
														/>
													</Grid>
													<Grid item sm={6} xs={12} md={6} lg={6}>
														<ZText
															label="Data Nascimento"
															value={data.dataNascimento2}
															mask="99/99/9999"
															onBlur={handleDataNascimento('dataNascimento2')}
														/>
													</Grid>
												</React.Fragment>
											)}

											{data.tipoEstabelecimentoId === 2 && (
												<React.Fragment>
													<Grid item sm={6} xs={12} md={6} lg={6}>
														<ZText
															label="CNPJ"
															onError={onError}
															required={true}
															mask="99.999.999/9999-99"
															value={data.cnpj}
															onBlur={handleChange('cnpj')}
														/>
													</Grid>
													<Grid item sm={6} xs={12} md={6} lg={6}>
														<ZText
															label="Razão social"
															onError={onError}
															required={true}
															value={data.razaoSocial}
															onBlur={handleChange('razaoSocial')}
														/>
													</Grid>
													<Grid item sm={6} xs={12} md={6} lg={6}>
														<ZText
															label="Nome Fantasia"
															onError={onError}
															required={true}
															value={data.nomeFantasia}
															onBlur={handleChange('nomeFantasia')}
														/>
													</Grid>
													<Grid item sm={6} xs={12} md={6} lg={6}>
														<ZText
															label="Telefone"
															value={data.telefone}
															onBlur={handleChange('telefone')}
															mask="(99) 9999 99999"
														/>
													</Grid>
													<Grid item sm={6} xs={12} md={6} lg={6}>
														<ZText
															label="Celular"
															onError={onError}
															required={true}
															value={data.celular}
															onBlur={handleChange('celular')}
															mask="(99) 9999 99999"
														/>
													</Grid>
													<Grid item sm={6} xs={12} md={6} lg={6}>
														<ZText
															label="E-mail"
															onError={onError}
															required={true}
															email={true}
															value={data.email}
															onBlur={handleChange('email')}
														/>
													</Grid>
												</React.Fragment>
											)}
										</Grid>
										<Grid container>
											<Grid item xs={12} style={{ marginTop: 65, marginBottom: 10 }}>
												<Typography align="center" variant="h6" gutterBottom>
													Endereço
												</Typography>
												<Divider />
											</Grid>
											<React.Fragment>
												<Grid item sm={6} xs={12} md={6} lg={6}>
													<ZText
														label="CEP"
														onError={onError}
														required={true}
														mask="99999-999"
														value={data.endereco.cep}
														onBlur={handleChangeEndereco('cep')}
													/>
												</Grid>
												<Grid item sm={6} xs={12} md={6} lg={6}>
													<ZText
														label="Número"
														onError={onError}
														required={true}
														value={data.endereco.numero}
														onBlur={handleChangeEndereco('numero')}
													/>
												</Grid>
												<Grid item sm={6} xs={12} md={6} lg={6}>
													<ZText
														label="Complemento"
														onError={onError}
														value={data.endereco.complemento}
														onBlur={handleChangeEndereco('complemento')}
													/>
												</Grid>
												<Grid item sm={6} xs={12} md={6} lg={6}>
													<ZText
														label="Rua"
														inputProps={{ readOnly: true }}
														value={data.endereco.logradouro}
														onBlur={handleChangeEndereco('logradouro')}
													/>
												</Grid>
												<Grid item sm={6} xs={12} md={6} lg={6}>
													<ZText
														label="Cidade"
														inputProps={{ readOnly: true }}
														value={data.endereco.cidade}
														onBlur={handleChangeEndereco('cidade')}
													/>
												</Grid>
												<Grid item sm={6} xs={12} md={6} lg={6}>
													<ZText
														label="Estado"
														inputProps={{ readOnly: true }}
														value={data.endereco.estado}
														onBlur={handleChangeEndereco('estado')}
													/>
												</Grid>
											</React.Fragment>
										</Grid>
									</Grid>
									<Grid item xs={12}>
										<Grid
											container
											direction="row"
											justifyContent="flex-end"
											alignItems="center"
											style={{ marginTop: 25 }}
										>
											<ZSaveButton
												onClick={() => handleSubmitForm()}
												color={'primary'}
												align="right"
												label={'Cadastrar'}
												loading={loading}
											/>
										</Grid>
									</Grid>
								</React.Fragment>
							)}

							{concluido && (
								<Grid
									container
									direction="row"
									justifyContent="center"
									alignItems="center"
									style={{ minHeight: '300px' }}
								>
									<Grid item xs={12}>
										<Typography align="center" variant="h4" gutterBottom style={{ fontSize: 29 }}>
											Pré cadastro realizado com sucesso!
										</Typography>
										<br />
										<Typography align="center" variant="h6">
											Em breve entraremos em contato com você!
										</Typography>
									</Grid>
									<Grid item xs={12} align="center">
										<Button variant="contained" color="primary" onClick={() => handleClearForm()}>
											Voltar
										</Button>
									</Grid>
								</Grid>
							)}
						</Paper>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
}

const mapStateToProps = store => ({
	token: store.auth.token,
	usuario: store.auth.usuario,
	message: store.alert,
});

const mapDispatchToProps = dispatch => bindActionCreators({ addMessage }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Cadastrar);
