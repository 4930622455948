import React, { useState, useEffect } from 'react';
import { createTheme, ThemeProvider, useTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isWhite } from '../../utils';

const useStyles = makeStyles(theme => ({
	checkboxContainer: {
		display: 'flex',
		alignItems: 'center',
		marginLeft: theme.spacing(1),
		marginBottom: theme.spacing(2),
	},
	checkboxText: {
		marginRight: theme.spacing(3),
	},
}));
const customTheme = createTheme({
	palette: {
		primary: {
			main: '#000000',
		},
	},
});

const ZModalConfirm = props => {
	const [open, setOpen] = useState(false);
	const title = props.title;
	const message = props.message;
	const submessage = props.submessage;
	const isCobranca = props.isCobranca;
	const handleCancelRetroativoChange = props.handleCancelRetroativoChange;
	const cancelRetroativo = props.cancelRetroativo;
	const [disabled, setDisabled] = useState(false);
	const handleClose = result => {
		if (result === 'escapeKeyDown' || (result === 'backdropClick' && props.title !== `Enviar email?`)) {
			props.onClose(false);
		} else if (result === 'escapeKeyDown' || (result === 'backdropClick' && props.title === `Enviar email?`)) {
			props.setIsModalOpen(false);
		} else {
			props.onClose(result);
		}
		setOpen(false);
		setDisabled(true);
	};

	useEffect(() => {
		setOpen(props.open);
		setDisabled(false);
	}, [props.open]);

	const classes = useStyles();
	const theme = useTheme();

	return (
		<Dialog
			open={open}
			onClose={(event, reason) => {
				handleClose(reason);
			}}
			// disableBackdropClick
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">{title}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">{message}</DialogContentText>
				{submessage && <DialogContentText id="alert-dialog-description">{submessage}</DialogContentText>}
			</DialogContent>
			{isCobranca && (
				<div className={classes.checkboxContainer}>
					<Checkbox checked={cancelRetroativo} onChange={handleCancelRetroativoChange} color="primary" />
					<span className={classes.checkboxText}>Marque para excluir débitos anteriores</span>
				</div>
			)}
			<DialogActions>
				<ThemeProvider theme={isWhite(theme.palette.primary.main) ? customTheme : theme}>
					<Button onClick={() => handleClose(false)} color="primary" disabled={disabled}>
						Não
					</Button>
					<Button onClick={() => handleClose(true)} color="primary" disabled={disabled} autoFocus>
						Sim
					</Button>
				</ThemeProvider>
			</DialogActions>
		</Dialog>
	);
};

export default ZModalConfirm;
