/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Grid, Container } from '@material-ui/core';
import axios from 'axios';
import config from 'react-global-configuration';
import { Isset } from '../../utils';
import { useSelector } from 'react-redux';
import 'react-credit-cards/es/styles-compiled.css';
import ZNotFound from '../system/ZNotFound';

export default props => {
	// const { match } = useReactRouter();
	const token = useSelector(store => store.auth.token);
	// const usuario = useSelector(store => store.auth.usuario);

	// const theme = useTheme();

	// const classes = useStyles();
	// const dispatch = useDispatch();

	const [data, setData] = useState(null);
	// const [loading, setLoading] = useState(true);
	// const [isMine, setIsMine] = useState(false);

	// const [isRefunded, setIsRefunded] = useState(false);

	const getData = async () => {
		setData(null);
		// setLoading(true);
		let req = await axios.post(`${config.get('apiUrl')}vendas/` + props.pedidoId + '/boleto',{}, {
			headers: { Authorization: `Bearer ${token}` },
		});

		if (req.data.success) {
			const venda = req.data.venda;
			setData(venda);
		}
		// setLoading(false);
	};

	useEffect(() => {
		getData();
	}, []);

	if (
		Isset(() => data.status.id) &&
		data.status.id === 7
		// (Isset(() => data.id) && (!Isset(() => data.pagamentos) || data.pagamentos.length < 1))
	) {
		return <ZNotFound text="Venda Em Processamento" />;
	}
	return (
		<Container maxWidth={'md'} id="section-to-print">
			<Grid container>
				<Grid item xs={12}>
					{/* <div className={classes.header}>Detalhes da Transação</div> */}
					<img
						title="img empresa"
						alt="img empresa"
						src={
							'https://z-systems-bucket.s3.sa-east-1.amazonaws.com/files/system_config/others/Banco-Monetiza.png'
						}
						style={{ width: '100%' }}
					/>
				</Grid>
				<Grid item xs={12}>
					{/* <div className={classes.header}>Detalhes da Transação</div> */}
					{data && data.tipoPagamento.boleto && (
						<iframe
							scrolling="no"
							frameBorder="0"
							title="boleto"
							style={{ width: '900px', display: 'table', height: 1200 }}
							src={data.tipoPagamento.boleto.url}
						/>
					)}
				</Grid>
			</Grid>
		</Container>
	);
};
