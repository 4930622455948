/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import axios from 'axios';
import clsx from 'clsx';
import config from 'react-global-configuration';
import { Mask, Isset } from '../../utils';

import TextField from '@material-ui/core/TextField';
import ZLoader from '../system/ZLoader';
import TaxaPix from './taxaPix';
import { Button } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { FaCreditCard } from 'react-icons/fa';

//import das bandeiras
import mastercard from '../../assets/flags/mastercard.png';

import american from '../../assets/flags/american.png';
import elo from '../../assets/flags/elo-logo.png';
import visa from '../../assets/flags/visa-logo.png';
import diners from '../../assets/flags/dinners.png';
import hiper from '../../assets/flags/hiper-logo.png';
import hipercard from '../../assets/flags/hipercard-logo.png';
import banescard from '../../assets/flags/banescard-logo.png';
import aura from '../../assets/flags/aura-logo.png';
import cabal from '../../assets/flags/cabal-logo.png';
import discover from '../../assets/flags/discover.png';
import jcb from '../../assets/flags/jcb-logo.png';

const useStyles = makeStyles(theme => ({
	taxasWrapper: {
		minHeight: 740,
	},
	taxasWrapper21: {
		minHeight: 1000,
	},
	pageTitle: {
		fontWeight: 'bold',
		fontSize: 18,
		marginBottom: 10,
	},
	planosTaxas: {
		position: 'relative',
	},
	tiposPagamentos: {
		position: 'absolute',
		paddingTop: 50,
		paddingBottom: 52,
		border: 'solid 1px #abc',
		borderRadius: 10,
		padding: 10,
		width: 'calc(100% - 30px)',
	},
	tipoPagamento: {
		fontWeight: 'bold',
		fontSize: 16,
		padding: 4,
		'&:not(:last-child)': {
			borderBottom: 'solid 1px #bcd',
		},
	},
	tipoPagamentoBoleto: {
		fontWeight: 'bold',
		fontSize: 16,
		padding: 4,
	},
	bandeirasWrapper: {
		position: 'absolute',
		marginLeft: 120,
		width: 'calc(100% - 160px)',
	},
	bandeira: {
		position: 'relative',
		padding: 10,
		marginTop: 10,
		border: 'solid 1px #abc',
		borderRadius: 10,
		backgroundColor: '#ffffffc0',
		width: 105,
		marginRight: 10,
		float: 'left',
	},
	novaBandeira: {
		position: 'relative',
		padding: 10,
		marginTop: 10,
		border: 'solid 1px #abc',
		borderRadius: 10,
		backgroundColor: '#ffffff',
		width: 160,
		marginRight: 10,
		float: 'left',
	},
	title: {
		textAlign: 'center',
		fontWeight: 'bold',
		fontSize: 16,
		height: 22,
		display: 'flex',
		justifyContent: 'center',
	},
	titleLong: {
		fontSize: 11,
		display: 'flex',
	},
	deleteButton: {
		top: 2,
		right: 2,
		cursor: 'pointer',
		position: 'absolute',
		width: 20,
		height: 20,
		color: '#e53935',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		opacity: 0.5,
		'&:hover': {
			opacity: 1,
		},
	},
	fields: {
		marginTop: 8,
		marginBottom: 15,
		borderTop: 'solid 1px #bcd',
	},
	tipoPagamentoFields: {
		borderBottom: 'solid 1px #bcd',
	},
	taxaField: {
		padding: 8,
		// '&::after': {
		// 	content: '"%"',
		// 	position: 'absolute',
		// 	right: 4,
		// 	fontSize: 12,
		// },
		'& .MuiInputBase-input': {
			textAlign: 'right',
			height: '1em',
			padding: 0,
			paddingRight: 15,
		},
		'& .MuiInput-underline:before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
			bottom: -7,
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: 'none',
		},
	},
	taxaFieldBoleto: {
		width: 100,
		padding: 7.45,
		// '&::after': {
		// 	content: '"R$"',
		// 	position: 'absolute',
		// 	left: 4,
		// 	fontSize: 12,
		// },
		'& .MuiInputBase-input': {
			textAlign: 'right',
			height: '1em',
			padding: 0,
			paddingRight: 15,
		},
		'& .MuiInput-underline:before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
			bottom: -7,
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: 'none',
		},
	},
	saveButton: {
		textAlign: 'center',
		textTransform: 'uppercase',
		fontWeight: 700,
		fontSize: 12,
		cursor: 'pointer',
		color: theme.palette.primary.main,
		// marginTop: 5,
	},
	adicionarButton: {
		textAlign: 'center',
		textTransform: 'uppercase',
		fontWeight: 700,
		fontSize: 12,
		cursor: 'pointer',
		color: theme.palette.primary.main,
		marginTop: 15,
	},
	taxaFieldReadOnly: {
		height: 30.8,
		textAlign: 'center',
		fontWeight: 'bold',
		fontSize: 15,
		padding: 4,
	},
	boleto: {
		marginTop: 10,
		display: 'flex',
		border: 'solid 1px #abc',
		borderRadius: 10,
		padding: 10,
	},
}));

const TaxaField = memo(props => {
	const { readOnly, money } = props;
	const [value, setValue] = useState(Mask.Porcentagem(props.value));
	const [isTyping, setIsTyping] = useState(null);
	const classes = useStyles();

	const onChange = e => {
		const value = e.target.value;
		let valor = Mask.OnlyNumber(e.target.value) / 100;
		if (!money && valor > 100) {
			valor = 100;
		}
		setValue(Mask.Porcentagem(valor));

		if (money) {
			if (isTyping) {
				clearTimeout(isTyping);
			}

			const timeout = setTimeout(() => {
				if (props.onChange) {
					props.onChange({ target: { value } });
				}
			}, 500);

			setIsTyping(timeout);
		} else {
			if (props.onChange) {
				props.onChange(e);
			}
		}
	};

	if (readOnly) {
		if (money) {
			return <div className={classes.taxaFieldReadOnly}>{`R$ ${value}`}</div>;
		}
		return <div className={classes.taxaFieldReadOnly}>{`${value} %`}</div>;
	}

	return <TextField {...props} value={value} onChange={onChange} />;
});

export default memo(props => {
	const { planoTaxaId, readOnly } = props;

	let { tipoVendaId } = props;
	if (!tipoVendaId) {
		tipoVendaId = 1;
	}

	const classes = useStyles();

	const [isPercentage, setIsPercentage] = useState(true);

	const token = useSelector(store => store.auth.token);

	const [taxas, setTaxas] = useState({});
	const [countBandeiras, setCountBandeiras] = useState(1);
	const [loading, setLoading] = useState(true);
	const [savingId, setSavingId] = useState(null);

	const [taxaBoleto, setTaxaBoleto] = useState(0);
	const [taxaBoletoChanged, setTaxaBoletoChanged] = useState(false);
	const [savingBoleto, setSavingBoleto] = useState(false);
	const [planoVenda, setPlanoVenda] = useState({});
	const [hasOnline, setHasOnline] = useState(false);
	const [maxParcelas, setMaxParcelas] = useState(0);

	const TooltipTitle = props => {
		return (
			<div>
				<div>{props.type}</div>
				<div>{props.brand}</div>
			</div>
		);
	};

	const getPaymentIcon = brand => {
		switch (brand) {
			case 'Mastercard':
			case 'MasterCard':
			case 'Maestro':
				return (
					<Tooltip title={<TooltipTitle brand={brand} />}>
						<div style={{ display: 'flex' }}>
							<img alt="" height={25} src={mastercard} />
						</div>
					</Tooltip>
				);
			case 'Visa':
			case 'Visa Electron':
				return (
					<Tooltip title={<TooltipTitle brand={brand} />}>
						<div style={{ display: 'flex' }}>
							<img alt="" height={25} src={visa} />
						</div>
					</Tooltip>
				);
			case 'American Express':
				return (
					<Tooltip title={<TooltipTitle brand={brand} />}>
						<div style={{ display: 'flex' }}>
							<img alt="" height={25} src={american} />
						</div>
					</Tooltip>
				);
			case 'Elo':
				return (
					<Tooltip title={<TooltipTitle brand={brand} />}>
						<div style={{ display: 'flex' }}>
							<img alt="" height={25} src={elo} />
						</div>
					</Tooltip>
				);
			case 'Diners Club':
				return (
					<Tooltip title={<TooltipTitle brand={brand} />}>
						<div style={{ display: 'flex' }}>
							<img alt="" height={25} src={diners} />
						</div>
					</Tooltip>
				);
			case 'Hipercard':
				return (
					<Tooltip title={<TooltipTitle brand={brand} />}>
						<div style={{ display: 'flex' }}>
							<img alt="" height={25} src={hipercard} />
						</div>
					</Tooltip>
				);
			case 'Hiper':
				return (
					<Tooltip title={<TooltipTitle brand={brand} />}>
						<div style={{ display: 'flex' }}>
							<img alt="" height={25} src={hiper} />
						</div>
					</Tooltip>
				);
			case 'Banescard':
				return (
					<Tooltip title={<TooltipTitle brand={brand} />}>
						<div style={{ display: 'flex' }}>
							<img alt="" height={25} src={banescard} />
						</div>
					</Tooltip>
				);
			case 'Aura':
				return (
					<Tooltip title={<TooltipTitle brand={brand} />}>
						<div style={{ display: 'flex' }}>
							<img alt="" height={25} src={aura} />
						</div>
					</Tooltip>
				);
			case 'Cabal':
				return (
					<Tooltip title={<TooltipTitle brand={brand} />}>
						<div style={{ display: 'flex' }}>
							<img alt="" height={25} src={cabal} />
						</div>
					</Tooltip>
				);
			case 'Discover':
				return (
					<Tooltip title={<TooltipTitle brand={brand} />}>
						<div style={{ display: 'flex' }}>
							<img alt="" height={25} src={discover} />
						</div>
					</Tooltip>
				);
			case 'JCB':
				return (
					<Tooltip title={<TooltipTitle brand={brand} />}>
						<div style={{ display: 'flex' }}>
							<img alt="" height={25} src={jcb} />
						</div>
					</Tooltip>
				);
			default:
				return (
					<Tooltip title={<TooltipTitle brand={brand} />}>
						<div>
							<FaCreditCard />
						</div>
					</Tooltip>
				);
		}
	};

	const getTaxas = async () => {
		setLoading(true);
		const res = await axios.get(`${config.get('apiUrl')}planos_taxas/getTaxaByPlanoTaxaId/${planoTaxaId}`, {
			params: { tipo_venda_id: tipoVendaId },
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		if (res.data.success) {
			setPlanoVenda(res.data.planos);
			setTaxas(res.data.taxas);
			setTaxaBoleto(res.data.taxaBoleto);
			setCountBandeiras(Object.keys(res.data.taxas).length);
			setHasOnline(res.data.planos.hasOnline)

			if (!res.data.pixIsPercentage) {
				setIsPercentage(false);
			}
		}
		setMaxParcelas(res.data.maximoParcelas ?? 12)
		setSavingId(null);
		setLoading(false);
	};

	useEffect(() => {
		getTaxas();
	}, []);

	const salvarButton = async bandeiraId => {
		const taxasBandeiras = taxas[bandeiraId].valores;
		setSavingId(bandeiraId);

		const parcelas = [];
		Object.keys(taxasBandeiras).forEach(tipoPagamentoId => {
			Object.keys(taxasBandeiras[tipoPagamentoId].valores).forEach(parcela => {
				const valor = taxasBandeiras[tipoPagamentoId].valores[parcela];
				parcelas.push({
					tipoPagamentoId,
					parcela,
					valor,
				});
			});
		});
		const data = {
			bandeiraId: taxas[bandeiraId].id,
			taxas: parcelas,
			tipo_venda_id: tipoVendaId || 1,
			maxParcelas: maxParcelas
		};

		const res = await axios.post(`${config.get('apiUrl')}planos_taxas/setTaxaByPlanoTaxaId/${planoTaxaId}`, data, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		if (res.data.success) {
			taxas[bandeiraId].changed = false;
			setTaxas(taxas);
			// getTaxas();
		}
		setSavingId(null);
	};

	const salvarBoletoButton = async bandeiraId => {
		setSavingBoleto(true);

		const data = {
			taxaBoleto,
			tipo_venda_id: tipoVendaId || 1,
		};

		const res = await axios.post(
			`${config.get('apiUrl')}planos_taxas/setTaxaBoletoByPlanoTaxaId/${planoTaxaId}`,
			data,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);

		if (res.data.success) {
			setTaxaBoletoChanged(false);
		}

		setSavingBoleto(false);
	};

	const updateTaxa = (bandeiraId, tipoPagamentoId, parcela, valor) => {
		const valorAtual = taxas[bandeiraId].valores[tipoPagamentoId].valores[parcela];
		if (valorAtual !== valor) {
			setTaxas({
				...taxas,
				[bandeiraId]: {
					...taxas[bandeiraId],
					changed: true,
					valores: {
						...taxas[bandeiraId].valores,
						[tipoPagamentoId]: {
							...taxas[bandeiraId].valores[tipoPagamentoId],
							valores: {
								...taxas[bandeiraId].valores[tipoPagamentoId].valores,
								[parcela]: valor,
							},
						},
					},
				},
			});
		}
	};

	// if (tipoVendaId === 2 && !planoVenda.antecipado) {
	if (tipoVendaId === 2 && !hasOnline) {
		return null;
	}

	return (
		<div className={maxParcelas === 12 ? classes.taxasWrapper : classes.taxasWrapper21}>
			{tipoVendaId === 2 && <div className={classes.pageTitle}>Tabela Vendas Online</div>}

			{tipoVendaId === 1 && (
				<div className={classes.boleto}>
					<div className={classes.tipoPagamentoBoleto}>Boleto</div>
					<div>
						{loading ? (
							<ZLoader height={30} size={14} />
						) : (
							<>
								R$
								<TaxaField
									money={1}
									readOnly={readOnly}
									className={classes.taxaFieldBoleto}
									value={taxaBoleto}
									// onChange={() => setTaxaBoletoChanged(true)}
									onChange={e => {
										setTaxaBoletoChanged(true);
										setTaxaBoleto(Mask.OnlyNumber(e.target.value) / 100);
									}}
									onFocus={e => {
										const length = e.target.value.length * 2;
										e.target.setSelectionRange(0, length);
									}}
								/>
							</>
						)}
						{taxaBoletoChanged && (
							<Button
								className={classes.saveButton}
								variant="contained"
								onClick={() => salvarBoletoButton()}
							>
								{savingBoleto ? <ZLoader height={30} size={14} /> : 'Salvar'}
							</Button>
						)}
					</div>

					<TaxaPix planoTaxaId={planoTaxaId} tipoVendaId={tipoVendaId} isPercentage={isPercentage} />
				</div>
			)}

			<div style={{ marginTop: 10 }}>
				<div className={classes.tiposPagamentos}>
					{new Array(maxParcelas).fill('').map((v, i) => {
						return (
							<div key={i} className={classes.tipoPagamento}>
								Crédito {++i}x
							</div>
						);
					})}
					<div className={classes.tipoPagamento}>Débito</div>
				</div>

				{loading ? (
					<ZLoader height={300} />
				) : (
					<div className={classes.bandeirasWrapper}>
						<div style={{ width: (countBandeiras + 1) * 110 }}>
							{Object.keys(taxas).map(bandeiraId => (
								<div
									key={`Bandeira-${bandeiraId}`}
									className={classes.bandeira}
									style={{ backgroundColor: taxas[bandeiraId].changed ? '#fffdf2c0' : '#ffffffc0' }}
								>
									<div
										className={clsx(classes.title, {
											[classes.titleLong]:
												Isset(() => taxas[bandeiraId].titulo.length) &&
												taxas[bandeiraId].titulo.length > 10,
										})}
									>
										{/* {taxas[bandeiraId].titulo} */}
										{getPaymentIcon(taxas[bandeiraId].titulo)}
									</div>
									<div className={classes.fields}>
										{Object.keys(taxas[bandeiraId].valores).map(tipoPagamentoId => (
											<div
												key={`${bandeiraId}${tipoPagamentoId}`}
												className={classes.tipoPagamentoFields}
											>
												{Object.keys(taxas[bandeiraId].valores[tipoPagamentoId].valores).map(
													parcela => (
														<TaxaField
															readOnly={readOnly}
															className={classes.taxaField}
															key={`${bandeiraId}${tipoPagamentoId}${parcela}`}
															value={
																taxas[bandeiraId].valores[tipoPagamentoId].valores[
																parcela
																]
															}
															onBlur={e =>
																updateTaxa(
																	bandeiraId,
																	tipoPagamentoId,
																	parcela,
																	Mask.OnlyNumber(e.target.value) / 100
																)
															}
															onFocus={e => {
																const length = e.target.value.length * 2;
																e.target.setSelectionRange(0, length);
															}}
														/>
													)
												)}
											</div>
										))}
									</div>
									{taxas[bandeiraId].changed && (
										<div className={classes.saveButton} onClick={() => salvarButton(bandeiraId)}>
											{savingId === bandeiraId ? <ZLoader height={30} size={14} /> : 'Salvar'}
										</div>
									)}
								</div>
							))}
						</div>
					</div>
				)}
			</div>
		</div>
	);
});
