import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
// import axios from 'axios';
// import config from 'react-global-configuration';
import ZText from './ZText';
import ZSelect from '../system/ZSelect';
import { Mask } from '../../utils';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';

function ZCard(props) {
	const [focus, setFocus] = useState(null);
	const [data, setData] = useState({
		titular: props.name,
		numero: props.number,
		codigoSeguranca: props.cvc,
		vencimento: props.expiry,
		parcela: props.installment,
	});

	const { enableInstallmentOnCardNumber } = props;

	useEffect(() => {
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleChangeCard = name => value => {
		setData({
			...data,
			[name]: value,
		});
	};

	const onChange = (type, isValid) => {
		if (props.onChange) {
			props.onChange(type, isValid);
		}
	};

	const installmentOnChange = e => {
		setData({ ...data, parcela: e.target.value });

		if (props.installmentOnChange) {
			props.installmentOnChange(e);
		}
	};

	const installmentOnBlur = e => {
		if (props.installmentOnBlur) {
			props.installmentOnBlur(e);
		}
	};

	return (
		<Grid container direction={props.direction || 'row'} justifyContent="center" alignItems="center">
			<Grid
				item
				xs={props.cardGrid ? props.cardGrid.xs : 12}
				sm={props.cardGrid ? props.cardGrid.sm : 12}
				md={props.cardGrid ? props.cardGrid.md : 5}
				lg={props.cardGrid ? props.cardGrid.lg : 5}
				style={{
					marginBottom: props.cardGrid && props.cardGrid.marginBottom ? props.cardGrid.marginBottom : 0,
				}}
			>
				<Cards
					number={data.numero}
					name={data.titular}
					expiry={data.vencimento}
					cvc={data.codigoSeguranca}
					focused={focus}
					locale={{ valid: 'Validade' }}
					placeholders={{ name: 'SEU NOME AQUI' }}
					callback={onChange}
				/>
			</Grid>
			<Grid
				item
				style={{
					marginTop:
						props.containerInputsGrid && props.containerInputsGrid.marginTop
							? props.containerInputsGrid.marginTop
							: 0,
				}}
				xs={props.containerInputsGrid ? props.containerInputsGrid.xs : 12}
				sm={props.containerInputsGrid ? props.containerInputsGrid.sm : 12}
				md={props.containerInputsGrid ? props.containerInputsGrid.md : 7}
				lg={props.containerInputsGrid ? props.containerInputsGrid.lg : 7}
			>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={6} md={6} lg={6}>
						<ZText
							required={true}
							label="Número"
							onError={props.onError}
							onFocus={() => setFocus('number')}
							onChange={handleChangeCard('numero')}
							onBlur={props.numberOnBlur.bind(this)}
							value={Mask.Cartao(data.numero)}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={6}>
						<ZText
							required={true}
							label="Titular"
							onError={props.onError}
							onFocus={() => setFocus('name')}
							onChange={handleChangeCard('titular')}
							value={data.titular}
							onBlur={props.nameOnBlur.bind(this)}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={6}>
						<ZText
							required={true}
							label="Vencimento"
							onError={props.onError}
							onFocus={() => setFocus('expiry')}
							onChange={handleChangeCard('vencimento')}
							mask="99/99"
							value={data.vencimento}
							onBlur={props.expiryOnBlur.bind(this)}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={6}>
						<ZText
							required={true}
							label="Código de segurança"
							onError={props.onError}
							onFocus={() => setFocus('cvc')}
							onChange={handleChangeCard('codigoSeguranca')}
							value={data.codigoSeguranca}
							mask={'9999'}
							onBlur={props.cvcOnBlur.bind(this)}
						/>
					</Grid>
					{props.maxInstallment && props.maxInstallment.length > 1 && (
						<Grid item xs={12}>
							<ZSelect
								options={props.maxInstallment}
								label="Parcelamento"
								required={true}
								value={data.parcela}
								onChange={installmentOnChange}
								onBlur={installmentOnBlur}
								disabled={enableInstallmentOnCardNumber && !data.numero}
							/>
						</Grid>
					)}
				</Grid>
			</Grid>
		</Grid>
	);
}

// const mapStateToProps = store => ({
//   token: store.auth.token,
//   usuario: store.auth.usuario,
// });

// const mapDispatchToProps = dispatch =>
//   bindActionCreators({ addMessage }, dispatch);

export default ZCard;
