/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import axios from 'axios';

import config from 'react-global-configuration';
// import { Mask, Isset } from '../utils';

import TextField from '@material-ui/core/TextField';
import ZLoader from '../system/ZLoader';
import { Button } from '@material-ui/core';
import { Mask } from '../../utils';

const useStyles = makeStyles(theme => ({
	taxasWrapper: {
		minHeight: 580,
	},
	pageTitle: {
		fontWeight: 'bold',
		fontSize: 18,
		marginBottom: 10,
		paddingLeft: 20,
	},
	planosTaxas: {
		position: 'relative',
	},
	tiposPagamentos: {
		position: 'absolute',
		paddingTop: 50,
		paddingBottom: 52,
		border: 'solid 1px #abc',
		borderRadius: 10,
		padding: 10,
		width: 'calc(100% - 30px)',
	},
	tipoPagamento: {
		fontWeight: 'bold',
		fontSize: 16,
		padding: 4,
		'&:not(:last-child)': {
			borderBottom: 'solid 1px #bcd',
		},
	},
	tipoPagamentoPix: {
		fontWeight: 'bold',
		fontSize: 16,
		padding: 4,
		width: 90,
		marginLeft: 50,
	},
	bandeirasWrapper: {
		position: 'absolute',
		marginLeft: 120,
		width: 'calc(100% - 160px)',
		overflowX: 'auto',
	},
	bandeira: {
		position: 'relative',
		padding: 10,
		marginTop: 10,
		border: 'solid 1px #abc',
		borderRadius: 10,
		backgroundColor: '#ffffffc0',
		height: 490,
		width: 105,
		marginRight: 10,
		float: 'left',
	},
	novaBandeira: {
		position: 'relative',
		padding: 10,
		marginTop: 10,
		border: 'solid 1px #abc',
		borderRadius: 10,
		backgroundColor: '#ffffff',
		width: 160,
		marginRight: 10,
		float: 'left',
	},
	title: {
		textAlign: 'center',
		fontWeight: 'bold',
		fontSize: 16,
		height: 22,
	},
	titleLong: {
		fontSize: 11,
	},
	deleteButton: {
		top: 2,
		right: 2,
		cursor: 'pointer',
		position: 'absolute',
		width: 20,
		height: 20,
		color: '#e53935',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		opacity: 0.5,
		'&:hover': {
			opacity: 1,
		},
	},
	fields: {
		marginTop: 8,
		marginBottom: 15,
		borderTop: 'solid 1px #bcd',
	},
	tipoPagamentoFields: {
		borderBottom: 'solid 1px #bcd',
	},
	taxaField: {
		padding: 7.45,
		// '&::after': {
		// 	content: '"%"',
		// 	position: 'absolute',
		// 	right: 4,
		// 	fontSize: 12,
		// },
		'& .MuiInputBase-input': {
			textAlign: 'right',
			height: '1em',
			padding: 0,
			paddingRight: 15,
		},
		'& .MuiInput-underline:before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
			bottom: -7,
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: 'none',
		},
	},
	taxaFieldBoleto: {
		width: 100,
		padding: 7.45,
		// '&::after': {
		// 	content: '"R$"',
		// 	position: 'absolute',
		// 	left: 4,
		// 	fontSize: 12,
		// },
		// '&::after': {
		// 	content: '"%"',
		// 	position: 'absolute',
		// 	right: 4,
		// 	fontSize: 12,
		// },
		'& .MuiInputBase-input': {
			textAlign: 'right',
			height: '1em',
			padding: 0,
			paddingRight: 15,
		},
		'& .MuiInput-underline:before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
			bottom: -7,
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: 'none',
		},
	},
	saveButton: {
		textAlign: 'center',
		textTransform: 'uppercase',
		fontWeight: 700,
		fontSize: 12,
		marginRight: 30,
		// cursor: 'pointer',
		color: theme.palette.primary.main,
		// marginTop: -5,
	},
	adicionarButton: {
		textAlign: 'center',
		textTransform: 'uppercase',
		fontWeight: 700,
		fontSize: 12,
		cursor: 'pointer',
		color: theme.palette.primary.main,
		marginTop: 15,
	},
	taxaFieldReadOnly: {
		height: 30.8,
		textAlign: 'center',
		fontWeight: 'bold',
		fontSize: 15,
		padding: 4,
	},
	boleto: {
		marginTop: 10,
		display: 'flex',
		border: 'solid 1px #abc',
		borderRadius: 10,
		padding: 10,
	},
}));

const TaxaField = memo(props => {
	const { readOnly, money } = props;
	const [value, setValue] = useState(Mask.Porcentagem(props.value));
	const [isTyping, setIsTyping] = useState(null);
	const classes = useStyles();

	const onChange = e => {
		const value = e.target.value;
		let valor = Mask.OnlyNumber(e.target.value) / 100;
		if (!money && valor > 100) {
			valor = 100;
		}
		setValue(Mask.Porcentagem(valor));

		if (money) {
			if (isTyping) {
				clearTimeout(isTyping);
			}

			const timeout = setTimeout(() => {
				if (props.onChange) {
					props.onChange({ target: { value } });
				}
			}, 500);

			setIsTyping(timeout);
		} else {
			if (props.onChange) {
				props.onChange(e);
			}
		}
	};

	if (readOnly) {
		if (money) {
			return <div className={classes.taxaFieldReadOnly}>{`R$ ${value}`}</div>;
		}
		return <div className={classes.taxaFieldReadOnly}>{`${value} %`}</div>;
	}

	return <TextField {...props} value={value} onChange={onChange} />;
});

export default props => {
	const { planoTaxaId, tipoVendaId } = props;

	const classes = useStyles();

	const token = useSelector(store => store.auth.token);

	// const [taxas, setTaxas] = useState({});
	// const [countBandeiras, setCountBandeiras] = useState(1);
	const [loading, setLoading] = useState(true);
	// const [savingId, setSavingId] = useState(null);

	const [taxaPix, setTaxaPix] = useState(0);
	const [TaxaPixChanged, setTaxaPixChanged] = useState(false);
	const [savingBoleto, setSavingBoleto] = useState(false);
	const [isAntecipado, setIsAntecipado] = useState(false);

	const getTaxas = async () => {
		setLoading(true);
		const res = await axios.get(`${config.get('apiUrl')}planos_taxas/getTaxaByPlanoTaxaId/${planoTaxaId}`, {
			params: {
				tipo_venda_id: tipoVendaId || 1,
			},
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		if (res.data.success) {
			// setTaxas(res.data.taxas);
			setTaxaPix(res.data.taxaPix);
			// setCountBandeiras(Object.keys(res.data.taxas).length);
			setIsAntecipado(res.data.planos.antecipado);
		}
		// setSavingId(null);
		setLoading(false);
	};

	useEffect(() => {
		getTaxas();
	}, [planoTaxaId]);

	const salvarBoletoButton = async bandeiraId => {
		setSavingBoleto(true);

		const data = {
			taxaPix,
			tipo_venda_id: tipoVendaId || 1,
			tipo_taxa: props.isPercentage ? 'percent' : 'fixed',
		};

		const res = await axios.post(
			`${config.get('apiUrl')}planos_taxas/setTaxaPixByPlanoTaxaId/${planoTaxaId}`,
			data,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);

		if (res.data.success) {
			setTaxaPixChanged(false);
		}
		setSavingBoleto(false);
	};

	if (tipoVendaId === 2 && !isAntecipado) {
		return false;
	}

	return (
		<>
			<div className={classes.tipoPagamentoPix}>Pix</div>
			<div>
				{loading ? (
					<ZLoader height={30} size={14} />
				) : (
					<>
						{props.isPercentage && <>%</>}

						{!props.isPercentage && <>R$</>}
						<TaxaField
							money={0}
							readOnly={false}
							className={classes.taxaFieldBoleto}
							value={taxaPix}
							// onChange={() => setTaxaPixChanged(true)}
							onChange={e => {
								setTaxaPixChanged(true);
								setTaxaPix(Mask.OnlyNumber(e.target.value) / 100);
							}}
							onFocus={e => {
								const length = e.target.value.length * 2;
								e.target.setSelectionRange(0, length);
							}}
						/>
					</>
				)}
			</div>
			{TaxaPixChanged && (
				<Button className={classes.saveButton} variant="contained" onClick={() => salvarBoletoButton()}>
					{savingBoleto ? <ZLoader height={30} size={14} /> : 'Salvar'}
				</Button>
			)}
		</>
	);
};
