import React from 'react';
import { Grid, Paper, makeStyles, Checkbox, Button } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import axios from 'axios';

import config from 'react-global-configuration';
import { useSelector } from 'react-redux';
import { Mask } from '../../../utils';
import format from 'date-fns/format';
import ZPaginationDiv from '../../system/ZPaginationDiv';
import ZFiltros from '../../system/ZFiltros';
import ZLoader from '../../system/ZLoader';
import moment from 'moment';
import ZMenuOptions from '../../system/ZMenuOptions';

import { Isset } from '../../../utils';
import { useHistory, useParams } from 'react-router-dom';
const useStyles = makeStyles(theme => ({
	rowTitle: {
		margin: 0,
		textAlign: 'left',
		fontWeight: 700,
		[theme.breakpoints.down('xs')]: {
			textAlign: 'left',
			marginTop: 24,
		},
	},
	rowText: {
		display: 'inline-block',
		fontWeight: 400,
		marginTop: 8,
	},
	cardContainer: {
		margin: '16px 0',
	},
}));

function CardTable({ data, cliente, handleCheck }) {
	const classes = useStyles();

	// const history = useHistory();
	const documento =
		cliente.clientes_documentos[0].documento.length <= 11
			? Mask.Cpf(cliente.clientes_documentos[0].documento)
			: Mask.Cnpj(cliente.clientes_documentos[0].documento);

	const dataMenuOptions = [
		{
			title: 'Visualizar',
			onClick: () => {
				// history.push(`/vendas/detalhes/${data.id}/publico`),
				window.location = `/vendas/detalhes/${data.id}/publico`;
			},
		},
	];

	if (data.status_pedido_id === 1) {
		const url =
			data.marketplace_id === 25
				? `https://portal.bancomonetiza.com.br/app/vendas/${data.id}/boleto`
				: `${data.pagamentos[0].pagamento_boleto.url}`;

		dataMenuOptions.push({
			title: 'Imprimir',
			onClick: () => window.open(url),
		});

		dataMenuOptions.push({
			title: 'Pagar',
			onClick: () => {
				// history.push(`/vendas/pagar/${data.id}`),
				window.location = `/vendas/pagar/${data.id}`;
			},
			disabled: true,
		});
	}

	return (
		<Grid item xs={12} sm={12} className={classes.cardContainer}>
			<Paper>
				<Grid container style={{ padding: 20 }}>
					<Grid
						item
						xs={1}
						sm={1}
						container
						justifyContent="flex-start"
						alignItems="center"
						direction="column"
					>
						<Checkbox
							name="checkbox"
							color="default"
							inputProps={{ 'aria-label': 'checkbox with default color' }}
							onChange={event => handleCheck(event, data.id)}
						/>
					</Grid>
					<Grid item xs={6} sm={2} container>
						<p className={classes.rowTitle}>
							Nome <br />
							<span className={classes.rowText}>{cliente.nome}</span>
						</p>
					</Grid>
					<Grid item xs={4} sm={2} container justifyContent="center" alignItems="center">
						<p className={classes.rowTitle}>
							CPF/CNPJ <br /> <span className={classes.rowText}>{documento}</span>
						</p>
					</Grid>
					<Grid item xs={4} sm={2} container justifyContent="center" alignItems="center">
						<p className={classes.rowTitle}>
							Valor <br />
							<span className={classes.rowText}>{Mask.Real(data.valor_bruto)}</span>
						</p>
					</Grid>
					<Grid item xs={4} sm={2} container justifyContent="center" alignItems="center">
						<p className={classes.rowTitle}>
							Data de Vencimento <br />
							<span className={classes.rowText}>
								{Isset(() => data.pagamentos[0].pagamento_boleto.data_vencimento)
									? format(data.pagamentos[0].pagamento_boleto.data_vencimento, 'DD/MM/YYYY')
									: 'N/A'}
							</span>
						</p>
					</Grid>
					<Grid item xs={6} sm={2} container justifyContent="center" alignItems="center">
						<p className={classes.rowTitle}>
							Status <br />
							<span className={classes.rowText}>{data.status_pedido.titulo}</span>
						</p>
					</Grid>
					<Grid item xs={4} sm={1} container justifyContent="center" alignItems="center" direction="column">
						<p className={classes.rowTitle} style={{ marginBottom: 4 }}>
							Opções
						</p>
						<ZMenuOptions data={dataMenuOptions} />
					</Grid>
				</Grid>
			</Paper>
		</Grid>
	);
}

export default props => {
	// const classes = useStyles();
	const [boletos, setBoletos] = React.useState([]);
	// const [cliente, setCliente] = React.useState({});
	const [loading, setLoading] = React.useState(false);
	const [boletosSelecionados, setBoletosSelecionados] = React.useState([]);
	const systemConfig = useSelector(store => store.system.config);
	const { estabelecimentoId } = systemConfig;

	const params = useParams();
	const documento = params.documento;

	const history = useHistory();

	//paginacao
	const [page, setPage] = React.useState(1);
	const [rowsPerPage, setRowsPerPage] = React.useState(15);
	const [totalRows, setTotalRows] = React.useState(0);
	const [filtros, setFiltros] = React.useState({
		created: {
			// start: moment()
			// 	.subtract(2, 'months')
			// 	.format('YYYY-MM-DD'),
			// end: moment().format('YYYY-MM-DD'),
			start: '',
			end: '',
			label: 'Selecione uma data',
		},
	});

	const getOptions = () => {
		const result = {
			statusVenda: [
				{ id: '1', name: 'Pendente' },
				{ id: '2', name: 'Aprovado' },
				// { id: '3', name: 'Falhado' },
				// { id: '4', name: 'Cancelado' },
				// { id: '5', name: 'Parcialmente Pago' },
				// { id: '6', name: 'Estornado' },
				// { id: '7', name: 'Em Processamento' },
				// { id: '8', name: 'Pré Autorizado' },
				// { id: '9', name: 'Disputa' },
				// { id: '10', name: 'Reverso' },
				// { id: '12', name: 'Recusado Anti Fraude' },
				// { id: '13', name: 'Charge Back' },
				{ id: '14', name: 'Pago com cartão de crédito' },
			],
		};

		return result;
	};

	// const getCliente = () => {
	// 	setLoading(true);
	// 	axios
	// 		.get(`${config.get('apiUrl')}clientes/por_documento/${documento}/public/${estabelecimentoId}`)
	// 		.then(response => response.data)
	// 		.then(data => {
	// 			if (data.success) {
	// 				setLoading(false);
	// 				setCliente(data.cliente);
	// 			}
	// 		})
	// 		.catch(err => {
	// 			console.error(err);
	// 			setLoading(false);
	// 		});
	// };

	const getBoletos = () => {
		setLoading(true);
		axios
			.get(`${config.get('apiUrl')}clientes/consultar-boletos`, {
				params: {
					startDate: moment(filtros.created.start).format('YYYY-MM-DD'),
					endDate: moment(filtros.created.end).format('YYYY-MM-DD'),
					page,
					statusPedido: filtros.statusVenda,
					limit: rowsPerPage,
					cpf: documento,
					estabelecimentoId,
				},
			})
			.then(response => response.data)
			.then(data => {
				if (data.success) {
					setLoading(false);
					setBoletos(data.boletos);
					setTotalRows(data.totalRows);
					// setTotalRows(rowsPerPage * data.totalPages);
				}
			})
			.catch(err => console.error(err));
	};

	const imprimirBoletos = () => {
		history.push({
			pathname: '/imprimir-boletos',
			state: { boletos: boletosSelecionados },
		});
	};

	// React.useEffect(() => {
	// 	getCliente();
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	React.useEffect(() => {
		getBoletos();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		getBoletos();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtros, page, rowsPerPage]);

	const onChangePage = newPage => {
		setPage(newPage);
	};

	const onChangeRowsPerPage = rowsPerPage => {
		setRowsPerPage(rowsPerPage);
		setPage(1);
	};

	const handleFiltrosChange = data => {
		const newFiltro = { ...filtros };
		if (Isset(() => data.created.start)) {
			newFiltro.created = { ...filtros.created, start: data.created.start, end: data.created.end };
		}

		if (Isset(() => data.statusVenda)) {
			newFiltro.statusVenda = data.statusVenda;
		}

		setFiltros(newFiltro);
	};

	const handleCheck = (event, pedidoId) => {
		if (!event.target.checked) {
			let boletosImpressao = boletosSelecionados.filter(p => {
				if (p === pedidoId) {
					return false;
				}
				return true;
			});
			setBoletosSelecionados(boletosImpressao);
		} else {
			setBoletosSelecionados([...boletosSelecionados, pedidoId]);
		}
	};

	return (
		// <div
		// 	style={{
		// 		background: systemConfig.backgroundColor,
		// 		// position: 'relative',
		// 		// display: 'block',
		// 		// width: '100%',
		// 		minHeight: '100vh',
		// 	}}
		// >
		<Grid container style={{ padding: 5 }}>
			<Grid item xs={8} sm={8} container>
				<ZFiltros
					onChange={handleFiltrosChange}
					created={{ ...filtros.created }}
					// modal={true}
					selectField={[
						{
							key: 'statusVenda',
							defaultValue: filtros.statusVenda,
							// title: 'Status da Venda',
							placeholder: 'Status da venda',
							multiple: true,
							options: getOptions().statusVenda,
							modal: false,
							md: 3,
							divider: false,
						},
					]}
				/>
			</Grid>
			<Grid item xs={4} sm={4} container justifyContent="flex-end">
				{boletosSelecionados.length > 0 && (
					<Button
						style={{ background: systemConfig.backgroundColor }}
						variant="contained"
						color="secondary"
						onClick={imprimirBoletos}
						size="small"
						startIcon={<PrintIcon />}
					>
						Imprimir
					</Button>
				)}
			</Grid>

			{loading ? (
				<ZLoader height={200} message="Carregando Boletos..." />
			) : (
				<>
					{boletos.map(data => (
						<CardTable key={data.id} data={data} cliente={data.cliente} handleCheck={handleCheck} />
					))}
				</>
			)}
			<Paper
				style={{
					marginBottom: 10,
					padding: '5px 10px',
				}}
			>
				<ZPaginationDiv
					count={totalRows}
					rowsPerPage={rowsPerPage}
					page={page - 1}
					onChangePage={onChangePage}
					onChangeRowsPerPage={onChangeRowsPerPage}
					rowsPerPageOptions={[15, 30]}
				/>
			</Paper>
		</Grid>
		// </div>
	);
};
