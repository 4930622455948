import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import Cookies from 'js-cookie';

const ZModalCookie = ({ userEmail }) => {
	const [aceitarCookie, setAceitarCookie] = useState(false);

	useEffect(() => {
		const verificarCookie = Cookies.get(`usuario_${userEmail}`);

		if (!verificarCookie) {
			setAceitarCookie(true);
		}
	}, [userEmail]);

	const handleAceitarCookie = () => {
		// Salvar o consentimento no cookie
		Cookies.set(`usuario_${userEmail}`, true, { expires: 365, secure: true, sameSite: 'Strict' });

		setAceitarCookie(false);
	};

	return (
		<Dialog
			open={aceitarCookie}
			aria-labelledby="cookie-consent-title"
			aria-describedby="cookie-consent-description"
		>
			<DialogTitle id="cookie-consent-title">Politica de Privacidade </DialogTitle>
			<DialogContent>
				<DialogContentText id="cookie-consent-description">
					Ao continuar navegando, você concorda com nossa{' '}
					<a
						target="_blank"
						rel="noopener noreferrer"
						href={`https://docs.google.com/viewerng/viewer?url=https://zgs-upload-image.s3.sa-east-1.amazonaws.com/1718113397250_Politica_de_Privacidade_Cookies+(1).pdf`}
					>
						Política de Privacidade.
					</a>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleAceitarCookie} color="primary" autoFocus>
					Aceitar
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ZModalCookie;
