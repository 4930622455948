import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { SelectEstabelecimento } from './SelectEstabelecimento';
import { useSelector } from 'react-redux';
import axios from 'axios';
import config from 'react-global-configuration';
import ZLoader from '../system/ZLoader';

const useStyles = makeStyles(theme => ({
	box: {
		width: '100%',
		minWidth: '404px',
	},
	titulo: {
		marginTop: '0px',
		color: '#000',
		fontSize: '20px',
		fontWeight: '700',
	},
	texto: {
		color: '#000',
		fontSize: '16px',
		fontWeight: '400',
	},
	input: {
		height: '40px',
		fontSize: '16px',
		border: 'none',
		borderBottom: '1px solid slategrey',
		width: '100%',
	},
}));

export default props => {
	const classes = useStyles();
	const token = useSelector(store => store.auth.token);
	const estabelecimentoId = props.estabelecimentoId;
	const [marketplaceAtual, setMarketplaceAtual] = useState([]);
	const { novoMarketplace, setNovoMarketplace } = props;
	const [loadingMarketplaceAtual, setLoadingMarketplaceAtual] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${config.get('apiUrl')}estabelecimentos/${estabelecimentoId}`, {
					headers: { Authorization: `Bearer ${token}` },
				});
				setMarketplaceAtual(
					response.data.estabelecimento.EstabelecimentoPai.nome_fantasia
						? response.data.estabelecimento.EstabelecimentoPai.nome_fantasia
						: response.data.estabelecimento.EstabelecimentoPai.razao_social
				);
			} catch (error) {
				console.error('Erro na requisição:', error);
			} finally {
				setLoadingMarketplaceAtual(false);
			}
		};

		fetchData();
	}, [estabelecimentoId, token]);

	const handleNovoMarketplace = (id, name) => {
		setNovoMarketplace(id, name);
	};

	return (
		<div className={classes.box}>
			<div className={classes.box}>
				<p className={classes.texto}>Marketplace atual:</p>
				{loadingMarketplaceAtual ? (
					<div>{<ZLoader height={20} size={20} />}</div>
				) : (
					<input className={classes.input} value={marketplaceAtual} disabled />
				)}
			</div>
			<div>
				<p className={classes.texto}>Selecione o novo marketplace:</p>
				<SelectEstabelecimento
					value={novoMarketplace}
					setEstabelecimento={estabelecimento => {
						const hasEstabelecimento = Boolean(estabelecimento);
						const id = hasEstabelecimento ? estabelecimento.id : '';
						const name = hasEstabelecimento ? estabelecimento.nomeFantasia : '';
						handleNovoMarketplace(id, name);
					}}
				/>
			</div>
		</div>
	);
};
