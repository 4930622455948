/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useTheme } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
// import useReactRouter from 'use-react-router';
import { updateSaldo } from '../../store/system';
import { addMessage } from '../../store/alert';
import ZLoader from '../system/ZLoader';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Mask, Isset, Formatter } from '../../utils';
import { Paper } from '@material-ui/core';
import axios from 'axios';
import config from 'react-global-configuration';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
	MenuItem,
	Select,
	FormControl,
	InputLabel,
	Backdrop,
	CircularProgress,
	RadioGroup,
	FormControlLabel,
	Radio,
} from '@material-ui/core/';

const useStyles = makeStyles(theme => ({
	field: {
		padding: 5,
		textAlign: 'center',
	},
	label: {
		fontSize: 11,
		textTransform: 'uppercase',
		fontWeight: 700,
	},
	content: {
		[theme.breakpoints.up('sm')]: {
			width: 400,
		},
	},
	fullWidth: {
		width: '100%',
	},
	saldoBox: {
		backgroundColor: '#616161',
		color: 'white',
		margin: -10,
		marginBottom: 10,
		padding: '5px 15px',
	},
	transferencia: {},
	formaTransferencia: {
		padding: 16,
		fontSize: 14,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontWeight: 700,
		color: theme.palette.primary.main,
		border: `solid 1px ${theme.palette.primary.main}`,
		borderRadius: 6,
		cursor: 'pointer',
		'&.selected': {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.getContrastText(theme.palette.primary.main),
		},
	},
	contaBancaria: {
		margin: 8,
		marginTop: 10,
		padding: 4,
		fontSize: 14,
		fontWeight: 700,
		color: theme.palette.primary.main,
		border: `solid 1px ${theme.palette.primary.main}`,
		borderRadius: 6,
		cursor: 'pointer',
		'&.selected': {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.getContrastText(theme.palette.primary.main),
		},
	},
	error: {
		fontSize: 13,
		textTransform: 'uppercase',
		fontWeight: 700,
		color: '#f44336',
	},
	errorLink: {
		color: '#f44310',
		cursor: 'pointer',
	},
}));

var minDate = new Date();
minDate.setDate(minDate.getDate() + 1);

const initialState = {
	cpfcnpj: '',
	formaTransferencia: 1,
	valor: 0,
	agencia: '',
	conta: '',
	digito: '',
	bancoId: 1,
	descricao: '',
	senha: '',
	favorecido: '',
	agendadoPara: null,
	tipoConta: '1',
};

export default props => {
	const classes = useStyles();
	const dispatch = useDispatch();
	// const { history } = useReactRouter();

	const token = useSelector(state => state.auth.token);
	const estabelecimento = useSelector(state => state.auth.estabelecimento);
	const estabelecimentoAdmin = useSelector(state => state.admin.estabelecimento);
	const [open, setOpen] = useState(props.open || false);
	const [sending, setSending] = useState(false);
	// const [contasBancarias, setContasBancarias] = useState([]);
	const [data, setData] = useState(initialState);
	const [switcher] = useState(false);
	// const [loadingContaBancaria, setLoadingContaBancaria] = useState(false);

	const estabelecimentoId = Isset(() => estabelecimentoAdmin.id) ? estabelecimentoAdmin.id : estabelecimento.id;

	const [errors, setErrors] = useState({});
	const [saldo, setSaldo] = useState({
		atual: 0,
		futuro: 0,
	});
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

	const validate = () => {
		let e = {};

		if (!data.valor) {
			e.valor = true;
			dispatch(addMessage({ type: 'error', message: 'Campo valor a ser transferido obrigatório' }));
		} else if (!switcher && data.valor > saldo.atual) {
			e.valorMessage = 'Valor maior que o saldo atual.';
		}

		if (!data.cpfcnpj) {
			e.cpfcnpj = true;
			dispatch(addMessage({ type: 'error', message: 'Campo CPF/CNPJ obrigatório' }));
		}

		if (!data.agencia) {
			e.agencia = true;
			dispatch(addMessage({ type: 'error', message: 'Campo agência obrigatório' }));
		}

		if (!data.conta) {
			e.conta = true;
			dispatch(addMessage({ type: 'error', message: 'Campo conta obrigatório' }));
		}
		if (!data.digito) {
			e.digito = true;
			dispatch(addMessage({ type: 'error', message: 'Campo dígito obrigatório' }));
		}
		if (!data.cpfcnpj) {
			e.cpfcnpj = true;
			dispatch(addMessage({ type: 'error', message: 'Campo senha obrigatório' }));
		}

		// if (data.formaTransferencia === 1) {
		// 	if (!data.estabelecimento.id) {
		// 		e.cpfcnpj = true;
		// 	}
		// } else if (data.formaTransferencia === 2) {
		// 	if (!data.contaBancariaId) {
		// 		e.contaBancaria = true;
		// 	}
		// }

		if (!data.senha) {
			e.senha = true;
			dispatch(addMessage({ type: 'error', message: 'Campo senha obrigatório' }));
		}

		setErrors(e);

		return Object.keys(e).length > 0;
	};

	const handleClose = () => {
		// setOpen(false);
		if (props.onClose) {
			props.onClose();
		}
	};

	useEffect(() => {
		setOpen(props.open);
	}, [props.open]);

	const handleSubmit = async () => {
		if (!validate()) {
			setSending(true);
			setBackdrop(true);

			const postData = {
				tipoTransferencia: data.formaTransferencia,
				valor: data.valor,
				cpfcnpj: data.cpfcnpj,
				agencia: data.agencia,
				conta: data.conta,
				digito: data.digito,
				favorecido: data.favorecido,
				senha: data.senha,
				descricao: data.descricao,
				bancoId: data.bancoId,
				tipoConta: data.tipoConta,
			};

			const res = await axios.post(`${config.get('apiUrl')}servicos-bancarios/transferencia-bancaria`, postData, {
				headers: { Authorization: `Bearer ${token}` },
			});

			if (res.data.success) {
				dispatch(addMessage({ type: 'success', message: 'Operação realizada com sucesso!' }));
				dispatch(updateSaldo(true));
				if (props.onSuccess) {
					props.onSuccess();
					setData(initialState);
				}
				handleClose();
			} else {
				dispatch(addMessage({ type: 'error', message: res.data.message }));
				if (props.onError) {
					props.onError();
				}
			}

			setBackdrop(false);
			setSending(false);
		}
	};

	useEffect(() => {
		const getSaldo = async () => {
			const res = await axios.get(`${config.get('apiUrl')}estabelecimentos/${estabelecimentoId}/saldo`, {
				headers: { Authorization: `Bearer ${token}` },
			});

			if (res.data.success) {
				setSaldo({
					...saldo,
					...res.data.saldo,
				});
			}
		};
		getSaldo();
	}, []);

	useEffect(() => {
		setData({
			...data,
			estabelecimento: {
				id: null,
				logo: null,
				nome: '',
				email: '',
				documento: '',
			},
		});
		if ([11, 14].includes(data.cpfcnpj.length)) {
			const getEstabelecimento = async () => {
				const res = await axios.get(`${config.get('apiUrl')}estabelecimentos/por_documento/${data.cpfcnpj}`, {
					headers: { Authorization: `Bearer ${token}` },
				});

				if (res.data.success) {
					const est = res.data.estabelecimento;

					if (est.id === estabelecimentoId) {
						setErrors({
							...errors,
							estabelecimentoMessage: 'Você não pode transferir dinheiro para você mesmo.',
						});
						return;
					}

					setData({
						...data,
						estabelecimento: {
							id: est.id,
							logo: Isset(() => est.arquivo.url) ? est.arquivo.url : null,
							nome: est.nome_fantasia,
							email: Isset(() => est.estabelecimentos_contatos[0].contato)
								? est.estabelecimentos_contatos[0].contato
								: '',
							documento: Isset(() => est.estabelecimentos_documentos[0].documento)
								? est.estabelecimentos_documentos[0].documento
								: '',
						},
					});
					setErrors({
						...errors,
						estabelecimentoMessage: null,
					});
				} else {
					setErrors({
						...errors,
						estabelecimentoMessage: 'Estabelecimento não encontrado.',
					});
				}
			};

			getEstabelecimento();
		}
	}, [data.cpfcnpj]);

	const [bancos, setBancos] = useState([]);
	useEffect(() => {
		const getBancos = async () => {
			const result = await axios.get(`${config.get('apiUrl')}bancos`);

			if (result.data.success) {
				setBancos(result.data.bancos);
			}
		};

		getBancos();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [backdrop, setBackdrop] = useState(false);

	return (
		<div className={classes.transferencia}>
			{backdrop && (
				<Backdrop className={classes.backdrop} open={open} style={{ zIndex: 99999 }}>
					<CircularProgress color="inherit" />
				</Backdrop>
			)}

			<Dialog open={open} fullScreen={fullScreen} onClose={handleClose}>
				<DialogContent className={classes.content}>
					<Paper className={classes.saldoBox}>
						<div className={classes.saldoAtual}>Saldo Atual: {Formatter.Real(saldo.atual)} </div>
						{/* <div className={classes.saldoFuturo}>Saldo Futuro: {Formatter.Real(saldo.futuro)} </div> */}
					</Paper>

					<Grid container justifyContent="center" spacing={2} style={{ marginTop: 5 }}>
						<Grid item xs={12}>
							<TextField
								className={classes.fullWidth}
								error={errors.valor}
								label="Valor a ser transferido"
								value={Mask.Real(data.valor)}
								onChange={e => setData({ ...data, valor: Mask.OnlyNumber(e.target.value) / 100 })}
							/>
						</Grid>
						{errors.valorMessage && (
							<Grid item xs={12} style={{ textAlign: 'center' }}>
								<div className={classes.error}>{errors.valorMessage}</div>
							</Grid>
						)}

						<Grid item xs={12}>
							Para que a transferência seja realizado no mesmo dia é necessário que a solicitação seja
							feita até as 11h, após esse horário as novas solicitações serão processadas no próximo dia
							útil
						</Grid>

						<Grid item xs={12} align="center" style={{ marginTop: 20 }}>
							Dados do destinatário
						</Grid>

						<Grid item xs={12} style={{ marginTop: 2 }}>
							<FormControl error={errors.bancoId || false} style={{ width: '100%' }}>
								<InputLabel htmlFor="banco">Banco</InputLabel>
								<Select
									label="Banco"
									value={data.bancoId}
									onChange={e => setData({ ...data, bancoId: e.target.value })}
									inputProps={{ id: 'bancoId' }}
								>
									{bancos.map((b, i) => (
										<MenuItem key={i} value={b.id}>
											{b.nome}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} style={{ marginTop: 4 }}>
							<TextField
								className={classes.fullWidth}
								error={errors.favorecido}
								label="Favorecido"
								value={data.favorecido}
								onChange={e => setData({ ...data, favorecido: e.target.value })}
							/>
						</Grid>
						<Grid item xs={12} style={{ marginTop: 4 }}>
							<TextField
								className={classes.fullWidth}
								error={errors.cpfcnpj}
								label="CPF/CNPJ"
								autoComplete="off"
								value={data.cpfcnpj.length <= 11 ? Mask.Cpf(data.cpfcnpj) : Mask.Cnpj(data.cpfcnpj)}
								onChange={e => {
									const value =
										e.target.value.length <= 11
											? Mask.Cpf(e.target.value)
											: Mask.Cnpj(e.target.value);
									setData({ ...data, cpfcnpj: Mask.OnlyNumber(value) });
								}}
							/>
						</Grid>

						<Grid item xs={12} style={{ marginTop: 4 }}>
							<TextField
								className={classes.fullWidth}
								error={errors.agencia}
								label="Agencia"
								value={data.agencia}
								onChange={e => setData({ ...data, agencia: e.target.value })}
							/>
						</Grid>

						<Grid item xs={8} style={{ marginTop: 4 }}>
							<TextField
								className={classes.fullWidth}
								label="Conta"
								error={errors.conta}
								value={data.conta}
								onChange={e => setData({ ...data, conta: e.target.value })}
							/>
						</Grid>

						<Grid item xs={4} style={{ marginTop: 4 }}>
							<TextField
								className={classes.fullWidth}
								label="Digito"
								error={errors.digito}
								value={data.digito}
								onChange={e => setData({ ...data, digito: e.target.value })}
							/>
						</Grid>
						<Grid item xs={12} style={{ marginTop: 5 }}>
							<FormControl component="fieldset">
								{/* <FormLabel component="legend">labelPlacement</FormLabel> */}
								<RadioGroup
									row
									aria-label="position"
									name="position"
									value={data.tipoConta}
									onChange={(a, value) => {
										setData({ ...data, tipoConta: value });
									}}
								>
									<FormControlLabel
										value="1"
										control={<Radio color="primary" />}
										label="Conta Corrente"
									/>
									<FormControlLabel
										value="2"
										control={<Radio color="primary" />}
										label="Conta Poupança"
									/>
								</RadioGroup>
							</FormControl>
						</Grid>

						<Grid item xs={12} style={{ marginTop: 4 }}>
							<TextField
								className={classes.fullWidth}
								label="Descrição da transferência"
								value={data.descricao}
								onChange={e => setData({ ...data, descricao: e.target.value })}
							/>
						</Grid>

						<Grid item xs={12} align="center" style={{ marginTop: 25 }}>
							Informe sua senha para concluir
						</Grid>
						<Grid item xs={12} style={{ marginTop: 0 }}>
							<TextField
								className={classes.fullWidth}
								error={errors.senha}
								type="password"
								label="Senha"
								inputProps={{
									autoComplete: 'new-password',
									form: {
										autoComplete: 'off',
									},
								}}
								value={data.senha}
								onChange={e => setData({ ...data, senha: e.target.value })}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					{sending ? (
						<ZLoader size={30} height={60} />
					) : (
						<React.Fragment>
							<Button onClick={handleClose} color="primary">
								Cancelar
							</Button>
							<Button onClick={handleSubmit} color="primary" autoFocus>
								Concluir
							</Button>
						</React.Fragment>
					)}
				</DialogActions>
			</Dialog>
		</div>
	);
};
