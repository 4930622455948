import axios from 'axios';
import config from 'react-global-configuration';

// Action Types

export const Types = {
    SET_ESTABELECIMENTO: 'admin/SET_ESTABELECIMENTO',
};

// Reducer

const initialState = {
    estabelecimento: null,
};

const reducer = (state = initialState, action) => {
    if(!action){ return state; }

    switch (action.type) {
        case Types.SET_ESTABELECIMENTO:
            return {
                ...state,
                estabelecimento: action.estabelecimento
            };

        default:
            return state;
    }
};

export default reducer;

// Actions

export const setEstabelecimento = (estabelecimentoId) => async dispatch => {
    let token = localStorage.getItem('token');

    if (token && estabelecimentoId) {
        const res = await axios.get(`${config.get('apiUrl')}estabelecimentos/${estabelecimentoId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if (res.data.success && res.data.estabelecimento){
            return dispatch({
                type: Types.SET_ESTABELECIMENTO,
                estabelecimento: res.data.estabelecimento,
            });
        }
    }

    return dispatch({
        type: Types.SET_ESTABELECIMENTO,
        estabelecimento: null,
    })
};