import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	ZSplashScreen: {
		flexGrow: 1,
	},
}));

export default function ZSplashScreen(props) {
	const classes = useStyles();
	const systemConfig = useSelector(store => store.system.config);

	return (
		<Grid container className={classes.root} style={{ height: '100vh' }}>
			<Grid item align="center">
				<img
					src={systemConfig.loaderLogoUrl}
					style={{ maxWidth: '250px' }}
					alt={`Logo ${systemConfig.nome}`}
					title={`Logo ${systemConfig.nome}`}
				></img>
				<LinearProgress />
			</Grid>
		</Grid>
	);
}
