import React, { useState } from 'react';
import { Typography, Grid, Button, Divider, makeStyles } from '@material-ui/core';
import format from 'date-fns/format';
// import { useSelector } from 'react-redux';

import { Mask, Formatter, isWhite } from '../../utils';
import ZLoader from '../system/ZLoader';
import ZModalConfirm from '../system/ZModalConfirm';

const useStyles = makeStyles(theme => ({
	fullWidth: {
		width: '100%',
	},
	formaPagamento: {
		padding: 20,
		fontSize: 16,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginBottom: 32,
		fontWeight: 700,
		color: '#333',
		width: 263,
		border: `solid 1px rgb(234, 234, 234)`,
		borderRadius: 8,
		cursor: 'pointer',
		'&.selected': {
			border: 'solid 1px #0073EC',
			color: '#0073EC',
		},
		'&:hover': {
			border: '1px solid #0073EC',
			color: '#0073EC',
		},
		[theme.breakpoints.down('sm')]: {
			padding: 10,
			marginBottom: 20,
			width: '100%',
		},
	},
	stepActions: {
		display: 'flex',
		flexWrap: 'nowrap',
		marginTop: 60,
		textAlign: 'center',
	},
	backButton: {
		color: isWhite(theme.palette.primary.main) ? '#B4B4B4' : theme.palette.primary.main,
		border: `solid 1px `,
		borderColor: isWhite(theme.palette.primary.main),
		width: 171,
		height: 44,
		marginRight: 10,
		'&:hover': {
			color: isWhite(theme.palette.primary.main)
				? '#313131'
				: theme.palette.primary.main
				? '#fff'
				: theme.palette.getContrastText(theme.palette.primary.main),
			backgroundColor: theme.palette.primary.main,
		},
		[theme.breakpoints.down('sm')]: {
			marginRight: 0,
			width: '100%',
		},
	},
	nextButton: {
		width: 171,
		height: 44,
		backgroundColor: isWhite(theme.palette.primary.main) ? '#EEEEEE' : theme.palette.primary.main,
		marginRight: 10,
		color: isWhite(theme.palette.primary.main)
			? '#606060'
			: theme.palette.getContrastText(theme.palette.primary.main),
		'&:hover': {
			backgroundColor: isWhite(theme.palette.primary.main) ? '#ccc' : theme.palette.primary.dark,
		},
		[theme.breakpoints.down('sm')]: {
			marginRight: 0,
			width: '100%',
		},
	},
	stepTitle: {
		color: '#3E3B47',
		marginBottom: 48,
	},
	header: {
		fontWeight: 700,
		fontSize: 20,
		margin: 8,
	},
	subHeader: {
		fontWeight: 700,
		fontSize: 16,
		margin: 8,
		marginTop: 50,
		textAlign: 'center',
	},
	subHeader2: {
		fontWeight: 700,
		fontSize: 14,
		margin: 8,
		marginTop: 20,
		marginBottom: 0,
	},
	content: {
		padding: 10,
	},
	field: {
		padding: 5,
	},
	label: {
		fontSize: 11,
		textTransform: 'uppercase',
		fontWeight: 700,
	},
	valorTotal: {
		fontSize: 24,
		fontWeight: 700,
	},
	parcelas: {
		fontSize: 18,
		fontWeight: 700,
	},
	parcelasValor: {
		fontSize: 15,
		fontWeight: 600,
	},
	containerEndereco: {
		display: 'flex',

		'& div + div': {
			marginLeft: 8,
		},
	},
	containerSplit: {
		'& > div ': {
			borderRight: '1px solid #eee',
			marginTop: 32,

			'& div, span': {
				marginLeft: 8,
			},
		},
	},
	containerDesconto: {
		'& > div ': {
			borderRight: '1px solid #eee',
			marginTop: 20,

			'& div, span': {
				marginLeft: 8,
			},
		},
	},
	qrcodeHover: {
		transition: theme.transitions.create(['all'], {
			duration: theme.transitions.duration.complex,
		}),
		opacity: 0,
		height: '100%',
		position: 'absolute',
		width: '100%',
		background: '#fafafa',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		zIndex: 100,
		top: 0,
		left: 0,
		'&:hover': {
			opacity: 0.7,
		},
	},
	divider: {
		margin: 4,
	},
	span: {
		color: '#777',
		fontSize: 14,
	},
}));

const ResumoPix = ({ data, handleSubmit, sending, activeStep, setActiveStep, split, canSplit }) => {
	const classes = useStyles();
	return (
		<>
			<Typography variant="h5" component="h6" className={classes.stepTitle}>
				Resumo
			</Typography>

			<Grid container style={{ padding: 16 }}>
				<Grid item xs={12}>
					<Typography
						variant="body2"
						component="h6"
						className={classes.subHeaderTitle}
						style={{ marginTop: 32 }}
					>
						Pagamento PIX
					</Typography>
					<Divider />
				</Grid>

				<Grid container direction="row">
					<Grid item xs={4} lg={4}>
						<div className={classes.field}>
							<div className={classes.label}>Valor</div>
							<div className={classes.valorTotal}>{Formatter.Real(data.valorPix)}</div>
						</div>
					</Grid>
					<Grid item xs={8} lg={8}>
						<div className={classes.field}>
							<div className={classes.label}>Descrição</div>
							<div>{data.descricao}</div>
						</div>
					</Grid>
				</Grid>
			</Grid>

			<Grid container spacing={2} direction="row" justifyContent="flex-end" className={classes.stepActions}>
				{sending ? (
					<ZLoader height={20} size={30} />
				) : (
					<React.Fragment>
						<Grid item xs={12} sm={12} md={8} lg={3}>
							<Button
								className={classes.backButton}
								fullWidth
								onClick={
									split === 0 && canSplit
										? () => setActiveStep(+activeStep - 1)
										: () => setActiveStep(+activeStep - 3)
								}
							>
								Voltar
							</Button>
						</Grid>
						<Grid item xs={12} sm={12} md={8} lg={3}>
							<Button className={classes.nextButton} fullWidth onClick={() => handleSubmit()}>
								Finalizar
							</Button>
						</Grid>
					</React.Fragment>
				)}
			</Grid>
		</>
	);
};

export default function Resumo({ data, handleSubmit, sending, activeStep, setActiveStep, split, canSplit, setData }) {
	function verifyModal(prev) {
		switch (prev) {
			case true:
				setIsModalOpen(false);
				handleSubmit(true);
				break;
			case false:
				setIsModalOpen(false);
				handleSubmit(false);
				break;
			default:
				console.log('error');
		}
	}
	const classes = useStyles();
	const props = { data, handleSubmit, sending, activeStep, setActiveStep, split, canSplit, setData };
	const [isModalOpen, setIsModalOpen] = useState(false);
	if (data.formaPagamento === 5) {
		return <ResumoPix {...props} />;
	}

	const handleBack = () => {
		if ((split !== undefined && split !== 0) || (canSplit !== undefined && !canSplit)) {
			if (data.formaPagamento === 3) {
				return setActiveStep(+activeStep - 3);
			}
			return setActiveStep(+activeStep - 2);
		}
		setActiveStep(+activeStep - 1);
	};

	return (
		<>
			<Typography variant="h5" component="h6" className={classes.stepTitle}>
				Resumo
			</Typography>

			<Grid container style={{ padding: 16 }}>
				<Grid item xs={12} sm={12} md={12}>
					<Grid container justifyContent="center">
						<Grid item xs={12}>
							<Typography variant="body2" component="h6" className={classes.subHeaderTitle}>
								Cliente
							</Typography>
							<Divider />
						</Grid>

						<Grid item xs={12} sm={4} md={4} lg={4}>
							<div className={classes.field}>
								<div className={classes.label}>Nome</div>
								<div>{data.nome}</div>
							</div>
						</Grid>
						<Grid item xs={12} sm={4} md={4} lg={4}>
							<div className={classes.field}>
								<div className={classes.label}>CPF / CNPJ</div>
								<div>{data.cpf.length <= 11 ? Mask.Cpf(data.cpf) : Mask.Cnpj(data.cpf)}</div>
							</div>
						</Grid>
						<Grid item xs={12} sm={4} md={4} lg={4}>
							<div className={classes.field}>
								<div className={classes.label}>E-mail</div>
								<div>{data.email}</div>
							</div>
						</Grid>
					</Grid>

					<Grid item xs={12}>
						<Typography
							variant="body2"
							component="h6"
							className={classes.subHeaderTitle}
							style={{ marginTop: 32 }}
						>
							Endereço
						</Typography>
						<Divider />
					</Grid>

					<Grid item xs={12} sm={4} md={4} lg={12}>
						<div className={classes.field}>
							<div className={classes.label}>Endereço</div>
							<div className={classes.containerEndereco}>
								<div>{`${data.logradouro}, ${data.numero} - `}</div>
								<div>{`${Mask.Cep(data.cep)}, ${data.bairro} - ${data.cidade} - ${data.estado}`}</div>
								<div>{`${data.complemento}`}</div>
							</div>
						</div>
					</Grid>

					<Grid item xs={12}>
						<Typography
							variant="body2"
							component="h6"
							className={classes.subHeaderTitle}
							style={{ marginTop: 32 }}
						>
							Pagamento
						</Typography>
						<Divider />
					</Grid>

					{data.formaPagamento === 1 && (
						<React.Fragment>
							<Grid container direction="row">
								<Grid item xs={4} lg={3}>
									<div className={classes.field}>
										<div className={classes.label}>Valor</div>
										<div className={classes.valorTotal}>{Formatter.Real(data.valorBoleto)}</div>
									</div>
								</Grid>
								<Grid item xs={4} lg={3}>
									<div className={classes.field}>
										<div className={classes.label}>Data de Vencimento</div>
										<div>{format(data.dataVencimento, 'DD/MM/YYYY')}</div>
									</div>
								</Grid>
								<Grid item xs={4} lg={3}>
									<div className={classes.field}>
										<div className={classes.label}>Data Limite</div>
										<div>{format(data.dataLimite, 'DD/MM/YYYY')}</div>
									</div>
								</Grid>
							</Grid>

							<Grid item xs={12}>
								<Typography
									variant="body2"
									component="h6"
									className={classes.subHeaderTitle}
									style={{ marginTop: 32 }}
								>
									Descrição
								</Typography>
								<Divider />
							</Grid>

							<Grid item xs={12} sm={4} md={4} lg={4}>
								<div className={classes.field}>
									<div>{data.descricao}</div>
								</div>
							</Grid>

							{data.descontos[0].value !== 0 && (
								<>
									<Grid item xs={12}>
										<Typography
											variant="body2"
											component="h6"
											className={classes.subHeaderTitle}
											style={{ marginTop: 32 }}
										>
											Descontos
										</Typography>
										<Divider />
									</Grid>

									<Grid container item md={12} xs={12}>
										<Grid container item md={12} xs={12}>
											{data.descontos.map((desconto, key) => (
												<>
													{desconto.value !== 0 && (
														<Grid
															key={key}
															item
															md={4}
															xs={3}
															className={classes.containerDesconto}
														>
															<div>
																<div className={classes.label} style={{ marginTop: 8 }}>
																	{desconto.mode === 'FIXED'
																		? 'Valor do desconto'
																		: 'Percentual de desconto'}
																</div>
																<span className={classes.span}>
																	{desconto.mode === 'FIXED'
																		? Mask.Real(desconto.value)
																		: `${Mask.Porcentagem(desconto.value)}%`}
																</span>
																<div className={classes.label} style={{ marginTop: 8 }}>
																	Data limite
																</div>
																<span className={classes.span}>
																	{format(desconto.limitDate, 'DD/MM/YYYY')}
																</span>
															</div>
														</Grid>
													)}
												</>
											))}
										</Grid>
									</Grid>
								</>
							)}
						</React.Fragment>
					)}

					{data.formaPagamento === 3 && (
						<React.Fragment>
							<Grid container direction="row">
								<Grid item xs={6} lg={3}>
									<div className={classes.field}>
										<div className={classes.parcelas}>{`${data.parcelasCartao}x`}</div>
										<div className={classes.parcelasValor}>
											{Formatter.Real(data.valorCartao / data.parcelasCartao)}
										</div>
									</div>
								</Grid>
								<Grid item xs={6} lg={3}>
									<div className={classes.field}>
										<div className={classes.label}>Valor</div>
										<div className={classes.valorTotal}>{Formatter.Real(data.valorCartao)}</div>
									</div>
								</Grid>
							</Grid>
						</React.Fragment>
					)}

					{data.formaPagamento === 4 && (
						<React.Fragment>
							<Grid container direction="row">
								<Grid item xs={6} lg={3}>
									<div className={classes.field}>
										<div className={classes.parcelas}>{`${data.parcelasCarne}x`}</div>
										<div className={classes.parcelasValor}>
											{Formatter.Real(data.valorCarne / data.parcelasCarne)}
										</div>
									</div>
								</Grid>
								<Grid item xs={6} lg={3}>
									<div className={classes.field}>
										<div className={classes.label}>Valor</div>
										<div className={classes.valorTotal}>{Formatter.Real(data.valorCarne)}</div>
									</div>
								</Grid>
							</Grid>
							{data.descontos[0].value !== 0 && (
								<>
									<Grid item xs={12}>
										<Typography
											variant="body2"
											component="h6"
											className={classes.subHeaderTitle}
											style={{ marginTop: 32 }}
										>
											Descontos
										</Typography>
										<Divider />
									</Grid>

									<Grid container item md={12} xs={12}>
										<Grid container item md={12} xs={12}>
											{data.descontos.map((desconto, key) => (
												<>
													{desconto.value !== 0 && (
														<Grid
															key={key}
															item
															md={4}
															xs={3}
															className={classes.containerDesconto}
														>
															<div>
																<div className={classes.label} style={{ marginTop: 8 }}>
																	{desconto.mode === 'FIXED'
																		? 'Valor do desconto na parcela'
																		: 'Percentual de desconto na parcela'}
																</div>
																<span className={classes.span}>
																	{desconto.mode === 'FIXED'
																		? Mask.Real(desconto.value)
																		: `${Mask.Porcentagem(desconto.value)}%`}
																</span>
																<div className={classes.label} style={{ marginTop: 8 }}>
																	Data limite
																</div>
																<span className={classes.span}>
																	{format(desconto.limitDate, 'DD/MM/YYYY')}
																</span>
															</div>
														</Grid>
													)}
												</>
											))}
										</Grid>
									</Grid>
								</>
							)}
						</React.Fragment>
					)}

					{data.splits[0].cpfcnpj && (
						<>
							<Grid item xs={12}>
								<Typography
									variant="body2"
									component="h6"
									className={classes.subHeaderTitle}
									style={{ marginTop: 32 }}
								>
									Splits
								</Typography>
								<Divider />
							</Grid>

							<Grid container item md={12} xs={12}>
								<Grid container item md={12} xs={12}>
									{data.splits.map((split, key) => (
										<Grid key={key} item md={4} xs={3} className={classes.containerSplit}>
											<div>
												<div className={classes.label}>CPF/CNPJ</div>
												<span>
													{split.cpfcnpj.length <= 11
														? Mask.Cpf(split.cpfcnpj)
														: Mask.Cnpj(split.cpfcnpj)}
												</span>

												<div className={classes.label} style={{ marginTop: 8 }}>
													{split.tipoSplit === 1 ? 'Valor' : 'Percentual de split'}
												</div>
												<span className={classes.span}>
													{split.tipoSplit === 1
														? Mask.Real(split.valor)
														: `${Mask.Porcentagem(split.valor)}%`}
												</span>
											</div>
										</Grid>
									))}
								</Grid>
							</Grid>
						</>
					)}
				</Grid>
			</Grid>

			<Grid container spacing={2} direction="row" justifyContent="flex-end" className={classes.stepActions}>
				{sending ? (
					<ZLoader height={20} size={30} />
				) : (
					<React.Fragment>
						<Grid item xs={12} sm={12} md={8} lg={3}>
							<Button className={classes.backButton} fullWidth onClick={handleBack}>
								Voltar
							</Button>
						</Grid>
						<Grid item xs={12} sm={12} md={8} lg={3}>
							<Button
								className={classes.nextButton}
								fullWidth
								onClick={() => {
									if (data.formaPagamento === 1) {
										setIsModalOpen(true);
									} else {
										handleSubmit();
									}
								}}
							>
								Finalizar
							</Button>
						</Grid>
					</React.Fragment>
				)}
			</Grid>
			<ZModalConfirm
				title={`Enviar email?`}
				onClose={result => {
					verifyModal(result);
				}}
				setIsModalOpen={setIsModalOpen}
				open={isModalOpen}
				message={`Deseja enviar um email ao cliente cadastrado ?`}
			/>
		</>
	);
}
