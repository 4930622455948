/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Close';
import { FaPercentage } from 'react-icons/fa';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';

import { useHistory, useLocation } from 'react-router-dom';
import { SelectEstabelecimento } from '../Estabelecimentos/SelectEstabelecimento';
import { addMessage } from '../../store/alert';
import format from 'date-fns/format';
import parse from 'date-fns/parse';

import axios from 'axios';
import config from 'react-global-configuration';

// import ZTitle from '../system/ZTitle';
import ZSaveButton from '../system/ZSaveButton';
import ZSwitch from '../system/ZSwitch';
import ZDatePicker from '../system/ZDatePicker';
import ZText from '../system/ZText';
import ZLoader from '../system/ZLoader';

import { Mask, Isset, Formatter } from '../../utils';
import ZModalConfirm from '../system/ZModalConfirm';
import ZModalWarning from '../system/ZModalWarning';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
	fullWidth: {
		width: '100%',
	},
	estabelecimento: {
		padding: '6px 6px',
		marginBottom: 10,
	},
	estabelecimentoValor: {
		'&.porcentagem input': {
			textAlign: 'right',
		},
	},
	estabelecimentoIcone: {
		border: 'solid 1px #abc',
		marginRight: 2,
		marginLeft: 2,
		marginTop: 16,
		'&.selected': {
			backgroundColor: `${theme.palette.primary.main}`,
			color: theme.palette.getContrastText(theme.palette.primary.main),
		},
	},
	estabelecimentoError: {
		fontSize: 12,
		color: '#e53935',
	},
	label: {
		textAlign: 'center',
		fontSize: 11,
		textTransform: 'uppercase',
		fontWeight: 700,
	},
	removeButton: {
		minHeight: 26,
		height: 26,
		width: 26,
		backgroundColor: '#e53935',
		color: 'white',
	},
	novaRegraSplit: {
		textAlign: 'center',
		textTransform: 'uppercase',
		margin: 8,
		fontWeight: 700,
		fontSize: 12,
		cursor: 'pointer',
		color: theme.palette.primary.main,
	},
	box: {
		textTransform: 'uppercase',
		fontSize: 12,
		position: 'relative',
		padding: 10,
		height: 110,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		cursor: 'pointer',
		'&.selected': {
			backgroundColor: `${theme.palette.primary.main}55`,
		},
		'& .value': {
			fontWeight: 700,
			fontSize: 13,
		},
	},
	splitAtivo: {
		position: 'absolute',
		top: -8,
		right: 10,
		fontWeight: 700,
		color: '#e53935',
		'&.ativo': {
			color: 'green',
		},
	},
	divider: {
		margin: 4,
	},
	removerButton: {
		backgroundColor: '#e53935',
		color: 'white',
	},
	statusButton: {
		backgroundColor: '#e53935',
		color: 'white',
		'&.ativar': {
			backgroundColor: 'green',
		},
	},
	error: {
		padding: 8,
		borderRadius: 5,
		backgroundColor: '#e53935',
		color: 'white',
		marginBottom: 10,
	},
	subheader: {
		fontWeight: 700,
		fontSize: 18,
		fontVariant: 'small-caps',
		letterSpacing: '0.02em',
		margin: '20px 8px',
		marginTop: -30,
	},
	valorLiquido: {
		'& .MuiFormControlLabel-label': {
			fontSize: 11,
			fontWeight: 'bold',
			textAlign: 'center',
		},
	},
	modalSelectEstabelecimento: {
		minWidth: '400px',
	},
	SelectEstabelecimento: {
		width: '100%',
	},
	formControl: {
		width: '100%',
	},
}));

const initialState = {
	id: null,
	ativo: false,
	estabelecimentos: [
		{
			cpfcnpj: '',
			estabelecimentoId: null,
			tipoSplit: 2, // 1 - Valor 2 - Percentual
			valor: '',
			nome: '',
			email: '',
			chargeProcessingFee: true,
		},
	],
	limitarData: false,
	limitarValor: false,
	assumirTaxa: false,
	dataInicio: new Date(),
	dataFim: new Date(),
	valorMaximo: 0,
};

export default props => {
	const classes = useStyles();
	const dispatch = useDispatch();
	// const { history, location, match } = useReactRouter();
	const history = useHistory();
	const location = useLocation();

	let estabelecimentoId = history.params ? history.params.estabelecimentoId : undefined;

	if (!estabelecimentoId) {
		({ estabelecimentoId } = props);
	}

	const token = useSelector(store => store.auth.token);

	const [loading, setLoading] = useState(false);

	// const [estabelecimento, setEstabelecimento] = useState(null);
	const [errors, setErrors] = useState({});
	const [data, setData] = useState(initialState);
	const [newSplit, setNewSplit] = useState(true);
	const [loadingSplits, setLoadingSplits] = useState(true);
	const [splits, setSplits] = useState([]);
	const [confirmRemoveOpen, setConfirmRemoveOpen] = useState(false);
	const [confirmRemoveEcs, setConfirmRemoveEcs] = useState(false);

	useEffect(() => {
		const getSplits = async () => {
			const res = await axios.get(`${config.get('apiUrl')}estabelecimentos/${estabelecimentoId}/splits`, {
				headers: { Authorization: `Bearer ${token}` },
			});

			if (res.data.success) {
				// if (Isset(() => res.data.estabelecimento)) {
				// 	setEstabelecimento(res.data.estabelecimento);
				// }

				if (res.data.splits.length > 0) {
					const s = res.data.splits.map(v => {
						const totalValor = v.splits_estabelecimentos.reduce(
							(r, a) => (a.tipo_split === 1 ? r + +a.valor : r),
							0
						);
						const totalPercent = v.splits_estabelecimentos.reduce(
							(r, a) => (a.tipo_split === 2 ? r + +a.valor : r),
							0
						);

						return { ...v, totalValor, totalPercent };
					});

					setSplits(s);
					setNewSplit(false);
				}
			}
			setLoadingSplits(false);
		};

		getSplits();
	}, []);

	const validate = () => {
		setErrors({});
		const e = {};

		if (data.limitarData) {
			if (!data.dataInicio) {
				e.dataInicio = true;
			}
			if (!data.dataFim) {
				e.dataFim = true;
			}
		}

		if (data.limitarValor && (!data.valorMaximo || data.valorMaximo < 1)) {
			e.valorMaximo = true;
		}

		let totalPorcentagem = 0;

		data.estabelecimentos.forEach((a, i) => {
			if (!a.cpfcnpj) {
				e[`estabelecimento${i}`] = 'Escolha um estabelecimento.';
			} else if (
				!a.estabelecimentoId &&
				data.estabelecimentos.find((b, j) => b.cpfcnpj === a.cpfcnpj && j !== i)
			) {
				e[`estabelecimento${i}`] = 'Você já selecionou este estabelecimento.';
			} else if (!a.estabelecimentoId) {
				e[`estabelecimento${i}`] = 'Estabelecimento não encontrado.';
			}

			if (!a.valor) {
				e[`estabelecimento${i}valor`] = true;
			} else {
				if (+a.tipoSplit === 2) {
					totalPorcentagem += +a.valor;
				}
			}
		});

		if (totalPorcentagem > 100) {
			e.porcentagem = true;
		}

		setErrors(e);
		return Object.keys(e).length > 0;
	};

	const handleNewEstabelecimento = () => {
		var estabelecimentos = [...data.estabelecimentos];

		estabelecimentos.push({
			cpfcnpj: '',
			estabelecimentoId: null,
			nome: '',
			email: '',
			tipoSplit: 2, // 1 - Valor, 2 - Percentual
			valor: '',
			chargeProcessingFee: true,
		});

		setData({
			...data,
			estabelecimentos,
		});
	};

	const handleRemoverEstabelecimento = position => {
		var estabelecimentos = [...data.estabelecimentos];
		estabelecimentos.splice(position, 1);

		setData({
			...data,
			estabelecimentos,
		});
	};

	const updateEstabelecimento = async (index, name, value) => {
		let estabelecimentos = [...data.estabelecimentos];
		let estabelecimento = estabelecimentos.find((_, i) => i === index);

		if (estabelecimento) {
			estabelecimento = Object.assign({}, estabelecimento);
			estabelecimento[name] = value;

			if (name === 'cpfcnpj') {
				delete errors[`estabelecimento${index}`];
				setErrors(errors);

				if ([11, 14].includes(value.length)) {
					const result = await axios.get(`${config.get('apiUrl')}estabelecimentos/por_documento/${value}`, {
						headers: { Authorization: `Bearer ${token}` },
					});

					if (result.data.success) {
						const e = result.data.estabelecimento;

						if (!e.length) {
							if (+estabelecimentoId === +e.id) {
								setErrors({
									...errors,
									[`estabelecimento${index}`]: 'Você não pode selecionar o mesmo estabelecimento.',
								});
								return;
							}

							const email = e.estabelecimentos_contatos.find(a => a.tipo_contato_id === 3);

							const estabelecimentoExiste = data.estabelecimentos.find(
								(a, i) => a.estabelecimentoId === e.id && index !== i
							);

							if (estabelecimentoExiste) {
								setErrors({
									...errors,
									[`estabelecimento${index}`]: 'Você já selecionou este estabelecimento.',
								});
								return;
							} else {
								estabelecimento['estabelecimentoId'] = e.id;
								estabelecimento['nome'] = e.nome_fantasia;
								estabelecimento['email'] = Isset(() => email.contato) ? email.contato : '';
							}
						} else {
							openEstablishmentSelectionModal(e, index);
						}
					} else {
						setErrors({ ...errors, [`estabelecimento${index}`]: 'Estabelecimento Não Encontrado.' });
					}
				} else {
					estabelecimento['estabelecimentoId'] = null;
					estabelecimento['nome'] = '';
					estabelecimento['email'] = '';
				}
			}

			estabelecimentos[index] = estabelecimento;

			setData({ ...data, estabelecimentos });
		}
	};

	const [showModalEstablishmentSelection, setShowModalEstablishmentSelection] = useState(false);
	const [ecsOfTheDocument, setEcsOfTheDocument] = useState();
	const [ecSelected, setEcSelected] = useState('Estabelecimento');
	const [indexEc, setIndexEc] = useState();

	const openEstablishmentSelectionModal = (estabelecimentos, index) => {
		setEcsOfTheDocument(estabelecimentos);
		setIndexEc(index);
		setShowModalEstablishmentSelection(true);
	};

	const editSplit = async splitId => {
		setNewSplit(false);
		setData(initialState);
		const res = await axios.get(`${config.get('apiUrl')}estabelecimentos/${estabelecimentoId}/splits/${splitId}`, {
			headers: { Authorization: `Bearer ${token}` },
		});

		if (res.data.success) {
			const split = res.data.split;
			const editData = Object.assign({}, initialState);
			editData.id = split.id;
			editData.ativo = split.ativo;
			if (split.data_inicio && split.data_fim) {
				editData.limitarData = true;
				editData.dataInicio = parse(split.data_inicio, 'YYYY-MM-DD', new Date());
				editData.dataFim = parse(split.data_fim, 'YYYY-MM-DD', new Date());
			}
			if (split.valor_maximo) {
				editData.limitarValor = true;
				editData.valorMaximo = split.valor_maximo;
				editData.valorPago = split.valor_pago;
			}
			editData.estabelecimentos = [];
			split.splits_estabelecimentos.forEach(a => {
				editData.estabelecimentos.push({
					cpfcnpj: a.estabelecimento.documento,
					estabelecimentoId: a.estabelecimento_id,
					nome: a.estabelecimento.nome_fantasia,
					email: a.estabelecimento.email,
					tipoSplit: a.tipo_split,
					valor: a.valor,
					chargeProcessingFee: a.charge_processing_fee,
					charge_recipient_processing_fee: a.charge_recipient_processing_fee,
				});
			});

			setData(editData);
			setData({
				...editData,
				assumirTaxa: editData.estabelecimentos[0].charge_recipient_processing_fee,
			});
			setNewSplit(true);
		}
	};

	const handleSubmit = async () => {
		setLoading(true);

		if (!validate()) {
			const postData = {
				...data,
			};

			if (!postData.limitarData) {
				postData.dataInicio = null;
				postData.dataFim = null;
			} else {
				postData.dataInicio = format(postData.dataInicio, 'YYYY-MM-DD');
				postData.dataFim = format(postData.dataFim, 'YYYY-MM-DD');

				if (postData.dataFim < postData.dataInicio) {
					postData.dataFim = postData.dataInicio;
				}
			}

			if (!postData.limitarValor) {
				postData.valorMaximo = null;
			}

			const res = await axios.post(
				`${config.get('apiUrl')}estabelecimentos/${estabelecimentoId}/splits`,
				postData,
				{
					headers: { Authorization: `Bearer ${token}` },
				}
			);

			if (res.data.success) {
				setData(initialState);
				dispatch(addMessage({ type: 'success', message: 'Configuração de Split salva com sucesso.' }));
				const current = location.pathname;
				setTimeout(() => {
					history.replace('/reload');
					setTimeout(() => history.replace(current));
				}, 200);
			} else {
				dispatch(addMessage({ type: 'error', message: res.data.message }));
			}
		}

		setLoading(false);
	};

	const toggleStatus = async splitId => {
		const res = await axios.put(
			`${config.get('apiUrl')}estabelecimentos/${estabelecimentoId}/splits/${splitId}/status`,
			{},
			{
				headers: { Authorization: `Bearer ${token}` },
			}
		);

		if (res.data.success) {
			dispatch(addMessage({ type: 'success', message: 'Status alterado com sucesso.' }));
			const current = location.pathname;
			history.replace('/reload');
			setTimeout(() => history.replace(current));
		}
	};

	const remover = async splitId => {
		const res = await axios.delete(
			`${config.get('apiUrl')}estabelecimentos/${estabelecimentoId}/splits/${splitId}`,
			{
				headers: { Authorization: `Bearer ${token}` },
			}
		);

		if (res.data.success) {
			dispatch(addMessage({ type: 'success', message: 'Regra de Split removida com sucesso.' }));
			const current = location.pathname;
			history.replace('/reload');
			setTimeout(() => history.replace(current));
		}

		setConfirmRemoveOpen(false);
	};

	if (loadingSplits) {
		return <ZLoader />;
	}

	const handleSettarAssumirTaxa = () => {
		setData({ ...data, assumirTaxa: !data.assumirTaxa });
		if (!data.assumirTaxa && data.estabelecimentos.length > 1) {
			setConfirmRemoveEcs(true);
		}
	};

	const handleConfirmRemoveEcs = result => {
		if (result) {
			setData({
				...data,
				estabelecimentos: [
					{
						cpfcnpj: data.estabelecimentos[0].cpfcnpj,
						estabelecimentoId: data.estabelecimentos[0].estabelecimentoId,
						tipoSplit: data.estabelecimentos[0].tipoSplit, // 1 - Valor 2 - Percentual
						valor: data.estabelecimentos[0].valor,
						nome: data.estabelecimentos[0].nome,
						email: data.estabelecimentos[0].email,
						chargeProcessingFee: data.estabelecimentos[0].chargeProcessingFee,
					},
				],
			});
		} else {
			setData({ ...data, assumirTaxa: !data.assumirTaxa });
		}
		setConfirmRemoveEcs(false);
	};

	const handleSetEstabelecimento = () => {
		let estabelecimento;
		let estabelecimentoFormatoParaData = {};
		let estabelecimentos = [...data.estabelecimentos];

		estabelecimento = ecsOfTheDocument.find(e => e.id === ecSelected);

		const email = estabelecimento.estabelecimentos_contatos.find(a => a.tipo_contato_id === 3);

		estabelecimentoFormatoParaData['estabelecimentoId'] = estabelecimento.id;
		estabelecimentoFormatoParaData['nome'] = estabelecimento.nome_fantasia;
		estabelecimentoFormatoParaData['email'] = Isset(() => email.contato) ? email.contato : '';
		estabelecimentoFormatoParaData['cpfcnpj'] = estabelecimento.estabelecimentos_documentos[0].documento;
		estabelecimentoFormatoParaData['tipoSplit'] = 2;

		estabelecimentos[indexEc] = estabelecimentoFormatoParaData;

		setData({ ...data, estabelecimentos });
		setShowModalEstablishmentSelection(false);
	};

	return (
		<Grid>
			{/* <ZTitle title="Configuração de Split" />
			{Isset(() => estabelecimento.nome_fantasia) && (
				<div className={classes.subheader}>{estabelecimento.nome_fantasia}</div>
			)} */}

			{splits.length > 0 && (
				<Grid container spacing={2}>
					{splits.map((split, i) => (
						<Grid key={i} item xs={12} sm={6} md={3}>
							<Paper
								className={clsx(classes.box, { selected: data.id === split.id })}
								onClick={() => editSplit(split.id)}
							>
								<div className={clsx(classes.splitAtivo, { ativo: split.ativo })}>
									{split.ativo ? 'Ativa' : 'Inativa'}
								</div>

								{split.data_inicio && split.data_fim && (
									<div className={classes.splitRange}>
										De <span className="value">{format(split.data_inicio, 'DD/MM/YYYY')}</span> até{' '}
										<span className="value">{format(split.data_fim, 'DD/MM/YYYY')}</span>
									</div>
								)}

								{split.valor_maximo > 0 && (
									<div className={classes.splitValorMaximo}>
										Até <span className="value">{Formatter.Real(split.valor_maximo)}</span>
									</div>
								)}

								<div>
									<span className="value">{split.splits_estabelecimentos.length}</span>{' '}
									estabelecimentos
								</div>

								<Divider className={classes.divider} />

								{split.totalValor > 0 && (
									<div className={classes.splitTotalValor}>
										Valor Total <span className="value">{Formatter.Real(split.totalValor)}</span>
									</div>
								)}
								{split.totalPercent > 0 && (
									<div className={classes.splitTotalPercent}>
										Porcentagem Total{' '}
										<span className="value">{Formatter.Real(split.totalPercent, false)} %</span>
									</div>
								)}
							</Paper>
						</Grid>
					))}
					{!newSplit && (
						<Grid item xs={12} sm={6} md={3}>
							<Paper
								className={classes.box}
								onClick={() => {
									setData(initialState);
									setNewSplit(true);
								}}
							>
								<div className={classes.novaRegraSplit}>Nova Regra de Split</div>
							</Paper>
						</Grid>
					)}
				</Grid>
			)}

			{newSplit && (
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Paper>
							<Grid container>
								{data.id !== null && (
									<Grid item xs={12} style={{ padding: 10 }}>
										<ZModalConfirm
											title="Atenção!"
											message={`Deseja remover esta Regra de Split?`}
											open={confirmRemoveOpen}
											onClose={result => (result ? remover(data.id) : null)}
										/>

										<Button
											onClick={() => setConfirmRemoveOpen(true)}
											size="small"
											style={{ marginRight: 10 }}
											className={clsx(classes.removerButton)}
										>
											Remover
										</Button>

										<Button
											onClick={() => toggleStatus(data.id)}
											size="small"
											className={clsx(classes.statusButton, { ativar: !data.ativo })}
										>
											{data.ativo ? 'Desativar' : 'Ativar'}
										</Button>
									</Grid>
								)}

								<Grid item xs={12} md={6} lg={6} style={{ padding: 20 }}>
									<Grid item xs={12} style={{ marginBottom: 10 }}>
										<Grid container>
											{errors.porcentagem && (
												<Grid item xs={12}>
													<div className={classes.error}>
														O total da porcentagem deve ser menor ou igual a 100%
													</div>
												</Grid>
											)}
											<Grid item xs={6}>
												<Typography variant="h6" gutterBottom>
													Estabelecimentos
												</Typography>
											</Grid>
											<Grid item xs={6}>
												{!data.assumirTaxa && (
													<Grid container direction="row" justifyContent="flex-end">
														<Button
															onClick={() => handleNewEstabelecimento()}
															variant="contained"
															size="small"
															color="primary"
															aria-label="add"
														>
															<AddIcon /> Estabelecimento
														</Button>
													</Grid>
												)}
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12} style={{ minHeight: '200px' }}>
										{data.estabelecimentos.map((estabelecimento, key) => {
											return (
												<Paper key={key} className={classes.estabelecimento} elevation={0}>
													<Grid container>
														<Grid item xs={12}>
															<Grid container spacing={2}>
																<Grid item xs={12} md={3}>
																	<ZText
																		className={classes.fullWidth}
																		label="CPF / CNPJ"
																		value={
																			estabelecimento.cpfcnpj.length <= 11
																				? Mask.Cpf(estabelecimento.cpfcnpj)
																				: Mask.Cnpj(estabelecimento.cpfcnpj)
																		}
																		onChange={(value, setValue) => {
																			value = Mask.OnlyNumber(value);
																			setValue(
																				value.length <= 11
																					? Mask.Cpf(value)
																					: Mask.Cnpj(value)
																			);
																		}}
																		onBlur={e => {
																			const v =
																				e.target.value.length <= 11
																					? Mask.Cpf(e.target.value)
																					: Mask.Cnpj(e.target.value);
																			updateEstabelecimento(
																				key,
																				'cpfcnpj',
																				Mask.OnlyNumber(v)
																			);
																		}}
																	/>
																</Grid>
																<Grid
																	style={{
																		display: 'flex',
																		alignItems: 'center',
																		marginTop: 27,
																	}}
																	item
																	xs={12}
																	md={3}
																>
																	<SelectEstabelecimento
																		estabelecimentoId={
																			estabelecimento.estabelecimentoId
																		}
																		value={Mask.OnlyNumber(estabelecimento.cpfcnpj)}
																		setEstabelecimento={estabelecimento => {
																			const hasEstabelecimento = Boolean(
																				estabelecimento
																			);
																			const documento = hasEstabelecimento
																				? estabelecimento.documento
																				: '';

																			updateEstabelecimento(
																				key,
																				'cpfcnpj',
																				Mask.OnlyNumber(documento)
																			);
																		}}
																	/>
																</Grid>

																<Grid
																	style={{
																		display: 'flex',
																		alignItems: 'center',
																	}}
																	item
																	xs={12}
																	md={3}
																>
																	{/* <IconButton
																		className={clsx(classes.estabelecimentoIcone, {
																			selected: estabelecimento.tipoSplit === 1,
																		})}
																		onClick={() =>
																			updateEstabelecimento(key, 'tipoSplit', 1)
																		}
																	>
																		<FaDollarSign style={{ fontSize: 14 }} />
																	</IconButton> */}
																	<ZText
																		error={errors[`estabelecimento${key}valor`]}
																		className={clsx(
																			classes.fullWidth,
																			classes.estabelecimentoValor,
																			{
																				porcentagem:
																					estabelecimento.tipoSplit === 2,
																			}
																		)}
																		label={
																			estabelecimento.tipoSplit === 1
																				? 'Valor'
																				: 'Porcentagem'
																		}
																		value={
																			estabelecimento.tipoSplit === 1
																				? Mask.Real(estabelecimento.valor)
																				: Mask.Porcentagem(
																						estabelecimento.valor
																				  )
																		}
																		onChange={(value, setValue) => {
																			value = Mask.OnlyNumber(value) / 100;
																			setValue(
																				estabelecimento.tipoSplit === 1
																					? Mask.Real(value)
																					: Mask.Porcentagem(value)
																			);
																		}}
																		onBlur={e =>
																			updateEstabelecimento(
																				key,
																				'valor',
																				Mask.OnlyNumber(e.target.value) / 100
																			)
																		}
																		onFocus={e => {
																			const length = e.target.value.length * 2;
																			e.target.setSelectionRange(0, length);
																		}}
																	/>
																	<IconButton
																		className={clsx(classes.estabelecimentoIcone, {
																			selected: estabelecimento.tipoSplit === 2,
																		})}
																		onClick={() =>
																			updateEstabelecimento(key, 'tipoSplit', 2)
																		}
																	>
																		<FaPercentage style={{ fontSize: 14 }} />
																	</IconButton>
																</Grid>
																{/* <Grid
																	className={classes.valorLiquido}
																	style={{ display: 'flex', alignItems: 'center' }}
																	item
																	xs={12}
																	md={2}
																>
																	<ZSwitch
																		onChange={() =>
																			updateEstabelecimento(
																				key,
																				'chargeProcessingFee',
																				!estabelecimento.chargeProcessingFee
																			)
																		}
																		label={
																			<div>
																				Split Pelo
																				<br />
																				Valor Líquido?
																			</div>
																		}
																		labelPlacement="top"
																		checked={estabelecimento.chargeProcessingFee}
																	/>
																</Grid> */}
																{key > 0 && (
																	<Grid item lg={1} sm={1} xs={1}>
																		<Fab
																			size="small"
																			className={classes.removeButton}
																			onClick={() =>
																				handleRemoverEstabelecimento(key)
																			}
																		>
																			<RemoveIcon
																				size="small"
																				style={{ fontSize: 15 }}
																			/>
																		</Fab>
																	</Grid>
																)}
															</Grid>
														</Grid>

														{estabelecimento.estabelecimentoId !== null && (
															<React.Fragment>
																<Grid item xs={4}>
																	<div className={classes.label}>
																		{estabelecimento.nome}
																	</div>
																</Grid>
																<Grid item xs={4}>
																	<div className={classes.label}>
																		{estabelecimento.email}
																	</div>
																</Grid>
															</React.Fragment>
														)}

														{Isset(() => errors[`estabelecimento${key}`]) && (
															<Grid item xs={6}>
																<div
																	className={clsx(
																		classes.label,
																		classes.estabelecimentoError
																	)}
																>
																	{errors[`estabelecimento${key}`]}
																</div>
															</Grid>
														)}
													</Grid>
												</Paper>
											);
										})}
									</Grid>
								</Grid>
								<Grid item xs={12} md={6} lg={6} style={{ padding: 20 }}>
									<Grid item xs={12}>
										<Grid container>
											<Grid item xs={12} style={{ marginBottom: 10 }}>
												<Typography variant="h6" gutterBottom>
													Opções do Split
												</Typography>
												<Divider />
											</Grid>

											<Grid item xs={12} style={{ marginTop: 30 }}>
												<Grid container>
													<Grid item xs={12} sm={6} md={6} style={{ marginTop: 17 }}>
														<ZSwitch
															onChange={() =>
																setData({ ...data, limitarData: !data.limitarData })
															}
															label="Limitar por data"
															checked={data.limitarData}
														/>
													</Grid>
													{data.limitarData && (
														<React.Fragment>
															<Grid item xs={6} sm={3} md={3}>
																<ZDatePicker
																	error={errors.dataInicio}
																	textField
																	label="Data Início"
																	minDate={new Date()}
																	date={data.dataInicio}
																	onChange={date =>
																		setData({ ...data, dataInicio: date })
																	}
																/>
															</Grid>
															<Grid item xs={6} sm={3} md={3}>
																<ZDatePicker
																	error={errors.dataFim}
																	textField
																	label="Data Fim"
																	date={
																		data.dataFim !== null
																			? data.dataFim
																			: data.dataInicio
																	}
																	minDate={
																		data.dataInicio !== null
																			? data.dataInicio
																			: new Date()
																	}
																	onChange={date =>
																		setData({ ...data, dataFim: date })
																	}
																/>
															</Grid>
														</React.Fragment>
													)}
												</Grid>
											</Grid>

											<Grid item xs={12} style={{ marginTop: 30 }}>
												<Grid container>
													<Grid item xs={12} sm={6} md={6} style={{ marginTop: 25 }}>
														<ZSwitch
															onChange={() =>
																setData({ ...data, limitarValor: !data.limitarValor })
															}
															label="Limitar por valor"
															checked={data.limitarValor}
														/>
													</Grid>
													{data.limitarValor && (
														<Grid item xs={12} sm={6} md={6}>
															<TextField
																error={errors.valorMaximo}
																className={classes.fullWidth}
																value={Mask.Real(data.valorMaximo)}
																onChange={e =>
																	setData({
																		...data,
																		valorMaximo:
																			Mask.OnlyNumber(e.target.value) / 100,
																	})
																}
																label="Valor máximo"
															/>
															<Grid container item>
																<Grid item xs={6} style={{ fontSize: 12 }}>
																	Valor Pago{' '}
																	<span style={{ fontWeight: 'bold' }}>
																		{Formatter.Real(data.valorPago)}
																	</span>
																</Grid>
																{/* <Grid item xs={6} style={{ fontSize: 12 }}>
																	<Button
																		style={{ fontSize: 10 }}
																		variant="contained"
																	>
																		Resetar Valor Pago
																	</Button>
																</Grid> */}
															</Grid>
														</Grid>
													)}
												</Grid>
											</Grid>

											<Grid item xs={12} style={{ marginTop: 30 }}>
												<Grid container>
													<Grid item xs={12} sm={6} md={6} style={{ marginTop: 25 }}>
														<ZSwitch
															onChange={handleSettarAssumirTaxa}
															label="Assumir a taxa da venda"
															checked={data.assumirTaxa}
														/>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<Grid
									container
									direction="row"
									justifyContent="flex-end"
									alignItems="center"
									style={{ padding: 25 }}
									spacing={3}
								>
									<Grid item xs={6} md={3} lg={2}>
										<ZSaveButton
											onClick={() => {
												setData(initialState);
												setNewSplit(false);
											}}
											color={'secondary'}
											align="right"
											label={'Cancelar'}
											loading={loading}
										/>
									</Grid>
									<Grid item xs={6} md={3} lg={2}>
										<ZSaveButton
											onClick={handleSubmit}
											color={'primary'}
											align="right"
											label={'Salvar'}
											loading={loading}
										/>
									</Grid>
								</Grid>
							</Grid>
						</Paper>
					</Grid>
				</Grid>
			)}

			<ZModalWarning
				title={`Atenção`}
				description={'Selecione um estabelecimento abaixo.'}
				content={
					<div className={classes.modalSelectEstabelecimento}>
						<FormControl className={classes.formControl}>
							<InputLabel id="demo-simple-select-label">Estabelecimentos</InputLabel>
							<Select
								className={classes.SelectEstabelecimento}
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={ecSelected}
								onChange={e => setEcSelected(e.target.value)}
							>
								{ecsOfTheDocument &&
									ecsOfTheDocument.map(e => {
										return (
											<MenuItem key={e.id} value={e.id}>
												{e.nome_fantasia}
											</MenuItem>
										);
									})}
							</Select>
						</FormControl>
					</div>
				}
				open={showModalEstablishmentSelection}
				handleClose={() => setShowModalEstablishmentSelection(false)}
				button={
					<Button onClick={handleSetEstabelecimento} color="primary">
						Confirmar
					</Button>
				}
			/>

			<ZModalConfirm
				title={`Atenção`}
				message={`Ao selecionar a opção "Assumir a taxa de venda" \n você só pode splitar para um estabelecimento`}
				submessage={`Deseja remover os ultimos estabelecimentos selecionados ?`}
				open={confirmRemoveEcs}
				onClose={result => handleConfirmRemoveEcs(result)}
			/>
		</Grid>
	);
};
