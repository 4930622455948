/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Grid, Typography, TextField, Button, makeStyles } from '@material-ui/core';
import axios from 'axios';
import config from 'react-global-configuration';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Mask, Isset, dbDateToDate, ValidarCpf, ValidarCnpj, isWhite } from '../../utils';
import ZLoader from '../system/ZLoader';

//nova Venda Pix
const useStyles = makeStyles(theme => ({
	fullWidth: {
		width: '100%',
	},
	stepActions: {
		marginTop: 60,
		textAlign: 'center',
	},
	backButton: {
		width: '100%',
		height: 44,
		backgroundColor: isWhite(theme.palette.primary.main) ? '#EEEEEE' : theme.palette.primary.main,
		color: isWhite(theme.palette.primary.main)
			? '#606060'
			: theme.palette.getContrastText(theme.palette.primary.main),
		'&:hover': {
			backgroundColor: isWhite(theme.palette.primary.main),
		},
	},
	nextButton: {
		width: 171,
		height: 44,
		backgroundColor: isWhite(theme.palette.primary.main) ? '#EEEEEE' : theme.palette.primary.main,
		color: isWhite(theme.palette.primary.main)
			? '#606060'
			: theme.palette.getContrastText(theme.palette.primary.main),
		'&:hover': {
			backgroundColor: isWhite(theme.palette.primary.main) ? '#ccc' : theme.palette.primary.dark,
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	stepTitle: {
		color: '#3E3B47',
		marginBottom: 48,
	},
	clientes: {
		border: '1px solid #e4e4e4',
		borderRadius: 8,
		overflowY: 'scroll',
		height: 350,
		[theme.breakpoints.down('xs')]: {
			marginBottom: 32,
		},

		'& p': {
			marginLeft: 16,
			marginRight: 16,
			padding: 5,
		},

		'& p:hover': {
			cursor: 'pointer',
			backgroundColor: `rgb(243, 245, 247)`,
			borderRadius: 4,
		},
	},
}));

export default function DadosCliente({ data, setData, errors, changeStep, clientes, showBackButton }) {
	const classes = useStyles();

	const { cpfDoCliente } = useParams();

	const token = useSelector(store => store.auth.token);
	const estabelecimento = useSelector(state => state.auth.estabelecimento);
	const estabelecimentoAdmin = useSelector(state => state.admin.estabelecimento);
	const estabelecimentoId = Isset(() => estabelecimentoAdmin.id) ? estabelecimentoAdmin.id : estabelecimento.id;

	const [cpfCnpjAux, setCpfCnpjAux] = useState(cpfDoCliente || '');
	const [cpfCnpjTypingTimeout, setCpfCnpjTypingTimeout] = useState(null);
	const [errorMessage, setErrorMessage] = useState('');

	const [loadingCliente, setLoadingCliente] = useState(false);

	useEffect(() => {
		if (data.cep && data.cep.length === 8) {
			const getEndereco = async value => {
				if (data.cep.length === 8) {
					var res = await axios.get(`https://viacep.com.br/ws/${data.cep}/json/`);
					if (res.status !== 200) {
						setData({
							...data,

							cep: value,
							logradouro: '',
							cidade: '',
							estado: '',
							complemento: '',
						});
						return false;
					}

					setData({
						...data,
						cep: Mask.OnlyNumber(res.data.cep),
						logradouro: res.data.logradouro,
						cidade: res.data.localidade,
						estado: res.data.uf,
						complemento: res.data.complemento,
						bairro: res.data.bairro,
					});
				}
			};

			getEndereco();
		}
	}, [data.cep]);
	useEffect(() => {
		if (cpfCnpjAux.length === 11 || cpfCnpjAux.length === 14) {
			const getCliente = async () => {
				setLoadingCliente(true);

				setData({
					...data,
					cpf: cpfCnpjAux,
					id: null,
					nome: '',
					email: '',
					dataNascimento: '',
					celular: '',
					logradouro: '',
					numero: '',
					cep: '',
					cidade: '',
					estado: '',
					complemento: '',
					bairro: '',
				});

				const result = await axios.get(`${config.get('apiUrl')}clientes/por_documento/${cpfCnpjAux}`, {
					headers: { Authorization: `Bearer ${token}` },
					params: {
						filters: {
							estabelecimentoId,
						},
					},
				});

				const { success, cliente } = result.data;

				if (success && cliente) {
					const { cliente } = result.data;

					const checkContato =
						typeof cliente.clientes_contatos !== 'undefined' && cliente.clientes_contatos.length > 0;

					let celular;

					if (checkContato) {
						cliente.clientes_contatos.forEach(clienteData => {
							if (clienteData.tipo_contato.id === 2) {
								celular = clienteData.contato;
							}
						});
					}

					setData({
						...data,
						cpf: cpfCnpjAux,
						id: cliente.id,
						nome: cliente.nome,
						email: cliente.email,
						dataNascimento: cliente.data_nascimento !== null ? dbDateToDate(cliente.data_nascimento) : '',
						celular,
						logradouro: cliente.endereco.logradouro,
						numero: cliente.endereco.numero,
						bairro: cliente.endereco.bairro ? cliente.endereco.bairro : '',
						cep: Mask.OnlyNumber(cliente.endereco.cep),
						cidade: cliente.endereco.cidade,
						estado: cliente.endereco.uf,
						complemento: cliente.endereco.complemento || '',
					});
				}

				setLoadingCliente(false);
			};

			getCliente();
		}
	}, [cpfCnpjAux]);

	const handleCpfCnpjChange = e => {
		const value = e.target.value.length <= 11 ? Mask.Cpf(e.target.value) : Mask.Cnpj(e.target.value);
		setData({ ...data, cpf: Mask.OnlyNumber(value) });

		if (cpfCnpjTypingTimeout) {
			clearTimeout(cpfCnpjTypingTimeout);
		}

		const timeout = setTimeout(() => {
			setCpfCnpjAux(Mask.OnlyNumber(value));
		}, 500);

		setCpfCnpjTypingTimeout(timeout);
	};

	const handleValidateCpfCnpj = e => {
		const value = e.target.value.replace(/[^\d]+/g, '');
		const cpfCnpj = value.length <= 11 ? ValidarCpf(value) : ValidarCnpj(value);
		const errorMessage = value.length <= 11 ? 'CPF Inválido' : 'CNPJ Inválido';

		if (!cpfCnpj && value) {
			setErrorMessage(errorMessage);
		} else {
			setErrorMessage('');
		}
	};

	return (
		<>
			<Typography variant="h5" component="h6" className={classes.stepTitle}>
				Dados do Cliente
			</Typography>

			<Grid container>
				<Grid item md={6} lg={4}>
					<Grid container item xs={12} justifyContent="center" style={{ marginBottom: 16 }}>
						<Typography variant="body2" component="h6">
							Últimos clientes
						</Typography>
					</Grid>

					<div className={classes.clientes}>
						{clientes.map(cliente => (
							<p
								key={cliente.id}
								style={{ color: '#777' }}
								onClick={() => {
									setCpfCnpjAux(cliente.clientes_documentos[0].documento);
								}}
							>
								{cliente.nome} -{' '}
								{cliente.clientes_documentos[0].documento.length === 11
									? Mask.Cpf(cliente.clientes_documentos[0].documento)
									: Mask.Cnpj(cliente.clientes_documentos[0].documento)}
							</p>
						))}
					</div>
				</Grid>

				<Grid item md={6} lg={8}>
					<Grid container justifyContent="center" spacing={2}>
						<Grid item xs={12} sm={6} md={4} lg={5}>
							<TextField
								className={classes.fullWidth}
								error={errors.cpf}
								label="CPF / CNPJ"
								value={data.cpf.length <= 11 ? Mask.Cpf(data.cpf) : Mask.Cnpj(data.cpf)}
								onChange={handleCpfCnpjChange}
								onBlur={handleValidateCpfCnpj}
							/>

							{errorMessage && <span style={{ color: '#ff0033', fontSize: 14 }}>{errorMessage}</span>}
						</Grid>
					</Grid>

					{loadingCliente ? (
						<ZLoader height={100} size={20} message="Carregando Cliente" />
					) : (
						<>
							{[11, 14].includes(data.cpf.length) && (
								<React.Fragment>
									<Grid container spacing={2} justifyContent="center">
										<Grid item xs={12} sm={11} md={8}>
											<Grid container spacing={2} justifyContent="center">
												<Grid item xs={12}>
													<Typography
														variant="body2"
														component="h6"
														className={classes.subHeaderTitle}
													>
														Cliente
													</Typography>
												</Grid>

												<Grid item xs={12} sm={6} md={4} lg={6}>
													<TextField
														className={classes.fullWidth}
														error={errors.nome}
														label="Nome"
														value={data.nome}
														onChange={e => setData({ ...data, nome: e.target.value })}
													/>
												</Grid>
												<Grid item xs={12} sm={6} md={3} lg={6}>
													<TextField
														className={classes.fullWidth}
														error={errors.email}
														label="E-mail"
														value={data.email}
														onChange={e => setData({ ...data, email: e.target.value })}
													/>
												</Grid>
												<Grid item xs={12} sm={6} md={2} lg={6}>
													<TextField
														className={classes.fullWidth}
														error={errors.celular}
														label="Celular"
														value={Mask.Celular(data.celular)}
														onChange={e =>
															setData({
																...data,
																celular: Mask.OnlyNumber(Mask.Celular(e.target.value)),
															})
														}
													/>
												</Grid>
												{/* {data.cpf.length === 11 && ( */}
												{/* <> */}
												<Grid item xs={12} sm={6} md={3} lg={6}>
													<TextField
														className={classes.fullWidth}
														error={errors.dataNascimento}
														label={
															data.cpf.length === 11
																? 'Data de Nascimento'
																: 'Criação da Empresa'
														}
														value={Mask.Date(data.dataNascimento)}
														onChange={e =>
															setData({
																...data,
																dataNascimento: Mask.Date(e.target.value),
															})
														}
													/>
												</Grid>
												{/* </> */}
												{/* )} */}
											</Grid>

											<Typography
												variant="body2"
												component="h6"
												style={{ marginTop: 32, marginBottom: 16 }}
											>
												Endereço
											</Typography>

											<Grid container justifyContent="flex-start" spacing={2}>
												<Grid item xs={12} sm={3} lg={6}>
													<TextField
														className={classes.fullWidth}
														error={errors.cep}
														label="CEP"
														value={Mask.Cep(data.cep)}
														onChange={e =>
															setData({
																...data,
																cep: Mask.OnlyNumber(Mask.Cep(e.target.value)),
															})
														}
													/>
												</Grid>
												<Grid item xs={12} sm={4} lg={6}>
													<TextField
														className={classes.fullWidth}
														error={errors.logradouro}
														label="Logradouro"
														value={data.logradouro}
														onChange={e =>
															setData({
																...data,
																logradouro: e.target.value,
															})
														}
													/>
												</Grid>
												<Grid item xs={12} sm={2} lg={2}>
													<TextField
														className={classes.fullWidth}
														error={errors.numero}
														label="Número"
														value={data.numero}
														onChange={e => setData({ ...data, numero: e.target.value })}
													/>
												</Grid>
												<Grid item xs={12} sm={4} lg={4}>
													<TextField
														className={classes.fullWidth}
														label="Complemento"
														value={data.complemento}
														onChange={e =>
															setData({
																...data,
																complemento: e.target.value,
															})
														}
													/>
												</Grid>
												<Grid item xs={12} sm={6} lg={6}>
													<TextField
														className={classes.fullWidth}
														error={errors.bairro}
														label="Bairro"
														value={data.bairro}
														onChange={e => setData({ ...data, bairro: e.target.value })}
													/>
												</Grid>
												<Grid item xs={12} sm={3} lg={3}>
													<TextField
														className={classes.fullWidth}
														error={errors.cidade}
														label="Cidade"
														value={data.cidade}
														onChange={e => setData({ ...data, cidade: e.target.value })}
													/>
												</Grid>
												<Grid item xs={12} sm={2} lg={3}>
													<TextField
														className={classes.fullWidth}
														error={errors.estado}
														label="Estado"
														value={data.estado}
														onChange={e => setData({ ...data, estado: e.target.value })}
													/>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</React.Fragment>
							)}
						</>
					)}
				</Grid>

				<Grid container justifyContent="flex-end" spacing={2} direction="row" className={classes.stepActions}>
					<Grid item xs={12} sm={12} md={6} lg={3}>
						{(showBackButton === null || showBackButton) && (
							<Button className={classes.backButton} fullWidth onClick={() => changeStep(0)}>
								Voltar
							</Button>
						)}
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={3}>
						<Button className={classes.nextButton} fullWidth onClick={() => changeStep(2)}>
							Continuar
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
}
