import React, { useState, useEffect } from 'react';
import { InputBase, withStyles } from '@material-ui/core';
import { Mask } from '../../../utils';

const BootstrapInput = withStyles(theme => ({
	root: {
		'label + &': {
			marginTop: theme.spacing(3),
		},
	},
	input: {
		borderRadius: 4,
		backgroundColor: theme.palette.common.white,
		border: '1px solid #ced4da',
		fontSize: 16,
		height: 15,
		padding: '10px 12px',
		transition: theme.transitions.create(['border-color']),
		fontFamily: [
			'Exo 2',
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(','),
		'&:focus': {
			borderColor: theme.palette.primary.main,
		},
	},
}))(InputBase);

export default function Input(props) {
	const [state, setState] = useState(props.value.defaultValue || '');

	useEffect(() => {
		setState(props.value.defaultValue || '');
	}, [props.value.defaultValue]);

	const onChange = e => {
		const { value } = e.target;
		const str = props.value.mask ? Mask.OnlyNumber(value) : value;

		setState(str);
	};

	const handleMask = key => {
		const value = state.replace(/[^\d]+/g, '');

		const masks = {
			documento: value.length <= 11 ? Mask.Cpf(value) : Mask.Cnpj(value),
			real: Mask.Real(value / 100),
			cpf: Mask.Cpf(value),
			cnpj: Mask.Cnpj(value),
			celular: Mask.Celular(value),
		};

		return masks[key];
	};

	const onBlur = e => {
		const { value } = e.target;

		if (props.onChange) {
			props.onChange(props.value.key, value);
		}
	};

	const onKeyDown = ev => {
		const enter = ev.keyCode === 13;
		const { value } = ev.target;

		if (enter) {
			if (props.onChange) {
				props.onChange(props.value.key, value, enter);
			}
		}
	};

	return (
		<>
			{props.value.modal && <p style={{ fontSize: 14, fontWeight: 'bold' }}>{props.value.placeholder}</p>}

			<BootstrapInput
				size={'small'}
				fullWidth
				onKeyDown={onKeyDown}
				onChange={onChange}
				onBlur={onBlur}
				value={props.value.mask ? handleMask(props.value.mask) : state}
				placeholder={props.value.placeholder}
				variant="outlined"
			/>
		</>
	);
}
