/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import ZLoader from '../system/ZLoader';
import ZTable from '../system/ZTable';
import ZPaginationDiv from '../system/ZPaginationDiv';
import config from 'react-global-configuration';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import { getGrupoPermission, Isset } from '../../utils';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import FileDownload from 'js-file-download';
import { FaDownload } from 'react-icons/fa';
import { addMessage } from '../../store/alert';
import ZFiltros from '../system/ZFiltros';
import { Button, Grid, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import isWithinInterval from 'date-fns/isWithinInterval';
import addMinutes from 'date-fns/addMinutes';
import ZModalWarning from '../system/ZModalWarning';

const useStyles = makeStyles(theme => ({
	tabela: {
		'& .MuiTableRow-root': {
			cursor: 'pointer',
		},
	},
	header: {
		fontWeight: 700,
		fontSize: 20,
		margin: 8,
	},
	iconButton: {
		minWidth: 0,
	},
	buttonExport: {
		color: '#040633',
		border: `solid 1px #040633`,
		'&:hover': {
			color: '#FFF',
			backgroundColor: '#011d29',
			'& svg': {
				color: '#FFF',
			},
		},
		[theme.breakpoints.down('xs')]: {
			width: '90%',
			marginTop: 16,
		},
		'& svg': {
			margin: 0,
		},
	},
	filtros: {
		position: 'relative',
		borderRadius: 20,
		marginBottom: 30,
		border: `solid 1px ${theme.palette.primary.main}67`,
		padding: '5px 10px',
		display: 'flex',
		alignItems: 'center',
	},
	export: {
		position: 'absolute',
		top: -22,
		right: 10,
		opacity: 0.5,
		cursor: 'pointer',
		color: '#464ae6',
		fontSize: 16,
		transition: theme.transitions.create(['all'], {
			duration: theme.transitions.duration.complex,
		}),
		'&:hover': {
			fontSize: 18,
			opacity: 1,
		},
	},
	ImportButton: {
		margin: '8px',
		width: 200,
		backgroundColor: theme.palette.primary.main,
		color: '#FFF',
		'&:hover': {
			color: '#FFF',
			backgroundColor: `${theme.palette.primary.main}`,
		},
	},
}));

export default props => {
	const classes = useStyles();
	const token = useSelector(store => store.auth.token);
	const usuario = useSelector(store => store.auth.usuario);
	const marketplaceId = usuario.marketplace.id;
	const history = useHistory();
	const [planos, setPlanos] = useState([]);
	const [loading, setLoading] = useState(true);
	const dispatch = useDispatch();
	const [showExportMessage, setShowExportMessage] = useState(false);

	const { pathname } = useLocation();
	const pathnameSlug = pathname.toLowerCase().replace(/[^a-z0-9]/gi, '');
	const filtersCache = useSelector(store => store.filters[pathnameSlug] || {});

	const operarMarketplace = useSelector(store =>
		Isset(() => store.system.params['4'].valor) ? store.system.params['4'].valor : null
	);

	const canViewExportar = [1, 7, 10].includes(usuario.grupo_id);

	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(15);
	const [totalRows, setTotalRows] = useState(0);

	const queryFilter = {
		omni: '',
		...filtersCache,
	};

	const [filtros, setFiltros] = useState({
		...queryFilter,
	});

	const [loadingImportPlan, setLoadingImportPlan] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [showButtonImportPlan, setShowButtonImportPlan] = useState(false);

	const onChangePage = newPage => {
		setPage(newPage);
	};

	const onChangeRowsPerPage = rowsPerPage => {
		setRowsPerPage(rowsPerPage);
		setPage(1);
	};

	const filtrosChange = async data => {
		setPage(1);
		setFiltros(data);
	};

	const getPlanos = async () => {
		setLoading(true);
		let res = await axios.get(`${config.get('apiUrl')}planos_de_vendas`, {
			params: {
				limit: rowsPerPage,
				page,
				filtros,
			},
			headers: { Authorization: `Bearer ${token}` },
		});

		if (res.data.success) {
			setPlanos(res.data.planos);
			setTotalRows(res.data.totalRows);
		}

		setLoading(false);
	};

	useEffect(() => {
		getPlanos();
	}, [filtros, page, rowsPerPage]);

	useEffect(() => {
		const usuarioPermission = getGrupoPermission(usuario.grupo_id);
		if(usuario.grupo_id === 2 && usuario.estabelecimento.parent === null){
			setShowButtonImportPlan(true);
		}
		if (usuarioPermission[1] === 'admin' && operarMarketplace) {
			setShowButtonImportPlan(true);
		}
	}, [usuario, operarMarketplace]);

	const exportXls = async () => {
		setShowExportMessage(true);
		const result = await axios.get(`${config.get('apiUrl')}export/getPlanos/`, {
			headers: { Authorization: `Bearer ${token}` },
			responseType: 'blob',
		});

		if (result.status !== 200 || !Isset(() => result.data)) {
			setShowExportMessage(false);
			return dispatch(addMessage({ type: 'error', message: 'Erro ao exportar relatório.' }));
		}

		const { 'content-disposition': contentDisposition, 'content-type': contentType } = result.headers;
		let filename = 'performancePorEstabelecimento.xlsx';
		if (contentDisposition.indexOf('filename="') !== -1) {
			filename = contentDisposition.slice(
				contentDisposition.indexOf('filename="') + 10,
				contentDisposition.length - 1
			);
		}

		FileDownload(result.data, filename, contentType);
		setShowExportMessage(false);
	};

	const handleImportPlans = async () => {
		setLoadingImportPlan(true);

		const dataImportarPlanos = localStorage.getItem('importarVendas');
		const intervalo = isWithinInterval(new Date(), {
			start: new Date(dataImportarPlanos),
			end: addMinutes(new Date(dataImportarPlanos), 5),
		});

		if (!intervalo) {
			let res = await axios.post(
				`${config.get('apiUrl')}planos_de_vendas/${marketplaceId}/importar`,
				{},
				{
					headers: { Authorization: `Bearer ${token}` },
				}
			);
			if (res.data.success) {
				localStorage.setItem('importarVendas', new Date());
				setLoadingImportPlan(false);
				setShowModal(true);
			}
			setLoadingImportPlan(false);
			dispatch(addMessage({ type: 'error', message: res.data.error }));
		}
		setShowModal(true);
		setLoadingImportPlan(false);
	};

	return (
		<div>
			<div className={classes.header}>Planos de Vendas</div>
			<Paper className={classes.tabela}>
				<div style={{ position: 'relative' }}>
					<Paper style={{ marginBottom: 16, padding: '5px 10px' }}>
						<Grid container alignItems="center">
							<Grid item md={8}>
								<ZFiltros
									onChange={filtrosChange}
									input={[
										{
											key: 'omni',
											defaultValue: filtros.omni,
											placeholder: 'Plano de Venda',
											modal: false,
											md: 2,
										},
									]}
								/>
							</Grid>
							<Grid item md={2}>
								{showButtonImportPlan && (
									<Button className={classes.ImportButton} onClick={handleImportPlans}>
										{loadingImportPlan ? <ZLoader height={50} size={25} /> : 'Importar Planos'}
									</Button>
								)}
							</Grid>

							<Grid container item md={1}>
								<Grid item align="end" md={12}>
									{canViewExportar && (
										<Tooltip title="Exportar">
											<Button
												className={clsx(classes.buttonExport, classes.iconButton)}
												onClick={exportXls}
											>
												<FaDownload className={classes.exportDownload} />
											</Button>
										</Tooltip>
									)}
								</Grid>
							</Grid>
						</Grid>
					</Paper>

					{loading ? (
						<ZLoader height={200} />
					) : (
						<>
							<ZTable
								headCells={[
									{ id: 'id', numeric: false, disablePadding: true, label: 'ID' },
									{
										id: 'name',
										numeric: false,
										disablePadding: false,
										label: 'Título',
									},
									{
										id: 'description',
										numeric: false,
										disablePadding: false,
										label: 'Descrição',
									},
									{
										id: 'antecipado',
										numeric: false,
										disablePadding: false,
										label: 'Antecipado',
										filter: value => (value ? 'Sim' : 'Não'),
									},
								]}
								tableTitle=""
								toolBar={false}
								rows={planos}
								rowClick={plano => history.push(`/planos-de-vendas/${plano}`)}
								densePadding={false}
								checkBox={false}
								rowsPerPage={[15, 30, 60]}
								rowsPerPageDefault={rowsPerPage}
								pagination={false}
							/>

							<ZPaginationDiv
								count={totalRows}
								rowsPerPage={rowsPerPage}
								page={page - 1}
								onChangePage={onChangePage}
								onChangeRowsPerPage={onChangeRowsPerPage}
								rowsPerPageOptions={[15, 30, 60]}
							/>
						</>
					)}
				</div>
			</Paper>

			<ZModalWarning
				title={`Atenção`}
				description={'Estamos processando a sua solicitação, em breve você receberá um e-mail de confirmação!'}
				open={showModal}
				handleClose={() => setShowModal(false)}
				button={
					<Button onClick={() => setShowModal(false)} color="primary">
						Ok
					</Button>
				}
			/>

			<Dialog open={showExportMessage}>
				<DialogContent style={{ width: 200 }}>
					<ZLoader height={100} size={26} message={'Exportando'} />
				</DialogContent>
			</Dialog>
		</div>
	);
};
