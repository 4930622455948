/* eslint-disable react-hooks/exhaustive-deps */

import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import config from 'react-global-configuration';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ZLoader from '../system/ZLoader';
import FileDownload from 'js-file-download';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import { Button } from '@material-ui/core';
import { addMessage } from '../../store/alert';
import ZModalConfirm from '../system/ZModalConfirm';

const useStyles = makeStyles(theme => ({
	paper: {
		padding: 10,
	},
	actionButtons: {
		marginTop: 10,
		textAlign: 'center',
	},
}));

export default props => {
	const { usuario } = props;
	const estabelecimentoId = usuario.estabelecimento.id;
	const marketplaceId = usuario.marketplace.id;
	const classes = useStyles();
	const token = useSelector(store => store.auth.token);
	const dispatch = useDispatch();

	const [saving, setSaving] = useState(false);
	const [loading, setLoading] = useState(true);
	const [editor, setEditor] = useState(EditorState.createEmpty());
	const [removerTermos, setRemoverTermos] = useState(false);
	const [disabledRemover, setDisabledRemover] = useState(true);
	const [disabledSalvar, setDisabledSalvar] = useState(true);
	const [showExportMessage, setShowExportMessage] = useState(false);

	const onEditorStateChange = async editorState => {
		const termosHtml = draftToHtml(convertToRaw(editorState.getCurrentContent()));
		if (termosHtml.length > 8) {
			setDisabledSalvar(false);
		} else {
			setDisabledSalvar(true);
		}
		setEditor(editorState);
	};

	const getTermos = async () => {
		setLoading(true);
		const res = await axios.get(`${config.get('apiUrl')}termosCondicoes/por_marketplace/${marketplaceId}`, {
			headers: { Authorization: `Bearer ${token}` },
		});

		if (res.data.success) {
			const convert = convertFromHTML(res.data.termos.termos_condicoes);
			const state = ContentState.createFromBlockArray(convert.contentBlocks, convert.entityMap);
			setEditor(EditorState.createWithContent(state));
			setDisabledRemover(false);
			setDisabledSalvar(false);
		}
		setLoading(false);
	};

	useEffect(() => {
		getTermos();
	}, []);

	const handleSubmit = async () => {
		const termosHtml = draftToHtml(convertToRaw(editor.getCurrentContent()));
		setSaving(true);
		const res = await axios.post(
			`${config.get('apiUrl')}termosCondicoes/por_marketplace/${marketplaceId}`,
			{ termosHtml, estabelecimentoId },
			{
				headers: { Authorization: `Bearer ${token}` },
			}
		);

		if (res.data.success) {
			dispatch(addMessage({ type: 'success', message: 'Termos e condições salvos com sucesso' }));
			setDisabledRemover(false);
		}
		setSaving(false);
	};

	const handleRemover = async () => {
		setSaving(true);
		setRemoverTermos(false);
		setDisabledRemover(true);
		setDisabledSalvar(true);
		const res = await axios.delete(`${config.get('apiUrl')}termosCondicoes/por_marketplace/${marketplaceId}`, {
			headers: { Authorization: `Bearer ${token}` },
		});
		if (res.data.success) {
			dispatch(addMessage({ type: 'success', message: 'Termos e condições removidos com sucesso' }));
			setEditor(false);
		} else {
			dispatch(addMessage({ type: 'error', message: 'Termos e condições não removidos' }));
		}
		setSaving(false);
	};

	const exportXls = async () => {
		setShowExportMessage(true);
		const estabelecimentos = await axios.get(`${config.get('apiUrl')}export/aceite-termos-de-uso`, {
			headers: { Authorization: `Bearer ${token}` },
			responseType: 'blob',
		});

		if (estabelecimentos.status === 200) {
			const { 'content-disposition': contentDisposition, 'content-type': contentType } = estabelecimentos.headers;
			let filename = 'estabelecimentos.xlsx';
			if (contentDisposition.indexOf('filename="') !== -1) {
				filename = contentDisposition.slice(
					contentDisposition.indexOf('filename="') + 10,
					contentDisposition.length - 1
				);
			}

			FileDownload(estabelecimentos.data, filename, contentType);
		} else {
			dispatch(addMessage({ type: 'error', message: 'Erro ao exportar aceites dos termos de uso.' }));
		}
		setShowExportMessage(false);
	};

	if (loading) {
		return <ZLoader height={200} />;
	}

	return (
		<div className={classes.termos}>
			<Grid item container justifyContent="center" alignItems="center">
				<Grid item xs={12} sm={8}>
					<Paper className={classes.paper}>
						<div>
							<Editor
								editorState={editor}
								toolbarClassName="toolbarClassName"
								wrapperClassName="wrapperClassName"
								editorClassName="editorClassName"
								onEditorStateChange={onEditorStateChange}
								placeholder="Digite aqui..."
							/>
						</div>
					</Paper>
				</Grid>
			</Grid>
			<div className={classes.actionButtons}>
				<Button
					style={{ marginRight: 5 }}
					disabled={disabledSalvar || saving}
					variant="contained"
					color="primary"
					onClick={handleSubmit}
				>
					{saving ? <ZLoader size={14} height={24} /> : 'Salvar'}
				</Button>
				<Button
					style={{ marginRight: 5 }}
					disabled={disabledRemover || saving}
					variant="contained"
					color="primary"
					onClick={() => setRemoverTermos(true)}
				>
					{saving ? <ZLoader size={14} height={24} /> : 'Remover'}
				</Button>
				<Button
					style={{ marginRight: 5 }}
					disabled={saving}
					variant="contained"
					color="primary"
					onClick={exportXls}
				>
					{saving ? <ZLoader size={14} height={24} /> : 'Emitir Relatório'}
				</Button>
			</div>
			<ZModalConfirm
				title={`Remover Termos e Condições`}
				message={`Deseja prosseguir com a exclusão dos termos e condições?`}
				open={removerTermos}
				onClose={handleRemover}
			/>
			<Dialog open={showExportMessage}>
				<DialogContent style={{ width: 200 }}>
					<ZLoader height={100} size={26} message={'Exportando'} />
				</DialogContent>
			</Dialog>
			{/* <div>
				<textarea
                	disabled
                	value={draftToHtml(convertToRaw(editor.getCurrentContent()))}
            	></textarea>
			</div> */}
		</div>
	);
};
