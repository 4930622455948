/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Axios from 'axios';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ZLoader from '../system/ZLoader';
import ZFiltros from '../system/ZFiltros';
import { useLocation } from 'react-router-dom';

import { FaDownload } from 'react-icons/fa';
import ZEmpty from '../system/ZEmpty';
import { Isset, Formatter } from '../../utils';
import clsx from 'clsx';
import FileDownload from 'js-file-download';
import { addMessage } from '../../store/alert';

import config from 'react-global-configuration';
import { makeStyles } from '@material-ui/core/styles';
import ZPaginationDiv from '../system/ZPaginationDiv';
import addMonths from 'date-fns/addMonths';
import { Tooltip, Button } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	header: {
		fontWeight: 700,
		fontSize: 20,
		margin: 8,
	},
	export: {
		position: 'absolute',
		right: 10,
		opacity: 0.5,
		cursor: 'pointer',
		color: '#464ae6',
		fontSize: 16,
		transition: theme.transitions.create(['all'], {
			duration: theme.transitions.duration.complex,
		}),
		'&:hover': {
			fontSize: 18,
			opacity: 1,
		},
	},
	exportDownload: {
		color: '#011D29',
		fontSize: 16,
		marginLeft: 8,
	},
	iconButton: {
		minWidth: 0,
	},
	buttonExport: {
		color: '#040633',
		border: `solid 1px #040633`,
		'&:hover': {
			color: '#FFF',
			backgroundColor: '#011d29',
			'& svg': {
				color: '#FFF',
			},
		},
		[theme.breakpoints.down('xs')]: {
			width: '90%',
			marginTop: 16,
		},
		'& svg': {
			margin: 0,
		},
	},

	rowTitle: {
		margin: 0,
		textAlign: 'center',
		fontWeight: 700,
		[theme.breakpoints.down('xs')]: {
			textAlign: 'left',
			marginTop: 24,
		},
	},

	rowText: {
		display: 'inline-block',
		fontWeight: 400,
		marginTop: 8,
	},

	tableRow: {
		fontWeight: 700,
		padding: 5,
		display: 'flex',
		alignItems: 'center',
		borderRadius: 10,
		marginBottom: 2,
		backgroundColor: `${theme.palette.primary.main}1a`,
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}2b`,
		},
	},

	tableRowDanger: {
		fontWeight: 700,
		padding: 5,
		display: 'flex',
		alignItems: 'center',
		borderRadius: 10,
		marginBottom: 2,
		backgroundColor: '#fd2e2e6e',
		'&:hover': {
			backgroundColor: '#fd2e2e79',
		},
	},

	vendas: {
		marginBottom: 10,
	},
}));

export default props => {
	const token = useSelector(store => store.auth.token);
	const classes = useStyles();

	const dispatch = useDispatch();
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [showExportMessage, setShowExportMessage] = useState(false);
	const { pathname } = useLocation();
	const pathnameSlug = pathname.toLowerCase().replace(/[^a-z0-9]/gi, '');

	const filtersCache = useSelector(store => store.filters[pathnameSlug] || {});

	const queryFilter = {
		startDate: addMonths(new Date(), -1),
		endDate: new Date(),
		...filtersCache,
	};

	const [filtros, setFiltros] = useState(queryFilter);
	const filtrosChange = async data => {
		const f = {
			startDate: data.date.start,
			endDate: data.date.end,
			omni: data.omni,
		};

		setFiltros(f);
	};

	// Paginação

	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(100);
	const [totalRows, setTotalRows] = useState(0);

	const onChangePage = newPage => {
		setPage(newPage);
	};

	const onChangeRowsPerPage = rowsPerPage => {
		setRowsPerPage(rowsPerPage);
		setPage(1);
	};

	useEffect(() => {
		const getChargeback = async () => {
			setLoading(true);
			const res = await Axios.get(`${config.get('apiUrl')}relatorios/chargebackPorEstabelecimento`, {
				params: {
					...filtros,
					limit: rowsPerPage,
					page,
				},
				headers: { Authorization: `Bearer ${token}` },
			});

			if (res.data.success) {
				setData(res.data.result);
				setTotalRows(res.data.totalRows);
			}
			setLoading(false);
		};

		getChargeback();
	}, [filtros, page, rowsPerPage]);

	const exportXls = async () => {
		setShowExportMessage(true);
		const result = await Axios.get(`${config.get('apiUrl')}export/relatorios/chargebackPorEstabelecimento`, {
			headers: { Authorization: `Bearer ${token}` },
			responseType: 'blob',
			params: {
				...filtros,
			},
		});

		if (result.status !== 200 || !Isset(() => result.data)) {
			setShowExportMessage(false);
			return dispatch(addMessage({ type: 'error', message: 'Erro ao exportar relatório.' }));
		}

		const { 'content-disposition': contentDisposition, 'content-type': contentType } = result.headers;
		let filename = 'chargebackPorEstabelecimento.xlsx';
		if (contentDisposition.indexOf('filename="') !== -1) {
			filename = contentDisposition.slice(
				contentDisposition.indexOf('filename="') + 10,
				contentDisposition.length - 1
			);
		}

		FileDownload(result.data, filename, contentType);
		setShowExportMessage(false);
	};

	return (
		<>
			<Grid className={classes.vendas}>
				<div className={classes.header}>Chargeback Por Estabelecimento</div>

				<Paper
					style={{
						position: 'relative',
						marginBottom: 10,
						borderRadius: 4,
						padding: '5px 10px',
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<ZFiltros
						onChange={filtrosChange}
						dateRange={{
							default: 'custom',
							startDate: filtros.startDate,
							endDate: filtros.endDate,
						}}
						input={[
							{
								key: 'omni',
								modal: false,
								md: 2,
								defaultValue: filtros.omni,
								placeholder: 'Estabelecimento',
							},
						]}
					/>
					<Tooltip title="Exportar">
						<Button className={clsx(classes.buttonExport, classes.iconButton)} onClick={exportXls}>
							<FaDownload className={classes.exportDownload} />
						</Button>
					</Tooltip>
				</Paper>

				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Paper style={{ padding: 15 }}>
							{loading ? (
								<ZLoader height={200} />
							) : (
								<>
									{data.length > 0 ? (
										<Grid container className={classes.vendas}>
											{data.map((estabelecimento, key) => (
												<React.Fragment key={key}>
													<Grid item xs={12} sm={12} style={{ margin: '8px 0' }}>
														<Paper
															className={
																estabelecimento.percentChargeback > 5
																	? classes.tableRowDanger
																	: classes.tableRow
															}
														>
															<Grid container style={{ padding: 20 }}>
																<Grid item xs={12} sm={6} md={3}>
																	<p className={classes.rowTitle}>
																		Estabelecimento <br />
																		<span className={classes.rowText}>
																			{estabelecimento.nomeFantasia}{' '}
																		</span>
																	</p>
																</Grid>
																<Grid item xs={12} sm={6} md={3}>
																	<p className={classes.rowTitle}>
																		Total Vendas <br />
																		<span className={classes.rowText}>
																			{Formatter.Real(
																				estabelecimento.valorTotalVendas
																			)}
																		</span>
																	</p>
																</Grid>
																<Grid item xs={12} sm={6} md={3}>
																	<p className={classes.rowTitle}>
																		Total Chargeback <br />{' '}
																		<span className={classes.rowText}>
																			{Formatter.Real(
																				estabelecimento.valorTotalChargeback
																			)}
																		</span>
																	</p>
																</Grid>
																<Grid item xs={12} sm={6} md={3}>
																	<p className={classes.rowTitle}>
																		Chargeback <br />
																		<span className={classes.rowText}>
																			{estabelecimento.percentChargeback}%
																		</span>
																	</p>
																</Grid>
															</Grid>
														</Paper>
													</Grid>
												</React.Fragment>
											))}
										</Grid>
									) : (
										<ZEmpty height={200} text="Nenhum Resultado Encontrado." />
									)}
								</>
							)}
						</Paper>

						<ZPaginationDiv
							count={totalRows}
							rowsPerPage={rowsPerPage}
							page={page - 1}
							onChangePage={onChangePage}
							onChangeRowsPerPage={onChangeRowsPerPage}
							rowsPerPageOptions={[50, 100, 200]}
						/>
					</Grid>
				</Grid>
			</Grid>

			<Dialog open={showExportMessage}>
				<DialogContent style={{ width: 200 }}>
					<ZLoader height={100} size={26} message={'Exportando'} />
				</DialogContent>
			</Dialog>
		</>
	);
};
