import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Paper, Grid, makeStyles, Typography, Button, Backdrop, CircularProgress } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import config from 'react-global-configuration';
import { useHistory, useParams } from 'react-router-dom';
import format from 'date-fns/format';

import ZDatePicker from '../system/ZDatePicker';
import ZTitle from '../system/ZTitle';
import ZSelect from '../system/ZSelect';
import ZText from '../system/ZText';
import ZLoader from '../system/ZLoader';
import { Mask, Isset } from '../../utils';
import { addMessage } from '../../store/alert';

const styles = makeStyles(theme => ({
	layout: {
		width: 'auto',
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
		[theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
			width: 960,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	paper: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
		padding: theme.spacing(2),
		[theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
			marginTop: theme.spacing(6),
			marginBottom: theme.spacing(6),
			padding: theme.spacing(3),
		},
	},
	text: {
		fontSize: 16,
		color: '#777',
	},
	label: {
		fontWeight: 700,
	},
	divider: {
		marginTop: 48,
		marginBottom: 32,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
}));

export default () => {
	const classes = styles();

	const history = useHistory();
	const { estabelecimentoId, cobrancaId } = useParams();
	const dispatch = useDispatch();
	const token = useSelector(store => store.auth.token);

	const [dateTime, setDateTime] = useState(new Date());
	const [loading, setLoading] = useState({
		backdrop: false,
		zloader: false,
	});
	const [estabelecimento, setEstabelecimento] = useState({
		nome: '',
		documento: '',
	});
	const [errors, setErrors] = useState({});
	const [tipoCobranca, setTipoCobranca] = useState([]);
	const [cobrancaDescricao, setCobrancaDescricao] = useState('');
	const [data, setData] = useState({
		estabelecimentoId,
		tipo_cobranca_recorrente_id: 1,
		valor: 0,
		meta: 0,
		meta_nao_batida: 0,
		meta_batida: 0,
		day: '',
		descricao: '',
		data_inicial: format(dateTime, 'DD/MM/YYYY'),
	});

	useEffect(() => {
		getEC(estabelecimentoId);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getTipoCobranca();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (cobrancaId) {
			getData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const { estabelecimentoId } = data;

		if (estabelecimentoId) {
			getEC(estabelecimentoId);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data.estabelecimentoId]);

	const getTipoCobranca = useCallback(async () => {
		const response = await axios.get(`${config.get('apiUrl')}cobranca_recorrente/tipos`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		const [{ description }] = response.data.tipos.filter(cobranca => cobranca.id === 1);

		setCobrancaDescricao(description);
		setTipoCobranca(response.data.tipos);
	}, [token]);

	const getEC = useCallback(
		async estabelecimentoId => {
			const response = await axios.get(`${config.get('apiUrl')}estabelecimentos/${estabelecimentoId}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			if (response.data.success && response.data.estabelecimento) {
				const {
					nome_fantasia: nome,
					estabelecimentos_documentos: [{ documento }],
				} = response.data.estabelecimento;

				setEstabelecimento(estabelecimento => ({ ...estabelecimento, nome, documento }));
			}
		},
		[token]
	);

	const validate = useCallback(() => {
		const prop = {};
		const tipoCobranca = data.tipo_cobranca_recorrente_id === 1 || data.tipo_cobranca_recorrente_id === 2;

		if (data.valor === 0 && !tipoCobranca) {
			prop.valor = true;
		}

		if (tipoCobranca) {
			if (!data.meta) {
				prop.meta = true;
			}

			// console.log(data.meta_batida);
			// if (!data.meta_batida && ) {
			// 	prop.meta_batida = true;
			// }
			if (!data.meta_nao_batida) {
				prop.meta_nao_batida = true;
			}
		}

		setErrors(prop);

		return Object.keys(prop).length > 0;
	}, [data]);

	const getData = useCallback(async () => {
		setLoading(loading => ({ ...loading, zloader: true }));

		const response = await axios.get(`${config.get('apiUrl')}cobranca_recorrente/${cobrancaId}`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		if (response.data.success && response.data.cobranca) {
			const {
				day,
				valor,
				meta,
				meta_batida,
				meta_nao_batida,
				descricao,
				tipo_cobranca_recorrente_id,
				estabelecimento_id: estabelecimentoId,
				data_inicial,
			} = response.data.cobranca;

			setDateTime(data_inicial);

			setData(data => ({
				...data,
				estabelecimentoId,
				day,
				valor,
				meta,
				meta_batida,
				meta_nao_batida,
				descricao,
				tipo_cobranca_recorrente_id,
				data_inicial,
			}));

			setLoading(loading => ({ ...loading, zloader: false }));
		}
	}, [cobrancaId, token]);

	const handleChange = useCallback(
		name => event => {
			const { value } = event.target;

			setData(data => ({ ...data, [name]: value }));
		},
		[]
	);

	const handleValidateDay = useCallback(
		name => event => {
			const { value } = event.target;

			if (name === 'day' && /^\d{2}$/.test(value)) {
				const diasNoMes = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
				const diaEhValido = value > 0 && value <= diasNoMes[11 - 1];

				if (!diaEhValido) {
					dispatch(addMessage({ type: 'info', message: 'Dia da cobrança não pode ultrapassar 30 dias' }));

					setErrors(errors => ({ ...errors, day: true }));
					return;
				}

				setErrors({});
				setData(data => ({ ...data, [name]: value }));
			} else {
				dispatch(addMessage({ type: 'info', message: 'Dia da cobrança precisa ter 2 digitos' }));

				setErrors(errors => ({ ...errors, day: true }));
			}
		},
		[dispatch]
	);

	const handleSubmit = useCallback(async () => {
		if (!validate()) {
			let response;

			setLoading(loading => ({ ...loading, backdrop: true }));

			if (cobrancaId) {
				setLoading(loading => ({ ...loading, backdrop: true }));

				data.meta = String(data.meta / 100);
				data.meta_batida = String(data.meta_batida / 100);
				data.meta_nao_batida = String(data.meta_nao_batida / 100);
				data.valor = String(data.valor / 100);

				response = await axios.put(`${config.get('apiUrl')}cobranca_recorrente/${cobrancaId}`, data, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
			} else {
				setLoading(loading => ({ ...loading, backdrop: true }));

				response = await axios.post(`${config.get('apiUrl')}cobranca_recorrente`, data, {
					headers: { Authorization: `Bearer ${token}` },
				});
			}

			if (response.data.success) {
				dispatch(addMessage({ type: 'success', message: 'Operação realizada com sucesso' }));

				setLoading(loading => ({ ...loading, backdrop: false }));

				history.push(`/estabelecimentos/${estabelecimentoId || data.estabelecimentoId}/cobranca_recorrente`);
			} else {
				dispatch(addMessage({ type: 'error', message: response.data.error }));

				setLoading(loading => ({ ...loading, backdrop: false }));
			}
		}
	}, [data, token, dispatch, history, estabelecimentoId, cobrancaId, validate]);

	const cpfOuCnpj = useMemo(() => {
		if (Isset(() => estabelecimento.documento) && estabelecimento.documento) {
			return estabelecimento.documento.length <= 11
				? Mask.Cpf(estabelecimento.documento)
				: Mask.Cnpj(estabelecimento.documento);
		}

		return '';
	}, [estabelecimento]);

	const tipoDaCobranca = useMemo(() => {
		return tipoCobranca.map(({ id, name }) => ({
			id,
			name,
		}));
	}, [tipoCobranca]);

	return (
		<div className={classes.layout}>
			<ZTitle title={`${cobrancaId ? 'Editar' : 'Cadastrar'} Taxa`} />

			<Backdrop className={classes.backdrop} open={loading.backdrop}>
				<CircularProgress color="inherit" />
			</Backdrop>

			<Paper className={classes.paper}>
				<Typography variant="h5" gutterBottom>
					{estabelecimento.nome} <br /> <span className={classes.text}>{cpfOuCnpj}</span>
				</Typography>

				{loading.zloader ? (
					<ZLoader height={100} size={20} />
				) : (
					<>
						<Grid item xs={12} md={4} className={classes.divider}>
							<ZSelect
								options={tipoDaCobranca}
								value={data.tipo_cobranca_recorrente_id}
								width="100%"
								disabled={cobrancaId ? true : false}
								onChange={e => {
									setData({
										...data,
										tipo_cobranca_recorrente_id: e.target.value,
										valor: 0,
										meta: 0,
										meta_nao_batida: 0,
										meta_batida: 0,
										day: '',
										descricao: '',
									});

									const [{ description }] = tipoCobranca.filter(
										cobranca => cobranca.id === e.target.value
									);

									setCobrancaDescricao(description);

									setErrors({});
								}}
							/>
						</Grid>

						{cobrancaDescricao && (
							<Grid xs={12} style={{ marginBottom: 32 }}>
								<Typography style={{ color: '#777', whiteSpace: 'pre-line' }} variant="body1">
									{cobrancaDescricao}
								</Typography>
							</Grid>
						)}

						<Grid container spacing={2}>
							<Grid item xs={12} sm={12} md={12}>
								{data.tipo_cobranca_recorrente_id === 1 || data.tipo_cobranca_recorrente_id === 2 ? (
									<Grid container spacing={2}>
										<Grid item xs={12} sm={6} md={4} lg={4}>
											<ZText
												fullWidth
												label="Meta"
												margin="normal"
												realMask
												error={errors.meta}
												required={true}
												value={data.meta / 100}
												onBlur={e => {
													setData({
														...data,
														meta: Math.round(e.target.value * 100),
													});
												}}
											/>
										</Grid>
										<Grid item xs={12} sm={6} md={4} lg={4}>
											<ZText
												fullWidth
												label="Valor ao atingir a meta"
												margin="normal"
												realMask
												error={errors.meta_batida}
												required={true}
												value={data.meta_batida / 100}
												onBlur={e => {
													setData({
														...data,
														meta_batida: Math.round(e.target.value * 100),
													});
												}}
											/>
										</Grid>
										<Grid item xs={12} sm={6} md={4} lg={4}>
											<ZText
												fullWidth
												label="Valor se não atingir a meta"
												margin="normal"
												realMask
												error={errors.meta_nao_batida}
												required={true}
												value={data.meta_nao_batida / 100}
												onBlur={e => {
													setData({
														...data,
														meta_nao_batida: Math.round(e.target.value * 100),
													});
												}}
											/>
										</Grid>
										<Grid item xs={12} sm={6} md={4} lg={4}>
											<ZText
												fullWidth
												error={errors.day}
												label="Dia de cobrança"
												value={data.day}
												required={true}
												margin="normal"
												onBlur={handleValidateDay('day')}
											/>
										</Grid>
										<Grid item xs={12} sm={6} md={4} lg={4} style={{ marginTop: 16 }}>
											<ZDatePicker
												textField
												margin="normal"
												error={errors.dateTime}
												label="Data inicial da cobrança"
												date={dateTime}
												minDate={new Date()}
												onChange={date => {
													setDateTime(date);
													setData({ ...data, data_inicial: format(date, 'DD/MM/YYYY') });
												}}
											/>
										</Grid>
									</Grid>
								) : (
									<Grid container spacing={2}>
										<Grid item xs={12} sm={4} lg={4}>
											<ZText
												fullWidth
												label="Valor"
												margin="normal"
												realMask
												error={errors.valor}
												required={true}
												placeholder="Valor a ser pago"
												value={data.valor / 100}
												onBlur={e => {
													setData({
														...data,
														valor: Math.round(e.target.value * 100),
													});
												}}
											/>
										</Grid>
										<Grid item xs={12} sm={3} lg={4}>
											<ZText
												label="Descrição"
												value={data.descricao}
												fullWidth
												onBlur={handleChange('descricao')}
											/>
										</Grid>
										<Grid item xs={12} sm={2} lg={4}>
											<ZText
												fullWidth
												label="Dia de cobrança"
												value={data.day}
												error={errors.day}
												required={true}
												margin="normal"
												onBlur={handleValidateDay('day')}
											/>
										</Grid>
										<Grid item xs={12} sm={6} md={4} lg={4} style={{ marginTop: 16 }}>
											<ZDatePicker
												textField
												margin="normal"
												label="Data inicial da cobrança"
												error={errors.dateTime}
												date={dateTime}
												minDate={new Date()}
												onChange={date => {
													setDateTime(date);

													setData({ ...data, data_inicial: format(date, 'DD/MM/YYYY') });
												}}
											/>
										</Grid>
									</Grid>
								)}
							</Grid>
						</Grid>
					</>
				)}

				<Grid container justifyContent="flex-end" style={{ marginTop: 32 }}>
					<Grid item xs={12} sm={12} md={6} lg={3}>
						<Button onClick={handleSubmit} variant="contained" color="primary" fullWidth>
							Salvar
						</Button>
					</Grid>
				</Grid>
			</Paper>
		</div>
	);
};
