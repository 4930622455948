import React, { useState, useEffect } from 'react';
import { ResponsiveLine } from 'nivo';
import { makeStyles } from '@material-ui/core/styles';
import { Formatter } from '../../../utils';
import moment from 'moment';

const colors = ['#ea6061', '#005797', '#ffa550'];

const useStyles = makeStyles(theme => ({
	chart: {
		height: 120,
		width: '100%',
		transform: 'translateY(50%)',
	},
}));

export default props => {
	const classes = useStyles();
	const [data, setData] = useState([]);

	useEffect(() => {
		const d = [];
		const dates = [];
		const keys = Object.keys(props.data).filter(a => ['entrada', 'saida'].includes(a));

		keys.forEach((key, i) => {
			const b = props.data[key];
			b.forEach(a => {
				if (dates.indexOf(a.dia_full) === -1) {
					dates.push(a.dia_full);
				}
			});
		});

		dates.sort();

		keys.forEach((key, i) => {
			const b = props.data[key];
			if (b.length > 0) {
				d.push({
					id: key[0].toUpperCase() + key.slice(1),
					color: colors[i],
					data: dates.map(c => {
						const dia = moment(c).format('DD/MM');
						const d = b.find(v => v.dia_full === c);
						let val = 0;
						if (d) {
							val = +d.valorTotal;
						}
						return { x: dia, y: val };
					}),
				});
			}
		});

		setData(d);
	}, [props.data]);

	return (
		<div className={classes.chart}>
			{data.length > 0 && (
				<ResponsiveLine
					data={data}
					margin={{ top: 20, right: 20, bottom: 30, left: 20 }}
					xScale={{ type: 'point' }}
					yScale={{
						type: 'log',
						base: 10,
						max: 'auto',
					}}
					curve="natural"
					enableGridX={false}
					enableGridY={false}
					colors={colors}
					pointColor={{ theme: 'background' }}
					pointBorderWidth={2}
					pointBorderColor={{ from: 'serieColor', modifiers: [] }}
					tooltipFormat={value => Formatter.Real(value)}
					enableArea={true}
					areaOpacity={0.2}
					useMesh={true}
					axisLeft={null}
					motionStiffness={160}
				/>
			)}
		</div>
	);
};
