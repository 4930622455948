/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import Badge from '@material-ui/core/Badge';
import MenuIcon from '@material-ui/icons/Menu';
// import NotificationsIcon from '@material-ui/icons/Notifications';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MoreIcon from '@material-ui/icons/MoreVert';
import { Link, useHistory } from 'react-router-dom';
import { logout } from '../../store/auth';
import { useDispatch } from 'react-redux';
import { Isset, Mask } from '../../utils';
import { setEstabelecimento } from '../../store/admin';
import { getConfig, updateSaldo, resetFilters, setOnePayComunicado } from '../../store/system';
import axios from 'axios';
import config from 'react-global-configuration';
import { useSetRecoilState, useRecoilState } from 'recoil';
import { estabelecimentoIdState, authTokenState } from '../../recoil/atoms';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(theme => ({
	grow: {
		flexGrow: 1,
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	title: {
		fontFamily: "'Exo 2', sans-serif",
		fontSize: 12,
		lineHeight: 1.2,
		[theme.breakpoints.up('sm')]: {
			display: 'block',
			fontSize: 20,
		},
	},
	helloUser: {
		fontFamily: "'Exo 2', sans-serif",
		fontSize: 14,
		lineHeight: 1.3,
		[theme.breakpoints.up('sm')]: {
			display: 'block',
			fontSize: 15,
		},
	},
	inputRoot: {
		color: 'inherit',
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 7),
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: 200,
		},
	},
	sectionDesktop: {
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
		},
	},
	sectionMobile: {
		display: 'flex',
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
	linkStyle: {
		color: 'black',
		textDecoration: 'none',
	},
	avatar: {
		cursor: 'pointer',
		margin: 4,
		marginRight: 15,
		width: 140,
		maxHeight: 50,
		objectFit: 'contain',
		[theme.breakpoints.down('sm')]: {
			width: 50,
			marginRight: 10,
		},
	},
	clearBtn: {
		cursor: 'pointer',
		marginLeft: 6,
	},
	selectEstabelecimento: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	estabelecimentoSelectLogo: {
		width: 50,
		height: 50,
		borderRadius: '50%',
	},
	estabelecimentoSelect: {
		marginLeft: 25,
		fontWeight: 700,
		fontSize: 12,
		color: theme.palette.getContrastText(theme.palette.primary.main),
		'&:hover': {
			'&:before': {
				borderBottom: 'none !important',
			},
		},
		'&:before': {
			borderBottom: 'none !important',
		},
		'& .MuiSelect-select': {
			padding: '2px 10px !important',
			marginRight: 10,
		},
		'& .MuiSelect-icon': {
			color: theme.palette.getContrastText(theme.palette.primary.main),
		},
		'& .MuiInputLabel-root': {
			color: theme.palette.getContrastText(theme.palette.primary.main),
			fontSize: 14,
		},
		'& .MuiInputLabel-outlined': {
			transform: 'translate(14px, 14px) scale(1)',
			'&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
				transform: 'translate(14px, -6px) scale(0.75)',
			},
		},
		'& .MuiOutlinedInput-root': {
			color: theme.palette.getContrastText(theme.palette.primary.main),
			padding: 0,
		},
		'& .MuiAutocomplete-input': {
			flexGrow: 0,
			width: 250,
		},
		'& .MuiSvgIcon-root': {
			color: theme.palette.getContrastText(theme.palette.primary.main),
		},
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: theme.palette.getContrastText(theme.palette.primary.main),
		},
	},
	estabelecimentoOption: {
		flexDirection: 'column',
	},
	estabelecimentoNome: {
		width: '100%',
		padding: 0,
		lineHeight: '1.25em',
	},
	estabelecimentoDocumento: {
		width: '100%',
		padding: 0,
		lineHeight: '1.25em',
		fontSize: 10,
	},
	menuDivider: {
		textAlign: 'center',
		fontVariant: 'small-caps',
		fontWeight: 'bold',
		fontSize: 18,
		margin: 4,
		paddingTop: 10,
		'&:not(:first-child)': {
			borderTop: 'solid 1px #eaeaea',
		},
		'&:focus': {
			outline: 'none',
		},
	},
}));

export default props => {
	const dispatch = useDispatch();
	const history = useHistory();

	const token = useSelector(state => state.auth.token);
	const usuario = useSelector(state => state.auth.usuario);
	const estabelecimento = useSelector(state => state.auth.estabelecimento);
	let logo = useSelector(state => state.system.logo);
	if (!logo) {
		logo = estabelecimento.marketplace.configuracoes.marketplace_logo;
	}
	const operarMarketplace = useSelector(store =>
		Isset(() => store.system.params['4'].valor) ? store.system.params['4'].valor : null
	);

	const canViewSelectEC = [1, 7, 10, 12].includes(usuario.grupo_id) || (usuario.grupo_id === 2 && operarMarketplace);

	const classes = useStyles();
	const [estabelecimentos, setEstabelecimentos] = React.useState([]);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

	const setAuthTokenState = useSetRecoilState(authTokenState);
	const [estabelecimentoId, setEstabelecimentoIdState] = useRecoilState(estabelecimentoIdState);

	if (!estabelecimentoId) {
		setEstabelecimentoIdState(estabelecimento.id);
	}

	const isMenuOpen = Boolean(anchorEl);
	const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

	const handleProfileMenuOpen = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleMobileMenuClose = () => {
		setMobileMoreAnchorEl(null);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
		handleMobileMenuClose();
	};

	const handleMobileMenuOpen = event => {
		setMobileMoreAnchorEl(event.currentTarget);
	};

	const logoutClick = async () => {
		setEstabelecimentoIdState(null);
		setAuthTokenState(null);
		await dispatch(logout());
		dispatch(setOnePayComunicado(true));
		history.push('/');
		window.location.reload();
		handleMenuClose();
	};

	const menuId = 'primary-search-account-menu';
	// console.log(usuario.grupo_id);
	const renderMenu = (
		<Menu
			anchorEl={anchorEl}
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			id={menuId}
			keepMounted
			transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			open={isMenuOpen}
			onClose={handleMenuClose}
		>
			{(usuario.grupo_id === 1 || usuario.grupo_id === 10) && [
				<div key="menuMarketplace" className={classes.menuDivider}>
					Marketplace
				</div>,
				<MenuItem key="menuMarketplaceConfiguracoes" onClick={handleMenuClose}>
					<Link className={classes.linkStyle} to="/marketplace/configuracoes">
						Configurações
					</Link>
				</MenuItem>,
			]}
			{[1, 2, 3, 10].includes(usuario.grupo_id) && [
				<div key="menuEstabelecimento" className={classes.menuDivider}>
					Estabelecimento
				</div>,
				<MenuItem key="menuEstabelecimentoConfiguracoes" onClick={handleMenuClose}>
					<Link className={classes.linkStyle} to="/estabelecimentos/configuracoes">
						Configurações
					</Link>
				</MenuItem>,
			]}
			<div className={classes.menuDivider}>{usuario.nome.split(' ')[0]}</div>
			<MenuItem onClick={handleMenuClose}>
				<Link className={classes.linkStyle} to="/configuracoes">
					Alterar Senha
				</Link>
			</MenuItem>
			<MenuItem onClick={logoutClick}>
				<div className={classes.linkStyle}>Logout</div>
			</MenuItem>
		</Menu>
	);

	const mobileMenuId = 'primary-search-account-menu-mobile';
	const renderMobileMenu = (
		<Menu
			anchorEl={mobileMoreAnchorEl}
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			id={mobileMenuId}
			keepMounted
			transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			open={isMobileMenuOpen}
			onClose={handleMobileMenuClose}
		>
			{usuario.grupo_id === 1 && [
				<div key="menuMarketplace" className={classes.menuDivider}>
					Marketplace
				</div>,
				<MenuItem key="menuMarketplaceConfiguracoes" onClick={handleMenuClose}>
					<Link className={classes.linkStyle} to="/marketplace/configuracoes">
						Configurações
					</Link>
				</MenuItem>,
			]}
			{[1, 2, 3, 10].includes(usuario.grupo_id) && [
				<div key="menuEstabelecimento" className={classes.menuDivider}>
					Estabelecimento
				</div>,
				<MenuItem key="menuEstabelecimentoConfiguracoes" onClick={handleMenuClose}>
					<Link className={classes.linkStyle} to="/estabelecimentos/configuracoes">
						Configurações
					</Link>
				</MenuItem>,
			]}
			<div className={classes.menuDivider}>{usuario.nome.split(' ')[0]}</div>
			<MenuItem onClick={handleMenuClose}>
				<Link className={classes.linkStyle} to="/configuracoes">
					Configurações
				</Link>
			</MenuItem>
			<MenuItem onClick={logoutClick}>
				<div className={classes.linkStyle}>Logout</div>
			</MenuItem>
		</Menu>
	);

	const estabelecimentoChange = async e => {
		const value = e.target.value;
		dispatch(resetFilters());
		await dispatch(setEstabelecimento(value));
		dispatch(getConfig(value));
		dispatch(updateSaldo(true));

		setEstabelecimentoIdState(value);
		// const current = location.pathname;
		// setTimeout(() => {
		// 	history.replace('/reload');
		// 	setTimeout(() => history.replace(current));
		// }, 200);
	};

	const estabelecimentoClear = async e => {
		// e.stopPropagation();
		dispatch(resetFilters());
		await dispatch(setEstabelecimento(null));
		dispatch(getConfig(usuario.estabelecimento.id));
		dispatch(updateSaldo(true));

		setEstabelecimentoIdState(estabelecimento.id);
		// const current = location.pathname;
		// setTimeout(() => {
		// 	history.replace('/reload');
		// 	setTimeout(() => history.replace(current));
		// }, 200);
	};

	useEffect(() => {
		const getEstabelecimentos = async () => {
			const res = await axios.post(
				`${config.get('apiUrl')}graphql`,
				{
					query: `
					query Estabelecimentos {
						estabelecimentos {
							id, nomeFantasia, documento, logo
						}
					}
				`,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			if (res.status === 200 && Isset(() => res.data.data.estabelecimentos)) {
				setEstabelecimentos(res.data.data.estabelecimentos);
			}
		};

		getEstabelecimentos();
	}, []);

	return (
		<div className={classes.grow}>
			<AppBar position="static">
				<Toolbar>
					<IconButton
						edge="start"
						className={props.propsClassName}
						color="inherit"
						aria-label="Open drawer"
						onClick={props.propsOnClick}
					>
						<MenuIcon />
					</IconButton>
					{logo && (
						<img
							alt="Logo Estabelecimento"
							src={logo}
							className={classes.avatar}
							onClick={() => history.push('/')}
						/>
					)}
					<div
						style={{ cursor: 'pointer', width: isMobile ? '50%' : '265px' }}
						onClick={() => history.push('/')}
					>
						<Typography className={classes.helloUser} variant="h6" noWrap>
							Olá, {usuario.nome}
						</Typography>
						<Typography className={classes.title} variant="h6" noWrap>
							{usuario.grupo_id === 1 ? estabelecimento.marketplace.nome : estabelecimento.nomeFantasia}
						</Typography>
					</div>

					{canViewSelectEC && !isMobile && (
						<div className={classes.selectEstabelecimento}>
							<Autocomplete
								className={classes.estabelecimentoSelect}
								options={estabelecimentos}
								getOptionLabel={option =>
									option.nomeFantasia +
									` ` +
									(option.documento.length <= 11
										? Mask.Cpf(option.documento)
										: Mask.Cnpj(option.documento))
								}
								onChange={(a, value) => {
									const event = { target: { value: estabelecimento.id } };
									if (Isset(() => value.id)) {
										event.target.value = value.id;
										estabelecimentoChange(event);
									} else {
										estabelecimentoClear();
									}
								}}
								style={{ width: 300 }}
								renderInput={params => (
									<TextField {...params} label={<em>Estabelecimento</em>} variant="outlined" />
								)}
							/>
							{/* <Select
								className={classes.estabelecimentoSelect}
								value={Isset(() => adminEstabelecimento.id) ? adminEstabelecimento.id : ''}
								onChange={estabelecimentoChange}
								displayEmpty
								renderValue={value => {
									if (!value) {
										return windowWidth > 700 ? <em>Estabelecimento</em> : <FaUserTie />;
									}

									const ec = estabelecimentos.find(a => +a.id === +value);

									if (ec) {
										if (windowWidth > 700) {
											return (
												<>
													<div className={classes.estabelecimentoNome}>{ec.nomeFantasia}</div>
													<div className={classes.estabelecimentoDocumento}>
														{ec.documento.length <= 11
															? Formatter.Cpf(ec.documento)
															: Formatter.Cnpj(ec.documento)}
													</div>
												</>
											);
										} else if (ec.logo) {
											return (
												<img
													alt="Logo EC"
													src={ec.logo}
													className={classes.estabelecimentoSelectLogo}
												/>
											);
										} else {
											return (
												<div>
													{ec.nomeFantasia
														.split(' ')
														.splice(0, 2)
														.map(a => a[0])}
												</div>
											);
										}
									}

									return value;
								}}
							>
								<MenuItem value="">
									{windowWidth > 700 ? <em>Estabelecimento</em> : <FaUserTie />}
								</MenuItem>
								{estabelecimentos.map((estabelecimento, key) => (
									<MenuItem
										className={classes.estabelecimentoOption}
										key={key}
										value={estabelecimento.id}
									>
										<div className={classes.estabelecimentoNome}>
											{estabelecimento.nomeFantasia}
										</div>
										<div className={classes.estabelecimentoDocumento}>
											{estabelecimento.documento.length <= 11
												? Formatter.Cpf(estabelecimento.documento)
												: Formatter.Cnpj(estabelecimento.documento)}
										</div>
									</MenuItem>
								))}
							</Select>
							{Isset(() => adminEstabelecimento.id) && (
								<FaTimes className={classes.clearBtn} onClick={estabelecimentoClear} />
							)} */}
						</div>
					)}

					<div className={classes.grow} />
					<div className={classes.sectionDesktop}>
						{/* <IconButton aria-label="Show 17 new notifications" color="inherit">
							<Badge badgeContent={17} color="secondary">
								<NotificationsIcon />
							</Badge>
						</IconButton> */}
						<IconButton
							edge="end"
							aria-label="Account of current user"
							aria-controls={menuId}
							aria-haspopup="true"
							onClick={handleProfileMenuOpen}
							color="inherit"
						>
							<AccountCircle />
						</IconButton>
					</div>
					<div className={classes.sectionMobile}>
						<IconButton
							aria-label="Show more"
							aria-controls={mobileMenuId}
							aria-haspopup="true"
							onClick={handleMobileMenuOpen}
							color="inherit"
						>
							<MoreIcon />
						</IconButton>
					</div>
				</Toolbar>
				{canViewSelectEC && isMobile && (
					<div className={classes.selectEstabelecimento}>
						<Autocomplete
							className={classes.estabelecimentoSelect}
							options={estabelecimentos}
							getOptionLabel={option =>
								option.nomeFantasia +
								` ` +
								(option.documento.length <= 11
									? Mask.Cpf(option.documento)
									: Mask.Cnpj(option.documento))
							}
							onChange={(a, value) => {
								const event = { target: { value: estabelecimento.id } };
								if (Isset(() => value.id)) {
									event.target.value = value.id;
									estabelecimentoChange(event);
								} else {
									estabelecimentoClear();
								}
							}}
							style={{ width: 300 }}
							renderInput={params => (
								<TextField {...params} label={<em>Estabelecimento</em>} variant="outlined" />
							)}
						/>
					</div>
				)}
			</AppBar>
			{renderMobileMenu}
			{renderMenu}
		</div>
	);
};
