import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ZPagination from '../system/ZPagination';
import ZMenuOptions from '../system/ZMenuOptions';

import axios from 'axios';
import moment from 'moment';
import config from 'react-global-configuration';
import { addMessage } from '../../store/alert';

import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ZTitle from '../system/ZTitle';
import { Mask } from '../../utils';
import ZFiltros from '../system/ZFiltros';
import ZModalConfirm from '../system/ZModalConfirm';

const StatusPreCadastro = {
	0: 'Aguardando',
	1: 'Reprovado',
	2: 'Aprovado',
	5: 'Lead',
};

export default props => {
	const history = useHistory();

	const token = useSelector(store => store.auth.token);
	const user = useSelector(store => store.auth.usuario);

	const { id: estabelecimentoId } = useSelector(state => state.admin.estabelecimento) || {};
	// const [loading, setLoading] = useState(true);
	const dispatch = useDispatch();

	const [removerPreCadastro, setRemoverPreCadastro] = useState({
		estabelecimentoId: null,
		open: false,
	});

	const getOptions = () => {
		if (user.grupo_id === 5) {
			return {
				statusPreCadastro: [
					{
						id: '5',
						name: 'Lead',
					},
				],
			};
		} else {
			return {
				statusPreCadastro: [
					{
						id: '0',
						name: 'Aguardando',
					},
					{
						id: '1',
						name: 'Reprovado',
					},
					{
						id: '2',
						name: 'Aprovado',
					},
					{
						id: '5',
						name: 'Lead',
					},
				],
			};
		}
	};

	const getData = async () => {
		let res = await axios.get(`${config.get('apiUrl')}estabelecimentos/pre_cadastro`, {
			headers: { Authorization: `Bearer ${token}` },
			params: {
				estabelecimentoId,
				page,
				limit: rowsPerPage,
				filters: {
					status: filtros.statusPreCadastro,
				},
			},
		});

		if (!res.data.success) return false;

		setData({
			paginas: res.data.paginas,
			pre_cadastro: res.data.pre_cadastros,
			quantidade: res.data.quantidade,
			totalRows: res.data.quantidade,
		});
	};

	const onChangePage = newPage => {
		setPage(newPage);
	};

	const onChangeRowsPerPage = rowsPerPage => {
		setRowsPerPage(rowsPerPage);
		setPage(1);
	};

	const [data, setData] = useState({
		pre_cadastro: [],
		quantiade: 0,
		paginas: 0,
		totalRows: 0,
	});
	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(15);
	// const [totalRows, setTotalRows] = useState(0);
	const [filtros, setFiltros] = useState({
		statusPreCadastro: user.grupo_id === 5 ? 5 : [0, 5],
	});

	useEffect(() => {
		getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, rowsPerPage, filtros]);

	useEffect(() => {
		getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [estabelecimentoId]);

	const filtrosChange = data => {
		setPage(1);
		setFiltros({
			...filtros,
			...data,
		});
	};

	const handleRemoverPreCadastro = result => {
		const estabelecimentoId = removerPreCadastro.estabelecimentoId;
		setRemoverPreCadastro({ ...removerPreCadastro, open: false, estabelecimentoId: null });

		if (result) {
			// setLoading(true);
			axios
				.delete(`${config.get('apiUrl')}estabelecimentos/pre_cadastro/${estabelecimentoId}`, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				})
				.then(res => res.data)
				.then(data => {
					if (data.success) {
						dispatch(addMessage({ type: 'success', message: 'Pré-cadastro removido com sucesso' }));
						getData();
					}
					// setLoading(false);
				});
		}
	};

	return (
		<Grid>
			<ZTitle title="Pré Cadastro" />
			<Grid container>
				<Grid item xs={12}>
					<Paper
						style={{
							position: 'relative',
							marginBottom: 10,
							borderRadius: 4,
							padding: '5px 10px',
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<ZFiltros
							onChange={filtrosChange}
							selectField={[
								{
									key: 'statusPreCadastro',
									defaultValue: filtros.statusPreCadastro,
									placeholder: 'Status',
									multiple: false,
									options: getOptions().statusPreCadastro,
									modal: false,
									md: 2,
								},
							]}
						/>
					</Paper>
				</Grid>
			</Grid>

			<Grid container>
				<Grid item xs={12}>
					<Paper style={{ overflowX: 'auto', width: 'calc(100vw - 30px)' }}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell align="right">Usuário</TableCell>
									<TableCell align="right">Nome</TableCell>
									<TableCell align="right">E-mail</TableCell>
									<TableCell align="right">CPF/CNPJ</TableCell>
									<TableCell align="right">Telefone</TableCell>
									<TableCell align="right">Celular</TableCell>
									<TableCell align="right">Data</TableCell>
									<TableCell align="right">Status</TableCell>
									<TableCell align="right">Opções</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{data.pre_cadastro.map((value, key) => {
									return (
										<TableRow key={key}>
											<TableCell component="th" scope="row">
												{value.usuarioNome}
											</TableCell>
											<TableCell align="right">
												{value.nome_fantasia}
												{value.razao_social && (
													<div>
														<small>{value.razao_social}</small>
													</div>
												)}
											</TableCell>
											<TableCell align="right">{value.email}</TableCell>
											<TableCell align="right">
												{value.documento.length > 11
													? Mask.Cnpj(value.documento)
													: Mask.Cpf(value.documento)}
											</TableCell>
											<TableCell align="right">{Mask.Telefone(value.telefone || '')}</TableCell>
											<TableCell align="right">{Mask.Celular(value.celular || '')}</TableCell>
											<TableCell align="right">
												{moment(value.created).format('DD/MM/YYYY HH:mm:ss')}
											</TableCell>
											<TableCell align="right">{StatusPreCadastro[value.status]}</TableCell>
											<TableCell>
												<ZMenuOptions
													data={[
														{
															title: 'Visualizar',
															onClick: () =>
																history.push(
																	'/estabelecimentos/pre-cadastro/visualizar/' +
																	value.id
																),
															visible: value.status === 0,
														},
														{
															title: 'Analisar',
															onClick: () =>
																history.push(
																	'/estabelecimentos/pre-cadastro/analisar/' +
																	value.id
																),
															visible: value.status === 0,
														},
														{
															title: 'Completar Cadastro',
															onClick: () => {
																return history.push(
																	'/estabelecimentos/pre-cadastro/completar/' +
																	value.id
																);
															},
															visible: value.status === 5,
														},
														{
															title: 'Remover',
															onClick: () => {
																setRemoverPreCadastro({
																	...removerPreCadastro,
																	estabelecimentoId: value.id,
																	open: true,
																});
															},
															// visible: value.status === 0,
														},
													]}
												/>
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
							<ZPagination
								count={data.totalRows}
								rowsPerPage={rowsPerPage}
								page={page - 1}
								onChangePage={onChangePage}
								onChangeRowsPerPage={onChangeRowsPerPage}
							/>
						</Table>
					</Paper>
				</Grid>
			</Grid>
			<ZModalConfirm
				title={`Remover Pré-cadastro`}
				message={`Deseja prosseguir com a exclusão do pré-cadastro?`}
				open={removerPreCadastro.open}
				onClose={handleRemoverPreCadastro}
			/>
			{/* <ZModalStatus
				title="Alterar Status"
				label="Status"
				options={[
					{ id: 0, name: 'Aguardando' },
					{ id: 1, name: 'Reprovado' },
					{ id: 2, name: 'Aprovado' },
				]}
				description="Selecione o status do pré cadastro."
				open={dataModal.open}
				value={dataModal.status}
				handleClose={() => handleModalClose()}
				handleOnSave={() => handleOnSave()}
				handleOnChange={handleOnChangeStatus}
			/> */}
		</Grid>
	);
};
