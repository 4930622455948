import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import FilterIcon from '@material-ui/icons/FilterList';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import useReactRouter from 'use-react-router';
import { useSelector } from 'react-redux';

import { format } from 'date-fns';

import ZDateRangePicker from '../system/ZDateRangePicker';
import config from 'react-global-configuration';

import { Formatter } from '../../utils';

const useStyles = makeStyles(theme => ({
    minhasVendas: {
        fontFamily: "'Exo 2', sans-serif",
    },
    header: {
        fontWeight: 700,
        fontSize: 20,
        margin: 8,
    },
    subHeader: {
        fontWeight: 600,
        fontSize: 16,
    },
    paper: {
        padding: 10
    },
    filtros: {
        display: 'flex',
        alignItems: 'center',
        padding: 8
    },
    resumoVendas: {
        marginTop: 10
    },
    ultimaVendasGrid: {
        padding: 10,
        display: 'block',
    },
    resumoVendasTipos: {
        borderLeft: '1px solid rgba(0,0,0,0.2)',
        padding: 10,
        display: 'block',
    },
    gridHeader: {
        textAlign: 'center',
        fontWeight: 700,
        color: 'rgba(0,0,0,0.4)',
        height: 30,
    },
    ultimasVendasRow: {
        textAlign: 'center',
        fontWeight: 700,
        height: 30,
        display: 'flex',
        alignItems: 'center',
        borderRadius: 20,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: `${theme.palette.primary.main}2b`
        }
    },
    resumoVendasTiposRow: {
        textAlign: 'center',
        fontWeight: 700,
        height: 24,
        display: 'flex',
        alignItems: 'center',
        borderRadius: 10,
        '&:hover': {
            backgroundColor: `${theme.palette.primary.main}2b`
        }
    },
    progress: {
        height: 8,
        borderRadius: 6,
        backgroundColor: `${theme.palette.primary.main}87`,
        '& .MuiLinearProgress-bar': {
            backgroundColor: `${theme.palette.primary.main}FF`
        }
    },
    valores: {
        padding: 10,
        margin: 4,
        '& .title': {
            fontSize: 12,
        },
        '& .value': {
            fontSize: 18,
            fontWeight: 'bold'
        }
    },
    ultimasVendas: {
        marginTop: 10,
    },
    empty: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 100,
    },
}));

export default props => {
    const classes = useStyles();

    const { history } = useReactRouter();
    const token = useSelector(state => state.auth.token);

    const [ultimasVendas, setUltimasVendas] = useState([]);
    const [resumoVendas, setResumoVendas] = useState({
        valorTotal: 0,
        valorLiquido: 0,
        quantidade: 0,
        tiposVendas: []
    });

    useEffect(() => {
        const getUltimasVendas = async () => {
            const vendas = await axios.get(`${config.get('apiUrl')}vendas`, {
                headers: { Authorization: `Bearer ${token}` }
            });
    
            if(vendas.data.success){
                setUltimasVendas(vendas.data.vendas);
            }
        }
        const getResumoVendas = async () => {
            const vendas = await axios.get(`${config.get('apiUrl')}vendas/resumo`, {
                headers: { Authorization: `Bearer ${token}` }
            });
    
            if(vendas.data.success){
                setResumoVendas(vendas.data.resumo);
            }
        }
        getUltimasVendas();
        getResumoVendas();
    }, [token]);

    const handleRangePickerClose = async (range) => {
        const vendas = await axios.get(`${config.get('apiUrl')}vendas/resumo`, {
            headers: { Authorization: `Bearer ${token}` },
            params: {
                de: format(range.startDate, 'YYYY-MM-DD'),
                ate: format(range.endDate, 'YYYY-MM-DD'),
            }
        });

        if (vendas.data.success) {
            setResumoVendas(vendas.data.resumo);
        }
    }

    const viewVenda = vendaId => {
        history.push(`/vendas/detalhes/${vendaId}`);
    }

    return (
        <div className={clsx(classes.minhasVendas)}>
            <div className={clsx(classes.header)}>Minhas Vendas</div>
            
            <Paper className={clsx(classes.paper)}>
                <div className={clsx(classes.subHeader)}>Resumo de Vendas</div>

                <div className={classes.filtros}>
                    <FilterIcon /> Filtros
                    <ZDateRangePicker onClose={handleRangePickerClose} maxDate={new Date()} />
                </div>
                
                <Divider />

                <Grid container className={classes.resumoVendas}>
                    <Grid item xs={3}>
                        <div className={classes.valores}>
                            <div className="title">Valor de Vendas</div>
                            <div className="value">{Formatter.Real(resumoVendas.valorTotal)}</div>
                        </div>
                        <div className={classes.valores}>
                            <div className="title">Valor Total Líquido</div>
                            <div className="value">{Formatter.Real(resumoVendas.valorLiquido)}</div>
                        </div>
                        <div className={classes.valores}>
                            <div className="title">Quantidade</div>
                            <div className="value">{resumoVendas.quantidade}</div>
                        </div>
                    </Grid>
                    <Grid container item xs={9} className={classes.resumoVendasTipos}>
                        <Grid container item className={classes.gridHeader}>
                            <Grid item xs>Tipo de Venda</Grid>
                            <Grid item xs>Nº de Vendas</Grid>
                            <Grid item xs>Total</Grid>
                            <Grid item xs>Média</Grid>
                            <Grid item xs={3}>Porcentagem das Vendas</Grid>
                        </Grid>

                        {resumoVendas.tiposVendas.length > 0 ? (
                            <>
                                {resumoVendas.tiposVendas.map((tipo, key) => (
                                    <Grid key={key} container item className={classes.resumoVendasTiposRow}>
                                        <Grid item xs>{tipo.tipo}</Grid>
                                        <Grid item xs>{tipo.quantidade}</Grid>
                                        <Grid item xs>{Formatter.Real(tipo.total)}</Grid>
                                        <Grid item xs>{Formatter.Real(tipo.total / tipo.quantidade)}</Grid>
                                        <Grid item xs={3}><LinearProgress className={classes.progress} color="secondary" variant="determinate" value={+tipo.porcentagem} /></Grid>
                                    </Grid>
                                ))}
                            </>
                        ) : (
                            <div className={classes.empty}>
                                Nenhuma venda encontrada
                            </div>
                        )}
                    </Grid>
                </Grid>
            </Paper>

            <Paper className={clsx(classes.paper, classes.ultimasVendas)}>
                <div className={clsx(classes.subHeader)}>Últimas Vendas</div>

                <Grid container item className={classes.ultimaVendasGrid}>
                    <Grid container item className={classes.gridHeader}>
                        <Grid item xs>Data</Grid>
                        <Grid item xs>Status</Grid>
                        <Grid item xs>Tipo de Venda</Grid>
                        <Grid item xs>Valor da Venda</Grid>
                        <Grid item xs>Taxa</Grid>
                        <Grid item xs>Valor Líquido</Grid>
                    </Grid>

                    {ultimasVendas.map((venda, key) => (
                        <Grid key={key} container item className={classes.ultimasVendasRow} onClick={() => viewVenda(venda.id)}>
                            <Grid item xs>{format(venda.created, 'DD/MM/YYYY HH:mm:ss')}</Grid>
                            <Grid item xs>{venda.status.titulo}</Grid>
                            <Grid item xs>{venda.tipoPagamento.titulo}</Grid>
                            <Grid item xs>{Formatter.Real(venda.valores.total)}</Grid>
                            <Grid item xs>{Formatter.Real(venda.valores.taxa)}</Grid>
                            <Grid item xs>{Formatter.Real(venda.valores.liquido)}</Grid>
                        </Grid>
                    ))}
                </Grid>
            </Paper>
        </div>
    )
};