/* eslint-disable eqeqeq */
import CurrencyFormatter from 'currency-formatter';
import React from 'react';
import StringMask from 'string-mask';
import Validator from 'validator';

export const trimChar = (string, charToRemove) => {
	if (!Array.isArray(charToRemove)) charToRemove = [charToRemove];

	while (charToRemove.includes(string.charAt(0))) {
		string = string.substring(1);
	}

	while (charToRemove.includes(string.charAt(string.length - 1))) {
		string = string.substring(0, string.length - 1);
	}

	return string;
};

export const Formatter = {
	nl2br: (str, is_xhtml) => {
		// http://kevin.vanzonneveld.net
		// +   original by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
		// +   improved by: Philip Peterson
		// +   improved by: Onno Marsman
		// +   improved by: Atli Þór
		// +   bugfixed by: Onno Marsman
		// +      input by: Brett Zamir (http://brett-zamir.me)
		// +   bugfixed by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
		// +   improved by: Brett Zamir (http://brett-zamir.me)
		// +   improved by: Maximusya
		// *     example 1: nl2br('Kevin\nvan\nZonneveld');
		// *     returns 1: 'Kevin<br />\nvan<br />\nZonneveld'
		// *     example 2: nl2br("\nOne\nTwo\n\nThree\n", false);
		// *     returns 2: '<br>\nOne<br>\nTwo<br>\n<br>\nThree<br>\n'
		// *     example 3: nl2br("\nOne\nTwo\n\nThree\n", true);
		// *     returns 3: '<br />\nOne<br />\nTwo<br />\n<br />\nThree<br />\n'
		var breakTag = is_xhtml || typeof is_xhtml === 'undefined' ? '<br />' : '<br>'; // Adjust comment to avoid issue on phpjs.org display

		return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
	},
	Real: (v, currency) => {
		v = +v;

		var money = CurrencyFormatter.format(v, { code: 'BRL' });

		if (currency === false) {
			return money
				.replace(/\s/g, '')
				.replace('/ /g', ' ')
				.replace('R$', '');
		}

		money.replace('R$', 'R$ ');

		return money;
	},
	Cpf: v => {
		if (!v) return '';

		return v.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
	},
	Cnpj: v => {
		if (!v) return '';

		return v.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
	},
	Telefone: v => {
		if (!v) return '';

		return v.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
	},
	Celular: v => {
		if (!v) return '';

		return v.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
	},
	Cartao: v => {
		return v.replace(/(\d{4})(\d{4})(\d{4})(\d{4})/, '$1 $2 $3 $4');
	},
	Valor: (number, decimals, dec_point, thousands_sep) => {
		var n = number,
			prec = decimals;
		n = !isFinite(+n) ? 0 : +n;
		prec = !isFinite(+prec) ? 0 : Math.abs(prec);
		var sep = typeof thousands_sep == 'undefined' ? ',' : thousands_sep;
		var dec = typeof dec_point == 'undefined' ? '.' : dec_point;

		var s = prec > 0 ? n.toFixed(prec) : Math.round(n).toFixed(prec); //fix for IE parseFloat(0.55).toFixed(0) = 0;

		var abs = Math.abs(n).toFixed(prec);
		var _, i;

		if (abs >= 1000) {
			_ = abs.split(/\D/);
			i = _[0].length % 3 || 3;

			_[0] = s.slice(0, i + (n < 0)) + _[0].slice(i).replace(/(\d{3})/g, sep + '$1');

			s = _.join(dec);
		} else {
			s = s.replace('.', dec);
		}

		return s;
	},
	Pluralize: (nome, quantidade) => (quantidade > 1 ? `${nome}s` : nome),
};

export const Mask = {
	OnlyNumber: v => {
		return v.replace(/\D/g, '');
	},
	Cartao: v => {
		var firstDigits = v.substring(0, 2);
		var specialCard = ['30', '38', '36', '34', '37', '20', '21'];
		// console.log(specialCard.indexOf(firstDigits));
		var mask = new StringMask('0000 0000 0000 0000');
		if (specialCard.indexOf(firstDigits) !== -1) {
			mask = new StringMask('0000 000000 00000');
		}

		v = v.replace(/\D/g, '');
		return mask.apply(v).trim();
	},
	ValidadeCartao: v => {
		const mask = new StringMask('00 / 00');
		v = v.replace(/\D/g, '');
		return trimChar(mask.apply(v), [' ', '/']);
	},
	Real: v => Formatter.Real(v),
	Porcentagem: v => Formatter.Real(v, false),
	Cep: v => {
		if (!v) {
			return '';
		}
		const mask = new StringMask('00000-000');
		v = v.replace(/\D/g, '');
		return trimChar(mask.apply(v).trim(), ['-']);
	},
	Cpf: v => {
		const mask = new StringMask('000.000.000-00');
		v = v.replace(/\D/g, '');
		return trimChar(mask.apply(v).trim(), ['.', '-']);
	},
	Cnpj: v => {
		const mask = new StringMask('00.000.000/0000-00');
		v = v.replace(/\D/g, '');
		return trimChar(mask.apply(v).trim(), ['.', '/', '-']);
	},
	Telefone: v => {
		const mask = new StringMask('(00) 0000-0000');
		v = v.replace(/\D/g, '');
		return trimChar(mask.apply(v).trim(), [')', ' ', '-']);
	},
	Celular: v => {
		if (!v) {
			return '';
		}
		const mask = new StringMask('(00) 00000-0000');
		v = v.replace(/\D/g, '');
		return trimChar(mask.apply(v).trim(), [')', ' ', '-']);
	},
	Date: v => {
		const mask = new StringMask('00/00/0000');
		v = v.replace(/\D/g, '');
		return trimChar(mask.apply(v).trim(), ['/']);
	},
	DBDate: v => {
		const mask = new StringMask('0000-00-00');
		v = v.replace(/\D/g, '');
		return trimChar(mask.apply(v).trim(), ['-']);
	},
	Boleto: v => {
		const mask = new StringMask('00000.00000 00000.000000 00000.000000 0 00000000000000');
		v = v.replace(/\D/g, '');
		return trimChar(mask.apply(v).trim(), ['.', '/', '-']);
	},
};

export const Pad = {
	Left: (value, length, str) => {
		return Array(length - String(value).length + 1).join(str || '0') + value;
	},
};

export const fileToBase64 = file => {
	return new Promise(resolve => {
		var reader = new FileReader();
		// Read file content on file loaded event
		reader.onload = function(event) {
			resolve(event.target.result);
		};

		// Convert data to base64
		reader.readAsDataURL(file);
	});
};

export const Isset = accessor => {
	try {
		return typeof accessor() !== 'undefined';
	} catch (e) {
		return false;
	}
};

export const delayUnmounting = Component => {
	return class extends React.Component {
		state = {
			shouldRender: this.props.isMounted,
		};

		componentDidUpdate(prevProps) {
			if (prevProps.isMounted && !this.props.isMounted) {
				setTimeout(() => this.setState({ shouldRender: false }), this.props.delayTime);
			} else if (!prevProps.isMounted && this.props.isMounted) {
				this.setState({ shouldRender: true });
			}
		}

		render() {
			return this.state.shouldRender ? <Component {...this.props} /> : null;
		}
	};
};

export const ValidarCpf = cpf => {
	cpf = cpf.replace(/[^\d]+/g, '');
	if (cpf === '') return false;
	// Elimina CPFs invalidos conhecidos
	if (
		cpf.length !== 11 ||
		cpf === '00000000000' ||
		cpf === '11111111111' ||
		cpf === '22222222222' ||
		cpf === '33333333333' ||
		cpf === '44444444444' ||
		cpf === '55555555555' ||
		cpf === '66666666666' ||
		cpf === '77777777777' ||
		cpf === '88888888888' ||
		cpf === '99999999999'
	)
		return false;
	// Valida 1o digito
	let i = 0;
	let rev = 0;
	let add = 0;
	for (i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
	rev = 11 - (add % 11);
	if (rev === 10 || rev === 11) rev = 0;
	if (rev !== parseInt(cpf.charAt(9))) return false;
	// Valida 2o digito
	add = 0;
	for (i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
	rev = 11 - (add % 11);
	if (rev === 10 || rev === 11) rev = 0;
	if (rev !== parseInt(cpf.charAt(10))) return false;
	return true;
};

export const ValidarCnpj = cnpj => {
	let b = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

	if ((cnpj = cnpj.replace(/[^\d]/g, '')).length != 14) return false;

	if (/0{14}/.test(cnpj)) return false;

	let i = 0;
	let n = 0;

	for (i = 0, n = 0; i < 12; n += cnpj[i] * b[++i]);
	if (cnpj[12] != ((n %= 11) < 2 ? 0 : 11 - n)) return false;

	for (i = 0, n = 0; i <= 12; n += cnpj[i] * b[i++]);
	if (cnpj[13] != ((n %= 11) < 2 ? 0 : 11 - n)) return false;

	return true;
};

export const ValidatorForm = (rules, data) => {
	try {
		Object.keys(rules).map(objectName => {
			if (typeof data[objectName] === 'object') {
				ValidatorForm(rules[objectName], data[objectName]);
			} else {
				Object.keys(rules[objectName]).map(value => {
					if (value === 'required') {
						if (data[objectName] === '') throw new Error('Campo vazio ' + objectName);
						if (data[objectName] === 0 && objectName === 'faturamento_estimado')
							throw new Error('Campo vazio' + objectName);
					}

					if (value === 'min' && parseFloat(rules[objectName][value]) > parseFloat(data[objectName])) {
						throw new Error('Valor menor que o permitido');
					}

					if (value === 'email' && !Validator.isEmail(data[objectName])) {
						throw new Error('Email inválido');
					}

					return value;
				});
			}

			return objectName;
		});
	} catch (error) {
		throw error;
	}

	return true;
};

export const dateToDbDate = date => {
	const d = date.split('/');
	const dia = d[0] || '';
	const mes = d[1] || '';
	const ano = d[2] || '';
	return `${ano}-${mes}-${dia}`;
};

export const dbDateToDate = date => {
	if (date instanceof Date) {
		date = date.toISOString().split('T')[0];
	} else {
		date = `${date}`.split('T')[0];
	}

	const d = date.split('-');
	const dia = d[2] || '';
	const mes = d[1] || '';
	const ano = d[0] || '';
	return `${dia}/${mes}/${ano}`;
};

export const getGrupoPermission = grupoId => {
	if (!grupoId) {
		return [];
	}
	const retorno = ['all'];
	switch (grupoId) {
		case 1:
			retorno.push('admin');
			break;
		case 2:
			retorno.push('gerencial');
			break;
		case 3:
			retorno.push('basico');
			break;
		case 4:
			retorno.push('financeiro');
			break;
		case 5:
			retorno.push('representante');
			break;
		case 7:
			retorno.push('backoffice');
			break;
		case 8:
			retorno.push('representanteBasico');
			break;
		case 10:
			retorno.push('moderador');
			break;
		case 11:
			retorno.push('perfilRisco');
			break;
		case 12:
			retorno.push('atendimento');
			break;
		case 13:
			retorno.push('financeiroElo7');
			break;
		case 14:
			retorno.push('financeiroGerencial');
			break;
		case 15:
			retorno.push('riscoGerencial');
			break;
		default:
	}

	return retorno;
};

export const fallbackCopyTextToClipboard = text => {
	var textArea = document.createElement('textarea');
	textArea.value = text;

	// Avoid scrolling to bottom
	textArea.style.top = '0';
	textArea.style.left = '0';
	textArea.style.position = 'fixed';

	document.body.appendChild(textArea);
	textArea.focus();
	textArea.select();

	try {
		var successful = document.execCommand('copy');
		var msg = successful ? 'successful' : 'unsuccessful';
		console.log('Fallback: Copying text command was ' + msg);
	} catch (err) {
		console.error('Fallback: Oops, unable to copy', err);
	}

	document.body.removeChild(textArea);
};

export const isWhite = str => {
	var whiteLimit = 200,
		r,
		g,
		b;

	r = parseInt('0x' + str.substring(1, 3));
	g = parseInt('0x' + str.substring(3, 5));
	b = parseInt('0x' + str.substring(5, 7));
	if (r < whiteLimit || b < whiteLimit || g < whiteLimit) {
		return false;
	}
	return true;
};

export const validatePassword = (password, confirmPassword) => {
	var numeros = /([0-9])/;
	var alfabetoa = /([a-z])/;
	var alfabetoA = /([A-Z])/;
	var chEspeciais = /([~,!,@,#,$,%,^,&,*,-,_,+,=,?,>,<])/;

	let errors = [];

	if (password === '' || confirmPassword === '') {
		errors.push('Preencha todos os campos. ');
	}

	if (password.length < 8) {
		errors.push('A senha precisa ter no mínimo 8 digitos. ');
	}

	if (password !== confirmPassword) {
		errors.push('As senhas não coincidem. ');
	}

	if (!password.match(numeros)) {
		errors.push('As senhas devem conter pelo menos um números. ');
	}

	if (!password.match(alfabetoA)) {
		errors.push('As senhas devem conter pelo menos uma letra maiúscula. ');
	}

	if (!password.match(alfabetoa)) {
		errors.push('As senhas devem conter pelo menos uma letra minúscula. ');
	}

	if (!password.match(chEspeciais)) {
		errors.push('As senhas devem conter pelo menos um caractere especial. ');
	}

	if (errors.length > 0) {
		return { success: false, errors: errors };
	} else {
		return { success: true, errors: false };
	}
};
