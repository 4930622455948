import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ZText from './ZText';

export default function ZModal(props) {
	const [open, setOpen] = React.useState(props.open || false);
	const [disabled, setDisabled] = React.useState(false);

	function copyToClipBoard() {
		let copyText = props.value.map(item => item.value)[1];
		if (!copyText) {
			copyText = props.value.map(item => item.value)[0];
		}

		if (props.title === 'Token Estabelecimento') {
			copyText = props.value.map(item => item.value)[2];
		}
		if (navigator.clipboard) {
			navigator.clipboard.writeText(copyText).then(
				() => {
					console.log('copy success');
				},
				error => {
					console.log("Error: " + error);
					document.execCommand('copy');
				}
			);
		} else {
			console.log(document.execCommand('copy'));
			document.execCommand('copy');
		}
	}

	function handleClose() {
		props.handleClose(false);
		setOpen(false);
	}

	React.useEffect(() => {
		setOpen(props.open);
		setDisabled(true);
	}, [props]);

	return (
		<div>
			<Dialog
				fullWidth={true}
				maxWidth="xs"
				open={open}
				onClose={handleClose}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
				<DialogContent>
					{props.value &&
						props.value.map((item, key) => (
							<ZText key={key} fullWidth disabled={disabled} value={item.value} label={item.label} />
						))}
				</DialogContent>
				<DialogActions>
					<Button onClick={copyToClipBoard} color="primary">
						Copiar
					</Button>
					<Button onClick={handleClose} color="primary">
						Fechar
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
