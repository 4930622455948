import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import format from 'date-fns/format';
import { Mask } from '../../utils';
import Boleto from 'boleto.js';
import parse from 'html-react-parser';

const useStyles = makeStyles(theme => ({
	container: {
		display: 'flex',
		visibility: 'visible',
		borderStyle: 'solid',
		borderWidth: 1,
		borderColor: 'black',
		height: 354,
		width: 1000,
		marginBottom: 10,
	},
	row: {
		visibility: 'visible',
		display: 'flex',
		width: '100%',
	},
	canhoto: {
		visibility: 'visible',
		width: 250,
		borderRight: 'dotted 2px black',
	},
	borderBottom: {
		visibility: 'visible',
		borderBottom: 'solid 1px #111',
	},
	borderRight: {
		visibility: 'visible',
		borderRight: 'solid 1px #111',
	},
	fieldTitle: {
		visibility: 'visible',
		textAlign: 'center',
		fontWeight: 'bold',
	},
	fieldValue: {
		visibility: 'visible',
		textAlign: 'center',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
	},
	fieldDiscountValue: {
		visibility: 'visible',
		alignItems: 'start',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
	},
	destro: {
		visibility: 'visible',
		width: 'calc(100% - 250px)',
	},
	middle: {
		visibility: 'visible',
		width: 'calc(100% - 180px)',
		borderRight: 'solid 1px #111',
	},
	right: {
		visibility: 'visible',
		width: 180,
	},
	logo: {
		visibility: 'visible',
		width: 120,
		height: 60,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	barcode: {
		visibility: 'visible',
		// display: 'flex',
		// justifyContent: 'center',
		// alignItems: 'center',
		// flexDirection: 'column',
		margin: 'auto',
		marginTop: 10,
		width: '95%',
		'& svg': {
			visibility: 'visible',
			'& rect': {
				visibility: 'visible',
			},
		},
	},
	descricao: {
		visibility: 'visible',
		height: 105,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		overflow: 'hidden',
	},
	whiteSpaceWrap: {
		whiteSpace: 'normal',
	},
}));

const GenerateBoleto = props => {
	const { codigoBarras } = props;
	const svg = new Boleto(Mask.Boleto(codigoBarras)).toSVG();

	return parse(svg);
};

export default props => {
	const { titulo, descricao, logo, totalParcelas, carneParcela, estabelecimento, cliente, descontos, mora, multa } = props;
	const classes = useStyles();

	const { logradouro, numero, cidade, uf, cep } = cliente.endereco;
	const clienteEndereco = `${logradouro}, ${numero} - ${cidade} - ${uf} - ${Mask.Cep(cep)}`;

	const [{ documento }] = cliente.clientes_documentos;

	const { codigo_barras: codigoBarras } = carneParcela;
	// const barcodePart1 = codigoBarras.substr(0, 4);
	// const barcodePart2 = codigoBarras.substr(4, 11);
	// const barcodePart3 = codigoBarras.substr(15, 14);
	// const barcodePart4 = codigoBarras.substr(29);

	// const barcode = `${barcodePart1}${barcodePart4}${barcodePart2}${barcodePart3}`;

	return (
		<div className={classes.container}>
			<div className={classes.canhoto}>
				<div className={classes.borderBottom}>
					<div className={classes.fieldTitle}>Estabelecimento</div>
					<div className={classes.fieldValue}>{estabelecimento.nome_fantasia}</div>
				</div>
				<div className={classes.borderBottom}>
					<div className={classes.fieldTitle}>Sacado</div>
					<div className={classes.fieldValue}>{cliente.nome}</div>
				</div>
				<div className={classes.row}>
					<div style={{ width: '30%' }} className={clsx(classes.borderBottom, classes.borderRight)}>
						<div className={classes.fieldTitle}>Parcela</div>
						<div className={classes.fieldValue}>{`${`${carneParcela.parcela}`.padStart(
							2,
							'0'
						)}/${`${totalParcelas}`.padStart(2, '0')}`}</div>
					</div>
					<div style={{ width: '70%' }} className={classes.borderBottom}>
						<div className={classes.fieldTitle}>Data de Vencimento</div>
						<div className={classes.fieldValue}>{format(carneParcela.data_vencimento, 'DD/MM/YYYY')}</div>
					</div>
				</div>
				<div className={classes.borderBottom}>
					<div className={classes.fieldTitle}>(=) Valor do Documento</div>
					<div className={classes.fieldValue}>{Mask.Real(carneParcela.valor)}</div>
				</div>
				<div className={classes.borderBottom}>
					<div className={classes.fieldTitle}>(-) Descontos</div>
					<div className={classes.fieldValue}>{Mask.Real(0)}</div>
				</div>
				<div className={classes.borderBottom}>
					<div className={classes.fieldTitle}>(-) Outros Descontos</div>
					<div className={classes.fieldValue}>{Mask.Real(0)}</div>
				</div>
				<div className={classes.borderBottom}>
					<div className={classes.fieldTitle}>(+) Multa / Mora</div>
					<div className={classes.fieldValue}>{Mask.Real(0)}</div>
				</div>
				<div className={classes.borderBottom}>
					<div className={classes.fieldTitle}>(+) Outros Acréscimos</div>
					<div className={classes.fieldValue}>{Mask.Real(0)}</div>
				</div>
				<div className={classes.borderBottom}>
					<div className={classes.fieldTitle}>(=) Total Cobrado</div>
					<div className={classes.fieldValue}>{Mask.Real(carneParcela.valor)}</div>
				</div>
			</div>
			<div className={classes.destro}>
				<div className={classes.row}>
					<div className={classes.middle}>
						<div className={classes.row}>
							<div className={clsx('force-print', classes.logo, classes.borderBottom, classes.borderRight)}>
								{logo && (
									<img
										src={logo}
										alt={estabelecimento.nome_fantasia}
										title={estabelecimento.nome_fantasia}
										style={{ maxWidth: '100%', maxHeight: '60px' }}
									/>
								)}
							</div>
							<div style={{ width: 'calc(100% - 120px)' }} className={classes.borderBottom}>
								<div className={classes.fieldTitle}>Estabelecimento</div>
								<div className={classes.fieldValue}>{estabelecimento.nome_fantasia}</div>
								{estabelecimento.razao_social && (
									<div className={clsx('force-print', classes.fieldValue)}>
										<small>{estabelecimento.razao_social}</small>
									</div>
								)}
							</div>
						</div>
						<div className={classes.row}>
							<div style={{ width: '33%' }} className={clsx(classes.borderBottom, classes.borderRight)}>
								<div className={classes.fieldTitle}>Carnê</div>
								<div className={classes.fieldValue}>{`${carneParcela.carne_id}`.padStart(5, '0')}</div>
							</div>
							<div style={{ width: '33%' }} className={clsx(classes.borderBottom, classes.borderRight)}>
								<div className={classes.fieldTitle}>Espécie</div>
								<div className={classes.fieldValue}>R$</div>
							</div>
							<div style={{ width: '34%' }} className={classes.borderBottom}>
								<div className={classes.fieldTitle}>Processamento</div>
								<div className={classes.fieldValue}>{format(carneParcela.created, 'DD/MM/YYYY')}</div>
							</div>
						</div>
						<div className={clsx(classes.descricao, classes.borderBottom)}>
							<div className={classes.fieldValue}>{titulo}</div>
							<div className={clsx(classes.fieldValue, classes.whiteSpaceWrap)}>{descricao}</div>
							{/* <div className={classes.fieldValue}>{mora}</div> */}
							{multa &&
								<div className={classes.fieldValue}>
									Multa {multa.mode === 'amount' ? Mask.Real(multa.amount) : `${Mask.Porcentagem(multa.percentage)}%`}
								</div>
							}
							{mora &&
								<div className={classes.fieldValue}>
									Mora {mora.mode === 'amount' ? Mask.Real(mora.amount) : `${mora.percentage}%`}
								</div>
							}
							{descontos.map((desconto, index) => (
								<>
									{desconto.mode === "FIXED" ? (
										<div className={classes.fieldValue}>
											Desconto de {Mask.Real(desconto.value / 100)} para pagamento até {format(desconto.limit_date, 'DD/MM/YYYY')}
										</div>
									) : (
										<div className={classes.fieldValue}>
											Desconto de {Mask.Porcentagem(desconto.value)}% para pagamento até {format(desconto.limit_date, 'DD/MM/YYYY')}
										</div>
									)}
								</>
							))}
						</div>
						<div className={classes.borderBottom}>
							<div className={classes.fieldValue}>
								{`${cliente.nome} - ${documento.length > 11 ? Mask.Cnpj(documento) : Mask.Cpf(documento)}`}
							</div>
							<div className={classes.fieldValue}>{clienteEndereco}</div>
						</div>
						<div className={classes.borderBottom}>
							<div style={{ fontWeight: 'bold' }} className={classes.fieldValue}>
								{Mask.Boleto(carneParcela.codigo_barras)}
							</div>
						</div>
					</div>
					<div className={classes.right}>
						<div className={classes.row}>
							<div style={{ width: '30%' }} className={clsx(classes.borderBottom, classes.borderRight)}>
								<div className={classes.fieldTitle}>Parcela</div>
								<div className={classes.fieldValue}>{`${`${carneParcela.parcela}`.padStart(
									2,
									'0'
								)}/${`${totalParcelas}`.padStart(2, '0')}`}</div>
							</div>
							<div style={{ width: '70%' }} className={classes.borderBottom}>
								<div className={classes.fieldTitle}>Vencimento</div>
								<div className={classes.fieldValue}>
									{format(carneParcela.data_vencimento, 'DD/MM/YYYY')}
								</div>
							</div>
						</div>
						<div className={classes.borderBottom}>
							<div className={classes.fieldTitle}>(=) Valor do Documento</div>
							<div className={classes.fieldValue}>{Mask.Real(carneParcela.valor)}</div>
						</div>
						<div className={classes.borderBottom}>
							<div className={classes.fieldTitle}>(-) Descontos</div>
							<div className={classes.fieldValue}>{Mask.Real(0)}</div>
						</div>
						<div className={classes.borderBottom}>
							<div className={classes.fieldTitle}>(-) Outros Descontos</div>
							<div className={classes.fieldValue}>{Mask.Real(0)}</div>
						</div>
						<div className={classes.borderBottom}>
							<div className={classes.fieldTitle}>(+) Multa / Mora</div>
							<div className={classes.fieldValue}>{Mask.Real(0)}</div>
						</div>
						<div className={classes.borderBottom}>
							<div className={classes.fieldTitle}>(+) Outros Acréscimos</div>
							<div className={classes.fieldValue}>{Mask.Real(0)}</div>
						</div>
						<div className={classes.borderBottom}>
							<div className={classes.fieldTitle}>(=) Total Cobrado</div>
							<div className={classes.fieldValue}>{Mask.Real(carneParcela.valor)}</div>
						</div>
					</div>
				</div>
				<div className={classes.barcode}>
					<GenerateBoleto codigoBarras={codigoBarras} />
				</div>
			</div>
		</div>
	);
};
