/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import config from 'react-global-configuration';
import { addMessage } from '../../store/alert';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import ZLoader from '../system/ZLoader';

import { Mask, Isset } from '../../utils';
import { Button } from '@material-ui/core';
import VincularECs from './VincularECs';

const useStyles = makeStyles(theme => ({
	pageTitle: {
		fontWeight: 'bold',
		fontSize: 18,
		marginBottom: 10,
	},
	title: {
		fontWeight: 'bold',
		fontSize: 14,
	},
	planosTaxas: {
		position: 'relative',
		paddingTop: 10,
	},
	panelTitle: {
		fontWeight: 'bold',
		fontSize: 16,
	},
	panelDetails: {
		minHeight: 530,
	},
	taxaField: {
		padding: 7.45,
		'&::after': {
			content: '"%"',
			position: 'absolute',
			right: 4,
			fontSize: 12,
		},
		'& .MuiInputBase-input': {
			textAlign: 'right',
			height: '1em',
			padding: 0,
			paddingRight: 15,
		},
		'& .MuiInput-underline:before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
			bottom: -7,
			transform: 'scaleX(1)',
		},
		'& .MuiInput-underline:not(.Mui-focused):after': {
			borderBottom: 'solid 1px #abc',
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: 'none',
		},
	},
}));

const TaxaField = props => {
	const [value, setValue] = useState(Mask.Porcentagem(props.value));

	const onChange = e => {
		let valor = Mask.OnlyNumber(e.target.value) / 100;
		if (valor > 100) {
			valor = 100;
		}
		setValue(Mask.Porcentagem(valor));
		props.onChange(valor);
	};
	return <TextField {...props} value={value} onChange={onChange} />;
};

export default props => {
	const { usuarioId } = useParams();

	const classes = useStyles();

	const dispatch = useDispatch();
	const token = useSelector(store => store.auth.token);

	const [usuarioNome, setUsuarioNome] = useState(null);
	const [loading, setLoading] = useState(true);

	const [comissao, setComissao] = useState(0);

	const getTaxas = async () => {
		setLoading(true);
		const res = await axios.get(`${config.get('apiUrl')}usuarios/comissoes/${usuarioId}`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		if (res.data.success && Isset(() => res.data.taxas)) {
			setComissao(res.data.taxas);
			// setTaxas(res.data.taxas);
		}

		setLoading(false);
	};

	useEffect(() => {
		axios
			.post(
				`${config.get('apiUrl')}graphql`,
				{
					query: `
						query Usuario {
							user(id: ${usuarioId}) {
								id, nome
							}
						}
					`,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then(res => setUsuarioNome(res.data.data.user.nome))
			.catch(err => console.log(err));
		getTaxas();
	}, []);

	const handleSubmit = async () => {
		if (comissao <= 0) {
			return dispatch(addMessage({ type: 'error', message: 'Comissão deve ser maior que 0%.' }));
		}

		const data = {
			usuarioId,
			bandeiraId: 1,
			antecipado: 1,
			taxas: [
				{
					tipoPagamentoId: 1,
					parcela: 1,
					valor: comissao,
				},
			],
		};

		const res = await axios.post(`${config.get('apiUrl')}usuarios/comissoes`, data, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		if (res.data.success) {
			dispatch(addMessage({ type: 'success', message: 'Comissão salva com sucesso.' }));
		} else {
			dispatch(addMessage({ type: 'error', message: 'Erro ao salvar comissão.' }));
		}
	};

	if (loading) {
		return <ZLoader height={200} />;
	}

	return (
		<div className={classes.planosTaxas}>
			<div className={classes.pageTitle}>Configurar Comissão {usuarioNome && ` - ${usuarioNome}`}</div>

			<Grid container justifyContent="center" spacing={2}>
				<Grid item xs={12} md={4}>
					<Paper style={{ padding: 10, textAlign: 'center' }}>
						<div className={classes.title}>Comissão</div>
						<TaxaField
							className={classes.taxaField}
							value={comissao}
							onChange={v => setComissao(v)}
							onFocus={e => {
								const length = e.target.value.length * 2;
								e.target.setSelectionRange(0, length);
							}}
						/>
					</Paper>
				</Grid>
			</Grid>

			<Grid container justifyContent="center" spacing={2}>
				<Grid item xs={12} md={4} style={{ textAlign: 'center' }}>
					<Button variant="contained" color="primary" onClick={handleSubmit}>
						Salvar Comissão
					</Button>
				</Grid>
			</Grid>

			<VincularECs usuarioId={usuarioId} />
		</div>
	);
};
