import React, { useState, useEffect } from 'react';
// import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import ZTitle from '../system/ZTitle';
import ZLoader from '../system/ZLoader';
import ZPagination from '../system/ZPagination';
import ZModalConfirm from '../system/ZModalConfirm';
import configuration from 'react-global-configuration';
import { Isset, Formatter } from '../../utils/';
import axios from 'axios';
import config from 'react-global-configuration';
import { addMessage } from '../../store/alert';

import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import ZMenuOptions from '../system/ZMenuOptions';
import ZFiltros from '../system/ZFiltros';
import clsx from 'clsx';
import { FaDownload } from 'react-icons/fa';
import FileDownload from 'js-file-download';
import { useHistory, useLocation } from 'react-router-dom';
// import queryString from 'query-string';

const useStyles = makeStyles(theme => ({
	buttonExport: {
		color: '#040633',
		border: `solid 1px #040633`,
		'&:hover': {
			color: '#FFF',
			backgroundColor: '#011d29',
			'& svg': {
				color: '#FFF',
			},
		},
		[theme.breakpoints.down('xs')]: {
			width: '90%',
			marginTop: 16,
		},
		'& svg': {
			margin: 0,
		},
	},
	iconButton: {
		minWidth: 0,
	},
}));

function getOptions() {
	return {
		status: [
			{ id: '1', name: 'Aguardando' },
			{ id: '2', name: 'Cancelado' },
			{ id: '3', name: 'Pago' },
			{ id: '4', name: 'Suspenso' },
			{ id: '5', name: 'Atrasado' },
		],
	};
}

export default props => {
	const history = useHistory();
	// const query = queryString.parse(useLocation().search);
	const { pathname } = useLocation();
	const pathnameSlug = pathname.toLowerCase().replace(/[^a-z0-9]/gi, '');
	const filtersCache = useSelector(store => store.filters[pathnameSlug] || {});

	const dispatch = useDispatch();
	const token = useSelector(store => store.auth.token);
	const estabelecimento = useSelector(store => store.admin.estabelecimento);
	const classes = useStyles();

	// const [showExportMessage, setShowExportMessage] = useState(false);
	const [loading, setLoading] = useState(true);
	const [assinaturas, setAssinaturas] = useState([]);
	const [removerAssinatura, setRemoverAssinatura] = useState({
		assianturaId: null,
		open: false,
	});
	const [suspenderAssinatura, setSuspenderAssinatura] = useState({
		assianturaId: null,
		open: false,
	});
	const [reativarAssinatura, setReativarAssinatura] = useState({
		assianturaId: null,
		open: false,
	});

	// const { id: estabelecimentoId } = useSelector(state => state.admin.estabelecimento) || {};

	const [filtros, setFiltros] = useState({
		// estabelecimentoId: estabelecimentoId ? [estabelecimentoId] : [],
		...filtersCache,
		omni: Isset(() => filtersCache.omni) ? filtersCache.omni : '',
		omni2: Isset(() => filtersCache.omni2) ? filtersCache.omni2 : '',
		status: Isset(() => filtersCache.status) ? filtersCache.status : [],
		created: {
			start: Isset(() => filtersCache.created.start) ? filtersCache.created.start : '',
			end: Isset(() => filtersCache.created.end) ? filtersCache.created.end : '',
			label: 'Data de criação',
		},
		proximaCobranca: {
			start: Isset(() => filtersCache.proximaCobranca.start) ? filtersCache.proximaCobranca.start : false,
			end: Isset(() => filtersCache.proximaCobranca.end) ? filtersCache.proximaCobranca.end : false,
			label: 'Próxima cobrança',
		},
		// planos: {
		// 	label: 'Planos',
		// 	value: '',
		// 	multiple: true,
		// 	options: planos
		// }
	});

	// Paginação

	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(15);
	const [totalRows, setTotalRows] = useState(0);

	const onChangePage = newPage => {
		setPage(newPage);
	};

	const onChangeRowsPerPage = rowsPerPage => {
		setRowsPerPage(rowsPerPage);
		setPage(1);
	};

	const getAssinaturas = () => {
		setLoading(true);
		let url = `${config.get('apiUrl')}planos/assinaturas`;
		if (props.match.params.planoId) {
			url = url + `/${props.match.params.planoId}`;
		}

		var dataFiltros = {
			status: filtros.status,
			omni: filtros.omni,
			assinaturaId: filtros.omni2,
		};

		if (filtros.created.start) {
			dataFiltros.created = {
				start: moment(filtros.created.start).format('YYYY-MM-DD 00:00:00'),
				end: moment(filtros.created.end).format('YYYY-MM-DD 23:59:59'),
			};
		}

		if (filtros.proximaCobranca.start) {
			dataFiltros.proximaCobranca = {
				start: moment(filtros.proximaCobranca.start).format('YYYY-MM-DD'),
				end: moment(filtros.proximaCobranca.end).format('YYYY-MM-DD'),
			};
		}

		let params = {
			page,
			limit: rowsPerPage,
			...dataFiltros,
		};

		if (estabelecimento) {
			params.estabelecimento = estabelecimento.id;
		}

		axios
			.get(url, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
				params,
			})
			.then(res => res.data)
			.then(data => {
				setLoading(false);
				if (data.success) {
					setAssinaturas(data.assinaturas);
					setTotalRows(data.quantidade);
				}
			});
	};

	const selectChanged = async (id, data) => {
		setFiltros({
			...filtros,
			[id]: {
				...filtros[id],
				value: data.target.value,
			},
		});
	};

	const filtrosChange = async data => {
		// console.log(data);
		// if( data.proximaCobranca ){
		// 	setFiltros({
		// 		...filtros,
		// 		proximaCobranca: {
		// 			start: data.proximaCobranca.start,
		// 			end: data.proximaCobranca.end,
		// 			label: 'Próxima cobrança'
		// 		}
		// 	});
		// }

		// if(data.created){
		// 	setFiltros({
		// 		...filtros,
		// 		created: {
		// 			start: data.created.start,
		// 			end: data.created.end,
		// 			label: 'Data de criação'
		// 		}
		// 	});
		// }

		// if(data.omni){

		setPage(1);
		setFiltros({
			...filtros,
			...data,
		});

		// }
	};

	useEffect(() => {
		getAssinaturas();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtros]);

	useEffect(() => {
		getAssinaturas();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(getAssinaturas, [page, rowsPerPage]);

	const handleRemoveAssinatura = result => {
		const assinaturaId = removerAssinatura.assinaturaId;
		setRemoverAssinatura({ ...removerAssinatura, open: false, assinaturaId: null });

		if (result) {
			setLoading(true);
			axios
				.delete(`${config.get('apiUrl')}planos/assinatura/${assinaturaId}`, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				})
				.then(res => res.data)
				.then(data => {
					if (data.success) {
						dispatch(addMessage({ type: 'success', message: 'Assinatura removida com sucesso' }));
						getAssinaturas();
					}
				});
		}
	};

	const handleReativarAssinatura = result => {
		const assinaturaId = reativarAssinatura.assinaturaId;
		setReativarAssinatura({ ...reativarAssinatura, open: false, assinaturaId: null });

		if (result) {
			setLoading(true);
			axios
				.post(
					`${config.get('apiUrl')}planos/assinatura/reativar`,
					{
						assinatura_id: assinaturaId,
					},
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				)
				.then(res => res.data)
				.then(data => {
					if (data.success) {
						dispatch(addMessage({ type: 'success', message: 'Assinatura reativada com sucesso' }));
						getAssinaturas();
					} else {
						dispatch(addMessage({ type: 'error', message: data.message }));
						setLoading(false);
					}
				});
		}
	};

	const handleClearFilter = () => {
		setFiltros({
			omni: '',
			omni2: '',
			status: [],
			created: {
				start: '',
				end: '',
				label: 'Data de criação',
			},
			proximaCobranca: {
				start: false,
				end: false,
				label: 'Próxima cobrança',
			},
		});
	};

	const handleSuspenderAssinatura = result => {
		const assinaturaId = suspenderAssinatura.assinaturaId;
		setSuspenderAssinatura({ ...suspenderAssinatura, open: false, assinaturaId: null });

		if (result) {
			setLoading(true);
			axios
				.post(
					`${config.get('apiUrl')}planos/assinatura/suspender/`,
					{
						assinatura_id: assinaturaId,
					},
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				)
				.then(res => res.data)
				.then(data => {
					if (data.success) {
						dispatch(addMessage({ type: 'success', message: 'Assinatura suspensa com sucesso' }));
						getAssinaturas();
					} else {
						dispatch(addMessage({ type: 'error', message: data.message }));
						setLoading(false);
					}
				});
		}
	};

	const exportXls = async () => {
		// setShowExportMessage(true);

		let url = `${config.get('apiUrl')}export/assinaturas`;
		if (props.match.params.planoId) {
			url = url + `/${props.match.params.planoId}`;
		}

		var dataFiltros = {
			status: filtros.status,
			omni: filtros.omni,
			assinaturaId: filtros.omni2,
		};

		if (filtros.created.start) {
			dataFiltros.created = {
				start: moment(filtros.created.start).format('YYYY-MM-DD'),
				end: moment(filtros.created.end).format('YYYY-MM-DD'),
			};
		}

		if (filtros.proximaCobranca.start) {
			dataFiltros.proximaCobranca = {
				start: moment(filtros.proximaCobranca.start).format('YYYY-MM-DD'),
				end: moment(filtros.proximaCobranca.end).format('YYYY-MM-DD'),
			};
		}

		let params = {
			page,
			limit: rowsPerPage,
			...dataFiltros,
		};

		if (estabelecimento) {
			params.estabelecimento = estabelecimento.id;
		}

		const assinaturas = await axios.get(url, {
			headers: { Authorization: `Bearer ${token}` },
			responseType: 'blob',
			params,
		});

		if (assinaturas.status === 200) {
			const { 'content-disposition': contentDisposition, 'content-type': contentType } = assinaturas.headers;
			let filename = 'assinaturas.xlsx';
			if (contentDisposition.indexOf('filename="') !== -1) {
				filename = contentDisposition.slice(
					contentDisposition.indexOf('filename="') + 10,
					contentDisposition.length - 1
				);
			}

			FileDownload(assinaturas.data, filename, contentType);
		} else {
			dispatch(addMessage({ type: 'error', message: 'Erro ao exportar assinaturas.' }));
		}
		// setShowExportMessage(false);
	};

	return (
		<Grid container>
			<ZTitle
				title="Assinantes"
				// button={props.match.params.planoId ? 'add' : false}
				// handleOnClick={() => history.push('/plano/' + props.match.params.planoId + '/assinar')}
			/>
			<Grid item xs={12}>
				<Paper
					style={{
						marginBottom: 10,
						borderRadius: 4,
						padding: '5px 10px',
					}}
				>
					<Grid container alignItems="center">
						<Grid item md={11}>
							<ZFiltros
								onChange={filtrosChange}
								onSelectChange={selectChanged}
								handleClearFilter={() => handleClearFilter()}
								modal={true}
								input={[
									{
										key: 'omni',
										placeholder: 'Nome/E-mail',
										defaultValue: filtros.omni,
										modal: true,
										md: 6,
									},
									{
										key: 'omni2',
										placeholder: 'ID',
										defaultValue: filtros.omni2,
										modal: true,
										md: 6,
									},
								]}
								selectField={[
									{
										key: 'status',
										title: 'Status',
										defaultValue: filtros.status,
										multiple: true,
										modal: true,
										md: 12,
										options: getOptions().status,
										divider: true,
									},
								]}
								created={{ ...filtros.created }}
								proximaCobranca={{ ...filtros.proximaCobranca }}
							/>
						</Grid>
						<Grid item md={1} style={{ textAlign: 'right' }}>
							<Tooltip title="Exportar">
								<Button className={clsx(classes.buttonExport, classes.iconButton)} onClick={exportXls}>
									<FaDownload className={classes.exportDownload} />
								</Button>
							</Tooltip>
						</Grid>
					</Grid>
				</Paper>
			</Grid>
			<Grid item xs={12}>
				<Paper style={{ overflowX: 'auto', width: 'calc(100vw - 45px)' }}>
					{loading ? (
						<Grid item xs={12}>
							<ZLoader height="200px" size={40} />
						</Grid>
					) : (
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>ID</TableCell>
									<TableCell>Cliente</TableCell>
									<TableCell>Plano</TableCell>
									<TableCell align="right">Preço</TableCell>
									{/* <TableCell align="right">Frequência</TableCell> */}
									{/* <TableCell align="right">Intervalo</TableCell> */}
									<TableCell align="right">Criado</TableCell>
									<TableCell align="right">Próxima cobrança</TableCell>
									<TableCell align="right">Data de expiração</TableCell>
									<TableCell align="right">Status</TableCell>
									<TableCell align="right"></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								<>
									{assinaturas.map((assinatura, key) => {
										let status = '';
										switch (assinatura.status_assinatura_id) {
											case 1:
												status = 'Aguardando';
												break;
											case 2:
												status = 'Cancelado';
												break;
											case 3:
												status = 'Pago';
												break;
											case 4:
												status = 'Suspenso';
												break;
											case 5:
												status = 'Atrasada';
												break;
											default:
												break;
										}

										// if( assinatura.ativo === 0 ){
										// 	status = 'Cancelada';
										// }

										var options = [
											{
												title: 'Detalhes',
												onClick: () => {
													history.push('/assinaturas/detalhes/' + assinatura.id);
												},
											},
											// {
											//     title : 'Editar',
											//     onClick: () => { history.push('/assinaturas/editar/'+assinatura.id)}
											// },
										];

										if (assinatura.status_assinatura_id === 4) {
											options.push({
												title: 'Reativar',
												onClick: () =>
													setReativarAssinatura({
														...suspenderAssinatura,
														assinaturaId: assinatura.id,
														open: true,
													}),
											});
										} else if (assinatura.status_assinatura_id !== 2 && assinatura.ativo) {
											options.push({
												title: 'Suspender',
												onClick: () =>
													setSuspenderAssinatura({
														...suspenderAssinatura,
														assinaturaId: assinatura.id,
														open: true,
													}),
											});
										}

										if (assinatura.ativo) {
											if (['credit', 'credito'].includes(assinatura.payment_method)) {
												options.push({
													title: 'Alterar Cartão de Crédito',
													onClick: () =>
														history.push('/assinaturas/alterar_cartao/' + assinatura.id),
												});

												options.push({
													title: 'Atualizar Cartão (URL)',
													onClick: () =>
														window.open(
															configuration.get('baseName') +
																'/assinatura/atualizar/' +
																assinatura.subscription_id,
															'_blank'
														),
												});
											}

											options.push({
												title: 'Remover',
												onClick: () =>
													setRemoverAssinatura({
														...removerAssinatura,
														assinaturaId: assinatura.id,
														open: true,
													}),
											});
										}

										return (
											<TableRow hover key={key}>
												<TableCell>{assinatura.id}</TableCell>
												<TableCell component="th" scope="row">
													{assinatura.cliente.nome}
												</TableCell>
												<TableCell component="th" scope="row">
													{assinatura.plano.name}
												</TableCell>
												<TableCell align="right">
													{Formatter.Real(assinatura.amount / 100)}
												</TableCell>
												{/* <TableCell align="right">{frequency}</TableCell> */}
												{/* <TableCell align="right">{assinatura.interval}</TableCell> */}
												<TableCell align="right">
													{moment(assinatura.created).format('DD/MM/YYYY')}
												</TableCell>
												<TableCell align="right">
													{assinatura.due_date
														? moment(assinatura.due_date).format('DD/MM/YYYY')
														: 'N/A'}
												</TableCell>
												<TableCell align="right">
													{assinatura.expiration_date
														? moment(assinatura.expiration_date).format('DD/MM/YYYY')
														: 'N/A'}
												</TableCell>
												<TableCell align="right">{status}</TableCell>
												<TableCell align="right">
													<ZMenuOptions data={options} />
												</TableCell>
											</TableRow>
										);
									})}
								</>
							</TableBody>
							<ZPagination
								count={totalRows}
								rowsPerPage={rowsPerPage}
								page={page - 1}
								onChangePage={onChangePage}
								onChangeRowsPerPage={onChangeRowsPerPage}
							/>
						</Table>
					)}
				</Paper>
			</Grid>

			<ZModalConfirm
				title={`Remover assinatura`}
				message={`Deseja remover essa assinatura? A ação não pode ser desfeita.`}
				open={removerAssinatura.open}
				onClose={handleRemoveAssinatura}
			/>
			<ZModalConfirm
				title={`Suspender assinatura`}
				message={`Deseja suspender essa assinatura?`}
				open={suspenderAssinatura.open}
				onClose={handleSuspenderAssinatura}
			/>
			<ZModalConfirm
				title={`Reativar assinatura`}
				message={`Deseja reativar essa assinatura?`}
				open={reativarAssinatura.open}
				onClose={handleReativarAssinatura}
			/>
		</Grid>
	);
};
