import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { pt } from 'react-date-range/src/locale/index';
import format from 'date-fns/format';
import addDays from 'date-fns/addDays';
import addMonths from 'date-fns/addMonths';

import { Isset } from '../../utils';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { FaCalendarAlt } from 'react-icons/fa';

import { DateRange } from 'react-date-range';

const useStyles = makeStyles(theme => ({
	filtro: {
		position: 'relative',
		cursor: 'pointer',
	},
	filtroText: {
		display: 'flex',
		alignItems: 'center',
	},
	filterSelected: {
		fontWeight: 700,
		fontSize: 12,
		[theme.breakpoints.down('xs')]: {
			display: 'none',
		},
	},
	filterBox: {
		position: 'absolute',
		top: 30,
		right: 0,
		padding: 5,
		zIndex: 1210,
	},
	backdrop: {
		position: 'fixed',
		zIndex: 1200,
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		backgroundColor: 'rgba(0,0,0,0.3)',
	},
	staticOptions: {
		textAlign: 'center',
	},
	staticOption: {
		textAlign: 'center',
	},
	optionSelected: {
		fontWeight: 700,
	},
	calendarIcon: {
		marginLeft: 6,
	},
}));

const selectedOptions = {
	last6months: 'Últimos 6 Meses',
	lastMonth: 'Último Mês',
	last10days: 'Últimos 10 Dias',
	today: 'Hoje',
	next10days: 'Próximos 10 Dias',
	next6months: 'Próximos 6 Meses',
	nextMonth: 'Próximo Mês',
};

export default props => {
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const [selected, setSelected] = useState(props.default || 'last10days');

	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const [minDate] = useState(props.minDate || undefined);
	const [maxDate] = useState(props.maxDate || undefined);

	const handleSelect = ranges => {
		setStartDate(ranges.selection.startDate);
		setEndDate(ranges.selection.endDate);
		setSelected('custom');

		if (props.onChange) {
			props.onChange(ranges.selection);
		}
	};

	const handleBackdropClick = () => {
		setOpen(false);

		if (props.onClose) {
			props.onClose({ startDate, endDate });
		}
	};

	const staticClick = option => {
		switch (option) {
			case 'last6months':
				setStartDate(addMonths(new Date(), -6));
				setEndDate(new Date());
				break;
			case 'lastMonth':
				setStartDate(addMonths(new Date(), -1));
				setEndDate(new Date());
				break;
			case 'last10days':
				setStartDate(addDays(new Date(), -10));
				setEndDate(new Date());
				break;
			case 'today':
				setStartDate(new Date());
				setEndDate(new Date());
				break;
			case 'next10days':
				setStartDate(new Date());
				setEndDate(addDays(new Date(), 10));
				break;
			case 'next6months':
				setStartDate(new Date());
				setEndDate(addMonths(new Date(), 6));
				break;
			case 'nextMonth':
				setStartDate(new Date());
				setEndDate(addMonths(new Date(), 1));
				break;
			default:
		}
	};

	useEffect(() => {
		staticClick(selected);
	}, [selected]);

	const selectionRange = {
		startDate,
		endDate,
		key: 'selection',
	};

	return (
		<div className={classes.filtro}>
			<div className={classes.filtroText} onClick={() => setOpen(!open)}>
				<span className={classes.filterSelected}>
					{Isset(() => selectedOptions[selected])
						? selectedOptions[selected]
						: `${format(startDate, 'DD/MM/YYYY')} - ${format(endDate, 'DD/MM/YYYY')}`}
				</span>
				<FaCalendarAlt className={classes.calendarIcon} />
			</div>

			{open && <div className={classes.backdrop} onClick={handleBackdropClick}></div>}

			{open && (
				<Paper className={classes.filterBox}>
					<DateRange
						locale={pt}
						ranges={[selectionRange]}
						onChange={handleSelect}
						dateDisplayFormat={'DD/MM/YYYY'}
						minDate={minDate}
						maxDate={maxDate}
						showSelectionPreview={false}
						moveRangeOnFirstSelection={false}
					/>
					<Grid container className={classes.staticOptions}>
						{minDate === undefined && (
							<Grid xs item>
								<div
									onClick={() => setSelected('today')}
									className={clsx({
										[classes.staticOption]: true,
										[classes.optionSelected]: selected === 'today',
									})}
								>
									{selectedOptions['today']}
								</div>
								<div
									onClick={() => setSelected('last10days')}
									className={clsx({
										[classes.staticOption]: true,
										[classes.optionSelected]: selected === 'last10days',
									})}
								>
									{selectedOptions['last10days']}
								</div>
								<div
									onClick={() => setSelected('lastMonth')}
									className={clsx({
										[classes.staticOption]: true,
										[classes.optionSelected]: selected === 'lastMonth',
									})}
								>
									{selectedOptions['lastMonth']}
								</div>
								<div
									onClick={() => setSelected('last6months')}
									className={clsx({
										[classes.staticOption]: true,
										[classes.optionSelected]: selected === 'last6months',
									})}
								>
									{selectedOptions['last6months']}
								</div>
							</Grid>
						)}
						{maxDate === undefined && (
							<Grid xs item>
								<div
									onClick={() => setSelected('nextMonth')}
									className={clsx({
										[classes.staticOption]: true,
										[classes.optionSelected]: selected === 'nextMonth',
									})}
								>
									{selectedOptions['nextMonth']}
								</div>
								<div
									onClick={() => setSelected('next6months')}
									className={clsx({
										[classes.staticOption]: true,
										[classes.optionSelected]: selected === 'next6months',
									})}
								>
									{selectedOptions['next6months']}
								</div>
							</Grid>
						)}
					</Grid>
				</Paper>
			)}
		</div>
	);
};
