/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, memo } from 'react';
import clsx from 'clsx';

import { Isset } from '../../../utils';

import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import { FaTimes } from 'react-icons/fa';

const useStyles = makeStyles(theme => ({
	filtros: {
		position: 'relative',
		display: 'inline-flex',
	},
	filtroText: {
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
		padding: '5px 10px',
		border: `solid 1px ${theme.palette.primary.main}`,
		borderRadius: 30,
		marginLeft: 10,
		fontWeight: 700,
		fontSize: 12,
		color: theme.palette.primary.main,
	},
	filtroTextSelectedMultiple: {
		backgroundColor: `${theme.palette.primary.main}`,
		color: `${theme.palette.getContrastText(theme.palette.primary.main)} !important`,
		'& .MuiSelect-select': {
			color: `${theme.palette.getContrastText(theme.palette.primary.main)} !important`,
		},
		padding: '2px 10px',
		minHeight: 29,
		maxWidth: 340,
	},
	clearBtn: {
		marginLeft: 6,
	},
	filtroSelect: {
		fontWeight: 700,
		fontSize: 12,
		'&:before': {
			borderBottom: 'none !important',
		},
		'&:after': {
			borderBottom: 'none !important',
		},
		'& .MuiSelect-select': {
			padding: '2px 10px !important',
			marginRight: 10,
		},
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	chip: {
		margin: 2,
		height: 16,
		maxWidth: 130,
		justifyContent: 'flex-start',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		'& .MuiChip-label': {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			display: 'inline-block',
		},
	},
	menuItem: {
		flexDirection: 'column',
	},
}));

const statusTransferencias = [
	{ id: '1', name: 'Pendente' },
	{ id: '3', name: 'Aprovada' },
	{ id: '4', name: 'Cancelada' },
];

export default memo(props => {
	const classes = useStyles();

	let value = Isset(() => props.multiple) && props.multiple ? [] : '';
	if (Isset(() => props.defaultValue) && props.defaultValue) {
		value = props.defaultValue;
	}

	const [statusTransferencia, setStatusTransferencia] = useState(value);

	const statusTransferenciaChange = e => {
		let value = e.target.value;
		if (Array.isArray(value)) {
			value = value.map(a => `${a}`).filter((v, i, self) => self.indexOf(v) === i);
		}
		setStatusTransferencia(value);
	};

	const statusTransferenciaClose = e => {
		if (props.onChange) {
			props.onChange(statusTransferencia);
		}
	};

	const statusTransferenciaClear = e => {
		const isMultiple = Isset(() => props.multiple) ? props.multiple : false;
		e.stopPropagation();
		const value = isMultiple ? [] : '';
		setStatusTransferencia(value);
		if (props.onChange) {
			props.onChange(value);
		}
	};

	return (
		<div className={classes.statusTransferencia}>
			{statusTransferencias && (
				<div
					className={clsx({
						[classes.filtroText]: true,
						[classes.filtroTextSelectedMultiple]: Array.isArray(statusTransferencia)
							? statusTransferencia.length > 0
							: statusTransferencia,
					})}
				>
					<Select
						multiple={props.multiple || false}
						className={classes.filtroSelect}
						value={statusTransferencia}
						onChange={statusTransferenciaChange}
						onClose={statusTransferenciaClose}
						displayEmpty
						renderValue={selected => {
							if (!selected || selected.length === 0) {
								return <em>Status da Transferência</em>;
							}

							const aux = statusTransferencias.filter(a => selected.includes(a.id)).map(a => a.name);
							return (
								<div className={classes.chips}>
									{aux.map((value, i) => (
										<Chip key={`${value}${i}`} label={value} className={classes.chip} />
									))}
								</div>
							);
						}}
					>
						<MenuItem disabled value="">
							<em>Status da Transferência</em>
						</MenuItem>
						{statusTransferencias.map((statusTransferencia, i) => (
							<MenuItem key={i} value={statusTransferencia.id} className={classes.menuItem}>
								{statusTransferencia.name}
							</MenuItem>
						))}
					</Select>
					{statusTransferencia && <FaTimes className={classes.clearBtn} onClick={statusTransferenciaClear} />}
				</div>
			)}
		</div>
	);
});
