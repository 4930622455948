import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ZTitle from '../system/ZTitle';
import ZText from '../system/ZText';
import ZSelect from '../system/ZSelect';
import ZSaveButton from '../system/ZSaveButton';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import config from 'react-global-configuration';
import moment from 'moment';
import { addMessage } from '../../store/alert';
import { Mask, ValidatorForm } from '../../utils';

const validateFields = {
	nome: {
		required: true,
	},
	email: {
		required: true,
		email: true,
	},
	data_nascimento: {
		required: true,
	},
	sexo: {
		required: true,
	},
	celular: {
		required: true,
	},
	endereco: {
		logradouro: {
			required: true,
		},
		numero: {
			required: true,
		},
		cep: {
			required: true,
		},
		cidade: {
			required: true,
		},
		estado: {
			required: true,
		},
	},
};

export default () => {
	const { id } = useParams();

	const history = useHistory();
	const dispatch = useDispatch();
	const token = useSelector(store => store.auth.token);

	const [loading, setLoading] = useState(false);
	const [onError, setOnError] = useState(false);

	const [data, setData] = useState({
		nome: '',
		email: '',
		data_nascimento: '',
		celular: '',
		sexo: '',
		endereco: {
			cep: '',
			cidade: '',
			complemento: '',
			logradouro: '',
			numero: '',
			uf: '',
		},
	});

	useEffect(() => {
		getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getData = useCallback(async () => {
		const response = await axios.get(`${config.get('apiUrl')}clientes/${id}`, {
			headers: { Authorization: `Bearer ${token}` },
		});

		if (response.data.success) {
			const { cliente } = response.data;

			setData(data => ({
				...data,
				nome: cliente.nome,
				email: cliente.email,
				sexo: cliente.sexo,
				celular:
					typeof cliente.clientes_contatos !== 'undefined' && cliente.clientes_contatos.length > 0
						? cliente.clientes_contatos[0].contato
						: '',
				data_nascimento: cliente.data_nascimento,
				endereco: {
					...cliente.endereco,
				},
			}));
		}
	}, [id, token]);

	const handleChange = useCallback(
		name => event => {
			if (name === 'data_nascimento') {
				const newDate = moment(event.target.value, 'DD/MM/YYYY').format('YYYY-MM-DD');

				if (!moment(newDate).isValid()) {
					dispatch(addMessage({ type: 'error', message: 'Data inválida' }));

					return false;
				}

				setData(data => ({ ...data, [name]: newDate }));
			} else {
				const { value } = event.target;

				setData(data => ({ ...data, [name]: value }));
			}
		},
		[dispatch]
	);

	const handleChangeContato = useCallback(
		name => event => {
			const value = Mask.OnlyNumber(event.target.value);

			setData(data => ({ ...data, [name]: value }));
		},
		[]
	);

	const handleChangeAddress = useCallback(
		name => async event => {
			setData(data => ({
				...data,
				endereco: {
					...data.endereco,
					[name]: event.target.value,
				},
			}));

			if (name === 'cep' && event.target.value.length < 1) {
				setData(data => ({
					...data,
					endereco: {
						...data.endereco,
						cep: '',
						logradouro: '',
						cidade: '',
						estado: '',
						complemento: '',
					},
				}));
			} else if (name === 'cep' && event.target.value.length === 9) {
				const value = event.target.value;
				const res = await axios.get(config.get('apiUrl') + 'buscar_cep/' + value);

				if (res.data.success === false || res.data.endereco.erro) {
					setData(data => ({
						...data,
						endereco: {
							...data.endereco,
							cep: value,
							logradouro: '',
							cidade: '',
							estado: '',
							complemento: '',
						},
					}));

					return false;
				}

				setData(data => ({
					...data,
					endereco: {
						...data.endereco,
						cep: Mask.OnlyNumber(res.data.endereco.cep),
						logradouro: res.data.endereco.logradouro,
						cidade: res.data.endereco.localidade,
						estado: res.data.endereco.uf,
						complemento: '',
						bairro: res.data.endereco.bairro,
					},
				}));
			}
		},
		[]
	);

	const handleSubmit = useCallback(async () => {
		setLoading(true);

		try {
			ValidatorForm(validateFields, data);

			const response = await axios.put(`${config.get('apiUrl')}clientes/${id}`, data, {
				headers: { Authorization: `Bearer ${token}` },
			});

			if (response.data.success) {
				dispatch(addMessage({ type: 'success', message: 'operação realizada com sucesso' }));

				setLoading(false);

				history.push('/clientes');
			}
		} catch (error) {
			dispatch(addMessage({ type: 'error', message: 'Existem campos inválidos no formulário' }));

			setOnError(true);
			setLoading(false);
		}
	}, [data, id, token, dispatch, history]);

	const formattedDate = useMemo(() => {
		return moment(data.data_nascimento, 'YYYY-MM-DD').format('DD/MM/YYYY');
	}, [data]);

	return (
		<Grid>
			<ZTitle title={`Editar cliente`} />
			{/* <ZErrors errors={errors} /> */}

			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper style={{ padding: 15 }}>
						<Grid container spacing={3}>
							<Grid item xs={12} style={{ marginBottom: 20 }}>
								<Typography variant="h6" gutterBottom>
									Dados Pessoais
								</Typography>
								<Divider />
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<ZText
									onError={onError}
									required={true}
									label="Nome"
									value={data.nome}
									onBlur={handleChange('nome')}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<ZText
									onError={onError}
									required={true}
									email={true}
									label="E-mail"
									value={data.email}
									onBlur={handleChange('email')}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<ZText
									onError={onError}
									required={true}
									label="Celular"
									mask="(99) 9 9999-9999"
									value={data.celular}
									onBlur={handleChangeContato('celular')}
								/>
							</Grid>
							{/* <Grid item xs={12} sm={6} md={4} lg={3}>
								<ZText
									onError={onError}
									disabled={true}
									required={true}
									mask="999.999.999-99"
									label="CPF"
									// value={data.cliente.clientes_documentos[0].documento}
									// onBlur={handleChangeDocumento(2, data.cliente.clientes_documentos[0].id)}
								/>
							</Grid> */}

							<Grid item xs={12} sm={6} md={4} lg={3}>
								<ZSelect
									required={true}
									onError={onError}
									options={[
										{ id: 'F', name: 'Feminino' },
										{ id: 'M', name: 'Masculino' },
									]}
									label="Sexo"
									value={data.sexo}
									onBlur={handleChange('sexo')}
								/>
							</Grid>

							<Grid item xs={12} sm={6} md={4} lg={2}>
								<ZText
									onError={onError}
									required={true}
									label="Data Nascimento"
									mask="99/99/9999"
									value={formattedDate}
									onBlur={handleChange('data_nascimento')}
								/>
							</Grid>
						</Grid>

						<Grid container spacing={3} style={{ marginTop: 40 }}>
							<Grid item xs={12} style={{ marginBottom: 20 }}>
								<Typography variant="h6" gutterBottom>
									Endereço
								</Typography>
								<Divider />
							</Grid>
							<Grid item xs={12} sm={6} md={2}>
								<ZText
									onError={onError}
									required={true}
									label="CEP"
									mask="99999-999"
									value={data.endereco.cep}
									onBlur={handleChangeAddress('cep')}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={1}>
								<ZText
									onError={onError}
									required={true}
									label="Número"
									value={data.endereco.numero}
									onBlur={handleChangeAddress('numero')}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={3}>
								<ZText
									// inputProps={{ readOnly: true }}
									label="Logradouro"
									value={data.endereco.logradouro}
									onBlur={handleChangeAddress('logradouro')}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={2}>
								<ZText
									// inputProps={{ readOnly: true }}
									label="Bairro"
									value={data.endereco.bairro}
									onBlur={handleChangeAddress('bairro')}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={2}>
								<ZText
									// inputProps={{ readOnly: true }}
									label="Cidade"
									value={data.endereco.cidade}
									onBlur={handleChangeAddress('cidade')}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={1}>
								<ZText
									// inputProps={{ readOnly: true }}
									label="Estado"
									value={data.endereco.uf}
									onBlur={handleChangeAddress('uf')}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={3}>
								<ZText
									label="Complemento"
									value={data.endereco.complemento}
									onBlur={handleChangeAddress('complemento')}
								/>
							</Grid>
						</Grid>
						<Grid
							container
							direction="row"
							justifyContent="flex-end"
							alignItems="center"
							style={{ marginTop: 25 }}
						>
							<ZSaveButton
								onClick={handleSubmit}
								color={'primary'}
								align="right"
								label={'Salvar'}
								loading={loading}
							/>
						</Grid>
					</Paper>
				</Grid>
			</Grid>
		</Grid>
	);
};
