import React, { useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import { pt } from 'react-date-range/src/locale/index';
import { makeStyles } from '@material-ui/core/styles';
import { defaultStaticRanges, defaultInputRanges } from 'react-date-range/src/defaultRanges';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import { format } from 'date-fns';
import { FaTimes } from 'react-icons/fa';

const staticRangesLabels = ['Hoje', 'Ontem', 'Essa semana', 'Semana passada', 'Esse mês', 'Mês passado'];
const staticRanges = defaultStaticRanges.map((a, i) => {
	a.label = staticRangesLabels[i];
	return a;
});

const inputRangesLabels = ['dias até hoje', 'dias a partir de hoje'];
const inputRanges = defaultInputRanges.map((a, i) => {
	a.label = inputRangesLabels[i];
	return a;
});

const useStyles = makeStyles(theme => ({
	zDateRangePickerDiv: {
		width: '100%',
		position: 'relative',
		margin: '8px 10px',
	},
	clearBtn: {
		marginLeft: 6,
	},
	selectedDate: {
		fontFamily: "'Exo 2', sans-serif",
		borderRadius: 4,
		backgroundColor: theme.palette.common.white,
		border: '1px solid #ced4da',
		fontSize: 16,
		cursor: 'pointer',
		textAlign: 'center',
		padding: '8px 12px',
		[theme.breakpoints.up('md')]: {
			width: '100%',
		},
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
	},
	open: {
		position: 'absolute',
		top: 40,
		borderRadius: 10,
		padding: 10,
		zIndex: 1201,
	},
	closed: {
		display: 'none',
	},
	backdrop: {
		position: 'fixed',
		zIndex: 1200,
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		backgroundColor: 'rgba(0,0,0,0.3)',
	},
}));

const ZDateRangePicker = props => {
	const [startDate, setStartDate] = useState(props.startDate);
	const [endDate, setEndDate] = useState(props.endDate);
	const [open, setOpen] = useState(false);
	const [minDate] = useState(props.minDate || undefined);
	const [maxDate] = useState(props.maxDate || undefined);
	const label = props.label || 'Clique aqui para selecionar';

	const classes = useStyles();

	const handleSelect = ranges => {
		setStartDate(ranges.selection.startDate);
		setEndDate(ranges.selection.endDate);

		if (props.handleSelect) {
			props.handleSelect({ startDate: ranges.selection.startDate, endDate: ranges.selection.endDate });
		}
	};

	const handleOpen = () => {
		setOpen(!open);
	};

	const handleBackdropClick = () => {
		setOpen(false);

		if (props.onClose) {
			props.onClose({ startDate: startDate, endDate: endDate });
		}
	};

	const selectionRange = {
		startDate: startDate || new Date(),
		endDate: endDate || new Date(),
		key: 'selection',
	};

	const handleClearData = () => {
		setStartDate('');
		setEndDate('');

		if (props.clearDate) {
			props.clearDate();
		}
	};

	if (minDate) {
		inputRanges.splice(0, 1);
	}
	if (maxDate) {
		inputRanges.splice(1, 1);
	}

	React.useEffect(() => {
		// setStartDate(startDate);
		// setEndDate(endDate);
		// console.log(startDate, endDate);
	}, [props.startDate]);

	return (
		<div className={classes.zDateRangePickerDiv}>
			{open && <div className={classes.backdrop} onClick={handleBackdropClick}></div>}

			<div className={classes.selectedDate} onClick={handleOpen}>
				<div>
					{startDate && endDate && (
						<>{`${format(startDate, 'DD/MM/YYYY')} - ${format(endDate, 'DD/MM/YYYY')}`}</>
					)}
					{(!startDate || !endDate) && <>{label}</>}

					{startDate && endDate && props.clearDate && (
						<FaTimes className={classes.clearBtn} onClick={handleClearData} />
					)}
				</div>
			</div>
			{open && (
				<Grow in={open}>
					<Paper className={classes.open} style={{ right: props.modal ? -23 : null }}>
						<div>
							<DateRangePicker
								staticRanges={staticRanges}
								inputRanges={inputRanges}
								locale={pt}
								ranges={[selectionRange]}
								onChange={handleSelect}
								dateDisplayFormat={'DD/MM/YYYY'}
								minDate={minDate}
								maxDate={maxDate}
							/>
						</div>
					</Paper>
				</Grow>
			)}
		</div>
	);
};

export default ZDateRangePicker;
