import React from 'react';
import ZTitle from '../system/ZTitle';
import { Grid, Paper, TablePagination, Button, CircularProgress } from '@material-ui/core/';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import { Formatter } from '../../utils/';
import axios from 'axios';
import config from 'react-global-configuration';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

export default props => {

	const history = useHistory();
	// const dispatch = useDispatch();
	// const url = useSelector(store => store.system.config.hostname);
	// const classes = useStyles();
	const token = useSelector(store => store.auth.token);
	const [vendas, setVendas] = React.useState([]);
	const [loading, setLoading] = React.useState(true);
	const [pagination, setPagination] = React.useState({
		limit: 30,
		current: 0,
		total: 0,
	});

	const handleChangePage = (event, newPage) => {
		setPagination({
			...pagination,
			current: newPage,
		});
	};

	const handleChangeRowsPerPage = event => {
		setPagination({
			...pagination,
			limit: parseInt(event.target.value, 10),
			current: 0,
		});
	};

	React.useEffect(() => {
		const getData = async () => {
			if (!loading) {
				setLoading(true);
			}

			const res = await axios.get(
				config.get('apiUrl') +
				`vendas/pre-venda/${props.preVendaId}?limit=${pagination.limit}&page=${pagination.current}`,
				{
					headers: { Authorization: `Bearer ${token}` },
				}
			);

			if (res.data.success) {
				setVendas(res.data.data);
				setPagination({
					...pagination,
					limit: res.data.limit,
					total: res.data.total,
					current: res.data.page,
				});
			}

			setLoading(false);
		};

		getData();
		// eslint-disable-next-line
	}, [props.preVendaId, token, pagination.limit, pagination.current]);

	return (
		<Grid container>
			<ZTitle title="Pedidos" textAlign="center" />
			<Grid item xs={12} md={12} lg={12}>
				<Paper style={{ padding: '15px' }}>
					{loading === true && (
						<Grid item xs={12} align="center" style={{ padding: 60 }}>
							<CircularProgress />
						</Grid>
					)}

					{vendas.length > 0 && !loading && (
						<Table style={{ width: '100%' }}>
							<TableHead>
								<TableRow>
									<TableCell>Venda ID</TableCell>
									<TableCell>Status</TableCell>
									<TableCell>Data</TableCell>
									<TableCell>Bandeira</TableCell>
									<TableCell>Valor</TableCell>
									<TableCell align="right"></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{vendas.map((value, key) => {
									return (
										<TableRow>
											<TableCell>{value.id}</TableCell>
											<TableCell>{value.status_pedido.titulo}</TableCell>
											<TableCell>{moment(value.created).format('DD/MM/YYYY')}</TableCell>
											<TableCell>{value.bandeira ? value.bandeira.toUpperCase() : ''}</TableCell>
											<TableCell>{Formatter.Real(value.valor_bruto)}</TableCell>
											<TableCell align="right">
												<Button
													variant="outlined"
													onClick={() => history.push(`/vendas/detalhes/${value.id}`)}
													size="small"
												>
													{' '}
													Visualizar
												</Button>
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
							{/* <TableFooter> */}
							<TablePagination
								rowsPerPageOptions={[30, 60, 90]}
								count={pagination.total}
								rowsPerPage={pagination.limit}
								page={pagination.current}
								SelectProps={{
									inputProps: { 'aria-label': 'Linhas por página:' },
									native: true,
								}}
								onChangePage={handleChangePage}
								onChangeRowsPerPage={handleChangeRowsPerPage}
								labelRowsPerPage="Linhas por página:"
								labelDisplayedRows={({ from, to, count }) =>
									`${from}-${to === -1 ? count : to} de ${count}`
								}
							/>
							{/* </TableFooter> */}
						</Table>
					)}

					{!vendas.length && !loading && (
						<Grid item align="center" style={{ padding: 40 }}>
							Ainda não há vendas feitas através dessa URL.
						</Grid>
					)}
				</Paper>
			</Grid>
		</Grid>
	);
};
