/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
// import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import ZTitle from '../system/ZTitle';
import axios from 'axios';
import config from 'react-global-configuration';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { addMessage } from '../../store/alert';
import ZMenuOptions from '../system/ZMenuOptions';
import ZPagination from '../system/ZPagination';
import ZModalConfirm from '../system/ZModalConfirm';
import { Isset } from '../../utils';
import ZFiltros from '../system/ZFiltros';
import ZLoader from '../system/ZLoader';

export default props => {
	const history = useHistory();
	const token = useSelector(store => store.auth.token);
	const usuario = useSelector(store => store.auth.usuario);
	const estabelecimentoAdmin = useSelector(store => store.admin.estabelecimento);
	const dispatch = useDispatch();
	const { pathname } = useLocation();
	const pathnameSlug = pathname.toLowerCase().replace(/[^a-z0-9]/gi, '');

	const filtersCache = useSelector(store => store.filters[pathnameSlug] || {});

	const [loading, setLoading] = useState(true);
	const [data, setData] = useState({
		usuarios: [],
		quantiade: 0,
		paginas: 0,
	});

	const [removerUsuario, setRemoverUsuario] = useState({
		usuarioId: null,
		open: false,
	});

	const [alterarSenha, setAlterarSenha] = useState({
		usuarioEmail: null,
		open: false,
	});

	const { id: estabelecimentoId } = useSelector(state => state.admin.estabelecimento) || {};
	const [filtros, setFiltros] = useState({
		// estabelecimentoId: estabelecimentoId ? [estabelecimentoId] : [],
		omni: '',
		...filtersCache,
	});

	const filtrosChange = async data => {
		data.estabelecimentoId = [estabelecimentoId];

		if (Array.isArray(data.estabelecimento)) {
			data.estabelecimentoId = [estabelecimentoId, ...data.estabelecimento];
		}

		setFiltros({ ...filtros, ...data });
	};

	const getData = async () => {
		setLoading(true);
		let res = await axios.get(`${config.get('apiUrl')}usuarios`, {
			headers: { Authorization: `Bearer ${token}` },
			params: {
				page,
				limit: rowsPerPage,
				filters: {
					estabelecimentoId: Isset(() => estabelecimentoAdmin.id) ? estabelecimentoAdmin.id : null,
					...filtros,
				},
			},
		});

		if (res.data.success) {
			setData(res.data);
			setTotalRows(res.data.quantidade);
		}
		setLoading(false);
	};

	const handleRemoverUsuario = result => {
		const usuarioId = removerUsuario.usuarioId;

		if (result) {
			axios
				.delete(`${config.get('apiUrl')}usuarios/` + usuarioId, {
					headers: { Authorization: `Bearer ${token}` },
				})
				.then(res => res.data)
				.then(data => {
					if (data.success) {
						dispatch(addMessage({ type: 'success', message: 'Usuário removido com sucesso' }));
						getData();
					} else {
						dispatch(addMessage({ type: 'error', message: data.message }));
					}

					setRemoverUsuario({ ...removerUsuario, open: false, usuarioId: null });
				});
		}
	};

	const handleOnSave = async result => {
		const usuarioEmail = alterarSenha.usuarioEmail;

		if (result) {
			setLoading(true);
			const res = await axios.put(`${config.get('apiUrl')}usuarios/recuperar_senha`, {
				email: usuarioEmail,
			});
			if (res.data.success) {
				setLoading(false);
				dispatch(addMessage({ type: 'success', message: 'Verifique sua caixa de E-mail' }));
			} else {
				setLoading(false);
				dispatch(addMessage({ type: 'error', message: data.message }));
			}
		}

		setAlterarSenha({
			...alterarSenha,
			usuarioEmail: null,
			open: false,
		});
	};

	const onChangePage = newPage => {
		setPage(newPage);
	};

	const onChangeRowsPerPage = rowsPerPage => {
		setRowsPerPage(rowsPerPage);
		setPage(1);
	};

	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(15);
	const [totalRows, setTotalRows] = useState(0);

	useEffect(() => {
		getData();
	}, [page, rowsPerPage, filtros, estabelecimentoAdmin]);

	const handleAdd = () => {
		history.push('/usuarios/adicionar');
	};

	return (
		<Grid>
			<ZTitle
				title="Usuários"
				grupo={![10, 11, 12, 15].includes(usuario.grupo_id)}
				button="add"
				handleOnClick={() => {
					handleAdd();
				}}
			/>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper
						style={{
							display: 'flex',
							alignItems: 'center',
							marginBottom: 8,
							padding: '5px 10px',
						}}
					>
						<ZFiltros
							onChange={filtrosChange}
							showFilterButton={true}
							input={[
								{
									key: 'omni',
									placeholder: 'Nome/E-mail',
									defaultValue: filtros.omni,
								},
							]}
						/>
					</Paper>
				</Grid>
				<Grid item xs={12}>
					<Paper style={{ overflowX: 'auto', width: 'calc(100vw - 45px)' }}>
						{loading ? (
							<ZLoader height={200} />
						) : (
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>Nome</TableCell>
										<TableCell align="right">E-mail</TableCell>
										<TableCell align="right">Sexo</TableCell>
										<TableCell align="right">Grupo</TableCell>
										{usuario.grupo_id < 3 && <TableCell align="right">Opções</TableCell>}
									</TableRow>
								</TableHead>
								<TableBody>
									{data.usuarios.map((value, key) => {
										return (
											<TableRow key={key}>
												<TableCell component="th" scope="row">
													{value.nome}
												</TableCell>
												<TableCell align="right">{value.email}</TableCell>
												<TableCell align="right">{value.sexo}</TableCell>
												<TableCell align="right">
													{value.grupo_id === 5 && value.representantes ? (
														<Tooltip
															title={
																<>
																	{`${value.representantes}`.split(',').map(n => (
																		<div key={n}>{n}</div>
																	))}
																</>
															}
														>
															<div>{value.grupo.nome}</div>
														</Tooltip>
													) : (
														<div>{value.grupo.nome}</div>
													)}
												</TableCell>
												{usuario.grupo_id < 3 && (
													<TableCell align="right">
														<ZMenuOptions
															data={[
																{
																	title: 'Editar',
																	onClick: () => {
																		history.push('/usuarios/gerenciar/' + value.id);
																	},
																},
																{
																	title: 'Configurar Comissão',
																	visible: [5, 8].includes(value.grupo.id),
																	onClick: () => {
																		history.push(
																			'/representante/comissao/' + value.id
																		);
																	},
																},
																{
																	title: 'Alterar senha',
																	onClick: () => {
																		setAlterarSenha({
																			...alterarSenha,
																			usuarioEmail: value.email,
																			open: true,
																		});
																	},
																},
																{
																	title: 'Remover',
																	onClick: () => {
																		setRemoverUsuario({
																			...removerUsuario,
																			usuarioId: value.id,
																			open: true,
																		});
																	},
																},
															]}
														/>
													</TableCell>
												)}
											</TableRow>
										);
									})}
								</TableBody>
								<ZPagination
									count={totalRows}
									rowsPerPage={rowsPerPage}
									page={page - 1}
									onChangePage={onChangePage}
									onChangeRowsPerPage={onChangeRowsPerPage}
								/>
							</Table>
						)}
					</Paper>
				</Grid>
			</Grid>
			<ZModalConfirm
				title={`Remover usuário`}
				message={`Deseja remover esse usuário?`}
				open={removerUsuario.open}
				onClose={handleRemoverUsuario}
			/>
			<ZModalConfirm
				title={`Alterar senha`}
				message={`Redefinição de senha será enviada por e-mail`}
				submessage={`Deseja enviar ?`}
				open={alterarSenha.open}
				onClose={handleOnSave}
			/>
		</Grid>
	);
};
