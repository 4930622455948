import React, { useState, useCallback, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ZTitle from '../system/ZTitle';
import axios from 'axios';
import config from 'react-global-configuration';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import ZMenuOptions from '../system/ZMenuOptions';
import ZPagination from '../system/ZPagination';
import ZFiltros from '../system/ZFiltros';
import ZLoader from '../system/ZLoader';
import FileDownload from 'js-file-download';
import { addMessage } from '../../store/alert';
import { FaDownload } from 'react-icons/fa';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
	buttonExport: {
		color: '#040633',
		border: `solid 1px #040633`,
		'&:hover': {
			color: '#FFF',
			backgroundColor: '#011d29',
			'& svg': {
				color: '#FFF',
			},
		},
		[theme.breakpoints.down('xs')]: {
			width: '90%',
			marginTop: 16,
		},
		'& svg': {
			margin: 0,
		},
	},
	iconButton: {
		minWidth: 0,
	},
}));

export default () => {
	const token = useSelector(store => store.auth.token);
	const usuario = useSelector(store => store.auth.usuario);
	const { pathname } = useLocation();

	const classes = useStyles();

	const dispatch = useDispatch();

	const history = useHistory();
	const pathnameSlug = pathname.toLowerCase().replace(/[^a-z0-9]/gi, '');
	const filtersCache = useSelector(store => store.filters[pathnameSlug] || {});
	const { id: estabelecimentoId } = useSelector(state => state.admin.estabelecimento) || {};

	// const [showExportMessage, setShowExportMessage] = useState(false);

	const [loading, setLoading] = useState(false);
	const [data, setData] = useState({
		clientes: [],
		quantidade: 0,
		paginas: 0,
	});
	const [filtros, setFiltros] = useState({
		omni: '',
		...filtersCache,
	});

	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(30);
	const [totalRows, setTotalRows] = useState(0);

	// useEffect(() => {
	// 	getData();
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	// useEffect(() => {
	// 	getData();
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [page, rowsPerPage]);

	useEffect(() => {
		getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtros, estabelecimentoId, page, rowsPerPage]);

	const getData = useCallback(async () => {
		setLoading(true);

		const response = await axios.get(`${config.get('apiUrl')}clientes`, {
			headers: { Authorization: `Bearer ${token}` },
			params: {
				page,
				limit: rowsPerPage,
				filters: {
					estabelecimentoId,
					documento: filtros.searchEstabelecimentoByCpfOrCNPJ,
					contato: filtros.omni2,
					...filtros,
				},
			},
		});

		if (response.data.success) {
			setData(response.data);

			setTotalRows(response.data.quantidade);
		}

		setLoading(false);
	}, [page, rowsPerPage, token, filtros, estabelecimentoId]);

	const handleFiltrosChange = useCallback(data => {
		setFiltros(data);
	}, []);

	const onChangePage = useCallback(newPage => {
		setPage(newPage);
	}, []);

	const onChangeRowsPerPage = useCallback(rowsPerPage => {
		setRowsPerPage(rowsPerPage);
		setPage(1);
	}, []);

	const exportXls = async () => {
		// setShowExportMessage(true);
		const clientes = await axios.get(`${config.get('apiUrl')}export/clientes`, {
			headers: { Authorization: `Bearer ${token}` },
			responseType: 'blob',
			params: {
				filters: {
					...filtros,
				},
			},
		});

		if (clientes.status === 200) {
			const { 'content-disposition': contentDisposition, 'content-type': contentType } = clientes.headers;
			let filename = 'clientes.xlsx';
			if (contentDisposition.indexOf('filename="') !== -1) {
				filename = contentDisposition.slice(
					contentDisposition.indexOf('filename="') + 10,
					contentDisposition.length - 1
				);
			}

			FileDownload(clientes.data, filename, contentType);
		} else {
			dispatch(addMessage({ type: 'error', message: 'Erro ao exportar clientes.' }));
		}
		// setShowExportMessage(false);
	};

	return (
		<Grid>
			<ZTitle title="Clientes" grupo={usuario.grupo_id} />
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper
						style={{
							position: 'relative',
							marginBottom: 10,
							padding: '5px 10px',
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<ZFiltros
							onChange={handleFiltrosChange}
							input={[
								{
									key: 'omni',
									defaultValue: filtros.omni,
									modal: false,
									placeholder: 'Nome/E-mail',
									md: 2,
								},
								{
									key: 'omni2',
									defaultValue: filtros.omni2,
									placeholder: 'Celular',
									mask: 'celular',
									modal: false,
									md: 2,
								},
								{
									key: 'searchEstabelecimentoByCpfOrCNPJ',
									placeholder: 'CPF',
									defaultValue: filtros.documento,
									modal: false,
									mask: 'cpf',
									md: 2,
								},
							]}
							// omni={{
							// 	default: filtros.omni || '',
							// 	placeholder: 'Nome/Email',
							// }}
							// omni2={{
							// 	default: filtros.omni2 || '',
							// 	placeholder: 'Celular',
							// }}
							// searchEstabelecimentoByCpfOrCNPJ={{
							// 	value: filtros.documento || '',
							// 	placeholder: 'CPF',
							// }}
						/>
						<Tooltip title="Exportar">
							<Button className={clsx(classes.buttonExport, classes.iconButton)} onClick={exportXls}>
								<FaDownload className={classes.exportDownload} />
							</Button>
						</Tooltip>
					</Paper>
				</Grid>
				<Grid item xs={12}>
					<Paper style={{ overflowX: 'auto', width: 'calc(100vw - 45px)' }}>
						{loading ? (
							<ZLoader height={200} />
						) : (
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>Nome</TableCell>
										<TableCell align="right">E-mail</TableCell>
										<TableCell align="right">Sexo</TableCell>
										<TableCell align="right">Opções</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{data.clientes.map(cliente => (
										<TableRow key={cliente.id}>
											<TableCell component="th" scope="row">
												{cliente.nome}
											</TableCell>
											<TableCell align="right">{cliente.email}</TableCell>
											<TableCell align="right">{cliente.sexo}</TableCell>
											<TableCell align="right">
												{[1, 2, 7, 10].includes(usuario.grupo_id) && (
													<ZMenuOptions
														data={[
															{
																title: 'Editar',
																onClick: () => {
																	history.push(`/cliente/editar/${cliente.id}`);
																},
															},
															{
																title: 'Nova Venda',
																onClick: () => {
																	history.push(
																		`/vendas/nova/${cliente.clientes_documentos[0].documento}`
																	);
																},
															},
														]}
													/>
												)}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
								<ZPagination
									count={totalRows}
									rowsPerPage={rowsPerPage}
									page={page - 1}
									onChangePage={onChangePage}
									onChangeRowsPerPage={onChangeRowsPerPage}
								/>
							</Table>
						)}
					</Paper>
				</Grid>
			</Grid>
		</Grid>
	);
};
