import axios from 'axios';

const setupAxiosInterceptors = () => {
    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error.response && error.response.status === 401) {
                alert('Sua sessão expirou. Faça login novamente.');
                localStorage.setItem('token', null);
                localStorage.setItem('mainToken', null);
                localStorage.setItem('loggedAt', null);

                window.location = '/app';
                return false;
            }
            return Promise.reject(error);
        }
    );
};

export default setupAxiosInterceptors;