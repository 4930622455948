/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import ZSaveButton from '../system/ZSaveButton';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import moment from 'moment';
import axios from 'axios';

import { checkLogin } from '../../store/auth';
import { addMessage } from '../../store/alert';

import { useSelector, useDispatch } from 'react-redux';
import ZAlert from '../system/ZAlert';
import config from 'react-global-configuration';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import expirationImage from '../../assets/expirationTime.png';

import { validatePassword } from '../../utils';

const useStyles = makeStyles(theme => ({
	'@global': {
		body: {
			backgroundColor: theme.palette.common.white,
		},
	},
	paper: {
		marginTop: theme.spacing(12),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		width: '100%',
		// backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	configBox: {
		position: 'relative',
		padding: 10,
		minHeight: 150,
	},
	configHeader: {
		fontWeight: 700,
		fontSize: 22,
	},
}));

export default props => {
	let params = props.location.search.split('?');
	let tokenSenha = params[1].split('=')[1];

	const history = useHistory();
	const dispatch = useDispatch();
	const systemConfig = useSelector(store => store.system.config);
	const logado = useSelector(store => store.auth.logado);
	const message = useSelector(store => store.alert);

	const [loader, setLoader] = useState(false);
	const [expiration, setExpiration] = useState(true);
	const [showPassword, setShowPassword] = useState(false);

	const [senha, setSenha] = useState({
		new: '',
		confirm: '',
		errors: false,
	});

	useEffect(() => {
		dispatch(checkLogin());
	}, []);

	const classes = useStyles();

	const [loading, setLoading] = useState(false);

	const handleShowPasswordClick = () => {
		setShowPassword(prevState => !prevState);
	};

	const handleAtualizarClick = async value => {
		const validacao = validatePassword(senha.new, senha.confirm);

		if (validacao.success) {
			try {
				setLoading(true);
				const res = await axios.put(`${config.get('apiUrl')}usuarios/recuperar_senha/nova_senha`, {
					tokenSenha,
					new: senha.new,
					confirm: senha.confirm,
				});

				if (res.data.success) {
					setSenha({
						errors: false,
						current: '',
						new: '',
						confirm: '',
					});
					setLoading(false);
					dispatch(addMessage({ type: 'success', message: res.data.message }));
					window.location = '/';
				}
			} catch (e) {
				console.log(e.response);
				setLoading(false);
				dispatch(addMessage({ type: 'error', message: e.response.statusText }));
			}
		}
		//popup de erro removido pois já há mensagem de erro abaixo dos campos.
		//dispatch(addMessage({ type: 'error', message: validacao.errors }));

		setSenha({
			...senha,
			errors: validacao.errors,
		});
	};

	useEffect(() => {
		validated();
	}, []);

	const validated = async () => {
		const res = await axios.post(`${config.get('apiUrl')}usuarios/recuperar_senha/validacao`, {
			tokenSenha,
		});
		if (res.data.success) {
			setExpiration(false);
		}
		setLoader(true);
	};

	if (logado) {
		history.push('/');
	}

	if (expiration) {
		return (
			<div style={{ background: systemConfig.backgroundColor }}>
				<Container maxWidth="md" style={{ height: '100vh' }}>
					<Grid
						container
						direction="row"
						justifyContent="center"
						alignItems="center"
						style={{ height: '100%' }}
					>
						<Grid item xs={12} style={{ background: systemConfig.backgroundColor, padding: 20 }}>
							<Grid container direction="row" justifyContent="center" alignItems="center">
								<Grid item xs={12} sm={6} lg={6} style={{}}>
									<Grid container direction="row" justifyContent="center" alignItems="center">
										<Grid item xs={12} style={{ padding: 30 }} align="center">
											<Link href={systemConfig.website} color="inherit">
												<img
													src={systemConfig.logoUrl}
													style={{ maxWidth: '100%' }}
													alt={`Logo ${systemConfig.nome}`}
													title={`Logo ${systemConfig.nome}`}
												/>
											</Link>
										</Grid>
									</Grid>
								</Grid>
								<Grid
									item
									xs={12}
									sm={6}
									lg={6}
									style={{ background: 'white', padding: 25, borderRadius: 5 }}
								>
									{loader && (
										<div style={{ display: 'flex' }}>
											<img
												src={expirationImage}
												style={{ maxWidth: '300px', margin: 'auto' }}
												alt={`Logo ${systemConfig.nome}`}
												title={`Logo ${systemConfig.nome}`}
											/>
										</div>
									)}
									<div style={{ textAlign: 'center' }}>
										{!loader ? (
											<>
												<Typography style={{ fontSize: '22px' }}>Carregando ...</Typography>
												<CircularProgress />
											</>
										) : (
											<>
												<Typography>Desculpe, mas este link expirou.</Typography>
												<Typography>
													O link para recuperar a senha tem validade de 24 horas.
												</Typography>
											</>
										)}
									</div>
									<Box mt={5}>
										<Typography variant="body2" color="textSecondary" align="center">
											Sistema de Pagamentos
											<br />
											{`© ${systemConfig.nome} ` + moment().format('YYYY')}
										</Typography>
									</Box>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Container>
			</div>
		);
	}
	return (
		<div style={{ background: systemConfig.backgroundColor }}>
			<Container maxWidth="md" style={{ height: '100vh' }}>
				<Grid container direction="row" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
					<Grid item xs={12} style={{ background: systemConfig.backgroundColor, padding: 20 }}>
						<Grid container direction="row" justifyContent="center" alignItems="center">
							<Grid item xs={12} sm={6} lg={6} style={{}}>
								<Grid container direction="row" justifyContent="center" alignItems="center">
									<Grid item xs={12} style={{ padding: 30 }} align="center">
										<Link href={systemConfig.website} color="inherit">
											<img
												src={systemConfig.logoUrl}
												style={{ maxWidth: '100%' }}
												alt={`Logo ${systemConfig.nome}`}
												title={`Logo ${systemConfig.nome}`}
											/>
										</Link>
									</Grid>
								</Grid>
							</Grid>
							<Grid
								item
								xs={12}
								sm={6}
								lg={6}
								style={{ background: 'white', padding: 25, borderRadius: 5 }}
							>
								<div className={classes.configBox}>
									<div className={classes.configHeader}>Alterar senha</div>
									<p className={classes.passwordInstruction}>
										<small>
											A Senha deve conter mais de 8 caracteres, letras e números. Além de letras
											maiúsculas e caracteres especiais
										</small>
									</p>
									<Grid item xs={12}>
										{/* <ZText
											label="Nova senha"
											onBlur={e => setSenha({ ...senha, new: e.target.value })}
											value={senha.new}
											type="password"
											id="new"
										/> */}
										<TextField
											variant="outlined"
											margin="normal"
											required
											fullWidth
											label="Nova senha"
											type={showPassword ? 'text' : 'password'}
											id="new"
											onChange={e => setSenha({ ...senha, new: e.target.value })}
											value={senha.new}
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<IconButton onClick={handleShowPasswordClick}>
															{showPassword ? <VisibilityOff /> : <Visibility />}
														</IconButton>
													</InputAdornment>
												),
											}}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											variant="outlined"
											margin="normal"
											required
											fullWidth
											name="password"
											label="Confirme a nova senha"
											type={showPassword ? 'text' : 'password'}
											id="confirm"
											onKeyPress={e => (e.key === 'Enter' ? handleAtualizarClick() : null)}
											onChange={e => setSenha({ ...senha, confirm: e.target.value })}
											value={senha.confirm}
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<IconButton onClick={handleShowPasswordClick}>
															{showPassword ? <VisibilityOff /> : <Visibility />}
														</IconButton>
													</InputAdornment>
												),
											}}
										/>
									</Grid>
									{senha.errors.length && (
										<Grid item align="center" xs={12}>
											<small style={{ color: 'red' }}> {senha.errors}</small>
										</Grid>
									)}
								</div>
								<div style={{ margin: '6px 0 6px 0' }}>
									<ZSaveButton
										onClick={() => handleAtualizarClick('password', senha)}
										color={'primary'}
										align="right"
										label={'Alterar senha'}
										loading={loading}
									/>
								</div>
								<Box mt={5}>
									<Typography variant="body2" color="textSecondary" align="center">
										Sistema de Pagamentos
										<br />
										{`© ${systemConfig.nome} ` + moment().format('YYYY')}
									</Typography>
								</Box>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				{message.message.length > 0 && (
					<Grid item xs={12}>
						<ZAlert type={message.type} message={message.message} time={moment().unix()} vertical="top" />
					</Grid>
				)}
			</Container>
		</div>
	);
};
