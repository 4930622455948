import React, { useState, useEffect } from 'react';
import { Grid, Typography, Container, Paper, Button, TextField } from '@material-ui/core';
import ZAlert from '../system/ZAlert';
import ZTable from '../system/ZTable';
import ZTitle from '../system/ZTitle';
import ZLoader from '../system/ZLoader';
import ZModalDatePicker from '../system/ZModalDatePicker';
import ZModalConfirm from '../system/ZModalConfirm';
import ZModal from '../system/ZModal';
import { Mask, Isset } from '../../utils';
import { useSelector, useDispatch } from 'react-redux';
import { addMessage } from '../../store/alert';
import config from 'react-global-configuration';
import axios from 'axios';

import { useHistory, useRouteMatch } from 'react-router-dom';
import moment from 'moment';
import ZMenuOptions from '../system/ZMenuOptions';

export default props => {
	// const { match, history } = useReactRouter();
	const history = useHistory();
	const match = useRouteMatch();

	const dispatch = useDispatch();
	const message = useSelector(state => state.alert);
	const [text, setText] = useState('Carregando...');
	const [loading, setLoading] = useState(true);
	const [faturas, setFaturas] = useState([]);
	const [alterarData, setAlterarData] = useState({
		assinaturaId: null,
		open: false,
		newDate: moment()
			.add(1, 'days')
			.format('YYYY-MM-DD'),
		expiration_date: moment()
			.add(1, 'days')
			.format('YYYY-MM-DD'),
		tipoData: null,
	});

	const [dataAssinatura, setAssinatura] = useState({
		planoId: '',
		cliente: {
			id: '',
			nome: '',
			email: '',
			dataNascimento: '',
			cpf: '',
			cnpj: '',
			socio: '',
			socioCpf: '',
		},
		endereco: {
			logradouro: '',
			numero: '',
			cep: '',
			cidade: '',
			estado: '',
		},
		cartao: {
			titular: '',
			validade: '',
			numero: '',
			codigoSeguranca: '',
			bandeira: '',
		},
		name: '',
		description: '',
		setup_amount: '',
		amount: '',
		grace_period: '',
		tolerance_period: '',
		subscription_id: '',
		payment_method: '',
		due_date: '',
		due_since_date: '',
		status_assinatura_id: '',
		suspended_at: '',
	});
	const [alterarValor, setAlterarValor] = useState({
		amount: 0,
		open: false,
	});

	const [estornarAssinatura, setEstornarAssinatura] = useState({
		invoiceId: null,
		open: false,
	});

	const token = useSelector(store => store.auth.token);

	const estorno = useSelector(store =>
		Isset(() => store.system.params['26'].valor) ? store.system.params['26'].valor : null
	);

	//Paginação
	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(8);
	const [totalRows, setTotalRows] = useState(0);

	const onChangePage = newPage => {
		setPage(newPage);
	};

	const onChangeRowsPerPage = rowsPerPage => {
		setRowsPerPage(rowsPerPage);
		setPage(1);
	};

	const getData = () => {
		axios
			.get(config.get('apiUrl') + 'planos/assinatura/' + match.params.assinaturaId, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then(res => {
				if (res.data.success) {
					let data = res.data.data;
					let cpf =
						data.cliente.clientes_documentos[0].tipo_documento_id === 2
							? data.cliente.clientes_documentos[0].documento
							: '';
					let cnpj =
						data.cliente.clientes_documentos[0].tipo_documento_id === 3
							? data.cliente.clientes_documentos[0].documento
							: '';

					setAssinatura({
						planoId: data.plano_id,
						socio: data.socio,
						socioCpf: data.socioCpf,
						cliente: {
							id: data.cliente.id,
							nome: data.cliente.nome,
							email: data.cliente.email,
							dataNascimento: data.cliente.data_nascimento,
							cpf: cpf,
							cnpj: cnpj,
						},
						clientes_contatos: [
							{
								id: data.cliente.clientes_contatos[0].id,
								tipo_contato_id: data.cliente.clientes_contatos[0].tipo_contato_id,
								contato: data.cliente.clientes_contatos[0].contato,
							},
							{
								// id: data.cliente.clientes_contatos[1].id || null,
								// tipo_contato_id: data.cliente.clientes_contatos[1].tipo_contato_id || null,
								// contato: data.cliente.clientes_contatos[1].contato || '',
								id: Isset(() => data.cliente.clientes_contatos[1].id)
									? data.cliente.clientes_contatos[1].id
									: null,
								tipo_contato_id: Isset(() => data.cliente.clientes_contatos[1].tipo_contato_id)
									? data.cliente.clientes_contatos[1].tipo_contato_id
									: null,
								contato: Isset(() => data.cliente.clientes_contatos[1].contato)
									? data.cliente.clientes_contatos[1].contato
									: '',
							},
						],
						endereco: {
							logradouro: data.cliente.endereco.logradouro,
							numero: data.cliente.endereco.numero,
							cep: data.cliente.endereco.cep,
							cidade: data.cliente.endereco.cidade,
							estado: data.cliente.endereco.uf,
						},
						cartao: {
							titular: Isset(() => data.cliente.clientes_cartoes[0].nome_titular)
								? data.cliente.clientes_cartoes[0].nome_titular
								: '',
							validade: Isset(() => data.cliente.clientes_cartoes[0].ano_expiracao)
								? data.cliente.clientes_cartoes[0].ano_expiracao
								: '',
							numero: Isset(() => data.cliente.clientes_cartoes[0].ultimos_digitos)
								? data.cliente.clientes_cartoes[0].ultimos_digitos
								: '',
							bandeira: Isset(() => data.cliente.clientes_cartoes[0].bandeira)
								? data.cliente.clientes_cartoes[0].bandeira
								: '',
							codigoSeguranca: '',
						},
						name: data.plano.name,
						description: data.plano.description,
						setup_amount: data.plano.setup_amount,
						amount: data.amount,
						grace_period: Isset(() => data.grace_period) ? data.grace_period : '',
						tolerance_period: Isset(() => data.tolerance_period) ? data.tolerance_period : '',
						subscription_id: Isset(() => data.subscription_id) ? data.subscription_id : '',
						due_date: data.due_date,
						due_since_date: data.due_since_date,
						expiration_date: data.expiration_date,
						payment_method: data.payment_method,
						status_assinatura_id: data.status_assinatura_id,
						suspended_at: data.suspended_at ? data.suspended_at : null,
					});

					axios
						.get(config.get('apiUrl') + `planos/assinatura/${match.params.assinaturaId}/faturas`, {
							headers: { Authorization: `Bearer ${token}` },
							params: {
								page,
								limit: rowsPerPage,
							},
						})
						.then(res => {
							setFaturas(res.data.faturas);
							setTotalRows(res.data.totalRows);
						});
				} else {
					setText('Não encontramos essa assinatura.');
				}
				setLoading(false);
			});
	};

	useEffect(() => {
		if (match.params.assinaturaId) {
			axios
				.get(config.get('apiUrl') + 'planos/assinatura/' + match.params.assinaturaId, {
					headers: { Authorization: `Bearer ${token}` },
				})
				.then(res => {
					if (res.data.success) {
						let data = res.data.data;
						let cpf =
							data.cliente.clientes_documentos[0].tipo_documento_id === 2
								? data.cliente.clientes_documentos[0].documento
								: '';
						let cnpj =
							data.cliente.clientes_documentos[0].tipo_documento_id === 3
								? data.cliente.clientes_documentos[0].documento
								: '';

						setAssinatura({
							planoId: data.plano_id,
							socio: data.socio,
							socioCpf: data.socioCpf,
							cliente: {
								id: data.cliente.id,
								nome: data.cliente.nome,
								email: data.cliente.email,
								dataNascimento: data.cliente.data_nascimento,
								cpf: cpf,
								cnpj: cnpj,
							},
							clientes_contatos: [
								{
									id: data.cliente.clientes_contatos[0].id,
									tipo_contato_id: data.cliente.clientes_contatos[0].tipo_contato_id,
									contato: data.cliente.clientes_contatos[0].contato,
								},
								{
									// id: data.cliente.clientes_contatos[1].id || null,
									// tipo_contato_id: data.cliente.clientes_contatos[1].tipo_contato_id || null,
									// contato: data.cliente.clientes_contatos[1].contato || '',
									id: Isset(() => data.cliente.clientes_contatos[1].id)
										? data.cliente.clientes_contatos[1].id
										: null,
									tipo_contato_id: Isset(() => data.cliente.clientes_contatos[1].tipo_contato_id)
										? data.cliente.clientes_contatos[1].tipo_contato_id
										: null,
									contato: Isset(() => data.cliente.clientes_contatos[1].contato)
										? data.cliente.clientes_contatos[1].contato
										: '',
								},
							],
							endereco: {
								logradouro: data.cliente.endereco.logradouro,
								numero: data.cliente.endereco.numero,
								cep: data.cliente.endereco.cep,
								cidade: data.cliente.endereco.cidade,
								estado: data.cliente.endereco.uf,
							},
							cartao: {
								titular: Isset(() => data.cliente.clientes_cartoes[0].nome_titular)
									? data.cliente.clientes_cartoes[0].nome_titular
									: '',
								validade: Isset(() => data.cliente.clientes_cartoes[0].ano_expiracao)
									? data.cliente.clientes_cartoes[0].ano_expiracao
									: '',
								numero: Isset(() => data.cliente.clientes_cartoes[0].ultimos_digitos)
									? data.cliente.clientes_cartoes[0].ultimos_digitos
									: '',
								bandeira: Isset(() => data.cliente.clientes_cartoes[0].bandeira)
									? data.cliente.clientes_cartoes[0].bandeira
									: '',
								codigoSeguranca: '',
							},
							name: data.plano.name,
							description: data.plano.description,
							setup_amount: data.plano.setup_amount,
							amount: data.amount,
							grace_period: Isset(() => data.grace_period) ? data.grace_period : '',
							tolerance_period: Isset(() => data.tolerance_period) ? data.tolerance_period : '',
							subscription_id: Isset(() => data.subscription_id) ? data.subscription_id : '',
							created: data.created,
							due_date: data.due_date,
							due_since_date: data.due_since_date,
							expiration_date: data.expiration_date,
							status_assinatura_id: data.status_assinatura_id,
							payment_method: data.payment_method,
							suspended_at: data.suspended_at ? data.suspended_at : null,
						});
					} else {
						setText('Não encontramos essa assinatura.');
					}
					setLoading(false);
				});

			axios
				.get(config.get('apiUrl') + `planos/assinatura/${match.params.assinaturaId}/faturas`, {
					headers: { Authorization: `Bearer ${token}` },
					params: {
						page,
						limit: rowsPerPage,
					},
				})
				.then(res => {
					setFaturas(res.data.faturas);
					setTotalRows(res.data.totalRows);
				});

			// axios.get(config.get('apiUrl')+`planos/assinatura/${match.params.assinaturaId}/pagamentos`,
			// {
			//     headers: { Authorization: `Bearer ${token}` }
			// }).then(res  => {
			//     if(res.data.success){
			//         let data = res.data.data;

			//         setRows({
			//             id: data.plano_id,
			//             date: data.plano.name,
			//             amount: data.plano.description,
			//             status: data.plano.setup_amount,
			//         });
			//     }else{
			//         setText('Não encontramos essa assinatura.');
			//     }
			//     setLoading(false);
			// });
		}
	}, [match.params.assinaturaId, page, props.assinaturaId, props.planoId, rowsPerPage, token]);

	const handleAlterarData = result => {
		const assinaturaId = match.params.assinaturaId;
		const newDate = alterarData.newDate;
		// const { expiration_date } = alterarData;
		setAlterarData({ ...alterarData, open: false, assinaturaId: null });
		setLoading(true);

		axios
			.put(
				`${config.get('apiUrl')}planos/assinatura/${assinaturaId}`,
				{
					due_date: newDate,
					// expiration_date,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then(res => res.data)
			.then(data => {
				if (data.success) {
					dispatch(addMessage({ type: 'success', message: 'Assinatura atualizada com sucesso' }));
					getData();
				} else {
					dispatch(addMessage({ type: 'error', message: data.message }));
					setLoading(false);
				}
			});
	};

	const handleAlterarDataExpiracao = result => {
		const assinaturaId = match.params.assinaturaId;
		// const newDate = alterarData.newDate;
		const { expiration_date } = alterarData;
		setAlterarData({ ...alterarData, open: false, assinaturaId: null });
		setLoading(true);

		axios
			.put(
				`${config.get('apiUrl')}planos/assinatura/${assinaturaId}`,
				{
					// due_date: newDate,
					expiration_date,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then(res => res.data)
			.then(data => {
				if (data.success) {
					dispatch(addMessage({ type: 'success', message: 'Assinatura atualizada com sucesso' }));
					getData();
				} else {
					dispatch(addMessage({ type: 'error', message: data.message }));
					setLoading(false);
				}
			});
	};

	const handleAlterarValor = () => {
		const { assinaturaId } = match.params;
		const { amount } = alterarValor;
		setLoading(true);

		axios
			.put(
				`${config.get('apiUrl')}planos/assinatura/${assinaturaId}`,
				{
					amount: (amount * 100).toString(),
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then(response => response.data)
			.then(data => {
				if (data.success) {
					dispatch(addMessage({ type: 'success', message: data.message }));

					getData();

					setAlterarValor({ ...alterarValor, open: false });
				} else {
					dispatch(addMessage({ type: 'error', message: data.message }));

					setLoading(false);
				}
			});
	};

	const handleEstornarAssinatura = result => {
		const invoiceId = estornarAssinatura.invoiceId;
		setEstornarAssinatura({ ...estornarAssinatura, open: false, invoiceId: null });

		if (result) {
			setLoading(true);
			axios
				.post(
					`${config.get('apiUrl')}planos/assinatura/estornar`,
					{
						id: invoiceId,
					},
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				)
				.then(res => res.data)
				.then(data => {
					if (data.success) {
						dispatch(addMessage({ type: 'success', message: 'Pagamento estornada com sucesso' }));
						getData();
					} else {
						dispatch(addMessage({ type: 'error', message: data.message }));
						setLoading(false);
					}
				});
		}
	};

	const handleClose = () => {
		setAlterarData({ ...alterarData, open: false });
	};

	if (loading) {
		return <ZLoader size={30} height={60} />;
	} else if (!dataAssinatura.cliente.nome && !loading) {
		return (
			<div>
				<Container maxWidth="md">
					<Grid
						container
						direction="row"
						justifyContent="center"
						alignItems="center"
						style={{ height: '80vh' }}
					>
						<Paper style={{ padding: 45 }}>
							<Grid item xs={12} align="center">
								<Typography variant="h4" gutterBottom>
									{text}
								</Typography>
							</Grid>
						</Paper>
					</Grid>
				</Container>
			</div>
		);
	}

	if (dataAssinatura.cliente.nome) {
		let status = '';
		switch (dataAssinatura.status_assinatura_id) {
			case 1:
				status = 'Aguardando';
				break;
			case 2:
				status = 'Cancelado';
				break;
			case 3:
				status = 'Pago';
				break;
			case 4:
				status = 'Suspenso';
				break;
			case 5:
				status = 'Atrasado';
				break;
			default:
				break;
		}

		return (
			<div>
				<Container maxWidth="lg">
					<Grid container>
						{message.message && (
							<ZAlert type={message.type} message={message.message} time={moment().unix()} />
						)}
						<Grid item xs={12}>
							<ZTitle title="Detalhes da assinatura" />
						</Grid>
						<Grid item xs={12}>
							<Paper style={{ padding: 20, marginBottom: 50 }}>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<Typography variant="h5" gutterBottom>
											Plano
										</Typography>
									</Grid>
									<Grid item xs={12} sm={6} md={8}>
										<Typography variant="subtitle1" gutterBottom>
											{dataAssinatura.name}
										</Typography>
										<Typography variant="subtitle2" gutterBottom>
											{dataAssinatura.description}
										</Typography>
									</Grid>
									<Grid item xs={12} sm={6} md={2}>
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
												flexShrink: 0,
											}}
										>
											<Typography
												style={{ marginRight: '16px' }}
												variant="subtitle1"
												gutterBottom
											>
												Valor <br />
												{Mask.Real(dataAssinatura.amount / 100)}
											</Typography>
											{status !== 'Suspenso' && (
												<Button
													variant="outlined"
													color="default"
													onClick={() => setAlterarValor({ ...alterarValor, open: true })}
												>
													Alterar Valor
												</Button>
											)}
										</div>
									</Grid>
									<Grid item xs={12} sm={6} md={2}>
										<Button variant="outlined" color="default">
											Status Recorrência:
											<br /> {status}
										</Button>
									</Grid>

									{['credit', 'credito'].includes(dataAssinatura.payment_method) && (
										<>
											<Grid item xs={12} style={{ marginTop: 50 }}>
												<Typography variant="h5" gutterBottom>
													Cartão
												</Typography>
											</Grid>
											<Grid item xs={12} sm={6} md={3}>
												<Typography variant="subtitle1" display="block" gutterBottom>
													Bandeira
												</Typography>
												<Typography variant="subtitle2" gutterBottom>
													{dataAssinatura.cartao.bandeira}
												</Typography>
											</Grid>
											<Grid item xs={12} sm={6} md={3}>
												<Typography variant="subtitle1" display="block" gutterBottom>
													Número
												</Typography>
												<Typography variant="subtitle2" gutterBottom>
													**** **** **** {dataAssinatura.cartao.numero}
												</Typography>
											</Grid>
											<Grid item xs={12} sm={6} md={3}>
												<Typography variant="subtitle1" display="block" gutterBottom>
													Titular
												</Typography>
												<Typography variant="subtitle2" gutterBottom>
													{dataAssinatura.cartao.titular}
												</Typography>
											</Grid>
											<Grid item xs={12} sm={6} md={3}>
												<Typography variant="subtitle1" display="block" gutterBottom>
													Vencimento
												</Typography>
												<Typography variant="subtitle2" gutterBottom>
													**/{dataAssinatura.cartao.validade}
												</Typography>
											</Grid>
										</>
									)}
								</Grid>
							</Paper>
							<Paper style={{ padding: 20, marginBottom: 50 }}>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<Typography variant="h5" gutterBottom>
											Pagamentos
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<Grid container spacing={2}>
											<Grid item xs={12} md={9}>
												<Grid container>
													<Grid
														item
														xs={12}
														style={{ overflowX: 'auto', width: 'calc(100vw - 120px)' }}
													>
														<ZTable
															headCells={[
																{
																	id: 'date_invoice',
																	numeric: false,
																	disablePadding: true,
																	label: 'Data',
																	isDate: true,
																},
																{
																	id: 'amount',
																	numeric: false,
																	disablePadding: false,
																	label: 'Valor',
																	isReal: true,
																	filter: value => {
																		return value / 100;
																	},
																},
																{
																	id: 'status',
																	numeric: false,
																	disablePadding: false,
																	label: 'Status',
																	filter: value => {
																		switch (value) {
																			case 'paid':
																				value = 'Pago';
																				break;
																			case 'failed':
																				value = 'Recusado';
																				break;
																			case 'void':
																				value = 'Estornado';
																				break;
																			case 'overdue':
																				value = 'Vencido';
																				break;
																			default:
																				value = 'Pendente';
																				break;
																		}
																		return value;
																	},
																},
																{
																	id: 'voided_at',
																	numeric: false,
																	disablePadding: false,
																	label: 'Estornado',
																	filter: value => {
																		return value
																			? moment(value).format(
																					'DD/MM/YYYY HH:mm:ss'
																			  )
																			: 'N/A';
																	},
																},
																{
																	id: 'options',
																	numeric: true,
																	align: 'right',
																	disablePadding: false,
																	label: 'Opções',
																	isButton: true,
																	filter: row => {
																		const options = [];
																		const {
																			status,
																			id: invoiceId,
																			pedido_id,
																		} = row;
																		const boleto =
																			dataAssinatura.payment_method === 'boleto';
																		const pix =
																			dataAssinatura.payment_method === 'pix';

																		if (
																			boleto &&
																			Isset(() => row.pedido.pagamentos[0]) &&
																			row.pedido.pagamentos[0]
																				.pagamento_boleto !== null
																		) {
																			const {
																				pedido: {
																					pagamentos: [
																						{
																							pagamento_boleto: { url },
																						},
																					],
																				},
																			} = row;

																			if (status !== 'paid' && boleto) {
																				options.push({
																					title: 'Visualizar Boleto',
																					onClick: () =>
																						window.open(url, '_blank'),
																				});
																			}
																		}

																		if (
																			status === 'paid' &&
																			!boleto &&
																			!pix &&
																			estorno
																		) {
																			options.push({
																				title: 'Estornar',
																				onClick: () => {
																					setEstornarAssinatura({
																						open: true,
																						invoiceId: invoiceId,
																					});
																				},
																			});
																		}

																		if (pedido_id) {
																			options.push({
																				title: 'Detalhes do Pedido',
																				onClick: () =>
																					window.open(
																						`/vendas/detalhes/${pedido_id}`,
																						'_blank'
																					),
																			});
																		}

																		return <ZMenuOptions data={options} />;
																	},
																},
															]}
															tableTitle=""
															toolBar={false}
															rows={faturas}
															densePadding={false}
															checkBox={false}
															rowsPerPage={[8, 16, 24]}
															rowsPerPageDefault={rowsPerPage}
															onChangePage={onChangePage}
															onChangeRowsPerPage={onChangeRowsPerPage}
															count={totalRows}
															page={page - 1}
														/>
													</Grid>
												</Grid>
											</Grid>
											<Grid item xs={12} md={3}>
												<Grid
													container
													direction="row"
													justifyContent="center"
													alignItems="center"
												>
													<Grid
														item
														xs={12}
														align="center"
														style={{
															background: 'rgb(245, 245, 245)',
															padding: 15,
															borderRadius: 5,
														}}
													>
														<Typography variant="subtitle1" gutterBottom>
															Assinante desde:
														</Typography>
														<Typography variant="subtitle2" display="block" gutterBottom>
															{moment(dataAssinatura.created).format('DD/MM/YYYY')}
														</Typography>
													</Grid>
													{status !== 'Suspenso' && (
														<Grid
															item
															xs={12}
															align="center"
															style={{
																background: 'rgb(245, 245, 245)',
																padding: 15,
																borderRadius: 5,
																marginTop: 20,
															}}
														>
															<Typography variant="subtitle1" gutterBottom>
																Data de expiração:
															</Typography>
															<Typography
																variant="subtitle2"
																display="block"
																gutterBottom
															>
																{dataAssinatura.expiration_date
																	? moment(dataAssinatura.expiration_date).format(
																			'DD/MM/YYYY'
																	  )
																	: 'N/A'}
															</Typography>
															<Button
																variant="contained"
																size="small"
																color="primary"
																onClick={() =>
																	setAlterarData({
																		...alterarData,
																		assinaturaId: dataAssinatura.id,
																		open: true,
																		tipoData: 2, // expiration_date
																	})
																}
															>
																Alterar data
															</Button>
														</Grid>
													)}
													<Grid
														item
														xs={12}
														align="center"
														style={{
															background: 'rgb(245, 245, 245)',
															padding: 15,
															borderRadius: 5,
															marginTop: 20,
														}}
													>
														<Typography variant="subtitle1" gutterBottom>
															Data do primeiro pagamento:
														</Typography>
														<Typography variant="subtitle2" display="block" gutterBottom>
															{dataAssinatura.due_since_date
																? moment(dataAssinatura.due_since_date).format(
																		'DD/MM/YYYY'
																  )
																: 'N/A'}
														</Typography>
													</Grid>
													{status === 'Suspenso' && (
														<Grid
															item
															xs={12}
															align="center"
															style={{
																background: 'rgb(245, 245, 245)',
																padding: 15,
																borderRadius: 5,
																marginTop: 20,
															}}
														>
															<Typography variant="subtitle1" gutterBottom>
																Data de Suspensão:
															</Typography>
															<Typography
																variant="subtitle2"
																display="block"
																gutterBottom
															>
																{dataAssinatura.suspended_at
																	? moment(dataAssinatura.suspended_at).format(
																			'DD/MM/YYYY'
																	  )
																	: 'N/A'}
															</Typography>
														</Grid>
													)}
													{status !== 'Suspenso' && (
														<Grid
															item
															xs={12}
															align="center"
															style={{
																background: 'rgb(245, 245, 245)',
																padding: 15,
																borderRadius: 5,
																marginTop: 20,
															}}
														>
															<Typography variant="subtitle1" gutterBottom>
																Próxima recorrência
															</Typography>
															<Typography
																variant="subtitle2"
																display="block"
																gutterBottom
															>
																{dataAssinatura.due_date
																	? moment(dataAssinatura.due_date).format(
																			'DD/MM/YYYY'
																	  )
																	: 'N/A'}
															</Typography>
															<Button
																variant="contained"
																size="small"
																color="primary"
																onClick={() =>
																	setAlterarData({
																		...alterarData,
																		assinaturaId: dataAssinatura.id,
																		open: true,
																		tipoData: 1, // Due_date
																	})
																}
															>
																Alterar data
															</Button>
														</Grid>
													)}
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Paper>
						</Grid>
						<Grid item xs={12}>
							<Paper style={{ padding: 20, marginBottom: 50 }}>
								<Grid container spacing={2}>
									{/* <Grid item xs={12} style={{marginBottom:50}}>
                                        <Typography variant="h3" gutterBottom>
                                            {data.name}
                                        </Typography>
                                        <Typography variant="subtitle1" gutterBottom>
                                            {data.description}
                                        </Typography>
                                    </Grid> */}
									<Grid item xs={12}>
										<Grid container spacing={2}>
											<Grid item xs={12}>
												<Grid container>
													<Grid item xs={10}>
														<Grid container spacing={2}>
															<Grid item xs={12}>
																<Typography variant="h5" gutterBottom>
																	Cliente
																</Typography>
															</Grid>
															<Grid item xs={12} sm={6} md={3}>
																<Typography
																	variant="subtitle1"
																	display="block"
																	gutterBottom
																>
																	Nome
																</Typography>
																<Typography variant="subtitle2" gutterBottom>
																	{dataAssinatura.cliente.nome}
																</Typography>
															</Grid>
															<Grid item xs={12} sm={6} md={3}>
																<Typography
																	variant="subtitle1"
																	display="block"
																	gutterBottom
																>
																	E-mail
																</Typography>
																<Typography variant="subtitle2" gutterBottom>
																	{dataAssinatura.cliente.email}
																</Typography>
															</Grid>
															<Grid item xs={12} sm={6} md={3}>
																<Typography
																	variant="subtitle1"
																	display="block"
																	gutterBottom
																>
																	Data de Nascimento
																</Typography>
																<Typography variant="subtitle2" gutterBottom>
																	{moment(
																		dataAssinatura.cliente.dataNascimento
																	).format('DD/MM/YYYY')}
																</Typography>
															</Grid>
															{dataAssinatura.cliente.cpf !== '' && (
																<Grid item xs={12} sm={6} md={3}>
																	<Typography
																		variant="subtitle1"
																		display="block"
																		gutterBottom
																	>
																		CPF
																	</Typography>
																	<Typography variant="subtitle2" gutterBottom>
																		{Mask.Cpf(dataAssinatura.cliente.cpf)}
																	</Typography>
																</Grid>
															)}
															{dataAssinatura.cliente.cnpj && (
																<Grid item xs={12} sm={6} md={3}>
																	<Typography
																		variant="subtitle1"
																		display="block"
																		gutterBottom
																	>
																		CNPJ
																	</Typography>
																	<Typography variant="subtitle2" gutterBottom>
																		{Mask.Cnpj(dataAssinatura.cliente.cnpj)}
																	</Typography>
																</Grid>
															)}
															{dataAssinatura.socio && (
																<Grid item xs={12} sm={6} md={3}>
																	<Typography
																		variant="subtitle1"
																		display="block"
																		gutterBottom
																	>
																		Sócio
																	</Typography>
																	<Typography variant="subtitle2" gutterBottom>
																		{dataAssinatura.socio}
																	</Typography>
																</Grid>
															)}
															{dataAssinatura.socioCpf && (
																<Grid item xs={12} sm={6} md={3}>
																	<Typography
																		variant="subtitle1"
																		display="block"
																		gutterBottom
																	>
																		CPF do Sócio
																	</Typography>
																	<Typography variant="subtitle2" gutterBottom>
																		{Mask.Cpf(dataAssinatura.socioCpf)}
																	</Typography>
																</Grid>
															)}
															{dataAssinatura.clientes_contatos.map((data, index) => {
																if (data.id !== null) {
																	return (
																		<Grid item xs={12} sm={6} md={3} key={index}>
																			<Typography
																				variant="subtitle1"
																				display="block"
																				gutterBottom
																			>
																				{parseInt(data.tipo_contato_id) === 1
																					? 'Telefone'
																					: 'Celular'}
																			</Typography>
																			<Typography
																				variant="subtitle2"
																				gutterBottom
																			>
																				{parseInt(data.tipo_contato_id) === 1
																					? Mask.Telefone(data.contato)
																					: Mask.Celular(data.contato)}
																			</Typography>
																		</Grid>
																	);
																}
																return false;
															})}
														</Grid>
													</Grid>
													<Grid item xs={12} md={2}>
														<Grid
															container
															direction="row"
															justifyContent="center"
															alignItems="center"
															style={{ height: 120 }}
														>
															<Grid item xs={12} align="center">
																<Button
																	variant="contained"
																	size="small"
																	color="primary"
																	onClick={() =>
																		history.push(
																			`/cliente/editar/${dataAssinatura.cliente.id}`
																		)
																	}
																>
																	Editar Cliente
																</Button>
															</Grid>
														</Grid>
													</Grid>
												</Grid>
											</Grid>

											<Grid item xs={12} style={{ marginTop: 50 }}>
												<Typography variant="h5" gutterBottom>
													Endereço
												</Typography>
											</Grid>
											<Grid item xs={12} sm={6} md={3}>
												<Typography variant="subtitle1" display="block" gutterBottom>
													Logradouro
												</Typography>
												<Typography variant="subtitle2" gutterBottom>
													{dataAssinatura.endereco.logradouro}
												</Typography>
											</Grid>
											<Grid item xs={12} sm={6} md={1}>
												<Typography variant="subtitle1" display="block" gutterBottom>
													Número
												</Typography>
												<Typography variant="subtitle2" gutterBottom>
													{dataAssinatura.endereco.numero}
												</Typography>
											</Grid>
											<Grid item xs={12} sm={6} md={2}>
												<Typography variant="subtitle1" display="block" gutterBottom>
													CEP
												</Typography>
												<Typography variant="subtitle2" gutterBottom>
													{Mask.Cep(dataAssinatura.endereco.cep)}
												</Typography>
											</Grid>
											<Grid item xs={12} sm={6} md={3}>
												<Typography variant="subtitle1" display="block" gutterBottom>
													Cidade
												</Typography>
												<Typography variant="subtitle2" gutterBottom>
													{dataAssinatura.endereco.cidade}
												</Typography>
											</Grid>
											<Grid item xs={12} sm={6} md={2}>
												<Typography variant="subtitle1" display="block" gutterBottom>
													Estado
												</Typography>
												<Typography variant="subtitle2" gutterBottom>
													{dataAssinatura.endereco.estado}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Paper>
						</Grid>
					</Grid>
					<ZModalDatePicker
						title={`Alterar data`}
						message={`Deseja alterar a data dessa cobrança?`}
						open={alterarData.open}
						date={
							alterarData.tipoData === 2
								? moment(alterarData.expiration_date).toDate()
								: moment(alterarData.newDate).toDate()
						}
						minDate={
							dataAssinatura.payment_method === 'boleto'
								? moment()
										.add(6, 'days')
										.toDate()
								: moment()
										.add(1, 'days')
										.toDate()
						}
						handleOnChange={date => {
							alterarData.tipoData === 2
								? setAlterarData({
										...alterarData,
										expiration_date: moment(date).format('YYYY-MM-DD'),
								  })
								: setAlterarData({
										...alterarData,
										newDate: moment(date).format('YYYY-MM-DD'),
								  });
						}}
						handleOnSave={alterarData.tipoData === 1 ? handleAlterarData : handleAlterarDataExpiracao}
						handleClose={handleClose}
					/>
					<ZModalConfirm
						title={`Estornar`}
						message={`Deseja estornar essa cobrança? Essa ação não pode ser desfeita.`}
						open={estornarAssinatura.open}
						onClose={handleEstornarAssinatura}
					/>
					<ZModal
						title={`Alterar Valor`}
						description={`Digite abaixo o novo valor da assinatura`}
						open={alterarValor.open}
						content={
							<TextField
								fullWidth
								label="Valor"
								value={Mask.Real(alterarValor.amount)}
								onChange={e =>
									setAlterarValor({
										...alterarValor,
										amount: Mask.OnlyNumber(e.target.value) / 100,
									})
								}
							/>
						}
						handleOnSave={handleAlterarValor}
						handleClose={() => setAlterarValor({ ...alterarValor, open: false })}
					/>
				</Container>
			</div>
		);
	}
};
