import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Container, Link, TextField, Box, Typography } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { Mask } from '../../utils';
import ZSaveButton from '../system/ZSaveButton';
import config from 'react-global-configuration';
import { addMessage } from '../../store/alert';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
	'@global': {
		body: {
			backgroundColor: theme.palette.common.white,
		},
	},
	paper: {
		marginTop: theme.spacing(12),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		width: '100%',
		// backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	preCadastroEC: {},
}));

export default props => {
	const history = useHistory();
	const dispatch = useDispatch();
	const classes = useStyles();

	const systemConfig = useSelector(store => store.system.config);
	const [loading, setLoading] = useState(false);

	const [data, setData] = useState({
		nome: '',
		cpfcnpj: '',
		email: '',
		telefone: '',
		mensagem: '',
		estabelecimentoId: systemConfig.estabelecimentoId,
	});
	const [dataError, setDataError] = useState({
		nome: false,
		cpfcnpj: false,
		email: false,
		telefone: false,
	});

	const handleSubmit = async () => {
		setLoading(true);
		let hasError = false;
		const errors = {
			nome: false,
			cpfcnpj: false,
			email: false,
			telefone: false,
		};

		if (!data.nome) {
			hasError = true;
			errors.nome = true;
		}
		if (!data.cpfcnpj) {
			hasError = true;
			errors.cpfcnpj = true;
		}
		if (!data.email) {
			hasError = true;
			errors.email = true;
		}
		if (!data.telefone) {
			hasError = true;
			errors.telefone = true;
		}

		setDataError(errors);
		if (hasError) {
			setLoading(false);
			return false;
		}

		const res = await axios.post(config.get('apiUrl') + 'marketplaces/pre-cadastrar-ec', data);

		if (res.data.success) {
			dispatch(addMessage({ type: 'success', message: 'Cadastro enviado com sucesso.' }));
			window.location = '/cadastro/sucesso';
		} else {
			dispatch(addMessage({ type: 'error', message: data.error }));
		}

		setLoading(false);
	};

	return (
		<div style={{ background: systemConfig.backgroundColor }}>
			<Container maxWidth="md" style={{ height: '100vh' }}>
				<Grid container direction="row" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
					<Grid item xs={12} style={{ background: systemConfig.backgroundColor, padding: 20 }}>
						<Grid container direction="row" justifyContent="center" alignItems="center">
							<Grid item xs={12} sm={6} lg={6} style={{}}>
								<Grid container direction="row" justifyContent="center" alignItems="center">
									<Grid item xs={12} style={{ padding: 30 }} align="center">
										<Link href={systemConfig.website} color="inherit">
											<img
												src={systemConfig.logoUrl}
												style={{ maxWidth: '100%' }}
												alt={`Logo ${systemConfig.nome}`}
												title={`Logo ${systemConfig.nome}`}
											/>
										</Link>
									</Grid>
								</Grid>
							</Grid>

							<Grid
								item
								xs={12}
								sm={6}
								lg={6}
								style={{ background: 'white', padding: 25, borderRadius: 5 }}
							>
								<form className={classes.form} noValidate>
									<TextField
										error={dataError.nome}
										variant="outlined"
										margin="normal"
										required
										fullWidth
										id="nome"
										label="Nome"
										name="nome"
										autoComplete="nome"
										autoFocus
										onChange={e => {
											setData({ ...data, nome: e.target.value });
										}}
										value={data.nome}
									/>
									<TextField
										error={dataError.cpfcnpj}
										variant="outlined"
										margin="normal"
										required
										fullWidth
										id="cpfcnpj"
										label="CPF / CNPJ"
										name="cpfcnpj"
										autoComplete="cpfcnpj"
										autoFocus
										onChange={e => {
											setData({ ...data, cpfcnpj: Mask.OnlyNumber(e.target.value) });
										}}
										value={
											data.cpfcnpj.length > 11 ? Mask.Cnpj(data.cpfcnpj) : Mask.Cpf(data.cpfcnpj)
										}
									/>
									<TextField
										error={dataError.email}
										variant="outlined"
										margin="normal"
										required
										fullWidth
										id="email"
										label="E-mail"
										name="email"
										autoComplete="email"
										autoFocus
										onChange={e => {
											setData({ ...data, email: e.target.value });
										}}
										value={data.email}
									/>
									<TextField
										error={dataError.telefone}
										variant="outlined"
										margin="normal"
										required
										fullWidth
										id="telefone"
										label="Telefone"
										name="telefone"
										autoComplete="telefone"
										autoFocus
										onChange={e => {
											setData({ ...data, telefone: Mask.OnlyNumber(e.target.value) });
										}}
										value={
											data.telefone.length > 10
												? Mask.Celular(data.telefone)
												: Mask.Telefone(data.telefone)
										}
									/>
									<TextField
										multiline
										rowsMax={4}
										variant="outlined"
										margin="normal"
										required
										fullWidth
										id="mensagem"
										label="Mensagem"
										name="mensagem"
										autoComplete="mensagem"
										autoFocus
										onChange={e => {
											setData({ ...data, mensagem: e.target.value });
										}}
										value={data.mensagem}
									/>

									<ZSaveButton
										onClick={() => handleSubmit()}
										color={'primary'}
										align="right"
										// type="submit"
										label={'Enviar'}
										loading={loading}
									/>
								</form>
								<Grid container>
									<Grid item xs style={{ marginTop: 15 }}>
										<Link
											onClick={() => history.push('/')}
											style={{ cursor: 'pointer' }}
											variant="body2"
										>
											Voltar para o login
										</Link>
									</Grid>
								</Grid>

								<Box mt={5}>
									<Typography variant="body2" color="textSecondary" align="center">
										Sistema de Pagamentos
										<br />
										{`© ${systemConfig.nome} ` + moment().format('YYYY')}
									</Typography>
								</Box>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
};
