var APP_CONFIG = process.env.REACT_APP_CONFIG;

if (typeof APP_CONFIG === 'undefined' || APP_CONFIG === 'undefined') {
	APP_CONFIG = {
		apiUrl: 'http://localhost:3000/',
		apiV2Url: 'http://localhost:3003/',
		sistemaUrl: 'http://sistema.localhost/',
		baseName: '/app',
		hostName: null, //'portal.clearpay.com.br/',
	};
}

if (process.env.REACT_APP_DEPLOY_VERCEL) {
	APP_CONFIG = {
		apiUrl: 'https://homologacao-api.nectaco.com.br/',
		apiV2Url: 'https://api-v2.zsystems.com.br/',
		sistemaUrl: 'https://z-payments-front-end.vercel.app/',
		baseName: '/app',
		hostName: null, //'portal.clearpay.com.br/',
	};
}

if (process.env.REACT_APP_ENV === 'homologacao') {
	APP_CONFIG = {
		apiUrl: 'https://homologacao-api.nectaco.com.br/',
		apiV2Url: 'https://homologacao-api-v2.nectaco.com.br/',
		sistemaUrl: 'http://sistema.localhost/',
		baseName: '/app',
		hostName: null, //'portal.clearpay.com.br/',
	};
}

if (process.env.REACT_APP_ENV === 'production') {
	APP_CONFIG = {
		apiUrl: 'https://api.zsystems.com.br/',
		apiV2Url: 'https://api-v2.zsystems.com.br/',
		baseName: '/app',
		hostName: null, //'portal.clearpay.com.br/',
	};
}

if (process.env.REACT_APP_ENV === 'newArchitecture') {
	APP_CONFIG = {
		apiUrl: process.env.REACT_APP_API_URL,
		apiV2Url: process.env.REACT_APP_API_URL_V2,
		baseName: '/app',
		hostName: null, //'portal.clearpay.com.br/',
	};
}

const config = {
	apiUrl: APP_CONFIG.apiUrl,
	apiV2Url: APP_CONFIG.apiV2Url ? APP_CONFIG.apiV2Url : 'https://api-v2.zsystems.com.br/',
	sistemaUrl: APP_CONFIG.sistemaUrl,
	baseName: APP_CONFIG.baseName,
	hostName: APP_CONFIG.hostName || null,
};

export default config;
