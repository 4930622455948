import React, { useEffect, useState } from 'react';
import { Mask, fallbackCopyTextToClipboard } from '../../utils';
import Countdown from 'react-countdown';
import QRCode from 'qrcode.react';
import saveSvgAsPng from 'save-svg-as-png';
import { FaCopy } from 'react-icons/fa';
import { useSelector, useDispatch } from 'react-redux';
import { addMessage } from '../../store/alert';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import config from 'react-global-configuration';
import { subHours } from 'date-fns';
import ZLoader from '../system/ZLoader';
import { FiShoppingBag, FiAlertTriangle } from 'react-icons/fi';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { FaQrcode } from 'react-icons/fa';

import { Grid, Typography, Container, Paper, Button, SvgIcon, CircularProgress, TextField } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
	containerPaper: {
		maxWidth: '960px',
		margin: '32px auto',
		[theme.breakpoints.down('xs')]: {
			margin: '16px 0',
		},
	},
	container: {
		margin: '32px auto',
		[theme.breakpoints.down('xs')]: {
			margin: '16px 0',
		},
	},
	paper: {
		borderRadius: 5,
		padding: 64,
		marginBottom: 16,
	},
	displayMobile: {
		height: '100vh',
		display: 'flex',
		alignItems: 'center',
	},
	success: {
		backgroundColor: '#50c878',
	},
	container2: {
		width: '100%',
		minHeight: '50vh',
		display: 'flex',

		'& .container__icon': {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			marginTop: '64px',

			position: 'relative',
			backgroundColor: '#FFF',
			borderRadius: '50%',
			width: '100px',
			height: '100px',
		},

		'& .container__icon-check': {
			position: 'absolute',
			top: '66px',
			left: '79px',
		},

		'& .paper': {
			borderRadius: 5,
			padding: 32,
			position: 'absolute',
			// top: '55%',

			'& .paper__content-title': {
				color: '#424242',
				fontSize: '18px',
				fontWeight: 'bold',
				margin: 0,
			},

			'& .paper__content-text': {
				color: '#757575',
				fontSize: '14px',
				lineHeight: '24px',
				margin: 0,
			},
		},
	},
}));

export default props => {
	const dispatch = useDispatch();
	const token = props.data.split('-');

	const primary_color = useSelector(store => store.system.color);
	const classes = useStyles({ colorButton: primary_color || '#3f51b5' });
	const qrCodeRef = React.createRef();

	const [loadingData, setLoading] = useState(true);
	const [pixVencido, setPixVencido] = useState(false);
	const [pagamentoAprovado, setPagamentoAprovado] = useState(false);
	const [dataAssinatura, setDataAssinatura] = useState({});
	const [validade, setValidade] = useState('');

	const downloadQRCode = () => {
		const svg = qrCodeRef.current.getElementsByTagName('svg')[0];
		saveSvgAsPng.saveSvgAsPng(svg, 'qrcode.png', {
			scale: 5,
			encoderOptions: 1,
			backgroundColor: 'white',
		});
	};
	const getAssinaturas = async () => {
		setLoading(true);
		const idAssinatura = props.data.split('-');
		let url = `${config.get('apiUrl')}planos/assinatura/${idAssinatura[0]}/publica?token=${props.data}`;

		const res = await axios.get(url, { headers: {} });
		if (res.data.success) {
			setDataAssinatura(res.data.data);
			const parts = res.data.data.validade.split(' ');
			const dateParts = parts[0].split('/');
			const timeParts = parts[1].split(':');
			const dateValidation = subHours(
				new Date(dateParts[2], dateParts[1] - 1, dateParts[0], timeParts[0], timeParts[1], timeParts[2]),
				3
			);
			setValidade(dateValidation.toISOString());
		}
		setLoading(false);
		if (res.data.error === 'Assinatura ja paga!') {
			setPagamentoAprovado(true);
		}
	};

	const handleGetSale = async () => {
		if (dataAssinatura.idPedido && !pagamentoAprovado) {
			const res = await axios.get(`${config.get('apiUrl')}vendas/${dataAssinatura.idPedido}/publico`);

			if (res.data.success) {
				if (res.data.venda.status.id === 2) {
					setPagamentoAprovado(true);
				}
			}
		}
	};

	useEffect(() => {
		if (dataAssinatura) {
			handleGetSale();

			const id = setInterval(handleGetSale, 8000);

			return () => {
				clearInterval(id);
			};
		}
	}, [dataAssinatura]);

	useEffect(() => {
		getAssinaturas();
	}, []);

	console.log('dataAssinatura', dataAssinatura);
	console.log('validade', validade);
	console.log('loading,', loadingData);
	if (loadingData) {
		return <ZLoader message="Carregando" />;
	}

	if (pagamentoAprovado) {
		return (
			<>
				<Grid
					container
					className={clsx(classes.container2, {
						[classes.success]: true,
					})}
				>
					<Grid container item justifyContent="center">
						<div className="container__icon">
							<div>
								<FiShoppingBag color="#059142" size="50px" strokeWidth="1px" />;
							</div>
							<div className="container__icon-check">
								<SvgIcon component={CheckCircleIcon} fontSize="large" style={{ color: '#043927' }} />
							</div>
						</div>
						<Grid container item justifyContent="center">
							<section>
								<p
									style={{
										color: '#fff',
										fontSize: '32px',
										margin: 0,
										padding: 10,
										textAlign: 'center',
									}}
								>
									Pronto, seu pagamento foi aprovado!
								</p>
								<p
									style={{
										color: '#fff',
										fontSize: '18px',
										textAlign: 'center',
										marginBottom: '64px',
										marginTop: 8,
									}}
								>
									{dataAssinatura.idPedido}
								</p>
							</section>
						</Grid>
					</Grid>
				</Grid>
			</>
		);
	}
	if (dataAssinatura) {
		return (
			<Container className={classes.containerPaper}>
				<Grid container spacing={6}>
					<Grid item xs={12} md={6}>
						<Paper className={classes.paper}>
							<Grid container spacing={2}>
								{!pixVencido ? (
									<>
										<Grid item xs={12} md={6} className={classes.container}>
											<Grid xs={12}>
												<Countdown
													date={validade}
													onComplete={() => setPixVencido(true)}
													renderer={({ hours, minutes, seconds, completed }) => (
														<div
															style={{
																display: 'flex',
																justifyContent: 'center',
																alignItems: 'center',
																height: '100%',
																fontWeight: 'bold',
																flexDirection: 'column',
																margin: '18px',
															}}
														>
															<div>Este PIX expira em</div>
															<span
																style={{
																	fontSize: 30,
																	color: '#149611',
																}}
															>
																{`${hours}`.padStart(2, '0')}:
																{`${minutes}`.padStart(2, '0')}:
																{`${seconds}`.padStart(2, '0')}
															</span>
														</div>
													)}
												/>
											</Grid>
											<Grid xs={12}>
												<div
													ref={qrCodeRef}
													style={{
														display: 'flex',
														flexDirection: 'column',
														justifyContent: 'center',
														alignItems: 'center',
														fontWeight: 'bold',
														margin: '12px',
														height: 256,
														cursor: 'pointer',
													}}
													onClick={downloadQRCode}
												>
													<QRCode
														renderAs="svg"
														value={dataAssinatura.qrCode}
														width={256}
														height={256}
													/>
												</div>
											</Grid>
											<Grid xs={12}>
												<div
													onClick={() => {
														if (navigator.clipboard) {
															navigator.clipboard.writeText(dataAssinatura.qrCode);
														} else {
															fallbackCopyTextToClipboard(dataAssinatura.qrCode);
														}
														dispatch(
															addMessage({
																type: 'success',
																message: 'Código PIX Copiado com sucesso.',
															})
														);
													}}
													style={{
														display: 'flex',
														flexDirection: 'column',
														justifyContent: 'center',
														alignItems: 'center',
														height: '100%',
														fontWeight: 'bold',
													}}
												>
													<div style={{ fontSize: 16 }}>
														PIX Copia & Cola <FaCopy />
													</div>
													<div style={{ fontSize: 10 }}>{dataAssinatura.qrCode}</div>
												</div>
											</Grid>
										</Grid>
									</>
								) : (
									<Grid
										xs={12}
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											height: '256px',
											fontWeight: 'bold',
											fontSize: 30,
											color: '#dc3e3e',
										}}
									>
										Pix Vencido
									</Grid>
								)}
							</Grid>
						</Paper>
					</Grid>
					<Grid item xs={12} md={6}>
						<Paper className={classes.paper}>
							<Grid container>
								<Grid item xs={12} md={12}>
									<Typography className={classes.title} variant="h5" gutterBottom>
										{dataAssinatura.plano.name}
									</Typography>
									<Typography className={classes.description} variant="subtitle1" gutterBottom>
										{dataAssinatura.plano.descricao}
									</Typography>
									<Typography className={classes.description} variant="subtitle1" gutterBottom>
										<p>
											Preço:{' '}
											<b>
												{Mask.Real(
													dataAssinatura.plano?.setup_amount && token.length === 1
														? (+dataAssinatura.plano.amount +
																+dataAssinatura.plano?.setup_amount) /
																100
														: dataAssinatura.plano.amount / 100
												)}
											</b>
										</p>
									</Typography>
									<Typography variant="body2" gutterBottom style={{ marginBottom: 16 }}>
										{dataAssinatura.plano.estabelecimento.razao_social
											? dataAssinatura.plano.estabelecimento.razao_social
											: dataAssinatura.plano.estabelecimento.nome_fantasia}
									</Typography>
								</Grid>
							</Grid>
						</Paper>
					</Grid>
				</Grid>
			</Container>
		);
	} else {
		return <h1>Erro ao carregar assinatura</h1>;
	}
};
