import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    empty: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 22,
        fontWeight: 700,
        fontVariant: 'small-caps',
    },
}));

export default function ZEmpty(props) {
    const classes = useStyles();

    const { height, text } = props;

    const style = {
        height: height ? height : '100vh'
    };

    return (
        <div className={classes.empty} style={style}>{text ? text : 'Nenhum Resultado Encontrado.'}</div>
    );
}