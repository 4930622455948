// import 'react-hot-loader';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import setupAxiosInterceptors from './api/utils/axiosInterceptor';

setupAxiosInterceptors();

ReactDOM.render(

	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root')
);
