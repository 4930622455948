/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import ZLoader from '../system/ZLoader';
import ZTable from '../system/ZTable';
import ZText from '../system/ZText';
import ZPaginationDiv from '../system/ZPaginationDiv';
import config from 'react-global-configuration';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import ZTitle from '../system/ZTitle';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import { Formatter } from '../../utils';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CadastrarTransferencia from './CadastrarTransferencia';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import { DialogTitle, Button, Grid, CircularProgress, Backdrop } from '@material-ui/core/';
import { FilePond } from 'react-filepond';
import ZFiltros from '../system/ZFiltros';
import FileDownload from 'js-file-download';

import { FaDownload } from 'react-icons/fa';
import clsx from 'clsx';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
	tabela: {
		'& .MuiTableRow-root': {
			cursor: 'pointer',
		},
	},
	header: {
		fontWeight: 700,
		fontSize: 20,
		margin: 8,
	},
	filtros: {
		position: 'relative',
		borderRadius: 20,
		marginBottom: 30,
		border: `solid 1px ${theme.palette.primary.main}67`,
		padding: '5px 10px',
		display: 'flex',
		alignItems: 'center',
	},
	export: {
		position: 'absolute',
		top: -22,
		right: 10,
		opacity: 0.5,
		cursor: 'pointer',
		color: '#464ae6',
		fontSize: 16,
		transition: theme.transitions.create(['all'], {
			duration: theme.transitions.duration.complex,
		}),
		'&:hover': {
			fontSize: 18,
			opacity: 1,
		},
	},
}));

const getOptions = () => {
	const result = {
		statusServicoBancario: [
			{
				id: '1',
				name: 'Aguardando',
			},
			{
				id: '2',
				name: 'Aprovado',
			},
			{
				id: '3',
				name: 'Reprovado',
			},
		],
	};

	return result.statusServicoBancario;
};

export default props => {
	const classes = useStyles();
	const token = useSelector(store => store.auth.token);
	const usuario = useSelector(store => store.auth.usuario);
	// const history = useHistory();
	const [servicosBancarios, setServicosBancarios] = useState([]);
	const [loading, setLoading] = useState(true);
	// const dispatch = useDispatch();
	// const [showExportMessage] = useState(false);

	const { pathname } = useLocation();
	const pathnameSlug = pathname.toLowerCase().replace(/[^a-z0-9]/gi, '');
	const filtersCache = useSelector(store => store.filters[pathnameSlug] || {});

	// const canViewExportar = [1, 7, 10].includes(usuario.grupo_id);
	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(15);
	const [totalRows, setTotalRows] = useState(0);
	const [validaPagamento, setValidaPagamento] = useState({ open: false, data: false });
	const [modalDetalhes, setModalDetalhes] = useState({ open: false, data: false });
	const [message, setMessage] = useState('');

	const [dataTransferir, setDataTransferir] = useState({
		open: false,
	});

	const queryFilter = {
		omni: '',
		dataTransferencia: { de: new Date(), ate: new Date() },
		...filtersCache,
	};

	const [filtros, setFiltros] = useState({
		...queryFilter,
	});

	const onChangePage = newPage => {
		setPage(newPage);
	};

	const onChangeRowsPerPage = rowsPerPage => {
		setRowsPerPage(rowsPerPage);
		setPage(1);
	};

	const [showExportMessage, setShowExportMessage] = useState(false);
	const exportXls = async () => {
		setShowExportMessage(true);
		try {
			const servicosBancarios = await axios.get(
				`${config.get('apiUrl')}export/servicos-bancarios/transferencia-entre-bancos`,
				{
					headers: { Authorization: `Bearer ${token}` },
					responseType: 'blob',
					params: {
						// rows: totalRows,
						filtros: {
							// estabelecimentoId,
							...filtros,
						},
					},
				}
			);

			if (servicosBancarios.status === 200) {
				const {
					'content-disposition': contentDisposition,
					'content-type': contentType,
				} = servicosBancarios.headers;

				let filename = 'servicos_banarios_pagamento_boleto.xlsx';
				if (contentDisposition.indexOf('filename="') !== -1) {
					filename = contentDisposition.slice(
						contentDisposition.indexOf('filename="') + 10,
						contentDisposition.length - 1
					);
				}

				FileDownload(servicosBancarios.data, filename, contentType);
			}
		} catch (error) {
			console.log(error);
		}
		setShowExportMessage(false);
	};

	const filtrosChange = async data => {
		setPage(1);

		data.dataTransferencia = {
			de: data.date.start,
			ate: data.date.end,
		};

		data.servicosBancarios = +data.servicosBancarios;

		setFiltros(data);
	};

	const getServicosBancarios = async () => {
		setLoading(true);
		let res = await axios.get(`${config.get('apiUrl')}servicos-bancarios/transferencia-bancaria`, {
			params: {
				limit: rowsPerPage,
				page,
				filtros,
			},
			headers: { Authorization: `Bearer ${token}` },
		});

		if (res.data.success) {
			const dataServico = res.data.servicosBancarios;
			const dataServicosBancarios = dataServico.map(value => {
				return {
					id: value.id,
					valor: +value.amount / 100,
					banco: value.servico_bancario_transferencia.banco.codigo,
					bancoNome: value.servico_bancario_transferencia.banco.nome,
					agencia: value.servico_bancario_transferencia.agencia,
					conta: value.servico_bancario_transferencia.conta,
					digito: value.servico_bancario_transferencia.digito,
					cpfcnpj: value.servico_bancario_transferencia.cpfcnpj,
					status: value.status,
					description: value.description,
					estabelecimento: value.estabelecimento.nome_fantasia,
					arquivo: value.arquivo || null,
					comment: value.comment,
					favorecido: value.servico_bancario_transferencia.favorecido,
					dataHora: value.created_at,
					tipoConta: value.servico_bancario_transferencia.tipo_conta_bancaria_id,
				};
			});

			setServicosBancarios(dataServicosBancarios);
			setTotalRows(res.data.totalRows);
		}

		setLoading(false);
	};

	useEffect(() => {
		getServicosBancarios();
	}, [filtros, page, rowsPerPage]);

	const handleValidaPagamento = async status => {
		setBackdrop(true);
		const formData = new FormData();
		const comprovante = !Array.isArray(validaPagamento.comprovante) ? false : validaPagamento.comprovante;

		let dataServicoBancario = validaPagamento;
		delete dataServicoBancario.data.comprovante;
		delete dataServicoBancario.comprovante;
		delete dataServicoBancario.data.status;

		for (let key in dataServicoBancario) {
			if (typeof dataServicoBancario[key] === 'object') {
				for (let key2 in dataServicoBancario[key]) {
					if (typeof dataServicoBancario[key][key2] === 'object') {
						for (let key3 in dataServicoBancario[key][key2]) {
							formData.append(`${key}[${key2}][${key3}]`, dataServicoBancario[key][key2][key3]);
						}
					} else {
						formData.append(`${key}[${key2}]`, dataServicoBancario[key][key2]);
					}
				}
			} else {
				formData.append(key, dataServicoBancario[key]);
			}
		}

		formData.append('data[status]', status);
		formData.append('data[message]', message);

		if (comprovante) {
			comprovante.forEach(a => {
				// if (!a.type.match('image.*')) {
				// 	return;
				// }
				formData.append('comprovante[]', a, a.name);
			});
		}

		let res = await axios.put(`${config.get('apiUrl')}servicos-bancarios/transferencia-bancaria`, formData, {
			headers: { Authorization: `Bearer ${token}` },
		});

		if (res.data.success) {
			setValidaPagamento({ ...validaPagamento, open: false });
			getServicosBancarios();
		}
		setBackdrop(false);
	};
	const [backdrop, setBackdrop] = useState(false);

	return (
		<div>
			{backdrop && (
				<Backdrop className={classes.backdrop} open={true} style={{ zIndex: 99999 }}>
					<CircularProgress color="inherit" />
				</Backdrop>
			)}
			<Dialog
				open={validaPagamento.open}
				onClose={() => setValidaPagamento({ ...validaPagamento, open: false })}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{'Validação de transferência'}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{validaPagamento.data && (
							<Grid container spacing={3}>
								<Grid item xs={12} sm={6}>
									<b>Estabelecimento</b> <br />
									{validaPagamento.data.estabelecimento}
								</Grid>
								<Grid item xs={12} sm={6}>
									<b>Hora da solicitação</b> <br />
									{moment(validaPagamento.data.dataHora).format('DD/MM/YYYY HH:mm:ss')}
								</Grid>
								<Grid item xs={12} sm={6}>
									<b>Favorecido</b> <br />
									{validaPagamento.data.favorecido}
								</Grid>
								<Grid item xs={12} sm={6}>
									<b>CPF/CNPJ</b> <br />
									{validaPagamento.data.cpfcnpj}
								</Grid>
								<Grid item xs={12} sm={6}>
									<b>Valor</b> <br />
									{Formatter.Real(validaPagamento.data.valor)}
								</Grid>
								<Grid item xs={12}></Grid>
								<Grid item xs={6}>
									<b>Banco</b> <br />
									{validaPagamento.data.banco} - {validaPagamento.data.bancoNome}
								</Grid>
								<Grid item xs={6}>
									{validaPagamento.data.tipoConta > 0 && (
										<>
											<b>Tipo Conta Bancária</b> <br />
											{validaPagamento.data.tipoConta === 1 ? 'Conta Corrente' : 'Conta Poupança'}
										</>
									)}
								</Grid>
								<Grid item xs={4}>
									<b>Agencia</b> <br />
									{validaPagamento.data.agencia}
								</Grid>
								<Grid item xs={4}>
									<b>Conta</b> <br />
									{validaPagamento.data.conta}
								</Grid>
								<Grid item xs={4}>
									<b>Digito</b>
									<br />
									{validaPagamento.data.digito}
								</Grid>
								<Grid item xs={12}>
									<b>Observação do cliente</b> <br />
									{validaPagamento.data.description}
								</Grid>
								<Grid item xs={12} style={{ marginTop: 10 }}>
									<ZText
										id="comment"
										label="Comentário"
										fullWidth
										value={message}
										onBlur={e => setMessage(e.target.value)}
										placeholder="Digite um comentário sobre esse pagamento"
										multiline
									/>
								</Grid>
								<Grid item xs={12}>
									<div className={classes.configHeader}>Comprovante</div>
									<FilePond
										server={{
											process: null,
										}}
										allowMultiple
										labelIdle='Arraste e solte seus arquivos aqui ou <span class="filepond--label-action">Selecione</span>'
										files={validaPagamento.comprovante}
										onupdatefiles={fileItems => {
											setValidaPagamento({
												...validaPagamento,
												comprovante: fileItems.map(f => f.file),
											});
										}}
									/>
								</Grid>
							</Grid>
						)}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => setValidaPagamento({ ...validaPagamento, open: false })}
						color="default"
						autoFocus
					>
						Fechar
					</Button>
					<Button onClick={() => handleValidaPagamento(3)} color="secondary">
						Recusar transferência
					</Button>
					<Button onClick={() => handleValidaPagamento(2)} color="primary">
						Concluir Transferencia
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={modalDetalhes.open}
				onClose={() => setModalDetalhes({ ...modalDetalhes, open: false })}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{'Detalhes de transferência'}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{modalDetalhes.data && (
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<b>Estabelecimento</b> <br />
									{modalDetalhes.data.estabelecimento}
								</Grid>
								<Grid item xs={12} sm={6}>
									<b>Favorecido</b> <br />
									{modalDetalhes.data.favorecido}
								</Grid>
								<Grid item xs={12} sm={6}>
									<b>CPF/CNPJ</b> <br />
									{modalDetalhes.data.cpfcnpj}
								</Grid>
								<Grid item xs={12} sm={6}>
									<b>Valor</b> <br />
									{Formatter.Real(modalDetalhes.data.valor)}
								</Grid>
								<Grid item xs={12}>
									<b>Banco</b> <br />
									{modalDetalhes.data.banco} - {modalDetalhes.data.bancoNome}
								</Grid>
								<Grid item xs={4}>
									<b>Agencia</b> <br />
									{modalDetalhes.data.agencia}
								</Grid>
								<Grid item xs={4}>
									<b>Conta</b> <br />
									{modalDetalhes.data.conta}
								</Grid>
								<Grid item xs={4}>
									<b>Digito</b>
									<br />
									{modalDetalhes.data.digito}
								</Grid>
								<Grid item xs={12}>
									<b>Observação do cliente</b> <br />
									{modalDetalhes.data.description}
								</Grid>
								{modalDetalhes.data.comment && (
									<Grid item xs={12}>
										<b>Comentário interno</b> <br />
										{modalDetalhes.data.comment}
									</Grid>
								)}

								{modalDetalhes.data.arquivo && (
									<Grid item xs={12}>
										<div className={classes.configHeader}>
											<b>Comprovante</b>
										</div>
										<a
											href={modalDetalhes.data.arquivo.url}
											rel="noopener noreferrer"
											target="_blank"
										>
											{' '}
											Clique para visualizar{' '}
										</a>
									</Grid>
								)}
							</Grid>
						)}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setModalDetalhes({ ...modalDetalhes, open: false })} color="primary">
						Fechar
					</Button>
				</DialogActions>
			</Dialog>

			<ZTitle
				title="Transferência entre bancos"
				button="add"
				handleOnClick={() => setDataTransferir({ ...dataTransferir, open: true })}
			/>

			<Paper style={{ marginBottom: 10, padding: '5px 10px' }}>
				<div
					style={{
						position: 'relative',
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<Grid container alignItems="center">
						<Grid item md={11}>
							<ZFiltros
								onChange={filtrosChange}
								dateRange={{
									default: 'custom',
									startDate: filtros.dataTransferencia.de,
									endDate: filtros.dataTransferencia.ate,
								}}
								input={
									![3].includes(usuario.grupo_id)
										? [
												{
													key: 'omni',
													defaultValue: filtros.omni,
													placeholder: 'Estabelecimento',
													modal: false,
												},
										  ]
										: null
								}
								selectField={[
									{
										key: 'servicosBancarios',
										defaultValue: filtros.status,
										placeholder: 'Status',
										multiple: false,
										options: getOptions(),
										modal: false,
										divider: false,
										md: 2,
									},
								]}
							/>
						</Grid>
						<Grid item md={1}>
							<Grid item align="center" xs={6} md={6}>
								<Grid item align="center" xs={6} md={6}>
									{/* {!hideExport.includes(usuario.grupo_id) && ( */}
									<Tooltip title="Exportar">
										<Button
											className={clsx(classes.buttonExport, classes.iconButton)}
											onClick={async () => await exportXls()}
										>
											<FaDownload className={classes.exportDownload} />
										</Button>
									</Tooltip>
									{/* )} */}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</div>
			</Paper>

			<CadastrarTransferencia
				open={dataTransferir.open}
				onClose={() => setDataTransferir({ ...dataTransferir, open: false })}
				onSuccess={() => getServicosBancarios()}
			/>
			<Paper className={classes.tabela}>
				<div style={{ position: 'relative' }}>
					{/* <Paper style={{ marginBottom: 10, padding: '5px 10px' }}>
						<div
							style={{
								position: 'relative',
								display: 'flex',
								alignItems: 'center',
							}}
						>
							<FaFilter />
							<ZFiltros
								onChange={filtrosChange}
								omni={{
									// default: filtros.omni,
									placeholder: 'Pesquisa por Plano de Venda',
								}}
							/>
						</div>
					</Paper> */}
					{/* {canViewExportar && <FaFileExcel className={classes.export} onClick={exportXls} />} */}

					{loading ? (
						<ZLoader height={200} />
					) : (
						<>
							<ZTable
								headCells={[
									{ id: 'id', numeric: false, disablePadding: true, label: 'ID' },
									{
										id: 'valor',
										numeric: false,
										disablePadding: false,
										label: 'Valor',
										filter: value => Formatter.Real(value),
									},
									{
										id: 'dataHora',
										numeric: false,
										disablePadding: false,
										label: 'Data',
										filter: value => {
											return moment(value).format('DD/MM/YYYY HH:mm:ss');
										},
									},
									{
										id: 'estabelecimento',
										numeric: false,
										disablePadding: false,
										label: 'Estabelecimento',
									},
									{
										id: 'favorecido',
										numeric: false,
										disablePadding: false,
										label: 'Favorecido',
									},
									{
										id: 'cpfcnpj',
										numeric: false,
										disablePadding: false,
										label: 'CPF/CNPJ',
									},
									{
										id: 'banco',
										numeric: false,
										disablePadding: false,
										label: 'Banco',
									},
									{
										id: 'agencia',
										numeric: false,
										disablePadding: false,
										label: 'Agencia',
									},
									{
										id: 'conta',
										numeric: false,
										disablePadding: false,
										label: 'Conta',
									},
									{
										id: 'digito',
										numeric: false,
										disablePadding: false,
										label: 'Digito',
									},

									{
										id: 'status',
										numeric: false,
										disablePadding: false,
										label: 'Status',
										filter: value => {
											let text = '';
											switch (+value) {
												case 2:
													text = 'Concluido';
													break;
												case 3:
													text = 'Recusado';
													break;
												default:
													text = 'Aguardando';
													break;
											}
											return text;
										},
									},
									// {
									// 	id: 'description',
									// 	numeric: false,
									// 	disablePadding: false,
									// 	label: 'Descrição',
									// 	// filter: value => (value === 1 ? 'Aguardando' : 'Concluido'),
									// },
								]}
								tableTitle=""
								toolBar={false}
								rows={servicosBancarios}
								// rowClick={plano => history.push(`/planos-de-vendas/${plano}`)}
								rowClick={data => {
									let dados = servicosBancarios.find(value => value.id === data);

									if (
										usuario.estabelecimento.parent === null &&
										[1, 2, 7].includes(usuario.grupo_id) &&
										dados.status === 1
									) {
										setValidaPagamento({ open: true, data: dados });
									} else {
										setModalDetalhes({ open: true, data: dados });
									}
								}}
								densePadding={false}
								checkBox={false}
								rowsPerPage={[15, 30, 60]}
								rowsPerPageDefault={rowsPerPage}
								pagination={false}
							/>

							<ZPaginationDiv
								count={totalRows}
								rowsPerPage={rowsPerPage}
								page={page - 1}
								onChangePage={onChangePage}
								onChangeRowsPerPage={onChangeRowsPerPage}
								rowsPerPageOptions={[15, 30, 60]}
							/>
						</>
					)}
				</div>
			</Paper>

			<Dialog open={showExportMessage}>
				<DialogContent style={{ width: 200 }}>
					<ZLoader height={100} size={26} message={'Exportando'} />
				</DialogContent>
			</Dialog>
		</div>
	);
};
