/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import axios from 'axios';
import config from 'react-global-configuration';
import moment from 'moment';
import { Formatter, Isset, Mask, fallbackCopyTextToClipboard } from '../../utils';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import format from 'date-fns/format';

import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import 'react-credit-cards/es/styles-compiled.css';
import { addMessage } from '../../store/alert';
import ZNotFound from '../system/ZNotFound';
import ZLoader from '../system/ZLoader';
import ZModal from '../system/ZModal';
import ZModalEstornar from '../system/ZModalEstornar';
import Tooltip from '@material-ui/core/Tooltip';
import ModalLink from '../system/ZModalToken';
import ZModalConfirm from '../system/ZModalConfirm';
import ZSaveButton from '../system/ZSaveButton';

import {
	FaBarcode,
	FaCcVisa,
	FaCcMastercard,
	FaCcAmex,
	FaCreditCard,
	FaRegCreditCard,
	FaDollarSign,
	FaInfoCircle,
	FaDownload,
	FaCopy,
} from 'react-icons/fa';
import { FiPrinter } from 'react-icons/fi';
import PixIcon from '../../assets/images/Pix';
import QRCode from 'qrcode.react';
import saveSvgAsPng from 'save-svg-as-png';
import Countdown from 'react-countdown';
import { useRouteMatch } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
	header: {
		fontWeight: 700,
		fontSize: 24,
		color: '#28262E',
		marginBottom: 16,
		width: '100%',
	},
	subHeader: {
		position: 'absolute',
		fontWeight: 700,
		fontSize: 24,
		// marginTop: 50,
		color: '#28262E',
		margin: 8,
		// width: '100%',
		top: -55,
		left: 0,
	},

	labelTransacao: {
		fontWeight: 700,
		color: '#28262E',
		margin: '4px 0',
		fontSize: 16,

		'& span': {
			fontWeight: 400,
			color: '#666360',
		},
	},
	field: {
		padding: 5,
		[theme.breakpoints.down('xs')]: {
			display: 'flex',
			justifyContent: 'space-between',
		},
	},
	cardContainerMobile: {
		[theme.breakpoints.down('xs')]: {
			marginBottom: 96,
		},
		[theme.breakpoints.down('sm')]: {
			marginBottom: 96,
		},
		marginTop: 40,
		textAlign: 'center',
	},
	urlPublicaStyle: {
		fontFamily: 'Exo 2',
		padding: 20,
	},
	label: {
		fontSize: 16,
		color: '#28262E',
		fontWeight: 700,
	},
	dataVenda: {
		// position: 'absolute',
		// top: -10,
		// left: 10,
		fontWeight: 700,
	},
	estabelecimento: {
		// position: 'absolute',
		// top: -10,
		// right: 10,
		fontWeight: 700,
	},
	valorTotal: {
		fontSize: 24,
		fontWeight: 700,
		color: '#666360',
	},
	valorSmall: {
		fontSize: 18,
	},
	markupCalculado: {
		fontSize: 12,
	},
	divButton: {
		marginBottom: 8,
		marginTop: 8,
		width: 155,
	},
	btnBoleto: {
		width: 100,
		padding: 6,
		textAlign: 'center',
		margin: 'auto',
		textTransform: 'uppercase',
		textDecoration: 'none',
		fontWeight: 700,
		borderRadius: 10,
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.getContrastText(theme.palette.primary.main),
	},
	flex: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	flex2: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	tableHeader: {
		textAlign: 'center',
		fontWeight: 700,
		fontSize: 18,
		color: '#28262E',
	},
	tableRow: {
		textAlign: 'center',
		fontWeight: 700,
		minHeight: 30,
		display: 'flex',
		alignItems: 'center',
		color: '#666360',
		borderRadius: 20,
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: 'rgb(243, 245, 247)',
		},
	},
	paymentIcon: {
		fontSize: 20,
		marginRight: 4,
	},
	paper: {
		position: 'relative',
	},
	labelText: {
		fontWeight: 400,
		color: '#666360',
		fontSize: 14,
	},
	qrcodeHover: {
		transition: theme.transitions.create(['all'], {
			duration: theme.transitions.duration.complex,
		}),
		opacity: 0,
		position: 'absolute',
		height: 256,
		width: 256,
		background: '#fafafa',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		zIndex: 100,
		top: 0,
		left: 0,
		'&:hover': {
			opacity: 0.7,
		},
	},
	hoverPixCopiaCola: {
		textAlign: 'center',
		padding: 10,
		border: 'solid 1px white',
		borderRadius: 10,
		cursor: 'pointer',
		'&:hover': {
			border: 'dashed 1px black',
		},
	},
	printButton: {
		backgroundColor: '#4a6682',
		color: theme.palette.getContrastText(theme.palette.primary.main),
		'&:hover': {
			color: theme.palette.primary.main,
			backgroundColor: '#4a6682',
		},
	},
}));

const getCategoriaById = id => {
	switch (id) {
		case 1:
			return 'Regra de Split';
		case 2:
			return 'Plano de Taxa';
		case 3:
			return 'Regra no Link de Pagamento';
		case 4:
			return 'Regra na Venda';
		case 5:
			return 'Taxa de Venda Online';
		case 6:
			return 'Pagamento de Taxa Recorrente';
		case 7:
			return 'Cobrança Por Transação';
		case 8:
			return 'Split no plano(recorrência)';
		default:
			return '';
	}
};

const TooltipTitle = props => {
	const {
		taxas: { markup, taxaCusto, spread, taxaAntecipacao },
		taxasFinais: { taxaVendaOnline },
		isAdmin,
		isGerencial,
		isCanceled,
	} = props;
	// const taxaZoop = Isset(() => taxas[1].taxa) ? +taxas[1].taxa : 0;
	const taxaZoop = Isset(() => taxaCusto) ? +taxaCusto : 0;
	const markupZoop = markup;
	// const spread = Isset(() => splits2[2].amount) ? +splits2[2].amount : 0;
	// const taxaVendaOnline = Isset(() => splits2[5].amount) ? +splits2[5].amount : 0;

	return (
		<div style={{ fontSize: 14 }}>
			{!isAdmin && !isGerencial && (
				<>
					<div>
						<small>Taxa de Custo</small> {Formatter.Real(taxaZoop)}
						{/* <small>Taxa de Custo</small> {Formatter.Real(+taxaZoop + +markupZoop)} */}
					</div>
					{taxaAntecipacao > 0 && (
						<div>
							<small>Taxa Antecipação</small> {Formatter.Real(taxaAntecipacao)}
						</div>
					)}
				</>
			)}
			{isAdmin && (
				<>
					<div>
						<small>Taxa de Custo</small> {Formatter.Real(taxaZoop)}
					</div>
					{taxaAntecipacao > 0 && (
						<div>
							<small>Taxa Antecipação</small> {Formatter.Real(taxaAntecipacao)}
						</div>
					)}
					<div>
						<small>Markup Zoop</small> {Formatter.Real(markupZoop)}
					</div>
				</>
			)}
			{isGerencial ? (
				<>
					<div>
						<small>Taxa de Custo</small> {Formatter.Real(taxaZoop)}
						{/* <small>Taxa de Custo</small> {Formatter.Real(+taxaTotal2)} */}
					</div>
					{/* {taxaAntecipacao > 0 && (
						<div>
							<small>Taxa Antecipação</small> {Formatter.Real(taxaAntecipacao)}
						</div>
					)} */}
				</>
			) : (
				<div>
					<small>Tabela de Markup</small> {isCanceled ? Formatter.Real(0) : Formatter.Real(spread)}
				</div>
			)}
			{taxaVendaOnline > 0 && (
				<div>
					<small>Taxa de Venda Online</small> {Formatter.Real(taxaVendaOnline)}
				</div>
			)}
		</div>
	);
};

const TooltipTitle2 = props => {
	const {
		taxas: { taxaZoop, tabelaMarkup },
		isCanceled,
	} = props;
	return (
		<div style={{ fontSize: 14 }}>
			<div>
				<small>Taxa Zoop</small> {isCanceled ? Formatter.Real(0) : Formatter.Real(taxaZoop)}
			</div>
			<div>
				<small>Tabela de Markup</small> {isCanceled ? Formatter.Real(0) : Formatter.Real(tabelaMarkup)}
			</div>
		</div>
	);
};

const getBandeira = bandeira => {
	switch (bandeira) {
		case 'american express':
			return 'American Express';
		case 'elo':
			return 'Elo';
		case 'maestro':
			return 'Maestro';
		case 'mastercard':
			return 'Mastercard';
		case 'visa':
			return 'Visa';
		case 'visa electron':
			return 'Visa Electron';
		case 'diners club':
			return 'Diners Club';
		case 'hiper':
			return 'Hiper';
		case 'hipercard':
			return 'HiperCard';
		case 'banescard':
			return 'Banescard';
		default:
			return bandeira;
	}
};

const getPaymentIcon = (paymentType, brand, className = null) => {
	brand = getBandeira(brand);
	if (paymentType === 1) {
		return <FaBarcode className={className} />;
	}

	if (paymentType === 2) {
		return <FaRegCreditCard className={className} />;
	}

	if (paymentType === 3) {
		switch (brand) {
			case 'Mastercard':
			case 'Maestro':
				return <FaCcMastercard className={className} />;
			case 'Visa':
			case 'Visa Electron':
				return <FaCcVisa className={className} />;
			case 'American Express':
				return <FaCcAmex className={className} />;
			default:
				return <FaCreditCard className={className} />;
		}
	}

	if (paymentType === 4) {
		return <FaDollarSign />;
	}

	if (paymentType === 6) {
		return (
			<div style={{ width: 20, height: 20, marginRight: 4 }}>
				<PixIcon />
			</div>
		);
	}
};

export default props => {
	const qrCodeRef = React.createRef();
	// const { match } = useReactRouter();
	const match = useRouteMatch();
	const token = useSelector(store => store.auth.token);
	const usuario = useSelector(store => store.auth.usuario);
	const isMaster = useSelector(store => store.auth.isMaster);
	// const estabelecimento = useSelector(store => store.auth.estabelecimento);
	let agruparTaxas = useSelector(store =>
		Isset(() => store.system.params['1'].valor) ? store.system.params['1'].valor : null
	);

	// const operarMarketplace = useSelector(store =>
	// 	Isset(() => store.system.params['4'].valor) ? store.system.params['4'].valor : null
	// );
	const canEstornarVenda = useSelector(store =>
		Isset(() => store.system.params['26'].valor) ? store.system.params['26'].valor : null
	);

	let canViewSplit = useSelector(store =>
		Isset(() => store.system.params['34'].valor) ? store.system.params['34'].valor : null
	);

	const theme = useTheme();
	const isXs = useMediaQuery(theme.breakpoints.down('xs'));
	const isAdmin = [1, 10, 11, 12, 13, 14, 15].includes(usuario.grupo_id);
	let canViewTaxasTooltip = [1, 2, 10].includes(usuario.grupo_id);

	if (canViewTaxasTooltip && usuario.grupo_id !== 1) {
		if (canViewSplit === null || !canViewSplit) {
			canViewTaxasTooltip = false;
		}
	}

	if (usuario.estabelecimento?.marketplace.id === 1 && usuario.estabelecimento.parent !== null) {
		canViewTaxasTooltip = false;
	}

	const isGerencial = usuario.grupo_id === 2;
	agruparTaxas = !agruparTaxas && isAdmin ? false : true;
	if (canViewSplit && !isAdmin) {
		agruparTaxas = false;
	}

	if (isAdmin && !canViewSplit) {
		canViewSplit = true;
	}

	if (props.inUrlPublica) {
		canViewSplit = false;
	}
	// const viewMarkupCalculado = true; // [1, 8, 9, 10].includes(usuario.marketplace.id);

	const classes = useStyles();
	const dispatch = useDispatch();

	const [data, setData] = useState(null);

	const [loading, setLoading] = useState(true);
	// const [isMine, setIsMine] = useState(false);
	// const [compartilharUrl, setCompartilharUrl] = useState({
	// 	open: false,
	// 	url: `${window.location.origin.toString()}/app/vendas/detalhes/${match.params.vendaId}/publico`,
	// });

	const [copiarModal, setCopiarModal] = useState({
		open: false,
		url: '',
	});

	const [title, setTitle] = useState('');
	const [label, setLabel] = useState('');

	const [sellWithoutAntifraude, setSellWithoutAntifraude] = useState({
		open: false,
		// url: `${window.location.origin.toString()}/app/vendas/detalhes/${match.params.vendaId}/publico`,
	});

	const [isRefunded, setIsRefunded] = useState(false);
	const [isCanceled, setIsCanceled] = useState(false);
	const [cartaCancelamentoLoading, setCartaCancelamentoLoading] = useState(false);

	const [pixVencido, setPixVencido] = useState(false);

	const getData = async () => {
		setData(null);
		setLoading(true);

		const urlId = props.inUrlPublica ? props.vendaId : match.params.vendaId;
		let req;

		if (props.inUrlPublica) {
			req = await axios.get(`${config.get('apiUrl')}vendas/${urlId}/publico`);
		} else {
			req = await axios.get(`${config.get('apiUrl')}vendas/${urlId}/dados`, {
				headers: { Authorization: `Bearer ${token}` },
			});
		}

		if (req.data.success) {
			const venda = req.data.venda;

			// if (venda.estabelecimento.id === estabelecimento.id) {
			// 	setIsMine(true);
			// }

			if (venda.status.id === 6) {
				setIsRefunded(true);
			}

			if (venda.status.id === 4) {
				setIsCanceled(true);
			}

			if (venda.tipoPagamento.id === 6) {
				const expirationDate = format(venda.pix.expirationDate, 'YYYYMMDDHHmmss');
				const now = format(new Date(), 'YYYYMMDDHHmmss');

				if (now > expirationDate) {
					setPixVencido(true);
				}

				if (venda.status.id !== 1) {
					setPixVencido(true);
				}
			}

			setData(venda);
			setDataExtorno({
				...dataExtorno,
				documento:
					venda.cliente.clientes_documentos.length > 0
						? venda.cliente.clientes_documentos[0].documento
						: null,
				nome: venda.cliente.nome,
			});
		}
		setLoading(false);
	};

	useEffect(() => {
		getData();
	}, []);

	const [dataEstornar, setDataEstornar] = useState({
		pedidoId: null,
		valorTotal: 0,
		open: false,
	});

	const [dataExcluirBoleto, setDataExcluirBoleto] = useState({
		pedidoId: null,
		open: false,
	});

	// const getData = async () => {
	// 	setData(null);
	// 	let req = await axios.get(`${config.get('apiUrl')}vendas/` + match.params.vendaId, {
	// 		headers: { Authorization: `Bearer ${token}` },
	// 	});

	// 	if (req.data.success) {
	// 		const venda = req.data.venda;

	// 		if (venda.estabelecimento.id === estabelecimento.id) {
	// 			setIsMine(true);
	// 		}

	// 		setData(venda);
	// 	}
	// 	setLoading(false);
	// };

	const handleEstornar = async () => {
		setDataEstornar({ ...dataEstornar, open: false, pedidoId: null });

		setData(null);
		setLoading(true);

		await axios
			.post(
				`${config.get('apiUrl')}vendas/${match.params.vendaId}/estornar`,
				{
					amount: dataEstornar.valorTotal * 100,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then(res => res.data)
			.then(data => {
				let typeDispatch = 'success';
				if (!data.success) {
					typeDispatch = 'error';
				} else if (parametroElo7) {
					setDataExcluirBoleto({ ...dataExcluirBoleto, open: true });
				}
				dispatch(addMessage({ type: typeDispatch, message: data.message }));
				getData();
			});
	};

	const validationEstorno = values => {
		if (data.status.id === 1) {
			return true;
		}
		if (!values.agencia) {
			return false;
		}
		if (!values.conta) {
			return false;
		}
		if (!values.bancoId) {
			return false;
		}
		return true;
	};

	const handleExcluirBoleto = async () => {
		const validacao = validationEstorno(dataExtorno);

		if (validacao) {
			setDataEstornar({ ...dataEstornar, open: false, pedidoId: null });
			setLoading(true);
			await axios
				.post(
					`${config.get('apiUrl')}vendas/${match.params.vendaId}/cancelar-boleto`,
					{
						documento: dataExtorno.documento,
						tipoContaBancaria: dataExtorno.tipoConta,
						nome: dataExtorno.nome,
						banco: dataExtorno.bancoId,
						agencia: dataExtorno.agencia,
						conta: dataExtorno.conta,
					},
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				)

				.then(res => res.data)
				.then(data => {
					let typeDispatch = 'success';
					if (!data.success) {
						typeDispatch = 'error';
						setLoading(false);
					}

					setDataExcluirBoleto({ ...dataExcluirBoleto, open: false });
					dispatch(addMessage({ type: typeDispatch, message: data.message }));
					getData();
					setLoading(false);
				});
		} else {
			dispatch(addMessage({ type: 'error', message: 'Preencha todos os campos' }));
			setLoading(false);
		}
	};

	const handleTranferirBoleto = async () => {
		const validacao = validationEstorno(dataExtorno);
		if (validacao) {
			setDataEstornar({ ...dataEstornar, open: false, pedidoId: null });
			setLoading(true);

			const urlId = props.inUrlPublica ? props.vendaId : match.params.vendaId;

			const res = await axios
				.post(
					`https://api-v2.zsystems.com.br/bank-account`,
					{
						holderName: dataExtorno.nome,
						bankCode: dataExtorno.bancoId,
						routingNumber: dataExtorno.agencia,
						accountNumber: dataExtorno.conta,
						identificationDocument: dataExtorno.documento,
						type: dataExtorno.tipoConta,
						amount: data.valores.total * 100,
						zoopBuyerId: data.cliente.zoop_buyer_id,
						pedidoId: urlId,
					},
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				)
				.then(res => {
					if (res.data.success) {
						dispatch(addMessage({ type: 'success', message: 'Transferência realizada com sucesso.' }));
					} else {
					}

					setDataExcluirBoleto({ ...dataExcluirBoleto, open: false });
					getData();
				})
				.catch(error => {
					let message;
					switch (error.response.data.error) {
						case 'Insufficient escrow funds':
							message = 'Fundos para transferência insuficientes.';
							break;
						default:
							message = 'Erro ao transferir estorno do boleto.';
							break;
					}
					dispatch(addMessage({ type: 'error', message }));
					setDataExcluirBoleto({ ...dataExcluirBoleto, open: false });
					getData();
				});
		} else {
			dispatch(addMessage({ type: 'error', message: 'Preencha todos os campos' }));
		}
	};

	const getCartaCancelamento = async () => {
		setCartaCancelamentoLoading(true);
		const res = await axios.get(`${config.get('apiUrl')}vendas/${match.params.vendaId}/carta-cancelamento`, {
			headers: { Authorization: `Bearer ${token}` },
		});

		if (res.data.success) {
			window.open(res.data.url);
		} else {
			dispatch(addMessage({ type: 'error', message: 'Erro ao emitir Carta de Cancelamento.' }));
		}
		setCartaCancelamentoLoading(false);
	};

	const reprocessarVenda = async () => {
		const res = await axios.get(`${config.get('apiUrl')}z1/vendas/${match.params.vendaId}/reprocessar`, {
			headers: { Authorization: `Bearer ${token}` },
		});

		if (res.data.success) {
			getData();
		} else {
			dispatch(addMessage({ type: 'error', message: 'Erro ao reprocessar venda.' }));
		}
	};

	const downloadQRCode = () => {
		const svg = qrCodeRef.current.getElementsByTagName('svg')[0];
		saveSvgAsPng.saveSvgAsPng(svg, 'qrcode.png', {
			scale: 5,
			encoderOptions: 1,
			backgroundColor: 'white',
		});
	};

	if (
		Isset(() => data.status.id) &&
		data.status.id === 7
		// (Isset(() => data.id) && (!Isset(() => data.pagamentos) || data.pagamentos.length < 1))
	) {
		return <ZNotFound text="Venda Em Processamento" />;
	}

	const isPos = Isset(() => data.pos) && data.pos !== null;
	const validandoStatus = Isset(() => data.status.id) && data.status.id !== null && data.status.id === 6;

	let textoBotaoVermelho = 'CANCELAR BOLETO';
	if (validandoStatus && usuario.estabelecimento.marketplace.id === 129) {
		textoBotaoVermelho = 'TRANSFERIR';
	}

	// parametro pra aparecer botao extornar pra elo7 quando o status do boleto for aprovado.
	const parametroElo7 =
		data &&
		data.tipoPagamento.id === 1 &&
		// data.pagamentos[0].statusPagamento.id === 2 &&
		// data.status.id === 2 &&
		usuario.estabelecimento.marketplace.id === 129 &&
		!data.oculto &&
		[1, 2, 7, 10].includes(usuario.grupo_id) &&
		!props.inUrlPublica;

	const parametroSandbox =
		data &&
		data.tipoPagamento.id === 1 &&
		// data.pagamentos[0].statusPagamento.id === 2 &&
		data.status.id === 2 &&
		usuario.estabelecimento.marketplace.id === 3 &&
		!data.oculto &&
		[1, 2, 7, 10].includes(usuario.grupo_id) &&
		!props.inUrlPublica;
	const [bancos, setBancos] = useState([]);
	const [showFields, setShowFields] = useState(false);
	const [dataExtorno, setDataExtorno] = useState({
		documento: '',
		tipoConta: 1,
		nome: '',
		bancoId: '',
		agencia: null,
		conta: null,
	});
	const getBancos = async () => {
		const result = await axios.get(`${config.get('apiUrl')}bancos`);

		if (result.data.success) {
			setBancos(result.data.bancos);
			setShowFields(false);
		}
	};

	return (
		<>
			<Grid
				id="section-to-print"
				container
				spacing={3}
				className={clsx({ [classes.urlPublicaStyle]: props.inUrlPublica })}
			>
				<Grid item xs={6}>
					<div className={classes.header}>Detalhes da Transação</div>

					{Isset(() => data.zoop_transaction_id) && (
						<>
							{!props.inUrlPublica && (
								<div className={classes.labelTransacao}>
									ID da Transação: <span>{data.zoop_transaction_id}</span>
									{data.referencia && (
										<>
											<br />
											Referência: <span>{data.referencia}</span>
										</>
									)}
									<br />
								</div>
							)}
							<div className={classes.labelTransacao}>
								Data da Transação:{' '}
								<span className={classes.dataVenda}>{format(data.created, 'DD/MM/YYYY HH:mm')}</span>
							</div>
							<div className={classes.labelTransacao}>
								Estabelecimento: <span>{data.estabelecimento.nome_fantasia}</span>
							</div>
						</>
					)}
				</Grid>

				<Grid item xs={6} align={'right'}>
					{data &&
						data.tipoPagamento.id === 1 &&
						data.status.id === 1 &&
						!data.oculto &&
						[1, 2, 7, 10].includes(usuario.grupo_id) &&
						!props.inUrlPublica && (
							<>
								<Button
									variant="contained"
									color="secondary"
									onClick={() => {
										getBancos();
										setDataExcluirBoleto({ ...dataExcluirBoleto, pedido_id: data.id, open: true });
									}}
									size="small"
								>
									{textoBotaoVermelho}
								</Button>
								<ZModal
									title={`Cancelar Boleto`}
									description={'Deseja cancelar esse boleto? Essa ação não poderá ser desfeita.'}
									open={dataExcluirBoleto.open}
									// onClose={handleEstornar}
									handleClose={() => setDataExcluirBoleto({ ...dataExcluirBoleto, open: false })}
									handleOnSave={handleExcluirBoleto}
									content={
										<Grid container direction="row" justifyContent="center" alignItems="center">
											{/* <Grid item xs={12} md={6}>
											<ZText
												value={dataEstornar.valorTotal}
												label={'Valor a ser estornado'}
												onChange={value =>
													setDataEstornar({ ...dataEstornar, valorTotal: value })
												}
												realMask={true}
											/>
										</Grid> */}
										</Grid>
									}
								/>
							</>
						)}
					{/* botao extornar pra elo7 \/ */}
					{data && data.status.id === 6 && (parametroElo7 || parametroSandbox) && (
						<>
							<Button
								variant="contained"
								color="secondary"
								onClick={() => {
									getBancos();
									setDataExcluirBoleto({ ...dataExcluirBoleto, pedido_id: data.id, open: true });
								}}
								size="small"
							>
								{textoBotaoVermelho}
							</Button>
							<ZModalEstornar
								loading={loading}
								title={`Transferir`}
								// description={'Deseja estornar esse boleto?  Essa ação não poderá ser desfeita.'}
								bancos={bancos}
								setData={setDataExtorno}
								data={dataExtorno}
								open={dataExcluirBoleto.open}
								// onClose={handleEstornar}
								handleClose={() => setDataExcluirBoleto({ ...dataExcluirBoleto, open: false })}
								handleOnSave={handleTranferirBoleto}
								content={
									<Grid container direction="row" justifyContent="center" alignItems="center"></Grid>
								}
							/>
						</>
					)}
					{canEstornarVenda &&
						data &&
						(([2, 3, 6].includes(data.tipoPagamento.id) &&
							data.status.id === 2 &&
							[1, 2, 7, 10].includes(usuario.grupo_id) &&
							data.tipo_pedido_id === 1) ||
							(data.tipo_pedido_id === 3 && data.tipoPagamento.id === 6 && data.status.id === 2) ||
							(parametroElo7 && data.status.id === 2)) &&
						!props.inUrlPublica && (
							<>
								<Button
									variant="contained"
									color="secondary"
									onClick={() =>
										setDataEstornar({
											...dataEstornar,
											valorTotal: data.valores.total,
											open: true,
										})
									}
									size="small"
								>
									Estornar
								</Button>
								<ZModal
									title={`Estornar venda`}
									description={'Deseja estornar essa venda? Essa ação não poderá ser desfeita.'}
									open={dataEstornar.open}
									// onClose={handleEstornar}
									handleClose={() => setDataEstornar({ ...dataEstornar, open: false })}
									handleOnSave={handleEstornar}
									// content={
									// 	<Grid container direction="row" justifyContent="center" alignItems="center">
									// 		<Grid item xs={12} md={6}>
									// 			<ZText
									// 				value={dataEstornar.valorTotal}
									// 				label={'Valor a ser estornado'}
									// 				disabled={true}
									// 				onChange={value => {
									// 					if (+value <= data.valores.total) {
									// 						setDataEstornar({ ...dataEstornar, valorTotal: value });
									// 					} else {
									// 						setDataEstornar({
									// 							...dataEstornar,
									// 							valorTotal: data.valores.total,
									// 						});
									// 					}
									// 				}}
									// 				realMask={true}
									// 			/>
									// 		</Grid>
									// 	</Grid>
									// }
								/>
							</>
						)}

					{(isRefunded || isCanceled) && data && data.tipoPagamento.id !== 1 && data.tipoPagamento.id !== 6 && (
						<div className={classes.divButton}>
							<ZSaveButton
								onClick={getCartaCancelamento}
								color={'secondary'}
								align="right"
								label={'Carta de Cancelamento'}
								size="small"
								loading={cartaCancelamentoLoading}
							/>
						</div>
					)}

					{isMaster && !props.inUrlPublica && (
						<div className={classes.divButton}>
							<Button
								style={{ background: '#4a6682' }}
								variant="contained"
								color="secondary"
								onClick={reprocessarVenda}
								size="small"
							>
								Reprocessar Venda
							</Button>
						</div>
					)}

					{!props.inUrlPublica && (
						<div className={classes.divButton}>
							<Button
								style={{ background: '#4a6682' }}
								variant="contained"
								color="secondary"
								onClick={() => {
									setCopiarModal({
										...copiarModal,
										open: true,
										url: `${window.location.origin.toString()}/app/vendas/detalhes/${
											match.params.vendaId
										}/publico`,
									});
									setTitle('Compartilhar Detalhes da Transação');
									setLabel('Link');
								}}
								size="small"
							>
								Compartilhar
							</Button>
						</div>
					)}
					<Tooltip title="Imprimir" arial-label="imprimir">
						<Button className={classes.printButton} onClick={() => window.print()}>
							<FiPrinter size={16} />
						</Button>
					</Tooltip>
				</Grid>

				{data === null ? (
					<>{loading ? <ZLoader height={200} /> : <ZNotFound text="Venda não encontrada." />}</>
				) : (
					<Grid item xs={12}>
						<Grid container spacing={3}>
							<Grid item xs={12} style={{ marginBottom: data.tipoPagamento.id === 6 ? 10 : 96 }}>
								<Paper style={{ padding: '0px 10px' }}>
									{/* <Grid style={{ position: 'relative', marginBottom: 20 }} container>
								</Grid> */}
									<Grid
										style={{ textAlign: 'center' }}
										justifyContent={'center'}
										container
										spacing={3}
									>
										{!data.parent_id ? (
											<>
												<Grid item xs={12} sm={6} md={props.inUrlPublica ? 3 : 2}>
													<div className={classes.field}>
														<div className={classes.label}>Valor da Transação</div>
														<div className={classes.valorTotal}>
															{Formatter.Real(data.valores.total)}
														</div>
													</div>
												</Grid>
												{!props.inUrlPublica && (
													<Grid item xs={12} sm={6} md={2}>
														{canViewTaxasTooltip ? (
															<Tooltip
																title={
																	<TooltipTitle
																		taxas={data.valores}
																		taxasFinais={data.taxasFinais}
																		isAdmin={isAdmin}
																		isGerencial={isGerencial}
																		isCanceled={isCanceled}
																	/>
																}
																placement="top"
															>
																<div className={classes.field}>
																	<div className={classes.label}>
																		Taxa{' '}
																		<FaInfoCircle
																			style={{ marginLeft: 5, fontSize: 10 }}
																		/>
																	</div>
																	<div className={classes.valorTotal}>
																		{isCanceled
																			? Formatter.Real(0)
																			: Formatter.Real(+data.valores.taxaTotal)}
																		{/* {Formatter.Real(+data.valores.taxaTotal)} */}
																		{/* {Formatter.Real(
																			agruparTaxas
																				? +data.valores.taxaTotal +
																						+data.valores.splitNormal
																				: +data.valores.taxaTotal
																		)} */}
																	</div>
																</div>
															</Tooltip>
														) : (
															<div className={classes.field}>
																<div className={classes.label}>Taxa</div>
																<div className={classes.valorTotal}>
																	{isCanceled
																		? Formatter.Real(0)
																		: Formatter.Real(+data.valores.taxaTotal)}
																	{/* {Formatter.Real(+data.valores.taxaTotal)} */}
																	{/* {Formatter.Real(
																			agruparTaxas
																				? +data.valores.taxaTotal +
																						+data.valores.splitNormal
																				: +data.valores.taxaTotal
																		)} */}
																</div>
															</div>
														)}
													</Grid>
												)}
												{!agruparTaxas && !props.inUrlPublica && (
													<Grid item xs={12} sm={6} md={2}>
														<div className={classes.field}>
															<div className={classes.label}>Split</div>
															<div className={classes.valorTotal}>
																{isCanceled
																	? Formatter.Real(0)
																	: Formatter.Real(+data.valores.splitNormal)}
																{/* {Formatter.Real(+data.valores.splitNormal)} */}
															</div>
														</div>
													</Grid>
												)}
												{!props.inUrlPublica && (
													<Grid item xs={12} sm={6} md={props.inUrlPublica ? 3 : 2}>
														<div className={classes.field}>
															<div className={classes.label}>Valor Líquido</div>
															<div className={classes.valorTotal}>
																{Formatter.Real(+data.valores.liquido)}
															</div>
														</div>
													</Grid>
												)}
											</>
										) : (
											<>
												<Grid item xs={12} sm={6} md={props.inUrlPublica ? 3 : 2}>
													<div className={classes.field}>
														<div className={classes.label}>Valor da Transação</div>
														<div className={classes.valorTotal}>
															{Formatter.Real(data.valores.total)}
														</div>
													</div>
												</Grid>
												<Grid item xs={12} sm={6} md={props.inUrlPublica ? 3 : 2}>
													<div className={classes.field}>
														<div className={classes.label}>Valor liquido</div>
														<div className={classes.valorTotal}>
															{Formatter.Real(+data.valores.liquido)}
														</div>
													</div>
												</Grid>
											</>
										)}
										{/* <Grid item xs={12} sm={6} md={2}>
										<div className={classes.field}>
											{isAdmin && data.status.id === 2 ? (
												<>
													<div className={clsx(classes.valorTotal, classes.valorSmall)}>
														<small>Valor Líquido</small>{' '}
														{Formatter.Real(data.valores.liquido)}
													</div>
													<div className={clsx(classes.valorTotal, classes.valorSmall)}>
														<small>Markup</small> {Formatter.Real(data.taxasFinais.markup)}
													</div>
													<div
														className={clsx(
															classes.valorTotal,
															classes.valorSmall,
															classes.markupCalculado
														)}
													>
														<small>Markup Filho</small>{' '}
														{Formatter.Real(data.taxasFinais.markupFilho)}
													</div>
												</>
											) : (
												<>
													{operarMarketplace ? (
														<>
															<div
																className={clsx(classes.valorTotal, classes.valorSmall)}
															>
																<small>Valor Líquido</small>{' '}
																{Formatter.Real(data.valores.liquido)}
															</div>
															<div
																className={clsx(classes.valorTotal, classes.valorSmall)}
															>
																<small>Markup</small>{' '}
																{Formatter.Real(data.taxasFinais.markupEC)}
															</div>
														</>
													) : (
														<>
															<div className={classes.label}>Valor Líquido</div>
															<div className={classes.valorTotal}>
																{Formatter.Real(data.valores.liquido)}
															</div>
														</>
													)}
												</>
											)}
							
										</div>
									</Grid> */}
										<Grid item xs={12} sm={6} md={props.inUrlPublica ? 3 : 2}>
											<div className={classes.field}>
												<div className={classes.label}>Forma de Pagamento</div>
												<div
													className={clsx(
														classes.valorTotal,
														classes.valorSmall,
														classes.flex2
													)}
												>
													{getPaymentIcon(
														data.tipoPagamento.id,
														data.bandeira,
														classes.paymentIcon
													)}
													{data.tipoPagamento.titulo}
												</div>
											</div>
										</Grid>
										<Grid item xs={12} sm={6} md={props.inUrlPublica ? 3 : 2}>
											<div className={classes.field}>
												<div className={classes.label}>Status</div>
												<div className={classes.valorTotal}> {data.status.titulo}</div>
												{data && data.status.id === 12 && [1].includes(usuario.grupo_id) && (
													<div className={classes.divButton}>
														<Button
															style={{ background: 'red' }}
															variant="contained"
															color="primary"
															onClick={() => setSellWithoutAntifraude({ open: true })}
															size="small"
														>
															Executar transação sem antifraude
														</Button>
													</div>
												)}
											</div>
										</Grid>
										{data.msg_erro && (
											<Grid item md={12} style={{ paddingLeft: 30, paddingRight: 30 }}>
												<Alert severity="error">
													<AlertTitle>Mensagem de erro</AlertTitle>
													{data.msg_erro}
												</Alert>
											</Grid>
										)}
									</Grid>
								</Paper>
							</Grid>

							<Grid
								style={{ marginBottom: data.tipoPagamento.id === 6 ? 20 : 96 }}
								container
								item
								alignItems="stretch"
								spacing={2}
							>
								{data.tipoPagamento.id === 6 &&
								Isset(() => data.pix.expirationDate) &&
								data.status.id === 1 && ( // Pix
										<Grid item xs={12} className={classes.cardContainerMobile}>
											<Paper
												style={{ padding: 15, height: '100%', minHeight: 270 }}
												className={classes.paper}
											>
												<Grid
													container
													justifyContent="center"
													spacing={2}
													style={{ height: '100%' }}
												>
													{!pixVencido ? (
														<>
															<Grid xs={12} md={2}>
																<Countdown
																	date={data.pix.expirationDate}
																	onComplete={() => setPixVencido(true)}
																	renderer={({
																		hours,
																		minutes,
																		seconds,
																		completed,
																	}) => (
																		<div
																			style={{
																				display: 'flex',
																				justifyContent: 'center',
																				alignItems: 'center',
																				height: '100%',
																				fontWeight: 'bold',
																				flexDirection: 'column',
																			}}
																		>
																			<div>Este PIX expira em</div>
																			<span
																				style={{
																					fontSize: 30,
																					color: '#149611',
																				}}
																			>
																				{`${hours}`.padStart(2, '0')}:
																				{`${minutes}`.padStart(2, '0')}:
																				{`${seconds}`.padStart(2, '0')}
																			</span>
																			<div>minutos</div>
																		</div>
																	)}
																/>
															</Grid>
															<Grid xs={12} md={3}>
																<div
																	ref={qrCodeRef}
																	style={{
																		position: 'relative',
																		left: 20,
																		height: 256,
																		cursor: 'pointer',
																	}}
																	onClick={downloadQRCode}
																>
																	<QRCode
																		renderAs="svg"
																		value={data.pix.qrCode}
																		width={256}
																		height={256}
																	/>
																	<div className={classes.qrcodeHover}>
																		<FaDownload />
																	</div>
																</div>
															</Grid>
															<Grid xs={12} md={4}>
																<div
																	style={{
																		display: 'flex',
																		justifyContent: 'center',
																		alignItems: 'center',
																		height: '100%',
																		fontWeight: 'bold',
																		flexDirection: 'column',
																	}}
																>
																	<div style={{ fontSize: 18 }}>
																		PIX Copia & Cola <FaCopy />
																	</div>
																	<div
																		className={classes.hoverPixCopiaCola}
																		onClick={() => {
																			if (navigator.clipboard) {
																				navigator.clipboard.writeText(
																					data.pix.qrCode
																				);
																			} else {
																				fallbackCopyTextToClipboard(
																					data.pix.qrCode
																				);
																			}
																			dispatch(
																				addMessage({
																					type: 'success',
																					message:
																						'Código PIX Copiado com sucesso.',
																				})
																			);
																		}}
																	>
																		{data.pix.qrCode}
																	</div>
																</div>
															</Grid>
														</>
													) : (
														<Grid
															xs={12}
															style={{
																display: 'flex',
																justifyContent: 'center',
																alignItems: 'center',
																height: '100%',
																fontWeight: 'bold',
																fontSize: 30,
																color: '#dc3e3e',
															}}
														>
															Pix Vencido
														</Grid>
													)}
												</Grid>
											</Paper>
										</Grid>
									)}

								{[1, 2, 3, 10].includes(data.tipoPagamento.id) && (
									<Grid item xs={12} md={6} style={{ marginTop: 20, textAlign: 'center' }}>
										{[2, 3].includes(data.tipoPagamento.id) &&
											Isset(() => data.pagamentos[0].pagamentoCartao) && (
												<Paper
													style={{ padding: 15, height: '100%' }}
													className={classes.paper}
												>
													<Grid
														container
														spacing={2}
														style={{ marginTop: 20 }}
														// justifyContent={'center'}
													>
														<div className={classes.subHeader}>
															Dados do Cartão{' '}
															{isPos && !props.inUrlPublica && <small>(Via POS)</small>}
														</div>
														<Grid item xs={12} sm={6} md={3}>
															<div className={classes.field}>
																<div className={classes.label}>Titular</div>
																<div className={classes.labelText}>
																	{Isset(
																		() =>
																			data.pagamentos[0].pagamentoCartao
																				.nome_titular
																	)
																		? data.pagamentos[0].pagamentoCartao
																				.nome_titular
																		: '---'}
																</div>
															</div>
														</Grid>
														<Grid item xs={12} sm={6} md={3}>
															<div className={classes.field}>
																<div className={classes.label}>Bandeira</div>
																<div className={classes.labelText}>
																	{Isset(
																		() =>
																			data.pagamentos[0].pagamentoCartao.bandeira
																	)
																		? data.pagamentos[0].pagamentoCartao.bandeira
																		: '---'}
																</div>
															</div>
														</Grid>

														<Grid item xs={12} sm={6} md={3}>
															<div className={classes.field}>
																<div className={classes.label}>Número</div>
																<div className={classes.labelText}>
																	**** **** ****{' '}
																	{Isset(
																		() =>
																			data.pagamentos[0].pagamentoCartao
																				.ultimos_digitos
																	)
																		? data.pagamentos[0].pagamentoCartao
																				.ultimos_digitos
																		: '****'}
																</div>
															</div>
														</Grid>

														<Grid item xs={12} sm={6} md={3}>
															<div className={classes.field}>
																<div className={classes.label}>Vencimento</div>
																{Isset(
																	() =>
																		data.pagamentos[0].pagamentoCartao.mes_expiracao
																) &&
																Isset(
																	() =>
																		data.pagamentos[0].pagamentoCartao.ano_expiracao
																) ? (
																	<div>
																		{data.pagamentos[0].pagamentoCartao.mes_expiracao
																			.toString()
																			.padStart(2, '0')}
																		/
																		{
																			data.pagamentos[0].pagamentoCartao
																				.ano_expiracao
																		}
																	</div>
																) : (
																	<div>{'--/--'}</div>
																)}
															</div>
														</Grid>
													</Grid>
												</Paper>
											)}

										{[1, 10].includes(data.tipoPagamento.id) && (
											<Paper style={{ padding: 15, height: '100%' }} className={classes.paper}>
												<Grid container spacing={2}>
													<div className={classes.subHeader}>Dados do Boleto</div>

													<Grid item xs={12} sm={6} md={6}>
														<div className={classes.field}>
															<div className={classes.label}>Descrição</div>
															<div className={classes.labelText}>
																{Isset(
																	() => data.pagamentos[0].pagamentoBoleto.descricao
																)
																	? data.pagamentos[0].pagamentoBoleto.descricao
																	: ''}
															</div>
														</div>
													</Grid>

													<Grid item xs={12} sm={6} md={6}>
														<div className={classes.field}>
															<div className={classes.label}>Vencimento</div>
															<div className={classes.labelText}>
																{Isset(
																	() =>
																		data.pagamentos[0].pagamentoBoleto
																			.data_vencimento
																)
																	? moment(
																			data.pagamentos[0].pagamentoBoleto
																				.data_vencimento,
																			'YYYY-MM-DD'
																	  ).format('DD/MM/YYYY')
																	: ''}
															</div>
														</div>
													</Grid>

													{data.status.id === 1 &&
														Isset(() => data.tipoPagamento.boleto.url) && (
															<>
																<Grid
																	item
																	xs={12}
																	sm={
																		usuario.estabelecimento.marketplace.id !== 25
																			? 4
																			: 6
																	}
																	md={
																		usuario.estabelecimento.marketplace.id !== 25
																			? 4
																			: 6
																	}
																	style={{
																		display: 'flex',
																		justifyContent: 'center',
																	}}
																>
																	<a
																		style={{ textDecoration: 'none' }}
																		href={
																			data.tipoPagamento.boleto.img_boleto
																				? data.tipoPagamento.boleto.img_boleto
																				: `/vendas/${
																						match.params.vendaId
																							? match.params.vendaId
																							: props.vendaId
																				  }/ver-boleto`
																		}
																		target="_blank"
																		rel="noreferrer noopener"
																	>
																		<div className={classes.divButton}>
																			<Button
																				variant="contained"
																				color="primary"
																				size="small"
																			>
																				Ver Boleto
																			</Button>
																		</div>
																	</a>
																</Grid>
																{usuario.estabelecimento.marketplace.id !== 25 && (
																	<Grid
																		item
																		xs={12}
																		sm={4}
																		md={4}
																		style={{
																			display: 'flex',
																			justifyContent: 'center',
																		}}
																	>
																		<a
																			style={{ textDecoration: 'none' }}
																			href={`/boleto/${
																				match.params.vendaId
																					? match.params.vendaId
																					: props.vendaId
																			}/publico`}
																			target="_blank"
																			rel="noreferrer noopener"
																		>
																			<div className={classes.divButton}>
																				<Button
																					variant="contained"
																					color="primary"
																					size="small"
																				>
																					Baixar Boleto
																				</Button>
																			</div>
																		</a>
																	</Grid>
																)}
																<Grid
																	item
																	xs={12}
																	sm={
																		usuario.estabelecimento.marketplace.id !== 25
																			? 4
																			: 6
																	}
																	md={
																		usuario.estabelecimento.marketplace.id !== 25
																			? 4
																			: 6
																	}
																	style={{
																		display: 'flex',
																		justifyContent: 'center',
																	}}
																>
																	<div className={classes.divButton}>
																		<Button
																			variant="contained"
																			color="primary"
																			onClick={() => {
																				setCopiarModal({
																					...copiarModal,
																					open: true,
																					url:
																						data.tipoPagamento.boleto
																							.codigo_barras,
																				});
																				setTitle('Copiar Código de Barras');
																				setLabel('Código de Barras');
																			}}
																			size="small"
																		>
																			Código de Barras
																		</Button>
																	</div>
																</Grid>
															</>
														)}

													{data.descontos.map((desconto, key) => (
														<Grid item xs={12} sm={4} md={4}>
															<div className={classes.field}>
																<div className={classes.label}>Data limite</div>
																<div className={classes.labelText}>
																	{desconto.limit_date
																		? moment(
																				desconto.limit_date,
																				'YYYY-MM-DD'
																		  ).format('DD/MM/YYYY')
																		: ''}
																</div>
																<div className={classes.label}>
																	{desconto.mode === 'FIXED'
																		? 'Valor Desconto'
																		: 'Percentual Desconto'}
																</div>
																<div className={classes.labelText}>
																	{desconto.mode === 'FIXED'
																		? Mask.Real(desconto.value / 100)
																		: `${Mask.Porcentagem(desconto.value)}%`}
																</div>
															</div>
														</Grid>
													))}
												</Grid>
											</Paper>
										)}
									</Grid>
								)}
								{!isPos && data.cliente !== null && (
									<Grid item xs={12} md={6} style={{ marginTop: 20, textAlign: 'center' }}>
										<Paper style={{ padding: 15, height: '100%' }} className={classes.paper}>
											<Grid
												container
												spacing={2}
												style={{ marginTop: 20 }}
												// justifyContent={'center'}
											>
												<div className={classes.subHeader}>Cliente</div>

												<Grid item xs={12} sm={6} md={4}>
													<div className={classes.field}>
														<div className={classes.label}>Nome</div>
														<div className={classes.labelText}>{data.cliente.nome}</div>
													</div>
												</Grid>
												{!props.inUrlPublica && (
													<Grid item xs={12} sm={6} md={4}>
														<div className={classes.field}>
															<div className={classes.label}>E-mail</div>
															<div className={classes.labelText}>
																{data.cliente.email}
															</div>
														</div>
													</Grid>
												)}
												{Isset(() => data.cliente.clientes_documentos[0].documento) &&
													!props.inUrlPublica && (
														<Grid item xs={12} sm={6} md={4}>
															{data.cliente.clientes_documentos[0].documento.length ===
															14 ? (
																<div className={classes.field}>
																	<div className={classes.label}>CNPJ</div>
																	<div className={classes.labelText}>
																		{Formatter.Cnpj(
																			data.cliente.clientes_documentos[0]
																				.documento
																		)}
																	</div>
																</div>
															) : (
																<div className={classes.field}>
																	<div className={classes.label}>CPF</div>
																	<div className={classes.labelText}>
																		{Formatter.Cpf(
																			data.cliente.clientes_documentos[0]
																				.documento
																		)}
																	</div>
																</div>
															)}
														</Grid>
													)}
											</Grid>
										</Paper>
									</Grid>
								)}
								{data.preVenda !== null && (
									<Grid item xs={12} md={6} style={{ marginTop: 70, textAlign: 'center' }}>
										<Paper style={{ padding: 15, height: '100%' }} className={classes.paper}>
											<Grid container spacing={2} style={{ margin: 10 }}>
												<div className={classes.subHeader}>Link de Pagamento</div>

												<Grid item xs={12} sm={6} md={3}>
													<div className={classes.field}>
														<div className={classes.label}>ID</div>
														<div className={classes.labelText}>{data.preVenda.id}</div>
													</div>
												</Grid>
												<Grid item xs={12} sm={6} md={4}>
													<div className={classes.field}>
														<div className={classes.label}>Título</div>
														<div className={classes.labelText}>{data.preVenda.titulo}</div>
													</div>
												</Grid>
												<Grid item xs={12} sm={6} md={5}>
													<div className={classes.field}>
														<div className={classes.label}>Descrição</div>
														<div className={classes.labelText}>
															{data.preVenda.descricao}
														</div>
													</div>
												</Grid>
											</Grid>
										</Paper>
									</Grid>
								)}
							</Grid>
							{canViewSplit && data.splitsNormais.length > 0 && (
								<Grid item xs={12} style={{ marginBottom: 96 }}>
									<Paper style={{ padding: 15 }} className={classes.paper}>
										<Grid container>
											<div className={classes.subHeader}>Splits</div>

											<Grid container item xs={12} className={classes.tableHeader}>
												<Grid item xs={6}>
													Estabelecimento
												</Grid>
												<Grid item xs={3}></Grid>
												{/* <Grid item xs={2}>
												Valor
											</Grid> */}
												<Grid item xs={3}>
													Valor
												</Grid>
											</Grid>
											{data.splitsNormais.map((split, key) => (
												<Grid container item xs={12} key={key} className={classes.tableRow}>
													<Grid item xs={6}>
														{Isset(() => split.estabelecimento.nome_fantasia)
															? split.estabelecimento.nome_fantasia
															: ''}
													</Grid>
													<Grid item xs={3}>
														{getCategoriaById(split.categoria)}
													</Grid>
													{/* <Grid item xs={2}>
													{Formatter.Real(split.zoop_gross_amount)}
												</Grid> */}
													<Grid item xs={3}>
														{Formatter.Real(split.zoop_amount)}
													</Grid>
												</Grid>
											))}
										</Grid>
									</Paper>
								</Grid>
							)}
							{!props.inUrlPublica && (
								<Grid item xs={12}>
									<Paper style={{ padding: 15 }} className={classes.paper}>
										<Grid container>
											<div className={classes.subHeader}>Recebíveis</div>

											<Grid
												container
												item
												xs={12}
												justifyContent="center"
												className={classes.tableHeader}
											>
												<Grid item xs={1}>
													{isXs ? '#' : 'Parcela'}
												</Grid>
												<Grid item xs={2}>
													Status
												</Grid>
												<Grid item xs={isXs ? 5 : 2}>
													Valor
												</Grid>
												{!isXs && !data.parent_id && (
													<>
														{agruparTaxas ? (
															<Grid item xs={2}>
																Taxa
															</Grid>
														) : (
															<>
																<Grid item xs={1}>
																	Taxa
																</Grid>
																<Grid item xs={1}>
																	Split
																</Grid>
															</>
														)}
														<Grid item xs={2}>
															Valor Líquido
														</Grid>
													</>
												)}
												<Grid item xs={2}>
													Recebimento
												</Grid>
											</Grid>

											{data.pagamentos.map((pagamento, key) => {
												return (
													<Grid
														container
														justifyContent="center"
														item
														xs={12}
														md={12}
														key={key}
														className={classes.tableRow}
													>
														<Grid item xs={1} md={1}>
															{pagamento.parcela}
														</Grid>
														<Grid item xs={2} md={2}>
															{pagamento.statusPagamento.titulo}
														</Grid>
														{!isXs ? (
															<Grid item xs={2} md={2}>
																<div>
																	{Formatter.Real(parseFloat(pagamento.valor))}
																	{/* {Formatter.Real(
																		parseFloat(pagamento.valor) +
																			parseFloat(
																				data.pagamentos.length - 1 === key
																					? +data.splitParcelaFinal +
																							+data.spreadParcelaFinal
																					: +data.splitParcela +
																							+data.spreadParcela
																			)
																	)} */}
																</div>
															</Grid>
														) : (
															<Grid item xs={5} md={5}>
																<div>
																	Total {Formatter.Real(parseFloat(pagamento.valor))}
																	{/* {Formatter.Real(
																		parseFloat(pagamento.valor) +
																			parseFloat(
																				data.pagamentos.length - 1 === key
																					? +data.splitParcelaFinal +
																							+data.spreadParcelaFinal
																					: +data.splitParcela +
																							+data.spreadParcela
																			)
																	)} */}
																</div>
																{agruparTaxas ? (
																	<div>
																		Taxa{' '}
																		{isCanceled
																			? Formatter.Real(0)
																			: Formatter.Real(+pagamento.taxaTotal)}
																		{/* Taxa {Formatter.Real(+pagamento.taxaTotal)} */}
																		{/* {Formatter.Real(
																			+pagamento.taxa +
																				+data.splitParcela +
																				+data.spreadParcela
																		)} */}
																	</div>
																) : (
																	<>
																		<div>
																			Taxa{' '}
																			{isCanceled
																				? Formatter.Real(0)
																				: Formatter.Real(
																						+pagamento.taxa +
																							(data.pagamentos.length -
																								1 ===
																							key
																								? +data.spreadParcelaFinal
																								: +data.spreadParcela)
																				  )}
																			{/* {Formatter.Real(
																				+pagamento.taxa +
																					(data.pagamentos.length - 1 === key
																						? +data.spreadParcelaFinal
																						: +data.spreadParcela)
																			)} */}
																		</div>
																		<div>
																			Split{' '}
																			{Formatter.Real(
																				data.pagamentos.length - 1 === key
																					? data.splitParcelaFinal
																					: data.splitParcela
																			)}
																		</div>
																	</>
																)}
																<div>
																	Líquido {Formatter.Real(pagamento.valorRecebido)}
																</div>
															</Grid>
														)}
														{!isXs && !data.parent_id && (
															<>
																{agruparTaxas ? (
																	<Grid item xs={2} md={2}>
																		{isCanceled
																			? Formatter.Real(0)
																			: Formatter.Real(
																					parseFloat(pagamento.taxaTotal)
																			  )}
																		{/* {Formatter.Real(
																			parseFloat(pagamento.taxaTotal)
																		)} */}
																		{/* {Formatter.Real(+pagamento.taxaTotal)} */}
																		{/* {Formatter.Real(
																			+pagamento.taxa +
																				(data.pagamentos.length - 1 === key
																					? +data.splitParcelaFinal +
																					  +data.spreadParcelaFinal
																					: +data.splitParcela +
																					  +data.spreadParcela)
																		)} */}
																	</Grid>
																) : (
																	<>
																		{isAdmin ? (
																			<Tooltip
																				title={
																					<TooltipTitle2
																						taxas={{
																							taxaZoop: +pagamento.taxa,
																							tabelaMarkup:
																								data.pagamentos.length -
																									1 ===
																								key
																									? +data.spreadParcelaFinal
																									: +data.spreadParcela,
																						}}
																						isCanceled={isCanceled}
																					/>
																				}
																				placement="top"
																			>
																				<Grid item xs={1} md={1}>
																					{isCanceled
																						? Formatter.Real(0)
																						: Formatter.Real(
																								+pagamento.taxa +
																									(data.pagamentos
																										.length -
																										1 ===
																									key
																										? +data.spreadParcelaFinal
																										: +data.spreadParcela)
																						  )}
																					{/* {Formatter.Real(
																						+pagamento.taxa +
																							(data.pagamentos.length -
																								1 ===
																							key
																								? +data.spreadParcelaFinal
																								: +data.spreadParcela)
																					)} */}
																					<FaInfoCircle
																						style={{
																							marginLeft: 5,
																							fontSize: 10,
																						}}
																					/>
																				</Grid>
																			</Tooltip>
																		) : (
																			<Grid item xs={1} md={1}>
																				{isCanceled
																					? Formatter.Real(0)
																					: Formatter.Real(
																							+pagamento.taxa +
																								(data.pagamentos
																									.length -
																									1 ===
																								key
																									? +data.spreadParcelaFinal
																									: +data.spreadParcela)
																					  )}
																				{/* {Formatter.Real(
																					+pagamento.taxa +
																						(data.pagamentos.length - 1 ===
																						key
																							? +data.spreadParcelaFinal
																							: +data.spreadParcela)
																				)} */}
																				<FaInfoCircle
																					style={{
																						marginLeft: 5,
																						fontSize: 10,
																					}}
																				/>
																			</Grid>
																		)}
																		<Grid item xs={1} md={1}>
																			{isCanceled
																				? Formatter.Real(0)
																				: Formatter.Real(
																						data.pagamentos.length - 1 ===
																							key
																							? data.splitParcelaFinal
																							: data.splitParcela
																				  )}
																			{/* {Formatter.Real(
																				data.pagamentos.length - 1 === key
																					? data.splitParcelaFinal
																					: data.splitParcela
																			)} */}
																		</Grid>
																	</>
																)}
																<Grid item xs={2} md={2}>
																	{isCanceled
																		? Formatter.Real(0)
																		: Formatter.Real(
																				parseFloat(pagamento.valorRecebido)
																		  )}
																	{/* {Formatter.Real(pagamento.valorRecebido)} */}
																</Grid>
															</>
														)}
														{pagamento.dataRecebimento && (
															<Grid item xs={2} md={2}>
																{format(
																	pagamento.dataRecebimento.substr(0, 10),
																	'DD/MM/YYYY'
																)}
															</Grid>
														)}
													</Grid>
												);
											})}
										</Grid>
									</Paper>
								</Grid>
							)}
						</Grid>
					</Grid>
				)}
			</Grid>

			<ZModalConfirm
				title={`Executar a transação sem antifraude.`}
				message={`O Antifraude negou essa venda por motivos se segurança. Deseja continuar?`}
				open={sellWithoutAntifraude.open}
				onClose={async result => {
					if (result) {
						await axios.put(
							`${config.get('apiUrl')}vendas/${data.id}/venda_sem_antifraude`,
							{},
							{
								headers: { Authorization: `Bearer ${token}` },
							}
						);
					}

					setSellWithoutAntifraude({ open: false });
				}}
			/>

			<ModalLink
				title={title}
				open={copiarModal.open}
				value={[{ label: label, value: copiarModal.url }]}
				handleClose={() => {
					setCopiarModal({ ...copiarModal, open: false, url: '' });
					setTitle('');
					setLabel('');
				}}
			/>
		</>
	);
};
