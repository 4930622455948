/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import config from 'react-global-configuration';
import { addMessage } from '../../store/alert';

import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Close';
import Fab from '@material-ui/core/Fab';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import ZLoader from '../system/ZLoader';
import ZModalConfirm from '../system/ZModalConfirm';

const useStyles = makeStyles(theme => ({
	urlWebhooks: {
		marginTop: 5,
	},
	urlWebhooksPaper: {
		padding: 10,
		paddingTop: 40,
		position: 'relative',
	},
	btnAdd: {
		position: 'absolute',
		right: 10,
		top: 10,
	},
	headerTitle: {
		fontWeight: 'bold',
		fontSize: 15,
	},
	urlRow: {
		borderTop: 'solid 1px #abc',
	},
	removeButton: {
		minHeight: 26,
		height: 26,
		width: 26,
		backgroundColor: '#e53935',
		color: 'white',
	},
	fullWidth: {
		width: 400,
	},
}));

export default memo(props => {
	const classes = useStyles();

	const [urlError, setUrlError] = useState(false);
	const token = useSelector(store => store.auth.token);
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(true);
	const [urlWebhooks, setUrlWebhooks] = useState([]);
	const [removerUrl, setRemoverUrl] = useState({
		urlId: null,
		open: false,
	});
	const [openModal, setOpenModal] = useState(false);
	const [url, setUrl] = useState('');

	const getUrlWebhooks = async () => {
		setLoading(true);
		const res = await axios.get(`${config.get('apiUrl')}estabelecimentos/url-webhook`, {
			headers: { Authorization: `Bearer ${token}` },
		});

		if (res.data.success) {
			setUrlWebhooks(res.data.urlWebhooks);
		}
		setLoading(false);
	};

	useEffect(() => {
		getUrlWebhooks();
	}, []);

	const handleAddUrl = async () => {
		if (!url) {
			setUrlError(true);
			return;
		}

		setUrlError(false);

		const res = await axios.post(
			`${config.get('apiUrl')}estabelecimentos/url-webhook`,
			{ url },
			{
				headers: { Authorization: `Bearer ${token}` },
			}
		);

		if (res.data.success) {
			dispatch(addMessage({ type: 'success', message: 'Operação realizada com sucesso' }));
			setUrl('');
			getUrlWebhooks();
		}

		setOpenModal(false);
	};

	const handleRemoverUrl = async result => {
		if (result) {
			const res = await axios.delete(`${config.get('apiUrl')}estabelecimentos/url-webhook/${removerUrl.urlId}`, {
				headers: { Authorization: `Bearer ${token}` },
			});

			if (res.data.success) {
				dispatch(addMessage({ type: 'success', message: 'Operação realizada com sucesso' }));
				getUrlWebhooks();
			}
		}

		setRemoverUrl({ ...removerUrl, open: false, urlId: null });
	};

	const handleClose = result => {
		if(result === 'escapeKeyDown' || result === 'backdropClick') {
			setOpenModal(false);
		}
	};

	return (
		<div className={classes.urlWebHooks}>
			<Paper className={classes.urlWebhooksPaper}>
				<Fab
					className={classes.btnAdd}
					color="primary"
					onClick={() => setOpenModal(true)}
					size="medium"
					aria-label="Add"
				>
					<AddIcon />
				</Fab>
				<Grid container spacing={2} justifyContent="center">
					{loading ? (
						<ZLoader height={200} />
					) : (
						<>
							<Grid item container className={classes.header}>
								<Grid item xs className={classes.headerTitle}>
									URL Webhooks
								</Grid>
								<Grid item xs={1} className={classes.headerTitle}></Grid>
							</Grid>
							{urlWebhooks.map(urlWebhook => (
								<Grid item container alignItems="center" className={classes.urlRow}>
									<Grid item xs>
										{urlWebhook.valor}
									</Grid>
									<Grid item xs={1}>
										<Fab
											size="small"
											className={classes.removeButton}
											onClick={() => {
												setRemoverUrl({
													...removerUrl,
													urlId: urlWebhook.id,
													open: true,
												});
											}}
										>
											<RemoveIcon size="small" style={{ fontSize: 15 }} />
										</Fab>
									</Grid>
								</Grid>
							))}
							<ZModalConfirm
								title={`Remover URL`}
								message={`Deseja remover essa URL?`}
								open={removerUrl.open}
								onClose={handleRemoverUrl}
							/>
							<Dialog
								open={openModal}
								// disableBackdropClick
								onClose={(event, reason) => {
									handleClose(reason)
								}}
								aria-labelledby="alert-dialog-title"
								aria-describedby="alert-dialog-description"
							>
								<DialogTitle id="alert-dialog-title">Adicionar URL</DialogTitle>
								<DialogContent>
									<TextField
										error={urlError}
										className={classes.fullWidth}
										type="text"
										label="URL"
										value={url}
										onChange={({ target: { value } }) => setUrl(value)}
									/>
								</DialogContent>
								<DialogActions>
									<Button onClick={() => setOpenModal(false)} color="primary">
										Cancelar
									</Button>
									<Button onClick={() => handleAddUrl()} color="primary" autoFocus>
										Salvar
									</Button>
								</DialogActions>
							</Dialog>
						</>
					)}
				</Grid>
			</Paper>
		</div>
	);
});
