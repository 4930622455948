/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import config from 'react-global-configuration';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';

import { addMessage } from '../../store/alert';
import { Isset } from '../../utils';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

import Taxas from '../Marketplace/Taxas';

const useStyles = makeStyles(theme => ({
	planoVendaAtualSpan: {
		fontWeight: 'bold',
	},
	planosTaxasPaper: {
		padding: 10,
	},
}));

export default props => {
	const { estabelecimentoId } = props;

	const token = useSelector(store => store.auth.token);
	const classes = useStyles();
	const dispatch = useDispatch();

	const [planoVendaAtual, setPlanoVendaAtual] = useState(null);
	const [planosTaxas, setPlanosTaxas] = useState([]);
	const [meuPlanoTaxa, setMeuPlanoTaxa] = useState('');
	const [planoChanged, setPlanoChanged] = useState(false);

	useEffect(() => {
		const getPlanoVenda = async () => {
			const res = await axios.get(
				`${config.get('apiUrl')}planos_de_vendas/estabelecimento/${estabelecimentoId}`,
				{
					headers: { Authorization: `Bearer ${token}` },
				}
			);

			if (res.data.success) {
				setPlanoVendaAtual(res.data.plano.titulo);

				const res2 = await axios.get(`${config.get('apiUrl')}planos_taxas/porPlanoVenda/${res.data.plano.id}`, {
					params: {
						estabelecimentoId,
					},
					headers: { Authorization: `Bearer ${token}` },
				});

				if (res2.data.success) {
					setPlanosTaxas(res2.data.planosTaxas);
				}
			}
		};

		const getMeuPlanoTaxa = async () => {
			const res = await axios.get(`${config.get('apiUrl')}planos_taxas/porEstabelecimento/${estabelecimentoId}`, { // renan
				headers: { Authorization: `Bearer ${token}` },
			});

			if (res.data.success && Isset(() => res.data.plano.plano_taxa_marketplace_id)) {
				setMeuPlanoTaxa(res.data.plano.plano_taxa_marketplace_id);
			}
		};

		getMeuPlanoTaxa();
		getPlanoVenda();
	}, []);

	const savePlanoTaxa = async () => {
		if (planoChanged) {
			const res = await axios.post(
				`${config.get('apiUrl')}planos_taxas/porEstabelecimento/${estabelecimentoId}`,
				{
					planoTaxaId: meuPlanoTaxa,
				},
				{
					headers: { Authorization: `Bearer ${token}` },
				}
			);

			if (res.data.success) {
				dispatch(addMessage({ type: 'success', message: 'Tabela de Markup salva com sucesso.' }));
			}
		}
	};

	return (
		<div className={classes.planosTaxas}>
			<Paper className={classes.planosTaxasPaper}>
				<div className={classes.planoVendaAtual}>
					Plano de Venda Ativo: <span className={classes.planoVendaAtualSpan}>{planoVendaAtual}</span>
				</div>
				<Grid style={{ marginTop: 10 }} container spacing={2} alignItems="center">
					<Grid item xs={3}>
						<InputLabel>Tabela de Markup</InputLabel>
						<Select
							style={{ width: '100%' }}
							value={meuPlanoTaxa}
							onChange={e => {
								setMeuPlanoTaxa(e.target.value);
								setPlanoChanged(true);
							}}
							displayEmpty
						>
							<MenuItem value={''}>
								<em>Selecione</em>
							</MenuItem>
							{planosTaxas.map(pt => (
								<MenuItem key={pt.id} value={pt.id}>
									{pt.titulo}
								</MenuItem>
							))}
						</Select>
					</Grid>
					<Grid item xs={3}>
						{planoChanged && (
							<Button variant="contained" color="primary" onClick={savePlanoTaxa}>
								Salvar
							</Button>
						)}
					</Grid>
				</Grid>
			</Paper>
			{meuPlanoTaxa !== '' && (
				<>
					<Taxas planoTaxaId={meuPlanoTaxa} readOnly />
					<Taxas planoTaxaId={meuPlanoTaxa} tipoVendaId={2} readOnly />
				</>
			)}
		</div>
	);
};
