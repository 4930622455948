import React, { useEffect } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import { Mask, Isset } from '../../utils';
import { useEstabelecimento } from '../../hooks/useEstabelecimento';

export function SelectEstabelecimento(props) {
	const { state, setState, options, setEstabelecimento } = useEstabelecimento();

	let optionsValidate = options.filter(a => a.documento !== '');
	optionsValidate = optionsValidate.filter(a => a.nomeFantasia !== '');

	useEffect(() => {
		if (props.value) {
			if (props.estabelecimentoId) {
				setState(optionsValidate.find(a => +a.id === props.estabelecimentoId));
			} else {
				setState(optionsValidate.find(({ documento }) => documento === props.value));
			}
		}
	}, [props.value, optionsValidate, setState]);

	const handleOnChange = (_, value) => {
		if (Isset(() => value)) {
			props.setEstabelecimento(value);
			setEstabelecimento(value);
			setState(value);
		} else {
			setEstabelecimento({});
			props.setEstabelecimento({});
			setState({});
		}
	};

	const getOptionLabel = option => {
		if (option.nomeFantasia && option.documento) {
			return (
				option.nomeFantasia +
				` ` +
				(option.documento.length <= 11 ? Mask.Cpf(option.documento) : Mask.Cnpj(option.documento))
			);
		} else if (option.documento) {
			return ` ` + (option.documento.length <= 11 ? Mask.Cpf(option.documento) : Mask.Cnpj(option.documento));
		}
	};

	const getOptionSelected = (option, value) => {
		if (option && value) {
			return option.nomeFantasia === value.nomeFantasia || option.id === value.id;
		}
	};

	return (
		<Autocomplete
			options={optionsValidate}
			getOptionLabel={getOptionLabel}
			getOptionSelected={getOptionSelected}
			onChange={handleOnChange}
			value={state}
			fullWidth
			renderInput={params => (
				<TextField {...params} placeholder="Estabelecimento" fullWidth size="small" variant="standard" />
			)}
		/>
	);
}
