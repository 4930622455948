import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Paper, Grid, makeStyles, Typography, Tooltip, Button } from '@material-ui/core';
import { useSelector } from 'react-redux';
import axios from 'axios';
import config from 'react-global-configuration';
import moment from 'moment';
import format from 'date-fns/format';
import FileDownload from 'js-file-download';

import ZTitle from '../system/ZTitle';
import ZLoader from '../system/ZLoader';
import ZPaginationDiv from '../system/ZPaginationDiv';
import ZFiltros from '../system/ZFiltros';
import { Formatter } from '../../utils';
import { FaDownload } from 'react-icons/fa';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
	tableRow: {
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}2b`,
		},
	},
	rowTitle: {
		margin: 0,
		textAlign: 'center',
		fontWeight: 700,
		[theme.breakpoints.down('xs')]: {
			textAlign: 'left',
			marginTop: 24,
		},
	},
	rowText: {
		display: 'inline-block',
		fontWeight: 400,
		marginTop: 8,
	},
	exportDownload: {
		color: '#011D29',
		fontSize: 16,
		marginLeft: 8,
	},
	iconButton: {
		minWidth: 0,
	},
	buttonExport: {
		color: '#040633',
		border: `solid 1px #040633`,
		'&:hover': {
			color: '#FFF',
			backgroundColor: '#011d29',
			'& svg': {
				color: '#FFF',
			},
		},
		[theme.breakpoints.down('xs')]: {
			width: '90%',
			marginTop: 16,
		},
		'& svg': {
			margin: 0,
		},
	},
	truncate: {
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		alignItems: 'center',
	},
}));

export default () => {
	const classes = useStyles();

	const history = useHistory();

	const token = useSelector(store => store.auth.token);
	const { id: estabelecimentoSelecionadoId } = useSelector(state => state.admin.estabelecimento) || {};

	const [pagamentos, setPagamentos] = useState([]);
	const [loading, setLoading] = useState(false);

	const [filtros, setFiltros] = useState({
		status: [],
		tipoCobranca: [],
		estabelecimento: '',
		created: {
			start: '',
			end: '',
			label: 'Data da Cobrança',
		},
		mes: format(new Date(), 'MM'),
		ano: format(new Date(), 'YYYY'),
	});

	//paginacao
	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(15);
	const [totalRows, setTotalRows] = useState(0);

	useEffect(() => {
		getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, rowsPerPage]);

	useEffect(() => {
		getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtros, estabelecimentoSelecionadoId]);

	const getOptions = () => {
		const result = {
			status: [
				{ id: '0', name: 'Reprovado' },
				{ id: '1', name: 'Aprovado' },
			],
			tipoCobranca: [
				{ id: 1, name: 'Aluguel de POS' },
				{ id: 2, name: 'Cobrança por transação' },
				{ id: 3, name: 'Pacote financeiro' },
				{ id: 4, name: 'Taxa administrativa' },
				{ id: 5, name: 'Taxa Transferência P2P' },
			],
		};

		const { status, tipoCobranca } = result;

		return {
			status,
			tipoCobranca,
		};
	};

	const getParams = () => {
		const dataFiltros = {
			status: filtros.status.value,
			tipo: filtros.tipoCobranca,
			mesReferente: `${filtros.mes}/${filtros.ano}`,
			estabelecimentoId: filtros.estabelecimento,
		};

		if (filtros.created.start) {
			dataFiltros.dataInicial = moment(filtros.created.start).format('YYYY-MM-DD');
			dataFiltros.dataFinal = moment(filtros.created.end).format('YYYY-MM-DD');
		}

		return {
			page,
			limit: rowsPerPage,
			...dataFiltros,
			estabelecimentoSelecionadoId,
		};
	};

	const getData = useCallback(async () => {
		setLoading(true);

		// const params = getParams();

		const response = await axios.get(`${config.get('apiUrl')}cobranca_recorrente/pagamentos`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
			params: getParams(),
		});

		if (response.data.success && response.data.pagamentos) {
			setLoading(false);
			setPagamentos(response.data.pagamentos);
			setTotalRows(response.data.quantidade);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, rowsPerPage, token, filtros, estabelecimentoSelecionadoId]);

	const exportXls = async () => {
		const params = getParams();

		const taxas = await axios.get(
			`${config.get('apiUrl')}export/taxas-recorrentes
			`,
			{
				headers: { Authorization: `Bearer ${token}` },
				params,
				responseType: 'blob',
			}
		);

		if (taxas.status === 200) {
			const { 'content-type': contentType } = taxas.headers;
			const filename = 'taxas.xlsx';

			FileDownload(taxas.data, filename, contentType);
		}
	};

	const onChangePage = useCallback(newPage => {
		setPage(newPage);
	}, []);

	const onChangeRowsPerPage = useCallback(rowsPerPage => {
		setRowsPerPage(rowsPerPage);
		setPage(1);
	}, []);

	const filtrosChange = useCallback(data => {
		console.log(data);
		setFiltros(filtros => ({ ...filtros, ...data }));
	}, []);

	const selectedChange = useCallback((id, data) => {
		const { value } = data.target;

		setFiltros(filtros => ({ ...filtros, [id]: { ...filtros[id], value } }));
	}, []);

	const handleClearFilter = useCallback(() => {
		setFiltros(filtros => ({
			...filtros,
			status: [],
			estabelecimento: '',
			tipoCobranca: [],
			created: {
				start: '',
				end: '',
				label: 'Data da Cobrança',
			},
			mes: format(new Date(), 'MM'),
			ano: format(new Date(), 'YYYY'),
		}));
	}, []);

	const dadosFormatados = useMemo(() => {
		return pagamentos.map(({ success, created_at }) => ({
			status: success === 1 ? 'Aprovado' : 'Reprovado',
			dataCriacao: moment(created_at).format('DD/MM/YYYY'),
		}));
	}, [pagamentos]);

	return (
		<React.Fragment>
			<Grid item xs={12} sm={12} style={{ margin: '8px 0' }}>
				<ZTitle title="Taxas" />

				<Paper
					style={{
						marginBottom: 10,
						padding: '5px 10px',
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<Grid container item alignItems="center">
						<Grid item xs={12} md={11}>
							<ZFiltros
								onChange={filtrosChange}
								onSelectChange={selectedChange}
								handleClearFilter={() => handleClearFilter()}
								modal={true}
								mes={{ defaultValue: filtros.mes }}
								ano={{ defaultValue: filtros.ano }}
								selectField={[
									{
										key: 'status',
										title: 'Status',
										defaultValue: filtros.status,
										multiple: true,
										modal: true,
										options: getOptions().status,
										md: 12,
									},
									{
										key: 'tipoCobranca',
										title: 'Tipo da Cobrança',
										defaultValue: filtros.tipoCobranca,
										multiple: true,
										modal: true,
										md: 12,
										options: getOptions().tipoCobranca,
									},
								]}
								created={{ ...filtros.created }}
								estabelecimento={{ defaultValue: filtros.estabelecimento }}
							/>
						</Grid>
						<Grid item align="center" xs={6} md={1}>
							<Tooltip title="Exportar">
								<Button className={clsx(classes.buttonExport, classes.iconButton)} onClick={exportXls}>
									<FaDownload className={classes.exportDownload} />
								</Button>
							</Tooltip>
						</Grid>
					</Grid>
				</Paper>

				{loading ? (
					<ZLoader height={200} message="Carregando Pagamentos" />
				) : (
					<>
						{pagamentos.length === 0 && (
							<Grid container justifyContent="center" alignItems="center">
								<Typography variant="h5">Taxa não encontrada</Typography>
							</Grid>
						)}

						{pagamentos.map((pagamento, index) => (
							<Paper
								key={pagamento.id}
								className={classes.tableRow}
								onClick={() => {
									if (pagamento.transferencia_id)
										history.push(`/transferencias/detalhes/${pagamento.transferencia_id}`);
								}}
							>
								<Grid container style={{ padding: 20, marginTop: 24 }}>
									<Grid item xs={4} sm={1} className={classes.truncate}>
										<p className={classes.rowTitle} style={{ margin: 0 }}>
											ID
											<br />
											<span className={classes.rowText}>{pagamento.id}</span>
										</p>
									</Grid>
									<Grid item xs={6} sm={2} className={classes.truncate}>
										<p className={classes.rowTitle} style={{ margin: 0 }}>
											Tipo da Cobrança
											<br />
											<span className={classes.rowText}>
												{pagamento.cobranca_recorrente.tipo_cobranca_recorrente.name}
											</span>
										</p>
									</Grid>
									<Grid item xs={8} sm={2}>
										<p className={classes.rowTitle}>
											Estabelecimento <br />{' '}
											<span className={classes.rowText}>
												{pagamento.cobranca_recorrente.EstabelecimentoCobrado.nome_fantasia}
											</span>
										</p>
									</Grid>
									<Grid item xs={4} sm={3}>
										<p className={classes.rowTitle}>
											Status <br />{' '}
											<span className={classes.rowText}>
												{/* {dadosFormatados[index].status} */}
												{pagamento.split.valor_pago === pagamento.split.valor_maximo &&
													'Pagamento concluído'}
												{pagamento.split.valor_pago !== pagamento.split.valor_maximo &&
													'Aguardando Pagamento'}
											</span>
										</p>
									</Grid>
									<Grid item xs={6} sm={1}>
										<p className={classes.rowTitle}>
											Referente <br />
											<span className={classes.rowText}>{pagamento.referente}</span>
										</p>
									</Grid>
									<Grid item xs={6} sm={1} className={classes.hideOnXs}>
										<p className={classes.rowTitle}>
											Data
											<br />
											<span className={classes.rowText}>
												{dadosFormatados[index].dataCriacao}
											</span>
										</p>
									</Grid>
									<Grid item xs={12} sm={2} className={classes.truncate}>
										<Tooltip title={`${pagamento.observacao}`}>
											<p className={classes.rowTitle}>
												Valor Pago / Valor Total <br />
												<span className={classes.rowText}>
													{Formatter.Real(pagamento.split.valor_pago)} /{' '}
													{Formatter.Real(pagamento.split.valor_maximo)}
												</span>
											</p>
										</Tooltip>
									</Grid>
									{/* <Grid item xs={12} sm={2} className={classes.truncate}>
										<Tooltip title={`${pagamento.observacao}`}>
											<p className={classes.rowTitle}>
												Observação: <br />
												<span className={classes.rowText}>{pagamento.observacao}</span>
											</p>
										</Tooltip>
									</Grid> */}
								</Grid>
							</Paper>
						))}
					</>
				)}

				<ZPaginationDiv
					count={totalRows}
					rowsPerPage={rowsPerPage}
					page={page - 1}
					onChangePage={onChangePage}
					onChangeRowsPerPage={onChangeRowsPerPage}
					rowsPerPageOptions={[15, 30, 45, 60, 75, 90, 105]}
				/>
			</Grid>
		</React.Fragment>
	);
};
