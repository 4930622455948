/* eslint-disable react-hooks/exhaustive-deps */
// import { hot } from 'react-hot-loader/root';
import React, { useEffect } from 'react';
import Main from './components/Main';
import { RecoilRoot } from 'recoil';

import configuration from 'react-global-configuration';
import config from './Config';

import { Provider } from 'react-redux';
import Store from './store';
import { BrowserRouter } from 'react-router-dom';
import './App.css';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

configuration.set(config, { freeze: false });

if (!('toJSON' in Error.prototype)) {
	// eslint-disable-next-line no-extend-native
	Object.defineProperty(Error.prototype, 'toJSON', {
		value() {
			const alt = {};

			Object.getOwnPropertyNames(this).forEach(function aux(key) {
				alt[key] = this[key];
			}, this);

			return alt;
		},
		configurable: true,
		writable: true,
	});
}

function App() {
	useEffect(() => {
		const version = localStorage.getItem('z4money-version');

		if (version !== '1.0.0') {
			localStorage.removeItem('z4money-filters');
			localStorage.setItem('z4money-version', '1.0.0');
		}
	}, []);

	return (
		<BrowserRouter basename={configuration.get('baseName')}>
			<div className="App">
				<RecoilRoot>
					<Provider store={Store}>
						<Main />
					</Provider>
				</RecoilRoot>
			</div>
		</BrowserRouter>
	);
}

export default App;
