import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import { makeStyles } from '@material-ui/core/styles';
import config from 'react-global-configuration';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { addMessage } from '../../store/alert';
import Parser from 'html-react-parser';

const useStyles = makeStyles(theme => ({
	dialogOK: {
		fontSize: 16,
		color: 'white',
		display: 'flex',
		marginLeft: 'auto',
		backgroundColor: '#1b901b',
		'&:hover': {
			backgroundColor: '#1b901bdd',
		},
	},
}));

export default props => {
	const { termos } = props;
	const classes = useStyles();
	const dispatch = useDispatch();
	const token = useSelector(store => store.auth.token);

	const aceitarTermo = async () => {
		const res = await axios.post(
			`${config.get('apiUrl')}estabelecimentos/aceitar-termos`,
			{},
			{
				headers: { Authorization: `Bearer ${token}` },
			}
		);

		if (res.data.success) {
			if (props.onAceitar) {
				props.onAceitar();
			}
		} else {
			dispatch(addMessage({ type: 'error', message: 'Erro ao aceitar os termos.' }));
		}
	};
	// const scrollRef = useRef()
	// if(scrollRef) {
	// 	//const pageScroll = document.getElementById("conteudo")
	// 	// const pageScroll = scrollRef.current.scrollToIndexes()
	// 	// console.log(pageScroll)
	// }

	return (
		<>
			<Dialog
				fullWidth
				maxWidth="md"
				open={props.open}
				onClose={() => { }}
				disableBackdropClick
				disableEscapeKeyDown
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{'Termos e Condições'}</DialogTitle>
				<DialogContent>
					<div>
						{Parser(termos)}
						<Button
							className={classes.dialogOK}
							onClick={aceitarTermo}
							color="primary"
							// variant="contained"
							autoFocus
						>
							Aceito
						</Button>
					</div>
				</DialogContent>
				<DialogActions>
				</DialogActions>
			</Dialog>
		</>
	);
};
