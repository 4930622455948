/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import Zoom from '@material-ui/core/Zoom';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Isset } from '../../utils';
import axios from 'axios';
import config from 'react-global-configuration';

import ContaBancaria from './ContaBancaria';
import PlanosDeVendas from '../PlanosDeVendas';
import Taxas from './Taxas';
import Split from './ConfigurarSplit';
import POS from '../POS';
import PlanosDeTaxas from '../PlanosDeTaxas/';
import Parametros from './Parametros';

import { setSelectedECId, resetSelectedEC } from '../../store/system';
import { addMessage } from '../../store/alert';
import Dados from './Dados';
import { useRouteMatch } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
	parametros: {},
	header: {
		fontWeight: 700,
		fontSize: 20,
		margin: 8,
	},
	configBox: {
		position: 'relative',
		padding: 10,
	},
	configHeader: {
		fontWeight: 700,
		fontSize: 16,
		margin: 4,
	},
	configUpdateBtn: {
		textAlign: 'center',
		textTransform: 'uppercase',
		paddingBottom: 15,
		fontWeight: 700,
		fontSize: 12,
		cursor: 'pointer',
		color: theme.palette.primary.main,
	},
	configOption: {
		textAlign: 'center',
		margin: 10,
	},
	icon: {
		color: theme.palette.primary.main,
	},
	updateCheck: {
		position: 'absolute',
		top: 10,
		right: 10,
	},
	tabPanels: {
		paddingTop: 10,
	},
}));

export default props => {
	const classes = useStyles();

	const dispatch = useDispatch();
	// const { match } = useReactRouter();
	const match = useRouteMatch();
	const usuario = useSelector(state => state.auth.usuario);
	const selectedEC = useSelector(state => state.system.selectedEC);
	const operarMarketplace = useSelector(store =>
		Isset(() => store.system.params['4'].valor) ? store.system.params['4'].valor : null
	);
	const token = useSelector(store => store.auth.token);

	const isBackoffice = [1, 7, 10, 11, 13, 15].includes(usuario.grupo_id);
	//const isOnePay = usuario.marketplace.id === 8;
	const notRepresentante = ![5].includes(usuario.grupo_id);

	const [tabSelected, setTabSelected] = useState(1);
	const [canViewConfigs, setCanViewConfigs] = useState(true);

	const estabelecimentoId = Isset(() => match.params.estabelecimentoId) ? match.params.estabelecimentoId : null;
	const canSplit = usuario.can_split;

	const canView = {
		dados: isBackoffice,
		parametros: isBackoffice || (usuario.grupo_id === 2 && operarMarketplace),
		contaBancaria: notRepresentante,
		planoVenda: isBackoffice,
		pos: true,
		tabelaMarkup: notRepresentante,
		taxas: notRepresentante,
		split: notRepresentante && canSplit,
	};

	if (!selectedEC.id) {
		dispatch(setSelectedECId(estabelecimentoId));
	}

	useEffect(() => {
		return () => {
			dispatch(resetSelectedEC());
		};
	}, []);

	useEffect(() => {
		if (usuario.grupo_id === 5) {
			setTabSelected(3);
		} else if (isBackoffice) {
			setTabSelected(7);
		}
	}, [usuario.grupo_id]);

	const getEstabelecimento = async () => {
		const res = await axios.get(`${config.get('apiUrl')}estabelecimentos/${estabelecimentoId}?mcc=true`, {
			headers: { Authorization: `Bearer ${token}` },
		});

		if (!res.data.success) {
			setCanViewConfigs(false);
			dispatch(addMessage({ type: 'error', message: 'Você não tem permissão para acessar estas configurações' }));
		}
	};

	useEffect(() => {
		getEstabelecimento();
	}, [estabelecimentoId, token]);

	const handleChange = (e, value) => setTabSelected(value);

	if (!canViewConfigs) {
		return (
			<div>
				<div className={classes.header}>Nenhuma configuração encontrada</div>
			</div>
		);
	}

	const PerfilDeRisco = usuario.grupo_id === 11 || usuario.grupo_id === 15;
	if (PerfilDeRisco) {
		delete canView.pos;
	}

	const PerfilDeAtendimento = usuario.grupo_id === 12;
	if (PerfilDeAtendimento) {
		delete canView.pos;
	}

	return (
		<div className={classes.parametros}>
			<div className={classes.header}>
				Configurações - {selectedEC.nome}
				{selectedEC.nomeComprovante ? <small> ({selectedEC.nomeComprovante})</small> : ''}
			</div>

			<Tabs value={tabSelected} onChange={handleChange} centered>
				{canView.dados && <Tab label="Dados" value={7} />}
				{canView.parametros && <Tab label="Parâmetros" value={0} />}
				{canView.contaBancaria && <Tab label="Conta Bancária" value={1} />}
				{canView.planoVenda && <Tab label="Plano de Venda" value={2} />}
				{canView.pos && <Tab label="POS" value={3} />}
				{canView.tabelaMarkup && <Tab label="Tabelas de Markup" value={4} />}
				{canView.taxas && <Tab label="Taxas" value={5} />}
				{canView.split && <Tab label="Split" value={6} />}
			</Tabs>
			<div className={classes.tabPanels}>
				{tabSelected === 7 && <Dados estabelecimentoId={estabelecimentoId} />}

				{tabSelected === 0 && <Parametros estabelecimentoId={estabelecimentoId} />}

				{tabSelected === 1 && (
					<Zoom in>
						<ContaBancaria estabelecimentoId={estabelecimentoId} />
					</Zoom>
				)}

				{tabSelected === 2 && <PlanosDeVendas estabelecimentoId={estabelecimentoId} />}

				{tabSelected === 3 && <POS estabelecimentoId={estabelecimentoId} />}

				{tabSelected === 4 && <PlanosDeTaxas estabelecimentoId={estabelecimentoId} />}

				{tabSelected === 5 && (
					<>
						<Taxas estabelecimentoId={estabelecimentoId} />
						<Taxas estabelecimentoId={estabelecimentoId} tipoVendaId={2} />
					</>
				)}

				{tabSelected === 6 && <Split estabelecimentoId={estabelecimentoId} />}
			</div>
		</div>
	);
};
