/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import ZLoader from '../system/ZLoader';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { DialogTitle } from '@material-ui/core';
import axios from 'axios';
import config from 'react-global-configuration';
import { SelectEstabelecimento } from '../Estabelecimentos/SelectEstabelecimento';
import { addMessage } from '../../store/alert';

const useStyles = makeStyles(theme => ({
	field: {
		padding: 5,
		textAlign: 'center',
	},
	label: {
		fontSize: 11,
		textTransform: 'uppercase',
		fontWeight: 700,
	},
	fullWidth: {
		width: '100%',
	},
	content: {
		width: 400,
	},
}));

export default props => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const token = useSelector(state => state.auth.token);
	const [open, setOpen] = useState(props.open || false);
	const [sending, setSending] = useState(false);
	const [tokenPOS, setTokenPOS] = useState('');
	const [tokenPosError, setTokenPosError] = useState(false);
	const [estabelecimento, setEstabelecimento] = useState('');

	const handleClose = () => {
		setOpen(false);

		if (props.onClose) {
			props.onClose();
		}
	};

	const handleHabilitarPOS = async () => {
		if (!tokenPOS) {
			setTokenPosError(true);
			return;
		}

		setTokenPosError(false);

		setSending(true);

		const res = await axios.post(
			`${config.get('apiUrl')}estabelecimentos/${estabelecimento.id}/habilitar_pos/${tokenPOS}`,
			{},
			{
				headers: { Authorization: `Bearer ${token}` },
			}
		);

		if (res.data.success) {
			setTokenPOS('');
			dispatch(addMessage({ type: 'success', message: 'Operação realizada com sucesso' }));
		} else {
			setTokenPosError(true);
			dispatch(addMessage({ type: 'error', message: 'Verifique o número da POS' }));
		}

		setSending(false);
	};

	return (
		<div>
			<Dialog open={open} onClose={handleClose}>
				<DialogTitle id="simple-dialog-title">Habilitar POS</DialogTitle>

				<DialogContent className={classes.content}>
					<Grid item xs={12} style={{ marginTop: 4 }}>
						<SelectEstabelecimento setEstabelecimento={setEstabelecimento} />
					</Grid>
					<Grid item xs={12} style={{ marginTop: 16 }}>
						<TextField
							error={tokenPosError}
							className={classes.fullWidth}
							type="text"
							label="Token POS"
							value={tokenPOS}
							onChange={({ target: { value } }) => setTokenPOS(value)}
						/>
					</Grid>
				</DialogContent>
				<DialogActions>
					{sending ? (
						<ZLoader size={30} height={60} />
					) : (
						<React.Fragment>
							<Button onClick={handleClose} color="primary">
								Cancelar
							</Button>
							<Button onClick={handleHabilitarPOS} color="primary" autoFocus>
								Salvar
							</Button>
						</React.Fragment>
					)}
				</DialogActions>
			</Dialog>
		</div>
	);
};
