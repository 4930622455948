import React, { useEffect, useState, useCallback } from 'react';
import { Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import config from 'react-global-configuration';
import { useSelector, useDispatch } from 'react-redux';
import ZLoader from '../system/ZLoader';
import ZText from '../system/ZText';
import { Mask, dbDateToDate } from '../../utils';
import clsx from 'clsx';
import { addMessage } from '../../store/alert';
import FileDownload from 'js-file-download';

const useStyles = makeStyles(theme => ({
	paper: {
		backgroundColor: '#f1f1f1',
		padding: 10,
	},
	sectionHeader: {
		textAlign: 'center',
		fontVariant: 'small-caps',
		fontSize: 16,
		fontWeight: 'bold',
	},
	sectionHeader2: {
		textAlign: 'center',
		fontVariant: 'small-caps',
		fontSize: 14,
		fontWeight: 'bold',
	},
	field: {
		padding: 5,
		[theme.breakpoints.down('sm')]: {
			textAlign: 'center',
		},
	},
	label: {
		fontSize: 10,
		textTransform: 'uppercase',
		fontWeight: 700,
	},
	previewDocumento: {
		width: 'calc(100% - 20px)',
		height: 200,
		fontVariant: 'small-caps',
		fontWeight: 'bold',
		cursor: 'pointer',
		borderRadius: 10,
		border: 'solid 1px #efefef',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		'&:hover': {
			border: 'solid 1px #abc',
		},
	},
	file: {
		width: 'calc(100% - 20px)',
		height: 40,
		fontVariant: 'small-caps',
		fontWeight: 'bold',
		cursor: 'pointer',
		borderRadius: 10,
		border: 'solid 1px #efefef',
		'&:hover': {
			border: 'solid 1px #abc',
		},
	},
	options: {
		display: 'flex',
		justifyContent: 'center',
	},
	optionBtn: {
		margin: 4,
		textAlign: 'center',
		textTransform: 'uppercase',
		fontWeight: 700,
		fontSize: 12,
		cursor: 'pointer',
		color: theme.palette.primary.main,
	},
	editing: {
		backgroundColor: '#fff',
	},
}));

export default props => {
	const dispatch = useDispatch();
	const [estabelecimentoId] = useState(
		props.estabelecimentoId !== undefined ? props.estabelecimentoId : +props.match.params.estabelecimentoId
	);
	const classes = useStyles();
	const token = useSelector(store => store.auth.token);
	const usuario = useSelector(store => store.auth.usuario);

	const representante = usuario.grupo_id === 5;

	const [estabelecimento, setEstabelecimento] = useState(null);
	const [loading, setLoading] = useState(true);
	const [isPJ, setIsPJ] = useState(false);
	const [documentos, setDocumentos] = useState({
		identificacao: [],
		identificacaoProprietario: [],
		atividade: [],
		endereco: [],
	});

	const [editData, setEditData] = useState({
		telefone: '',
		identificacao_fatura: '',
		endereco: {
			bairro: '',
			cep: '',
			cidade: '',
			complemento: '',
			id: null,
			logradouro: '',
			numero: '',
			uf: '',
		},
	});
	const [editingEC, setEditingEC] = useState(false);
	const [editingAddress, setEditingAddress] = useState(false);
	const [editingProprietario, setEditingProprietario] = useState(false);
	// const [showDownloadMessage, setShowDownloadMessage] = useState(false);

	const sendEditarEC = async data => {
		try {
			const res = await axios.post(`${config.get('apiUrl')}estabelecimentos/${estabelecimentoId}/editar`, data, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			if (res.data.success) {
				dispatch(addMessage({ type: 'success', message: 'Dados atualizados com sucesso.' }));
				getEstabelecimento();
				setEditingEC(false);
				setEditingAddress(false);
				setEditingProprietario(false);
			} else {
				dispatch(addMessage({ type: 'error', message: res.data.error }));
			}
		} catch (error) {
			dispatch(addMessage({ type: 'error', message: error.message }));
		}
	};

	const atualizarEC = async () => {
		const errors = [];
		if (!editData.telefone || editData.telefone.length < 11) {
			errors.push('Preencha o Telefone corretamente.');
		}
		if (errors.length > 0) {
			dispatch(addMessage({ type: 'error', message: errors.join('\n') }));
			return false;
		}

		await sendEditarEC({
			telefone: editData.telefone,
			identificacaoFatura: editData.identificacao_fatura,
		});
	};

	const atualizarAddress = async () => {
		const errors = [];
		if (!editData.endereco.cep) {
			errors.push('Preencha o Cep.');
		}
		if (!editData.endereco.logradouro) {
			errors.push('Preencha o Logradouro.');
		}
		if (!editData.endereco.numero) {
			errors.push('Preencha o Número.');
		}
		if (!editData.endereco.cidade) {
			errors.push('Preencha a Cidade.');
		}
		if (!editData.endereco.uf) {
			errors.push('Preencha o Estado.');
		}
		if (errors.length > 0) {
			dispatch(addMessage({ type: 'error', message: errors.join('\n') }));
			return false;
		}

		await sendEditarEC({
			endereco: editData.endereco,
		});
	};

	const atualizarProprietario = async () => {
		await sendEditarEC({
			proprietario: editData.proprietario,
		});
	};

	const getEstabelecimento = useCallback(async () => {
		setLoading(true);
		const res = await axios.get(`${config.get('apiUrl')}estabelecimentos/${estabelecimentoId}?mcc=true`, {
			headers: { Authorization: `Bearer ${token}` },
		});

		if (res.data.success) {
			const { estabelecimento } = res.data;

			estabelecimento.documento = estabelecimento.estabelecimentos_documentos.find(ed =>
				[2, 3].includes(ed.tipo_documento_id)
			).documento;

			estabelecimento.email = estabelecimento.estabelecimentos_contatos.find(
				ed => ed.tipo_contato_id === 3
			).contato;

			if (estabelecimento.estabelecimentos_contatos.length) {
				estabelecimento.telefone = estabelecimento.estabelecimentos_contatos.find(ed =>
					[1, 2].includes(ed.tipo_contato_id)
				);

				if (estabelecimento.telefone) {
					estabelecimento.telefone = estabelecimento.telefone.contato;
				}
			}

			setEstabelecimento(estabelecimento);
			setEditData({
				telefone: estabelecimento.telefone,
				identificacao_fatura: estabelecimento.identificacao_fatura,
				endereco: {
					...estabelecimento.endereco,
				},
				proprietario: estabelecimento.estabelecimento_proprietario,
			});
			setIsPJ(estabelecimento.tipo_estabelecimento.id === 2);

			const docs = {
				identificacao: estabelecimento.estabelecimentos_documentos.filter(ed =>
					[5].includes(ed.tipo_documento_id)
				),
				identificacaoProprietario: estabelecimento.estabelecimentos_documentos.filter(ed =>
					[8].includes(ed.tipo_documento_id)
				),
				atividade: estabelecimento.estabelecimentos_documentos.filter(ed => [6].includes(ed.tipo_documento_id)),
				endereco: estabelecimento.estabelecimentos_documentos.filter(ed => [7].includes(ed.tipo_documento_id)),
			};
			setDocumentos(docs);
		}
		setLoading(false);
	}, [estabelecimentoId, token]);

	useEffect(() => {
		getEstabelecimento();
	}, [estabelecimentoId, getEstabelecimento, token]);

	const handleCepChange = async value => {
		value = Mask.OnlyNumber(value);

		if (value.length === 8) {
			var res = await axios.get(`${config.get('apiUrl')}buscar_cep/${value}`);

			if (!res.data.success || res.data.endereco.erro) {
				setEditData({
					...editData,
					endereco: {
						...editData.endereco,
						cep: value,
						logradouro: '',
						cidade: '',
						uf: '',
						complemento: '',
						bairro: '',
					},
				});
				// setErrors([ 'CEP não encontrado' ]);
				dispatch(addMessage({ type: 'error', message: 'CEP não encontrado' }));
				return false;
			}

			setEditData({
				...editData,
				endereco: {
					...editData.endereco,
					cep: value,
					logradouro: res.data.endereco.logradouro,
					cidade: res.data.endereco.localidade,
					uf: res.data.endereco.uf,
					complemento: '',
					bairro: res.data.endereco.bairro,
				},
			});
		} else {
			setEditData({
				...editData,
				endereco: {
					...editData.endereco,
					cep: value,
					logradouro: '',
					cidade: '',
					uf: '',
					complemento: '',
				},
			});
		}
	};

	const downloadFile = async (arquivoId, arquivoName) => {
		// setShowDownloadMessage(true);
		const file = await axios.get(
			`${config.get('apiUrl')}estabelecimentos/${estabelecimentoId}/documento/${arquivoId}`,
			{
				headers: { Authorization: `Bearer ${token}` },
				responseType: 'blob',
			}
		);

		if (file.status === 200) {
			const { 'content-type': contentType } = file.headers;
			let filename = arquivoName;
			// if (contentDisposition.indexOf('filename="') !== -1) {
			// 	filename = contentDisposition.slice(
			// 		contentDisposition.indexOf('filename="') + 10,
			// 		contentDisposition.length - 1
			// 	);
			// }

			FileDownload(file.data, filename, contentType);
		} else {
			dispatch(addMessage({ type: 'error', message: 'Erro ao exportar estabelecimentos.' }));
		}
		// setShowDownloadMessage(false);
	};

	if (!estabelecimento) {
		return <ZLoader height={200} message="Carregando" />;
	}
	return (
		<div className={classes.estabelecimento}>
			{loading ? (
				<ZLoader height={200} />
			) : (
				<Grid container spacing={2}>
					<Grid item xs={12} sm={4}>
						<Paper className={clsx(classes.paper, { [classes.editing]: editingEC })}>
							<div className={classes.sectionHeader}>Estabelecimento</div>
							<div className={classes.field}>
								<div className={classes.label}>{isPJ ? 'Nome Fantasia' : 'Nome'}</div>
								<div>{estabelecimento.nome_fantasia}</div>
							</div>
							{isPJ ? (
								<>
									<div className={classes.field}>
										<div className={classes.label}>Razão Social</div>
										<div>{estabelecimento.razao_social}</div>
									</div>
									<div className={classes.field}>
										<div className={classes.label}>CNPJ</div>
										<div>{Mask.Cnpj(estabelecimento.documento)}</div>
									</div>
								</>
							) : (
								<>
									<div className={classes.field}>
										<div className={classes.label}>CPF</div>
										<div>{Mask.Cpf(estabelecimento.documento)}</div>
									</div>
									<div className={classes.field}>
										<div className={classes.label}>Data de Nascimento</div>
										<div>
											{estabelecimento.data_nascimento
												? dbDateToDate(estabelecimento.data_nascimento)
												: '---'}
										</div>
									</div>
								</>
							)}
							<div className={classes.field}>
								<div className={classes.label}>E-mail</div>
								<div>{estabelecimento.email}</div>
							</div>
							<div className={classes.field}>
								<div className={classes.label}>Telefone</div>
								{!editingEC ? (
									<div>
										{estabelecimento.telefone ?
											estabelecimento.telefone.length > 10 ? Mask.Celular(estabelecimento.telefone) : Mask.Telefone(estabelecimento.telefone)
										: '---'}
									</div>
								) : (
									<ZText
										value={editData.telefone}
										onChange={value => {
											setEditData({ ...editData, telefone: Mask.OnlyNumber(value) });
										}}
										mask={'(99) 99999-9999'}
									/>
								)}
							</div>
							<div className={classes.field}>
								<div className={classes.label}>Categoria do Negócio</div>
								<div>{estabelecimento.categoria ? estabelecimento.categoria : '---'}</div>
							</div>
							<div className={classes.field}>
								<div className={classes.label}>Data de Criação</div>
								<div>{dbDateToDate(estabelecimento.created)}</div>
							</div>
							<div className={classes.field}>
								<div className={classes.label}>Nome no Comprovante</div>
								{!editingEC ? (
									<div>
										{estabelecimento.identificacao_fatura
											? estabelecimento.identificacao_fatura
											: '---'}
									</div>
								) : (
									<ZText
										value={editData.identificacao_fatura}
										onChange={value => {
											setEditData({
												...editData,
												identificacao_fatura: value,
											});
										}}
									/>
								)}
							</div>
							<div className={classes.options}>
								{editingEC ? (
									<>
										<div className={classes.optionBtn} onClick={() => setEditingEC(false)}>
											Cancelar
										</div>
										<div className={classes.optionBtn} onClick={atualizarEC}>
											Atualizar
										</div>
									</>
								) : (
									!(usuario.estabelecimento.marketplace.id === 129 && usuario.grupo_id !== 1) && (
										<div className={classes.optionBtn} onClick={() => setEditingEC(true)}>
											Editar
										</div>
									)
								)}
							</div>
						</Paper>
					</Grid>

					<Grid item xs={12} sm={4}>
						<Paper className={clsx(classes.paper, { [classes.editing]: editingAddress })}>
							<div className={classes.sectionHeader}>Endereço</div>
							<div className={classes.field}>
								<div className={classes.label}>CEP</div>
								{!editingAddress ? (
									<div>{Mask.Cep(editData.endereco.cep)}</div>
								) : (
									<ZText value={editData.endereco.cep} mask="99999-999" onChange={handleCepChange} />
								)}
							</div>
							<div className={classes.field}>
								<div className={classes.label}>Logradouro</div>
								{!editingAddress ? (
									<div>{editData.endereco.logradouro}</div>
								) : (
									<ZText
										value={editData.endereco.logradouro}
										onChange={value =>
											setEditData({
												...editData,
												endereco: {
													...editData.endereco,
													logradouro: value,
												},
											})
										}
									/>
								)}
							</div>
							<div className={classes.field}>
								<div className={classes.label}>Número</div>
								{!editingAddress ? (
									<div>{editData.endereco.numero}</div>
								) : (
									<ZText
										value={editData.endereco.numero}
										onChange={value =>
											setEditData({
												...editData,
												endereco: {
													...editData.endereco,
													numero: value,
												},
											})
										}
									/>
								)}
							</div>
							<div className={classes.field}>
								<div className={classes.label}>Complemento</div>
								{!editingAddress ? (
									<div>
										{estabelecimento.endereco.complemento
											? estabelecimento.endereco.complemento
											: '---'}
									</div>
								) : (
									<ZText
										value={editData.endereco.complemento}
										onChange={value =>
											setEditData({
												...editData,
												endereco: {
													...editData.endereco,
													complemento: value,
												},
											})
										}
									/>
								)}
							</div>
							<div className={classes.field}>
								<div className={classes.label}>Bairro</div>
								{!editingAddress ? (
									<div>{editData.endereco.bairro ? editData.endereco.bairro : '---'}</div>
								) : (
									<ZText
										value={editData.endereco.bairro ? editData.endereco.bairro : '---'}
										onChange={value =>
											setEditData({
												...editData,
												endereco: {
													...editData.endereco,
													bairro: value,
												},
											})
										}
									/>
								)}
							</div>
							<div className={classes.field}>
								<div className={classes.label}>Cidade</div>
								{!editingAddress ? (
									<div>{editData.endereco.cidade}</div>
								) : (
									<ZText
										value={editData.endereco.cidade}
										onChange={value =>
											setEditData({
												...editData,
												endereco: {
													...editData.endereco,
													cidade: value,
												},
											})
										}
									/>
								)}
							</div>
							<div className={classes.field}>
								<div className={classes.label}>Estado</div>
								{!editingAddress ? (
									<div>{editData.endereco.uf}</div>
								) : (
									<ZText
										value={editData.endereco.uf}
										onChange={value =>
											setEditData({
												...editData,
												endereco: {
													...editData.endereco,
													uf: value,
												},
											})
										}
									/>
								)}
							</div>
							<div className={classes.options}>
								{editingAddress ? (
									<>
										<div className={classes.optionBtn} onClick={() => setEditingAddress(false)}>
											Cancelar
										</div>
										<div className={classes.optionBtn} onClick={atualizarAddress}>
											Atualizar
										</div>
									</>
								) : (
									!(usuario.estabelecimento.marketplace.id === 129 && usuario.grupo_id !== 1) && (
										<div className={classes.optionBtn} onClick={() => setEditingAddress(true)}>
											Editar
										</div>
									)
								)}
							</div>
						</Paper>
					</Grid>

					<Grid item xs={12} sm={4}>
						<Grid container>
							{isPJ && estabelecimento.estabelecimento_proprietario && (
								<Grid item xs={12} style={{ marginBottom: 12 }}>
									<Paper className={clsx(classes.paper, { [classes.editing]: editingProprietario })}>
										<div className={classes.sectionHeader}>Proprietário</div>
										<div className={classes.field}>
											<div className={classes.label}>CPF</div>
											{!editingProprietario ? (
												<div>
													{estabelecimento.estabelecimento_proprietario.cpf
														? Mask.Cpf(estabelecimento.estabelecimento_proprietario.cpf)
														: '---'}
												</div>
											) : (
												<ZText
													value={editData.proprietario.cpf}
													mask={'999.999.999-99'}
													onChange={value =>
														setEditData({
															...editData,
															proprietario: {
																...editData.proprietario,
																cpf: Mask.OnlyNumber(value),
															},
														})
													}
												/>
											)}
										</div>
										<div className={classes.field}>
											<div className={classes.label}>Nome</div>
											{!editingProprietario ? (
												<div>{estabelecimento.estabelecimento_proprietario.nome}</div>
											) : (
												<ZText
													value={editData.proprietario.nome}
													onChange={value =>
														setEditData({
															...editData,
															proprietario: {
																...editData.proprietario,
																nome: value,
															},
														})
													}
												/>
											)}
										</div>
										<div className={classes.field}>
											<div className={classes.label}>Sobrenome</div>
											{!editingProprietario ? (
												<div>{estabelecimento.estabelecimento_proprietario.sobrenome}</div>
											) : (
												<ZText
													value={editData.proprietario.sobrenome}
													onChange={value =>
														setEditData({
															...editData,
															proprietario: {
																...editData.proprietario,
																sobrenome: value,
															},
														})
													}
												/>
											)}
										</div>
										<div className={classes.field}>
											<div className={classes.label}>Data de Nascimento</div>
											{!editingProprietario ? (
												<div>
													{estabelecimento.estabelecimento_proprietario.data_nascimento
														? dbDateToDate(
																estabelecimento.estabelecimento_proprietario
																	.data_nascimento
														  )
														: '---'}
												</div>
											) : (
												<ZText
													value={dbDateToDate(editData.proprietario.data_nascimento)}
													mask="99/99/9999"
													onChange={value =>
														setEditData({
															...editData,
															proprietario: {
																...editData.proprietario,
																data_nascimento: value,
															},
														})
													}
												/>
											)}
										</div>
										<div className={classes.field}>
											<div className={classes.label}>Telefone</div>
											{!editingProprietario ? (
												<div>
													{estabelecimento.estabelecimento_proprietario.celular
														? estabelecimento.estabelecimento_proprietario.celular
														: '---'}
												</div>
											) : (
												<ZText
													value={editData.proprietario.celular}
													mask="(99) 9 9999-9999"
													onChange={value =>
														setEditData({
															...editData,
															proprietario: {
																...editData.proprietario,
																celular: Mask.OnlyNumber(value),
															},
														})
													}
												/>
											)}
										</div>
										<div className={classes.field}>
											<div className={classes.label}>E-mail</div>
											{!editingProprietario ? (
												<div>
													{estabelecimento.estabelecimento_proprietario.email
														? estabelecimento.estabelecimento_proprietario.email
														: '---'}
												</div>
											) : (
												<ZText
													value={editData.proprietario.email}
													onChange={value =>
														setEditData({
															...editData,
															proprietario: {
																...editData.proprietario,
																email: value,
															},
														})
													}
												/>
											)}
										</div>
										{!representante && (
											<div className={classes.options}>
												{editingProprietario ? (
													<>
														<div
															className={classes.optionBtn}
															onClick={() => setEditingProprietario(false)}
														>
															Cancelar
														</div>
														<div
															className={classes.optionBtn}
															onClick={atualizarProprietario}
														>
															Atualizar
														</div>
													</>
												) : (
													!(
														usuario.estabelecimento.marketplace.id === 129 &&
														usuario.grupo_id !== 1
													) && (
														<div
															className={classes.optionBtn}
															onClick={() => setEditingProprietario(true)}
														>
															Editar
														</div>
													)
												)}
											</div>
										)}
									</Paper>
								</Grid>
							)}
						</Grid>
					</Grid>
					{!representante && (
						<Grid item xs={12}>
							<Paper className={classes.paper}>
								<div className={classes.sectionHeader}>Documentos</div>
								<Grid container item justifyContent="center" style={{ textAlign: 'center' }}>
									{documentos.identificacao.length > 0 && (
										<Grid item xs={12} sm={3}>
											<div className={classes.sectionHeader2}>
												{estabelecimento.tipo_estabelecimento.id === 1
													? 'RG / CPF / CNH'
													: 'CNPJ / Documento de Identificação'}
											</div>
											{documentos.identificacao.map(doc => (
												<div
													key={doc.arquivo.url}
													className={
														doc.arquivo.external_id
															? classes.file
															: classes.previewDocumento
													}
													onClick={() => {
														if (doc.arquivo.external_id) {
															downloadFile(doc.arquivo.id, doc.arquivo.nome);
														} else {
															window.open(doc.arquivo.url);
														}
													}}
													// style={{ backgroundImage: `url(${doc.arquivo.url})` }}
												>
													{doc.arquivo.nome}
												</div>
											))}
										</Grid>
									)}
									{documentos.identificacaoProprietario.length > 0 && (
										<Grid item xs={12} sm={3}>
											<div className={classes.sectionHeader2}>RG / CPF / CNH do proprietário</div>
											{documentos.identificacaoProprietario.map(doc => (
												<div
													key={doc.arquivo.url}
													className={
														doc.arquivo.external_id
															? classes.file
															: classes.previewDocumento
													}
													onClick={() => {
														if (doc.arquivo.external_id) {
															downloadFile(doc.arquivo.id, doc.arquivo.nome);
														} else {
															window.open(doc.arquivo.url);
														}
													}}
												>
													{doc.arquivo.nome}
												</div>
											))}
										</Grid>
									)}
									{documentos.atividade.length > 0 && (
										<Grid item xs={12} sm={3}>
											<div className={classes.sectionHeader2}>Comprovante de Atividade</div>
											{documentos.atividade.map(doc => (
												<div
													key={doc.arquivo.url}
													className={
														doc.arquivo.external_id
															? classes.file
															: classes.previewDocumento
													}
													onClick={() => {
														if (doc.arquivo.external_id) {
															downloadFile(doc.arquivo.id, doc.arquivo.nome);
														} else {
															window.open(doc.arquivo.url);
														}
													}}
												>
													{doc.arquivo.nome}
												</div>
											))}
										</Grid>
									)}
									{documentos.endereco.length > 0 && (
										<Grid item xs={12} sm={3}>
											<div className={classes.sectionHeader2}>Comprovante de Endereço</div>
											{documentos.endereco.map(doc => (
												<div
													key={doc.arquivo.url}
													className={
														doc.arquivo.external_id
															? classes.file
															: classes.previewDocumento
													}
													onClick={() => {
														if (doc.arquivo.external_id) {
															downloadFile(doc.arquivo.id, doc.arquivo.nome);
														} else {
															window.open(doc.arquivo.url);
														}
													}}
												>
													{doc.arquivo.nome}
												</div>
											))}
										</Grid>
									)}
								</Grid>
							</Paper>
						</Grid>
					)}
				</Grid>
			)}
		</div>
	);
};
