/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { TextField, makeStyles } from '@material-ui/core';
import InputMask from 'react-input-mask';
import Validator from 'validator';
import { Mask } from '../../utils';
import { isWhite } from '../../utils';
import { useTheme } from '@material-ui/core';

const useStyles = makeStyles(() => ({
	errorText: {
		color: '#ff0033',
		fontSize: 14,
		lineSpacing: 1.5,
	},
}));

function ZText(props) {
	const classes = useStyles();
	const [data, setData] = useState(props.value === 'Invalid date' ? '' : props.value);
	const [error, setError] = useState(false);
	const theme = useTheme();
	const inputWidth = props.width || '100%';

	const handleValidation = event => {
		if (props.required) {
			if (typeof event.target.value === 'undefined' || Validator.isEmpty(event.target.value.toString())) {
				setError('O campo não pode ser vazio.');
				return false;
			}

			if (Mask.OnlyNumber(event.target.value) === '000' || event.target.value === 'R$ 0,00') {
				setError('O campo não pode ser vazio.');
				return false;
			}
		}

		if (props.min) {
			if (typeof event.target.value === 'undefined' || props.min > parseFloat(event.target.value)) {
				setError('Valor menor que o permitido.');
				return false;
			}
		}

		if (props.validateEmail === true || props.email) {
			if (typeof event.target.value === 'undefined' || !Validator.isEmail(event.target.value)) {
				setError('Utilize um e-mail válido.');

				return false;
			}
		}

		setError(false);
	};

	const handleChange = name => event => {
		let value = event.target.value;
		if (props.onlyNumber) {
			value = Mask.OnlyNumber(value);
		}

		if (props.realMask) {
			value = Mask.OnlyNumber(event.target.value) / 100;
		}

		setData(value);
		setError(false);

		if (typeof props.onChange === 'function') {
			props.onChange(value, setData);
		}
	};

	const handleOnBlur = name => event => {
		if (props.realMask) {
			event.target.value = Mask.OnlyNumber(event.target.value) / 100;
		}

		if (props.validateEmail === true || props.email) {
			event.target.value = event.target.value.trim();
		}

		handleValidation(event);
		if (typeof props.onBlur === 'function') {
			props.onBlur(event);
		}
	};

	useEffect(() => {
		let value = props.value === 'Invalid date' ? '' : props.value;
		setData(value);
	}, [props]);

	useEffect(() => {
		// if(props.value){
		// 	setData(props.value);
		// }

		if (props.onError === true) {
			var event = {
				target: {
					value: data,
				},
			};

			handleValidation(event);
		}
	}, [props.onError]);

	var { maxLength, validateEmail, onError, email, onlyNumber, ...rest } = props;
	if (props.mask) {
		return (
			<div>
				<InputMask {...rest} value={data} onChange={handleChange()} onBlur={handleOnBlur()}>
					{inputProps => (
						<TextField
							{...inputProps}
							// value={inputProps.field}
							// id={props.id || "standard-name"}
							margin="normal"
							style={{ width: inputWidth }}
							InputLabelProps={{ shrink: true }}
						/>
					)}
				</InputMask>
				<span className={classes.errorText}>{error}</span>
			</div>
		);
	} else if (props.realMask) {
		var { realMask, ...otherRest } = rest;
		return (
			<div>
				<TextField
					{...otherRest}
					margin="normal"
					value={Mask.Real(data)}
					//  onChange={e => setData({ ...data, amount: Mask.OnlyNumber(e.target.value) / 100 })}
					onChange={handleChange()}
					onBlur={handleOnBlur()}
				/>
				<span className={classes.errorText}>{error}</span>
			</div>
		);
	} else {
		return (
			<div>
				<TextField
					{...rest}
					value={data}
					margin="normal"
					onChange={handleChange()}
					onBlur={handleOnBlur()}
					inputProps={{ maxLength: maxLength }}
					style={{
						width: inputWidth,
						borderBottom: isWhite(theme.palette.primary.main) ? '1px solid rgba(0, 0, 0, 0.42)' : 'initial',
					}}
					InputLabelProps={{
						shrink: true,
					}}
					InputProps={{
						readOnly: props.readOnly ? true : false,
						endAdornment: props.endAdornment,
					}}
				/>
				<span className={classes.errorText}>{error}</span>
			</div>
		);
	}
}

export default ZText;
