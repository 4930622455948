import { selector } from 'recoil';
import { estabelecimentoIdState, authTokenState } from './atoms';
import axios from 'axios';
import config from 'react-global-configuration';
import { Isset } from '../utils';

export const estabelecimentoState = selector({
	key: 'estabelecimentoState',
	get: async ({ get }) => {
		const estabelecimentoId = get(estabelecimentoIdState);
		const token = get(authTokenState);

		if (!estabelecimentoId || !token) {
			return null;
		}

		let res = await axios.post(
			`${config.get('apiUrl')}graphql`,
			{
				query: `
				query Estabelecimentos {
                    estabelecimento(id: ${estabelecimentoId}) {
                        id, nomeFantasia, nomeComprovante
                    }
				}
			`,
			},
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);

		if (res.status === 200 && Isset(() => res.data.data.estabelecimento)) {
			return res.data.data.estabelecimento;
		}

		return null;
	},
});
