import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Formatter } from '../../utils';
import Grid from '@material-ui/core/Grid';
import format from 'date-fns/format';
import pt from 'date-fns/locale/pt';

const useStyles = makeStyles(theme => {
	return {
		tableHeader: {
			width: '100%',
			textAlign: 'center',
			fontWeight: 700,
			fontSize: 18,
			margin: 0,
		},
		tableDateRow: {
			fontSize: 14,
			fontWeight: 700,
			padding: 10,
			display: 'flex',
			alignItems: 'center',
			borderRadius: 10,
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.getContrastText(theme.palette.primary.main),
			paddingLeft: 20,
			marginTop: 10,
			marginBottom: 10,
		},
		tableRow: {
			fontSize: 14,
			textAlign: 'center',
			fontWeight: 400,
			padding: 15,
			borderColor: '#d9d7d7',
			border: '1px solid',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-around',
			borderRadius: 20,
			cursor: 'pointer',
			'&:hover': {
				backgroundColor: `${theme.palette.primary.main}2b`,
			},
			marginBottom: 8,
			marginTop: 8,
			background: '#fff',
		},
		negativo: {
			color: `#ff5440 !important`,
		},
		truncate: {
			textOverflow: 'ellipsis',
			overflow: 'hidden',
			whiteSpace: 'nowrap',
		},
		hideOnXs: {
			[theme.breakpoints.down('xs')]: {
				display: 'none',
			},
		},
		dataHoraXs: {
			[theme.breakpoints.down('xs')]: {
				fontSize: 10,
			},
		},
		valorOnXs: {
			[theme.breakpoints.down('xs')]: {
				fontSize: 10,
			},
		},
	};
});

export default props => {
	const [lancamentos] = useState(props.lancamentos);
	const classes = useStyles();
	const usuario = useSelector(state => state.auth.usuario);
	const isForPay = usuario.marketplace.id === 2;
	const isAdmin = usuario.grupo_id === 1;
	const isGerencial = usuario.grupo_id === 2;
	return (
		<Grid container item className={classes.tableHeader}>
			{/*<Grid item xs={1}>
                Hora
            </Grid>
            <Grid item xs={2} sm={1}>
                Tipo
            </Grid>
            <Grid item xs={2} sm={1}>
                Método
            </Grid>
            {(isAdmin || isGerencial) && (
                <Grid item xs>
                    Estabelecimento
                </Grid>
            )}
            <Grid item xs={2}>
                Valor
            </Grid>
            <Grid item xs={1} className={classes.hideOnXs}>
                Taxa
            </Grid>
            <Grid item xs={1} className={classes.hideOnXs}>
                Valor Líquido
            </Grid>
            {!isForPay && (
                <Grid item xs={2}>
                    Saldo
                </Grid>
            )}*/}
			{lancamentos.map((lancamento, key) => (
				<React.Fragment key={key}>
					{lancamento.typeRow === 'date' ? (
						<Grid container item className={classes.tableDateRow}>
							<Grid item xs={8} style={{ textAlign: 'left' }}>
								{format(lancamento.date, 'DD [de] MMMM [de] YYYY', {
									locale: pt,
								})}
								{/* {format(lancamento.date, 'DD/MM HH:mm', {
									locale: pt,
								})} */}
							</Grid>
							<Grid item xs style={{ textAlign: 'right', paddingRight: 20 }}>
								{!isForPay && lancamento.saldoNovo && Formatter.Real(lancamento.saldoNovo.saldo)}
							</Grid>
						</Grid>
					) : (
						<Grid
							container
							item
							className={classes.tableRow}
							// onClick={event => {
							// 	event.preventDefault();
							// 	const url = [2, 3].includes(lancamento.tipoId)
							// 		? '/transferencias/detalhes/' + lancamento.id
							// 		: '/vendas/detalhes/' + lancamento.pedido_id;
							// 	window.open(url, '_blank');
							// }}
							onClick={event => {
								event.preventDefault();
								let url = '/vendas/detalhes/' + lancamento.transaction.dbId;

								if (
									['in', 'out', 'Saída via transferência', 'Entrada via transferência'].includes(
										lancamento.type
									)
								) {
									url = '/transferencias/detalhes/' + lancamento.transfer.dbId;
								}

								window.open(url, '_blank');
							}}
						>
							<Grid item xs={1} className={classes.dataHoraXs}>
								<p className={classes.tableHeader}>Hora</p>
								{format(lancamento.occurredAt, 'DD/MM HH:mm')}
							</Grid>
							<Grid item xs={2} sm={1} className={classes.truncate}>
								<p className={classes.tableHeader}>Tipo</p>
								{lancamento.type}
							</Grid>
							<Grid item xs={2} sm={1} className={classes.truncate}>
								<p className={classes.tableHeader}>Método</p>
								{lancamento.method}
							</Grid>
							{(isAdmin || isGerencial) && (
								<Grid item xs={2}>
									{/*style={{ overflow: 'hidden', maxHeight: 25 }}*/}
									<p className={classes.tableHeader}>Estabelecimento</p>
									{lancamento?.fromEstablishmentName
										? lancamento.fromEstablishmentName
										: lancamento.establishmentName}
								</Grid>
							)}
							{/* <Grid
								item
								xs={2}
								className={clsx(classes.valorOnXs, {
									[classes.negativo]: lancamento.grossValue < 0,
								})}
							>
								<p className={classes.tableHeader}>Valor</p>
								{Formatter.Real(lancamento.grossValue)}
							</Grid>
							<Grid item xs={1} className={classes.hideOnXs}>
								<p className={classes.tableHeader}>Taxa</p>
								{Formatter.Real(lancamento.fee)}
							</Grid> */}
							<Grid
								item
								xs={1}
								className={clsx(classes.hideOnXs, {
									[classes.negativo]: lancamento.grossValue < 0,
								})}
							>
								<p className={classes.tableHeader}>Líquido</p>
								{Formatter.Real(lancamento.amountToReceive)}
							</Grid>
							{/* {!isForPay && (
								<Grid item xs={1} className={classes.valorOnXs}>
									<p className={classes.tableHeader}>Saldo</p>
									{Formatter.Real(Isset(() => lancamento.saldo) ? lancamento.saldo : 0)}
								</Grid>
							)} */}
						</Grid>
					)}
				</React.Fragment>
			))}
		</Grid>
	);
};
