/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import CircularProgress from '@material-ui/core/CircularProgress';
import { FaTimesCircle } from 'react-icons/fa';

const useStyles = makeStyles(theme => ({
	ZWarning: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
		width: '100%',
	},
	progress: {
		color: 'red',
		margin: theme.spacing(2),
	},
	message: {
		fontFamily: "'Exo 2', sans-serif",
		fontSize: 18,
		fontWeight: 'bold',
		fontVariant: 'small-caps',
	},
}));

function useInterval(callback, delay) {
	const savedCallback = useRef();

	// Remember the latest callback.
	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	// Set up the interval.
	useEffect(() => {
		function tick() {
			savedCallback.current();
		}
		if (delay !== null) {
			let id = setInterval(tick, delay);
			return () => clearInterval(id);
		}
	}, [delay]);
}

export default function ZWarning(props) {
	const classes = useStyles();

	const { height, size, message } = props;
	const [suffix, setSuffix] = useState('');

	const style = {
		height: height ? height : '100vh',
	};

	useInterval(() => {
		setSuffix(suffix.length >= 3 ? '.' : `${suffix}.`);
	}, 500);

	return (
		<div className={classes.ZWarning} style={style}>
			<FaTimesCircle className={classes.progress} size={size ? size : 80} />
			{message && (
				<div className={classes.message}>
					{message}
					{suffix}
				</div>
			)}
		</div>
	);
}
