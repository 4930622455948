import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import axios from 'axios';
import config from 'react-global-configuration';

import Parser from 'html-react-parser';

export default function ResponsiveDialog(props) {

	const { marketplaceId } = props;
	const [open, setOpen] = React.useState(props.open);
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const [termos, setTermos] = React.useState();

	// const handleClickOpen = () => {
	// 	setOpen(true);
	// };

	const handleClose = () => {
        props.handleClose();
        // setOpen(false);
    };

	React.useEffect(() => {
        setOpen(props.open);
	},[props.open]);

	React.useEffect(() => {
		const getTermos = async () => {
			const res = await axios.get(`${config.get('apiUrl')}termosCondicoes/por_marketplace/${marketplaceId}`);
			
			if (res.data.termos){
				setTermos(Parser(res.data.termos.termos_condicoes))
			}
		};
		getTermos();
	}, [marketplaceId]);

	return (
		<div>
			{/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
				Open responsive dialog
			</Button> */}
			<Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
				<DialogTitle id="responsive-dialog-title">{"Termo de adesão"}</DialogTitle>
				<DialogContent>
					{/* <DialogContentText> */}
						<div>
							{termos}
						</div>
					{/* </DialogContentText> */}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="#000000">
						Cancelar
					</Button> 
					<Button onClick={() => { 
                            handleClose(); 
                            props.handleSubmitForm();
                        }} color="#000000" autoFocus>
						Aceitar
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
