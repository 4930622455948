/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { updateSaldo } from '../../store/system';
import { addMessage } from '../../store/alert';
import ZLoader from '../system/ZLoader';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import { Mask, Isset, Formatter } from '../../utils';
import { Paper } from '@material-ui/core';
import axios from 'axios';
import config from 'react-global-configuration';
import ZDatePicker from '../system/ZDatePicker';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
	field: {
		padding: 5,
		textAlign: 'center',
	},
	label: {
		fontSize: 11,
		textTransform: 'uppercase',
		fontWeight: 700,
	},
	fullWidth: {
		width: '100%',
	},
	saldoBox: {
		backgroundColor: '#616161',
		color: 'white',
		margin: -10,
		marginBottom: 10,
		padding: '5px 15px',
	},
	transferencia: {},
	content: {
		width: 400,
	},
	formaTransferencia: {
		padding: 16,
		fontSize: 14,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontWeight: 700,
		color: theme.palette.primary.main,
		border: `solid 1px ${theme.palette.primary.main}`,
		borderRadius: 6,
		cursor: 'pointer',
		'&.selected': {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.getContrastText(theme.palette.primary.main),
		},
	},
	contaBancaria: {
		margin: 8,
		marginTop: 10,
		padding: 4,
		fontSize: 14,
		fontWeight: 700,
		color: theme.palette.primary.main,
		border: `solid 1px ${theme.palette.primary.main}`,
		borderRadius: 6,
		cursor: 'pointer',
		'&.selected': {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.getContrastText(theme.palette.primary.main),
		},
	},
	error: {
		fontSize: 13,
		textTransform: 'uppercase',
		fontWeight: 700,
		color: '#f44336',
	},
	errorLink: {
		color: '#f44310',
		cursor: 'pointer',
	},
}));

var minDate = new Date();
minDate.setDate(minDate.getDate() + 1);

const initialState = {
	cpfcnpj: '',
	formaTransferencia: 1,
	valor: 0,
	estabelecimento: {
		id: null,
		logo: null,
		nome: '',
		email: '',
		documento: '',
	},
	contaBancariaId: null,
	descricao: '',
	senha: '',
	agendadoPara: null,
};

export default props => {
	const classes = useStyles();

	const history = useHistory();
	const dispatch = useDispatch();
	const token = useSelector(state => state.auth.token);
	const [open, setOpen] = useState(props.open || false);
	const [sending, setSending] = useState(false);
	const [contasBancarias, setContasBancarias] = useState([]);
	const [data, setData] = useState(initialState);
	const [switcher, setSwitcher] = useState(false);
	const estabelecimentoId = props.estabelecimentoId;
	const [loadingContaBancaria, setLoadingContaBancaria] = useState(false);

	const [errors, setErrors] = useState({});
	const [saldo, setSaldo] = useState({
		atual: 0,
	});

	const validate = () => {
		let e = {};

		if (!data.valor) {
			e.valor = true;
		} else if (!switcher && data.valor > saldo.atual) {
			e.valorMessage = 'Valor maior que o saldo atual.';
		}

		if (data.formaTransferencia === 1) {
			if (!data.estabelecimento.id) {
				e.cpfcnpj = true;
			}
		} else if (data.formaTransferencia === 2) {
			if (!data.contaBancariaId) {
				e.contaBancaria = true;
			}
		}

		if (!data.senha) {
			e.senha = true;
		}

		setErrors(e);

		return Object.keys(e).length > 0;
	};

	const handleClose = () => {
		setOpen(false);

		if (props.onClose) {
			props.onClose();
		}
	};

	const handleSubmit = async () => {
		if (!validate()) {
			setSending(true);

			const postData = {
				tipoTransferencia: data.formaTransferencia,
				valor: data.valor,
				fromEstabelecimentoId: estabelecimentoId,
				toEstabelecimentoId: data.estabelecimento.id,
				contaBancariaId: data.contaBancariaId,
				senha: data.senha,
				descricao: data.descricao,
				agendadoPara: data.agendadoPara,
			};

			const res = await axios.post(`${config.get('apiUrl')}transferencias`, postData, {
				headers: { Authorization: `Bearer ${token}` },
			});

			if (res.data.success) {
				dispatch(addMessage({ type: 'success', message: 'Operação realizada com sucesso!' }));
				dispatch(updateSaldo(true));
				if (props.onSuccess) {
					props.onSuccess();
				}
				handleClose();
			} else {
				dispatch(addMessage({ type: 'error', message: res.data.message }));
				if (props.onError) {
					props.onError();
				}
			}

			setSending(false);
		}
	};

	useEffect(() => {
		const getSaldo = async () => {
			const res = await axios.get(`${config.get('apiUrl')}estabelecimentos/${estabelecimentoId}/saldo`, {
				headers: { Authorization: `Bearer ${token}` },
			});

			if (res.data.success) {
				setSaldo({
					...saldo,
					...res.data.saldo,
				});
			}
		};

		const getContasBancarias = async () => {
			setLoadingContaBancaria(true);
			const res = await axios.get(
				`${config.get('apiUrl')}estabelecimentos/${estabelecimentoId}/contas_bancarias`,
				{
					headers: { Authorization: `Bearer ${token}` },
				}
			);

			if (res.data.success) {
				setContasBancarias(res.data.contasBancarias);
			}
			setLoadingContaBancaria(false);
		};

		getSaldo();
		getContasBancarias();
	}, []);

	useEffect(() => {
		setData({
			...data,
			estabelecimento: {
				id: null,
				logo: null,
				nome: '',
				email: '',
				documento: '',
			},
		});
		if ([11, 14].includes(data.cpfcnpj.length)) {
			const getEstabelecimento = async () => {
				const res = await axios.get(`${config.get('apiUrl')}estabelecimentos/por_documento/${data.cpfcnpj}`, {
					headers: { Authorization: `Bearer ${token}` },
				});

				if (res.data.success) {
					const est = res.data.estabelecimento;

					if (est.id === estabelecimentoId) {
						setErrors({
							...errors,
							estabelecimentoMessage: 'Você não pode transferir dinheiro para você mesmo.',
						});
						return;
					}

					setData({
						...data,
						estabelecimento: {
							id: est.id,
							logo: Isset(() => est.arquivo.url) ? est.arquivo.url : null,
							nome: est.nome_fantasia,
							email: Isset(() => est.estabelecimentos_contatos[0].contato)
								? est.estabelecimentos_contatos[0].contato
								: '',
							documento: Isset(() => est.estabelecimentos_documentos[0].documento)
								? est.estabelecimentos_documentos[0].documento
								: '',
						},
					});
					setErrors({
						...errors,
						estabelecimentoMessage: null,
					});
				} else {
					setErrors({
						...errors,
						estabelecimentoMessage: 'Estabelecimento não encontrado.',
					});
				}
			};

			getEstabelecimento();
		}
	}, [data.cpfcnpj]);

	return (
		<div className={classes.transferencia}>
			<Dialog open={open} onClose={handleClose}>
				<DialogContent className={classes.content}>
					<Paper className={classes.saldoBox}>
						<div className={classes.saldoAtual}>Saldo Atual: {Formatter.Real(saldo.atual)} </div>
						{/* <div className={classes.saldoFuturo}>Saldo Futuro: {Formatter.Real(saldo.futuro)} </div> */}
					</Paper>

					<Grid item xs={12} align="center" style={{ marginTop: 20 }}>
						<FormControlLabel
							control={
								<Switch
									checked={switcher}
									onChange={() => {
										setSwitcher(!switcher);
										setData({ ...data, agendadoPara: switcher ? null : minDate });
									}}
									value="checkedB"
									color="primary"
								/>
							}
							label="Agendar transferência"
						/>
					</Grid>
					{switcher && (
						<Grid item xs={12}>
							<ZDatePicker
								modal
								textField
								label="Data"
								date={data.agendadoPara}
								minDate={minDate}
								onChange={date => setData({ ...data, agendadoPara: date })}
							/>
						</Grid>
					)}

					<Grid container justifyContent="center" spacing={2} style={{ marginTop: 5 }}>
						<Grid item xs={12}>
							<TextField
								className={classes.fullWidth}
								error={errors.valor}
								label="Valor"
								value={Mask.Real(data.valor)}
								onChange={e => setData({ ...data, valor: Mask.OnlyNumber(e.target.value) / 100 })}
							/>
						</Grid>
						{errors.valorMessage && (
							<Grid item xs={12} style={{ textAlign: 'center' }}>
								<div className={classes.error}>{errors.valorMessage}</div>
							</Grid>
						)}
					</Grid>

					<Grid container justifyContent="center" spacing={2}>
						<Grid item xs={6}>
							<div
								className={clsx(classes.formaTransferencia, {
									selected: data.formaTransferencia === 1,
								})}
								onClick={() => setData({ ...data, formaTransferencia: 1 })}
							>
								Conta Digital
							</div>
						</Grid>
						<Grid item xs={6}>
							<div
								className={clsx(classes.formaTransferencia, {
									selected: data.formaTransferencia === 2,
								})}
								onClick={() => setData({ ...data, formaTransferencia: 2 })}
							>
								Conta Bancária
							</div>
						</Grid>
					</Grid>

					{data.formaTransferencia === 1 && (
						<Grid container justifyContent="center" spacing={2}>
							<Grid item xs={12}>
								<TextField
									className={classes.fullWidth}
									error={errors.cpfcnpj}
									label="CPF / CNPJ"
									autoComplete="off"
									value={data.cpfcnpj.length <= 11 ? Mask.Cpf(data.cpfcnpj) : Mask.Cnpj(data.cpfcnpj)}
									onChange={e => {
										const value =
											e.target.value.length <= 11
												? Mask.Cpf(e.target.value)
												: Mask.Cnpj(e.target.value);
										setData({ ...data, cpfcnpj: Mask.OnlyNumber(value) });
									}}
								/>
							</Grid>

							{Isset(() => errors.estabelecimentoMessage) && errors.estabelecimentoMessage !== null && (
								<Grid item xs={12} style={{ textAlign: 'center' }}>
									<div className={classes.error}>{errors.estabelecimentoMessage}</div>
								</Grid>
							)}

							{data.estabelecimento.id !== null && (
								<React.Fragment>
									{data.estabelecimento.logo !== null && (
										<Grid item xs={12} style={{ textAlign: 'center' }}>
											<img src={data.estabelecimento.logo} alt="Logo" height={100} />
										</Grid>
									)}
									<Grid item xs={12}>
										<div className={classes.field}>
											<div className={classes.label}>Transferir Para</div>
											<div>{data.estabelecimento.nome}</div>
										</div>
									</Grid>
									<Grid item xs={6}>
										<div className={classes.field}>
											<div className={classes.label}>CPF / CNPJ</div>
											<div>
												{data.estabelecimento.documento.length <= 11
													? Mask.Cpf(data.estabelecimento.documento)
													: Mask.Cnpj(data.estabelecimento.documento)}
											</div>
										</div>
									</Grid>
									<Grid item xs={6}>
										<div className={classes.field}>
											<div className={classes.label}>E-mail</div>
											<div>{data.estabelecimento.email}</div>
										</div>
									</Grid>
								</React.Fragment>
							)}
						</Grid>
					)}

					{data.formaTransferencia === 2 && (
						<Grid container justifyContent="center" spacing={2}>
							{errors.contaBancaria && (
								<Grid item xs={12} style={{ textAlign: 'center' }}>
									<div className={classes.error}>Selecione uma Conta Bancária</div>
								</Grid>
							)}
							{loadingContaBancaria ? (
								<ZLoader height={100} message="Carregando Contas Bancárias" />
							) : (
								<>
									{contasBancarias.length === 0 ? (
										<Grid item xs={12} style={{ textAlign: 'center' }}>
											<div className={classes.error}>Nenhuma Conta Bancária Encontrada.</div>
											<div
												className={clsx(classes.error, classes.errorLink)}
												onClick={() =>
													history.push('/estabelecimentos/configuracoes?t=contaBancaria')
												}
											>
												Clique aqui para cadastrar uma.
											</div>
										</Grid>
									) : (
										<React.Fragment>
											{contasBancarias.map((c, i) => (
												<Paper
													key={i}
													className={clsx(classes.contaBancaria, {
														selected: data.contaBancariaId === c.id,
													})}
													onClick={() => setData({ ...data, contaBancariaId: c.id })}
												>
													<Grid container item xs={12}>
														<Grid item xs={6}>
															<div className={classes.label}>{c.banco}</div>
														</Grid>
														<Grid item xs={6}>
															<div
																className={classes.label}
																style={{ textAlign: 'right' }}
															>
																{c.tipoContaBancaria === 1
																	? 'Conta Corrente'
																	: 'Conta Poupança'}
															</div>
														</Grid>
														<Grid item xs={12}>
															<div className={classes.field}>
																<div className={classes.label}>Titular</div>
																<div>{c.nomeTitular}</div>
															</div>
														</Grid>
														<Grid item xs={6}>
															<div className={classes.field}>
																<div className={classes.label}>Agência</div>
																<div>{c.agencia}</div>
															</div>
														</Grid>
														<Grid item xs={6}>
															<div className={classes.field}>
																<div className={classes.label}>Conta</div>
																<div>{c.conta}</div>
															</div>
														</Grid>
													</Grid>
												</Paper>
											))}
										</React.Fragment>
									)}
								</>
							)}
						</Grid>
					)}

					<Grid item xs={12} style={{ marginTop: 4 }}>
						<TextField
							className={classes.fullWidth}
							label="Descrição"
							value={data.descricao}
							onChange={e => setData({ ...data, descricao: e.target.value })}
						/>
					</Grid>
					<Grid item xs={12} style={{ marginTop: 4 }}>
						<TextField
							className={classes.fullWidth}
							error={errors.senha}
							type="password"
							label="Senha"
							inputProps={{
								autoComplete: 'new-password',
								form: {
									autoComplete: 'off',
								},
							}}
							value={data.senha}
							onChange={e => setData({ ...data, senha: e.target.value })}
						/>
					</Grid>
				</DialogContent>
				<DialogActions>
					{sending ? (
						<ZLoader size={30} height={60} />
					) : (
						<React.Fragment>
							<Button onClick={handleClose} color="primary">
								Cancelar
							</Button>
							<Button onClick={handleSubmit} color="primary" autoFocus>
								Enviar
							</Button>
						</React.Fragment>
					)}
				</DialogActions>
			</Dialog>
		</div>
	);
};
