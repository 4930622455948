import { atom } from 'recoil';

export const estabelecimentoIdState = atom({
	key: 'estabelecimentoIdState',
	default: null,
});

export const authTokenState = atom({
	key: 'authTokenState',
	default: localStorage.getItem('token'),
});
