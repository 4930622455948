import React, { useMemo, useState } from 'react';
import { Grid, Typography, Button, makeStyles } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Close';
// import IconButton from '@material-ui/core/IconButton';

import clsx from 'clsx';
import axios from 'axios';
import config from 'react-global-configuration';
import { useSelector } from 'react-redux';

import ZText from '../system/ZText';
import { Mask, Isset, isWhite } from '../../utils';
import { SelectEstabelecimento } from '../Estabelecimentos/SelectEstabelecimento';
import ZModalWarning from '../system/ZModalWarning';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
	fullWidth: {
		width: '100%',
	},
	stepActions: {
		marginTop: 60,
		textAlign: 'center',
	},
	backButton: {
		color: isWhite(theme.palette.primary.main) ? '#B4B4B4' : theme.palette.primary.main,
		border: `solid 1px `,
		borderColor: isWhite(theme.palette.primary.main),
		width: 171,
		height: 44,
		marginRight: 10,
		'&:hover': {
			color: isWhite(theme.palette.primary.main)
				? '#313131'
				: theme.palette.primary.main
				? '#fff'
				: theme.palette.getContrastText(theme.palette.primary.main),
			backgroundColor: theme.palette.primary.main,
		},
		[theme.breakpoints.down('sm')]: {
			marginRight: 0,
			width: '100%',
		},
	},
	nextButton: {
		width: 171,
		height: 44,
		backgroundColor: isWhite(theme.palette.primary.main) ? '#EEEEEE' : theme.palette.primary.main,
		color: isWhite(theme.palette.primary.main)
			? '#606060'
			: theme.palette.getContrastText(theme.palette.primary.main),
		'&:hover': {
			backgroundColor: isWhite(theme.palette.primary.main) ? '#ccc' : theme.palette.primary.dark,
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	stepTitle: {
		color: '#3E3B47',
		marginBottom: 48,
	},
	removeButton: {
		minHeight: 26,
		height: 26,
		width: 26,
		backgroundColor: '#e53935',
		color: 'white',
	},
	label: {
		fontSize: 11,
		textTransform: 'uppercase',
		fontWeight: 700,
	},
	error: {
		padding: 8,
		borderRadius: 5,
		backgroundColor: '#e53935',
		color: 'white',
		marginBottom: 10,
	},
	estabelecimentoIcone: {
		border: 'solid 1px #abc',
		marginRight: 2,
		marginLeft: 2,
		marginTop: 16,
		'&.selected': {
			backgroundColor: `${theme.palette.primary.main}`,
			color: theme.palette.getContrastText(theme.palette.primary.main),
		},
	},
	estabelecimentoValor: {
		'&.porcentagem input': {
			textAlign: 'right',
		},
	},
	estabelecimentoError: {
		fontSize: 12,
		color: '#e53935',
	},
	modalSelectEstabelecimento: {
		minWidth: '400px',
	},
	SelectEstabelecimento: {
		width: '100%',
	},
	formControl: {
		width: '100%',
	},
}));

export default function ConfigurarSplit({
	setData,
	data,
	buttons,
	errors,
	estabelecimentoId,
	setErrors,
	setActiveStep,
}) {
	const classes = useStyles();

	if (typeof buttons === 'undefined') {
		buttons = true;
	}
	const token = useSelector(store => store.auth.token);

	// eslint-disable-next-line no-unused-vars
	const [showModal, setModal] = useState({
		messageDialog: '',
		open: false,
	});

	const valorDaVenda = useMemo(() => {
		let valor;
		const existeValor = data.valorBoleto !== 0;

		existeValor ? (valor = data.valorBoleto) : (valor = data.valorCartao);

		return valor;
	}, [data.valorBoleto, data.valorCartao]);

	const valorLiquido = useMemo(() => {
		return true;
	}, []);

	const handleNewSplit = () => {
		const splits = [...data.splits];

		splits.push({
			cpfcnpj: '',
			estabelecimentoId: null,
			nome: '',
			email: '',
			tipoSplit: 2, // 1 - Valor, 2 - Percentual
			valor: '',
			chargeProcessingFee: false,
		});

		setData({
			...data,
			splits,
		});
	};

	const updateSplit = async (index, name, value) => {
		let splits = [...data.splits];
		let split = splits.find((_, i) => i === index);

		if (split) {
			split = Object.assign({}, split);
			split[name] = value;

			if (name === 'cpfcnpj') {
				delete errors[`split${index}`];
				setErrors(errors);

				if ([11, 14].includes(value.length)) {
					const result = await axios.get(`${config.get('apiUrl')}estabelecimentos/por_documento/${value}`, {
						headers: { Authorization: `Bearer ${token}` },
					});

					if (result.data.success) {
						const e = result.data.estabelecimento;

						if (!e.length) {
							if (+estabelecimentoId === +e.id) {
								setErrors({
									...errors,
									[`split${index}`]: 'Você não pode selecionar o mesmo estabelecimento.',
								});
								return;
							}

							const email = e.estabelecimentos_contatos.find(a => a.tipo_contato_id === 3);

							const estabelecimentoExiste = data.splits.find(
								(a, i) => a.estabelecimentoId === e.id && index !== i
							);

							if (estabelecimentoExiste) {
								setErrors({
									...errors,
									[`split${index}`]: 'Você já selecionou este estabelecimento.',
								});
								return;
							} else {
								split['estabelecimentoId'] = e.id;
								split['nome'] = e.nome_fantasia;
								split['email'] = Isset(() => email.contato) ? email.contato : '';
							}
						} else {
							openEstablishmentSelectionModal(e, index);
						}
					} else {
						setErrors({ ...errors, [`split${index}`]: 'Estabelecimento Não Encontrado.' });
					}
				} else {
					split['estabelecimentoId'] = null;
					split['nome'] = '';
					split['email'] = '';
				}
			}

			if (name === 'tipoSplit' && value === 2) {
				if (split.value > 100) {
					split.value = 100;
				}
			}

			splits[index] = split;
			const splitMaximo = !valorLiquido ? valorDaVenda * 0.9 : valorDaVenda;
			let totalSplitado = 0;

			splits.forEach(split => {
				if (split.tipoSplit === 2) {
					totalSplitado += (split.valor / 100) * valorDaVenda;
				} else {
					totalSplitado += split.valor;
				}
			});

			if (totalSplitado > splitMaximo) {
				setModal({
					messageDialog: !valorLiquido
						? 'Para ultrapassar 90% do valor selecione Valor Líquido'
						: 'Você não pode splitar mais que o valor total',
					open: true,
				});
			} else {
				setData({ ...data, splits });
			}
		}
	};

	const [showModalEstablishmentSelection, setShowModalEstablishmentSelection] = useState(false);
	const [ecsOfTheDocument, setEcsOfTheDocument] = useState();
	const [ecSelected, setEcSelected] = useState('Estabelecimento');
	const [indexEc, setIndexEc] = useState();

	const openEstablishmentSelectionModal = (estabelecimentos, index) => {
		setEcsOfTheDocument(estabelecimentos);
		setIndexEc(index);
		setShowModalEstablishmentSelection(true);
	};

	const handleSetEstabelecimento = () => {
		let estabelecimento;
		let estabelecimentoFormatoParaData = {};
		let splits = [...data.splits];

		estabelecimento = ecsOfTheDocument.find(e => e.id === ecSelected);

		const email = estabelecimento.estabelecimentos_contatos.find(a => a.tipo_contato_id === 3);

		estabelecimentoFormatoParaData['estabelecimentoId'] = estabelecimento.id;
		estabelecimentoFormatoParaData['nome'] = estabelecimento.nome_fantasia;
		estabelecimentoFormatoParaData['email'] = Isset(() => email.contato) ? email.contato : '';
		estabelecimentoFormatoParaData['cpfcnpj'] = estabelecimento.estabelecimentos_documentos[0].documento;
		estabelecimentoFormatoParaData['tipoSplit'] = 2;

		splits[indexEc] = estabelecimentoFormatoParaData;

		setData({ ...data, splits });
		setShowModalEstablishmentSelection(false);
	};

	const handleRemoveSplit = position => {
		const splits = [...data.splits];
		splits.splice(position, 1);

		setData({
			...data,
			splits,
		});
	};

	return (
		<>
			<Grid container alignItems="center">
				<Typography variant="h5" component="h6" className={classes.stepTitle}>
					Configurar Split
				</Typography>
				<Grid container xs={12} justifyContent="center">
					<Typography variant="body1" component="h6" className={classes.stepTitle}>
						Se você não deseja fazer split nessa venda, clique em continuar
					</Typography>
				</Grid>

				<Grid container item md={12}>
					<Grid container style={{ marginBotton: 10 }}>
						{errors.porcentagem && (
							<Grid item xs={12}>
								<div className={classes.error}>
									O total da porcentagem deve ser menor ou igual a 100%
								</div>
							</Grid>
						)}
						<Grid item xs={6}></Grid>
						<Grid item xs={12} container alignItems="center">
							<Grid container direction="row" justifyContent="flex-end">
								{/* <Grid>
                  <ZSwitch
                    onChange={() =>
                      setData(data => ({
                        ...data,
                        chargeProcessingFee: !data.chargeProcessingFee,
                      }))
                    }
                    label={<div>Valor Líquido?</div>}
                    // checked={estabelecimento.chargeProcessingFee}
                  />
                </Grid> */}

								<Grid>
									<Button
										onClick={() => handleNewSplit()}
										variant="contained"
										size="small"
										color="primary"
										aria-label="add"
									>
										<AddIcon /> Estabelecimento
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid container spacing={3}>
						{data.splits.map((split, key) => {
							return (
								// <Paper key={key} className={classes.split}>
								// <Grid container>
								<div key={key}>
									<Grid item xs={12} md={12} style={{ marginBottom: 10, marginTop: 15 }}>
										<Grid container spacing={3} alignItems="center">
											<Grid item xs={12} md={3}>
												<ZText
													className={classes.fullWidth}
													label="CPF / CNPJ"
													value={
														split.cpfcnpj.length <= 11
															? Mask.Cpf(split.cpfcnpj)
															: Mask.Cnpj(split.cpfcnpj)
													}
													onChange={(value, setValue) => {
														value = Mask.OnlyNumber(value);
														setValue(
															value.length <= 11 ? Mask.Cpf(value) : Mask.Cnpj(value)
														);

														value !== '' ? (data.split = true) : (data.split = false);
													}}
													onBlur={e => {
														const v =
															e.target.value.length <= 11
																? Mask.Cpf(e.target.value)
																: Mask.Cnpj(e.target.value);
														updateSplit(key, 'cpfcnpj', Mask.OnlyNumber(v));
													}}
												/>
											</Grid>

											<Grid
												style={{
													display: 'flex',
													alignItems: 'center',
												}}
												item
												xs={12}
												md={3}
											>
												{/* <IconButton
													className={clsx(classes.estabelecimentoIcone, {
														selected: split.tipoSplit === 1,
													})}
													onClick={() => updateSplit(key, 'tipoSplit', 1)}
													>
													<FaDollarSign style={{ fontSize: 14 }} />
                        						</IconButton> */}
												<ZText
													error={errors[`split${key}valor`]}
													// endAdornment={<InputAdornment position="end">%</InputAdornment>}
													className={clsx(classes.fullWidth, classes.estabelecimentoValor, {
														porcentagem: split.tipoSplit === 2,
													})}
													label={split.tipoSplit === 1 ? 'Valor' : 'Porcentagem'}
													value={
														split.tipoSplit === 1
															? Mask.Real(split.valor)
															: Mask.Porcentagem(split.valor)
													}
													onChange={(value, setValue) => {
														value = Mask.OnlyNumber(value) / 100;
														setValue(
															split.tipoSplit === 1
																? Mask.Real(value)
																: value > 100
																? Mask.Porcentagem(100)
																: Mask.Porcentagem(value)
														);
													}}
													onBlur={e =>
														updateSplit(key, 'valor', Mask.OnlyNumber(e.target.value) / 100)
													}
													onFocus={e => {
														const length = e.target.value.length * 2;
														e.target.setSelectionRange(0, length);
													}}
												/>
												{/* <IconButton
                          className={clsx(classes.estabelecimentoIcone, {
                            selected: split.tipoSplit === 2,
                          })}
                          onClick={() => {
                            updateSplit(key, 'tipoSplit', 2);
                          }}
                        >
                          <FaPercentage style={{ fontSize: 14 }} />
                        </IconButton> */}
											</Grid>

											<Grid
												style={{
													display: 'flex',
													alignItems: 'center',
													marginTop: 27,
												}}
												item
												xs={12}
												md={3}
											>
												<SelectEstabelecimento
													estabelecimentoId={split.estabelecimentoId}
													value={Mask.OnlyNumber(split.cpfcnpj)}
													setEstabelecimento={estabelecimento => {
														const hasEstabelecimento = Boolean(estabelecimento);
														const documento = hasEstabelecimento
															? estabelecimento.documento
															: '';

														updateSplit(key, 'cpfcnpj', Mask.OnlyNumber(documento));
													}}
												/>
											</Grid>

											<Grid
												className={classes.valorLiquido}
												style={{
													display: 'flex',
													alignItems: 'center',
												}}
												item
												xs={12}
												md={1}
											>
												{key > 0 && (
													<Grid item lg={1} sm={1} xs={1}>
														<Fab
															size="small"
															className={classes.removeButton}
															onClick={() => handleRemoveSplit(key)}
														>
															<RemoveIcon size="small" style={{ fontSize: 15 }} />
														</Fab>
													</Grid>
												)}
											</Grid>

											{split.estabelecimentoId !== null && (
												<>
													<Grid
														item
														xs={6}
														style={{
															padding: 0,
															marginTop: -10,
														}}
													>
														<div className={classes.label}>{split.nome}</div>
													</Grid>
													<Grid
														item
														xs={6}
														style={{
															padding: 0,
															marginTop: -10,
														}}
													>
														<div className={classes.label}>{split.email}</div>
													</Grid>
												</>
											)}
											{Isset(() => errors[`split${key}`]) && (
												<Grid
													item
													xs={6}
													style={{
														padding: 0,
														marginTop: -10,
													}}
												>
													<div className={clsx(classes.label, classes.estabelecimentoError)}>
														{errors[`split${key}`]}
													</div>
												</Grid>
											)}
										</Grid>
									</Grid>
								</div>
							);
						})}
					</Grid>
				</Grid>

				{buttons === true && (
					<Grid
						container
						justifyContent="flex-end"
						spacing={2}
						direction="row"
						className={classes.stepActions}
					>
						<Grid item xs={12} sm={12} md={6} lg={3}>
							<Button
								className={classes.backButton}
								fullWidth
								onClick={() =>
									[1, 4].includes(data.formaPagamento) ? setActiveStep(3) : setActiveStep(2)
								}
							>
								Voltar
							</Button>
						</Grid>
						<Grid item xs={12} sm={12} md={6} lg={3}>
							<Button className={classes.nextButton} fullWidth onClick={() => setActiveStep(5)}>
								Continuar
							</Button>
						</Grid>
					</Grid>
				)}
			</Grid>
			<ZModalWarning
				title={`Atenção`}
				description={'Selecione um estabelecimento abaixo.'}
				content={
					<div className={classes.modalSelectEstabelecimento}>
						<FormControl className={classes.formControl}>
							<InputLabel id="demo-simple-select-label">Estabelecimentos</InputLabel>
							<Select
								className={classes.SelectEstabelecimento}
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={ecSelected}
								onChange={e => setEcSelected(e.target.value)}
							>
								{ecsOfTheDocument &&
									ecsOfTheDocument.map(e => {
										return (
											<MenuItem key={e.id} value={e.id}>
												{e.nome_fantasia}
											</MenuItem>
										);
									})}
							</Select>
						</FormControl>
					</div>
				}
				open={showModalEstablishmentSelection}
				handleClose={() => setShowModalEstablishmentSelection(false)}
				button={
					<Button onClick={handleSetEstabelecimento} color="primary">
						Confirmar
					</Button>
				}
			/>
		</>
	);
}
