import React, { useState, useEffect } from 'react';
import { TextField, makeStyles, Divider } from '@material-ui/core';
import AutoComplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles(theme => ({
	input: {
		width: '100%',
		[theme.breakpoints.down('xs')]: {
			width: '100%',
		},
	},
	dividers: {
		margin: '4px 0',
	},
	title: {
		fontSize: 14,
		fontWeight: 'Bold',
	},
	select: {
		'& .MuiAutocomplete-input': {
			padding: '3px 10px !important',
		},
	},
}));

export default function(props) {
	const classes = useStyles();
	const { options, defaultValue } = props.value;

	const [state, setState] = useState([]); // id e name
	const [data, setData] = useState(defaultValue || []); // só id

	useEffect(() => {
		if (data) {
			if (props.value.multiple) {
				setState(data.map(val => options.find(({ id }) => id === val)));
			} else {
				setState(options.find(({ id }) => id === data));
			}
		} else {
			setState([]);
		}
	}, [data, options, props.value.multiple]);

	useEffect(() => {
		setData(defaultValue || []);
	}, [defaultValue]);

	const onChange = (_, data) => {
		let result;

		if (props.value.multiple) {
			result = data.map(({ id }) => id);
		} else {
			result = data ? data.id : '';
		}

		if (props.onChange) {
			props.onChange(props.value.key, result);
		}

		setData(result);
	};

	const getOptionsSelected = (option, value) => {
		if (option && value) {
			return option.name === value.name || option.id === value.id;
		}
	};

	return (
		<>
			{props.value.divider && <Divider light className={classes.dividers} />}

			{props.value.title && <p className={classes.title}>{props.title}</p>}

			<AutoComplete
				className={classes.select}
				multiple={props.value.multiple}
				disableCloseOnSelect={true}
				filterSelectedOptions={true}
				options={options}
				value={state}
				getOptionLabel={option => option.name}
				getOptionSelected={getOptionsSelected}
				renderInput={params => (
					<TextField
						{...params}
						placeholder={props.value.placeholder}
						className={classes.input}
						size="small"
						variant="outlined"
					/>
				)}
				onChange={onChange}
			/>
		</>
	);
}
