/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Axios from 'axios';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import ZLoader from '../system/ZLoader';
import ZFiltros from '../system/ZFiltros';
import { useHistory, useLocation } from 'react-router-dom';

import { FaDownload } from 'react-icons/fa';
import ZEmpty from '../system/ZEmpty';
import clsx from 'clsx';
import { Formatter } from '../../utils/';

import { addMessage } from '../../store/alert';

import config from 'react-global-configuration';
import { makeStyles } from '@material-ui/core/styles';
import ZPaginationDiv from '../system/ZPaginationDiv';
import { Tooltip } from '@material-ui/core';
import LastExport from '../Export/LastExport';

const useStyles = makeStyles(theme => ({
	header: {
		fontWeight: 700,
		fontSize: 20,
		margin: 8,
	},
	export: {
		position: 'absolute',
		right: 10,
		opacity: 0.5,
		cursor: 'pointer',
		color: '#464ae6',
		fontSize: 16,
		transition: theme.transitions.create(['all'], {
			duration: theme.transitions.duration.complex,
		}),
		'&:hover': {
			fontSize: 18,
			opacity: 1,
		},
	},
	tableHeader: {
		textAlign: 'center',
		fontWeight: 700,
		fontSize: 18,
	},
	tableSubHeader: {
		textAlign: 'center',
		fontWeight: 700,
		fontSize: 16,
	},
	tableDateRow: {
		fontWeight: 700,
		// height: 30,
		padding: 4,
		display: 'flex',
		alignItems: 'center',
		borderRadius: 10,
		backgroundColor: `${theme.palette.primary.main}aa`,
		color: theme.palette.getContrastText(theme.palette.primary.main),
		paddingLeft: 20,
	},
	tableRow: {
		// textAlign: 'center',
		fontWeight: 700,
		padding: 5,
		display: 'flex',
		alignItems: 'center',
		borderRadius: 10,
		marginBottom: 2,
		backgroundColor: `${theme.palette.primary.main}1a`,
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}2b`,
		},
	},
	exportDownload: {
		color: '#011D29',
		fontSize: 16,
		marginLeft: 8,
	},
	iconButton: {
		minWidth: 0,
	},
	buttonExport: {
		color: '#040633',
		border: `solid 1px #040633`,
		'&:hover': {
			color: '#FFF',
			backgroundColor: '#011d29',
			'& svg': {
				color: '#FFF',
			},
		},
		[theme.breakpoints.down('xs')]: {
			width: '90%',
			marginTop: 16,
		},
		'& svg': {
			margin: 0,
		},
	},
	tableSubRow: {
		textAlign: 'center',
		fontWeight: 700,
		display: 'flex',
		alignItems: 'center',
		borderRadius: 20,
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}2b`,
		},
	},
	tableRowSelected: {
		backgroundColor: `${theme.palette.primary.main}3c`,
	},
	vendas: {
		marginBottom: 10,
	},
	noWrap: {
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
	},
	numberValue: {
		//textAlign: 'right',
		textAlign: 'center',
		paddingRight: 10,
	},
	btn: {
		marginBottom: 10,
		backgroundColor: '#ce8290',
		color: theme.palette.getContrastText('#ce8290'),
		'&:hover': {
			backgroundColor: '#ce8290dd',
		},
	},
}));

export default props => {
	const history = useHistory();
	const token = useSelector(store => store.auth.token);
	const classes = useStyles();

	const dispatch = useDispatch();
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [showExportMessage, setShowExportMessage] = useState(false);
	const [showLastExport, setShowLastExport] = useState(false);
	const { pathname } = useLocation();
	const pathnameSlug = pathname.toLowerCase().replace(/[^a-z0-9]/gi, '');

	const filtersCache = useSelector(store => store.filters[pathnameSlug] || {});

	const queryFilter = {
		startDate: new Date(),
		endDate: new Date(),
		...filtersCache,
	};

	const [filtros, setFiltros] = useState(queryFilter);
	const filtrosChange = async data => {
		const f = {
			startDate: data.date.start,
			endDate: data.date.end,
			omni: data.omni,
			searchEstabelecimentoByCpfOrCNPJ: data.searchEstabelecimentoByCpfOrCNPJ,
			nomeComprovante: data.nomeComprovante,
		};

		setFiltros(f);
	};

	// Paginação

	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(100);
	const [totalRows, setTotalRows] = useState(0);

	const onChangePage = newPage => {
		setPage(newPage);
	};

	const onChangeRowsPerPage = rowsPerPage => {
		setRowsPerPage(rowsPerPage);
		setPage(1);
	};
	useEffect(() => {
		const getPerformance = async () => {
			setLoading(true);

			try {
				const res = await Axios.get(`${config.get('apiUrl')}relatorios/performancePorEstabelecimento`, {
					params: {
						...filtros,
						limit: rowsPerPage,
						page,
					},
					headers: { Authorization: `Bearer ${token}` },
				});

				if (res.data.success) {
					if (res.data.message === 'generating cache') {
						setTimeout(getPerformance, 5000);
					} else {
						setData(res.data.result);
						setTotalRows(res.data.totalRows);
						setLoading(false);
					}
				}
			} catch (error) {
				console.error('Error fetching performance data:', error);
				setLoading(false);
			}
		};

		getPerformance();
	}, [filtros, page, rowsPerPage]);

	const exportXlsClick = async () => {
		setShowLastExport(true);
	};

	const newExport = async getLastExports => {
		await exportXls(getLastExports);
	};

	const exportXls = async getLastExports => {
		try {
			await Axios.get(`${config.get('apiUrl')}export/relatorios/performancePorEstabelecimento`, {
				headers: { Authorization: `Bearer ${token}` },
				responseType: 'blob',
				params: {
					...filtros,
				},
			});
			getLastExports();

			dispatch(addMessage({ type: 'warning', message: 'Sua nova exportação está sendo processada.' }));
		} catch (error) {
			console.log(error.response.data.text());
			const texto = await error.response.data.text();
			console.log(texto);
			dispatch(addMessage({ type: 'error', message: texto }));
		}
	};

	return (
		<>
			<Grid className={classes.vendas}>
				<div className={classes.header}>Performance Por Estabelecimento</div>
				<Grid item xs={12}>
					<Button
						variant="contained"
						size="small"
						className={classes.btn}
						onClick={() => history.push('/relatorios/sem-performance')}
					>
						Ver Sem Performance
					</Button>
				</Grid>
				<Paper
					style={{
						position: 'relative',
						marginBottom: 10,
						borderRadius: 4,
						padding: '5px 10px',
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<ZFiltros
						onChange={loading ? false : filtrosChange}
						dateRange={{
							default: 'custom',
							startDate: filtros.startDate,
							endDate: filtros.endDate,
						}}
						input={[
							{
								key: 'omni',
								defaultValue: filtros.omni,
								modal: false,
								placeholder: 'Estabelecimento',
								md: 2,
							},
							{
								key: 'searchEstabelecimentoByCpfOrCNPJ',
								defaultValue: filtros.searchEstabelecimentoByCpfOrCNPJ || '',
								placeholder: 'CPF/CNPJ',
								mask: 'documento',
								modal: false,
								md: 2,
							},
							{
								key: 'nomeComprovante',
								defaultValue: filtros.nomeComprovante || '',
								placeholder: 'Nome do Comprovante',
								md: 2,
							},
						]}
					/>
					<Tooltip title="Exportar">
						<Button className={clsx(classes.buttonExport, classes.iconButton)} onClick={exportXlsClick}>
							<FaDownload className={classes.exportDownload} />
						</Button>
					</Tooltip>
				</Paper>

				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Paper style={{ padding: 15 }}>
							{loading ? (
								<ZLoader height={200} />
							) : (
								<>
									{data.length > 0 ? (
										<Grid container className={classes.vendas}>
											<Grid container item className={classes.tableHeader}>
												<Grid item xs={3}>
													Estabelecimento
												</Grid>
												<Grid item xs>
													Crédito
												</Grid>
												<Grid item xs>
													Débito
												</Grid>
												<Grid item xs>
													Boleto
												</Grid>
												<Grid item xs>
													Aprovado
												</Grid>
												<Grid item xs>
													Quantidade Vendas
												</Grid>
												<Grid item xs>
													Ticket Médio
												</Grid>
												<Grid item xs>
													Quantidade POS
												</Grid>
											</Grid>
											{data.map((estabelecimento, key) => (
												<React.Fragment key={key}>
													<Grid container item className={clsx(classes.tableRow)}>
														<Grid item xs={3}>
															<div className={classes.noWrap}>
																{estabelecimento.nomeFantasia}
															</div>
															<div className={classes.noWrap}>
																<small>{estabelecimento.nomeComprovante}</small>
															</div>
														</Grid>
														<Grid item xs className={classes.numberValue}>
															{Formatter.Real(estabelecimento.valorTotalCredito)}
														</Grid>
														<Grid item xs className={classes.numberValue}>
															{Formatter.Real(estabelecimento.valorTotalDebito)}
														</Grid>
														<Grid item xs className={classes.numberValue}>
															{Formatter.Real(estabelecimento.valorTotalBoleto)}
														</Grid>
														<Grid
															item
															xs
															style={
																estabelecimento.valorTotalAprovado > 0
																	? { color: '#4caf50' }
																	: { color: '#f44336' }
															}
															// style={{ color: '#4caf50' }}
															className={classes.numberValue}
														>
															{Formatter.Real(estabelecimento.valorTotalAprovado)}
														</Grid>
														<Grid item xs style={{ textAlign: 'center' }}>
															{estabelecimento.quantidadeTotalVendas}
														</Grid>
														<Grid
															item
															xs
															style={
																estabelecimento.ticketMedio > 0
																	? { color: '#4caf50' }
																	: { color: '#f44336' }
															}
															//style={{ color: '#4caf50' }}
															className={classes.numberValue}
														>
															{Formatter.Real(estabelecimento.ticketMedio)}
														</Grid>
														<Grid item xs style={{ textAlign: 'center' }}>
															{estabelecimento.quantidadePOS}
														</Grid>
													</Grid>
												</React.Fragment>
											))}
										</Grid>
									) : (
										<ZEmpty height={200} text="Nenhum Resultado Encontrado." />
									)}
								</>
							)}
						</Paper>

						<ZPaginationDiv
							count={totalRows}
							rowsPerPage={rowsPerPage}
							page={page - 1}
							onChangePage={onChangePage}
							onChangeRowsPerPage={onChangeRowsPerPage}
							rowsPerPageOptions={[50, 100, 200]}
						/>
					</Grid>
				</Grid>
			</Grid>

			<Dialog open={showExportMessage}>
				<DialogContent style={{ width: 200 }}>
					<ZLoader height={100} size={26} message={'Exportando'} />
				</DialogContent>
			</Dialog>

			{showLastExport && (
				<LastExport
					show={showLastExport}
					onClose={() => setShowLastExport(false)}
					url={'/export/relatorios/performancePorEstabelecimento'}
					newExport={newExport}
					loadingProp={loading}
				/>
			)}
		</>
	);
};
