import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Formatter, Isset } from '../../utils';
import axios from 'axios';
import config from 'react-global-configuration';
import queryString from 'query-string';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import LastExport from '../Export/LastExport';

import { Button, Grid, Paper, TablePagination, Tooltip } from '@material-ui/core';
import { FaDownload } from 'react-icons/fa';

import format from 'date-fns/format';
// import addDays from 'date-fns/addDays';
import addMonths from 'date-fns/addMonths';
import pt from 'date-fns/locale/pt';
import { addMessage } from '../../store/alert';

import ZFiltros from '../system/ZFiltros';
import ZLoader from '../system/ZLoader';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
	extrato: {
		width: '100%',
		flexGrow: 1,
	},
	header: {
		fontWeight: 700,
		fontSize: 20,
		margin: 8,
	},
	filtros: {
		position: 'relative',
		borderRadius: 4,
		marginBottom: 10,
		padding: '5px 10px',
		display: 'flex',
		alignItems: 'center',
	},
	exportDownload: {
		color: '#011D29',
		fontSize: 16,
		marginLeft: 8,
	},
	iconButton: {
		minWidth: 0,
	},
	buttonExport: {
		color: '#040633',
		border: `solid 1px #040633`,
		'&:hover': {
			color: '#FFF',
			backgroundColor: '#011d29',
			'& svg': {
				color: '#FFF',
			},
		},
		[theme.breakpoints.down('xs')]: {
			width: '90%',
			marginTop: 16,
		},
		'& svg': {
			margin: 0,
		},
	},
	tableHeader: {
		textAlign: 'center',
		fontWeight: 700,
		fontSize: 16,
		margin: 0,
	},
	tableDateRow: {
		fontWeight: 700,
		padding: 10,
		display: 'flex',
		alignItems: 'center',
		borderRadius: 10,
		backgroundColor: `${theme.palette.primary.main}aa`,
		color: theme.palette.getContrastText(theme.palette.primary.main),
		paddingLeft: 20,
		marginTop: 10,
		marginBottom: 10,
	},
	tableRow: {
		textAlign: 'center',
		fontWeight: 400,
		padding: 15,
		display: 'flex',
		alignItems: 'center',
		borderColor: '#d9d7d7',
		border: '1px solid',
		marginBottom: 8,
		marginTop: 8,
		borderRadius: 10,
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}2b`,
		},
		background: '#fff',
	},
	negativo: {
		color: `#ff5440 !important`,
	},
	empty: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: 18,
		fontWeight: 700,
		height: 200,
	},
	hideOnXs: {
		[theme.breakpoints.down('xs')]: {
			display: 'none',
		},
	},
}));

export default props => {
	// const { history, location } = useReactRouter();
	const history = useHistory();
	const location = history.location;
	const [rowCount, setRowCount] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(100);
	const [currentPage, setCurrentPage] = useState(0);

	const query = queryString.parse(location.search);

	const queryFilter = {
		date: { start: new Date(), end: addMonths(new Date(), 6) },
		tipoTransacao: '',
	};

	if (Isset(() => query.tt)) {
		switch (+query.tt) {
			case 1:
				queryFilter.tipoTransacao = 'venda';
				break;
			case 2:
				queryFilter.tipoTransacao = 'transferencia';
				break;
			default:
		}
	}

	if (Isset(() => query.d)) {
		switch (query.d) {
			case 'lastMonth':
				queryFilter.date = { default: query.d, start: addMonths(new Date(), -1), end: new Date() };
				break;
			case 'next6months':
				queryFilter.date = { default: query.d, start: new Date(), end: addMonths(new Date(), 6) };
				break;
			default:
		}
	}

	const classes = useStyles();

	const dispatch = useDispatch();

	const token = useSelector(state => state.auth.token);
	const usuario = useSelector(state => state.auth.usuario);
	const isAdmin = usuario.grupo_id === 1;
	const { id: estabelecimentoId } = useSelector(state => state.admin.estabelecimento) || {};
	const [lancamentos, setLancamentos] = useState([]);
	const [filtros, setFiltros] = useState(queryFilter);
	const [totais, setTotais] = useState({});
	const [loading, setLoading] = useState(true);
	const [showExportMessage, setShowExportMessage] = useState(false);
	const [lastDate, setLastDate] = useState(null);

	useEffect(() => {
		const getLancamentos = async () => {
			setLoading(true);
			const lancamentos = await axios.get(
				`${config.get('apiUrl')}estabelecimentos/proximos-lancamentos?page=${currentPage}&limit=${rowsPerPage}`,
				{
					headers: { Authorization: `Bearer ${token}` },
					params: {
						filtros: {
							...filtros,
							estabelecimentoId,
						},
					},
				}
			);

			if (lancamentos.data.success) {
				const result = [];
				const t = {};
				let currentLastDate = lastDate;
				lancamentos.data.lancamentos.forEach(v => {
					const date = format(v.data, 'YYYY-MM-DD');
					if (currentLastDate !== date) {
						currentLastDate = date;
						result.push({ type: 'date', date });
					}

					if (!Isset(() => t[date])) {
						t[date] = {
							bruto: 0,
							liquido: 0,
						};
					}

					v.type = 'row';
					result.push(v);

					t[date].bruto += +v.valor;
					t[date].liquido += +v.valor_liquido;
				});

				if (result.length > 0) {
					const lastResult = result[result.length - 1];
					let lastSaldoId = Object.keys(lancamentos.data.saldo)
						.filter(i => format(i, 'YYYYMMDD') < format(lastResult.data, 'YYYYMMDD'))
						.pop();
					let lastSaldo = lancamentos.data.saldo[lastSaldoId];
					result.reverse().forEach(v => {
						if (v.type === 'date') {
							const date = format(v.date, 'YYYY-MM-DD');
							const saldo = lancamentos.data.saldo[date] || lastSaldo;
							v.saldo = saldo;
							lastSaldo = saldo;
						}
					});
				}
				setLastDate(currentLastDate);
				setTotais(t);
				setLancamentos(result.reverse());
			} else {
				setLancamentos([]);
			}
			setLoading(false);
		};

		getLancamentos();
	}, [filtros, estabelecimentoId, currentPage, rowsPerPage, lastDate, token]);

	useEffect(() => {
		const getPagination = async () => {
			const lancamentos = await axios.get(
				`${config.get(
					'apiUrl'
				)}estabelecimentos/proximos-lancamentos-paginacao?page=${currentPage}&limit=${rowsPerPage}`,
				{
					headers: { Authorization: `Bearer ${token}` },
					params: {
						filtros: {
							...filtros,
							estabelecimentoId,
						},
					},
				}
			);
			if (lancamentos.data.success) {
				setRowCount(lancamentos.data.pagination.rows);
			}
		};

		getPagination();
	}, [filtros, estabelecimentoId, currentPage, rowsPerPage, token]);

	const filtrosChange = data => {
		setFiltros(data);
	};

	const exportXls = async getLastExports => {
		try {
			await axios.get(`${config.get('apiUrl')}export/lancamentosFuturos`, {
				headers: { Authorization: `Bearer ${token}` },
				responseType: 'blob',
				params: {
					filtros: {
						...filtros,
						estabelecimentoId,
					},
				},
			});
			getLastExports();

			dispatch(addMessage({ type: 'warning', message: 'Sua nova exportação está sendo processada.' }));
		} catch (error) {
			console.log(error.response.data.text());
			const texto = await error.response.data.text();
			console.log(texto);
			dispatch(addMessage({ type: 'error', message: texto }));
		}
	};

	const [showLastExport, setShowLastExport] = useState(false);

	const newExport = async getLastExports => {
		await exportXls(getLastExports);
	};
	const exportXlsClick = async () => {
		// if (totalRows > 20000) {
		setShowLastExport(true);
		// } else {
		// 	await exportXls();
		// }
	};
	return (
		<div className={classes.extrato}>
			<div className={classes.header}>Próximos lançamentos</div>

			<Paper>
				<div className={classes.filtros}>
					<ZFiltros
						onChange={filtrosChange}
						dateRange={{
							default: filtros.date.default,
							startDate: filtros.date.start,
							endDate: filtros.date.end,
						}}
					/>
					<Tooltip title="Exportar">
						<Button className={clsx(classes.buttonExport, classes.iconButton)} onClick={exportXlsClick}>
							<FaDownload className={classes.exportDownload} />
						</Button>
					</Tooltip>
				</div>
			</Paper>

			{/*<Paper style={{ padding: 15 }}>*/}
			<div className={classes.grid}>
				{loading ? (
					<ZLoader height={200} message="Carregando Próximos Lançamentos" />
				) : (
					<>
						{lancamentos.length > 0 ? (
							<Grid container className={classes.lancamentos}>
								{/*<Grid container item className={classes.tableHeader}>
									<Grid item xs={2} sm={1}>
										Tipo
									</Grid>
									<Grid item xs={2}>
										Método
									</Grid>
									<Grid item xs>
										Cliente
									</Grid>
									{isAdmin && (
										<Grid item xs>
											Estabelecimento
										</Grid>
									)}
									<Grid item xs={1}>
										Valor
									</Grid>
									{isAdmin ? (
										<>
											<Grid item xs={1} className={classes.hideOnXs}>
												Taxa
											</Grid>
											<Grid item xs={1} className={classes.hideOnXs}>
												Split
											</Grid>
										</>
									) : (
										<Grid item xs={1} className={classes.hideOnXs}>
											Taxa
										</Grid>
									)}
									<Grid item xs={1} className={classes.hideOnXs}>
										Valor Líquido
									</Grid>
									</Grid>*/}
								{lancamentos.map((lancamento, key) => {
									return (
										<React.Fragment key={key}>
											{lancamento.type === 'date' ? (
												<Grid container item className={classes.tableDateRow}>
													<Grid item xs={8}>
														{format(lancamento.date, 'DD [de] MMMM [de] YYYY', {
															locale: pt,
														})}
													</Grid>
													<Grid item xs={1} style={{ textAlign: 'center' }}>
														{Isset(() => totais[lancamento.date].bruto)
															? Formatter.Real(totais[lancamento.date].bruto)
															: ''}
													</Grid>
													<Grid item xs={2}></Grid>
													<Grid item xs={1} style={{ textAlign: 'center' }}>
														{Isset(() => totais[lancamento.date].liquido)
															? Formatter.Real(totais[lancamento.date].liquido)
															: ''}
													</Grid>
												</Grid>
											) : (
												<Grid
													container
													item
													className={classes.tableRow}
													onClick={event => {
														event.preventDefault();
														const url =
															lancamento.tipoId === 2
																? '/transferencias/detalhes/' + lancamento.id
																: '/vendas/detalhes/' + lancamento.pedidoId;
														window.open(url, '_blank');
													}}
												>
													<Grid item xs={2} sm={1}>
														<p className={classes.tableHeader}>Tipo</p>
														{lancamento.tipo}
													</Grid>
													<Grid item xs={2} className={classes.truncate}>
														<p className={classes.tableHeader}>Método</p>
														{lancamento.metodo}
													</Grid>
													<Grid item xs className={classes.truncate}>
														<p className={classes.tableHeader}>Cliente</p>
														{Isset(() => lancamento.cliente.nome)
															? lancamento.cliente.nome
															: ''}
													</Grid>
													{isAdmin && (
														<Grid
															item
															xs
															className={classes.truncate}
															/*style={{ overflow: 'hidden', maxHeight: 40 }}*/
														>
															<p className={classes.tableHeader}>Estabelecimento</p>
															{lancamento.estabelecimento}
														</Grid>
													)}
													<Grid
														item
														xs={1}
														className={clsx({
															[classes.negativo]: lancamento.tipoId === 2,
														})}
													>
														<p className={classes.tableHeader}>Valor</p>
														{Formatter.Real(lancamento.valor)}
													</Grid>
													{isAdmin ? (
														<>
															<Grid item xs={1} className={classes.hideOnXs}>
																<p className={classes.tableHeader}>Taxa</p>
																{Formatter.Real(lancamento.taxa)}
															</Grid>
															<Grid item xs={1} className={classes.hideOnXs}>
																<p className={classes.tableHeader}>Split</p>
																{Formatter.Real(lancamento.split)}
															</Grid>
														</>
													) : (
														<Grid item xs={1} className={classes.hideOnXs}>
															<p className={classes.tableHeader}>Taxa</p>
															{Formatter.Real(+lancamento.taxa + +lancamento.split)}
														</Grid>
													)}
													<Grid
														item
														xs={1}
														className={clsx(classes.hideOnXs, {
															[classes.negativo]: lancamento.tipoId === 2,
														})}
													>
														<p className={classes.tableHeader}>Líquido</p>
														{Formatter.Real(lancamento.valor_liquido)}
													</Grid>
												</Grid>
											)}
										</React.Fragment>
									);
								})}
							</Grid>
						) : (
							<div className={classes.empty}>Nenhum lançamento encontrado.</div>
						)}
					</>
				)}
			</div>
			<TablePagination
				rowsPerPageOptions={[30, 60, 100]}
				component="div"
				count={rowCount}
				labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
				labelRowsPerPage={'Resultados por página:'}
				rowsPerPage={rowsPerPage}
				page={currentPage}
				onChangePage={(event, newPage) => setCurrentPage(newPage)}
				onChangeRowsPerPage={e => setRowsPerPage(e.target.value)}
			/>
			{/*</Paper>*/}

			<Dialog open={showExportMessage}>
				<DialogContent style={{ width: 200 }}>
					<ZLoader height={100} size={26} message={'Exportando'} />
				</DialogContent>
			</Dialog>

			{showLastExport && (
				<LastExport
					show={showLastExport}
					onClose={() => setShowLastExport(false)}
					url={'/export/lancamentosFuturos'}
					newExport={newExport}
					loadingProp={loading}
				/>
			)}
		</div>
	);
};
