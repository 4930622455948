/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ZLoader from '../system/ZLoader';
import Axios from 'axios';
import config from 'react-global-configuration';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';
import format from 'date-fns/format';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { isWhite } from '../../utils';

const useStyles = makeStyles(theme => ({
	dialog: {},
	title: {
		marginTop: 20,
		textAlign: 'center',
		fontSize: 16,
		fontWeight: 'bold',
	},
	label: {
		marginBottom: 10,
	},
	downloadButton: {
		background: `${theme.palette.primary.main}67`,
		margin: 2,
		padding: 2,
	},
	errorButton: {
		background: '#ff9090',
		margin: 2,
		padding: 2,
	},
	newExport: {
		margin: 'auto',
		marginTop: 10,
		textAlign: 'center',
		borderColor: isWhite(theme.palette.primary.main) ? '#000' : theme.palette.primary.main,
		border: '1px solid',
		backgroundColor: `${theme.palette.primary.main}67`,
		color: isWhite(theme.palette.primary.main) ? '#000' : theme.palette.primary.main,
	},
	aguardandoExport: {
		margin: 'auto',
		marginTop: 10,
		textAlign: 'center',
		backgroundColor: '#FFF',
		color: isWhite(theme.palette.primary.main) ? '#000' : theme.palette.primary.main,
	},
	aguardandoExport: {
		margin: 'auto',
		marginTop: 10,
		textAlign: 'center',
		backgroundColor: '#FFF',
		color: isWhite(theme.palette.primary.main) ? '#000' : theme.palette.primary.main,
	},
}));

export default props => {
	const classes = useStyles();
	const token = useSelector(store => store.auth.token);

	const [loading, setLoading] = useState(true);
	const [lastExports, setLastExports] = useState([]);

	const getLastExports = async (showLoading = false) => {
		setLoading(showLoading);
		const res = await Axios.get(`${config.get('apiUrl')}export/getLastExports`, {
			headers: { Authorization: `Bearer ${token}` },
			params: { url: props.url },
		});

		if (res.data.success) {
			setLastExports(res.data.result);
		}
		setLoading(false);
	};

	useEffect(() => {
		getLastExports(true);

		const id = setInterval(getLastExports, 12000);

		return () => {
			clearInterval(id);
		};
	}, []);

	return (
		<Dialog open={props.show} onClose={props.onClose} maxWidth="sm" fullWidth>
			<DialogContent style={{ textAlign: 'center' }}>
				<Alert severity="warning">
					<AlertTitle>Atenção</AlertTitle>
					<p>
						Aqui você pode visualizar suas últimas exportações ou gerar uma <strong>Nova Exportação</strong>
					</p>
				</Alert>
				{loading ? (
					<ZLoader height={200} size={15} message={'Carregando'} />
				) : (
					<>
						<div className={classes.title}>Últimas Exportações</div>
						{lastExports.length > 0 ? (
							<>
								<div style={{ height: 300, overflowY: 'scroll' }}>
									{lastExports.map(e => {
										const range = e.date_range ? JSON.parse(e.date_range) : null;
										return (
											<>
												<Grid
													key={e.created}
													container
													justifyContent="center"
													alignItems="center"
													style={{
														borderBottom: 'solid 1px #d2d2d2',
														textAlign: 'center',
													}}
												>
													<Grid item xs={4}>
														<label style={{ fontWeight: 'bold' }}>Usuario</label>
														<p>{e.usuario.nome}</p>
													</Grid>
													<Grid item xs={4}>
														{range && <label style={{ fontWeight: 'bold' }}>Período</label>}
														<p>
															{range &&
																range.start &&
																format(range.start, 'DD/MM/YYYY') +
																	' - ' +
																	format(range.end, 'DD/MM/YYYY')}
															{range &&
																range.mes &&
																'Mês: ' + range.mes + ' Ano: ' + range.ano}
															{range &&
																range.estabelecimento &&
																'Filtro Estabelecimento : ' + range.estabelecimento}
														</p>
													</Grid>

													{/* <Grid item xs={2}>
														{(+e.size / 1000000).toFixed(2)} MB
													</Grid> */}
													<Grid
														style={{ display: 'flex', flexDirection: 'column' }}
														item
														xs={4}
													>
														<Button
															className={`${classes.downloadButton} ${
																e.error ? classes.errorButton : ''
															}`}
															onClick={() => window.open(e.url)}
															disabled={e.finished === 0 || e.error}
														>
															{e.error ||
																(e.finished === 1 && e.url === '' && 'Export Falhou')}
															{!e.error && e.finished === 0 && 'Aguardando'}
															{!e.error && e.finished === 1 && e.url && 'Download'}
														</Button>

														<small>{format(e.created, 'DD/MM/YYYY HH:mm')}</small>
													</Grid>
												</Grid>
											</>
										);
									})}
								</div>
							</>
						) : (
							<div className={classes.label}>Nenhuma Exportação Recente.</div>
						)}
					</>
				)}
				{props.newExport && !props.loadingProp ? (
					<Button
						className={classes.newExport}
						onClick={() => {
							if (!props.exportInProgress) props.newExport(getLastExports);
						}}
						disabled={props.exportInProgress}
					>
						{props.exportInProgress ? 'Aguardando dados...' : 'Nova Exportação'}
					</Button>
				) : (
					<Button disabled={true} className={classes.aguardandoExport}>
						Aguardando dados...
					</Button>
				)}
			</DialogContent>
		</Dialog>
	);
};
