import React, { useState, useEffect, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import config from 'react-global-configuration';

import axios from 'axios';
import clsx from 'clsx';

import IconButton from '@material-ui/core/IconButton';
import { FaDollarSign, FaPercentage } from 'react-icons/fa';
import { Isset } from '../../utils';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';

import ParametroInfo from '../Marketplace/ParametroInfo';
import ZLoader from '../system/ZLoader';

import { Mask } from '../../utils';
import { Button } from '@material-ui/core';
import { addMessage } from '../../store/alert';
import { getUserParams } from '../../store/system';

const useStyles = makeStyles(theme => ({
	parametros: {},
	parametro: {
		position: 'relative',
		padding: 18,
		display: 'flex',
		alignItems: 'center',
		height: '100%',
	},
	parametroTitulo: {
		display: 'flex',
		alignItems: 'center',
		// justifyContent: 'flex-end',
		fontWeight: 'bold',
		textAlign: 'center',
		fontSize: '12px',
	},
	actionButtons: {
		marginTop: 10,
		textAlign: 'center',
	},
	empty: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: 18,
		fontWeight: 700,
		height: 200,
	},
	moneyPercentValor: {
		'&.porcentagem input': {
			textAlign: 'center',
		},
	},
	moneyPercentIcone: {
		border: 'solid 1px #abc',
		marginRight: 2,
		marginLeft: 2,
		marginTop: 16,
		'&.selected': {
			backgroundColor: `${theme.palette.primary.main}`,
			color: theme.palette.getContrastText(theme.palette.primary.main),
		},
	},
}));

export default memo(props => {
	const { estabelecimentoId } = props;
	const dispatch = useDispatch();

	const classes = useStyles();

	const token = useSelector(store => store.auth.token);
	const usuario = useSelector(state => state.auth.usuario);
	const operarMarketplace = useSelector(store =>
		Isset(() => store.system.params['4'].valor) ? store.system.params['4'].valor : null
	);

	const [loading, setLoading] = useState(true);
	const [saving, setSaving] = useState(false);
	const [parametros, setParametros] = useState([]);

	useEffect(() => {
		const getParametros = async () => {
			setLoading(true);
			const res = await axios.get(`${config.get('apiUrl')}parametros/por_estabelecimento/${estabelecimentoId}`, {
				headers: { Authorization: `Bearer ${token}` },
			});
			console.log(usuario.estabelecimento)
			if (res.data.success) {
				let parametros = res.data.parametros.filter(parametro => {
					if (usuario.estabelecimento.parent || (operarMarketplace && estabelecimentoId === usuario.estabelecimento.id)) {
						return parametro.titulo !== 'Operar como Marketplace?';
					}

					return true;
				});

				parametros = parametros.filter(parametro => {
					if (operarMarketplace && (usuario.estabelecimento.parent || estabelecimentoId === usuario.estabelecimento.id)) {
						return parametro.titulo !== 'Taxa de Venda Online';
					}

					return true;
				});

				parametros = parametros.filter(parametro => {
					if (operarMarketplace && (usuario.estabelecimento.parent || estabelecimentoId === usuario.estabelecimento.id)) {
						return parametro.titulo !== 'Valor Mínimo para Análise';
					}

					return true;
				});

				setParametros(parametros);
			}
			setLoading(false);
		};

		getParametros();
	}, [estabelecimentoId, operarMarketplace, token, usuario.estabelecimento.id]);

	const handleChange = id => e => {
		const result = parametros.map(p => {
			if (p.id === id) {
				switch (p.tipo) {
					case 'moneyPercent': {
						p.valor = Mask.OnlyNumber(e.target.value) / 100;
						if (p.tipoValor === 'percent' && p.valor > 100) {
							p.valor = 100;
						}
						break;
					}
					case 'text': {
						p.valor = e.target.value;
						break;
					}
					case 'percent': {
						p.valor = Mask.OnlyNumber(e.target.value) / 100;
						break;
					}
					case 'money': {
						p.valor = Mask.OnlyNumber(e.target.value) / 100;
						break;
					}
					case 'boolean':
					default:
						p.valor = e.target.checked;
				}
			}
			return p;
		});
		setParametros(result);
	};

	const handleSubmit = async () => {
		setSaving(true);
		const res = await axios.post(
			`${config.get('apiUrl')}parametros/por_estabelecimento/${estabelecimentoId}`,
			{ parametros },
			{
				headers: { Authorization: `Bearer ${token}` },
			}
		);

		if (res.data.success) {
			dispatch(addMessage({ type: 'success', message: 'Parâmetros salvos com sucesso' }));
			dispatch(getUserParams(usuario));
		}
		setSaving(false);
	};

	const updateParametroTipoValor = (id, value) => {
		const result = parametros.map(p => {
			if (p.id === id) {
				p.tipoValor = value;
			}
			return p;
		});
		setParametros(result);
	};

	if (loading) {
		return <ZLoader height={200} />;
	}

	return (
		<div className={classes.parametros}>
			{parametros.length > 0 ? (
				<>
					<Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={2}>
						{parametros.map((a, i) => (
							<Grid key={a.titulo} item xs={12} sm={6} md={3}>
								<Paper className={classes.parametro}>
									<ParametroInfo title={a.descricao} />
									<Grid
										style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
										container
										spacing={2}
									>
										<Grid item className={classes.parametroTitulo}>
											{a.titulo.toUpperCase()}
										</Grid>
										<Grid item style={{ display: 'flex', alignItems: 'center' }} xs={8}>
											{a.tipo === 'boolean' && (
												<Switch checked={a.valor} onChange={handleChange(a.id)} />
											)}
											{a.tipo === 'money' && (
												<TextField className={clsx(classes.fullWidth, classes.moneyPercentValor, {
													porcentagem: a.tipoValor === 'money',
												})} value={Mask.Real(a.valor)} onChange={handleChange(a.id)} />
											)}
											{a.tipo === 'percent' && (
												<>
													<TextField
														className={clsx(classes.fullWidth, classes.moneyPercentValor, {
															porcentagem: a.tipoValor === 'percent',
														})}
														value={Mask.Porcentagem(a.valor)}
														onChange={handleChange(a.id)}
													/>
													<div>
														<FaPercentage style={{ fontSize: 14 }} />
													</div>
												</>
											)}
											{a.tipo === 'text' && (
												<TextField value={a.valor || ''} onChange={handleChange(a.id)} />
											)}
											{a.tipo === 'moneyPercent' && (
												<>
													<IconButton
														className={clsx(classes.moneyPercentIcone, {
															selected: a.tipoValor === 'money',
														})}
														onClick={() => updateParametroTipoValor(a.id, 'money')}
													>
														<FaDollarSign style={{ fontSize: 14 }} />
													</IconButton>
													<TextField
														value={
															a.tipoValor === 'money'
																? Mask.Real(a.valor)
																: Mask.Porcentagem(a.valor)
														}
														className={clsx(classes.fullWidth, classes.moneyPercentValor, {
															porcentagem: a.tipoValor === 'percent' || a.tipoValor === 'money',
														})}
														onChange={handleChange(a.id)}
														onFocus={e => {
															const length = e.target.value.length * 2;
															e.target.setSelectionRange(0, length);
														}}
													/>
													<IconButton
														className={clsx(classes.moneyPercentIcone, {
															selected: a.tipoValor === 'percent',
														})}
														onClick={() => updateParametroTipoValor(a.id, 'percent')}
													>
														<FaPercentage style={{ fontSize: 14 }} />
													</IconButton>
												</>
											)}
										</Grid>
									</Grid>
								</Paper>
							</Grid>
						))}
					</Grid>
					<div className={classes.actionButtons}>
						<Button disabled={saving} variant="contained" color="primary" onClick={handleSubmit}>
							{saving ? <ZLoader size={14} height={24} /> : 'Salvar'}
						</Button>
					</div>
				</>
			) : (
				<div className={classes.empty}>Nenhum Parâmetro Encontrado.</div>
			)}
		</div>
	);
});
