import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import RecentActors from '@material-ui/icons/RecentActors';
import { StoreMallDirectory, Loop } from '@material-ui/icons';
import Dashboard from '@material-ui/icons/Dashboard';
import { AiFillCreditCard } from 'react-icons/ai';
import MonetizationOn from '@material-ui/icons/MonetizationOn';
import AccountBox from '@material-ui/icons/AccountBox';
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { useSelector } from 'react-redux';
import { AiOutlinePercentage } from 'react-icons/ai';
import NovaTransferencia from '../Transferencias/NovaTransferencia';
import {
	FaChartLine,
	FaFileInvoiceDollar,
	FaClipboard,
	FaDollarSign,
	FaReceipt,
	FaFunnelDollar,
	FaMoneyBill,
} from 'react-icons/fa';
import { Isset } from '../../utils';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CalculadoraValorLiquido from '../Calculadoras/ValorLiquido';
import CalculadoraValorBruto from '../Calculadoras/ValorBruto';
import HabilitarPOS from '../POS/HabilitarPOS';

const useStyles = makeStyles(theme => ({
	menu: {
		'& .MuiListItemIcon-root': {
			minWidth: 40,
		},
		'& .MuiTypography-body1': {
			fontSize: 14,
			fontWeight: 600,
		},
	},
	rotateIcon: {
		'& .MuiListItemIcon-root .MuiSvgIcon-root': {
			transition: theme.transitions.create(['all'], {
				duration: theme.transitions.duration.complex,
			}),
		},
		'&:hover .MuiListItemIcon-root .MuiSvgIcon-root': {
			transform: 'rotate(180deg)',
		},
	},
	collapseListItem: {
		paddingLeft: 36,
		'& .MuiListItemIcon-root': {
			minWidth: 30,
		},
	},
	linkStyle: {
		color: 'black',
		textDecoration: 'none',
	},
}));

export default props => {
	const usuario = useSelector(store => store.auth.usuario);
	const isAdmin = usuario.grupo_id === 1;
	const isGerencial = usuario.grupo_id === 2;
	const isRepresentante = usuario.grupo_id === 5;
	const isEC = [2, 3].includes(usuario.grupo_id);
	// const systemConfig = useSelector(store => store.system.config);
	const { id: estabelecimentoId } = usuario.estabelecimento;
	const classes = useStyles();

	let isElo7 = usuario.marketplace.id === 129; //['www.portal-elo7.com.br', 'portal-elo7.com.br'].includes(systemConfig.hostname);
	let is7Pay = usuario.marketplace.id === 60;
	let isElo7Atendimento = usuario.marketplace.id === 129 && usuario.grupo_id === 12;

	if (usuario.estabelecimento.parent === null) {
		isElo7 = false;
	}

	const [showCalculadoraValorLiquido, setShowCalculadoraValorLiquido] = React.useState(false);
	const [showCalculadoraValorBruto, setShowCalculadoraValorBruto] = React.useState(false);

	const canSale = Isset(() => usuario.estabelecimento.config.can_sale)
		? !!+usuario.estabelecimento.config.can_sale
		: true;

	const operarMarketplace = useSelector(store =>
		Isset(() => store.system.params['4'].valor) ? store.system.params['4'].valor : null
	);
	const canCriarTransferencia = useSelector(store =>
		Isset(() => store.system.params['22'].valor) ? store.system.params['22'].valor : null
	);

	// const bankService = (usuario.marketplace.marketplaces_configuracoes.find((value) => value.tipo_configuracao_id === 19)) ? +usuario.marketplace.marketplaces_configuracoes.find((value) => value.tipo_configuracao_id === 19).valor : false;

	const bankService = useSelector(store => {
		return Isset(() => store.system.params['27'].valor) ? store.system.params['27'].valor : null;
	});

	const taxasRecorrentes = useSelector(store => {
		return Isset(() => store.system.params['29'].valor) ? store.system.params['29'].valor : null;
	});

	const linkPagamento = useSelector(store =>
		Isset(() => store.system.params['11'].valor) ? store.system.params['11'].valor : null
	);

	const recorrencia = useSelector(store =>
		Isset(() => store.system.params['13'].valor) ? store.system.params['13'].valor : null
	);

	const usuarios = useSelector(store =>
		Isset(() => store.system.params['15'].valor) ? store.system.params['15'].valor : null
	);

	const carnes = useSelector(store =>
		Isset(() => store.system.params['31'].valor) ? store.system.params['31'].valor : null
	);

	const antecipacaoSobDemanda = useSelector(store => {
		return Isset(() => store.system.params['38'].valor) ? store.system.params['38'].valor : null;
	});

	const habilitarLinkRepresentante = useSelector(store => {
		return Isset(() => store.system.params['41'].valor) ? store.system.params['41'].valor : null;
	});

	const [openTransferencia, setOpenTransferencia] = React.useState(false);
	const [openHabilitarPOS, setOpenHabilitarPOS] = React.useState(false);
	const [open, setOpen] = React.useState({
		extratos: false,
		estabelecimento: false,
		recorrencia: false,
		transferencia: false,
		vendaDigitada: false,
		simulador: false,
		relatorios: false,
	});

	const planoVendasRule = [1, 7, 10];
	if (usuario.grupo_id === 2 && usuario.estabelecimento.parent === null) {
		planoVendasRule.push(2);
	}

	const canView = {
		extrato: [1, 2, 3, 4, 10, 11, 13, 14, 15].includes(usuario.grupo_id),
		estabelecimentos: [1, 2, 4, 5, 7, 10, 11, 12, 13, 14, 15].includes(usuario.grupo_id),
		// simuladorTaxas: [1, 2, 3, 4, 5, 7, 10].includes(usuario.grupo_id),
		simuladorTaxas: isElo7 || is7Pay ? false : [1, 2, 3, 4, 7, 10].includes(usuario.grupo_id),
		habilitarPOS: operarMarketplace && [2, 1, 7, 10].includes(usuario.grupo_id),
		assinaturas: [1, 2, 3, 4, 10].includes(usuario.grupo_id) && recorrencia && canSale,
		usuarios: [1, 10, 11, 12, 13, 14, 15].includes(usuario.grupo_id) || (usuarios && usuario.grupo_id === 2),
		clientes: isElo7 ? false : [1, 2, 7, 10, 11, 12, 13, 14, 15].includes(usuario.grupo_id),
		linkPagamento:
			([1, 2, 3, 4, 10, 13, 14].includes(usuario.grupo_id) && linkPagamento && canSale) ||
			([5].includes(usuario.grupo_id) && linkPagamento && canSale && habilitarLinkRepresentante),
		vendas: [1, 2, 3, 4, 5, 7, 10, 11, 12, 13, 14, 15].includes(usuario.grupo_id) && canSale,
		transferencias: [1, 2, 5, 7, 10, 11, 12, 13, 14, 15].includes(usuario.grupo_id),
		planoVendas: planoVendasRule.includes(usuario.grupo_id),
		//[1, 7,10].includes(usuario.grupo_id)
		relatorios: [1, 2, 4, 5, 7, 10, 11, 12, 13, 14, 15].includes(usuario.grupo_id),
		criarTransfererencia: canCriarTransferencia && [1, 2].includes(usuario.grupo_id),
		comissoesRepresentante: [1, 10].includes(+usuario.grupo_id) || (+usuario.grupo_id === 2 && operarMarketplace),
		// antecipacoes: [9].includes(usuario.marketplace.id) && [1].includes(usuario.grupo_id),
		antecipacoes: antecipacaoSobDemanda && [1, 2, 7].includes(usuario.grupo_id),
		recebiveis: [1, 10].includes(usuario.grupo_id),
		bank_services: bankService && [1, 2, 7, 3, 10].includes(usuario.grupo_id),
		taxas_recorrentes: taxasRecorrentes && [1, 2, 7, 13, 14].includes(usuario.grupo_id) && operarMarketplace,
		carnes: carnes && [1, 2, 3, 7].includes(usuario.grupo_id),
		// preCadastro: [1, 2, 5, 7, 10].includes(usuario.grupo_id),
		preCadastro: [1, 2, 7, 10].includes(usuario.grupo_id),
		marketplacesFilhos: [1, 2].includes(usuario.grupo_id) && usuario.estabelecimento.parent === null,
	};
	const canViewRecorrencia = canView.assinaturas || canView.taxas_recorrentes;

	const onePay = usuario.marketplace.id === 8 && usuario.estabelecimento.id !== 782;
	if (onePay) {
		delete canView.usuarios;
		delete canView.assinaturas;
	}

	function handleClick(value) {
		setOpen({
			...open,
			[value]: !open[value],
		});
	}

	return (
		<>
			<div className={classes.menu}>
				<Divider />
				<List>
					{!isElo7Atendimento && (
						<Link onClick={() => props.closeMenu()} className={classes.linkStyle} to="/">
							<ListItem button key={5}>
								<ListItemIcon>
									<Dashboard />
								</ListItemIcon>
								<ListItemText primary={'Dashboard'} />
							</ListItem>
						</Link>
					)}
					{canView.estabelecimentos && operarMarketplace && (
						<>
							{canView.preCadastro ? (
								<React.Fragment>
									<ListItem button key={2} onClick={() => handleClick('estabelecimento')}>
										<ListItemIcon>
											<StoreMallDirectory />
										</ListItemIcon>
										<ListItemText primary={'Estabelecimentos'} />
										{open.estabelecimento ? <ExpandLess /> : <ExpandMore />}
									</ListItem>

									<Collapse in={open.estabelecimento} timeout="auto" unmountOnExit>
										<Link
											onClick={() => props.closeMenu()}
											className={classes.linkStyle}
											to="/estabelecimentos"
										>
											<List component="div" disablePadding>
												<ListItem button className={classes.collapseListItem}>
													<ListItemIcon>
														<KeyboardArrowRight />
													</ListItemIcon>
													<ListItemText primary="Todos" />
												</ListItem>
											</List>
										</Link>

										<>
											{canView.marketplacesFilhos && (
												<Link
													onClick={() => props.closeMenu()}
													className={classes.linkStyle}
													to="/estabelecimentos/marketplace-filho"
												>
													<List component="div" disablePadding>
														<ListItem button className={classes.collapseListItem}>
															<ListItemIcon>
																<KeyboardArrowRight />
															</ListItemIcon>
															<ListItemText primary="Marketplaces Filhos" />
														</ListItem>
													</List>
												</Link>
											)}
										</>

										<Link
											onClick={() => props.closeMenu()}
											className={classes.linkStyle}
											to="/estabelecimentos/listar-pre-cadastro"
										>
											<List component="div" disablePadding>
												<ListItem button className={classes.collapseListItem}>
													<ListItemIcon>
														<KeyboardArrowRight />
													</ListItemIcon>
													<ListItemText primary="Pré Cadastro" />
												</ListItem>
											</List>
										</Link>
									</Collapse>
								</React.Fragment>
							) : (
								<Link
									onClick={() => props.closeMenu()}
									className={classes.linkStyle}
									to="/estabelecimentos"
								>
									<List component="div" disablePadding>
										<ListItem button>
											<ListItemIcon>
												<StoreMallDirectory />
											</ListItemIcon>
											<ListItemText primary="Estabelecimentos" />
										</ListItem>
									</List>
								</Link>
							)}
						</>
					)}
					{canView.clientes && (
						<Link onClick={() => props.closeMenu()} className={classes.linkStyle} to="/clientes">
							<ListItem button key={1}>
								<ListItemIcon>
									<AccountBox />
								</ListItemIcon>
								<ListItemText primary={'Clientes'} />
							</ListItem>
						</Link>
					)}
					{canView.usuarios && (
						<Link onClick={() => props.closeMenu()} className={classes.linkStyle} to="/usuarios">
							<ListItem button key={1}>
								<ListItemIcon>
									<AccountBox />
								</ListItemIcon>
								<ListItemText primary={'Usuários'} />
							</ListItem>
						</Link>
					)}
					{canView.planoVendas && (
						<Link onClick={() => props.closeMenu()} className={classes.linkStyle} to="/planos-de-vendas">
							<ListItem button key={1}>
								<ListItemIcon>
									<FaClipboard />
								</ListItemIcon>
								<ListItemText primary={'Planos de vendas'} />
							</ListItem>
						</Link>
					)}
					{canView.linkPagamento && (
						<Link
							onClick={() => props.closeMenu()}
							className={classes.linkStyle}
							to="/venda-digitada/todas"
						>
							<ListItem button key={2}>
								<ListItemIcon>
									<StoreMallDirectory />
								</ListItemIcon>
								<ListItemText primary={'Links gerados'} />
								{/* {open.vendaDigitada ? <ExpandLess /> : <ExpandMore />} */}
							</ListItem>
						</Link>
					)}
					{canViewRecorrencia && (
						<React.Fragment>
							<ListItem
								button
								className={classes.rotateIcon}
								key={2}
								onClick={() => handleClick('recorrencia')}
							>
								<ListItemIcon>
									<Loop />
								</ListItemIcon>
								<ListItemText primary={'Recorrência'} />
								{open.recorrencia ? <ExpandLess /> : <ExpandMore />}
							</ListItem>

							<Collapse in={open.recorrencia} timeout="auto" unmountOnExit>
								{canView.assinaturas && (
									<React.Fragment>
										<ListItem
											button
											className={classes.collapseListItem}
											key={2}
											onClick={() => handleClick('assinaturas')}
										>
											<ListItemIcon>
												{open.assinaturas ? <ExpandLess /> : <ExpandMore />}
											</ListItemIcon>
											<ListItemText primary={'Assinaturas'} />
											{/* {open.assinaturas ? <ExpandLess /> : <ExpandMore />} */}
										</ListItem>

										<Collapse in={open.assinaturas} timeout="auto" unmountOnExit>
											<Link
												onClick={() => props.closeMenu()}
												className={classes.linkStyle}
												to="/planos"
											>
												<List component="div" disablePadding>
													<ListItem button className={classes.collapseListItem}>
														<ListItemIcon>{/* <KeyboardArrowRight /> */}-</ListItemIcon>
														<ListItemText primary="Planos" />
													</ListItem>
												</List>
											</Link>
											<Link
												onClick={() => props.closeMenu()}
												className={classes.linkStyle}
												to="/assinaturas"
											>
												<List component="div" disablePadding>
													<ListItem button className={classes.collapseListItem}>
														<ListItemIcon>{/* <KeyboardArrowRight /> */}-</ListItemIcon>
														<ListItemText primary="Assinantes" />
													</ListItem>
												</List>
											</Link>
										</Collapse>
									</React.Fragment>
								)}

								{/* <Link onClick={() => props.closeMenu()} className={classes.linkStyle} to="/clientes">
					<ListItem button key={3}>
						<ListItemIcon>
							<RecentActors />
						</ListItemIcon>
						<ListItemText primary={'Clientes'} />
					</ListItem>
				</Link> */}

								{canView.taxas_recorrentes && (
									<Link
										onClick={() => props.closeMenu()}
										className={classes.linkStyle}
										to="/cobranca-recorrente"
									>
										<List component="div" disablePadding>
											<ListItem button className={classes.collapseListItem}>
												<ListItemIcon>
													<KeyboardArrowRight />
												</ListItemIcon>
												<ListItemText primary="Taxas Recorrentes" />
											</ListItem>
										</List>
									</Link>
								)}
							</Collapse>
						</React.Fragment>
					)}

					{canView.extrato &&
						(usuario.estabelecimento.marketplace.id !== 129 ||
							usuario.grupo_id === 1 ||
							usuario.grupo_id === 11 ||
							usuario.grupo_id === 13 ||
							usuario.grupo_id === 14 ||
							usuario.grupo_id === 15 ||
							usuario.estabelecimento.id === 46457 ||
							usuario.estabelecimento.id === 63434) && (
							<Link onClick={() => props.closeMenu()} className={classes.linkStyle} to="/extrato">
								<ListItem button key={5}>
									<ListItemIcon>
										<FaFileInvoiceDollar />
									</ListItemIcon>
									<ListItemText primary={'Extrato'} />
								</ListItem>
							</Link>
						)}

					{canView.vendas && (
						<>
							<Link onClick={() => props.closeMenu()} className={classes.linkStyle} to="/vendas">
								<ListItem button key={4}>
									<ListItemIcon>
										<FaDollarSign />
									</ListItemIcon>
									<ListItemText primary={'Vendas'} />
								</ListItem>
							</Link>
						</>
					)}

					{canView.transferencias && (
						<React.Fragment>
							<ListItem button key={2} onClick={() => handleClick('transferencia')}>
								<ListItemIcon>
									<StoreMallDirectory />
								</ListItemIcon>
								<ListItemText primary={'Transferências'} />
								{open.transferencia ? <ExpandLess /> : <ExpandMore />}
							</ListItem>
							<Collapse in={open.transferencia} timeout="auto" unmountOnExit>
								{canView.criarTransfererencia && (
									<span
										onClick={() => {
											setOpenTransferencia(true);
											props.closeMenu();
										}}
										className={classes.linkStyle}
									>
										<List component="div" disablePadding>
											<ListItem button className={classes.collapseListItem}>
												<ListItemIcon>
													<KeyboardArrowRight />
												</ListItemIcon>
												<ListItemText primary="Criar Transferência" />
											</ListItem>
										</List>
									</span>
								)}

								<Link
									onClick={() => props.closeMenu()}
									className={classes.linkStyle}
									to="/transferencias/agendadas"
								>
									<List component="div" disablePadding>
										<ListItem button className={classes.collapseListItem}>
											<ListItemIcon>
												<KeyboardArrowRight />
											</ListItemIcon>
											<ListItemText primary="Agendadas" />
										</ListItem>
									</List>
								</Link>
								<Link
									onClick={() => props.closeMenu()}
									className={classes.linkStyle}
									to="/transferencias"
								>
									<List component="div" disablePadding>
										<ListItem button className={classes.collapseListItem}>
											<ListItemIcon>
												<KeyboardArrowRight />
											</ListItemIcon>
											<ListItemText primary="Todas" />
										</ListItem>
									</List>
								</Link>
							</Collapse>
						</React.Fragment>
					)}
					{canView.antecipacoes && (
						<Link onClick={() => props.closeMenu()} className={classes.linkStyle} to="/antecipacoes">
							<ListItem button key={1}>
								<ListItemIcon>
									<FaReceipt />
								</ListItemIcon>
								<ListItemText primary={'Antecipações'} />
							</ListItem>
						</Link>
					)}

					{/* {operarMarketplace && [1, 2, 7].includes(usuario.grupo_id) && ([1, 30 , 20].includes(usuario.estabelecimento.marketplace.id)) && ( */}

					{canView.habilitarPOS && (
						<ListItem
							button
							key={1}
							onClick={() => {
								props.closeMenu();
								setOpenHabilitarPOS(true);
							}}
						>
							<ListItemIcon>
								<AiFillCreditCard size={21} />
							</ListItemIcon>
							<ListItemText primary={'Habilitar POS'} />
						</ListItem>
					)}

					{canView.carnes && (
						<Link onClick={() => props.closeMenu()} className={classes.linkStyle} to="/carnes">
							<ListItem button key={1}>
								<ListItemIcon>
									<FaMoneyBill />
								</ListItemIcon>
								<ListItemText primary={'Carnês'} />
							</ListItem>
						</Link>
					)}

					{canView.simuladorTaxas && (
						<React.Fragment>
							<ListItem
								button
								className={classes.rotateIcon}
								key={2}
								onClick={() => handleClick('simulador')}
							>
								<ListItemIcon>
									<AiOutlinePercentage size={22} />
								</ListItemIcon>
								<ListItemText primary={'Simulador de taxas'} />
								{open.simulador ? <ExpandLess /> : <ExpandMore />}
							</ListItem>

							<Collapse in={open.simulador} timeout="auto" unmountOnExit>
								<List
									component="div"
									disablePadding
									onClick={() => {
										props.closeMenu();
										setShowCalculadoraValorLiquido(true);
									}}
								>
									<ListItem button className={classes.collapseListItem}>
										<ListItemIcon>
											<KeyboardArrowRight />
										</ListItemIcon>
										<ListItemText primary="Valor Líquido" />
									</ListItem>
								</List>
								<List
									component="div"
									disablePadding
									onClick={() => {
										props.closeMenu();
										setShowCalculadoraValorBruto(true);
									}}
								>
									<ListItem button className={classes.collapseListItem}>
										<ListItemIcon>
											<KeyboardArrowRight />
										</ListItemIcon>
										<ListItemText primary="Valor Bruto" />
									</ListItem>
								</List>
							</Collapse>
						</React.Fragment>
					)}

					<ListItem button onClick={() => handleClick('relatorios')}>
						<ListItemIcon>
							<FaChartLine />
						</ListItemIcon>
						<ListItemText primary={'Relatórios'} />
						{open.relatorios ? <ExpandLess /> : <ExpandMore />}
					</ListItem>
					<Collapse in={open.relatorios} timeout="auto" unmountOnExit>
						{/* Subitens de "Relatórios" */}
						{canView.comissoesRepresentante && (
							<Link
								onClick={() => props.closeMenu()}
								className={classes.linkStyle}
								to="/representantes/comissoes"
							>
								<ListItem button key={1} className={classes.collapseListItem}>
									<ListItemIcon>
										<KeyboardArrowRight />
									</ListItemIcon>
									<ListItemText primary={'Comissões Representantes'} />
								</ListItem>
							</Link>
						)}
						{canView.recebiveis && (
							<Link onClick={() => props.closeMenu()} className={classes.linkStyle} to="/recebiveis">
								<ListItem button key={1} className={classes.collapseListItem}>
									<ListItemIcon>
										<KeyboardArrowRight />
									</ListItemIcon>
									<ListItemText primary={'Pagamentos'} />
								</ListItem>
							</Link>
						)}
						{(isAdmin || operarMarketplace) && (
							<Link
								onClick={() => props.closeMenu()}
								className={classes.linkStyle}
								to="/relatorios/vendasPorEstabelecimento"
							>
								<ListItem button className={classes.collapseListItem}>
									<ListItemIcon>
										<KeyboardArrowRight />
									</ListItemIcon>
									<ListItemText primary="Vendas por Estabelecimento" />
								</ListItem>
							</Link>
						)}

						{((usuario.marketplace.id !== 129 &&
							usuario.grupo_id !== 11 &&
							usuario.grupo_id !== 12 &&
							usuario.grupo_id !== 13 &&
							usuario.grupo_id !== 14) ||
							(isAdmin && usuario.marketplace.id === 129)) && (
							<Link
								onClick={() => props.closeMenu()}
								className={classes.linkStyle}
								to="/relatorios/vendasPorPOS"
							>
								<ListItem button className={classes.collapseListItem}>
									<ListItemIcon>
										<KeyboardArrowRight />
									</ListItemIcon>
									<ListItemText primary={'Vendas por POS'} />
								</ListItem>
							</Link>
						)}

						{(isAdmin || operarMarketplace) && (
							<Link
								onClick={() => props.closeMenu()}
								className={classes.linkStyle}
								to="/relatorios/markupPorEstabelecimento"
							>
								<ListItem button className={classes.collapseListItem}>
									<ListItemIcon>
										<KeyboardArrowRight />
									</ListItemIcon>
									<ListItemText primary={'MarkUp Por Estabelecimento'} />
								</ListItem>
							</Link>
						)}

						{(isAdmin || isRepresentante || operarMarketplace) && (
							<Link
								onClick={() => props.closeMenu()}
								className={classes.linkStyle}
								to="/relatorios/performance"
							>
								<ListItem button className={classes.collapseListItem}>
									<ListItemIcon>
										<KeyboardArrowRight />
									</ListItemIcon>
									<ListItemText primary={'Performance Por Estabelecimento'} />
								</ListItem>
							</Link>
						)}

						<Link
							onClick={() => props.closeMenu()}
							className={classes.linkStyle}
							to="/relatorios/vendasPorDia"
						>
							<ListItem button className={classes.collapseListItem}>
								<ListItemIcon>
									<KeyboardArrowRight />
								</ListItemIcon>
								<ListItemText primary={'Vendas por Dia'} />
							</ListItem>
						</Link>

						{(isAdmin || operarMarketplace) && (
							<Link
								onClick={() => props.closeMenu()}
								className={classes.linkStyle}
								to="/relatorios/chargeback-por-estabelecimento"
							>
								<ListItem button className={classes.collapseListItem}>
									<ListItemIcon>
										<KeyboardArrowRight />
									</ListItemIcon>
									<ListItemText primary={'Chargeback Por Estabelecimento'} />
								</ListItem>
							</Link>
						)}

						{(isAdmin || (isGerencial && operarMarketplace)) && (
							<Link
								onClick={() => props.closeMenu()}
								className={classes.linkStyle}
								to="/relatorios/splits-por-estabelecimento"
							>
								<ListItem button className={classes.collapseListItem}>
									<ListItemIcon>
										<KeyboardArrowRight />
									</ListItemIcon>
									<ListItemText primary={'Splits Por Estabelecimento'} />
								</ListItem>
							</Link>
						)}

						{/* {(isAdmin || operarMarketplace) && (
							<Link
								onClick={() => props.closeMenu()}
								className={classes.linkStyle}
								to="/relatorios/saldo-por-estabelecimento"
							>
								<ListItem button className={classes.collapseListItem}>
									<ListItemIcon>
										<KeyboardArrowRight />
									</ListItemIcon>
									<ListItemText primary={'Saldo Por Estabelecimento'} />
								</ListItem>
							</Link>
						)} */}
					</Collapse>

					{canView.bank_services && (
						<React.Fragment>
							<ListItem button key={2} onClick={() => handleClick('bank_servies')}>
								<ListItemIcon>
									<AccountBalanceIcon />
								</ListItemIcon>
								<ListItemText primary={'Serviços Bancários'} />
								{open.bank_servies ? <ExpandLess /> : <ExpandMore />}
							</ListItem>
							<Collapse in={open.bank_servies} timeout="auto" unmountOnExit>
								<Link
									onClick={() => props.closeMenu()}
									className={classes.linkStyle}
									to="/servicos-bancarios/pagamento-boleto"
								>
									<List component="div" disablePadding>
										<ListItem button className={classes.collapseListItem}>
											<ListItemIcon>
												<KeyboardArrowRight />
											</ListItemIcon>
											<ListItemText primary="Pagamento de Boleto" />
										</ListItem>
									</List>
								</Link>

								<Link
									onClick={() => props.closeMenu()}
									className={classes.linkStyle}
									to="/servicos-bancarios/transferencia-bancaria"
								>
									<List component="div" disablePadding>
										<ListItem button className={classes.collapseListItem}>
											<ListItemIcon>
												<KeyboardArrowRight />
											</ListItemIcon>
											<ListItemText primary="Transferência Bancária" />
										</ListItem>
									</List>
								</Link>
							</Collapse>
						</React.Fragment>
					)}

					{/* {canView.relatorios && (
						<Link onClick={() => props.closeMenu()} className={classes.linkStyle} to="/relatorios">
							<ListItem button key={1}>
								<ListItemIcon>
									<FaChartLine />
								</ListItemIcon>
								<ListItemText primary={'Relatórios'} />
							</ListItem>
						</Link>
					)} */}
				</List>
				<Divider />

				{showCalculadoraValorLiquido && (
					<CalculadoraValorLiquido
						open={showCalculadoraValorLiquido}
						estabelecimentoId={estabelecimentoId}
						onClose={() => setShowCalculadoraValorLiquido(false)}
						showOnlineButton
					/>
				)}

				{showCalculadoraValorBruto && (
					<CalculadoraValorBruto
						open={showCalculadoraValorBruto}
						estabelecimentoId={estabelecimentoId}
						onClose={() => setShowCalculadoraValorBruto(false)}
						showOnlineButton
					/>
				)}

				{openTransferencia && <NovaTransferencia open={true} onClose={() => setOpenTransferencia(false)} />}
				{openHabilitarPOS && <HabilitarPOS open={true} onClose={() => setOpenHabilitarPOS(false)} />}
			</div>
		</>
	);
};
