/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Container } from '@material-ui/core';
import axios from 'axios';
import config from 'react-global-configuration';
import 'react-credit-cards/es/styles-compiled.css';

export default props => {
	const systemConfig = useSelector(store => store.system.config);
	const [urlBoletos, setUrlBoletos] = React.useState([]);
	let urlBoletosAux = [];

	const getData = async () => {
		for (const pedidoId of props.location.state.boletos) {
			let req = await axios.get(`${config.get('apiUrl')}vendas/${pedidoId}/publico`);
			if (req.data.success) {
				const venda = req.data.venda;
				urlBoletosAux = [...urlBoletosAux, venda.pagamentos[0].pagamentoBoleto.url_original];
			}
		}
		setUrlBoletos(urlBoletosAux);
	};

	useEffect(() => {
		getData();
	}, []);

	return (
		<Container maxWidth={'md'} id="section-to-print">
			<Grid container>
				{systemConfig.marketplaceConfig.marketplace_name === 'Banco Monetiza' && (
					<Grid item xs={12}>
						<img
							title="img empresa"
							alt="img empresa"
							src={
								'https://z-systems-bucket.s3-sa-east-1.amazonaws.com/files/system_config/others/BoletoMonetiza.jpeg'
							}
							style={{ width: '100%' }}
						/>
					</Grid>
				)}
				{urlBoletos.map(url => {
					return (
						<Grid item xs={12}>
							<iframe
								scrolling="no"
								frameBorder="0"
								title="boleto"
								style={{ width: '900px', display: 'table', height: 1200 }}
								src={url}
							/>
						</Grid>
					);
				})}
			</Grid>
		</Container>
	);
};
