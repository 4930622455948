import React from 'react';
import { Mask } from '../utils';

export const useMask = initialValues => {
	const [state, setState] = React.useState(initialValues);

	const handleMask = e => {
		const fieldName = e.target.getAttribute('name');
		const fieldValue = e.target.value.replace(/\D/g, '');
		const value = setMask({ fieldName, fieldValue });

		setState(currentState => ({ ...currentState, [fieldName]: value }));
	};

	const setMask = ({ fieldName, fieldValue }) => {
		const masks = {
			documento: fieldValue.length <= 11 ? Mask.Cpf(fieldValue) : Mask.Cnpj(fieldValue),
			cpf: Mask.Cpf(fieldValue),
			celular: Mask.Celular(fieldValue),
			cep: Mask.Cep(fieldValue),
		};

		return masks[fieldName];
	};

	return {
		handleMask,
		state,
	};
};
