import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import admin from './admin';
import alert from './alert';
import auth from './auth';
import system from './system';
import filters from './filters';

const reducers = combineReducers({
	admin,
	alert,
	auth,
	system,
	filters,
});

const rootReducer = (state, action) => {
	if (action.type === 'auth/LOGOUT') {
		state = {
			system: state.system,
		};
	}

	return reducers(state, action);
};

const Store = createStore(rootReducer, applyMiddleware(thunk));

export default Store;
