/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import config from 'react-global-configuration';

import { Isset, Mask } from '../../../utils';

import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
	input: {
		width: '100%',
		[theme.breakpoints.down('xs')]: {
			width: '100%',
		},
	},
	dividers: {
		margin: '4px 0',
	},
	title: {
		fontSize: 14,
		fontWeight: 'Bold',
	},
	select: {
		'& .MuiAutocomplete-input': {
			padding: '3px 10px !important',
		},
	},
}));

export default props => {
	const classes = useStyles();

	const token = useSelector(store => store.auth.token);
	const { id: estabelecimentoId } = useSelector(state => state.admin.estabelecimento) || {};

	let value = '';

	if (Isset(() => props.defaultValue) && props.defaultValue) {
		value = props.defaultValue;
	}

	const [estabelecimento, setEstabelecimento] = useState(value);
	const [ecs, setEcs] = useState([]);

	useEffect(() => {
		const checkEstabelecimento = estabelecimentos => {
			let auxEstabelecimento = estabelecimento;
			if (auxEstabelecimento) {
				if (!Array.isArray(auxEstabelecimento)) {
					auxEstabelecimento = [auxEstabelecimento];
				}

				estabelecimentos = estabelecimentos.map(a => +a.id);

				setEstabelecimento(
					auxEstabelecimento.filter(a => a && estabelecimentos.includes(a) && a !== estabelecimentoId)
				);
			}
		};

		const getMeusECs = async () => {
			let res = await axios.post(
				`${config.get('apiUrl')}graphql`,
				{
					query: `
					query Estabelecimentos {
						estabelecimentos${estabelecimentoId ? `(id: ${estabelecimentoId})` : ''} {
							id, nomeFantasia, documento
						}
					}
				`,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			if (res.status === 200 && Isset(() => res.data.data.estabelecimentos)) {
				const result = res.data.data.estabelecimentos.map(a => ({ ...a, id: `${a.id}` }));
				setEcs(result);
				checkEstabelecimento(result);
			}
		};

		getMeusECs();
	}, [estabelecimentoId]);

	const handleOnChange = (a, value) => {
		const event = { target: { value: estabelecimento.id } };

		if (Isset(() => value.id)) {
			event.target.value = value.id;

			estabelecimentoChange(event);
		} else {
			estabelecimentoClear();
		}
	};

	const estabelecimentoChange = e => {
		let value = e.target.value;

		setEstabelecimento(value);

		if (props.onChange) {
			props.onChange(value);
		}
	};

	const getOptionLabel = option =>
		option.nomeFantasia +
		` ` +
		(option.documento.length <= 11 ? Mask.Cpf(option.documento) : Mask.Cnpj(option.documento));

	const estabelecimentoClear = e => {
		value = '';

		setEstabelecimento(value);

		if (props.onChange) {
			props.onChange(value);
		}
	};

	return (
		<>
			<div>
				<p className={classes.title}>Estabelecimentos</p>

				<Autocomplete
					className={classes.select}
					options={ecs}
					getOptionLabel={getOptionLabel}
					onChange={handleOnChange}
					renderInput={params => (
						<TextField {...params} className={classes.input} size="small" variant="outlined" />
					)}
				/>
			</div>
		</>
	);
};
