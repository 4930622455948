import { useEffect, useState } from 'react';
import axios from 'axios';
import config from 'react-global-configuration';
import { useSelector } from 'react-redux';
import { Isset } from '../utils';

export function useEstabelecimento() {
	const [estabelecimento, setEstabelecimento] = useState();
	const [ecs, setEcs] = useState([]);
	const [state, setState] = useState({});
	const token = useSelector(state => state.auth.token);
	const { id: estabelecimentoId } = useSelector(state => state.admin.estabelecimento) || {};

	useEffect(() => {
		const getMeusECs = async () => {
			const res = await axios.post(
				`${config.get('apiUrl')}graphql`,
				{
					query: `
					query Estabelecimentos {
						estabelecimentos${estabelecimentoId ? `(id: ${estabelecimentoId})` : ''} {
							id, nomeFantasia, documento
						}
					}
				`,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			const { data } = res.data;

			if (res.status === 200 && Isset(() => data.estabelecimentos)) {
				const result = data.estabelecimentos.map(estabelecimento => ({
					...estabelecimento,
				}));
				setEcs(result);
			}
		};

		getMeusECs();
	}, [estabelecimentoId, token]);

	return {
		options: ecs,
		state,
		setState,
		estabelecimento,
		setEstabelecimento,
	};
}
