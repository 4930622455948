import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
// import Clientes from '../Clientes/Clientes';
// import ClientesAdicionar from '../Clientes/ClientesAdicionar';
import ClientesListagem from '../Clientes/ClientesListagem';
import ClientesEditar from '../Clientes/ClientesEditar';
import ConfigurarSplit from '../Estabelecimentos/ConfigurarSplit';
import Dashboard from '../Usuarios/Dashboard';
import DashboardRepresentante from '../Usuarios/DashboardRepresentante';
import DashboardBackoffice from '../Usuarios/DashboardBackoffice';
import Estabelecimentos from '../Estabelecimentos';
import EstabelecimentosDesabilitados from '../Estabelecimentos/Desabilitados';
import EstabelecimentosAdicionar from '../Estabelecimentos/EstabelecimentosAdicionar';
import EstabelecimentosConfiguracoes from '../Estabelecimentos/Configuracoes';
import EstabelecimentosDados from '../Estabelecimentos/Dados';
import PreEstabelecimentosDados from '../Estabelecimentos/PreCadastroDados';
import EstabelecimentosConfiguracoesGerais from '../Estabelecimentos/ConfiguracoesGerais';
import EstabelecimentosTaxas from '../Estabelecimentos/Taxas';
import Extrato from '../Extrato';
import MinhasVendas from '../Vendas/MinhasVendas';
import Planos from '../Planos/Planos';
import Assinaturas from '../Planos/Assinaturas';
import AssinaturasEditar from '../Planos/AssinaturasEditar';
import AssinaturasDetalhes from '../Planos/AssinaturasDetalhes';
import AssinaturasAlterarCartao from '../Planos/AssinaturasAlterarCartao';
import PlanosAdicionar from '../Planos/PlanosAdicionar';
import PlanosDetalhe from '../Planos/PlanosDetalhe';
import NovaVenda from '../Vendas/NovaVenda';
import PreCadastrar from '../Estabelecimentos/PreCadastrar';
import PreCadastro from '../Estabelecimentos/PreCadastro';
import ProximosLancamentos from '../Extrato/ProximosLancamentos';
import ListarTransferencias from '../Transferencias/ListarTransferencias';
import TransferenciasAgendadas from '../Transferencias/Agendadas';
import TransferenciasDetalhes from '../Transferencias/Detalhes';
//Relatorios
import Relatorios from '../Relatorios';
import RelatoriosVendasPorEstabelecimento from '../Relatorios/vendasPorEstabelecimento';
import RelatoriosVendasPorPOS from '../Relatorios/vendasPorPOS';
import RelatoriosMarkupPorEstabelecimento from '../Relatorios/markupPorEstabelecimento';
import RelatoriosPerformancePorEstabelecimento from '../Relatorios/performancePorEstabelecimento';
import RelatoriosVendasPorDia from '../Relatorios/vendasPorDia';
import RelatoriosChargebackPorEstabelecimento from '../Relatorios/chargebackPorEstabelecimento';
import RelatoriosSplitsPorEstabelecimento from '../Relatorios/splitsPorEstabelecimento';
import RelatoriosEstabelecimentosSemPerformance from '../Relatorios/estabelecimentosSemPerformance';
import RelatoriosSaldoPorEstabelecimento from '../Relatorios/saldoPorEstabelecimento';

import Usuarios from '../Usuarios/Usuarios';
import UsuariosGerenciar from '../Usuarios/UsuariosGerenciar';
import Vendas from '../Vendas/Vendas';
import CadastrarVendaDigitada from '../VendaDigitada/CadastrarVendaDigitada';
import ListarTodas from '../VendaDigitada/ListarTodas';
// import PagarPreVenda from '../PreVenda/PagarPreVenda';
import VendasDetalhes from '../Vendas/VendasDetalhes';
import ZAlert from '../system/ZAlert';
import ZReload from '../system/ZReload';
import ZNotFound from '../system/ZNotFound';
import MarketplaceConfiguracoes from '../Marketplace/Configuracoes';
import MarketplacePlanosTaxasAdd from '../Marketplace/PlanosTaxasAdd';
import MarketplacePlanosTaxasEdit from '../Marketplace/PlanosTaxasEdit';
import EstabelecimentoPlanosTaxasAdd from '../Estabelecimentos/PlanosTaxasAdd';
import EstabelecimentoPlanosTaxasEdit from '../Estabelecimentos/PlanosTaxasEdit';
import RepresentanteComissao from '../Representante/Comissao';
import RepresentantesComissoes from '../Representante/Listagem';
import UsuariosConfiguracoes from '../Usuarios/Configuracoes';

import CobrancaRecorrente from '../CobrancaRecorrente';
import CobrancaListagem from '../CobrancaRecorrente/CobrancaListagem';
import CobrancaGerenciar from '../CobrancaRecorrente/CobrancaGerenciar';

import PlanosDeVendas from '../PlanosDeVendas/list';
import PlanosDeVendasDetalhes from '../PlanosDeVendas/details';
import Boleto from '../Vendas/Boleto';

import ListarBoletoServicosBancarios from '../ServicosBancarios/listarBoletos';
import ListarTransferenciasBancarias from '../ServicosBancarios/listarTransferencias';

import Antecipacoes from '../Antecipacoes';
import Recebiveis from '../Recebiveis';
import Carnes from '../Carnes';
import CarneNew from '../Carnes/New';
import CarneView from '../Carnes/View';

import moment from 'moment';

import { addMessage } from '../../store/alert';
import { getGrupoPermission } from '../../utils';
import { Route, Switch } from 'react-router-dom';

const authorizedRoutes = [
	{ path: '/', exact: true, permissions: ['all'], redirect: '/login', component: Dashboard },
	{
		path: '/dashboard/representante',
		exact: true,
		permissions: ['representante'],
		redirect: '/login',
		component: DashboardRepresentante,
	},
	{
		path: '/dashboard/backoffice',
		exact: true,
		permissions: ['backoffice'],
		redirect: '/login',
		component: DashboardBackoffice,
	},
	{
		path: '/representante/comissao/:usuarioId',
		exact: true,
		permissions: ['admin', 'gerencial', 'moderador'],
		redirect: '/',
		component: RepresentanteComissao,
	},
	{
		path: '/representantes/comissoes',
		exact: true,
		permissions: ['admin', 'gerencial', 'moderador'],
		redirect: '/',
		component: RepresentantesComissoes,
	},
	{ path: '/reload', exact: true, permissions: ['all'], redirect: '/', component: ZReload },
	// { path: '/clientes', exact: true, permissions: ['all'], redirect: '/', component: Clientes },
	// { path: '/clientes/adicionar', exact: true, permissions: ['all'], redirect: '/', component: ClientesAdicionar },
	{
		path: '/clientes',
		exact: true,
		permissions: [
			'admin',
			'gerencial',
			'backoffice',
			'moderador',
			'perfilRisco',
			'riscoGerencial',
			'atendimento',
			'financeiroElo7',
			'financeiroGerencial',
		],
		redirect: '/',
		component: ClientesListagem,
	},
	{
		path: '/cliente/editar/:id',
		exact: true,
		permissions: ['admin', 'gerencial', 'backoffice', 'moderador'],
		redirect: '/',
		component: ClientesEditar,
	},
	// {
	// 	path: '/clientes/gerenciar/:clienteId',
	// 	exact: true,
	// 	permissions: ['all'],
	// 	redirect: '/',
	// 	component: ClientesAdicionar,
	// },
	{
		path: '/estabelecimentos',
		exact: true,
		permissions: [
			'admin',
			'gerencial',
			'representante',
			'backoffice',
			'representanteBasico',
			'moderador',
			'perfilRisco',
			'riscoGerencial',
			'atendimento',
			'financeiroElo7',
			'financeiroGerencial',
		],
		redirect: '/',
		component: Estabelecimentos,
	},
	{
		path: '/estabelecimentos/marketplace-filho',
		exact: true,
		permissions: [
			'admin',
			'gerencial',
			'representante',
			'backoffice',
			'representanteBasico',
			'moderador',
			'perfilRisco',
			'riscoGerencial',
			'atendimento',
			'financeiroElo7',
			'financeiroGerencial',
		],
		redirect: '/',
		component: Estabelecimentos,
	},
	{
		path: '/estabelecimentos/desabilitados',
		exact: true,
		permissions: [
			'admin',
			'backoffice',
			'moderador',
			'perfilRisco',
			'riscoGerencial',
			'atendimento',
			'financeiroElo7',
			'financeiroGerencial',
		],
		redirect: '/',
		component: EstabelecimentosDesabilitados,
	},
	{
		path: '/estabelecimentos/adicionar',
		exact: true,
		permissions: [
			'admin',
			'gerencial',
			'backoffice',
			'moderador',
			'representante',
			'perfilRisco',
			'riscoGerencial',
			'atendimento',
			'financeiroElo7',
			'financeiroGerencial',
		],
		redirect: '/',
		component: EstabelecimentosAdicionar,
	},
	{
		path: '/estabelecimentos/editar/:estabelecimentoId',
		exact: true,
		permissions: [
			'admin',
			'gerencial',
			'representante',
			'backoffice',
			'moderador',
			'perfilRisco',
			'riscoGerencial',
			'atendimento',
			'financeiroElo7',
			'financeiroGerencial',
		],
		redirect: '/',
		component: EstabelecimentosAdicionar,
	},
	{
		path: '/estabelecimentos/configuracoes',
		exact: true,
		permissions: ['all'],
		redirect: '/',
		component: EstabelecimentosConfiguracoes,
	},
	{
		path: '/estabelecimentos/visualizar/:estabelecimentoId',
		exact: true,
		permissions: [
			'representante',
			'representanteBasico',
			'moderador',
			'perfilRisco',
			'riscoGerencial',
			'atendimento',
			'financeiroElo7',
			'financeiroGerencial',
		],
		redirect: '/',
		component: EstabelecimentosDados,
	},
	{
		path: '/estabelecimentos/pre-cadastro/visualizar/:estabelecimentoId',
		exact: true,
		permissions: ['admin', 'gerencial', 'backoffice', 'moderador'],
		redirect: '/',
		component: PreEstabelecimentosDados,
	},
	{
		path: '/estabelecimentos/pre-cadastro/analisar/:estabelecimentoId',
		exact: true,
		permissions: ['admin', 'gerencial', 'backoffice', 'moderador'],
		redirect: '/',
		component: PreCadastrar,
	},
	{
		path: '/estabelecimentos/pre-cadastro/completar/:estabelecimentoId',
		exact: true,
		permissions: ['admin', 'gerencial', 'representante', 'moderador', 'backoffice'],
		redirect: '/',
		component: PreCadastrar,
	},
	{
		path: '/configuracoes',
		exact: true,
		permissions: ['all'],
		redirect: '/',
		component: UsuariosConfiguracoes,
	},
	{
		path: '/estabelecimentos/configurar-split/:estabelecimentoId',
		exact: true,
		permissions: ['admin', 'gerencial', 'moderador'],
		redirect: '/',
		component: ConfigurarSplit,
	},
	{
		path: '/estabelecimentos/configuracoes/:estabelecimentoId',
		exact: true,
		permissions: [
			'admin',
			'gerencial',
			'backoffice',
			'moderador',
			'representante',
			'financeiroElo7',
			'financeiroGerencial',
			'perfilRisco',
			'riscoGerencial',
		],
		redirect: '/',
		component: EstabelecimentosConfiguracoesGerais,
	},
	{
		path: '/estabelecimentos/taxas/:estabelecimentoId',
		exact: true,
		permissions: ['admin', 'gerencial', 'backoffice', 'moderador'],
		redirect: '/',
		component: EstabelecimentosTaxas,
	},
	{
		path: '/estabelecimentos/pre-cadastro',
		exact: true,
		permissions: ['admin', 'gerencial', 'representante', 'representanteBasico', 'moderador'],
		redirect: '/',
		component: PreCadastrar,
	},
	{
		path: '/estabelecimentos/listar-pre-cadastro',
		exact: true,
		permissions: ['admin', 'gerencial', 'representante', 'backoffice', 'moderador'],
		redirect: '/',
		component: PreCadastro,
	},
	{
		path: '/estabelecimentos/:estabelecimentoId/cobranca_recorrente',
		exact: true,
		permissions: ['admin', 'gerencial', 'backoffice', 'moderador'],
		redirect: '/',
		component: CobrancaRecorrente,
	},
	{
		path: '/estabelecimentos/cobranca_recorrente/:cobrancaId/editar',
		exact: true,
		permissions: ['admin', 'gerencial', 'backoffice', 'moderador'],
		redirect: '/',
		component: CobrancaGerenciar,
	},
	{
		path: '/estabelecimentos/cobranca_recorrente/:estabelecimentoId/adicionar',
		exact: true,
		permissions: ['admin', 'gerencial', 'backoffice', 'moderador'],
		redirect: '/',
		component: CobrancaGerenciar,
	},
	{
		path: '/extrato',
		exact: true,
		permissions: [
			'admin',
			'gerencial',
			'basico',
			'financeiro',
			'representante',
			'backoffice',
			'representanteBasico',
			'moderador',
			'perfilRisco',
			'riscoGerencial',
			'financeiroElo7',
			'financeiroGerencial',
		],
		redirect: '/',
		component: Extrato,
	},
	{
		path: '/proximos-lancamentos',
		exact: true,
		permissions: [
			'admin',
			'gerencial',
			'basico',
			'financeiro',
			'representante',
			'backoffice',
			'representanteBasico',
			'moderador',
			'perfilRisco',
			'riscoGerencial',
			'financeiroElo7',
			'financeiroGerencial',
		],
		redirect: '/',
		component: ProximosLancamentos,
	},
	{
		path: '/transferencias',
		exact: true,
		permissions: [
			'admin',
			'gerencial',
			'representante',
			'backoffice',
			'moderador',
			'perfilRisco',
			'riscoGerencial',
			'atendimento',
			'financeiroElo7',
			'financeiroGerencial',
		],
		redirect: '/',
		component: ListarTransferencias,
	},
	{
		path: '/transferencias/agendadas',
		exact: true,
		permissions: ['admin', 'gerencial', 'moderador'],
		redirect: '/',
		component: TransferenciasAgendadas,
	},
	{
		path: '/transferencias/detalhes/:transferenciaId',
		exact: true,
		permissions: [
			'admin',
			'gerencial',
			'backoffice',
			'representante',
			'moderador',
			'atendimento',
			'perfilRisco',
			'riscoGerencial',
			'financeiroElo7',
			'financeiroGerencial',
		],
		redirect: '/',
		component: TransferenciasDetalhes,
	},
	{
		path: '/usuarios',
		exact: true,
		permissions: [
			'admin',
			'gerencial',
			'moderador',
			'perfilRisco',
			'riscoGerencial',
			'atendimento',
			'financeiroElo7',
			'financeiroGerencial',
		],
		redirect: '/',
		component: Usuarios,
	},
	{
		path: '/usuarios/adicionar',
		exact: true,
		permissions: ['admin', 'gerencial', 'moderador'],
		redirect: '/',
		component: UsuariosGerenciar,
	},
	{
		path: '/usuarios/gerenciar/:usuarioId',
		exact: true,
		permissions: ['admin', 'gerencial', 'moderador'],
		redirect: '/',
		component: UsuariosGerenciar,
	},
	{
		path: '/planos',
		exact: true,
		permissions: [
			'admin',
			'gerencial',
			'basico',
			'financeiro',
			'representante',
			'backoffice',
			'representanteBasico',
			'moderador',
		],
		redirect: '/',
		component: Planos,
	},
	{
		path: '/assinaturas/detalhes/:assinaturaId',
		exact: true,
		permissions: [
			'admin',
			'gerencial',
			'basico',
			'financeiro',
			'representante',
			'backoffice',
			'representanteBasico',
			'moderador',
		],
		redirect: '/',
		component: AssinaturasDetalhes,
	},
	{
		path: '/assinaturas/alterar_cartao/:assinaturaId',
		exact: true,
		permissions: [
			'admin',
			'gerencial',
			'basico',
			'financeiro',
			'representante',
			'backoffice',
			'representanteBasico',
			'moderador',
		],
		redirect: '/',
		component: AssinaturasAlterarCartao,
	},
	{
		path: '/assinaturas/editar/:assinaturaId',
		exact: true,
		permissions: [
			'admin',
			'gerencial',
			'basico',
			'financeiro',
			'representante',
			'backoffice',
			'representanteBasico',
			'moderador',
		],
		redirect: '/',
		component: AssinaturasEditar,
	},
	{
		path: '/assinaturas',
		exact: true,
		permissions: [
			'admin',
			'gerencial',
			'basico',
			'financeiro',
			'representante',
			'backoffice',
			'representanteBasico',
			'moderador',
		],
		redirect: '/',
		component: Assinaturas,
	},
	{
		path: '/assinaturas/:planoId',
		exact: true,
		permissions: [
			'admin',
			'gerencial',
			'basico',
			'financeiro',
			'representante',
			'backoffice',
			'representanteBasico',
			'moderador',
		],
		redirect: '/',
		component: Assinaturas,
	},
	{
		path: '/planos/adicionar',
		exact: true,
		permissions: [
			'admin',
			'gerencial',
			'basico',
			'financeiro',
			'representante',
			'backoffice',
			'representanteBasico',
			'moderador',
		],
		redirect: '/',
		component: PlanosAdicionar,
	},
	{
		path: '/planos/editar/:planoId',
		exact: true,
		permissions: [
			'admin',
			'gerencial',
			'basico',
			'financeiro',
			'representante',
			'backoffice',
			'representanteBasico',
			'moderador',
			'atendimento',
			'financeiroElo7',
			'financeiroGerencial',
		],
		redirect: '/',
		component: PlanosAdicionar,
	},
	{
		path: '/planos/detalhe/:planoId',
		exact: true,
		permissions: [
			'admin',
			'gerencial',
			'basico',
			'financeiro',
			'representante',
			'backoffice',
			'representanteBasico',
			'moderador',
		],
		redirect: '/',
		component: PlanosDetalhe,
	},
	{
		path: '/venda-digitada',
		exact: true,
		permissions: [
			'admin',
			'gerencial',
			'basico',
			'financeiro',
			'representante',
			'backoffice',
			'representanteBasico',
			'moderador',
			'financeiroElo7',
			'financeiroGerencial',
		],
		redirect: '/',
		component: CadastrarVendaDigitada,
	},
	{
		path: '/venda-digitada/todas',
		exact: true,
		permissions: [
			'admin',
			'gerencial',
			'basico',
			'financeiro',
			'representante',
			'backoffice',
			'representanteBasico',
			'moderador',
			'financeiroElo7',
			'financeiroGerencial',
		],
		redirect: '/',
		component: ListarTodas,
	},
	{
		path: '/venda-digitada/:preVendaId',
		exact: true,
		permissions: [
			'admin',
			'gerencial',
			'basico',
			'financeiro',
			'representante',
			'backoffice',
			'representanteBasico',
			'moderador',
			'financeiroElo7',
			'financeiroGerencial',
		],
		redirect: '/',
		component: CadastrarVendaDigitada,
	},
	// { path: "/venda/:token", exact: true, permissions: ['all'], redirect: '/', component: PagarPreVenda },
	{ path: '/vendas', exact: true, permissions: ['all'], redirect: '/', component: Vendas },
	{ path: '/vendas/detalhes/:vendaId', exact: true, permissions: ['all'], redirect: '/', component: VendasDetalhes },
	{ path: '/vendas/me', exact: true, permissions: ['all'], redirect: '/', component: MinhasVendas },
	{ path: '/vendas/nova', exact: true, permissions: ['all'], redirect: '/', component: NovaVenda },
	{
		path: '/vendas/nova/:cpfDoCliente',
		exact: true,
		permissions: ['admin', 'gerencial', 'backoffice', 'moderador'],
		redirect: '/',
		component: NovaVenda,
	},
	{
		path: '/vendas/:vendaId/ver-boleto',
		exact: true,
		permissions: ['all'],
		redirect: '/',
		component: Boleto,
	},
	{
		path: '/relatorios',
		exact: true,
		permissions: [
			'admin',
			'gerencial',
			'financeiro',
			'backoffice',
			'representante',
			'moderador',
			'perfilRisco',
			'riscoGerencial',
			'atendimento',
			'financeiroElo7',
			'financeiroGerencial',
		],
		redirect: '/',
		component: Relatorios,
	},
	{
		path: '/relatorios/vendasPorEstabelecimento',
		exact: true,
		permissions: ['admin', 'gerencial', 'financeiro', 'backoffice', 'representante', 'moderador'],
		redirect: '/',
		component: RelatoriosVendasPorEstabelecimento,
	},
	{
		path: '/relatorios/vendasPorPOS',
		exact: true,
		permissions: ['admin', 'gerencial', 'financeiro', 'backoffice', 'representante', 'moderador'],
		redirect: '/',
		component: RelatoriosVendasPorPOS,
	},
	{
		path: '/relatorios/vendasPorDia',
		exact: true,
		permissions: [
			'admin',
			'gerencial',
			'financeiro',
			'backoffice',
			'representante',
			'moderador',
			'perfilRisco',
			'riscoGerencial',
			'financeiroElo7',
			'financeiroGerencial',
		],
		redirect: '/',
		component: RelatoriosVendasPorDia,
	},
	{
		path: '/relatorios/markupPorEstabelecimento',
		exact: true,
		permissions: ['admin', 'representante', 'gerencial', 'moderador', 'financeiroElo7', 'financeiroGerencial'],
		redirect: '/',
		component: RelatoriosMarkupPorEstabelecimento,
	},
	{
		path: '/relatorios/performance',
		exact: true,
		permissions: ['admin', 'gerencial', 'representante', 'moderador'],
		redirect: '/',
		component: RelatoriosPerformancePorEstabelecimento,
	},
	{
		path: '/relatorios/chargeback-por-estabelecimento',
		exact: true,
		permissions: ['admin', 'gerencial', 'financeiro', 'backoffice', 'representante', 'moderador'],
		redirect: '/',
		component: RelatoriosChargebackPorEstabelecimento,
	},
	{
		path: '/relatorios/splits-por-estabelecimento',
		exact: true,
		permissions: ['admin', 'gerencial'],
		redirect: '/',
		component: RelatoriosSplitsPorEstabelecimento,
	},
	{
		path: '/relatorios/saldo-por-estabelecimento',
		exact: true,
		permissions: ['admin', 'gerencial'],
		redirect: '/',
		component: RelatoriosSaldoPorEstabelecimento,
	},
	{
		path: '/relatorios/sem-performance',
		exact: true,
		permissions: ['admin', 'gerencial', 'financeiro', 'backoffice', 'representante', 'moderador'],
		redirect: '/',
		component: RelatoriosEstabelecimentosSemPerformance,
	},
	{
		path: '/marketplace/configuracoes',
		exact: true,
		permissions: ['admin', 'moderador'],
		redirect: '/',
		component: MarketplaceConfiguracoes,
	},
	{
		path: '/marketplace/:marketplaceId/planos-taxas/add',
		exact: true,
		permissions: ['admin', 'moderador'],
		redirect: '/',
		component: MarketplacePlanosTaxasAdd,
	},
	{
		path: '/estabelecimentos/:estabelecimentoId/planos-taxas/add',
		exact: true,
		permissions: ['admin', 'gerencial', 'moderador'],
		redirect: '/',
		component: EstabelecimentoPlanosTaxasAdd,
	},
	{
		path: '/marketplace/:marketplaceId/planos-taxas/:planoTaxaId',
		exact: true,
		permissions: ['admin', 'moderador'],
		redirect: '/',
		component: MarketplacePlanosTaxasEdit,
	},
	{
		path: '/cobranca-recorrente',
		exact: true,
		permissions: [
			'admin',
			'gerencial',
			'basico',
			'financeiro',
			'representante',
			'backoffice',
			'representanteBasico',
			'moderador',
			'financeiroElo7',
			'financeiroGerencial',
		],
		redirect: '/',
		component: CobrancaListagem,
	},
	{
		path: '/estabelecimentos/:estabelecimentoId/planos-taxas/:planoTaxaId',
		exact: true,
		permissions: ['admin', 'gerencial', 'moderador'],
		redirect: '/',
		component: EstabelecimentoPlanosTaxasEdit,
	},
	{
		path: '/planos-de-vendas',
		exact: true,
		permissions: [
			'admin',
			'gerencial',
			'basico',
			'financeiro',
			'representante',
			'backoffice',
			'representanteBasico',
			'moderador',
			'perfilRisco',
			'riscoGerencial',
			'financeiroElo7',
			'financeiroGerencial',
		],
		redirect: '/',
		component: PlanosDeVendas,
	},
	{
		path: '/planos-de-vendas/:id',
		exact: true,
		permissions: [
			'admin',
			'gerencial',
			'basico',
			'financeiro',
			'representante',
			'backoffice',
			'representanteBasico',
			'moderador',
			'perfilRisco',
			'riscoGerencial',
			'financeiroElo7',
			'financeiroGerencial',
		],
		redirect: '/',
		component: PlanosDeVendasDetalhes,
	},
	{
		path: '/servicos-bancarios/pagamento-boleto',
		exact: true,
		permissions: ['all'],
		redirect: '/',
		component: ListarBoletoServicosBancarios,
	},
	{
		path: '/servicos-bancarios/transferencia-bancaria',
		exact: true,
		permissions: ['all'],
		redirect: '/',
		component: ListarTransferenciasBancarias,
	},
	{
		path: '/antecipacoes',
		exact: true,
		permissions: ['admin', 'gerencial', 'moderador'],
		redirect: '/',
		component: Antecipacoes,
	},
	{
		path: '/recebiveis',
		exact: true,
		permissions: ['admin', 'moderador'],
		redirect: '/',
		component: Recebiveis,
	},
	{
		path: '/carnes',
		exact: true,
		permissions: ['admin', 'gerencial', 'moderador', 'basico'],
		redirect: '/',
		component: Carnes,
	},
	{
		path: '/carnes/new',
		exact: true,
		permissions: ['admin', 'gerencial', 'moderador', 'basico'],
		redirect: '/',
		component: CarneNew,
	},
	{
		path: '/carnes/:id',
		exact: true,
		permissions: ['admin', 'gerencial', 'moderador', 'basico'],
		redirect: '/',
		component: CarneView,
	},
];

// const normalRoutes = [
// 	{ path: '/login', exact: true, component: Login },
// 	{ path: '/logout', exact: true, component: Logout },
// 	{ path: '/esqueci-minha-senha', exact: true, component: EsqueciMinhaSenha },
// 	{ path: '/pre-cadastro-ec', exact: true, component: PreCadastrarEC },
// 	{ path: '/vendas/:vendaId/boleto', exact: true, component: VisualizarBoleto },
// 	{ path: '/imprimir-boletos', exact: true, component: ImprimirBoletos },
// ];

const clearAddMessage = (callback, time) => {
	setTimeout(function() {
		callback();
	}, time);
};

export default props => {
	const message = useSelector(state => state.alert);
	const usuario = useSelector(state => state.auth.usuario);
	const dispatch = useDispatch();
	// const location = useLocation();

	useEffect(() => {
		if (message.message !== false) {
			clearAddMessage(() => dispatch(addMessage({ type: 'error', message: false })), 5000);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [message.message]);

	const availableRoutes = authorizedRoutes.filter(v =>
		v.permissions.find(v2 => {
			return getGrupoPermission(usuario.grupo_id).includes(v2);
		})
	);

	// const isValidRoute = availableRoutes.find(v => v.path === location.pathname);
	// console.log(isValidRoute);
	// if (!isValidRoute) {
	// 	return '';
	// }

	return (
		<div style={{ marginTop: '20px' }}>
			{message.message && <ZAlert type={message.type} message={message.message} time={moment().unix()} />}
			{/* <Switch> */}
			<Switch>
				{availableRoutes.map(routeData => {
					return (
						<Route
							exact={routeData.exact}
							path={routeData.path}
							key={routeData.path}
							component={routeData.component}
						></Route>
					);
				})}
				<Route path="*" component={() => <ZNotFound />}></Route>
			</Switch>
			{/* <AclRouter
				authorities={getGrupoPermission(usuario.grupo_id)}
				authorizedRoutes={authorizedRoutes}
				normalRoutes={normalRoutes}
				notFound={() => <ZNotFound />}
			/> */}
			{/* </Switch> */}
		</div>
	);
};
