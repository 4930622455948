/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import config from 'react-global-configuration';
import clsx from 'clsx';
import DashboardFiltros from './DashboardFiltros';
import VendasChart from './DashboardGraficos/VendasChart';
import UltimasVendas from './Dashboard/UltimasVendas';
import UltimasTransferencias from './Dashboard/UltimasTransferencias';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
// import TextField from '@material-ui/core/TextField';
import ZLoader from '../system/ZLoader';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
	dashboard: {
		padding: 10,
	},
	box: {
		position: 'relative',
		padding: 10,
		height: '100%',
	},
	boxTitle: {
		fontWeight: 700,
		fontSize: 14,
		margin: 4,
	},
	boxFiltro: {
		position: 'absolute',
		top: 10,
		right: 5,
	},
	extrato: {
		height: 270,
	},
	extratoChart: {
		width: '100%',
		height: 160,
	},
	lancamentosChart: {
		width: '100%',
		height: 230,
	},
	extratoIO: {
		marginTop: 10,
		marginBottom: 20,
	},
	extratoFooter: {
		position: 'absolute',
		bottom: 4,
		right: 10,
		textAlign: 'right',
		cursor: 'pointer',
		'&:hover': {
			fontWeight: '700',
		},
	},
	progress: {
		height: 6,
		borderRadius: 6,
	},
	progressEntrada: {
		backgroundColor: `#ea606187`,
		'& .MuiLinearProgress-bar': {
			backgroundColor: `#ea6061FF`,
		},
	},
	progressSaida: {
		backgroundColor: `#00579787`,
		'& .MuiLinearProgress-bar': {
			backgroundColor: `#005797FF`,
		},
	},
	vendas: {
		minHeight: 435,
	},
	btnUltimasVendas: {
		[theme.breakpoints.down('md')]: {
			marginTop: 35,
		},
	},
	vendasChart: {
		width: '100%',
		height: 280,
		marginBottom: 10,
		[theme.breakpoints.up('md')]: {
			width: 300,
		},
	},
	webOnly: {
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'block',
		},
	},
	gridHeader: {
		fontWeight: 700,
		color: 'rgba(0,0,0,0.4)',
		height: 30,
	},
	ultimasVendas: {
		maxHeight: 300,
		overflowY: 'auto',
	},
	ultimasVendasFooter: {
		// position: 'absolute',
		bottom: 4,
		marginTop: 10,
		textAlign: 'right',
		cursor: 'pointer',
		'&:hover': {
			fontWeight: '700',
		},
	},
	ultimasTransferencias: {
		maxHeight: 210,
		overflowY: 'auto',
		marginBottom: 25,
	},
	ultimasTransferenciasFooter: {
		position: 'absolute',
		bottom: 4,
		right: 10,
		textAlign: 'right',
		cursor: 'pointer',
		'&:hover': {
			fontWeight: '700',
		},
	},
	tableDateRow: {
		fontWeight: 700,
		height: 30,
		display: 'flex',
		alignItems: 'center',
		borderRadius: 10,
		backgroundColor: `${theme.palette.primary.main}67`,
		paddingLeft: 20,
	},
	tableRow: {
		textAlign: 'center',
		fontWeight: 700,
		height: 30,
		display: 'flex',
		alignItems: 'center',
		borderRadius: 20,
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}2b`,
		},
	},
	tipoPagamentoIcon: {
		color: theme.palette.primary.main,
		fontSize: 20,
	},
	empty: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: 18,
		fontWeight: 700,
	},
	extratoEmpty: {
		height: 120,
	},
	vendasEmpty: {
		height: '100%',
	},
	lancamentosEmpty: {
		height: 230,
	},
	transferenciasEmpty: {
		height: '100%',
	},
	transferenciaDe: {
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
	transferenciaPara: {
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		textAlign: 'left',
		overflow: 'hidden',
	},
	hide: {
		display: 'none !important',
	},
}));

export default props => {
	const classes = useStyles();

	const token = useSelector(store => store.auth.token);

	const history = useHistory();

	const adminEstabelecimento = useSelector(state => state.admin.estabelecimento);
	const { id: estabelecimentoId } = adminEstabelecimento || {};

	const [chartLoading, setChartLoading] = useState([]);
	const [vendasData, setVendasData] = useState([]);
	const [vendasQuantidadeTotal, setVendasQuantidadeTotal] = useState([]);
	const [vendasFiltro, setVendasFiltro] = useState({ startDate: new Date(), endDate: new Date() });

	useEffect(() => {
		setChartLoading(true);
		axios
			.get(`${config.get('apiUrl')}dashboard/vendas`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
				params: {
					...vendasFiltro,
					filters: {
						estabelecimentoId,
					},
				},
			})
			.then(res => res.data)
			.then(data => {
				if (data.success) {
					const { quantidadeTotal, formasPagamentos } = data;
					setVendasData(formasPagamentos || []);
					setVendasQuantidadeTotal(quantidadeTotal || 0);
				}
				setChartLoading(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [vendasFiltro, estabelecimentoId]);

	return (
		<div className={classes.dashboard}>
			<Grid container spacing={2}>
				<Grid xs={12} className={classes.vendas} item>
					<Paper className={clsx(classes.box)}>
						<div className={classes.boxTitle}>Vendas</div>
						<div className={classes.boxFiltro}>
							<DashboardFiltros
								default="today"
								maxDate={new Date()}
								onClose={ranges => setVendasFiltro(ranges)}
							/>
						</div>
						<Grid container>
							<Grid xs={12} sm={12} md={5} item>
								{chartLoading ? (
									<ZLoader height={200} size={50} />
								) : (
									<VendasChart
										data={vendasData}
										quantidadeTotal={vendasQuantidadeTotal}
										showValorTotal={false}
									/>
								)}
							</Grid>
							<Grid xs={12} sm={12} md={7} item className={classes.webOnly}>
								<UltimasVendas estabelecimentoId={estabelecimentoId} />
							</Grid>
						</Grid>
						<Grid container>
							<Grid item xs={12} className={classes.btnUltimasVendas}>
								<div className={classes.ultimasVendasFooter} onClick={() => history.push('/vendas')}>
									<Button size="small">Ver todas as vendas</Button>
								</div>
							</Grid>
						</Grid>
					</Paper>
				</Grid>

				<Grid xs={12} className={classes.transferencias} item>
					<UltimasTransferencias estabelecimentoId={estabelecimentoId} />
				</Grid>
			</Grid>
		</div>
	);
};
