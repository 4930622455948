import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addMessage } from '../../store/alert';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import axios from 'axios';
import config from 'react-global-configuration';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Check from '@material-ui/icons/Check';
import Grow from '@material-ui/core/Grow';
import Zoom from '@material-ui/core/Zoom';
import Divider from '@material-ui/core/Divider';
import ZLoader from '../system/ZLoader';
import ZModalConfirm from '../system/ZModalConfirm';

import { Isset, Mask } from '../../utils';

const useStyles = makeStyles(theme => ({
	contasBancarias: {
		position: 'relative',
	},
	novaContaBancaria: {
		textAlign: 'center',
		textTransform: 'uppercase',
		margin: 8,
		fontWeight: 700,
		fontSize: 12,
		cursor: 'pointer',
		color: theme.palette.primary.main,
	},
	novaContaBancariaFields: {
		display: 'none',
		'&.show': {
			display: 'block',
		},
	},
	box: {
		position: 'relative',
		padding: 10,
		borderTop: `solid 4px transparent`,
	},
	boxAtivo: {
		borderTop: `solid 4px ${theme.palette.primary.main}`,
	},
	ativo: {
		position: 'absolute',
		right: 10,
		top: 5,
		textTransform: 'uppercase',
		fontSize: 11,
		fontWeight: 'bold',
	},
	formControl: {
		width: '100%',
		marginTop: 4,
	},
	salvarBtn: {
		textAlign: 'center',
		textTransform: 'uppercase',
		margin: 8,
		fontWeight: 700,
		fontSize: 12,
		cursor: 'pointer',
		color: theme.palette.primary.main,
	},
	textField: {
		width: '100%',
		marginTop: 4,
	},
	tipoContaBancaria: {
		padding: 20,
		fontSize: 12,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontWeight: 700,
		color: theme.palette.primary.main,
		border: `solid 1px ${theme.palette.primary.main}`,
		borderRadius: 6,
		cursor: 'pointer',
		'&.selected': {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.getContrastText(theme.palette.primary.main),
		},
		[theme.breakpoints.down('md')]: {
			padding: 10,
		},
	},
	icon: {
		color: theme.palette.primary.main,
	},
	updateCheck: {
		position: 'absolute',
		top: 10,
		right: 10,
	},
	field: {
		padding: 5,
	},
	label: {
		fontSize: 11,
		textTransform: 'uppercase',
		fontWeight: 700,
	},
	configUpdateBtn: {
		textAlign: 'center',
		textTransform: 'uppercase',
		paddingBottom: 15,
		fontWeight: 700,
		fontSize: 12,
		cursor: 'pointer',
		color: theme.palette.primary.main,
	},
	politicaRecebimento: {
		padding: 10,
		height: 100,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		cursor: 'pointer',
	},
	politicaRecebimentoAtivo: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.getContrastText(theme.palette.primary.main),
	},
	politicaRecebimentoTitle: {
		textAlign: 'center',
		fontWeight: 'bold',
	},
	politicaRecebimentoDescription: {
		fontSize: 10,
		[theme.breakpoints.down('xs')]: {
			display: 'none',
		},
	},
	atualizarContasBancariasBtn: {
		position: 'absolute',
		top: 0,
		right: 0,
		borderRadius: 4,
		padding: 6,
		backgroundColor: '#5867d1',
		color: 'white',
		cursor: 'pointer',
	},
}));

export default props => {
	const dispatch = useDispatch();
	const token = useSelector(state => state.auth.token);
	const estabelecimento = useSelector(state => state.auth.estabelecimento);
	const disableCpfCnpj = estabelecimento.marketplace.id === 24 ? false : true;

	const usuario = useSelector(state => state.auth.usuario);
	const { id: estabelecimentoAdminId } = useSelector(state => state.admin.estabelecimento) || {};
	// const isElo7Admin = estabelecimento.marketplace.id === 129 && usuario.grupo_id === 1;
	const isElo7Permitidos =
		estabelecimento.marketplace.id === 129 &&
		(usuario.grupo_id === 1 || usuario.grupo_id === 13 || usuario.grupo_id === 14 || usuario.grupo_id === 15);

	const isEdmond = usuario.marketplace.id === 17;
	// const isModerador = [1, 10].includes(usuario.grupo_id);

	const canTransferencia = useSelector(store =>
		Isset(() => store.system.params['22'].valor) ? store.system.params['22'].valor : null
	);

	const classes = useStyles();

	const [loading, setLoading] = useState(true);
	const [saving, setSaving] = useState(false);
	const [showFields, setShowFields] = useState(false);
	const [success, setSuccess] = useState(false);
	const [bancos, setBancos] = useState([]);
	const [contasBancarias, setContasBancarias] = useState([]);
	const [data, setData] = useState({
		tipoContaBancaria: 1,
		nomeTitular: '',
		bancoId: isEdmond ? '112' : '',
		agencia: '',
		conta: '',
		cpfCnpj: '',
	});

	const [errors, setErrors] = useState({});
	const [excluirContaBancaria, setExcluirContaBancaria] = useState(null);
	const [politicaRecebimento, setPoliticaRecebimento] = useState(null);
	const [estabelecimentoDocumento, setEstabelecimentoDocumento] = useState({
		documento: null,
		tipoDocumento: null,
	});

	//id2(gerencial) nao pode ser da elo7
	const canViewPoliticaRecebimento =
		(usuario.grupo_id === 1 || (usuario.grupo_id === 2 && usuario.marketplace.id !== 129)) && canTransferencia;

	const estabelecimentoId = Isset(() => props.estabelecimentoId)
		? props.estabelecimentoId
		: estabelecimentoAdminId
		? estabelecimentoAdminId
		: estabelecimento.id;

	const validate = () => {
		const e = {};

		if (!data.nomeTitular) {
			e.nomeTitular = true;
		}
		if (!data.bancoId) {
			e.bancoId = true;
		}
		if (!data.agencia) {
			e.agencia = true;
		}
		if (!data.conta) {
			e.conta = true;
		}

		setErrors(e);

		return Object.keys(e).length > 0;
	};

	const getContas = async () => {
		setLoading(true);
		const result = await axios.get(
			`${config.get('apiUrl')}estabelecimentos/${estabelecimentoId}/contas_bancarias`,
			{
				headers: { Authorization: `Bearer ${token}` },
			}
		);

		if (result.data.success) {
			setContasBancarias(result.data.contasBancarias);
		}

		setLoading(false);
	};

	const getPoliticaRecebimento = async () => {
		const res = await axios.get(
			`${config.get('apiUrl')}estabelecimentos/${estabelecimentoId}/politicaRecebimento`,
			{
				headers: { Authorization: `Bearer ${token}` },
			}
		);

		if (res.data.success && Isset(() => res.data.result.transfer_enabled)) {
			setPoliticaRecebimento(res.data.result.transfer_enabled);
		}
	};

	const getEstabelecimento = async () => {
		const res = await axios.post(
			`${config.get('apiUrl')}graphql`,
			{
				query: `
			query Estabelecimentos {
				estabelecimento(id: ${estabelecimentoId}) {
					nomeFantasia, documento, tipoDocumento
				}
			}
		`,
			},
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);

		if (res.status === 200 && Isset(() => res.data.data.estabelecimento)) {
			const { documento, tipoDocumento } = res.data.data.estabelecimento;
			setEstabelecimentoDocumento({ documento, tipoDocumento });
		}
	};

	useEffect(() => {
		const getBancos = async () => {
			const result = await axios.get(`${config.get('apiUrl')}bancos`);

			if (result.data.success) {
				setBancos(result.data.bancos);
				setShowFields(false);
			}
		};

		getContas();
		getBancos();
		getPoliticaRecebimento();
		getEstabelecimento();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// useEffect(() => {
	//     validate();
	// // eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [data]);

	const handleSubmit = async () => {
		if (!validate()) {
			setSaving(true);

			data.cpfCnpj = estabelecimentoDocumento.documento;
			// data.tipoContaBancaria = estabelecimento.tipoDocumento;

			const contaBancaria = await axios.post(
				`${config.get('apiUrl')}estabelecimentos/${estabelecimentoId}/contas_bancarias`,
				data,
				{
					headers: { Authorization: `Bearer ${token}` },
				}
			);

			if (contaBancaria.data.success) {
				setSuccess(true);
				setTimeout(_ => {
					setSuccess(false);
				}, 2000);
				setData({
					tipoContaBancaria: 1,
					nomeTitular: '',
					bancoId: isEdmond ? '112' : '',
					agencia: '',
					conta: '',
				});
				getContas();
			} else {
				dispatch(addMessage({ type: 'error', message: contaBancaria.data.message }));
			}
			setSaving(false);
		}
	};

	const ativar = async contaBancariaId => {
		setLoading(true);
		const res = await axios.put(
			`${config.get('apiUrl')}estabelecimentos/${estabelecimentoId}/contas_bancarias/${contaBancariaId}/ativar`,
			{},
			{
				headers: { Authorization: `Bearer ${token}` },
			}
		);

		if (res.data.success) {
			await getContas();
		}
	};

	const excluir = async contaBancariaId => {
		setExcluirContaBancaria(contaBancariaId);
	};

	const handleExcluir = async result => {
		if (result && excluirContaBancaria !== null) {
			setLoading(true);
			const res = await axios.delete(
				`${config.get(
					'apiUrl'
				)}estabelecimentos/${estabelecimentoId}/contas_bancarias/${excluirContaBancaria}/excluir`,
				{
					headers: { Authorization: `Bearer ${token}` },
				}
			);
			if (res.data.success) {
				await getContas();
			} else {
				dispatch(addMessage({ type: 'error', message: res.data.message }));
				setLoading(false);
			}
			setExcluirContaBancaria(null);
		}
	};

	const politicaRecebimentoClick = async automatico => {
		setLoading(true);
		const res = await axios.post(
			`${config.get('apiUrl')}estabelecimentos/${estabelecimentoId}/politicaRecebimento/${automatico ? 1 : 0}`,
			{},
			{
				headers: { Authorization: `Bearer ${token}` },
			}
		);

		if (res.data.success) {
			await getPoliticaRecebimento();
		}
		setLoading(false);
	};

	const atualizarContasBancariasBtnClick = async () => {
		setLoading(true);
		const res = await axios.post(
			`${config.get('apiUrl')}estabelecimentos/${estabelecimentoId}/atualizar-contas-bancarias`,
			{},
			{
				headers: { Authorization: `Bearer ${token}` },
			}
		);

		if (res.data.success) {
			await getContas();
		}
		setLoading(false);
	};

	if (loading) {
		return <ZLoader height={200} />;
	}

	return (
		<div className={classes.contasBancarias}>
			<Zoom in>
				<>
					<div className={classes.atualizarContasBancariasBtn} onClick={atualizarContasBancariasBtnClick}>
						Atualizar Contas Bancárias
					</div>
					{(canViewPoliticaRecebimento ||
						isElo7Permitidos ||
						(usuario.grupo_id === 11 && estabelecimento.marketplace.id !== 129)) && (
						<>
							<Grid justifyContent="center" container spacing={2}>
								<Grid item sm={3} xs={6}>
									<Paper
										className={clsx(classes.politicaRecebimento, {
											[classes.politicaRecebimentoAtivo]: politicaRecebimento,
										})}
										onClick={() => !politicaRecebimento && politicaRecebimentoClick(true)}
									>
										<div className={classes.politicaRecebimentoTitle}>Pagamento Automático</div>
										<div className={classes.politicaRecebimentoDescription}>
											Receba o valor de suas vendas na sua conta bancária ou cartão pré-pago de
											acordo com o calendário de recebimento do seu plano de taxas.
										</div>
									</Paper>
								</Grid>
								<Grid item sm={3} xs={6}>
									<Paper
										className={clsx(classes.politicaRecebimento, {
											[classes.politicaRecebimentoAtivo]: !politicaRecebimento,
										})}
										onClick={() => politicaRecebimento && politicaRecebimentoClick(false)}
									>
										<div className={classes.politicaRecebimentoTitle}>
											Pagamento em Conta Digital
										</div>
										<div className={classes.politicaRecebimentoDescription}>
											Receba pelas suas vendas direto na Conta Digital , sem custo adicional. Faça
											transferências sob demanda quando quiser.
										</div>
									</Paper>
								</Grid>
							</Grid>

							<Divider style={{ margin: 10 }} />
						</>
					)}

					<Grid container spacing={2}>
						{contasBancarias.map((c, i) => (
							<Grid key={i} item xs={12} sm={6} md={3}>
								<Paper className={clsx(classes.box, { [classes.boxAtivo]: c.ativo })}>
									{c.ativo && <div className={classes.ativo}>Ativo</div>}
									<div className={classes.field}>
										<div className={classes.label}>Nome do Titular</div>
										<div>{c.nomeTitular}</div>
									</div>
									<div className={classes.field}>
										<div className={classes.label}>Tipo de Conta</div>
										<div>{c.tipoContaBancaria === 1 ? 'Conta Corrente' : 'Conta Poupança'}</div>
									</div>
									<div className={classes.field}>
										<div className={classes.label}>Banco</div>
										<div>{c.banco}</div>
									</div>
									<div className={classes.field}>
										<div className={classes.label}>Agência</div>
										<div>{c.agencia}</div>
									</div>
									<div className={classes.field}>
										<div className={classes.label}>Conta</div>
										<div>{c.conta}</div>
									</div>
									<Grid container justifyContent="center">
										{!c.ativo && (
											<Grid
												item
												xs={6}
												className={classes.configUpdateBtn}
												onClick={() => ativar(c.id)}
											>
												Ativar
											</Grid>
										)}
										<Grid
											item
											xs={6}
											className={classes.configUpdateBtn}
											onClick={() => excluir(c.id)}
										>
											Excluir
										</Grid>
									</Grid>
								</Paper>
							</Grid>
						))}

						{contasBancarias.length < 4 && (
							<Grid item xs={12} sm={6} md={3}>
								<Paper className={classes.box}>
									<div className={classes.updateCheck}>
										<Grow in={success}>
											<Check className={classes.icon} />
										</Grow>
									</div>

									<div className={classes.novaContaBancaria} onClick={() => setShowFields(true)}>
										Nova Conta Bancária
									</div>
									<div className={clsx(classes.novaContaBancariaFields, { show: showFields })}>
										<Grid container spacing={2}>
											<Grid item xs>
												<div
													className={clsx(classes.tipoContaBancaria, {
														selected: data.tipoContaBancaria === 1,
													})}
													onClick={() => setData({ ...data, tipoContaBancaria: 1 })}
												>
													Conta Corrente
												</div>
											</Grid>
											<Grid item xs>
												<div
													className={clsx(classes.tipoContaBancaria, {
														selected: data.tipoContaBancaria === 2,
													})}
													onClick={() => setData({ ...data, tipoContaBancaria: 2 })}
												>
													Conta Poupança
												</div>
											</Grid>
										</Grid>

										<TextField
											style={{ marginTop: 15 }}
											className={classes.textField}
											label={`${
												estabelecimentoDocumento.tipoDocumento === 2 ? 'CPF' : 'CNPJ'
											} do Titular`}
											disabled={disableCpfCnpj}
											onChange={e => {
												let tipoDocumento = 1;
												if (Mask.OnlyNumber(e.target.value).length <= 11) {
													tipoDocumento = 2;
												}

												setEstabelecimentoDocumento({
													tipoDocumento,
													documento: Mask.OnlyNumber(e.target.value),
												});
											}}
											value={
												estabelecimentoDocumento.tipoDocumento === 2
													? Mask.Cpf(estabelecimentoDocumento.documento)
													: Mask.Cnpj(estabelecimentoDocumento.documento)
											}
											// onChange={e => setData({ ...data, nomeTitular: e.target.value })}
										/>

										<TextField
											error={errors.nomeTitular || false}
											className={classes.textField}
											label="Nome"
											value={data.nomeTitular}
											onChange={e => setData({ ...data, nomeTitular: e.target.value })}
										/>

										<FormControl error={errors.bancoId || false} className={classes.formControl}>
											<InputLabel htmlFor="banco">Banco</InputLabel>
											<Select
												value={data.bancoId}
												onChange={e => setData({ ...data, bancoId: e.target.value })}
												inputProps={{ id: 'bancoId' }}
												// disabled={isEdmond && !isModerador}
											>
												{bancos.map((b, i) => (
													<MenuItem key={i} value={b.id}>
														{b.nome}
													</MenuItem>
												))}
											</Select>
										</FormControl>

										<TextField
											error={errors.agencia || false}
											className={classes.textField}
											label="Agência"
											value={data.agencia}
											onChange={e => setData({ ...data, agencia: e.target.value })}
										/>

										<TextField
											error={errors.conta || false}
											className={classes.textField}
											label="Conta"
											value={data.conta}
											onChange={e => setData({ ...data, conta: e.target.value })}
										/>

										<div className={classes.salvarBtn} onClick={handleSubmit}>
											{!saving ? 'Salvar' : <ZLoader height={20} size={20} />}
										</div>
									</div>
								</Paper>
							</Grid>
						)}
					</Grid>
				</>
			</Zoom>

			<ZModalConfirm
				title={`Remover Conta Bancária`}
				message={`Deseja remover essa Conta Bancária?`}
				open={excluirContaBancaria !== null}
				onClose={handleExcluir}
			/>
		</div>
	);
};
