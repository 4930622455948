/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { estabelecimentoIdState, authTokenState } from '../../recoil/atoms';
import { estabelecimentoState } from '../../recoil/selectors';
import { Paper, Grid, TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Formatter, Mask } from '../../utils';
import ZLoader from '../system/ZLoader';
import Axios from 'axios';
import config from 'react-global-configuration';
import format from 'date-fns/format';

import { addMessage } from '../../store/alert';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
	estabelecimentoInfo: {
		fontSize: 20,
		fontWeight: 'bold',
	},
	title: {
		fontSize: 18,
		fontWeight: 'bold',
	},
	message: {
		fontSize: 14,
		fontWeight: 'bold',
	},
	paper: {
		marginTop: 10,
		padding: 10,
		paddingRight: 20,
	},
	table: {
		textAlign: 'center',
		maxHeight: 300,
		overflowY: 'scroll',
		boxShadow: '2px 2px 6px 0px #ccc',
		position: 'relative',
		marginTop: 5,
		padding: 2,
	},
	tableHeader: {
		position: 'sticky',
		top: 0,
		left: 0,
		width: '100%',
		margin: 0,
		fontWeight: 'bold',
		fontSize: 15,
		background: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		borderRadius: 10,
		textAlign: 'center',
		'& .MuiGrid-item': {
			padding: 2,
		},
	},
	tableRow: {
		width: '100%',
		margin: 1,
		borderRadius: 10,
		cursor: 'pointer',
		textAlign: 'center',
		'& .MuiGrid-item': {
			padding: 2,
		},
		'&:hover': {
			background: `${theme.palette.primary.main}33`,
			// color: theme.palette.getContrastText(`${theme.palette.primary.main}aa`),
		},
	},
	isSelected: {
		background: `${theme.palette.primary.main}66`,
	},
	antecipacaoOptions: {
		textAlign: 'center',
	},
	antecipacaoOption: {
		margin: '10px 30px',
		borderWidth: 1,
		borderStyle: 'solid',
		borderColor: theme.palette.primary.main,
		padding: 10,
		borderRadius: 20,
		cursor: 'pointer',
	},
	antecipacaoOptionSelected: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
	},
	empty: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: 18,
		fontWeight: 700,
		height: 100,
	},
	check: {
		fontSize: 14,
		color: 'green',
	},
}));

const getStatus = status => {
	switch (status) {
		case 'succeeded':
			return 'Sucesso';
		case 'simulated':
			return 'Simulada';
		case 'invalid':
			return 'Inválida';
		case 'expired':
			return 'Expirada';
		case 'requested':
			return 'Solicitada';
		case 'ready':
			return 'Pronta';
		default:
			return status;
	}
};

// const Vendas = memo(props => {
// 	const classes = useStyles();
// 	const { estabelecimentoId } = props;
// 	const [vendas, setVendas] = useState([]);
// 	const [loading, setLoading] = useState(true);
// 	const token = useRecoilValue(authTokenState);
// 	const dispatch = useDispatch();
// 	const [saving, setSaving] = useState(false);
// 	const [loadingMore, setLoadingMore] = useState(false);
// 	const [hasMore, setHasMore] = useState(true);
// 	const [page, setPage] = useState(1);

// 	const [rowsSelected, setRowsSelected] = useState([]);

// 	const getVendasDisponiveis = async () => {
// 		setLoading(true);
// 		const res = await Axios.get(
// 			`${config.get('apiUrl')}antecipacoes/recebiveis?estabelecimentoId=${estabelecimentoId}&group=pedidos`,
// 			{
// 				headers: { Authorization: `Bearer ${token}` },
// 			}
// 		);

// 		if (res.data.success) {
// 			setVendas(res.data.retorno);
// 		}
// 		setLoading(false);
// 	};

// 	useEffect(() => {
// 		getVendasDisponiveis();
// 	}, [estabelecimentoId]);

// 	useEffect(() => {
// 		props.updateValor(rowsSelected);
// 	}, [rowsSelected]);

// 	const onRowClick = transactionId => {
// 		const isSelected = rowsSelected.find(a => a.transactionId === transactionId);
// 		if (isSelected) {
// 			setRowsSelected(rowsSelected.filter(a => a.transactionId !== transactionId));
// 		} else {
// 			const row = vendas.find(a => a.transactionId === transactionId);
// 			setRowsSelected([...rowsSelected, row]);
// 		}
// 	};

// 	const onSubmit = async () => {
// 		if (!rowsSelected.length) {
// 			return false;
// 		}
// 		setSaving(true);

// 		const data = {
// 			estabelecimentoId,
// 			parcelas: rowsSelected.map(a => a.transactionId),
// 		};

// 		const res = await Axios.post(`${config.get('apiUrl')}antecipacoes/simular`, data, {
// 			headers: { Authorization: `Bearer ${token}` },
// 		});

// 		if (res.data.success) {
// 			props.onSuccess();
// 			getVendasDisponiveis();
// 		} else {
// 			dispatch(addMessage({ type: 'error', message: res.data.error }));
// 		}
// 		setSaving(false);
// 	};

// 	const loadMore = async () => {
// 		setLoadingMore(true);
// 		const p = page + 1;
// 		setPage(p);
// 		const res = await Axios.get(
// 			`${config.get(
// 				'apiUrl'
// 			)}antecipacoes/recebiveis?estabelecimentoId=${estabelecimentoId}&group=pedidos&page=${p}`,
// 			{
// 				headers: { Authorization: `Bearer ${token}` },
// 			}
// 		);

// 		if (res.data.success) {
// 			if (res.data.retorno.length === 0) {
// 				setHasMore(false);
// 			} else {
// 				setVendas([...vendas, ...res.data.retorno]);
// 			}
// 		}
// 		setLoadingMore(false);
// 	};

// 	const onScroll = async e => {
// 		const { scrollTop, scrollHeight, clientHeight } = e.target;

// 		if (hasMore && !loadingMore && scrollTop + clientHeight > scrollHeight * 0.9) {
// 			await loadMore();
// 			console.log('loadMore');
// 		}
// 	};

// 	if (loading) {
// 		return <ZLoader height={200} message="Carregando Vendas" />;
// 	}

// 	return (
// 		<>
// 			<div className={classes.table} onScroll={onScroll}>
// 				<Grid className={classes.tableHeader} container spacing={2}>
// 					<Grid item xs={1}></Grid>
// 					<Grid item xs={4}>
// 						Data Venda
// 					</Grid>
// 					<Grid item xs>
// 						ID da Transação
// 					</Grid>
// 					<Grid item xs={3}>
// 						Valor
// 					</Grid>
// 				</Grid>
// 				{vendas.length > 0 ? (
// 					<>
// 						{vendas.map(venda => {
// 							const isSelected = rowsSelected.find(a => a.transactionId === venda.transactionId);
// 							return (
// 								<Grid
// 									className={clsx(classes.tableRow, {
// 										[classes.isSelected]: isSelected,
// 									})}
// 									key={venda.id}
// 									container
// 									spacing={2}
// 									onClick={() => onRowClick(venda.transactionId)}
// 								>
// 									<Grid item xs={1}>
// 										{isSelected && <FaCheck className={classes.check} />}
// 									</Grid>
// 									<Grid item xs={4}>
// 										{format(venda.dataVenda, 'DD/MM/YYYY HH:mm')}
// 									</Grid>
// 									<Grid item xs>
// 										{venda.transactionId}
// 									</Grid>
// 									<Grid item xs={3}>
// 										{Formatter.Real(venda.amount)}
// 									</Grid>
// 								</Grid>
// 							);
// 						})}
// 					</>
// 				) : (
// 					<div className={classes.empty}>Nenhuma Venda Encontrada</div>
// 				)}
// 			</div>
// 			{rowsSelected.length > 0 && (
// 				<Grid container justifyContent="flex-end" spacing={2} style={{ textAlign: 'right', marginTop: 5 }}>
// 					<Grid item xs={12} md={3}>
// 						<Button disabled={saving} variant="contained" color="primary" onClick={onSubmit}>
// 							{saving ? <ZLoader size={14} height={24} /> : 'Simular'}
// 						</Button>
// 					</Grid>
// 				</Grid>
// 			)}
// 		</>
// 	);
// });

// const Parcelas = memo(props => {
// 	const classes = useStyles();
// 	const { estabelecimentoId } = props;
// 	const [parcelas, setParcelas] = useState([]);
// 	const [loading, setLoading] = useState(true);
// 	const token = useRecoilValue(authTokenState);
// 	const dispatch = useDispatch();
// 	const [saving, setSaving] = useState(false);
// 	const [loadingMore, setLoadingMore] = useState(false);
// 	const [hasMore, setHasMore] = useState(true);
// 	const [page, setPage] = useState(1);

// 	const [rowsSelected, setRowsSelected] = useState([]);

// 	const getParcelasDisponiveis = async () => {
// 		setLoading(true);
// 		const res = await Axios.get(
// 			`${config.get('apiUrl')}antecipacoes/recebiveis?estabelecimentoId=${estabelecimentoId}`,
// 			{
// 				headers: { Authorization: `Bearer ${token}` },
// 			}
// 		);

// 		if (res.data.success) {
// 			setParcelas(res.data.retorno);
// 		}
// 		setLoading(false);
// 	};

// 	useEffect(() => {
// 		getParcelasDisponiveis();
// 	}, [estabelecimentoId]);

// 	useEffect(() => {
// 		props.updateValor(rowsSelected);
// 	}, [rowsSelected]);

// 	const onRowClick = receivableId => {
// 		const isSelected = rowsSelected.find(a => a.id === receivableId);
// 		if (isSelected) {
// 			setRowsSelected(rowsSelected.filter(a => a.id !== receivableId));
// 		} else {
// 			const row = parcelas.find(a => a.id === receivableId);
// 			setRowsSelected([...rowsSelected, row]);
// 		}
// 	};

// 	const onSubmit = async () => {
// 		if (!rowsSelected.length) {
// 			return false;
// 		}
// 		setSaving(true);

// 		const data = {
// 			estabelecimentoId,
// 			recebiveis: rowsSelected.map(a => a.id),
// 		};

// 		const res = await Axios.post(`${config.get('apiUrl')}antecipacoes/simular`, data, {
// 			headers: { Authorization: `Bearer ${token}` },
// 		});

// 		if (res.data.success) {
// 			props.onSuccess();
// 			getParcelasDisponiveis();
// 		} else {
// 			dispatch(addMessage({ type: 'error', message: res.data.error }));
// 		}
// 		setSaving(false);
// 	};

// 	const loadMore = async () => {
// 		setLoadingMore(true);
// 		const p = page + 1;
// 		setPage(p);
// 		const res = await Axios.get(
// 			`${config.get('apiUrl')}antecipacoes/recebiveis?estabelecimentoId=${estabelecimentoId}&page=${p}`,
// 			{
// 				headers: { Authorization: `Bearer ${token}` },
// 			}
// 		);

// 		if (res.data.success) {
// 			if (res.data.retorno.length === 0) {
// 				setHasMore(false);
// 			} else {
// 				setParcelas([...parcelas, ...res.data.retorno]);
// 			}
// 		}
// 		setLoadingMore(false);
// 	};

// 	const onScroll = async e => {
// 		const { scrollTop, scrollHeight, clientHeight } = e.target;

// 		if (hasMore && !loadingMore && scrollTop + clientHeight > scrollHeight * 0.9) {
// 			await loadMore();
// 			console.log('loadMore');
// 		}
// 	};

// 	if (loading) {
// 		return <ZLoader height={200} message="Carregando Vendas" />;
// 	}

// 	return (
// 		<>
// 			<div className={classes.table} onScroll={onScroll}>
// 				<Grid className={classes.tableHeader} container spacing={2}>
// 					<Grid item xs={1}></Grid>
// 					<Grid item xs={4}>
// 						Data Venda
// 					</Grid>
// 					<Grid item xs>
// 						ID da Transação
// 					</Grid>
// 					<Grid item xs={3}>
// 						Valor
// 					</Grid>
// 				</Grid>
// 				{parcelas.length > 0 ? (
// 					<>
// 						{parcelas.map(parcela => {
// 							const isSelected = rowsSelected.find(a => a.id === parcela.id);
// 							return (
// 								<Grid
// 									className={clsx(classes.tableRow, {
// 										[classes.isSelected]: isSelected,
// 									})}
// 									key={parcela.id}
// 									container
// 									spacing={2}
// 									onClick={() => onRowClick(parcela.id)}
// 								>
// 									<Grid item xs={1}>
// 										{isSelected && <FaCheck className={classes.check} />}
// 									</Grid>
// 									<Grid item xs={4}>
// 										{format(parcela.dataVenda, 'DD/MM/YYYY HH:mm')}
// 									</Grid>
// 									<Grid item xs>
// 										{parcela.transactionId}
// 									</Grid>
// 									<Grid item xs={3}>
// 										{Formatter.Real(parcela.amount)}
// 									</Grid>
// 								</Grid>
// 							);
// 						})}
// 					</>
// 				) : (
// 					<div className={classes.empty}>Nenhuma Parcela Encontrada</div>
// 				)}
// 			</div>
// 			{rowsSelected.length > 0 && (
// 				<Grid container justifyContent="flex-end" spacing={2} style={{ textAlign: 'right', marginTop: 5 }}>
// 					<Grid item xs={12} md={3}>
// 						<Button disabled={saving} variant="contained" color="primary" onClick={onSubmit}>
// 							{saving ? <ZLoader size={14} height={24} /> : 'Simular'}
// 						</Button>
// 					</Grid>
// 				</Grid>
// 			)}
// 		</>
// 	);
// });

const Antecipacoes = props => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const estabelecimentoId = useRecoilValue(estabelecimentoIdState);
	const estabelecimento = useRecoilValue(estabelecimentoState);
	const token = useRecoilValue(authTokenState);

	// const [valorTotalDisponivel, setValorTotalDisponivel] = useState(0);
	// const [loading, setLoading] = useState(true);
	const [saving, setSaving] = useState(false);
	const [loadingAntecipacoes, setLoadingAntecipacoes] = useState(true);
	// const [option, setOption] = useState(1);
	const [selectedAmount, setSelectedAmount] = useState(0);

	const [antecipacoes, setAntecipacoes] = useState([]);

	const getAntecipacoes = async () => {
		setLoadingAntecipacoes(true);
		const res = await Axios.get(`${config.get('apiUrl')}antecipacoes/${estabelecimentoId}`, {
			headers: { Authorization: `Bearer ${token}` },
		});

		if (res.data.success) {
			console.log(res.data.antecipacoes);
			setAntecipacoes(res.data.antecipacoes);
		}
		setLoadingAntecipacoes(false);
	};

	// const getValorDisponivel = async () => {
	// 	setLoading(true);
	// 	const res = await Axios.get(
	// 		`${config.get('apiUrl')}antecipacoes/valor-disponivel?estabelecimentoId=${estabelecimentoId}`,
	// 		{
	// 			headers: { Authorization: `Bearer ${token}` },
	// 		}
	// 	);

	// 	if (res.data.success) {
	// 		setValorTotalDisponivel(res.data.valorDisponivel);
	// 	}
	// 	setLoading(false);
	// };

	useEffect(() => {
		// getValorDisponivel();
		getAntecipacoes();
	}, [estabelecimentoId, token]);

	if (!estabelecimento) {
		return <ZLoader height={200} message="Carregando Informações" />;
	}

	// const onOptionChange = option => {
	// 	setOption(option);
	// 	setSelectedAmount(0);
	// };

	// const updateValor = rows => {
	// 	setSelectedAmount(rows.reduce((r, v) => r + +v.amount, 0));
	// };

	const onSuccess = () => {
		getAntecipacoes();
		// getValorDisponivel();
	};

	const onSubmit = async () => {
		if (selectedAmount <= 0) {
			return false;
		}
		setSaving(true);

		const data = {
			estabelecimentoId,
			valor: selectedAmount,
		};

		const res = await Axios.post(`${config.get('apiUrl')}antecipacoes/simular`, data, {
			headers: { Authorization: `Bearer ${token}` },
		});

		if (res.data.success) {
			onSuccess();
		} else {
			dispatch(addMessage({ type: 'error', message: res.data.error.message }));
		}
		setSaving(false);
	};

	const onAprovar = async id => {
		const res = await Axios.post(
			`${config.get('apiUrl')}antecipacoes/aprovar/${id}`,
			{},
			{
				headers: { Authorization: `Bearer ${token}` },
			}
		);

		if (res.data.success) {
			onSuccess();
		} else {
			dispatch(addMessage({ type: 'error', message: res.data.error }));
		}
	};

	return (
		<div>
			<div className={classes.estabelecimentoInfo}>
				{estabelecimento.nomeFantasia}
				{estabelecimento.nomeComprovante ? <small> ({estabelecimento.nomeComprovante})</small> : ''}
			</div>
			<Paper className={classes.paper}>
				<div className={classes.title}>Antecipação sob Demanda</div>
				{/* <div className={classes.title}>Antecipar Pagamentos</div> */}
				<div className={classes.message}>
					As antecipações só podem ser realizadas de segunda a sexta, das 08:00 às 15:00.
					<br />
					Após solicitar uma simulação, aguarde até que o status fique como Simulado e depois é necessário
					confirmar se deseja a antecipação.
				</div>

				{/* {loading ? (
					<ZLoader height={200} message="Carregando Valor Disponível" />
				) : ( */}
				<Grid container spacing={2}>
					{/* <Grid item xs={12} sm={4} md={3}>
							<div className={classes.antecipacaoOptions}>
								<div
									className={clsx(classes.antecipacaoOption, {
										[classes.antecipacaoOptionSelected]: option === 1,
									})}
									onClick={() => onOptionChange(1)}
								>
									Por Valor
								</div>
								<div
									className={clsx(classes.antecipacaoOption, {
										[classes.antecipacaoOptionSelected]: option === 2,
									})}
									onClick={() => onOptionChange(2)}
								>
									Por Venda
								</div>
								<div
									className={clsx(classes.antecipacaoOption, {
										[classes.antecipacaoOptionSelected]: option === 3,
									})}
									onClick={() => onOptionChange(3)}
								>
									Por Parcela
								</div>
							</div>
						</Grid> */}
					<Grid item xs={12} sm={8} md={9} style={{ marginTop: 10 }}>
						{/* <div>
							<div>Valor total disponível para antecipação</div>
							<div>{Formatter.Real(valorTotalDisponivel)}</div>
						</div>  */}
						<div>
							{/* <div>{option === 1 ? 'Valor' : 'Valor Selecionado'}</div> */}
							<div>
								<TextField
									// disabled={option !== 1}
									value={Mask.Real(selectedAmount)}
									onChange={e => {
										const value = Mask.OnlyNumber(e.target.value) / 100;
										setSelectedAmount(value);
									}}
								/>
							</div>
						</div>

						{selectedAmount > 0 && (
							<Grid
								container
								justifyContent="flex-end"
								spacing={2}
								style={{ textAlign: 'left', marginTop: 5 }}
							>
								<Grid item xs={12} md={12}>
									<Button disabled={saving} variant="contained" color="primary" onClick={onSubmit}>
										{saving ? <ZLoader size={14} height={24} /> : 'Simular'}
									</Button>
								</Grid>
							</Grid>
						)}
					</Grid>
				</Grid>
				{/* )} */}
			</Paper>
			<Paper className={classes.paper}>
				<div className={classes.title}>Antecipações</div>

				{loadingAntecipacoes ? (
					<ZLoader height={200} message="Carregando Antecipações" />
				) : (
					<>
						{antecipacoes.length > 0 ? (
							<>
								<Grid className={classes.tableHeader} container spacing={2}>
									<Grid item xs>
										Data da Requisição
									</Grid>
									<Grid item xs>
										Data para Antecipação
									</Grid>
									<Grid item xs>
										Status
									</Grid>
									<Grid item xs>
										Valor Bruto
									</Grid>
									<Grid item xs>
										Taxas
									</Grid>
									<Grid item xs>
										Valor Líquido
									</Grid>
									<Grid item xs>
										Ação
									</Grid>
								</Grid>
								{antecipacoes.map(antecipacao => (
									<Grid className={classes.tableRow} key={antecipacao.id} container spacing={2}>
										<Grid item xs>
											{format(antecipacao.created_at, 'DD/MM/YYYY')}
										</Grid>
										<Grid item xs>
											{format(antecipacao.prepayment_date, 'DD/MM/YYYY')}
										</Grid>
										<Grid item xs>
											<div>{getStatus(antecipacao.status)}</div>
											<div>
												<small>{antecipacao.status_detail}</small>
											</div>
										</Grid>
										<Grid item xs>
											{Formatter.Real(antecipacao.prepayment_gross_amount / 100)}
										</Grid>
										<Grid item xs>
											{Mask.Porcentagem(+antecipacao.taxa)}%
										</Grid>
										<Grid item xs>
											{Formatter.Real(antecipacao.prepayment_net_amount / 100)}
										</Grid>
										<Grid item xs>
											{antecipacao.status === 'simulated' ? (
												<Button
													variant="contained"
													color="primary"
													onClick={() => onAprovar(antecipacao.id)}
												>
													{/* <Button onClick={() => onAprovar(antecipacao.id)} variant="contained"> */}
													Aprovar
												</Button>
											) : (
												<div>Nenhuma Ação</div>
											)}
										</Grid>
									</Grid>
								))}
							</>
						) : (
							<div className={classes.empty}>Nenhuma Antecipação Encontrada</div>
						)}
					</>
				)}
			</Paper>
		</div>
	);
};

export default props => {
	return (
		<React.Suspense fallback={<ZLoader />}>
			<Antecipacoes {...props} />
		</React.Suspense>
	);
};
