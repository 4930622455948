import axios from 'axios';
import config from 'react-global-configuration';
import { Isset } from '../utils';
import moment from 'moment';
// Action Types

export const Types = {
	LOGIN: 'auth/LOGIN',
	LOGOUT: 'auth/LOGOUT',
	LOGIN_AS_EC: 'auth/LOGIN_AS_EC',
};

// Reducer

const initialState = {
	logado: false,
	token: null,
	mainToken: localStorage.getItem('mainToken'),
	usuario: {},
	estabelecimento: {},
	isMaster: localStorage.getItem('isMaster'),
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case Types.LOGIN_AS_EC:
			localStorage.setItem('mainToken', state.token);
			localStorage.setItem('token', action.ecUserToken);
			return {
				...state,
				mainToken: state.token,
				token: action.ecUserToken,
			};
		case Types.LOGIN:
			localStorage.setItem('token', action.usuario.token);

			const loggedAt = localStorage.getItem('loggedAt');

			if (!loggedAt || !moment(loggedAt).isValid()) {
				localStorage.setItem('loggedAt', moment());
			}

			if (Isset(() => action.usuario.isMaster)) {
				localStorage.setItem('isMaster', action.usuario.isMaster || false);
			}

			return {
				...state,
				logado: true,
				token: action.usuario.token,
				usuario: action.usuario,
				estabelecimento: action.usuario.estabelecimento || {},
				isMaster: action.usuario.isMaster || false,
				loggedAt: localStorage.getItem('loggedAt')
			};

		case Types.LOGOUT:
			if (state.mainToken !== null) {
				localStorage.setItem('token', state.mainToken);
				localStorage.setItem('mainToken', null);
			} else {
				localStorage.setItem('token', null);
				localStorage.setItem('mainToken', null);
				localStorage.setItem('isMaster', false);
			}

			localStorage.setItem('loggedAt', null);

			return { ...state, ...initialState };

		default:
			return state;
	}
};

export default reducer;

// Action Creators

export const checkLoginIsValid = () => async () => {

	const loggedAt = localStorage.getItem("loggedAt");
	if (!loggedAt) { return false }

	if (moment(loggedAt).format() < moment().add('-1', 'hours').format() && process.env.NODE_ENV === 'production') {
		localStorage.setItem('token', null);
		localStorage.setItem('mainToken', null);
		localStorage.setItem('loggedAt', null);

		window.location = '/app';
		return false;
	}

	return true;
};

export const checkLogin = () => async dispatch => {
	let token = localStorage.getItem('token');

	if (token) {
		let res = await axios.post(`${config.get('apiUrl')}autenticar`, {
			token,
		});

		if (res.data.success) {
			return dispatch(login(res.data.usuario, false));
		} else {
			return dispatch(logout());
		}
	} else {
		return false;
	}
};

export const login = usuario => ({
	type: Types.LOGIN,
	usuario,
});

export const logout = () => ({
	type: Types.LOGOUT,
});

export const loginAsEC = ecId => async dispatch => {
	let token = localStorage.getItem('token');

	if (token) {
		let res = await axios.get(`${config.get('apiUrl')}login-as-ec/${ecId}`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		if (res.data.success) {
			return dispatch(authenticateAsEC(res.data.usuario.token));
		} else {
			return false;
		}

	}
	return false;
};

export const authenticateAsEC = newToken => ({
	type: Types.LOGIN_AS_EC,
	ecUserToken: newToken,
});
