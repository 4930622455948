/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';

import { Isset } from '../../../utils';

import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(theme => ({
	filtros: {
		position: 'relative',
		display: 'inline-flex',
	},
	filtroText: {
		borderRadius: 4,
		backgroundColor: theme.palette.common.white,
		border: '1px solid #ced4da',
		fontSize: 16,
		padding: '8.5px 12px',
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
		marginLeft: 10,
		marginTop: 8,
	},
	filtroTextSelectedMultiple: {
		backgroundColor: `${theme.palette.primary.main}`,
		color: `${theme.palette.getContrastText(theme.palette.primary.main)} !important`,
		'& .MuiSelect-select': {
			color: `${theme.palette.getContrastText(theme.palette.primary.main)} !important`,
		},
		padding: '2px 10px',
		minHeight: 29,
		maxWidth: 340,
	},
	clearBtn: {
		marginLeft: 6,
	},
	filtroSelect: {
		fontWeight: 700,
		fontSize: 12,
		'&:before': {
			borderBottom: 'none !important',
		},
		'&:after': {
			borderBottom: 'none !important',
		},
		'& .MuiSelect-select': {
			padding: '2px 10px !important',
			marginRight: 10,
		},
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	chip: {
		margin: 2,
		height: 16,
		maxWidth: 130,
		justifyContent: 'flex-start',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		'& .MuiChip-label': {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			display: 'inline-block',
		},
	},
	menuItem: {
		flexDirection: 'column',
	},
}));

const meses = [
	{ id: '01', value: 'Janeiro' },
	{ id: '02', value: 'Fevereiro' },
	{ id: '03', value: 'Março' },
	{ id: '04', value: 'Abril' },
	{ id: '05', value: 'Maio' },
	{ id: '06', value: 'Junho' },
	{ id: '07', value: 'Julho' },
	{ id: '08', value: 'Agosto' },
	{ id: '09', value: 'Setembro' },
	{ id: '10', value: 'Outubro' },
	{ id: '11', value: 'Novembro' },
	{ id: '12', value: 'Dezembro' },
];

export default props => {
	const classes = useStyles();

	let value = '';
	if (Isset(() => props.defaultValue) && props.defaultValue) {
		value = props.defaultValue;
	}

	const [mes, setMes] = useState(value);

	const mesChange = e => {
		let value = e.target.value;
		setMes(value);
		if (props.onChange) {
			props.onChange(value);
		}
	};

	return (
		<div className={classes.estabelecimento}>
			{meses.length > 0 && (
				<div className={classes.filtroText}>
					<Select
						multiple={props.multiple || false}
						className={classes.filtroSelect}
						value={mes}
						onChange={mesChange}
						displayEmpty
					>
						{meses.map((m, i) => (
							<MenuItem key={i} value={`${m.id}`} className={classes.menuItem}>
								{m.value}
							</MenuItem>
						))}
					</Select>
				</div>
			)}
		</div>
	);
};
