import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setColor } from '../../../store/system';
import { makeStyles } from '@material-ui/core/styles';
import { ChromePicker } from 'react-color';
import config from 'react-global-configuration';
import axios from 'axios';

const useStyles = makeStyles(theme => ({
	configuracoes: {
		fontFamily: "'Exo 2', sans-serif",
		width: '100%',
		flexGrow: 1,
	},
	header: {
		fontWeight: 700,
		fontSize: 20,
		margin: 8,
	},
	tabPanels: {
		paddingTop: 10,
	},
	configBox: {
		position: 'relative',
		padding: 10,
		minHeight: 150,
	},
	configHeader: {
		fontWeight: 700,
		fontSize: 16,
		margin: 4,
	},
	configUpdateBtn: {
		textAlign: 'center',
		textTransform: 'uppercase',
		paddingBottom: 15,
		fontWeight: 700,
		fontSize: 12,
		cursor: 'pointer',
		color: 'black',
	},
	colorPicker: {
		display: 'flex',
		justifyContent: 'center',
		'& .MuiTextField-root': {
			width: '100% !important',
		},
	},
	icon: {
		color: 'black',
	},
	updateCheck: {
		position: 'absolute',
		top: 10,
		right: 1,
	},
}));

const LayoutColorSelector = props => {
	const { submit, setSubmit, setSlugUpdated, estabelecimentoId, slug, token } = props;
	const classes = useStyles();
	const dispatch = useDispatch();
	const currentColor = useSelector(state => state.system.color); // Obtém a cor atual do Redux

	// função de envio da cor para o backend
	const handleAtualizarClick = useCallback(
		async (slug, value) => {
			axios
				.post(
					`${config.get(
						'apiUrl'
					)}estabelecimentos/configuracoes/${estabelecimentoId}/${slug.toLowerCase()}/${encodeURIComponent(
						value
					)}`,
					{},
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				)
				.then(_ => {
					setSubmit(false);
					setSlugUpdated(slug);
					setTimeout(_ => {
						setSlugUpdated(null);
					}, 2000);
				});
		},
		[estabelecimentoId, setSubmit, setSlugUpdated, token]
	);

	// useEffect para observar a mudança na propriedade 'submit' e executar a lógica de envio
	useEffect(() => {
		if (submit) {
			handleAtualizarClick(slug, currentColor); // Enviar a cor atualizada para o backend
		}
	}, [submit, slug, currentColor, handleAtualizarClick]);
	//se alterar uma das 3 variaveis o useeffect roda dnv (syntaxe do useeffect)
	// Renderizando o seletor de cores
	return (
		<div className={classes.colorPicker}>
			<ChromePicker
				color={currentColor}
				onChange={color => dispatch(setColor(color.hex))} // Atualizar a cor no Redux usando o dispatch
			/>
		</div>
	);
};

export default LayoutColorSelector;
