/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Axios from 'axios';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ZLoader from '../system/ZLoader';
import ZFiltros from '../system/ZFiltros';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import ZEmpty from '../system/ZEmpty';
import { Formatter } from '../../utils';
import { FaDownload } from 'react-icons/fa';
import config from 'react-global-configuration';
import { makeStyles } from '@material-ui/core/styles';
import ZPaginationDiv from '../system/ZPaginationDiv';
import subDays from 'date-fns/subDays';
import { Button } from '@material-ui/core';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import NovaTransferenciaRelatorio from '../Transferencias/NovaTransferenciaRelatorio';
import { addMessage } from '../../store/alert';
import LastExport from '../Export/LastExport';

const useStyles = makeStyles(theme => ({
	header: {
		fontWeight: 700,
		fontSize: 20,
		margin: 8,
	},

	iconButton: {
		minWidth: 0,
	},

	rowTitle: {
		margin: 0,
		textAlign: 'center',
		fontWeight: 700,
		[theme.breakpoints.down('xs')]: {
			textAlign: 'left',
			marginTop: 24,
		},
	},

	rowText: {
		display: 'inline-block',
		fontWeight: 400,
		marginTop: 8,
	},

	tableRowHeader: {
		fontWeight: 700,
		padding: 5,
		display: 'flex',
		alignItems: 'center',
		borderRadius: 10,
		marginBottom: 2,
		backgroundColor: `${theme.palette.primary.main}1a`,
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}2b`,
		},
	},

	tableRow: {
		fontWeight: 700,
		padding: 5,
		display: 'flex',
		alignItems: 'center',
		borderRadius: 10,
		marginBottom: 2,
		backgroundColor: `${theme.palette.primary.main}0f`,
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}1f`,
		},
	},

	tableRowDanger: {
		fontWeight: 700,
		padding: 5,
		display: 'flex',
		alignItems: 'center',
		borderRadius: 10,
		marginBottom: 2,
		backgroundColor: '#fd2e2e6e',
		'&:hover': {
			backgroundColor: '#fd2e2e79',
		},
	},

	vendas: {
		marginBottom: 10,
	},

	button: {
		height: 37,
		backgroundColor: theme.palette.primary.main,
		border: `solid 1px ${theme.palette.primary.main}`,
		'&:hover': {
			color: '#FFF',
			backgroundColor: `${theme.palette.primary.main}`,
		},
		color: theme.palette.getContrastText(theme.palette.primary.main),
		[theme.breakpoints.down('xs')]: {
			marginTop: 10,
			marginBottom: 10,
		},
	},
}));

export default props => {
	const token = useSelector(store => store.auth.token);
	const classes = useStyles();

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const { pathname } = useLocation();
	const pathnameSlug = pathname.toLowerCase().replace(/[^a-z0-9]/gi, '');
	const [openTransferencia, setOpenTransferencia] = useState(false);

	const filtersCache = useSelector(store => store.filters[pathnameSlug] || {});

	const usuario = useSelector(state => state.auth.usuario);
	const dispatch = useDispatch();
	const [showLastExport, setShowLastExport] = useState(false);

	const queryFilter = {
		data_venda: { de: subDays(new Date(), 1), ate: subDays(new Date(), 1) },
		...filtersCache,
	};

	const [filtros, setFiltros] = useState(queryFilter);
	const filtrosChange = async data => {
		const f = {
			nomeEstabelecimento: data.omni,
		};

		setFiltros(f);
	};

	// Paginação

	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(100);
	const [totalRows, setTotalRows] = useState(0);
	const [estabelecimentoId, setEstabelecimentoId] = useState(null);

	const onChangePage = newPage => {
		setPage(newPage);
	};

	const onChangeRowsPerPage = rowsPerPage => {
		setRowsPerPage(rowsPerPage);
		setPage(1);
	};

	const exportXlsClick = async () => {
		// if (totalRows > 20000) {
		setShowLastExport(true);
		// } else {
		// 	await exportXls();
		// }
	};

	const exportXls = async getLastExports => {
		try {
			await axios.get(`${config.get('apiUrl')}export/relatorios/saldoPorEstabelecimento`, {
				headers: { Authorization: `Bearer ${token}` },
				responseType: 'blob',
				params: {
					filtros: {
						...filtros,
						estabelecimentoId,
					},
				},
			});
			getLastExports();

			dispatch(addMessage({ type: 'warning', message: 'Sua nova exportação está sendo processada.' }));
		} catch (error) {
			console.error('Error exporting data:', error);
			dispatch(addMessage({ type: 'error', message: 'Erro ao exportar os dados.' }));
		}
	};

	const newExport = async getLastExports => {
		await exportXls(getLastExports);
	};

	useEffect(() => {
		const getChargeback = async () => {
			setLoading(true);
			const res = await Axios.get(`${config.get('apiUrl')}relatorios/saldo-por-estabelecimento`, {
				params: {
					...filtros,
					limit: rowsPerPage,
					page,
				},
				headers: { Authorization: `Bearer ${token}` },
			});

			if (res.data.success) {
				setData(res.data.result);
				setTotalRows(res.data.totalRows);
			}
			setLoading(false);
		};

		getChargeback();
	}, [filtros, page, rowsPerPage]);

	return (
		<>
			<Grid className={classes.vendas}>
				<div className={classes.header}>Saldo Por Estabelecimento</div>

				<Paper
					style={{
						position: 'relative',
						marginBottom: 10,
						borderRadius: 4,
						padding: '5px 10px',
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<ZFiltros
						onChange={filtrosChange}
						input={[
							{
								key: 'omni',
								modal: false,
								md: 2,
								defaultValue: filtros.omni,
								placeholder: 'Estabelecimento',
							},
						]}
					/>
					<Grid container item md={1}>
						{usuario.grupo_id !== 7 && (
							<Grid item align="end" md={11} xs={12}>
								<Tooltip title="Exportar">
									<Button
										className={clsx(classes.buttonExport, classes.iconButton)}
										onClick={exportXlsClick}
									>
										<FaDownload className={classes.exportDownload} />
									</Button>
								</Tooltip>
							</Grid>
						)}
					</Grid>
				</Paper>

				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Paper style={{ padding: 15 }}>
							{loading ? (
								<ZLoader height={200} />
							) : (
								<>
									{data.length > 0 ? (
										<Grid container className={classes.vendas}>
											<Grid item xs={12} sm={12}>
												<Paper className={classes.tableRowHeader}>
													<Grid container>
														<Grid item xs={12} sm={6} md={6}>
															<p className={classes.rowTitle}>Estabelecimento</p>
														</Grid>
														<Grid item xs={12} sm={3} md={3}>
															<p className={classes.rowTitle}>Saldo</p>
														</Grid>
														<Grid item xs={12} sm={3} md={3}>
															<p className={classes.rowTitle}></p>
														</Grid>
													</Grid>
												</Paper>
											</Grid>
											{data.map((estabelecimento, key) => (
												<React.Fragment key={key}>
													<Grid item xs={12} sm={12} style={{ margin: '1px 0' }}>
														<Paper className={classes.tableRow}>
															<Grid container style={{ padding: 0 }}>
																<Grid item xs={12} sm={6} md={6}>
																	<p className={classes.rowTitle}>
																		<span className={classes.rowText}>
																			{estabelecimento.nome_fantasia}
																		</span>
																		<div style={{ fontSize: 10 }}>
																			{`${estabelecimento.razao_social} - `}
																			{estabelecimento.identificacao_fatura}
																		</div>
																	</p>
																</Grid>
																<Grid item xs={12} sm={3} md={3}>
																	<p className={classes.rowTitle}>
																		<span className={classes.rowText}>
																			{Formatter.Real(estabelecimento.saldoZoop)}
																		</span>
																	</p>
																</Grid>
																{estabelecimento.saldoZoop > 0 && (
																	<Grid
																		item
																		xs={12}
																		sm={3}
																		md={3}
																		style={{
																			display: 'flex',
																			justifyContent: 'center',
																		}}
																	>
																		<Button
																			// variant="contained"
																			// size="small"
																			className={classes.button}
																			onClick={() => {
																				setOpenTransferencia(true);
																				setEstabelecimentoId(
																					estabelecimento.id
																				);
																			}}
																		>
																			Transferir
																		</Button>
																	</Grid>
																)}
															</Grid>
														</Paper>
													</Grid>
												</React.Fragment>
											))}
										</Grid>
									) : (
										<ZEmpty height={200} text="Nenhum Resultado Encontrado." />
									)}
								</>
							)}
						</Paper>

						<ZPaginationDiv
							count={totalRows}
							rowsPerPage={rowsPerPage}
							page={page - 1}
							onChangePage={onChangePage}
							onChangeRowsPerPage={onChangeRowsPerPage}
							rowsPerPageOptions={[50, 100, 200]}
						/>
					</Grid>
				</Grid>
				{openTransferencia && (
					<NovaTransferenciaRelatorio
						estabelecimentoId={estabelecimentoId}
						open={true}
						onClose={() => setOpenTransferencia(false)}
					/>
				)}
				{showLastExport && (
					<LastExport
						show={showLastExport}
						onClose={() => setShowLastExport(false)}
						url={'/export/saldoPorEstabelecimento'}
						newExport={newExport}
						loadingProp={loading}
					/>
				)}
			</Grid>
		</>
	);
};
