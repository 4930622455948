/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, memo, useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import Button from '@material-ui/core/Button';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
// import InfiniteScroll from 'react-infinite-scroll-component';
import TablePagination from '@material-ui/core/TablePagination';
import Tooltip from '@material-ui/core/Tooltip';
import axios from 'axios';
import config from 'react-global-configuration';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedEC } from '../../store/system';
import { useHistory, useLocation } from 'react-router-dom';
import FileDownload from 'js-file-download';

import moment from 'moment';
import ZTitle from '../system/ZTitle';
import ZMenuOptions from '../system/ZMenuOptions';
import ZFiltros from '../system/ZFiltros';
import { Formatter, Isset } from '../../utils';
import { FaDownload, FaExclamationCircle } from 'react-icons/fa';
import yellow from '@material-ui/core/colors/yellow';
import ZLoader from '../system/ZLoader';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { addMessage } from '../../store/alert';
import { loginAsEC } from '../../store/auth';
import ZModalConfirm from '../system/ZModalConfirm';
import ZModalToken from '../system/ZModalToken';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';
import NovaTransferencia from '../Transferencias/NovaTransferencia';
import ZModal from '../system/ZModal';
import RepassarTaxa from './repassarTaxa';
import AlterarAfiliacaoDoEC from './AlterarAfiliacaoDoEC';

const useStyles = makeStyles(theme => ({
	buttonExport: {
		color: '#040633',
		border: `solid 1px #040633`,
		'&:hover': {
			color: '#FFF',
			backgroundColor: '#011d29',
			'& svg': {
				color: '#FFF',
			},
		},
		[theme.breakpoints.down('xs')]: {
			width: '90%',
			marginTop: 16,
		},
		'& svg': {
			margin: 0,
		},
	},
	iconButton: {
		minWidth: 0,
	},
	btn: {
		marginBottom: 10,
		backgroundColor: '#ce8290',
		color: theme.palette.getContrastText('#ce8290'),
		'&:hover': {
			backgroundColor: '#ce8290dd',
		},
	},
	popover: {
		pointerEvents: 'none',
	},
	paper: {
		padding: theme.spacing(1),
	},
}));

const getStatusLabel = status => {
	switch (+status) {
		case 2:
			return (
				<Chip
					size="small"
					label="Habilitado"
					style={{
						backgroundColor: '#43a047',
						color: 'white',
						fontSize: 10,
						textTransform: 'uppercase',
						fontWeight: 700,
					}}
				/>
			);
		case 3:
			return (
				<Chip
					size="small"
					label="Inativo"
					style={{
						backgroundColor: '#e53935',
						color: 'white',
						fontSize: 10,
						textTransform: 'uppercase',
						fontWeight: 700,
					}}
				/>
			);
		default:
			return (
				<Chip
					size="small"
					label="Aguardando Aprovação"
					style={{
						backgroundColor: '#e6c038',
						color: 'black',
						fontSize: 10,
						textTransform: 'uppercase',
						fontWeight: 700,
					}}
				/>
			);
	}
};

const DiasOcioso = props => {
	const { inativoDesde } = props;
	var timeDiff = Math.abs(new Date().getTime() - new Date(inativoDesde).getTime());
	var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
	return `Ocioso a ${diffDays} ${diffDays > 1 ? 'dias' : 'dia'}`;
};

const EstabelecimentoRow = memo(props => {
	const { estabelecimento } = props;

	const [openTransferencia, setOpenTransferencia] = useState(false);

	const history = useHistory();
	const dispatch = useDispatch();
	const usuario = useSelector(store => store.auth.usuario);
	const visibleAlterarMarketplace = estabelecimento.parent_id ? true : false;
	const operarMarketplace = useSelector(store =>
		Isset(() => store.system.params['4'].valor) ? store.system.params['4'].valor : null
	);
	const repasseSindicato = usuario.estabelecimento.marketplace.configuracoes.repasse_sindicato;

	const taxasRecorrentes = useSelector(store => {
		return Isset(() => store.system.params['29'].valor) ? store.system.params['29'].valor : null;
	});

	const canViewTaxas = taxasRecorrentes && [1, 2, 7, 10].includes(usuario.grupo_id) && operarMarketplace;

	const HtmlTooltip = withStyles(theme => ({
		tooltip: {
			backgroundColor: '#f5f5f9',
			color: 'rgba(0, 0, 0, 0.87)',
			maxWidth: 320,
			fontSize: theme.typography.pxToRem(12),
			border: '1px solid #dadde9',
			display:
				estabelecimento.plano_taxa_estabelecimento &&
				estabelecimento.plano_taxa_estabelecimento.plano_taxa_marketplace
					? ''
					: 'none',
		},
	}))(Tooltip);

	return (
		<>
			<HtmlTooltip
				title={
					<React.Fragment>
						<Typography color="inherit">Tabela Markup : </Typography>
						{estabelecimento.plano_taxa_estabelecimento &&
						estabelecimento.plano_taxa_estabelecimento.plano_taxa_marketplace
							? estabelecimento.plano_taxa_estabelecimento.plano_taxa_marketplace.titulo
							: ' - '}
					</React.Fragment>
				}
			>
				<TableRow>
					<TableCell component="th" scope="row">
						{estabelecimento.nome_fantasia}
						<div style={{ fontSize: 10 }}>
							{`${estabelecimento.razao_social} - `}
							{estabelecimento.identificacao_fatura}
						</div>
					</TableCell>
					<TableCell align="right">
						{estabelecimento.documento && (
							<>
								{estabelecimento.documento.length <= 11
									? Formatter.Cpf(estabelecimento.documento)
									: Formatter.Cnpj(estabelecimento.documento)}
							</>
						)}
					</TableCell>
					<TableCell align="right">{estabelecimento.email}</TableCell>
					<TableCell align="right">
						{moment(estabelecimento.created, 'YYYY-MM-DD').format('DD/MM/YYYY')}
					</TableCell>
					{[1, 7, 10].includes(usuario.grupo_id) && (
						<TableCell align="right">{estabelecimento.repMaster}</TableCell>
					)}
					<TableCell align="right">
						<Grid>
							<div
								style={{
									display: 'flex',
									justifyContent: 'right',
									alignItems: 'right',
									height: '100%',
								}}
							>
								{estabelecimento.inativo_desde !== null && (
									<div style={{ marginRight: 5 }}>
										<Tooltip title={<DiasOcioso inativoDesde={estabelecimento.inativo_desde} />}>
											<div>
												<FaExclamationCircle style={{ color: yellow[700] }} />
											</div>
										</Tooltip>
									</div>
								)}
								<div>{getStatusLabel(estabelecimento.status_estabelecimento_id)}</div>
							</div>
						</Grid>
					</TableCell>
					<TableCell align="right">
						<ZMenuOptions
							data={[
								{
									title: 'Entrar Como Estabelecimento',
									visible:
										[1].includes(usuario.grupo_id) || (usuario.grupo_id === 2 && operarMarketplace),
									onClick: async () => {
										await dispatch(loginAsEC(estabelecimento.id));
										// await dispatch(checkLogin());
										history.push('/');
										window.location.reload();
									},
								},
								{
									title: 'Visualizar Estabelecimento',
									visible: [5, 8].includes(usuario.grupo_id),
									onClick: () => {
										dispatch(
											setSelectedEC({
												id: estabelecimento.id,
												nome: estabelecimento.nome_fantasia,
											})
										);
										history.push('/estabelecimentos/visualizar/' + estabelecimento.id);
									},
								},
								{
									title: 'Editar Estabelecimento',
									visible: [1, 2, 3, 7, 10, 13].includes(usuario.grupo_id),
									onClick: () => {
										dispatch(
											setSelectedEC({
												id: estabelecimento.id,
												nome: estabelecimento.nome_fantasia,
											})
										);
										history.push('/estabelecimentos/editar/' + estabelecimento.id);
									},
								},
								{
									title: 'Token',
									visible: [1, 2, 7, 10].includes(usuario.grupo_id),
									onClick: () => {
										props.onSolicitarClick(estabelecimento.id);
									},
								},
								{
									title: 'Alterar marketplace',
									visible: [1].includes(usuario.grupo_id) && visibleAlterarMarketplace,
									onClick: () => {
										props.setAlterarAfiliacaoDoEC({
											estabelecimentoId: estabelecimento.id,
											open: true,
										});
									},
								},
								{
									title: 'Cadastrar Taxa Recorrente',
									// visible: [1, 2, 7, 10].includes(usuario.grupo_id) && [1, 30, 20].includes(usuario.estabelecimento.marketplace.id),
									visible: canViewTaxas,
									onClick: () => {
										history.push(`/estabelecimentos/${estabelecimento.id}/cobranca_recorrente`);
									},
								},
								// {
								// 	title: 'Configurar Split',
								// 	visible: usuario.grupo_id <= 2,
								// 	onClick: () => {
								// 		dispatch(
								// 			setSelectedEC({
								// 				id: estabelecimento.id,
								// 				nome: estabelecimento.nome_fantasia,
								// 			})
								// 		);
								// 		history.push('/estabelecimentos/configurar-split/' + estabelecimento.id);
								// 	},
								// },
								{
									title: 'Configurações',
									visible: [1, 2, 5, 7, 10, 11, 13, 14, 15].includes(usuario.grupo_id),
									onClick: () => {
										dispatch(
											setSelectedEC({
												id: estabelecimento.id,
												nome: estabelecimento.nome_fantasia,
											})
										);
										history.push('/estabelecimentos/configuracoes/' + estabelecimento.id);
									},
								},
								// {
								// 	title: 'Visualizar Taxas',
								// 	visible: usuario.grupo_id <= 2 || usuario.grupo_id === 7,
								// 	onClick: () => {
								// 		dispatch(
								// 			setSelectedEC({
								// 				id: estabelecimento.id,
								// 				nome: estabelecimento.nome_fantasia,
								// 			})
								// 		);
								// 		history.push('/estabelecimentos/taxas/' + estabelecimento.id);
								// 	},
								// },
								{
									title: 'Repassar taxa sindicato',
									visible: [1, 2].includes(usuario.grupo_id) && repasseSindicato === 'true',
									onClick: () =>
										props.setShowRepasseSindicato({ estabelecimento: estabelecimento, open: true }),
								},
								{
									title: 'Desabilitar',
									visible:
										estabelecimento.status_estabelecimento_id === 2 &&
										[1, 7, 10].includes(usuario.grupo_id),
									onClick: () => {
										props.onDesabilitarClick(estabelecimento.id);
									},
								},
								{
									title: 'Excluir',
									visible:
										[1].includes(usuario.grupo_id) ||
										([2].includes(usuario.grupo_id) && operarMarketplace),
									onClick: () => {
										props.onExcluirClick(estabelecimento.id);
									},
								},
								{
									title: 'Realizar Transferência',
									visible: [13, 14].includes(usuario.grupo_id),
									onClick: async () => setOpenTransferencia(true),
								},
							]}
						/>
					</TableCell>
				</TableRow>
			</HtmlTooltip>
			{openTransferencia && (
				<NovaTransferencia
					open={true}
					onClose={() => setOpenTransferencia(false)}
					documento={estabelecimento.documento}
				/>
			)}
		</>
	);
});

export default props => {
	const history = useHistory();
	const dispatch = useDispatch();
	const classes = useStyles();
	const token = useSelector(store => store.auth.token);
	const usuario = useSelector(store => store.auth.usuario);
	const estabelecimentoAdmin = useSelector(store => store.admin.estabelecimento);
	// const axiosCancelSource = axios.CancelToken.source();

	const { pathname } = useLocation();
	const pathnameSlug = pathname.toLowerCase().replace(/[^a-z0-9]/gi, '');

	const marketplacesFilhos = pathnameSlug === 'estabelecimentosmarketplacefilho' ? true : false;

	const filtersCache = useSelector(store => store.filters[pathnameSlug] || {});

	const [estabelecimentos, setEstabelecimentos] = useState([]);

	const [novoMarketplace, setNovoMarketplace] = useState('');
	const [loadingSalvarNovoMarketplace, setLoadingSalvarNovoMarketplace] = useState(false);
	const [alterarAfiliacaoDoEC, setAlterarAfiliacaoDoEC] = useState({
		estabelecimentoId: null,
		open: false,
	});
	const salvarNovoMarketplace = async () => {
		if (novoMarketplace) {
			const data = {
				parentId: novoMarketplace,
			};
			try {
				setLoadingSalvarNovoMarketplace(true);
				const response = await axios.put(
					`https://pas.nectaco.com.br/establishment/${alterarAfiliacaoDoEC.estabelecimentoId}/change-parent`,
					data
				);
				if (response.data.success) {
					setLoadingSalvarNovoMarketplace(false);
					setAlterarAfiliacaoDoEC({ open: false });
					dispatch(addMessage({ type: 'success', message: 'Marketplace alterado com sucesso' }));
				}
			} catch (error) {
				if (error.response.data) {
					switch (error.response.data.message) {
						case 'Parent not found':
							dispatch(addMessage({ type: 'error', message: 'Pai não encontrado' }));
							break;
						default:
							dispatch(addMessage({ type: 'error', message: error.response.data.message }));
							break;
					}
					setLoadingSalvarNovoMarketplace(false);
				}
			}
		} else {
			dispatch(addMessage({ type: 'error', message: 'Preencha os campos corretamente.' }));
			setLoadingSalvarNovoMarketplace(false);
		}
	};

	const [showExportMessage, setShowExportMessage] = useState(false);
	const [estabelecimentoId, setEstablecimentoId] = useState(null);
	const [tokenEC, setTokenEC] = useState({
		tokenEstabelecimento: null,
		open: false,
	});
	const [sellerId, setSellerId] = useState('');

	const [desabilitarEC, setDesabilitarEC] = useState({
		ecId: null,
		open: false,
	});

	const [excluirEC, setExcluirEC] = useState({
		ecId: null,
		open: false,
	});

	const [loading, setLoading] = useState(true);
	// const [page, setPage] = useState(1);
	// const [rowsPerPage] = useState(15);
	const [totalRows, setTotalRows] = useState(0);
	// const [hasMore, setHasMore] = useState(false);
	const [filtros, setFiltros] = useState({
		omni: '',
		parentId: Isset(() => estabelecimentoAdmin.id) ? estabelecimentoAdmin.id : null,
		situacaoEstabelecimento: null,
		...filtersCache,
	});
	const [confirmRequest, setConfirmRequest] = useState(false);
	const [showRepasseSindicato, setShowRepasseSindicato] = useState({
		estabelecimento: '',
		open: false,
	});
	const [loaderSindicato, setLoaderSindicato] = useState(false);

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(100);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = event => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	const getData = async () => {
		setLoading(true);
		// setPage(1);
		let res = await axios.get(`${config.get('apiUrl')}estabelecimentos/filhos`, {
			// cancelToken: axiosCancelSource.token,
			headers: { Authorization: `Bearer ${token}` },
			params: {
				page,
				marketplacesFilhos,
				limit: rowsPerPage,
				filters: {
					...filtros,
				},
			},
		});

		if (res.data.success) {
			setEstabelecimentos(res.data.estabelecimentos);
			setTotalRows(res.data.quantidade);
			// setHasMore(res.data.quantidade > res.data.estabelecimentos.length);
		}
		setLoading(false);
	};

	const getOptions = () => {
		const result = {
			situacaoEstabelecimento: [
				{ id: '0', name: 'Ativo' },
				{ id: '1', name: 'Ocioso' },
			],
		};

		return result;
	};

	useEffect(() => {
		getData();
		// return async () => {
		// 	axiosCancelSource.cancel('Transferencias Unmounted.');
		// };
	}, [filtros, page, rowsPerPage]);

	useEffect(() => {
		setFiltros({ ...filtros, parentId: Isset(() => estabelecimentoAdmin.id) ? estabelecimentoAdmin.id : null });
	}, [estabelecimentoAdmin]);

	// const getMoreData = async () => {
	// 	// setPage(page + 1);
	// 	setLoading(true);
	// 	let res = await axios.get(`${config.get('apiUrl')}estabelecimentos/filhos`, {
	// 		headers: { Authorization: `Bearer ${token}` },
	// 		params: {
	// 			page: page,
	// 			limit: rowsPerPage,
	// 			filters: {
	// 				...filtros,
	// 			},
	// 		},
	// 	});
	// 	if (res.data.success) {
	// 		const newData = [...estabelecimentos, ...res.data.estabelecimentos];
	// 		setEstabelecimentos(newData);
	// 		// setHasMore(totalRows > newData.length);
	// 	}
	// 	setLoading(false);
	// };

	const filtrosChange = data => {
		setPage(0);

		data.documento = data.documento ? data.documento.replace(/[^\d]+/g, '') : '';

		setFiltros({
			...filtros,
			...data,
		});
	};

	const exportXls = async () => {
		setShowExportMessage(true);
		const estabelecimentos = await axios.get(`${config.get('apiUrl')}export/estabelecimentos`, {
			headers: { Authorization: `Bearer ${token}` },
			responseType: 'blob',
			params: {
				marketplacesFilhos,
				filters: {
					...filtros,
				},
			},
		});

		if (estabelecimentos.status === 200) {
			const { 'content-disposition': contentDisposition, 'content-type': contentType } = estabelecimentos.headers;
			let filename = 'estabelecimentos.xlsx';
			if (contentDisposition.indexOf('filename="') !== -1) {
				filename = contentDisposition.slice(
					contentDisposition.indexOf('filename="') + 10,
					contentDisposition.length - 1
				);
			}

			FileDownload(estabelecimentos.data, filename, contentType);
		} else {
			dispatch(addMessage({ type: 'error', message: 'Erro ao exportar estabelecimentos.' }));
		}
		setShowExportMessage(false);
	};

	const handleDesabilitarEC = result => {
		const ecId = desabilitarEC.ecId;
		setDesabilitarEC({ ...desabilitarEC, open: false, ecId: null });

		if (result) {
			axios
				.delete(`${config.get('apiUrl')}estabelecimentos/${ecId}/desabilitar`, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				})
				.then(res => res.data)
				.then(data => {
					if (data.success) {
						dispatch(addMessage({ type: 'success', message: 'Estabelecimento desabilitado com sucesso' }));
						getData();
					}
				});
		}
	};

	const handleExcluirEC = result => {
		const ecId = excluirEC.ecId;
		setExcluirEC({ ...excluirEC, open: false, ecId: null });

		if (result) {
			axios
				.delete(`${config.get('apiUrl')}estabelecimentos/${ecId}/excluir`, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				})
				.then(res => res.data)
				.then(data => {
					if (data.success) {
						dispatch(addMessage({ type: 'success', message: 'Estabelecimento excluído com sucesso' }));
						getData();
					}
				});
		}
	};

	const handleSoliticarTokenEC = useCallback(ecId => {
		setEstablecimentoId(ecId);

		axios
			.get(`${config.get('apiUrl')}estabelecimentos/${ecId}/token`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then(response => response.data)
			.then(data => {
				setTokenEC({ open: true, tokenEstabelecimento: data.token });
				setSellerId(data.sellerId);
			})
			.catch(error => alert(`${error}`));
	}, []);

	return (
		<Grid>
			<ZTitle
				title={marketplacesFilhos ? 'Marketplaces Filhos' : 'Estabelecimentos'}
				button="add"
				handleOnClick={() => {
					// if ([5, 8].includes(usuario.grupo_id)) {
					// 	history.push('/estabelecimentos/pre-cadastro');
					// } else {
					history.push('/estabelecimentos/adicionar');
					// }
				}}
			/>
			<Grid container>
				{[1, 7, 10].includes(usuario.grupo_id) && !marketplacesFilhos && (
					<Grid item xs={12}>
						<Button
							//fazer a validação a cima em includes

							variant="contained"
							size="small"
							className={classes.btn}
							onClick={() => history.push('/estabelecimentos/desabilitados')}
						>
							Ver Desabilitados
						</Button>
					</Grid>
				)}
				<Grid item xs={12}>
					<Paper
						style={{
							position: 'relative',
							marginBottom: 10,
							borderRadius: 4,
							padding: '5px 10px',
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<ZFiltros
							onChange={filtrosChange}
							input={[
								{
									key: 'omni',
									placeholder: 'Empresa',
									defaultValue: filtros.omni,
									modal: false,
								},
								{
									key: 'documento',
									placeholder: 'CPF/CNPJ',
									defaultValue: filtros.documento || '',
									modal: false,
									mask: 'documento',
								},
								{
									key: 'nomeComprovante',
									placeholder: 'Nome do Comprovante',
									defaultValue: filtros.nomeComprovante || '',
									modal: false,
								},
							]}
							selectField={[
								{
									key: 'situacaoEstabelecimento',
									defaultValue: filtros.situacaoEstabelecimento,
									placeholder: 'Situação',
									multiple: false,
									options: getOptions().situacaoEstabelecimento,
									modal: false,
									md: 2,
								},
							]}
						/>
						<Tooltip title="Exportar">
							<Button className={clsx(classes.buttonExport, classes.iconButton)} onClick={exportXls}>
								<FaDownload className={classes.exportDownload} />
							</Button>
						</Tooltip>
						{/* <FaFileExcel className={classes.export} onClick={exportXls} /> */}
					</Paper>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={12}>
					<Paper style={{ overflowX: 'auto', width: 'calc(100vw - 30px)' }}>
						{loading ? (
							<ZLoader height={200} message="Carregando Estabelecimentos" />
						) : (
							// <InfiniteScroll
							// 	dataLength={estabelecimentos.length}
							// 	next={getMoreData}
							// 	hasMore={hasMore}
							// 	loader={<h4 style={{ textAlign: 'center' }}>Carregando...</h4>}
							// >
							<>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell>Nome</TableCell>
											<TableCell align="right">CPF / CNPJ</TableCell>
											<TableCell align="right">E-mail</TableCell>
											<TableCell align="right">Data Criação</TableCell>
											{[1, 7, 10].includes(usuario.grupo_id) && (
												<TableCell align="right">Representante</TableCell>
											)}
											<TableCell align="right">Status</TableCell>
											<TableCell align="right">Opções</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{estabelecimentos.map((value, key) => (
											<EstabelecimentoRow
												key={`estab-${key}`}
												estabelecimento={value}
												onSolicitarClick={ecId => handleSoliticarTokenEC(ecId)}
												setAlterarAfiliacaoDoEC={setAlterarAfiliacaoDoEC}
												onDesabilitarClick={ecId => {
													setDesabilitarEC({
														...desabilitarEC,
														ecId,
														open: true,
													});
												}}
												onExcluirClick={ecId => {
													setExcluirEC({
														...excluirEC,
														ecId,
														open: true,
													});
												}}
												setShowRepasseSindicato={setShowRepasseSindicato}
											/>
										))}
									</TableBody>
								</Table>
								<TablePagination
									rowsPerPageOptions={[15, 30, 60, 100]}
									component="div"
									count={totalRows}
									labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
									labelRowsPerPage={'Resultados por página:'}
									rowsPerPage={rowsPerPage}
									page={page}
									onPageChange={handleChangePage}
									onChangeRowsPerPage={handleChangeRowsPerPage}
								/>
							</>
							// </InfiniteScroll>
						)}
					</Paper>
				</Grid>
			</Grid>

			<Dialog open={showExportMessage}>
				<DialogContent style={{ width: 200 }}>
					<ZLoader height={100} size={26} message={'Exportando'} />
				</DialogContent>
			</Dialog>

			<ZModalConfirm
				title={`Desabilitar Estabelecimento`}
				message={`Deseja desabilitar esse estabelecimento?`}
				open={desabilitarEC.open}
				onClose={handleDesabilitarEC}
			/>

			<ZModalConfirm
				title={`Excluir Estabelecimento`}
				message={`Deseja excluir esse estabelecimento?`}
				submessage={`Ao excluir um estabelecimento, ele também será excluido da Zoop. Essa ação não poderá ser desfeita!`}
				open={excluirEC.open}
				onClose={handleExcluirEC}
			/>

			<ZModal
				content={
					<RepassarTaxa
						confirmRequest={confirmRequest}
						setConfirmRequest={setConfirmRequest}
						estabelecimentoId={showRepasseSindicato.estabelecimento.id}
						setShowRepasseSindicato={setShowRepasseSindicato}
						showRepasseSindicato={showRepasseSindicato}
						setLoader={setLoaderSindicato}
					/>
				}
				open={showRepasseSindicato.open}
				title={`Repassar Taxa`}
				handleClose={() =>
					setShowRepasseSindicato({
						estabelecimento: '',
						open: false,
					})
				}
				handleOnSave={() => {
					if (!loaderSindicato) {
						setConfirmRequest(true);
					}
				}}
				loader={loaderSindicato}
			/>
			<ZModal
				content={
					<AlterarAfiliacaoDoEC
						estabelecimentoId={alterarAfiliacaoDoEC.estabelecimentoId}
						setNovoMarketplace={setNovoMarketplace}
					/>
				}
				title={`Alterar marketplace`}
				open={alterarAfiliacaoDoEC.open}
				handleClose={() =>
					setAlterarAfiliacaoDoEC({
						estabelecimento: '',
						open: false,
					})
				}
				handleOnSave={async () => {
					if (salvarNovoMarketplace) {
						await salvarNovoMarketplace();
					}
				}}
				loader={loadingSalvarNovoMarketplace}
			/>

			<ZModalToken
				title={`Token Estabelecimento`}
				open={tokenEC.open}
				value={[
					{ label: 'Estabelecimento', value: estabelecimentoId },
					{ label: 'Zoop Seller ID', value: sellerId },
					{ label: 'Token', value: tokenEC.tokenEstabelecimento },
				]}
				handleClose={() => {
					setTokenEC({ open: false, tokenEstabelecimento: null });
					setEstablecimentoId(null);
				}}
			/>
		</Grid>
	);
};
//https://pas-aps.up.railway.app/${novoMarketplace}/change-parent
