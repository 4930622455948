/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from 'react-global-configuration';

import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ZLoader from '../system/ZLoader';
import ZModalConfirm from '../system/ZModalConfirm';
import PlanoDeVenda from './PlanoDeVenda';
import ZPaginationDiv from '../system/ZPaginationDiv';
import ZFiltros from '../system/ZFiltros';

import Zoom from '@material-ui/core/Zoom';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import ZModalWarning from '../system/ZModalWarning';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { makeStyles } from '@material-ui/styles';
import { addMessage } from '../../store/alert';
import { useDispatch } from 'react-redux';
import { Formatter, Isset, getGrupoPermission } from '../../utils';

import { Button } from '@material-ui/core';

import isWithinInterval from 'date-fns/isWithinInterval';
import addMinutes from 'date-fns/addMinutes';

const getFormaDePagamento = formaDePagamento => {
	switch (formaDePagamento) {
		case 'boleto':
			return 'Boleto';
		case 'credit':
			return 'Crédito';
		case 'debit':
			return 'Débito';
		case 'pix':
			return 'Pix';
		case 'bolepix':
			return 'Bolepix';
		default:
			return null;
	}
};

const useStyles = makeStyles(theme => ({
	chevron: {
		position: 'absolute',
		right: 10,
		top: 10,
	},
	brandTitle: {
		textAlign: 'center',
		fontWeight: 700,
	},
	taxa: {
		position: 'relative',
		cursor: 'pointer',
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.getContrastText(theme.palette.primary.main),
		marginTop: 5,
		borderRadius: 5,
		padding: 6,
		fontWeight: 700,
	},
	taxaDetalhes: {
		width: '98%',
		borderWidth: 1,
		borderColor: theme.palette.primary.main,
		borderStyle: 'solid',
		borderRadius: 5,
		padding: 6,
		margin: 'auto',
		marginTop: -5,
		paddingTop: 10,
	},
	empty: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: 18,
		fontWeight: 700,
		height: 200,
	},
	ImportButton: {
		margin: '8px',
		width: 200,
		backgroundColor: theme.palette.primary.main,
		color: '#FFF',
		'&:hover': {
			color: '#FFF',
			backgroundColor: `${theme.palette.primary.main}`,
		},
	},
}));

export default props => {
		// const { match, location } = useReactRouter();
	// const history = useHistory();
	const location = useLocation();
	// const match = location.match;
	const dispatch = useDispatch();
	const classes = useStyles();
	let estabelecimentoId = undefined; // match.params;

	if (!estabelecimentoId) {
		({ estabelecimentoId } = props);
	}

	const token = useSelector(store => store.auth.token);
	const usuario = useSelector(store => store.auth.usuario);
	const marketplaceId = usuario.marketplace.id;
	const operarMarketplace = useSelector(store =>
		Isset(() => store.system.params['4'].valor) ? store.system.params['4'].valor : null
	);

	const [showButtonImportPlan, setShowButtonImportPlan] = useState(false);
	useEffect(() => {
		const usuarioPermission = getGrupoPermission(usuario.grupo_id);

        if (usuarioPermission[1] === 'admin' && operarMarketplace) {
			setShowButtonImportPlan(true);
			}
	}, [usuario, operarMarketplace]);
const { pathname } = location;
	const pathnameSlug = pathname.toLowerCase().replace(/[^a-z0-9]/gi, '');
	const filtersCache = useSelector(store => store.filters[pathnameSlug] || {});

	const [loading, setLoading] = useState(true);
	const [loadingImportPlan, setLoadingImportPlan] = useState(false);
	const [planos, setPlanos] = useState([]);
	const [planoAtivoId, setPlanoAtivoId] = useState(null);

	const [viewPlanoId, setViewPlanoId] = useState(null);
	const [viewOpen, setViewOpen] = useState(false);
	const [viewPlano, setViewPlano] = useState(null);
	const [active, setActive] = useState(null);
	const [selectPlan, setSelectPlan] = useState({
		open: false,
		id: null,
	});

	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(32);
	const [totalRows, setTotalRows] = useState(0);

	const [showModal, setShowModal] = useState(false);

	const queryFilter = {
		omni: '',
		...filtersCache,
	};

	const [filtros, setFiltros] = useState({
		...queryFilter,
	});

	useEffect(() => {
		setLoading(true);
		// const axiosCancelSource = axios.CancelToken.source();

		const getPlanos = async () => {
			let res = await axios.get(`${config.get('apiUrl')}planos_de_vendas`, {
				params: {
					visible: +![1, 2, 7, 10].includes(usuario.grupo_id),
					limit: rowsPerPage,
					page,
					filtros,
				},
				// cancelToken: axiosCancelSource.token,
				headers: { Authorization: `Bearer ${token}` },
			});

			if (res.data.success) {
				setPlanos(res.data.planos);
				setTotalRows(res.data.totalRows);
			}

			setLoading(false);
		};

		const getPlanoEstabelecimento = async () => {
			let res = await axios.get(`${config.get('apiUrl')}planos_de_vendas/estabelecimento/${estabelecimentoId}`, {
				// cancelToken: axiosCancelSource.token,
				headers: { Authorization: `Bearer ${token}` },
			});

			if (res.data.success && Isset(() => res.data.plano.zoop_plan_id)) {
				setPlanoAtivoId(res.data.plano.zoop_plan_id);
			}
		};

		getPlanos();
		getPlanoEstabelecimento();

		// return async () => {
		// 	await axiosCancelSource.cancel('Plano de Vendas Unmounted.');
		// };
	}, [filtros, page, rowsPerPage]);

	useEffect(() => {
		if (planoAtivoId && planos.length) {
			setPlanos(planos.sort((a, b) => (a.id === planoAtivoId ? -1 : 1)));
		}
	}, [planoAtivoId, planos]);

	const onSelect = async planoId => {
		setSelectPlan({
			open: true,
			id: planoId,
		});
	};

	const onChangePage = newPage => {
		setPage(newPage);
	};

	const onChangeRowsPerPage = rowsPerPage => {
		setRowsPerPage(rowsPerPage);
		setPage(1);
	};

	const filtrosChange = async data => {
		setPage(1);
		setFiltros(data);
	};

	const handleSelectPlan = async confirm => {
		if (confirm) {
			setLoading(true);
			let res = await axios.post(
				`${config.get('apiUrl')}planos_de_vendas/estabelecimento/${estabelecimentoId}/${selectPlan.id}`,
				{},
				{
					headers: { Authorization: `Bearer ${token}` },
				}
			);

			if (res.data.success) {
				setPlanoAtivoId(selectPlan.id);
			} else {
				const messagemDeErro = res.data.message;
				const numeroDoErro = messagemDeErro.substring(0, 3);

				let mensagemDeErroFinal;

				switch (numeroDoErro) {
					case '422':
						mensagemDeErroFinal = 'O MCC do estabelecimento deve ser o mesmo do plano de venda.';
						break;
					default:
						mensagemDeErroFinal = 'Ocorreu um erro inesperado. Por favor, entre em contato com o suporte ';
				}

				dispatch(addMessage({ type: 'error', message: mensagemDeErroFinal }));
			}

			setLoading(false);
		}

		setSelectPlan({
			open: false,
			id: null,
		});
	};

	const onView = async planoId => {
		setViewOpen(true);
		let res = await axios.post(
			`${config.get('apiUrl')}planos_de_vendas/${planoId}`,
			{},
			{
				headers: { Authorization: `Bearer ${token}` },
			}
		);

		if (res.data.success && res.data.plano) {
			setViewPlanoId(planoId);
			setViewPlano(res.data.plano);
		}
	};

	const handleViewClose = () => {
		setViewOpen(false);
		setViewPlanoId(null);
	};

	const handleImportPlans = async () => {
		setLoadingImportPlan(true);

		const dataImportarPlanos = localStorage.getItem('importarVendas');
		const intervalo = isWithinInterval(new Date(), {
			start: new Date(dataImportarPlanos),
			end: addMinutes(new Date(dataImportarPlanos), 5),
		});

		if (!intervalo) {
			let res = await axios.post(
				`${config.get('apiUrl')}planos_de_vendas/${marketplaceId}/importar`,
				{},
				{
					headers: { Authorization: `Bearer ${token}` },
				}
			);
			if (res.data.success) {
				localStorage.setItem('importarVendas', new Date());
				setLoadingImportPlan(false);
				setShowModal(true);
			}
			setLoadingImportPlan(false);
			dispatch(addMessage({ type: 'error', message: res.data.error }));
		}
		setShowModal(true);
		setLoadingImportPlan(false);
	};

	return (
		<div>
			<Paper style={{ marginBottom: 10, padding: '5px 10px' }}>
					<div
					style={{
						position: 'relative',
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<ZFiltros
						onChange={filtrosChange}
						input={[
							{
								key: 'omni',
								placeholder: 'Plano de Venda',
								defaultValue: filtros.omni,
								modal: false,
								md: 3,
							},
						]}
					/>
					{showButtonImportPlan && (
						<>
							<Button className={classes.ImportButton} onClick={handleImportPlans}>
								{loadingImportPlan ? <ZLoader height={50} size={25} /> : 'Importar Planos'}
							</Button>
						</>
					)}
					</div>
			</Paper>

			<Zoom in>
				{loading ? (
					<ZLoader height={200} size={50} />
				) : (
					<Grid container spacing={2}>
						{planos.length > 0 ? (
							<>
								{planos.map((plano, key) => (
									<Grid item key={key} md={3} sm={6} xs={12}>
										<PlanoDeVenda
											onSelect={onSelect}
											onView={onView}
											plano={plano}
											ativo={planoAtivoId === plano.id}
										/>
									</Grid>
								))}
							</>
						) : (
							<Grid item xs={12}>
								<div className={classes.empty}>Nenhum Plano de Venda Encontrado.</div>
							</Grid>
						)}
					</Grid>
				)}
			</Zoom>
			<div style={{ width: '100%' }}>
				<ZPaginationDiv
					count={totalRows}
					rowsPerPage={rowsPerPage}
					page={page - 1}
					onChangePage={onChangePage}
					onChangeRowsPerPage={onChangeRowsPerPage}
					rowsPerPageOptions={[16, 32, 64]}
				/>
			</div>

			<Dialog open={viewOpen} onClose={handleViewClose} scroll={'paper'} aria-labelledby="scroll-dialog-title">
				{viewPlanoId !== null && viewPlano !== null ? (
					<>
						<DialogTitle id="scroll-dialog-title">{viewPlano.name}</DialogTitle>
						<DialogContent style={{ width: 300 }} dividers>
							<div className={classes.taxas}>
								{Object.keys(viewPlano.fees).map(feeKey => {
									if (feeKey !== 'bolepix') {
									return (
									<React.Fragment key={feeKey}>
										<div
											className={classes.taxa}
											onClick={() => setActive(active === feeKey ? null : feeKey)}
										>
											{getFormaDePagamento(feeKey)}
											<div className={classes.chevron}>
												{active === feeKey ? <FaChevronUp /> : <FaChevronDown />}
											</div>
										</div>

										<Collapse in={active === feeKey}>
											<div className={classes.taxaDetalhes}>
												{Object.keys(viewPlano.fees[feeKey]).map(brand => {
													if (Object.keys(viewPlano.fees[feeKey][brand]).length === 0) {
														return null;
													}

													const installments = viewPlano.fees[feeKey][brand];

													return (
														<div key={brand}>
															{['credit', 'credito'].includes(feeKey) && (
																<div className={classes.brandTitle}>
																	{brand === 'others' ? 'Outras Bandeiras' : brand}
																</div>
															)}

															{Object.keys(installments).map(i => {
																const installment = installments[i];

																return (
																	<Grid key={`${brand}-${i}`} container>
																		<Grid
																			xs={6}
																			item
																			style={{ textAlign: 'center' }}
																		>
																			{i}x
																		</Grid>
																		<Grid
																			xs={6}
																			item
																			style={{ textAlign: 'center' }}
																		>
																		{installment.typeFee === 'fixed' ? Formatter.Real(installment.fee / 100)
																			: `${(installment.fee / 100).toFixed(2)}%`}
																		</Grid>
																	</Grid>
																);
															})}
														</div>
													);
												})}
											</div>
										</Collapse>
									</React.Fragment>
								)}})}
							</div>
						</DialogContent>
					</>
				) : (
					<>
						<DialogTitle id="scroll-dialog-title">Carregando...</DialogTitle>
						<DialogContent style={{ width: 300 }} dividers>
							Plano Não Encontrado.
						</DialogContent>
					</>
				)}
			</Dialog>
			<ZModalConfirm
				title={`Selecionar Plano de Venda`}
				message={`Deseja realmente selecionar esse plano de venda?`}
				open={selectPlan.open}
				onClose={handleSelectPlan}
			/>
			<ZModalWarning
				title={`Atenção`}
				description={'Estamos processando a sua solicitação, em breve você receberá um e-mail de confirmação!'}
				open={showModal}
				handleClose={() => setShowModal(false)}
				button={
					<Button onClick={() => setShowModal(false)} color="primary">
						Ok
					</Button>
				}
			/>
		</div>
	);
};
