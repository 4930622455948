/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Isset } from '../../utils';

import axios from 'axios';
import config from 'react-global-configuration';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import ZLoader from '../system/ZLoader';
import ZTable from '../system/ZTable';
import ZFiltros from '../system/ZFiltros';
import { FaTimes } from 'react-icons/fa';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import { addMessage } from '../../store/alert';

const useStyles = makeStyles(theme => ({
	ecs: {
		width: '100%',
		height: 16,
		'& .MuiTypography-body1': {
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
	},
	empty: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: 18,
		fontWeight: 700,
		height: 200,
	},
	omni: {
		width: 200,
	},
	omniField: {
		'& .MuiInput-input': {
			fontSize: 14,
			padding: 0,
		},
		'& .MuiInput-underline:before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
			bottom: -6,
			transform: 'scaleX(1)',
		},
		'& .MuiInput-underline:not(.Mui-focused):after': {
			borderBottom: 'solid 1px #abc',
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: 'none',
			bottom: -6,
		},
	},
	filtroText: {
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
		padding: '5px 10px',
		border: `solid 1px ${theme.palette.primary.main}`,
		borderRadius: 30,
		marginLeft: 10,
		fontWeight: 700,
		fontSize: 12,
		color: theme.palette.primary.main,
	},
	filtroOmniHasText: {
		backgroundColor: `${theme.palette.primary.main}`,
		color: `${theme.palette.getContrastText(theme.palette.primary.main)} !important`,
		'& .MuiInput-input': {
			color: `${theme.palette.getContrastText(theme.palette.primary.main)} !important`,
		},
	},
}));

export default props => {
	const { usuarioId } = useParams();
	const classes = useStyles();
	const dispatch = useDispatch();

	const token = useSelector(store => store.auth.token);

	const [usuario, setUsuario] = useState(null);
	const [loading, setLoading] = useState(true);
	const [ecsRepresentante, setECsRepresentante] = useState([]);
	const [showingECsRepresentante, setShowingECsRepresentante] = useState([]);

	const [vincularECOpen, setVincularECOpen] = useState(false);
	const [vincularECs, setVincularECs] = useState([]);

	const [ecsFilters, setECsFilters] = useState({
		omniEstabelecimento: null,
	});
	const [ecsLoading, setECsLoading] = useState(true);
	const [ecs, setECs] = useState([]);
	const [pages, setPages] = useState(1);

	const [filtros, setFiltros] = useState({
		nome: '',
	});

	//Paginação
	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(8);
	const [totalRows, setTotalRows] = useState(0);

	const onChangePage = newPage => {
		setPage(newPage);
	};

	const onChangeRowsPerPage = rowsPerPage => {
		console.log(`trocou`);
		setRowsPerPage(rowsPerPage);
		setPage(1);
	};

	const getECs = async () => {
		setECsLoading(true);
		setPage(1);
		const res = await axios.post(
			`${config.get('apiUrl')}graphql`,
			{
				query: `
					query Estabelecimentos($filters: Filter, $pagination: Pagination) {
						estabelecimentos(filters: $filters, pagination: $pagination) {
							id, nomeFantasia, razaoSocial, pages
						}
					}
				`,
				variables: {
					filters: {
						...ecsFilters,
						usuarioId: usuario.parent_id || null,
					},
					pagination: {
						limit: 20,
						current: page,
					},
				},
			},
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);

		if (res.status === 200 && Isset(() => res.data.data.estabelecimentos)) {
			setECs(res.data.data.estabelecimentos);
			if (Isset(() => res.data.data.estabelecimentos[0].pages)) {
				setPages(res.data.data.estabelecimentos[0].pages);
			}
		}
		setECsLoading(false);
	};

	const getECsRepresentante = async () => {
		setLoading(true);
		const res = await axios.get(`${config.get('apiUrl')}usuarios/${usuarioId}`, {
			headers: { Authorization: `Bearer ${token}` },
			params: {
				page,
				limit: rowsPerPage,
			},
		});

		if (res.data.success && Isset(() => res.data.usuario.estabelecimentos)) {
			const ecs = res.data.usuario.estabelecimentos.map(ec => ({
				...ec,
				nome: (
					<>
						<div>{ec.nomeFantasia}</div>
						<div>
							<small>{ec.razaoSocial}</small>
						</div>
					</>
				),
			}));
			setUsuario(res.data.usuario);
			setECsRepresentante(ecs);
			setShowingECsRepresentante(ecs);
			setTotalRows(res.data.total);
		}
		setLoading(false);
	};

	useEffect(() => {
		getECsRepresentante();
	}, [page, rowsPerPage]);

	useEffect(() => {
		if (vincularECOpen) {
			getECs();
		}
	}, [vincularECOpen, ecsFilters, page]);

	useEffect(() => {
		let ecs = ecsRepresentante;
		if (filtros.nome) {
			const regex = new RegExp(filtros.nome.toLowerCase(), 'g');
			console.log(regex, 'teste'.match(regex));
			ecs = ecs.filter(({ nomeFantasia, razaoSocial }) => {
				return `${nomeFantasia}${razaoSocial}`.toLowerCase().match(regex);
			});
		}
		setShowingECsRepresentante(ecs);
	}, [filtros]);

	const handleDesvincularEC = async ecId => {
		const res = await axios.delete(`${config.get('apiUrl')}usuarios/desvincular-ecs/${usuarioId}/${ecId}`, {
			headers: { Authorization: `Bearer ${token}` },
		});

		if (res.data.success) {
			dispatch(addMessage({ type: 'success', message: 'Estabelecimento desvinculado com sucesso.' }));
			getECsRepresentante();
		} else {
			dispatch(addMessage({ type: 'error', message: res.data.error }));
		}
	};

	const toggleEC = e => {
		const value = e.target.value;

		if (e.target.checked) {
			const ec = ecs.find(a => a.id === value);

			setVincularECs([...vincularECs, ec]);
		} else {
			setVincularECs(vincularECs.filter(a => a.id !== value));
		}
	};

	const selecionarECs = async () => {
		const res = await axios.post(
			`${config.get('apiUrl')}usuarios/vincular-ecs/${usuarioId}`,
			{
				estabelecimentos: vincularECs,
			},
			{
				headers: { Authorization: `Bearer ${token}` },
			}
		);

		if (res.data.success) {
			setVincularECOpen(false);
			dispatch(addMessage({ type: 'success', message: 'Estabelecimentos vinculados com sucesso.' }));
			getECsRepresentante();
		} else {
			dispatch(addMessage({ type: 'error', message: res.data.error }));
		}
	};

	const handleClose = result => {
		if (result === 'escapeKeyDown' || result === 'backdropClick') {
			setVincularECOpen(false);
		}
	};

	console.log(rowsPerPage);
	return (
		<div>
			<Grid container justifyContent="center" style={{ marginTop: 10 }}>
				<Grid item xs={12} sm={6} style={{ textAlign: 'center' }}>
					<Button
						style={{ backgroundColor: green[500] }}
						variant="contained"
						color="secondary"
						onClick={() => {
							setVincularECOpen(true);
							setVincularECs([]);
						}}
					>
						Vincular Estabelecimento
					</Button>
				</Grid>
			</Grid>
			<Grid container justifyContent="center">
				<Grid item xs={12} sm={6}>
					<Paper style={{ marginTop: 10 }}>
						{loading ? (
							<ZLoader height={200} />
						) : (
							<>
								<div>
									<div>
										<ZFiltros
											onChange={data => {
												setFiltros({ ...filtros, nome: data.omni });
											}}
											input={[
												{
													key: 'omni',
													defaultValue: filtros.nome,
													placeholder: 'Estabelecimento',
													md: 3,
												},
											]}
										/>
									</div>
								</div>
								<ZTable
									headCells={[
										{
											id: 'nome',
											numeric: false,
											disablePadding: false,
											label: 'Estabelecimento',
										},
										{
											id: 'id',
											numeric: true,
											align: 'right',
											disablePadding: false,
											label: '',
											isButton: true,
											filter: row => {
												var ecId = row.id;

												return (
													<FaTimes
														style={{ cursor: 'pointer' }}
														onClick={() => handleDesvincularEC(ecId)}
													/>
												);
											},
										},
									]}
									tableTitle=""
									toolBar={false}
									// rowClick={plano => history.push(`/planos-de-vendas/${plano}`)}
									densePadding={false}
									checkBox={false}
									rowsPerPage={[8, 16, 24]}
									rowsPerPageDefault={rowsPerPage}
									rows={showingECsRepresentante}
									onChangePage={onChangePage}
									onChangeRowsPerPage={onChangeRowsPerPage}
									count={totalRows}
									page={page - 1}
								/>
							</>
						)}
					</Paper>
				</Grid>
			</Grid>

			<Dialog
				fullWidth
				maxWidth="md"
				// disableBackdropClick
				// disableEscapeKeyDown
				open={vincularECOpen}
				onClose={(event, reason) => {
					setVincularECs([]);
					handleClose(reason);
				}}
			>
				<DialogTitle>Selecione os Estabelecimentos</DialogTitle>
				<DialogContent>
					<Grid container>
						<Grid item xs={12}>
							<ZFiltros
								onChange={data => {
									setECsFilters({
										...ecsFilters,
										omniEstabelecimento: data.omni,
									});
								}}
								input={[
									{
										key: 'omni',
										defaultValue: ecsFilters.omniEstabelecimento,
										placeholder: 'Nome ou CPF/CNPJ',
										md: 3,
									},
								]}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							style={{
								fontFamily: "'Exo 2', sans-serif",
								fontSize: 18,
								fontWeight: 'bold',
								fontVariant: 'small-caps',
							}}
						>
							<Tooltip
								title={vincularECs.map(ec => (
									<div>{ec.nomeFantasia}</div>
								))}
							>
								<div>{`${vincularECs.length} estabelecimentos selecionados`}</div>
							</Tooltip>
						</Grid>

						<Grid
							container
							item
							direction="row"
							justifyContent="center"
							alignItems="center"
							spacing={2}
							style={{ minHeight: 100, maxHeight: 400, overflowY: 'scroll' }}
						>
							{ecsLoading ? (
								<ZLoader height={200} message="Carregando Estabelecimentos" />
							) : (
								<>
									{ecs.length > 0 ? (
										<>
											{ecs.map((ec, i) => {
												return (
													<Grid item key={i} xs={12} sm={6}>
														<FormControlLabel
															className={classes.ecs}
															value="end"
															control={
																<Switch
																	checked={!!vincularECs.find(a => +a.id === +ec.id)}
																	onChange={toggleEC}
																	value={ec.id}
																	color="primary"
																/>
															}
															label={ec.razaoSocial ? ec.razaoSocial : ec.nomeFantasia}
															labelPlacement="end"
														/>
													</Grid>
												);
											})}
										</>
									) : (
										<div className={classes.empty}>Nenhum Estabelecimento Encontrado.</div>
									)}
								</>
							)}
						</Grid>

						<Grid item xs={12} style={{ textAlign: 'center' }}>
							<Pagination count={pages} size="small" onChange={(e, v) => setPage(v)} />
						</Grid>
					</Grid>

					<Grid container spacing={2}>
						<Grid item xs={12} style={{ paddingTop: 10, textAlign: 'center' }}>
							<Button
								variant="contained"
								onClick={() => {
									setVincularECOpen(false);
									setVincularECs([]);
								}}
								style={{ marginRight: 10 }}
							>
								Cancelar
							</Button>
							<Button variant="contained" color="primary" onClick={selecionarECs}>
								Confirmar
							</Button>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
		</div>
	);
};
