/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import ZSwitch from '../system/ZSwitch';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Mask } from '../../utils';
import { SelectEstabelecimento } from './SelectEstabelecimento';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ZText from '../system/ZText';
import InputAdornment from '@material-ui/core/InputAdornment';
import DeleteIcon from '@material-ui/icons/Delete';
import { red } from '@material-ui/core/colors';
import { useSelector, useDispatch } from 'react-redux';
import { addMessage } from '../../store/alert';
import config from 'react-global-configuration';
import axios from 'axios';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	select: {
		// paddingTop: '16px',
		width: '100%',
		// fontSize: '16px',
		// height: '100%',
		background: '#fff',
		border: 'none',
		borderBottom: '1px solid black',
		outline: 'none',
	},
	inputValor: {
		// paddingTop: '16px',
		width: '100%',
		// fontSize: '16px',
		// height: '100%',
		background: '#fff',
		border: 'none',
		borderBottom: '1px solid black',
		outline: 'none',
	},
	formControl: {
		minWidth: 100,
		paddingBottom: '8px',
	},
	// selectEmpty: {
	// 	marginTop: theme.spacing(2),
	// },
}));

export default props => {
	const dispatch = useDispatch();
	const token = useSelector(store => store.auth.token);
	const classes = useStyles();
	const [somarTaxa, setSomarTaxa] = useState(false);
	const [repasseSindicato, setRepasseSindicato] = useState([
		{
			estabelecimento: '',
			tipoTaxa: 1,
			valor: 0,
		},
	]);

	const addEstablishment = () => {
		const newArray = [...repasseSindicato];

		newArray.push({
			id: '',
			estabelecimento: '',
			tipoTaxa: 1,
			valor: 0,
		});

		setRepasseSindicato(newArray);
	};

	const removeEstablishment = async (indexToRemove, id) => {
		let remove = true;
		if (id) {
			const res = await axios.delete(`${config.get('apiV2Url')}establishment/split-pass-fee/${id}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			if (res.data.success) {
				remove = true;
			} else {
				remove = false;
			}
		}

		if (remove) {
			const updatedRepasseSindicato = [...repasseSindicato];

			updatedRepasseSindicato.splice(indexToRemove, 1);
			setRepasseSindicato(updatedRepasseSindicato);
		}
	};

	const validarCampos = () => {
		for (const item of repasseSindicato) {
			if (
				item.tipoTaxa === 2 &&
				(!item.id ||
					item.valor === 0 ||
					isNaN(Number(item.valor.replace(',', '.'))) ||
					Number(item.valor.replace(',', '.')) === 0)
			) {
				return false;
			}

			if (item.tipoTaxa !== 2 && !item.id) {
				return false;
			}

			if (item.tipoTaxa === 1 && (item.valor === '' || isNaN(Number(item.valor)) || Number(item.valor) === 0)) {
				return false; // Retorna false se o campo valor não estiver preenchido corretamente
			}
		}

		return true;
	};

	const getTaxaSindicato = async () => {
		const res = await axios.get(
			`${config.get('apiV2Url')}transaction/get-split-pass-fees/${props.estabelecimentoId}`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		if (res.data.splits.length) {
			let data = [];
			res.data.splits.map(split => {
				data.push({
					estabelecimento: split.establishmentId,
					tipoTaxa: split.tipo,
					valor: split.valor,
					id: split.establishmentId,
					idSplit: split.id,
				});
			});
			setRepasseSindicato(data);
			setSomarTaxa(res.data.passRate);
		}
	};

	useEffect(() => {
		getTaxaSindicato();
	}, []);

	const postTaxaSindicato = async () => {
		const camposValidos = validarCampos();

		if (camposValidos) {
			props.setLoader(true);
			let updatedRepasseSindicato = repasseSindicato.reduce((acc, item, index) => {
				// if (!item.get) {
				const newItem = { establishmentId: item.id };

				if (item.tipoTaxa === 1) {
					newItem.amount = item.valor;
					acc.push(newItem);
				} else if (item.tipoTaxa === 2) {
					const valorComoNumero = Number(item.valor.replace(',', '.'));
					newItem.percentage = valorComoNumero;
					acc.push(newItem);
				}
				// }
				return acc;
			}, []);

			await axios
				.post(
					`${config.get('apiV2Url')}establishment/split-pass-fee`,
					{
						establishmentId: props.estabelecimentoId,
						passRate: somarTaxa,
						splits: updatedRepasseSindicato,
					},
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				)
				.then(res => {
					if (res.data.success) {
						dispatch(addMessage({ type: 'success', message: 'Operação realizada com sucesso' }));
						props.setLoader(false);
						props.setShowRepasseSindicato({
							...props.showRepasseSindicato,
							open: false,
						});
					} else {
						dispatch(addMessage({ type: 'error', message: res.data.message }));
					}
				})
				.catch(error => {
					if (error.response.data.error) {
						dispatch(addMessage({ type: 'error', message: error.response.data.error }));
					} else {
						dispatch(addMessage({ type: 'error', message: 'Erro ao relizar operação' }));
					}
					props.setLoader(false);
				});
		} else {
			dispatch(addMessage({ type: 'error', message: 'Preencha os campos corretamente.' }));
			props.setLoader(false);
			props.setConfirmRequest(false);
		}
	};

	useEffect(() => {
		if (props.confirmRequest) {
			postTaxaSindicato();
		}
	}, [props.confirmRequest]);

	const [confirmRemove, setConfirmRemove] = useState(false);
	const removeSplit = (index, idSplit) => {
		if (confirmRemove === index) {
			removeEstablishment(index, idSplit);
			setConfirmRemove(false);
		} else {
			setConfirmRemove(index);
		}
	};

	return (
		<>
			<Grid container direction="column">
				<Grid spacing={5} item container alignItems="center">
					<Grid item>
						<p>Somar taxa ao valor final do produto</p>
					</Grid>
					<Grid item>
						<ZSwitch onChange={() => setSomarTaxa(!somarTaxa)} checked={somarTaxa} />
					</Grid>
				</Grid>
				{repasseSindicato.map((repasse, index) => {
					const handleEstabelecimentoChange = (index, value, slug) => {
						const updatedRepasseSindicato = [...repasseSindicato];
						updatedRepasseSindicato[index][slug] = value;
						setRepasseSindicato(updatedRepasseSindicato);
					};

					return (
						<Grid key={index} container spacing={4}>
							<Grid
								style={{
									display: 'flex',
									alignItems: 'center',
									marginTop: 27,
								}}
								item
								xs={12}
								md={5}
							>
								<SelectEstabelecimento
									estabelecimentoId={repasse.estabelecimento}
									value={repasse.estabelecimento}
									setEstabelecimento={estabelecimento => {
										const hasEstabelecimento = Boolean(estabelecimento);
										const id = hasEstabelecimento ? estabelecimento.id : '';
										const name = hasEstabelecimento ? estabelecimento.cpfcnpj : '';

										handleEstabelecimentoChange(index, id, 'id');
										handleEstabelecimentoChange(index, name, 'estabelecimento');
									}}
								/>
							</Grid>
							<Grid item xs={12} md={3} style={{ display: 'flex', alignItems: 'flex-end' }}>
								<FormControl className={classes.formControl}>
									<InputLabel id="demo-simple-select-label">Tipo de taxa</InputLabel>
									<Select
										// labelId="demo-simple-select-label"
										// id="demo-simple-select"
										className={classes.select}
										value={repasse.tipoTaxa}
										onChange={e => handleEstabelecimentoChange(index, e.target.value, 'tipoTaxa')}
									>
										<MenuItem value={1}>Valor fixo</MenuItem>
										<MenuItem value={2}>Valor %</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} md={3}>
								{repasse.tipoTaxa === 1 ? (
									<ZText
										fullWidth
										label="Valor"
										margin="normal"
										realMask
										placeholder="Valor "
										value={repasse.valor}
										onBlur={e => {
											handleEstabelecimentoChange(index, e.target.value, 'valor');
										}}
									/>
								) : (
									<ZText
										label="Valor"
										required={true}
										value={repasse.valor}
										endAdornment={<InputAdornment position="end">%</InputAdornment>}
										onChange={(value, setValue) => {
											value = Mask.OnlyNumber(value) / 100;

											setValue(value > 100 ? Mask.Porcentagem(100) : Mask.Porcentagem(value));
										}}
										onBlur={e => handleEstabelecimentoChange(index, e.target.value, 'valor')}
									/>
								)}
							</Grid>
							<Grid
								item
								xs={12}
								md={1}
								alignItems="center"
								justifyContent="center"
								style={{ display: 'flex', alignItems: 'center', paddingTop: '16px' }}
							>
								<Button
									onClick={() => removeSplit(index, repasse.idSplit)}
									style={{ marginTop: '16px' }}
								>
									<DeleteIcon fontSize="16px" style={{ color: red[500] }} />
								</Button>
							</Grid>
							{confirmRemove === index && (
								<Grid
									item
									xs={12}
									style={{
										display: 'flex',
										justifyContent: 'flex-end',
										alignItems: 'start',
										marginTop: '-32px',
									}}
								>
									<Typography
										onClick={() => removeSplit(index, repasse.idSplit)}
										style={{ color: red[500] }}
									>
										Clique para confirmar{' '}
									</Typography>
								</Grid>
							)}
						</Grid>
					);
				})}
				<Grid container style={{ justifyContent: 'center' }}>
					<Grid alignItems="center" justifyContent="center" style={{ margin: '24px 0' }}>
						<Button onClick={addEstablishment}>+ Adicionar estabelecimento</Button>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};
