/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import config from 'react-global-configuration';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Formatter } from '../../utils';
import { FaChevronUp, FaChevronDown, FaFileExcel } from 'react-icons/fa';
import ZLoader from '../system/ZLoader';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import FileDownload from 'js-file-download';
import { addMessage } from '../../store/alert';
import { Isset } from '../../utils';

const getFormaDePagamento = formaDePagamento => {
	switch (formaDePagamento) {
		case 'boleto':
			return 'Boleto';
		case 'credit':
			return 'Crédito';
		case 'debit':
			return 'Débito';
		case 'pix':
			return 'Pix';
		case 'bolepix':
			return 'Bolepix';
		case 'nupay': 
		    return 'NUPay';
		default:
			return null;
	}
};

const useStyles = makeStyles(theme => ({
	header: {
		fontWeight: 700,
		fontSize: 20,
		margin: 8,
	},
	chevron: {
		position: 'absolute',
		right: 10,
		top: 10,
	},
	brandTitle: {
		textAlign: 'center',
		fontWeight: 700,
	},
	taxa: {
		position: 'relative',
		cursor: 'pointer',
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.getContrastText(theme.palette.primary.main),
		marginTop: 5,
		borderRadius: 5,
		padding: 6,
		fontWeight: 700,
	},
	taxaDetalhes: {
		borderWidth: 1,
		borderColor: theme.palette.primary.main,
		borderStyle: 'solid',
		borderRadius: 5,
		padding: 6,
		margin: 'auto',
		marginTop: -5,
		paddingTop: 10,
	},
	export: {
		position: 'absolute',
		top: 0,
		right: 10,
		opacity: 0.5,
		cursor: 'pointer',
		color: '#464ae6',
		fontSize: 16,
		transition: theme.transitions.create(['all'], {
			duration: theme.transitions.duration.complex,
		}),
		'&:hover': {
			fontSize: 18,
			opacity: 1,
		},
	},
}));

export default props => {
	const { id } = useParams();
	const classes = useStyles();
	const token = useSelector(store => store.auth.token);
	const [plano, setPlano] = useState([]);
	const [loading, setLoading] = useState(true);
	const [active, setActive] = useState(null);
	const theme = useTheme();
	const isXs = useMediaQuery(theme.breakpoints.down('xs'));
	const dispatch = useDispatch();

	const [showExportMessage, setShowExportMessage] = useState(false);

	const getPlano = async () => {
		setLoading(true);
		let res = await axios.get(`${config.get('apiUrl')}planos_de_vendas/${id}`, {
			headers: { Authorization: `Bearer ${token}` },
		});

		if (res.data.success) {
			setPlano(res.data.plano);
		}

		setLoading(false);
	};

	useEffect(() => {
		getPlano();
	}, []);

	const exportXls = async () => {
		setShowExportMessage(true);
		const result = await axios.get(`${config.get('apiUrl')}export/getPlanoDetails/${id}`, {
			headers: { Authorization: `Bearer ${token}` },
			responseType: 'blob',
		});

		if (result.status !== 200 || !Isset(() => result.data)) {
			setShowExportMessage(false);
			return dispatch(addMessage({ type: 'error', message: 'Erro ao exportar relatório.' }));
		}

		const { 'content-disposition': contentDisposition, 'content-type': contentType } = result.headers;
		let filename = 'performancePorEstabelecimento.xlsx';
		if (contentDisposition.indexOf('filename="') !== -1) {
			filename = contentDisposition.slice(
				contentDisposition.indexOf('filename="') + 10,
				contentDisposition.length - 1
			);
		}

		FileDownload(result.data, filename, contentType);
		setShowExportMessage(false);
	};

	return (
		<div>
			<div className={classes.header}>Plano de Venda - {plano.name || 'Carregando...'}</div>
			<div className={classes.taxas}>
				{loading ? (
					<ZLoader height={200} />
				) : (
					<Grid container justifyContent="center" spacing={2} style={{ position: 'relative' }}>
						<FaFileExcel className={classes.export} onClick={exportXls} />
						{Object.keys(plano.fees).map(feeKey => {
							if (feeKey !== 'bolepix') {
							return (
							<Grid item xs={12} sm={4} md={3} key={feeKey}>
								<div
									className={classes.taxa}
									onClick={() => setActive(active === feeKey ? null : feeKey)}
								>
									{getFormaDePagamento(feeKey)}
									<div className={classes.chevron}>
										{isXs && active === feeKey ? <FaChevronUp /> : <FaChevronDown />}
									</div>
								</div>

								<Collapse in={isXs ? active === feeKey : true}>
									<div className={classes.taxaDetalhes}>
										{Object.keys(plano.fees[feeKey]).map(brand => {
											if (Object.keys(plano.fees[feeKey][brand]).length === 0) {
												return null;
											}
											const installments = plano.fees[feeKey][brand];

											return (
												<div key={brand}>
													{['credit', 'credito'].includes(feeKey) && (
														<div className={classes.brandTitle}>
															{brand === 'others' ? 'Outras Bandeiras' : brand}
														</div>
													)}

													{Object.keys(installments).map(i => {
														const installment = installments[i];

														return (
															<Grid key={`${brand}-${i}`} container>
																{feeKey === 'debit' ? (
																	<Grid xs={6} item style={{ textAlign: 'left', paddingLeft: '16px', fontWeight: 'bold' }}>
																		{brand}
																	</Grid>
																) : (
																	<Grid xs={6} item style={{ textAlign: 'center' }}>
																		{i}x
																	</Grid>
																)}
																<Grid xs={6} item style={{ textAlign: 'center' }}>
																	{installment.typeFee === 'fixed' && feeKey !== 'credit' && feeKey !== 'debit'
																		? Formatter.Real(installment.fee / 100)
																		: `${(installment.fee / 100).toFixed(2)}%`}
																</Grid>
															</Grid>
														);
													})}
												</div>
											);
										})}
									</div>
								</Collapse>
							</Grid>
						)}})}
					</Grid>
				)}
			</div>

			<Dialog open={showExportMessage}>
				<DialogContent style={{ width: 200 }}>
					<ZLoader height={100} size={26} message={'Exportando'} />
				</DialogContent>
			</Dialog>
		</div>
	);
};
