import React from 'react';
import { Grid, Container } from '@material-ui/core';

import { useSelector } from 'react-redux';
import moment from 'moment';
import ZAlert from '../system/ZAlert';
import Link from '@material-ui/core/Link';

import PortalRoutes from './Routes/PortalRoutes';

// const useStyles = makeStyles(theme => ({
// 	paper: {
// 		marginBottom: theme.spacing(3),
// 		padding: theme.spacing(2),
// 		[theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
// 			marginBottom: theme.spacing(6),
// 			padding: theme.spacing(6),
// 		},
// 	},
// 	button: {
// 		marginTop: theme.spacing(3),
// 		marginBottom: theme.spacing(1),
// 		padding: '8px 16px',
// 	},
// }));

export default function MeusBoletos() {
	// const classes = useStyles();
	// const dispatch = useDispatch();
	const message = useSelector(store => store.alert);

	// const history = useHistory();
	const systemConfig = useSelector(store => store.system.config);
	// const { estabelecimentoId } = systemConfig;
	// const initialState = {
	// 	documento: '',
	// };
	// const { state, handleMask } = useMask(initialState);

	// const handleChange = e => {
	// 	handleMask(e);

	// 	dispatch(addMessage({ type: 'success', message: '' }));
	// };

	// const handleSubmit = e => {
	// 	e.preventDefault();
	// 	const documento = state.documento.replace(/\D/g, '');

	// 	axios
	// 		.get(`${config.get('apiUrl')}clientes/por_documento/${documento}/public/${estabelecimentoId}`)
	// 		.then(response => response.data)
	// 		.then(data => {
	// 			if (!data.success) {
	// 				dispatch(addMessage({ type: 'error', message: data.error }));
	// 			} else {
	// 				dispatch(addMessage({ type: 'success', message: '' }));
	// 				history.push(`listagem-boletos/${documento}`);
	// 				console.log('fds');
	// 				// window.location = `/listagem-boletos/${documento}`;
	// 			}
	// 		})
	// 		.catch(err => {
	// 			dispatch(addMessage({ type: 'error', message: 'Cliente não encontrado' }));

	// 			console.error(err);
	// 		});
	// };

	return (
		<div style={{ background: systemConfig.backgroundColor, paddingBottom: 100, minHeight: '100vh' }}>
			<Container maxWidth="lg" style={{}}>
				<Grid container direction="row" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
					<Grid item xs={12} style={{ background: systemConfig.backgroundColor, padding: 20 }}>
						<Grid container direction="row" justifyContent="center" alignItems="center">
							<Grid item xs={12} sm={6} lg={6} style={{}}>
								<Grid container direction="row" justifyContent="center" alignItems="center">
									<Grid item xs={12} style={{ padding: 30 }} align="center">
										<Link href={systemConfig.website} color="inherit">
											<img
												src={systemConfig.logoUrl}
												style={{ maxWidth: '100%' }}
												alt={`Logo ${systemConfig.nome}`}
												title={`Logo ${systemConfig.nome}`}
											/>
										</Link>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid
						container
						alignContent="center"
						justifyContent="center"
						style={{ background: 'white', padding: 30, borderRadius: 5 }}
						xs={12}
					>
						<PortalRoutes></PortalRoutes>
					</Grid>
				</Grid>

				{message.message.length > 0 && (
					<Grid item xs={12}>
						<ZAlert type={message.type} message={message.message} time={moment().unix()} vertical="top" />
					</Grid>
				)}
			</Container>
		</div>
	);
}
// <Container maxWidth="sm">
// 	<ZTitle title={'Meus Boletos'} marginBottom={32} />

// <Paper className={classes.paper}>
// 	<Grid container justifyContent="center">
// 		<Grid item md={12} xs={12}>
// 			<form onSubmit={handleSubmit}>
// 				<TextField
// 					name="documento"
// 					label="CPF/CNPJ"
// 					fullWidth
// 					onChange={handleChange}
// 					value={state.documento}
// 				/>
// 			</form>
// 		</Grid>

// 		<Grid container justifyContent="flex-end">
// 			<Grid item xs={12} md={4}>
// 				<Button
// 					className={classes.button}
// 					type="submit"
// 					onClick={handleSubmit}
// 					variant="contained"
// 					color="primary"
// 					disableElevation
// 					fullWidth
// 				>
// 					Continuar
// 				</Button>
// 			</Grid>
// 		</Grid>
// 	</Grid>
// </Paper>

// 	{message.message.length > 0 && (
// 		<Grid item xs={12}>
// 			<ZAlert type={message.type} message={message.message} time={moment().unix()} vertical="top" />
// 		</Grid>
// 	)}
// </Container>
