import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ConsultarBoletos from '../components/ConsultarBoletos';
import ImprimirBoletos from '../components/ImprimirBoletos';
import ListagemBoletos from '../components/ListagemBoletos';

export default props => {
	return (
		<>
			<Switch>
				<Route exact={true} component={ConsultarBoletos} path={'/consultar-boleto/'}></Route>
				<Route exact={true} component={ListagemBoletos} path={'/listagem-boletos/:documento'}></Route>
				<Route exact={true} component={ImprimirBoletos} path={'/imprimir-boletos'}></Route>
			</Switch>
		</>
	);
};
