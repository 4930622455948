import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { Mask } from '../../utils';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		padding: theme.spacing(2),
	},
	title: {
		marginTop: theme.spacing(2),
	},
	infoText: {
		marginBottom: theme.spacing(2),
	},
	buttonContainer: {
		display: 'flex',
		justifyContent: 'start',
		marginTop: theme.spacing(1),
	},
	button: {
		padding: theme.spacing(1, 4),
		fontSize: '1rem',
		fontWeight: 'bold',
		border: 'none',
		cursor: 'pointer',
		outline: 'none',
		width: '100%',
	},
	selectedButton: {
		backgroundColor: 'orange',
		color: 'white',
	},
	inputContainer: {
		marginBottom: theme.spacing(2), // Adjusted spacing
	},
	inputLabel: {
		fontSize: '0.875rem',
		fontWeight: 'bold',
		marginBottom: theme.spacing(1),
	},
	inputField: {
		width: '100%',
		border: 'none',
		borderBottom: `2px solid ${theme.palette.primary.main}`,
		padding: theme.spacing(0.5, 0),
		outline: 'none',
		'&:focus': {
			borderColor: 'orange',
		},
	},
	sendButton: {
		marginTop: theme.spacing(4),
		padding: theme.spacing(1, 4),
		backgroundColor: 'orange',
		color: 'white',
		border: 'none',
		borderRadius: theme.spacing(1),
		fontWeight: 'bold',
		cursor: 'pointer',
		outline: 'none',
	},
	continueText: {
		marginTop: theme.spacing(4),
		color: 'orange',
		fontWeight: 'bold',
		display: 'flex',
		justifyContent: 'center',
		cursor: 'pointer',
	},
	cancelButton: {
		marginRight: theme.spacing(2),
		marginLeft: 'auto', // Move to the right
	},
	confirmButton: {
		backgroundColor: 'orange',
		color: 'white',
		'&:hover': {
			backgroundColor: 'darkorange',
		},
	},
}));

export default function ZModalEstornar(props) {
	const classes = useStyles();
	const [open, setOpen] = useState(props.open || false);
	const [disabled, setDisabled] = useState(false);
	const { bancos, setData, data } = props;
	const handleClose = () => {
		props.handleClose(false);
		setOpen(false);
	};

	useEffect(() => {
		setOpen(props.open);
		setDisabled(false);
	}, [props]);

	return (
		<div>
			<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
				<DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
				<DialogContent>
					{/* <DialogContentText>{props.description}</DialogContentText> */}
					{props.content}
					<div className={classes.container}>
						<h3 className={classes.title}>Dados Bancários</h3>
						<p className={classes.infoText}>Informe os dados que receberão o estorno</p>
						<hr className="w-full mt-2 mb-4" />
						<div className={classes.buttonContainer}>
							<button
								className={`${classes.button} ${data.tipoConta === 1 ? classes.selectedButton : ''}`}
								onClick={() => setData({ ...data, tipoConta: 1 })}
							>
								Conta Corrente
							</button>
							<button
								className={`${classes.button} ${data.tipoConta === 2 ? classes.selectedButton : ''}`}
								onClick={() => setData({ ...data, tipoConta: 2 })}
							>
								Conta Poupança
							</button>
						</div>
						<div className={classes.inputContainer}>
							<p className={classes.inputLabel}>CPF/CNPJ do Titular</p>
							<input
								className={classes.inputField}
								type="text"
								disabled={true}
								value={
									data.documento.length < 15
										? Mask.Cpf(data.documento || '')
										: Mask.Cnpj(data.nome || '')
								}
							/>
						</div>
						<div className={classes.inputContainer}>
							<p className={classes.inputLabel}>Nome</p>
							<input className={classes.inputField} type="text" value={data.nome} disabled={true} />
						</div>
						<div className={classes.inputContainer}>
							<p className={classes.inputLabel}>Banco</p>
							<Select
								value={data.bancoId}
								onChange={e => setData({ ...data, bancoId: e.target.value })}
								inputProps={{ id: 'bancoId' }}
								className={classes.inputField}
							>
								{bancos.map((b, i) => (
									<MenuItem key={i} value={b.id}>
										{b.nome}
									</MenuItem>
								))}
							</Select>
						</div>

						<div className={classes.inputContainer}>
							<p className={classes.inputLabel}>Agência sem dígito</p>
							<input
								className={classes.inputField}
								type="number"
								value={data.agencia}
								onChange={e => setData({ ...data, agencia: e.target.value.slice(0, 4) })}
								onBlur={e => {
									if (e.target.value.length === 3) {
										const newValue = '0' + e.target.value;
										setData({ ...data, agencia: newValue });
									}
								}}
							/>
						</div>
						<div className={classes.inputContainer}>
							<p className={classes.inputLabel}>Conta com dígito sem hífen</p>
							<input
								className={classes.inputField}
								type="number"
								value={data.conta}
								onChange={e => setData({ ...data, conta: e.target.value })}
							/>
						</div>
					</div>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary" className={classes.cancelButton}>
						Cancelar
					</Button>
					<Button
						onClick={() => {
							props.handleOnSave();
							setDisabled(true);
						}}
						disabled={disabled || props.loading}
						color="primary"
						className={classes.confirmButton}
					>
						{props.loading ? (
							<span
								style={{
									width: '76px',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								<CircularProgress size={24} style={{ color: '#555555' }} />
							</span>
						) : (
							'Confirmar'
						)}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
