import React from 'react';


function ZErrors(props){
	return (
		<div>
			{!! props.errors.length && (
    			<ul style={{width:'100%', backgroundColor:'#f66767', border: '1px solid #f58282', color:'white', paddingTop:15, paddingBottom:5}}>
    				<li><b>Atenção</b></li>
    				
    				{props.errors.map((error,i) => (
    					<li key={i} style={{padding:5, listStyle: 'none'}}>{error}</li>
    				))}
    			</ul>
    		)}
		</div>
		);
}

export default ZErrors;