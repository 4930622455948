/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Isset } from '../../utils';
import { addMessage } from '../../store/alert';

import axios from 'axios';
import config from 'react-global-configuration';

const useStyles = makeStyles(theme => ({
	header: {
		fontWeight: 700,
		fontSize: 20,
		margin: 8,
	},
	planoTaxaPaper: {
		padding: 10,
	},
	dialogPVs: {
		position: 'absolute',
		top: 70,
		left: 10,
		zIndex: 1101,
	},
	dialogPVsPaper: {
		width: 240,
		minHeight: 150,
		padding: 10,
		backgroundColor: '#f1f1f1',
	},
	dialogPV: {
		padding: 2,
		fontWeight: 'bold',
		'&:not(:last-child)': {
			borderBottom: 'solid 1px #abc',
		},
	},
	backdrop: {
		position: 'fixed',
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		backgroundColor: 'black',
		opacity: 0.5,
		zIndex: 1100,
	},
	planosVendasEmpty: {
		fontVariant: 'small-caps',
		textAlign: 'center',
		fontSize: 16,
		fontWeight: 'bold',
	},
}));

export default props => {
	const { estabelecimentoId } = useParams();
	const classes = useStyles();
	const token = useSelector(store => store.auth.token);
	const history = useHistory();
	const dispatch = useDispatch();

	const [titulo, setTitulo] = useState('');

	const btnAddClick = async () => {
		if (!titulo) {
			dispatch(addMessage({ type: 'error', message: 'Existem campos inválidos no formulário.' }));
			return false;
		}

		const res = await axios.post(
			`${config.get('apiUrl')}planos_taxas/porMarketplaceFilho/${estabelecimentoId}`,
			{
				titulo,
			},
			{
				headers: { Authorization: `Bearer ${token}` },
			}
		);

		if (res.data.success && Isset(() => res.data.planoTaxa.id)) {
			history.push(`/estabelecimentos/${estabelecimentoId}/planos-taxas/${res.data.planoTaxa.id}`);
		}
	};

	return (
		<div className={classes.planosTaxasAdd}>
			<div className={classes.header}>Nova Tabela de Markup</div>

			<Paper className={classes.planoTaxaPaper}>
				<Grid container spacing={2} justifyContent="center" alignItems="center">
					<Grid item xs={3}>
						<TextField
							label="Título"
							value={titulo}
							onChange={e => setTitulo(e.target.value)}
							style={{ width: '100%' }}
						/>
					</Grid>
					<Grid item xs={3}>
						<Button variant="contained" color="primary" className={classes.btnAdd} onClick={btnAddClick}>
							Adicionar
						</Button>
					</Grid>
				</Grid>
			</Paper>
		</div>
	);
};
