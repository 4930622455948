
// import publicIp from 'public-ip';
import { useState, useEffect } from 'react';

export default function useClientIp() {
	const [ip, setIp] = useState('');

	useEffect(() => {
		const getClientIp = async () => {
			// const ipRes = await publicIp.v4();

			setIp('not implemented');
		};

		getClientIp();
	}, []);

	return ip;
}
