/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, memo } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import ZLoader from '../system/ZLoader';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import format from 'date-fns/format';
import pt from 'date-fns/locale/pt';
import FileDownload from 'js-file-download';
import queryString from 'query-string';
import clsx from 'clsx';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import LastExport from '../Export/LastExport';
import Button from '@material-ui/core/Button';

import axios from 'axios';
import config from 'react-global-configuration';
import { Isset, Formatter } from '../../utils/';
import ZEmpty from '../system/ZEmpty';
import ZFiltros from '../system/ZFiltros';
import ZPaginationDiv from '../system/ZPaginationDiv';
import {
	FaTimes,
	FaHistory,
	FaBarcode,
	FaCcVisa,
	FaCcMastercard,
	FaCcAmex,
	FaCreditCard,
	FaRegCreditCard,
	FaDollarSign,
	FaDownload,
} from 'react-icons/fa';

import { addMessage } from '../../store/alert';

const TooltipTitle = props => {
	return (
		<div>
			<div>{props.type}</div>
			<div>{props.brand}</div>
		</div>
	);
};

const getPaymentIndicator = paymentType => {
	switch (paymentType) {
		case 'boleto':
			return 'B';
		case 'debit':
			return 'D';
		case 'credit':
			return 'C';
		default:
			return '';
	}
};

const getBandeira = bandeira => {
	switch (bandeira) {
		case 'american express':
			return 'American Express';
		case 'elo':
			return 'Elo';
		case 'maestro':
			return 'Maestro';
		case 'mastercard':
			return 'Mastercard';
		case 'visa':
			return 'Visa';
		case 'visa electron':
			return 'Visa Electron';
		case 'diners club':
			return 'Diners Club';
		case 'hiper':
			return 'Hiper';
		case 'hipercard':
			return 'HiperCard';
		case 'banescard':
			return 'Banescard';
		default:
			return bandeira;
	}
};

const getPaymentIcon = (paymentType, brand, className = null) => {
	console.log(paymentType);
	brand = getBandeira(brand);
	if (paymentType === 'boleto') {
		return (
			<Tooltip title="Boleto Bancário">
				<div>
					<FaBarcode className={className} />
				</div>
			</Tooltip>
		);
	}

	if (paymentType === 'debit') {
		return (
			<Tooltip title={<TooltipTitle type="Cartão de Débito" brand={brand} />}>
				<div>
					<FaRegCreditCard className={className} />
				</div>
			</Tooltip>
		);
	}

	if (paymentType === 'credit') {
		switch (brand) {
			case 'MasterCard':
			case 'Maestro':
				return (
					<Tooltip title={<TooltipTitle type="Cartão de Crédito" brand={brand} />}>
						<div>
							<FaCcMastercard className={className} />
						</div>
					</Tooltip>
				);
			case 'Visa':
			case 'Visa Electron':
				return (
					<Tooltip title={<TooltipTitle type="Cartão de Crédito" brand={brand} />}>
						<div>
							<FaCcVisa className={className} />
						</div>
					</Tooltip>
				);
			case 'American Express':
				return (
					<Tooltip title={<TooltipTitle type="Cartão de Crédito" brand={brand} />}>
						<div>
							<FaCcAmex className={className} />
						</div>
					</Tooltip>
				);
			default:
				return (
					<Tooltip title={<TooltipTitle type="Cartão de Crédito" brand={brand} />}>
						<div>
							<FaCreditCard className={className} />
						</div>
					</Tooltip>
				);
		}
	}

	if (paymentType === 4) {
		return (
			<Tooltip title="Comissão">
				<div>
					<FaDollarSign />
				</div>
			</Tooltip>
		);
	}
};

const useStyles = makeStyles(theme => ({
	label: {
		fontSize: 11,
		textTransform: 'uppercase',
		fontWeight: 700,
		textAlign: 'center',
		whiteSpace: 'nowrap',
	},
	valor: {
		fontSize: 16,
		fontWeight: 700,
	},
	qtdPagamentos: {
		fontSize: 28,
	},
	pagamentos: {
		width: '100%',
		flexGrow: 1,
	},
	header: {
		fontWeight: 700,
		fontSize: 20,
		margin: 8,
	},
	filtros: {
		position: 'relative',
		borderRadius: 20,
		marginBottom: 30,
		border: `solid 1px ${theme.palette.primary.main}67`,
		padding: '5px 10px',
		display: 'flex',
		alignItems: 'center',
	},
	export: {
		position: 'absolute',
		right: 30,
		opacity: 0.5,
		cursor: 'pointer',
		color: '#464ae6',
		fontSize: 16,
		transition: theme.transitions.create(['all'], {
			duration: theme.transitions.duration.complex,
		}),
		'&:hover': {
			fontSize: 18,
			opacity: 1,
		},
	},
	exportHistory: {
		color: '#49bb57',
		fontSize: 16,
		marginLeft: 8,
	},
	exportDownload: {
		color: '#011D29',
		fontSize: 16,
		marginLeft: 8,
	},
	tableHeader: {
		textAlign: 'center',
		fontWeight: 700,
		fontSize: 18,
	},
	tableDateRow: {
		fontWeight: 700,
		height: 30,
		display: 'flex',
		alignItems: 'center',
		borderRadius: 10,
		backgroundColor: `${theme.palette.primary.main}aa`,
		color: theme.palette.getContrastText(theme.palette.primary.main),
	},
	tableRow: {
		textAlign: 'center',
		fontWeight: 700,
		height: 30,
		display: 'flex',
		alignItems: 'center',
		borderRadius: 20,
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}2b`,
		},
	},
	truncate: {
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
	},
	hideOnXs: {
		[theme.breakpoints.down('xs')]: {
			display: 'none',
		},
	},
	dataHoraXs: {
		[theme.breakpoints.down('xs')]: {
			fontSize: 10,
		},
	},
	paymentIcon: {
		fontSize: 20,
	},
	fixedFilter: {
		margin: 4,
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.getContrastText(theme.palette.primary.main),
		padding: '5px 10px',
		borderRadius: 30,
	},
	fixedFilterTitle: { fontWeight: 'bold', marginRight: 5 },
	fixedFilterValue: { fontSize: 11, marginRight: 10 },
	fixedFilterRemove: {
		cursor: 'pointer',
	},
	valorAprovado: {
		color: '#4caf50',
	},
	valorPendente: {
		color: '#ffc107',
	},
	valorCancelado: {
		color: '#f44336',
	},
	iconButton: {
		minWidth: 0,
	},
	buttonHistory: {
		color: '#53BD5C',
		border: `solid 1px #53BD5C`,
		'&:hover': {
			color: '#FFF',
			backgroundColor: '#53d48a',
			'& svg': {
				color: '#FFF',
			},
		},
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			marginTop: 16,
		},
		'& svg': {
			margin: 0,
		},
	},
	buttonExport: {
		color: '#040633',
		border: `solid 1px #040633`,
		'&:hover': {
			color: '#FFF',
			backgroundColor: '#011d29',
			'& svg': {
				color: '#FFF',
			},
		},
		[theme.breakpoints.down('xs')]: {
			width: '90%',
			marginTop: 16,
		},
		'& svg': {
			margin: 0,
		},
	},
}));

const getOptions = () => {
	const result = {
		formaPagamento: [
			{ id: 'boleto', name: 'Boleto' },
			{ id: 'debito', name: 'Débito' },
			{ id: 'creditoVista', name: 'Crédito à Vista' },
			{ id: 'creditoParcelado', name: 'Crédito Parcelado' },
		],
		bandeiras: [
			{ id: 'american express', name: 'American Express' },
			{ id: 'elo', name: 'Elo' },
			{ id: 'maestro', name: 'Maestro' },
			{ id: 'mastercard', name: 'Mastercard' },
			{ id: 'visa', name: 'Visa' },
			{ id: 'visa electron', name: 'Visa Electron' },
			{ id: 'diners club', name: 'Diners Club' },
			{ id: 'hiper', name: 'Hiper' },
			{ id: 'hipercard', name: 'HiperCard' },
			{ id: 'banescard', name: 'Banescard' },
		],
	};

	const { formaPagamento, bandeiras } = result;

	return {
		formaPagamento,
		bandeiras,
	};
};

const PagamentoRow = memo(props => {
	const classes = useStyles();

	const [pagamento] = useState(props.pagamento || {});
	// const { agruparTaxas } = props;

	if (!pagamento) {
		return null;
	}

	return (
		<Grid container item className={classes.tableRow} onClick={() => props.rowClick(pagamento.pedidoId)}>
			<Grid item xs={1} className={classes.dataHoraXs}>
				{format(pagamento.dataRecebimento, 'DD/MM')}
			</Grid>
			<Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
				<div style={{ marginRight: 5 }}>
					{getPaymentIcon(pagamento.tipoPagamento, pagamento.bandeira, classes.paymentIcon)}
				</div>
				<div style={{ marginRight: 2, fontSize: 10, width: 20, textAlign: 'left' }}>
					{getPaymentIndicator(pagamento.tipoPagamento)}
					{pagamento.tipoPagamento === 'credit' ? ` ${pagamento.parcela}/${pagamento.parcelas}` : ' '}
				</div>
			</Grid>
			{props.viewEC && (
				<Grid item xs className={classes.truncate}>
					{pagamento.estabelecimento}
				</Grid>
			)}
			<Grid item sm={3} className={classes.hideOnXs}>
				{pagamento.zoopTransactionId}
			</Grid>
			<Grid item sm={1}>
				{Formatter.Real(pagamento.valor)}
			</Grid>
			<Grid item xs={3} sm={1}>
				{Formatter.Real(pagamento.valorRecebido)}
			</Grid>
			<Grid item xs={3} sm={1}>
				{Formatter.Real(pagamento.markup)}
			</Grid>
		</Grid>
	);
});

const PagamentosList = memo(props => {
	const classes = useStyles();

	const { agruparTaxas } = props;

	const [pagamentos, setPagamentos] = useState([]);
	const [loading, setLoading] = useState(true);
	const [totais, setTotais] = useState({
		geral: {
			quantidade: 0,
			total: 0,
			taxa: 0,
			split: 0,
			liquido: 0,
		},
	});

	useEffect(() => {
		Isset(() => props.pagamentos) && setPagamentos(props.pagamentos);
		Isset(() => props.loading) && setLoading(props.loading);
		Isset(() => props.totais) && setTotais(props.totais);
	}, [props]);

	const usuario = useSelector(store => store.auth.usuario);
	const viewEC = [1, 2, 4, 5, 7, 11, 12, 13, 15].includes(usuario.grupo_id);

	if (!pagamentos) {
		return <ZEmpty height={200} text="Nenhuma Pagamento Encontrada." />;
	}

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Paper style={{ padding: 15 }}>
					{loading ? (
						<ZLoader height={200} />
					) : (
						<Grid container className={classes.pagamentos}>
							<Grid container item className={classes.tableHeader}>
								<Grid item xs={1}>
									Hora
								</Grid>
								<Grid item xs={2}></Grid>
								{viewEC && (
									<Grid item xs>
										Estabelecimento
									</Grid>
								)}
								<Grid item sm={3} className={classes.hideOnXs}>
									ID Transação
								</Grid>
								<Grid item sm={1} className={classes.hideOnXs}>
									Valor
								</Grid>
								<Grid item xs={3} sm={1} style={{ fontSize: 16 }}>
									Líquido
								</Grid>
								<Grid item xs={3} sm={1} style={{ fontSize: 16 }}>
									Markup
								</Grid>
							</Grid>
							{pagamentos.map((pagamento, key) => {
								return (
									<React.Fragment key={key}>
										{pagamento.type === 'date' ? (
											<Grid container item className={classes.tableDateRow}>
												<Grid item xs={6} sm={agruparTaxas ? 5 : 4} style={{ paddingLeft: 20 }}>
													{format(pagamento.date, 'DD [de] MMMM [de] YYYY', {
														locale: pt,
													})}
												</Grid>
												{usuario.grupo_id !== 7 && (
													<>
														<Grid
															item
															xs={1}
															className={classes.hideOnXs}
															style={{ textAlign: 'center' }}
														>
															{Isset(() => totais[pagamento.date].bruto)
																? Formatter.Real(totais[pagamento.date].bruto)
																: ''}
														</Grid>
														<Grid
															item
															xs={agruparTaxas ? 1 : 2}
															className={classes.hideOnXs}
														></Grid>
														<Grid item xs={2} sm={1} style={{ textAlign: 'center' }}>
															{Isset(() => totais[pagamento.date].liquido)
																? Formatter.Real(totais[pagamento.date].liquido)
																: ''}
														</Grid>
													</>
												)}
											</Grid>
										) : (
											<PagamentoRow
												pagamento={pagamento}
												rowClick={props.rowClick}
												viewEC={viewEC}
												agruparTaxas={agruparTaxas}
											/>
										)}
									</React.Fragment>
								);
							})}
						</Grid>
					)}
				</Paper>
			</Grid>
		</Grid>
	);
});

const FixedFilter = props => {
	const classes = useStyles();
	return (
		<div className={classes.fixedFilter}>
			<span className={classes.fixedFilterTitle}>{props.title}</span>
			<span className={classes.fixedFilterValue}>{props.value}</span>
			<span className={classes.fixedFilterRemove} onClick={props.onClick}>
				<FaTimes />
			</span>
		</div>
	);
};

export default props => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const query = queryString.parse(useLocation().search);
	const { pathname } = useLocation();
	const pathnameSlug = pathname.toLowerCase().replace(/[^a-z0-9]/gi, '');
	// const axiosCancelSource = axios.CancelToken.source();

	const filtersCache = useSelector(store => store.filters[pathnameSlug] || {});

	const history = useHistory();
	const usuario = useSelector(store => store.auth.usuario);
	const token = useSelector(store => store.auth.token);
	// const localFilters = useSelector(store => store.system.filters[location.pathname] || {});
	const { id: estabelecimentoId } = useSelector(state => state.admin.estabelecimento) || {};
	const agruparTaxas = useSelector(store =>
		Isset(() => store.system.params['1'].valor) ? store.system.params['1'].valor : null
	);

	let queryFilter = {
		startDate: new Date(),
		endDate: new Date(),
		data_pagamento: { de: new Date(), ate: new Date() },
		estabelecimentoId: estabelecimentoId ? [estabelecimentoId] : [],
		status: [],
		omni: '',
		omni2: '',
		...filtersCache,
	};

	const [data, setData] = useState({
		pagamentos: [],
	});
	const [loading, setLoading] = useState(true);
	const [showExportMessage, setShowExportMessage] = useState(false);
	const [showLastExport, setShowLastExport] = useState(false);

	const [totais, setTotais] = useState({
		geral: {
			quantidade: 0,
			quantidadePedidos: 0,
			totalAprovado: 0,
			totalFalhado: 0,
			totalCancelado: 0,
			totalEstornado: 0,
			totalPendente: 0,
			taxa: 0,
			split: 0,
			liquido: 0,
			markup: 0,
		},
	});

	// Paginação

	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(200);
	const [totalRows, setTotalRows] = useState(0);

	const onChangePage = newPage => {
		setPage(newPage);
	};

	const onChangeRowsPerPage = rowsPerPage => {
		setRowsPerPage(rowsPerPage);
		setPage(1);
	};

	const getData = async () => {
		setData({ pagamentos: [] });
		setTotais({
			geral: {
				quantidade: 0,
				quantidadePedidos: 0,
				totalAprovado: 0,
				totalFalhado: 0,
				totalCancelado: 0,
				totalEstornado: 0,
				totalPendente: 0,
				taxa: 0,
				split: 0,
				liquido: 0,
				markup: 0,
			},
		});
		setLoading(true);
		const res = await axios.get(`${config.get('apiUrl')}pagamentos`, {
			// cancelToken: axiosCancelSource.token,
			headers: { Authorization: `Bearer ${token}` },
			params: {
				page,
				limit: rowsPerPage,
				estabelecimentoId,
				...filtros,
			},
		});
		if (res.data.success) {
			setTotais({ geral: res.data.totaisGeral });
			setData({ pagamentos: res.data.pagamentos });
			setTotalRows(res.data.quantidade);
		}
		setLoading(false);
	};

	const [filtros, setFiltros] = useState({
		...queryFilter,
		pos: query.pos || '',
		omni2: Isset(() => query.estabelecimentoNome) ? query.estabelecimentoNome : queryFilter.omni2,
		startDate: Isset(() => query.startDate) ? query.startDate : queryFilter.startDate,
		endDate: Isset(() => query.endDate) ? query.endDate : queryFilter.endDate,
		data_pagamento: {
			de: Isset(() => query.startDate) ? query.startDate : queryFilter.data_pagamento.de,
			ate: Isset(() => query.endDate) ? query.endDate : queryFilter.data_pagamento.ate,
		},
	});

	useEffect(() => {
		getData();
		// dispatch(setFilters(location.pathname, filtros));
		// eslint-disable-next-line react-hooks/exhaustive-deps
		// return async () => {
		// 	axiosCancelSource.cancel('Pagamentos Unmounted.');
		// };
	}, [filtros, page, rowsPerPage]);

	const filtrosChange = async data => {
		setPage(1);
		data.startDate = data.date.start;
		data.endDate = data.date.end;

		data.data_pagamento = {
			de: data.date.start,
			ate: data.date.end,
		};

		data.estabelecimentoId = [estabelecimentoId];

		if (Array.isArray(data.estabelecimento)) {
			data.estabelecimentoId = [estabelecimentoId, ...data.estabelecimento];
		}

		data.status = data.statusPagamento;
		data.pos = query.pos || '';

		if (Isset(() => query.estabelecimento) && query.estabelecimento !== '') {
			data.estabelecimentoId = [query.estabelecimento];
		}

		setFiltros(data);
	};

	useEffect(() => {
		setFiltros({
			...filtros,
			estabelecimentoId: Isset(() => query.estabelecimento) ? [query.estabelecimento] : [estabelecimentoId],
		});
	}, [estabelecimentoId]);

	const exportXls = async () => {
		setShowExportMessage(true);
		const pagamentos = await axios.get(`${config.get('apiUrl')}export/pagamentos`, {
			headers: { Authorization: `Bearer ${token}` },
			responseType: 'blob',
			params: {
				rows: totalRows,
				estabelecimentoId,
				...filtros,
			},
		});

		if (pagamentos.status === 200) {
			const { 'content-disposition': contentDisposition, 'content-type': contentType } = pagamentos.headers;
			let filename = 'pagamentos.xlsx';
			if (contentDisposition.indexOf('filename="') !== -1) {
				filename = contentDisposition.slice(
					contentDisposition.indexOf('filename="') + 10,
					contentDisposition.length - 1
				);
			}

			FileDownload(pagamentos.data, filename, contentType);
		} else if (pagamentos.status === 201) {
			dispatch(addMessage({ type: 'warning', message: 'Sua nova exportação está sendo processada.' }));
		} else {
			dispatch(addMessage({ type: 'error', message: 'Erro ao exportar pagamentos.' }));
		}
		setShowExportMessage(false);
	};

	const exportXlsClick = async () => {
		if (totalRows > 20000) {
			setShowLastExport(true);
		} else {
			await exportXls();
		}
	};

	const newExport = async () => {
		await exportXls();
	};

	const rowClick = pedidoId => {
		history.push(`/vendas/detalhes/${pedidoId}`);
	};

	const handleClearFilter = () => {
		setFiltros({
			pos: query.pos || '',
			omni: '',
			omni2: '',
			startDate: new Date(),
			endDate: new Date(),
			data_pagamento: { de: new Date(), ate: new Date() },
		});
	};

	const hideExport = [7, 5];

	return (
		<Grid className={classes.pagamentos}>
			<div className={classes.header}>Pagamentos</div>

			<Paper style={{ marginBottom: 10, padding: '5px 10px' }}>
				<Grid
					container
					style={{
						position: 'relative',
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<Grid item md={11}>
						<ZFiltros
							onChange={filtrosChange}
							modal={true}
							handleClearFilter={() => handleClearFilter()}
							dateRange={{
								default: 'custom',
								startDate: filtros.startDate,
								endDate: filtros.endDate,
							}}
							input={
								![2, 3].includes(usuario.grupo_id)
									? [
											{
												key: 'omni',
												defaultValue: filtros.omni,
												mask: 'documento',
												placeholder: 'CPF/CNPJ',
												modal: true,
												md: 6,
											},
											{
												key: 'omni2',
												defaultValue: filtros.omni2,
												placeholder: 'Estabelecimento',
												modal: true,
												md: 6,
											},
									  ]
									: null
							}
							selectField={[
								{
									key: 'bandeira',
									defaultValue: filtros.bandeira,
									multiple: true,
									title: 'Bandeira',
									options: getOptions().bandeiras,
									modal: true,
									md: 12,
									divider: true,
								},
							]}
						/>
					</Grid>
					<Grid container item spacing={1} md={1}>
						<Grid xs={6} align="center" md={6}>
							{!hideExport.includes(usuario.grupo_id) && (
								<Tooltip title="Exportar">
									<Button
										className={clsx(classes.buttonExport, classes.iconButton)}
										onClick={exportXlsClick}
									>
										<FaDownload className={classes.exportDownload} />
									</Button>
								</Tooltip>
								// <FaFileExcel className={classes.export} onClick={exportXlsClick} />
							)}
						</Grid>
						<Grid xs={6} align="center" md={6}>
							{!hideExport.includes(usuario.grupo_id) && (
								// <FaHistory className={classes.exportHistory} onClick={() => setShowLastExport(true)} />
								<Tooltip title="Histórico">
									<Button
										className={clsx(classes.buttonHistory, classes.iconButton)}
										onClick={() => setShowLastExport(true)}
									>
										<FaHistory className={classes.exportHistory} />
									</Button>
								</Tooltip>
							)}
						</Grid>
					</Grid>
				</Grid>
				<div style={{ display: 'flex' }}>
					{query.pos && (
						<FixedFilter
							title="POS"
							value={query.pos}
							onClick={async () => {
								delete query.pos;
								await setFiltros({ ...filtros, pos: '' });
								history.push(`/pagamentos?${queryString.stringify(query)}`);
							}}
						/>
					)}
					{query.estabelecimento && (
						<FixedFilter
							title="Estabelecimento"
							value={query.estabelecimentoNome}
							onClick={async () => {
								delete query.estabelecimento;
								await setFiltros({
									...filtros,
									estabelecimentoId: estabelecimentoId ? [estabelecimentoId] : [],
								});
								history.push(`/pagamentos?${queryString.stringify(query)}`);
							}}
						/>
					)}
				</div>
			</Paper>

			{usuario.grupo_id !== 7 && (
				<Paper style={{ padding: 15, marginBottom: 10 }}>
					<Grid container spacing={3}>
						<Grid item xs={6} sm={3} md={3}>
							<div className={classes.label}>
								<div className={clsx(classes.valor, classes.qtdPagamentos)}>
									{Formatter.Valor(
										Isset(() => totais.geral.quantidade) ? totais.geral.quantidade : 0,
										0,
										',',
										'.'
									)}
								</div>
								Pagamentos
							</div>
						</Grid>
						<Grid item xs={6} sm={3} md={3}>
							<div className={classes.label}>
								<div className={clsx(classes.valor, classes.qtdPagamentos)}>
									{Formatter.Valor(
										Isset(() => totais.geral.quantidadePedidos)
											? totais.geral.quantidadePedidos
											: 0,
										0,
										',',
										'.'
									)}
								</div>
								Vendas
							</div>
						</Grid>
						<Grid item sm={3} md={3} className={classes.hideOnXs} style={{ textAlign: 'right' }}>
							<div className={classes.label}>
								{`Aprovado `}
								<span className={clsx(classes.valor, classes.valorAprovado)}>
									{Formatter.Real(Isset(() => totais.geral.valorBruto) ? totais.geral.valorBruto : 0)}
								</span>
							</div>
						</Grid>
						<Grid item sm={3} md={3} className={classes.hideOnXs} style={{ textAlign: 'right' }}>
							<div className={classes.label}>
								{`Taxa `}
								<span className={classes.valor}>{Formatter.Real(+totais.geral.valorTaxa)}</span>
							</div>
							<div className={classes.label}>
								{`Líquido `}
								<span className={classes.valor}>
									{Formatter.Real(
										Isset(() => totais.geral.valorLiquido) ? totais.geral.valorLiquido : 0
									)}
								</span>
							</div>
							{usuario.grupo_id === 1 && (
								<>
									<div className={classes.label}>
										{`Markup `}
										<span className={classes.valor}>
											{Formatter.Real(
												Isset(() => totais.geral.valorMarkup) ? totais.geral.valorMarkup : 0
											)}
										</span>
									</div>
								</>
							)}
						</Grid>
					</Grid>
				</Paper>
			)}

			<PagamentosList
				pagamentos={data.pagamentos}
				loading={loading}
				totais={totais}
				rowClick={rowClick}
				agruparTaxas={agruparTaxas}
			/>

			<ZPaginationDiv
				count={totalRows}
				rowsPerPage={rowsPerPage}
				page={page - 1}
				onChangePage={onChangePage}
				onChangeRowsPerPage={onChangeRowsPerPage}
				rowsPerPageOptions={[50, 100, 200]}
			/>

			<Dialog open={showExportMessage}>
				<DialogContent style={{ width: 200 }}>
					<ZLoader height={100} size={26} message={'Exportando'} />
				</DialogContent>
			</Dialog>

			{showLastExport && (
				<LastExport
					show={showLastExport}
					onClose={() => setShowLastExport(false)}
					tipo={'pagamentos'}
					newExport={newExport}
					loadingProp={loading}
				/>
			)}
		</Grid>
	);
};
